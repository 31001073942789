import {useStoreActions, useStoreState} from "easy-peasy";
import moment from "moment";
import {useEffect, useState} from "react";
import { EditOutlined, EyeOutlined, RiseOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
    Button,
    Card,
    Col,
    Progress,
    Input,
    Row,
    Statistic,
    Table,
    Tag,
    Popover,
    Tooltip,
    Select,
    Switch,
} from "antd";
import {Form, Nav, Navbar} from "react-bootstrap";
import React from "react";
import InputCell from "../../InputCell";
import {PaIncomePrognosisTableV2} from "../projections/PaIncomePrognosisTableV2"


const {CheckableTag} = Tag;
const {Option} = Select


const formatCurrency = (text) => {

    const nodata = ''
    if (text) {
        if (typeof text == 'number') return text.toLocaleString('et-ee', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        })
        else return text
    }
    //return nodata.toLocaleString('et-ee', {
    //        style: 'currency',
    //        currency: 'EUR',
    //        maximumFractionDigits: 0,
    //        minimumFractionDigits: 0,
    //    })
    return nodata
}


const PopContent = ({idoffer}) => {

    const costs = useStoreState(state => state.object.costs)
    const budget = useStoreState(state => state.object.budget)

    let dataSource = []

    // calculate assignments for each cost in a flat structure
    // calculate possible cost types in a list based on budget

    let myCostTypes = []                                    // list of cost types used in object, table rows in kokkuvõte
    let myCosts = []                                        // costs, only assignments
    Object.values(costs).forEach(cost => {
        if (cost.assignments) {
            cost.assignments.forEach(assignment => assignment.specialrow_idoffer === idoffer ? myCosts.push(
                {
                    ...assignment,
                    cost_type_name: cost.cost_type_name
                }
            ) : null)
        }
        if (!myCostTypes.includes(cost.cost_type_name)) myCostTypes.push(cost.cost_type_name)
    })


    myCostTypes.forEach(r => {
        dataSource.push(
            {
                costTypeName: r,
                budget: budget.reduce((sum, row) => row.idoffer === idoffer && r === row.cost_type_name ? sum += row.sum : sum += 0, 0), // see arvutab valesti
                costs: myCosts.reduce((sum, row) => r === row.cost_type_name ? sum += row.assignment_sum : sum += 0, 0),
                percent: 0,     // calculated later
                diff: 0         // calculated later
            }
        )
    })

    // adding sum row
    dataSource.push(
        {
            costTypeName: "Kokku:",
            budget: budget.reduce((sum, row) => row.idoffer === idoffer ? sum += row.sum : sum += 0, 0),
            costs: myCosts.reduce((sum, row) => sum += row.assignment_sum, 0),
            percent: 0,     // calculated later
            diff: 0,        // calculated later
        })


    // calculating percent and sum for each row and rounding for all
    dataSource.forEach(r => {
        r.budget = parseFloat(r.budget).toFixed(2)
        r.costs = parseFloat(r.costs).toFixed(2)
        r.diff = (r.budget - r.costs).toFixed(2)
        r.percent = ((r.budget ? r.costs / r.budget : 0) * 100) //.toFixed(2)
    })

    const Percentage = ({percent}) => {

        if (0 < percent && percent <= 100) return <div style={{width: 100}}><Progress size="small"
                                                                                      percent={percent.toFixed(2)}/>
        </div>
        if (percent > 100 && percent !== Number.POSITIVE_INFINITY) return <div><Tag color="red"> <RiseOutlined /></Tag> {percent.toFixed(2)} %</div>;
        if (isNaN(percent)) return <div style={{width: 100}}><Progress size="small" percent={0}/></div>
        if (percent === Number.POSITIVE_INFINITY || percent === Number.NEGATIVE_INFINITY) return <div><Tag
            color="blue"> ? </Tag></div>
        return <div style={{width: 100}}><Progress size="small" percent={0}/></div>
    }

    const cols = [
        {
            title: 'Kulukoht',
            dataIndex: 'costTypeName',

        },
        {
            title: 'Kulueelarve',
            dataIndex: 'budget',
            align: "right"
        },
        {
            title: 'Tegelikud kulud',
            dataIndex: 'costs',
            align: "right"
        },
        {
            title: 'Vahe',
            dataIndex: 'diff',
            align: "right"
        },
        {
            title: '%',
            dataIndex: 'percent',
            width: 180,
            render: (text, record, index) => <Percentage percent={text}/>
        },

    ]


    return <Table columns={cols} dataSource={dataSource} size="middle" pagination={false}/>
}


const PaProjectionSpecialUnitPrice = ({record, targetedCosts}) => {

    let unitprice = record.fulfilled_amount ?
        (targetedCosts[record.idoffer] / record.fulfilled_amount).toFixed(2) : 0

    let percentage = unitprice !== 0 ?
        (((unitprice / record.unit_price) - 1) * 100).toFixed(2) : null


    if (record.is_special_bill_row === 1) {


        return (
            <Popover content={<PopContent idoffer={record.idoffer}/>} title={record.art_name}>
                <div style={
                    percentage < 0 ? {
                            background: 'green',
                            color: 'white',
                            padding: '5px'
                        }
                        :
                        {background: 'red', color: 'white', padding: '5px'}}>


                    {record.fulfilled_amount ?
                        (targetedCosts[record.idoffer] / record.fulfilled_amount).toFixed(2)
                        :
                        <small>Alustamata</small>
                    }

                    {percentage ?
                        <small>
                            <LegacyIcon type={percentage > 0 ? "arrow-up" : "arrow-down"}/> {percentage} %
                        </small>
                        :
                        null
                    }

                </div>
            </Popover>
        );
    } else return (<div className="text-muted">{record.unit_price ? record.unit_price.toFixed(2) : null} </div>)

}

export const PaProjectionTableV2 = () => {

    const meta = useStoreState(state => state.object.meta)

    const updatePrognosis = useStoreActions(actions => actions.resultV2.apiSetPrognosis)
    const fetchObjectsTotals = useStoreActions(actions => actions.resultV2.apiFetchObjectsTotals)

    const resultAndPrognosis = useStoreState(state => state.resultV2.result)
    const objectsTotals = useStoreState(state => state.resultV2.objectsTotals)

    // budgetsubtotals also contains fully bills.flat content
    const budgetSubtotals = useStoreState(state => state.budget.budgetSubtotals)
    //const subtotalSumAtOfferByBill = useStoreState(state => state.resultV2.subtotalSumAtOfferByBill)
    const bills = useStoreState(state => state.bills.bills)
    const budget = useStoreState(state => state.budget.budget)

    const addBudgetRow = useStoreActions(actions => actions.budget.apiAddBudgetRow)
    const fetchBudget = useStoreActions(actions => actions.budget.apiFetchBudget)
    const fetchResultAndPrognosis = useStoreActions(actions => actions.resultV2.apiFetchResult)
    //const fetchObjectTotals = useStoreActions(actions => actions.resultV2.apiFetchObjectsTotals)

    let data = []


    if (budgetSubtotals) budgetSubtotals.sort((a, b) => a.parts[0] - b.parts[0]).forEach(bill => {
        data.push({result_row_type: 'bill', ...bill})
        resultAndPrognosis.filter(row => row.path === bill.path && row.result_row_type !== 'empty')
            .sort((a, b) => a.bill_path - b.bill_path)
            .forEach(b => {
                data.push(b)
            })

    })

    //data.sort((a, b) => a.mylevel - b.mylevel)

    console.log('DATA', budgetSubtotals, data)


    function format_shaded(text, record, index, percentage = null) {


        const value = text ?
            isFinite(text) ?
                percentage ?
                    (text * 100).toFixed(0).toString() + '%'
                    : text.toFixed(2).toString()
                : "-"
            : ""

        return {
            props: {
                className: record.result_row_type !== 'bill' ? 'projection_actual' : null,
                //style: {background: parseInt(text) > 50 ? "red" : "green"}
            },
            children: <div>{value}</div>
        };
    }

    function format_end_diff(text, record, index) {
        return {
            props: {
                className: record.result_row_type !== 'bill' ?
                    text < 0 ? 'projection_highlight_bad' : 'projection_highlight_good'
                    : null,

            },
            children: <div>{text ? text.toFixed(2).toString() : ""}</div>
        };
    }

    function format_end(text, record, index) {
        return {
            props: {
                className: record.result_row_type !== 'bill' ? 'projection_final' : null,
                //style: {background: parseInt(text) > 50 ? "red" : "green"}
            },
            children: <div>{text ? text.toFixed(2).toString() : ""}</div>
        };
    }

    function format_good_bad(text, record, index) {
        return {
            props: {
                className: record.result_row_type !== 'bill' ? text > 0 ? 'projection_highlight_good' : 'projection_highlight_bad' : null,
                //style: {background: parseInt(text) > 50 ? "red" : "green"}
            },
            children: <div>{text ? text.toFixed(0).toString() : ""}</div>
        };
    }

    function format_prognosis_unitprice(text, record, index) {

        // determine what to show
        // if prognosis exists, then value is prognosed value, if not then amount remaining

        const displayValue = record.unit_price_in_the_end ? record.unit_price_in_the_end : isFinite(text) ? text : null

        if (activeCell === index.toString() + "price") return {

            props: {
                className: record.result_row_type === 'budgetrow' ? 'projection_highlight' : null,
                //style: record.result_row_type === 'budgetrow' ? {border: '0px solid rgba(0, 0, 0, 0.1)'} : {width: 300}
            },
            children: <div>
                <div className="d-flex justify-content-between mb-1">
                    <div className="mr-2">Praegu:</div>
                    <div><b>{displayValue ? displayValue.toFixed(2) : 0}</b></div>
                </div>
                <div className="d-flex justify-content-between mb-1 align-items-center">
                    <div className="mr-2">Uus:</div>

                    <InputCell
                        defaultValue={displayValue ? displayValue.toFixed(2) : record.completed_cost_unit_price}
                        onClick={() => {
                            console.log("click", getCellReference(index, record.bill_name));
                            setActiveCell(getCellReference(index, 'price'))
                        }}
                        onBlur={(e) => handleUpdate(record, 'price', e)}
                        onMoveUp={(value) => setActiveCell(getCellReference(index > 0 ? index + value : 0, 'price'))}
                        onMoveDown={(value) => setActiveCell(getCellReference(index < data.length - 1 ? index + value : data.length - 1, 'price'))}
                        onUpdate={(e) => handleUpdate(record, 'price', e)}
                        active={activeCell === getCellReference(index, 'price')}
                    />


                </div>
            </div>
        };

        return {
            props: {
                className: record.result_row_type === 'budgetrow' ? 'projection_highlight' : null,
                //style: record.result_row_type === 'budgetrow' ? {border: '1px solid rgba(0, 0, 0, 0.1)'} : null
            },
            children: record.result_row_type === 'budgetrow' ? <div
                onClick={() => setActiveCell(index.toString() + "price")}><EditOutlined /> {displayValue ? displayValue.toFixed(2).toString() : 0} </div> : null
        };
    }

    function format_prognosis_amount(text, record, index) {

        // determine what to show
        // if prognosis exists, then value is prognosed value, if not then amount remaining

        //const displayValue = record.prognosis_amount ? record.prognosis_amount : record.amount_remaining
        const displayValue = record.amount_remaining
        let additionalAmounts = 0

        if (record.amount_in_the_end < record.completed_cost_amounts)
            additionalAmounts = record.completed_cost_amounts - record.amount_in_the_end

        if (activeCell === index.toString() + "amount") return {

            props: {
                className: record.result_row_type === 'budgetrow' ? 'projection_highlight' : null,
                //style: record.result_row_type === 'budgetrow' ? {border: '0px solid rgba(0, 0, 0, 0.1)'} : {width: 300}
            },
            children: <div>
                <div className="d-flex justify-content-between mb-1">
                    <div className="mr-2">Järel:</div>
                    <div><b>{displayValue ? displayValue.toFixed(2) : 0}</b></div>
                </div>
                <div className="d-flex justify-content-between mb-1 align-items-center">
                    <div className="mr-2">Lisaks:</div>

                    <InputCell
                        defaultValue={additionalAmounts.toFixed(2)}
                        onClick={() => {
                            console.log("click", getCellReference(index, record.bill_name));
                            setActiveCell(getCellReference(index, 'amount'))
                        }}
                        onBlur={(e) => handleUpdate(record, 'amount', e)}
                        onMoveUp={(value) => setActiveCell(getCellReference(index > 0 ? index + value : 0, 'amount'))}
                        onMoveDown={(value) => setActiveCell(getCellReference(index < data.length - 1 ? index + value : data.length - 1, 'amount'))}
                        onUpdate={(e) => handleUpdate(record, 'amount', e)}
                        active={activeCell === getCellReference(index, 'amount')}
                    />


                </div>
            </div>
        };

        return {
            props: {
                className: record.result_row_type === 'budgetrow' ? 'projection_highlight' : null,
                //style: record.result_row_type === 'budgetrow' ? {border: '0px solid rgba(0, 0, 0, 0.1)'} : null
            },
            children: record.result_row_type === 'budgetrow' ? <div
                    onClick={() => setActiveCell(index.toString() + "amount")}><EditOutlined /> {displayValue ? displayValue.toFixed(2).toString() : 0} </div>
                :
                record.result_row_type === 'costed_not_budgeted' ?
                    <Button onClick={() => handleAddRowToBudget(record)}>Soovin prognoosida</Button> : null
        };
    }


    const cost_type_name = (text, record, index) => {
        if (record.result_row_type === 'budgetrow') return <small
            className="text-muted"> {record.result_cost_type}</small>
        if (record.result_row_type === 'costed_not_budgeted') return <small
            className="text-muted"> {record.result_cost_type}</small>
    }


    const [activeCell, setActiveCell] = useState(false)

    function getCellReference(index, field) {
        return index.toString() + field
    }

    async function handleAddRowToBudget(record) {

        const payload = {

            offer_nr: budget[0].offer_nr,
            obj_id: meta.idobject,
            id_bills_types: bills.flat.find(bill => bill.path === record.path)['id'],
            bill_path: record.path,
            cost_type: record.costs_cost_type,
            cost_standard: record.ident1,
            cost_description: record.cost_description_cost,
            unit: record.completed_cost_unit,


        }

        await addBudgetRow(payload)
        fetchBudget({obj_id: meta.idobject})
        fetchResultAndPrognosis({obj_id: meta.idobject})
        fetchObjectsTotals({objects: meta.idobject})

        console.log('handlerowadd', record)
    }

    async function handleUpdate(record, field, value) {

        const baseValue = record.amount_in_the_end

        let payload =
            {
                obj_id: meta.idobject,
                bill_path: record.bill_path,
                //offer_id: record.idoffer,
                costs_budgets_id: record.budgets_id,
                amount: record.amount_in_the_end,
                price: record.unit_price_in_the_end,
            }

        if (field === 'amount') payload['amount'] = parseFloat(value) + baseValue
        if (field === 'price') payload['price'] = parseFloat(value)

        if (record[field] || value) {

            let newValueString = value ? value.toString() : false
            let oldValueString = record[field] ? record[field].toString() : false

            if (newValueString !== oldValueString) {

                setActiveCell(false)
                await updatePrognosis(payload)

            } else setActiveCell(false)
        } else setActiveCell(false)


        setActiveCell(false)
    }


    const columns = [
        //{title: '#', dataIndex: 'budgets_id', key: 'budgets_id', width: 70, fixed: 'left'},
        {
            title: 'Bill',
            dataIndex: 'path',
            key: 'path',
            width: 65,
            fixed: 'left',
            render: text => text ? text.replaceAll('/', '.') : null,
            //sorter: (a, b) => a.path.localeCompare(b.path),
        },
        //{
        //    title: 'resrowtype', dataIndex: 'result_row_type', key: 'res_row_type', width: 80, fixed: 'left',
        //},
        {
            title: "Nimetus", dataIndex: "name", key: "name", width: 150, fixed: 'left',
            render: (text, record, index) => {
                if (record.result_row_type === 'budgetrow') return <div className="ml-3"
                                                                        style={{color: 'green'}}> {record.cost_standard ? record.cost_standard : record.cost_description} {!record.cost_description ? record.res_name1 : null} {record.res_name2}
                    <br/>
                    <small style={{color: 'black'}}>{record.cost_identifier}  </small>
                </div>
                //if (record.result_row_type === 'costed_not_budgeted') return <div className="ml-3" style={{color: 'red'}}>{record.ident1 || 'Eelarvestamata kulud'}</div>
                if (record.result_row_type === 'costed_not_budgeted') return <div className="ml-3"
                                                                                  style={{color: 'red'}}>{record.cost_description_cost} {record.cost_standard} {record.ident1} {record.res_name1} {record.res_name2}</div>

                return text
            }
        },
        {
            title: "Kulutüüp", dataIndex: "result_cost_type", key: "result_cost_type", width: 100, fixed: 'left',
            render: cost_type_name
        },
        {
            title: "Ühik", dataIndex: "unit", key: "unit", width: 65, fixed: 'left',
            render: (text, record) => record.result_row_type === 'costed_not_budgeted' ? record.completed_cost_unit : text
        },
        {
            title: 'Pakkumise eelarve',
            filter_key: 'Pakkumise eelarve',
            key: 'offered_budget',
            children: [
                {
                    title: 'Kogus',
                    dataIndex: 'amount_at_offer',
                    key: 'amount_at_offer',
                    align: 'right',
                    width: 90,
                    render: format_shaded,
                },
                {
                    title: 'Ühikhind',
                    dataIndex: 'unit_price_at_offer',
                    key: 'unit_price_at_offer',
                    align: 'right',
                    width: 90,
                    className: 'projection_actual',
                    render: format_shaded,

                },
                {
                    title: 'Summa',
                    key: 'sum_at_offer',
                    dataIndex: 'sum_at_offer',
                    align: 'right',
                    width: 90,
                    className: 'projection_actual',
                    render: (text, record, index) => record.result_row_type !== 'bill' ?
                        format_shaded(text, record, index)
                        :
                        {
                            props: {
                                className: null,
                            },
                            children: <div>€ {record.subtotalContract.toFixed(0)}</div>
                        }
                    //render:  format_shaded
                }]
        },
        {
            title: 'Eesmärkeelarve',
            filter_key: 'Eesmärkeelarve',
            key: 'target_budget',
            children: [
                {
                    title: 'Kogus',
                    dataIndex: 'amount_target',
                    key: 'amount_target',
                    align: 'right',
                    width: 90,
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""
                },
                {
                    title: 'Ühikhind',
                    dataIndex: 'unit_price_target',
                    key: 'unit_price_target',
                    align: 'right',
                    width: 90,
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""

                },
                {
                    title: 'Summa',
                    key: 'sum_target',
                    dataIndex: 'sum_target',
                    align: 'right',
                    width: 90,
                    render: (text, record, index) => record.result_row_type !== 'bill' ?
                        text ? text.toFixed(2) : ""
                        :
                        {
                            props: {
                                className: null,
                            },
                            children: <div>€ {record.subtotalTarget.toFixed(0)}</div>
                        }
                }]
        },
        {
            title: 'Erinevus',
            filter_key: 'Erinevus',
            key: 'diff',
            children: [
                {
                    title: 'Vahe summa',
                    dataIndex: 'path',
                    key: 'diff',
                    align: 'right',
                    className: 'projection',
                    width: 90,
                    render: (text, record, index) => record.result_row_type === 'bill' ? {
                            props: {
                                className: null,
                            },
                            children: <div>{record.difference.toFixed(0)}</div>
                        }
                        :
                        format_good_bad(record.sum_target && record.sum_at_offer ? (record.sum_at_offer - record.sum_target) : "", record, index)
                },
            ]
        },
        {
            title: 'Teostatud',
            filter_key: 'Eesmärkeelarve',
            key: 'target_budget',
            children: [
                {
                    title: 'Kogus',
                    dataIndex: 'completed_cost_amounts',
                    key: 'completed_cost_amounts',
                    align: 'right',
                    width: 90,
                    //className: 'projection_actual',
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""
                },
                {
                    title: 'Ühikhind',
                    dataIndex: 'completed_cost_unit_price',
                    key: 'completed_cost_unit_price',
                    align: 'right',
                    width: 90,
                    //className: 'projection_actual',
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""

                },
                {
                    title: 'Summa',
                    key: 'completed_total',
                    dataIndex: 'completed_total',
                    align: 'right',
                    width: 90,
                    //className: 'projection_actual',
                    //render: (text, record, index) => text ? text.toFixed(0).toString() : ""
                    render: (text, record, index) => record.result_row_type !== 'bill' ?
                        text ? text.toFixed(2) : ""
                        :
                        {
                            props: {
                                className: null,
                            },
                            children:
                                <div>{record.subtotalCompletedCosts ? record.subtotalCompletedCosts.toFixed(0) : 0}</div>
                        }
                }, {
                    title: 'Kulude osakaal eelarvest',
                    key: 'cost_budget_KPI',
                    dataIndex: 'completed_total',
                    align: 'right',
                    width: 90,
                    //className: 'projection',
                    render: (text, record, index) => format_shaded(record.completed_total / record.sum_target, record, index, '%')
                }, {
                    title: 'Kulude osakaal prognoosist',
                    key: 'costs_to_prognosis',
                    dataIndex: 'costs_to_prognosis',
                    align: 'right',
                    width: 90,
                    //className: 'projection',
                    render: (text, record, index) => format_shaded(text, record, index, '%')
                },
                {
                    title: 'Valmis %',
                    key: 'completed_KPI',
                    dataIndex: 'completed_total',
                    align: 'right',
                    width: 90,
                    //className: 'projection',
                    render: (text, record, index) => format_shaded(record.completed_cost_amounts / record.amount_target, record, index, '%')
                }]
        },
        {
            title: <div>Järele jäänud (veel kulub)<br/>Lisa siia prognoos:</div>,
            filter_key: 'prognoos',
            key: 'prognosis',

            children: [
                {
                    title: 'Kogus',
                    dataIndex: 'amount_remaining',
                    key: 'remaining_cost_amounts',
                    align: 'right',
                    width: 150,
                    render: format_prognosis_amount
                },
                {
                    title: 'Ühikhind',
                    dataIndex: 'unit_price_target',
                    key: 'unit_price_target',
                    align: 'right',
                    width: 150,
                    //className: 'projection_actual',
                    render: (text, record, index) => format_prognosis_unitprice(record.sum_remaining / record.amount_remaining, record, index)

                },
                {
                    title: 'Summa',
                    key: 'sum_remaining',
                    dataIndex: 'sum_remaining',
                    align: 'right',
                    width: 100,
                    render: (text, record, index) => <div
                        className="mr-2">{text ? text.toFixed(0).toString() : ""}</div>
                }]
        },
        {
            title: 'Prognoos lõpus',
            filter_key: 'Eesmärkeelarve',
            key: 'target_budget',
            fixed: 'right',
            children: [
                {
                    title: 'Kogus',
                    fixed: 'right',
                    dataIndex: 'amount_in_the_end',
                    key: 'amount_in_the_end',
                    align: 'right',
                    width: 90,
                    //className: 'projection_actual',
                    render: format_end
                },
                {
                    title: 'Ühikhind',
                    fixed: 'right',
                    dataIndex: 'unit_price_in_the_end',
                    key: 'unit_price_in_the_end',
                    align: 'right',
                    width: 90,
                    //className: 'projection_actual',
                    render: format_end

                },
                {
                    title: 'Summa',
                    fixed: 'right',
                    key: 'sum_in_the_end',
                    dataIndex: 'sum_in_the_end',
                    align: 'right',
                    width: 90,
                    //className: 'projection_actual',
                    render: format_end
                },
                {
                    title: 'Vahe',
                    fixed: 'right',
                    key: 'sum_diff_in_the_end',
                    dataIndex: 'sum_diff_in_the_end',
                    align: 'right',
                    width: 80,
                    //className: 'projection_actual',
                    render: format_end_diff
                }]
        },

    ]

    const [filterOutEmptyRows, setFilterOutEmptyRows] = useState(false)

    if (filterOutEmptyRows) data = data.filter(row => row.subtotalCompletedCosts > 0 || row.subtotalContract > 0 || row.subtotalTarget > 0 || row.sum_target > 0 || row.sum_at_offer > 0 || row.completed_total > 0)

    return <div>

        <div className="d-flex justify-content-end m-2">
            <div className="mr-2"> Ära näita tühje ridu</div>
            <Switch checked={filterOutEmptyRows} onChange={setFilterOutEmptyRows}/>
        </div>

        <Table dataSource={data}
               columns={columns}
               size="middle"
               rowClassName={(record, index) => {
                   if (record.result_row_type === 'bill') return 'budget_titlerow'
               }}
               scroll={{x: 1900, y: 600}}
               pagination={{
                   defaultPageSize: 40,
                   showSizeChanger: true,
                   onChange: (page, pageSize) => console.log("page: ", page, "pagesize: ", pageSize),
                   onShowSizeChange: (current, size) => console.log("page: ", current, "pagesize: ", size)
               }}
        />
    </div>

}

export const PaProjectionV2 = () => {

    const [activeTab, setActiveTab] = useState('costPrognosis')
    let subtotals = useStoreState(state => state.resultV2.objectsTotals)
    const meta = useStoreState(state => state.object.meta)

    if (subtotals) subtotals = subtotals.find(r => r.idobject === meta.idobject)

    const tabList = [
        {
            key: 'costPrognosis',
            tab: 'Kuluprognoos',
        },
        {
            key: 'incomePrognosis',
            tab: 'Tuluprognoos',
        },
    ];


    const contentList = {
        costPrognosis: <div className="mt-1">
            <Row>
                <Col span={24}>
                    <PaObjectTotalsV2/>
                </Col>

            </Row>
            <PaProjectionTableV2/>
        </div>,
        incomePrognosis: <div className="mt-1">
            <Row>
                <Col span={24}>
                    <PaObjectTotalsV2/>
                </Col>

            </Row>
            <PaIncomePrognosisTableV2/>
        </div>,
    };

    return (
        <div className='mt-2'>

            <Card title={<div className="ml-3"> Prognoos</div>}
                  extra={
                      subtotals ?
                          <div className="d-flex">

                              <div style={{width: '220px'}} className="mr-2">Objekt valminud kulupõhiselt: <Progress
                                  percent={parseInt(subtotals.completed_by_cost * 100)} size="small"/></div>
                              <div style={{width: '220px'}}>Objekt valminud mahupõhiselt: <Progress
                                  percent={parseInt(subtotals.completed_by_income * 100)}
                                  size="small"/></div>
                          </div>
                          :
                          null
                  }
                  tabList={tabList}
                  activeTabKey={activeTab}
                  onTabChange={setActiveTab}
            >
                {contentList[activeTab]}

            </Card>
        </div>
    )

}

export const PaObjectTotalsV2 = ({objectSelector = false, showTotals = true, loading = false}) => {

    const subtotals = useStoreState(state => state.resultV2.objectsTotals)
    const objects = useStoreState(state => state.objects.objects)
    const meta = useStoreState(state => state.object.meta)
    const fetchObjectsTotals = useStoreActions(actions => actions.resultV2.apiFetchObjectsTotals)
    const objectsTotalsLoading = useStoreState(state => state.resultV2.objectsTotalsLoading)

    const [selectedObjects, setSelectedObjects] = useState([meta.idobject])

    const objectsSelect = [];
    objects.forEach((object) => {
        objectsSelect.push(
            <Option key={object.idobject}
                    value={object.idobject}
                    label={object.shiffer}>
                {object.shiffer} {object.name}
            </Option>);
    })

    //useEffect(() => selectedObjects ? fetchObjectsTotals({objects: selectedObjects}) : null, [selectedObjects])

    let datasource = []
    let dataSourceTotals = []
    const dataSourceTotalsRows = ['Eesmärk', 'Teostatud', 'Järelejäänud', 'Lõpp-prognoos']

    const columns = [
        {title: '', dataIndex: 'title', key: 'title'},
        {
            title: 'Tulu',
            dataIndex: 'income',
            key: 'income',
            align: 'right',
            render: formatCurrency,
            className: 'projection_actual'
        },
        {
            title: 'Kulu',
            dataIndex: 'cost',
            key: 'cost',
            align: 'right',
            render: formatCurrency,
            className: 'projection'
        },
        {
            title: 'Kate',
            dataIndex: 'grossProfit',
            key: 'grossProfit',
            align: 'right',
            render: formatCurrency,
            className: 'projection_highlight'
        },
        {
            title: 'Üldkulu',
            dataIndex: 'overhead',
            key: 'overhead',
            align: 'right',
            render: formatCurrency,
            className: 'projection_final'
        },
        {
            title: 'Tulem',
            dataIndex: 'netProfit',
            key: 'netProfit',
            align: 'right',
            render: formatCurrency,
            className: 'projection_actual'
        },
        {
            title: 'Marginaal',
            dataIndex: 'margin',
            key: 'margin',
            align: 'right',
            render: text => text ? (text * 100).toFixed(0) + ' %' : '',
            className: 'projection'
        },
    ]

    if (subtotals) {

        subtotals.forEach(subtotal => {
            if (subtotals.length > 1) datasource.push({
                title: <div><b>{subtotal.shiffer} {subtotal.name.substr(0, 9)}</b></div>,
                income: null,
                cost: null,
                grossProfit: null,
                overhead: null,
                netProfit: null,
                margin: null
            })

            datasource.push({
                title: 'Eesmärk',
                income: subtotal.target_income_sum,
                cost: subtotal.target_cost_sum,
                grossProfit: subtotal.target_income_sum - subtotal.target_cost_sum,
                overhead: subtotal.target_overhead,
                netProfit: subtotal.target_net_profit,
                margin: subtotal.target_net_profit / subtotal.target_income_sum

            })
            datasource.push({
                title: 'Teostatud',
                income: subtotal.actual_income_sum,
                cost: subtotal.actual_cost_sum,
                grossProfit: subtotal.actual_income_sum - subtotal.actual_cost_sum,
                overhead: subtotal.actual_overhead,
                netProfit: subtotal.actual_net_profit,
                margin: subtotal.actual_net_profit / subtotal.actual_income_sum

            })
            datasource.push({
                title: 'Järelejäänud',
                income: subtotal.remaining_income_sum,
                cost: subtotal.remaining_cost_sum,
                //grossProfit: subtotal.remaining_income_sum - subtotal.remianing_cost_sum,
                grossProfit: subtotal.remaining_gross_profit,
                overhead: subtotal.remaining_overhead,
                netProfit: subtotal.remaining_net_profit,
                margin: subtotal.remaining_net_profit / subtotal.remaining_income_sum

            })
            datasource.push({
                title: 'Lõpp-prognoos',
                income: subtotal.prognosis_income_sum,
                cost: subtotal.prognosis_cost_sum,
                grossProfit: subtotal.prognosis_income_sum - subtotal.prognosis_cost_sum,
                overhead: subtotal.prognosis_overhead,
                netProfit: subtotal.prognosis_net_profit,
                margin: subtotal.prognosis_net_profit / subtotal.prognosis_income_sum

            })
        })
    }

    if (subtotals) {

        dataSourceTotals.push({
            title: <div><b>Kokku: </b></div>,
            income: null,
            cost: null,
            grossProfit: null,
            overhead: null,
            netProfit: null,
            margin: null
        })

        dataSourceTotalsRows.forEach(title => {
            dataSourceTotals.push({
                title: title,
                income: datasource.filter(r => r.title === title).reduce((sum, current) => sum + current.income, 0),
                cost: datasource.filter(r => r.title === title).reduce((sum, current) => sum + current.cost, 0),
                grossProfit: datasource.filter(r => r.title === title).reduce((sum, current) => sum + current.grossProfit, 0),
                overhead: datasource.filter(r => r.title === title).reduce((sum, current) => sum + current.overhead, 0),
                netProfit: datasource.filter(r => r.title === title).reduce((sum, current) => sum + current.netProfit, 0),
                margin: null

            })
        })
    }


    if (subtotals && subtotals.length > 1) datasource.push(...dataSourceTotals)

    async function handleObjectsSelect(e) {

        if (e.length > 0) {
            await setSelectedObjects(e)
            await fetchObjectsTotals({objects: e})
        } else {
            await setSelectedObjects([meta.idobject])
            await fetchObjectsTotals({objects: [meta.idobject]})
        }

    }

    return <div>
        {objectSelector ?
            <div className="d-flex justify-content-end mb-2 align-items-center">
                <div className="mr-2">Siia saaad lisada veel huvipakkuvaid objekte:</div>
                <Select
                    mode="multiple"
                    optionLabelProp="label"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Vali objekt(id)"
                    dropdownMatchSelectWidth={false}
                    defaultValue={selectedObjects}
                    onChange={e => handleObjectsSelect(e)}
                    className="mr-2"
                >
                    {objectsSelect}
                </Select>
            </div>
            :
            null
        }
        <Table
            className="mb-2"
            dataSource={showTotals === 'totalsonly' ? dataSourceTotals : datasource}
            //loading={!datasource}
            columns={columns}
            size="small"
            pagination={false}
            loading={loading}
        />
    </div>

}

export const PaProjection = (size = "small") => {

    const costs = useStoreState(state => state.object.costs)
    const meta = useStoreState(state => state.object.meta)
    const prognosis = useStoreState(state => state.object.prognosis)
    const totals = useStoreState(state => state.object.totals)

    const updatePrognosis = useStoreActions(actions => actions.object.apiUpdatePrognosis)
    const activatePrognosis = useStoreActions(actions => actions.object.apiActivatePrognosis)

    const strprognosis = useStoreState(state => state.object.structuredPrognosisData)

    //console.log("struc calc", strprognosis)


    // setting state - current confirmed prognosis date (status ===1)
    let activePrognosis = false
    let activePrognosisDate = false

    //console.log("ACTIVE DATE", moment(activePrognosisDate).format('DD.MM.YY'))

    prognosis.forEach((row) => {
        if (row.is_current === 1) {
            activePrognosis = true

            if (moment(activePrognosisDate || '1900-01-01').isBefore(row.date)) activePrognosisDate = moment(row.date).format('DD.MM.YYYY')
        }
    })

    //refactor all this to use store state

    // filtering out target costs (where assignments contain special_row meaning specialrow_idoffer has value
    let targetedCosts = {}

    Object.values(costs).forEach((cost) => {
        if ('assignments' in cost && cost.assignments.length > 0) {

            cost.assignments.forEach((ass) => {
                if (ass.specialrow_idoffer) {
                    if (ass.assignment_sum > 0) {
                        if (!(ass.specialrow_idoffer in targetedCosts)) {
                            targetedCosts[ass.specialrow_idoffer] = ass.assignment_sum
                            //console.log("TARGETEDCOSTinitial", ass.specialrow_idoffer, ass.assignment_sum)
                        } else {
                            targetedCosts[ass.specialrow_idoffer] += ass.assignment_sum
                            //console.log("TARGETEDCOSTadd", ass.specialrow_idoffer, ass.assignment_sum)
                        }
                    }
                }
            })
        }
    })

    let targetedcostcount = 0

    Object.values(costs).forEach((cost) => {
        if ('assignments' in cost && cost.assignments.length > 0) {

            cost.assignments.forEach((ass) => {
                if (ass.specialrow_idoffer) {
                    if (ass.assignment_sum > 0) {
                        if (!(ass.specialrow_idoffer in targetedCosts)) {
                            targetedcostcount++
                        } else {
                            targetedcostcount++
                        }
                    }
                }
            })
        }
    })


    //console.log("OBJECT VALUES COSTS", Object.values(costs), "targetedcostcount", targetedcostcount)
    //console.log("TARGETEDCOSTS", targetedCosts)

    //filtering out draft prognosis should there be any (status match is_current ==2)
    let draftPrognosis = prognosis.filter((row) => row.is_current === 2)


    function getCellReference(index, field) {
        return index.toString() + field
    }

    function handleUpdate(row, field, value) {
        // this now is based on the draft_id value which represents non-confirmed prognosis
        // it should be included with payload. If found, value will be updated, if not new row will be created

        //console.log("Updating, ", row, field, value)
        let payload =
            {
                obj_id: meta.idobject,
                offer_id: row.idoffer,
                id: row.draft_id,
                amount: row.draft_amount,
                price: row.draft_price,
            }
        payload[field] = value
        updatePrognosis(payload)
        setActiveCell(false)
    }

    function handleActivate() {
        activatePrognosis({obj_id: meta.idobject})
    }

    // prognosis statuses: 2-draft, 1-current, any other number - old

    const [activeCell, setActiveCell] = useState(false)

    let fullColumns = [
        //{
        //    title: 'offerid',
        //    dataIndex: 'idoffer',
        //    filter_key: 'Title',
        //    key: 'idoffer',
        //    fixed: 'left',
        //    //render: text => <a>{text}</a>,
        //    width: 100,
        //
        //},
        {
            title: '#',
            dataIndex: 'art_id',
            key: 'art_id',
            filter_key: 'Title',
            width: 80,
            fixed: 'left',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Grupp/bill',
            dataIndex: 'art_name',
            key: 'art_name',
            filter_key: 'Title',
            width: 300,
            fixed: 'left',
            render: (text, record, index) => record.is_special_bill_row ? (
                <div><EyeOutlined /><b> {text} </b></div>) : text
        },
        {
            title: 'Ühik',
            filter_key: 'Title',
            dataIndex: 'unit',
            width: 100,
            fixed: 'left',
            key: 'unit',

        },
        {
            title: 'Leping',
            filter_key: 'Leping',
            key: "contract_title",
            children: [
                {
                    title: 'Lepinguline maht',
                    dataIndex: 'hinnapakkumise_amount',
                    key: 'hinnapakkumise_amount',
                    align: 'right',
                    width: 100,
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""
                },
                {
                    title: 'Lepinguline ühikhind',
                    dataIndex: 'hinnapakkumise_unit_price',
                    key: 'hinnapakkumise_unit_price',
                    align: 'right',
                    width: 100,
                    render: (text, record, index) => {

                        if (record.is_special_bill_row === 1)
                            return (
                                <div style={{background: '#fafafa', padding: '5px'}}>
                                    {text}
                                </div>
                            )
                        else return (<div className="text-muted">{text ? text.toFixed(2) : ''} </div>)
                    }

                },

                {
                    title: 'Leping kokku',
                    key: 'hinnapakkumise_total',
                    dataIndex: 'hinnapakkumise_total',
                    align: 'right',
                    width: 100,
                    render: (text, record, index) => text ? text.toFixed(0).toString() : ""
                }]
        },
        {
            title: 'Akteerimiseks',
            filter_key: 'Akteerimiseks',
            key: "to_be_billed_title",
            children: [
                {
                    title: 'Planeeritud akteeritav maht',
                    dataIndex: 'akteerimiseks_amount',
                    key: 'akteerimiseks_amount',
                    align: 'right',
                    width: 90,
                    className: 'projection_actual',
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""
                },
                {
                    title: 'Akteeritav summa',
                    key: 'akteerimiseks_total',
                    dataIndex: 'akteerimiseks_total',
                    className: 'projection_actual',
                    align: 'right',
                    width: 90,
                    render: (text, record, index) => text ? text.toFixed(0).toString() : ""
                }]
        },
        {
            title: 'Kulueesmärk- eelarve',
            filter_key: 'Kulueesmärk',
            key: "target_title",
            children: [
                {
                    title: 'Ühikhind',
                    dataIndex: 'unit_price',
                    key: 'unit_price',
                    align: 'right',
                    width: 90,
                    render: (text, record, index) => {

                        if (record.is_special_bill_row === 1)
                            return (
                                <div style={{background: '#fafafa', padding: '5px'}}>
                                    {text ? text.toFixed(2) : ''}
                                </div>
                            )
                        else return (<div className="text-muted">{text ? text.toFixed(2) : ''} </div>)
                    }
                    //fixed: 'left',
                    //width: 200
                },
                {
                    title: 'Maht',
                    dataIndex: 'offered',
                    key: 'offered',
                    align: 'right',
                    width: 90,
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""
                },
                {
                    title: 'Kulueesmärk kokku',
                    key: 'offered_total',
                    dataIndex: 'offered_total',
                    align: 'right',
                    width: 90,
                    render: (text, record, index) => text ? text.toFixed(0).toString() : ""
                }]
        }, {
            title: 'Teostatud',
            filter_key: 'Teostatud',
            key: 'actual_title',
            //fixed: 'left',
            children: [{
                title: 'Ühikhind',
                dataIndex: 'unit_price',
                key: 'actual_unit_price',
                align: 'right',
                width: 150,
                className: 'projection_actual',
                render: (text, record, index) => <PaProjectionSpecialUnitPrice record={record}
                                                                               targetedCosts={targetedCosts}/>

            }, {
                title: 'Maht',
                dataIndex: 'fulfilled_amount',
                key: 'fulfilled_amount',
                align: 'right',
                width: 90,
                className: 'projection_actual',
                render: (text, record, index) => text ? text.toFixed(2) : ''// <div className="text-muted">0</div>
            }, {
                title: 'Teostatud kulud kokku',
                dataIndex: 'actual_cost_to_date',
                key: 'actual_cost_to_date',
                align: 'right',
                width: 110,
                className: 'projection_actual',
                render: (text, record, index) => {

                    if (record.is_special_bill_row === 1)
                        return (
                            <div>

                                {record.fulfilled_amount ? parseFloat(targetedCosts[record.idoffer]).toFixed(2) : 0}
                            </div>
                        )
                    else return record.actual_cost_to_date ? text.toFixed(2) : record.fulfilled_total ? record.fulfilled_total.toFixed(2) : 0
                }
            }, {
                title: 'Teostatud tulud kokku',
                dataIndex: 'actual_income_to_date',
                key: 'actual_income_to_date',
                align: 'right',
                width: 110,
                className: 'projection_actual',
                render: (text, record, index) => {
                    return record.actual_income_to_date ? text ? text.toFixed(2) : 0 : record.fulfilled_income_total ? record.fulfilled_income_total.toFixed(2) : 0
                }
            }, {
                title: 'Teostatud tulem',
                dataIndex: 'actual_cost_to_date',
                key: 'actual_result_to_date',
                align: 'right',
                width: 110,
                className: 'projection_actual',
                render: (text, record, index) => {

                    if (record.is_special_bill_row === 1)
                        return (

                            ((record.actual_income_to_date ? record.actual_income_to_date : record.fulfilled_income_total ? record.fulfilled_income_total : 0) - (record.fulfilled_amount ? targetedCosts[record.idoffer] : 0)).toFixed(2)

                        )

                    else return record.actual_income_to_date && record.actual_cost_to_date ?
                        (record.actual_income_to_date - record.actual_cost_to_date).toFixed(2)
                        :
                        record.fulfilled_total && record.fulfilled_income_total ?
                            (record.fulfilled_income_total - record.fulfilled_total).toFixed(2) : 0
                }
            },


                {
                    title: 'Valmis %',
                    key: 'diff',
                    className: 'projection_actual',
                    align: 'right',
                    width: 90,
                    render: (text, record, index) => {
                        if (record.offered_total) {
                            let value = undefined
                            if (record.actual_cost_to_date)
                                value = record.actual_cost_to_date / record.offered_total
                            else if (record.fulfilled_total)
                                value = record.fulfilled_total / record.offered_total
                            return (
                                <small>{value ? (value * 100).toFixed(0).toString() + ' %' : ''}
                                </small>)
                        } else return ''
                    }

                }, {
                    title: 'Järelejäänud maht',
                    key: 'remaining_amount',
                    dataIndex: 'remaining_amount',
                    className: 'projection_actual',
                    align: 'right',
                    width: 90,
                    render: (text, record, index) => text ? text.toFixed(0).toString() : ""
                }
            ]
        },
        {
            title: 'Lisa täitmise prognoos',
            filter_key: 'lisa_prognoos',
            key: "add_prognosis_title",
            children: [{
                title: 'Prognoositav ühikhind',
                key: 'draft_price',
                dataIndex: 'draft_price',
                width: 90,
                align: 'right',
                render: (text, record, index) =>
                    record.is_content_row ?
                        <div style={record.has_draft ? {background: '#f3f7c2', padding: '5px'} : {}}>
                            <InputCell
                                defaultValue={text ? text.toFixed(2) : ''}
                                onClick={() => {
                                    console.log("click", getCellReference(index, record.bill_name));
                                    setActiveCell(getCellReference(index, record.bill_name))
                                }}
                                onBlur={(e) => handleUpdate(record, 'price', e)}
                                onMoveUp={(value) => setActiveCell(getCellReference(index > 0 ? index + value : 0, record.bill_name))}
                                onMoveDown={(value) => setActiveCell(getCellReference(index < record.rowCount - 1 ? index + value : record.rowCount - 1, record.bill_name))}
                                onUpdate={(e) => handleUpdate(record, 'price', e)}
                                active={activeCell === getCellReference(index, record.bill_name)}
                            /></div> :
                        text

            }, {
                title: 'Prognoositav maht',
                key: 'draft_amount',
                dataIndex: 'draft_amount',
                align: 'right',
                width: 90,
                render: (text, record, index) =>
                    record.is_content_row ?
                        <div style={
                            record.has_draft ? {
                                background: '#f3f7c2',
                                padding: '5px',
                            } : {}
                        }
                        >
                            <InputCell
                                defaultValue={text}
                                onClick={() => {
                                    console.log("click", getCellReference(index, record.bill_name + 'amount'));
                                    setActiveCell(getCellReference(index, record.bill_name + 'amount'))
                                }}
                                onBlur={(e) => handleUpdate(record, 'amount', e)}
                                onMoveUp={(value) => setActiveCell(getCellReference(index > 0 ? index + value : 0, record.bill_name + 'amount'))}
                                onMoveDown={(value) => setActiveCell(getCellReference(index < record.rowCount - 1 ? index + value : record.rowCount - 1, record.bill_name + 'amount'))}
                                onUpdate={(e) => handleUpdate(record, 'amount', e)}
                                active={activeCell === getCellReference(index, record.bill_name + 'amount')}
                            /></div> :
                        text
            }, {
                title: 'Kulu prognoos kokku',
                key: 'draft_total',
                dataIndex: 'draft_total',
                align: 'right',
                width: 90,
                render: (text, record, index) =>
                    <div style={record.has_draft ? {background: '#f3f7c2', padding: '5px'} : {}}>
                        {text ? text.toFixed(0).toString() : ""}
                    </div>
            }, {
                title: 'Tulu prognoos kokku',
                key: 'draft_income_total',
                dataIndex: 'draft_income_total',
                align: 'right',
                width: 90,
                render: (text, record, index) =>
                    <div style={record.has_draft ? {background: '#f3f7c2', padding: '5px'} : {}}>
                        {text ? text.toFixed(0).toString() : ""}
                    </div>
            },
            ]
        },
        {
            //title: <div> {moment(activePrognosis).format('DD.MM.YYYY')} </div>,
            //title: activePrognosis ? "Prognoositud " +  activePrognosis.format("DD.MM.YYYY"): "Prognoosi pole",
            title: <span>Kehtiv prognoos <br/> objekti lõppedes</span>,
            key: 'prognosis_title',
            filter_key: 'hetke_prognoos',
            children: [
                {
                    title: 'Lõplik ühikhind',
                    dataIndex: 'prognosis_price',
                    key: 'projection_unit_price',
                    align: 'right',
                    width: 90,
                    className: 'projection',
                    render: (text, record, index) =>
                        <div style={record.has_prognosis ? {background: '#d9f7be', padding: '5px'} : {}}>
                            {text ? text.toFixed(2).toString() : ""}
                        </div>

                },
                {
                    title: 'Lõplik maht',
                    dataIndex: 'prognosis_amount',
                    key: 'prognosis_amount',
                    align: 'right',
                    width: 90,
                    className: 'projection ',
                    render: (text, record, index) =>
                        <div style={record.has_prognosis ? {background: '#d9f7be', padding: '5px'} : {}}>
                            {text ? text.toFixed(2).toString() : ""}
                        </div>
                },
                {
                    title: 'Kulu kokku',
                    key: 'cost_prognosis_total',
                    dataIndex: 'prognosis_total',
                    align: 'right',
                    width: 90,
                    className: 'projection ',
                    render: (text, record, index) =>
                        <div style={record.has_prognosis ? {background: '#d9f7be', padding: '5px'} : {}}>
                            {text ? text.toFixed(0).toString() : ""}
                        </div>
                },
                {
                    title: 'Tulu kokku',
                    key: 'income_prognosis_total',
                    dataIndex: 'income_prognosis_total',
                    align: 'right',
                    width: 90,
                    className: 'projection ',
                    render: (text, record, index) =>
                        <div style={record.has_prognosis ? {background: '#d9f7be', padding: '5px'} : {}}>
                            {text ? text.toFixed(0).toString() : ""}
                        </div>
                }, {
                    title: 'Tulem',
                    key: 'result_prognosis_total',
                    dataIndex: 'result_prognosis_total',
                    align: 'right',
                    width: 90,
                    className: 'projection ',
                    render: (text, record, index) =>
                        <div style={record.has_prognosis ? {background: '#d9f7be', padding: '5px'} : {}}>
                            {text ? text.toFixed(0).toString() : ""}
                        </div>
                }

            ]
        },
    ];

    const filters = [
        //{val: 'Title', title: "Uued"},
        {val: 'Leping', title: "Leping"},
        {val: 'Akteerimiseks', title: "Akteerimiseks"},
        {val: 'Kulueesmärk', title: "Kulueesmärk"},
        {val: 'Teostatud', title: "Teostatud"},
        {val: 'lisa_prognoos', title: "Lisa prognoos"},
        {val: 'hetke_prognoos', title: "Kehtiv prognoos"},
    ]

    const [activeColumns, setActiveColumns] = useState(['Title', 'Leping', 'Akteerimiseks', 'Kulueesmärk', 'Teostatud', 'lisa_prognoos', 'hetke_prognoos'])

    // if no previous prognosis, removing columns to show
    if (!activePrognosis) fullColumns = fullColumns.filter((col) => col.title !== 'Eelmine prognoos')
    fullColumns = fullColumns.filter((col) => activeColumns.includes(col.filter_key))

    const handleCheck = (checked, val) => {
        let current = activeColumns
        checked ? current = [val, ...current] : current = current.filter(v => v !== val)
        setActiveColumns(current)
    }

    return (
        <div style={{background: '#fff'}} className="m-1 p-1 mt-2">
            <div style={{background: '#f9f9f9'}}>
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand>Prognoos </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {/*  <Dropdown overlay={menu}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    Vaata eelmisi prognoose <Icon type="down"/>
                                </a>
                            </Dropdown>
                            <Nav.Link href="#link">Link</Nav.Link> */}
                        </Nav>
                        <Form inline>
                            <span>{activePrognosisDate ? 'Viimane kinnitatud prognoos: ' + activePrognosisDate : ''} </span>
                            {draftPrognosis.length > 0 ?
                                <Button type='primary' className="mr-2 ml-2" onClick={() => handleActivate()}>Kinnita
                                    prognoos</Button>
                                : null
                            }
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <div className="m-2">
                <Row>
                    <Col lg={6} md={12} sm={24}>
                        <Card title="Planeeritud" style={{minHeight: 370}}>
                            <Row>
                                <Col span={12}>
                                    <Statistic title="Lepingu maht" value={totals.offeredToClientTarget} precision={0}/>

                                </Col>
                                <Col span={12}>
                                    <Statistic title="Akteeritav maht" value={totals.akteeritavToClientTarget}
                                               precision={0}/>
                                </Col>
                                <Col span={12}><Statistic title="Kulu eesmärkeelarve" value={totals.costTarget}
                                                          precision={0}/>
                                </Col>
                                <Col span={12}><Statistic title="Tulem (ilma üldkuluta)"
                                                          value={totals.akteeritavToClientTarget - totals.costTarget}
                                                          precision={0}/>
                                </Col>

                                {/* //value={((totals.offeredToClientTarget - totals.costTarget) / totals.akteeritavToClientTarget * 100)}  */}

                                <Col span={12}><Statistic title="Brutomarginaal (ilma üldkuluta)"
                                                          value={(totals.grossMarginTarget * 100).toFixed(2)}
                                                          suffix="%"
                                                          precision={2}/>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={6} md={12} sm={24}>
                        <Card title="Teostatud" style={{minHeight: 370}} extra={
                            <div style={{width: 130}}>
                                <Progress size="small"
                                          percent={(totals.costsToDate / totals.akteeritavToClientTarget * 100).toFixed(0)}/>
                            </div>}>
                            <Row gutter={8}>
                                <Col span={12}>
                                    <Statistic title="Teostatud kulud kokku (reaalsed)" value={totals.costsToDate}
                                               precision={0}/>

                                </Col>
                                <Col span={12}>
                                    <Statistic title="Peaks olema kulud kokku (eesmärkeelarves)"
                                               value={totals.costsAsTargetPriceToDate} precision={0}/>
                                </Col>
                                <Col span={12}>
                                    <Statistic title="Teostatud tulud kokku" value={totals.incomeToDate} precision={0}/>
                                </Col>

                                <Col span={12}>
                                    <Statistic title="Teostatud tulem" value={totals.resultToDate} precision={0}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}><Statistic title="Brutomarginaal (ilma üldkuluta)"
                                                          value={totals.resultToDate / totals.incomeToDate * 100}
                                                          suffix="%"
                                                          precision={2}/>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={6} md={12} sm={24}>
                        <Card title="Järel" style={{minHeight: 370, background: '#e6f7ff'}}>
                            <Row gutter={8}>
                                {/* <Col span={12}>
                                    <Statistic title="Kulu reaalselt "
                                               value={totals.costTarget - totals.costsToDate}
                                               precision={0}/>

                                </Col> */}
                                <Col span={12}>
                                    <Statistic title="Kulu vastavalt prognoosile "
                                               value={strprognosis.reduce((total, row) => row.draft_total + total, 0) - totals.costsToDate}
                                               precision={0}/>
                                </Col>
                                <Col span={12}>
                                    <Statistic title="Kulu vastavalt eesmärk eelarvele"
                                               value={totals.costTarget - totals.costsAsTargetPriceToDate}
                                               precision={0}/>
                                </Col>

                                <Col span={12}>
                                    <Statistic title="Tulu vastavalt prognoosile"
                                               value={strprognosis.reduce((total, row) => row.draft_income_total + total, 0) - totals.incomeToDate}
                                               precision={0}/>
                                </Col>

                                <Col span={12}>
                                    <Statistic title="Tulu vastavalt akteeritavale"
                                               value={totals.akteeritavToClientTarget - totals.incomeToDate}
                                               precision={0}/>
                                </Col>

                                <Col span={12}>
                                    <Statistic title="Tulem vastavalt prognoosile"
                                               value={(strprognosis.reduce((total, row) => row.draft_income_total + total, 0) - totals.incomeToDate) - (strprognosis.reduce((total, row) => row.draft_total + total, 0) - totals.costsToDate)}
                                               precision={0}/>
                                </Col>
                                <Col span={12}>
                                    <Statistic title="Marginaal (prognoositav)"
                                               suffix="%"
                                               value={((strprognosis.reduce((total, row) => row.draft_income_total + total, 0) - totals.incomeToDate) - (strprognosis.reduce((total, row) => row.draft_total + total, 0) - totals.costsToDate)) / (totals.akteeritavToClientTarget - totals.incomeToDate) * 100}
                                               precision={2}/>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={6} md={12} sm={24}>
                        <Card title="Prognoos" style={{minHeight: 370, background: '#f6ffed'}}
                              extra={
                                  draftPrognosis.length > 0 ?
                                      <Tag color="gold"> Mustand </Tag>
                                      : <Tag> Kehtiv </Tag>
                              }
                        >
                            <Row>
                                <Col span={12}>
                                    <Statistic
                                        title="Kulu prognoos"
                                        value={strprognosis.reduce((total, row) => row.draft_total + total, 0)}
                                        precision={0}
                                    />

                                </Col>
                                <Col span={12}>
                                    <Statistic title="Tulu prognoos"
                                               value={strprognosis.reduce((total, row) => row.draft_income_total + total, 0)}
                                               precision={0}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Statistic
                                        title="Tulemi prognoos"
                                        value={(strprognosis.reduce((total, row) => row.draft_income_total + total, 0)) - (strprognosis.reduce((total, row) => row.draft_total + total, 0))}
                                        precision={0}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}><Statistic title="Brutomarginaal (ilma üldkuluta)"
                                                          value={((strprognosis.reduce((total, row) => row.draft_income_total + total, 0)) - (strprognosis.reduce((total, row) => row.draft_total + total, 0))) / (strprognosis.reduce((total, row) => row.draft_income_total + total, 0)) * 100}
                                                          suffix="%"
                                                          precision={2}/>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    {/* <Col span={5}>
                        <Card title="Kehtiv prognoos" style={{minHeight: 250}}>
                            <Row>
                                <Col span={12}>
                                    <Statistic
                                        title="Kulu prognoos"
                                        value={strprognosis.reduce((total, row) => row.prognosis_total + total, 0)}
                                        precision={0}
                                    />

                                </Col>
                                <Col span={12}>
                                    <Statistic title="Tulu prognoos"
                                               value={strprognosis.reduce((total, row) => row.income_prognosis_total + total, 0)}
                                                precision={0}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Statistic
                                        title="Tulemi prognoos"
                                        value={(strprognosis.reduce((total, row) => row.income_prognosis_total + total, 0)) - (strprognosis.reduce((total, row) => row.prognosis_total + total, 0))}
                                    precision={0}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                </Row>

            </div>
            <div className="m-2 p-3"> Näita veerge: {filters.map(f =>
                <CheckableTag key={f.val} checked={activeColumns.includes(f.val)}
                              onChange={checked => handleCheck(checked, f.val)}>{f.title}</CheckableTag>
            )}
            </div>
            <div className="mt-1"> {/* style={{overflowX: 'auto', overflowY: 'auto'}}> */}
                <Table columns={fullColumns}
                       rowKey={(record) => record.idoffer}
                    //dataSource={structuredPrognosisData}
                       dataSource={strprognosis}
                       defaultExpandAllRows={true}
                    //rowClassName={(record, index) => {
                    //    let className = ''
                    //    if ('children' in record) className += " projection_titlerow"
                    //    if (record.is_special_bill_row === 1) className += ' projection_highlight'
                    //    return className
                    //}}
                       size="middle"
                       indentSize={0}
                    //scroll={{y: '75vh', x: '2000'}}
                       scroll={{x: '2000'}}
                    //footer={() => <div>
                    //    <b>Summad</b> Leping: {totals.offeredToClientTarget} Akt: {totals.akteeritavToClientTarget} Kulueesmärk: {totals.costTarget} Teostatud
                    //    kulu: {totals.costsToDate} Teostatud tulu:
                    //    {totals.incomeToDate} Teostatud tulem: {totals.resultToDate} Prognoos
                    //    kulu: {totals.prognosedCost} Prognoos tulu: {totals.prognosedIncome} Prognoos
                    //    tulem: {totals.prognosedResult} </div>}

                />
            </div>
        </div>
    )
}

export default PaProjection