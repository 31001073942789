import React from "react"
import Cookies from "js-cookie"
import { UploadOutlined } from '@ant-design/icons'
import { Upload, notification } from "antd"
import { s3, getS3FileURL } from '../../../utils/aws'
import { v4 as uuid } from 'uuid'
import axios from 'axios'

import '../../../App.css'

import { useStoreActions, useStoreState } from "easy-peasy"
import API, { URL as PaURL } from "../../../services/api"

const { Dragger } = Upload

export const PaUploadOffer = () => {
  let url = PaURL()
  const meta = useStoreState(state => state.object.meta)
  const loadObjectOffers = useStoreActions(actions => actions.offers.apiLoadOffers)
  const loadBudgets = useStoreActions(actions => actions.budgets.apiFetchBudgets)
  const fetchObjectOffers = useStoreActions(actions => actions.object.apiFetchObjectOffers)
  const obj_id = meta.idobject

  const actionURL = meta.costing_version === 2 ? url + '/api/v2/upload' : url + '/api/offer/upload'

  const props = {
    name: 'file',
    multiple: true,
    action: actionURL,
    beforeUpload: async (file) => {
      const uploadUuid = uuid()
      const fileName = file.name
      const presignedS3Url = s3.getSignedUrl('putObject', {
        Bucket: 'fieldworks-uploads',
        Key: `budgets/${uploadUuid}-${fileName}`,
        ContentType: file.type
      })
      const { pathname } = new URL(presignedS3Url)
      const res = await axios.put(presignedS3Url, file, {
        headers: {
          'Content-Type': file.type,
        }
      })
      if (res) {
        console.log({ res, pathname })
        await API("/api/aws", false, 'post', { idobject: obj_id, name: fileName, path: pathname })
        fetchObjectOffers(obj_id)
      }
      return true
    },
    headers: {
      'Authorization': "Bearer " + Cookies.get('jwt'),
      'objid': obj_id
    },
    onChange(info) {
      console.log("info:", info)
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        //message.success(`${info.file.name} file uploaded successfully.`);
        notification.success(
          {
            placement: 'topRight',
            message: `${info.file.name} edukalt üles laetud ja töödeldud.`,
            duration: 15,
          }
        )
        loadObjectOffers({ obj_id: obj_id })
        loadBudgets()

      } else if (status === 'error') {
        //message.error(`${info.file.name} file upload failed.`);
        //alert(`${info.file.name} üles laadimine ei õnnestunud. Kas on sama nimega fail juba üles laetud või on excel vigane.`);
        console.log("file", info.file)
        notification.error(
          {
            placement: 'topRight',
            message: <div>{info.file.name} üles laadimine ei õnnestunud. {info.file.response ? info.file.response : ''} </div>,
            duration: 0,
          }
        )
      }
    },
  }

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <UploadOutlined />
      </p>
      <p className="ant-upload-text">Kliki <b>siia</b><br /> või lohista <b>eesmärk eelarve või
                lisaeelarve</b><br /> excel
                üles laadimiseks sellesse kasti</p>
      <p className="ant-upload-hint">
        Eelarve seotakse kohe selle objektiga
      </p>
    </Dragger>
  )
}

