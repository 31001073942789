import React, { useEffect, useState } from "react"
import PaObjectsStatus from '../components/PaObjectsStatus'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Typography, Col, Row, Tooltip, Tag, Table } from "antd"
import ProTable from '@ant-design/pro-table'
import '../App.css'
import { useHistory } from "react-router"
import { useStoreActions, useStoreState } from "easy-peasy"
import { PaObjectsMap } from '../pages/objects'
import { PaObjectTotalsV2 } from "../components/object/projections/Projection"
import gravatar from "gravatar"
import { smartSearch } from "../services/smartSearch"
import FilterMenu from '../components/FilterMenu'
import { useFilters } from '../utils/hooks'
import { Statuses } from '../helpers/statuses'
import moment from 'moment'

const { Title } = Typography

let accountDropdownProps = {
  avatarURL: '/images/logo.png',
  name: '',
  description: 'Administrator',
}

export const SmallObjectsOverview = ({ objectFilter = [3, 4, 5, 6, 7], loading: loadingObjects, search = '' }) => {
  const user = useStoreState(state => state.user.me)
  const allObjects = useStoreState(state => state.objects.objects)
  const fetchObjectsTotals = useStoreActions(actions => actions.resultV2.apiFetchObjectsTotals)
  const objectsTotals = useStoreState(state => state.resultV2.objectsTotals)

  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useFilters('small')

  if (user && user.id) {
    accountDropdownProps.name = `${user.name} ${user.surname}`
    accountDropdownProps.email = `${user.username}`

    accountDropdownProps.description = `${user.position_name}`
    accountDropdownProps.avatarURL = gravatar.url(user.username, { d: '404' })
  }

  const searchColumns = ["costing_version", "shiffer", "proj_m_name", "proj_manager_name", "end_planned", "name"]

  const objectsIds = smartSearch(allObjects
    .filter(smallObject => smallObject.is_small_object === 1 && objectFilter.includes(smallObject.status)), search, searchColumns)
    .filter(smallObject => {
      if (filters?.filtered?.costing_version && filters?.filtered?.costing_version.length > 0) {
        return filters?.filtered?.costing_version.includes(smallObject.costing_version)
      }
      return true
    })
    .filter(smallObject => {
      if (filters?.filtered?.obj_m_name && filters?.filtered?.obj_m_name.length > 0) {
        return filters?.filtered?.obj_m_name.includes(smallObject.obj_m_name)
      }
      return true
    })
    .filter(smallObject => {
      if (filters?.filtered?.proj_m_name && filters?.filtered?.proj_m_name.length > 0) {
        return filters?.filtered?.proj_m_name.includes(smallObject.proj_m_name)
      }
      return true
    })
    .filter(smallObject => {
      const dates = filters?.filtered?.dateTimes
      if (dates && dates.length > 0) {
        return moment(smallObject.end_planned).isBetween(dates[0], dates[1], 'day', '[]')
      }
      return true
    })
    .map(smallObject => smallObject.idobject)

  let dataSource = []
  if (objectsTotals) dataSource = smartSearch(objectsTotals.filter(r => objectFilter.includes(r.status)), search, searchColumns)

  console.log('datasource', dataSource, objectsIds)

  useEffect(() => {
    (async () => {
      if (objectsIds) {
        //await loadObjects()
        setLoading(true)
        await fetchObjectsTotals({ objects: objectsIds })
        setLoading(false)
      }

    })()
    // eslint-disable-next-line
  }, [objectsIds.length])


  return (
    <div style={{ padding: 24 }}>
      <Row>
        <Col span={24}>
          <div><Title level={4}>Kokkuvõte ja asukohad</Title> Väikeobjektide koondtulem.
          </div>
          <div className="d-flex">
            <div style={{ width: '67%' }}>
              <PaObjectTotalsV2 showTotals="totalsonly" loading={loading} />
            </div>
            <PaObjectsMap
              dataSource={dataSource}
              className="pb-2 ml-2"
              style={{ width: '33%' }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="mt-2">
            <div><Title level={4}>Väikeobjektid</Title></div>
            <PaSmallObjectsOverviewTable
              dataSource={dataSource}
              className={"table-striped-rows"}
              handleEdit={() => null}
              loading={loadingObjects}
              pagination={{
                defaultPageSize: 100,
                showSizeChanger: false,
                onChange: (page, pageSize) => console.log("page: ", page, "pagesize: ", pageSize),
                onShowSizeChange: (current, size) => console.log("page: ", current, "pagesize: ", size)
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}


const PaSmallObjectsOverviewTable = ({ dataSource, className = false, tableName = 'small', size = 'middle', pagination, handleEdit, loading = false }) => {
  const fetchUsers = useStoreActions(actions => actions.users.fetch)
  const fetchFilters = useStoreActions(actions => actions.filters.fetchFilters)
  const users = useStoreState(state => state.users.list)

  moment.locale('et')

  useEffect(() => {
    fetchFilters()
  }, [])

  const [filters, setFilters] = useFilters(tableName)
  const [columnsStateMap, setColumnsStateMap] = useState(filters?.columns ?? {})

  useEffect(() => {
    fetchUsers()
  }, [])

  const filteredUsers = users.map(({ user_name, user_surname }) => ({ text: `${user_name} ${user_surname}`, value: `${user_name} ${user_surname}` }))

  let history = useHistory()

  const formatCurrency = (text) => {

    const nodata = 0
    if (text) {
      if (typeof text == 'number') return text.toLocaleString('et-ee', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
      else return text
    }
    return nodata.toLocaleString('et-ee', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
  }

  const columns = [
    // {
    //     title: "#",
    //     dataIndex: "idobject",
    //     key: "idobject",
    // },
    //{
    //    title: () => <Icon type="star"/>,
    //    dataIndex: "idobject",
    //    key: "isFav",
    //    //sorter: (a, b) => a.status - b.status,
    //    //render: idobject => <PaObjectsFavourite idobject={idobject}/>,
    //    width: 30
    //
    //},
    {
      title: <LegacyIcon type="file-text" style={{ color: 'green' }} />,
      dataIndex: "status",
      key: "status",
      filters: Object.keys(Statuses).map(id => ({ text: Statuses[id], value: id })),
      filteredValue: filters?.filtered?.status || null,
      onFilter: (value, record) => {
        if (record) return record.status === Number(value)
      },
      sorter: (a, b) => a.status - b.status,
      render: status => <PaObjectsStatus status={status} />
    },
    {
      title: <Tooltip title="Kuluarvestuse versioon: 1 - kuni 2020, 2 - alates 2021">Ver</Tooltip>,
      dataIndex: "costing_version",
      key: "costing_version",
      filters: [{ text: 'Vana', value: 1 }, { text: 'Uus', value: 2 }],
      filteredValue: filters?.filtered?.costing_version || null,
      onFilter: (value, record) => {
        if (record) return record.costing_version === value
      },
      sorter: (a, b) => a.costing_version - b.costing_version,
      render: costing_version => <Tooltip title={"Kuluarvestuse loogika  " + costing_version === 1 ? " kuni 2020" : " alates 2021"}> {costing_version === 1 ? <Tag color="gold">1</Tag> : <Tag color="blue">2</Tag>} </Tooltip>

    },
    {
      title: "Šiffer",
      dataIndex: "shiffer",
      key: "shiffer",
      sorter: (a, b) => a.shiffer.localeCompare(b.shiffer),


    }, {
      title: "Nimi",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, index) => (
        <a
          onClick={() => history.push("/object/" + record.idobject)}
        //onClick={() => (<Redirect to={"/object/"+record.idobject}/>)}
        >
          {record.restricted === 1 ? <LegacyIcon type="lock" style={{ color: 'red' }} /> : null} <b>{record.name}</b>
          <br />
          <small>{record.address} </small>
        </a>
      )
    },
    {
      title: "Algus",
      dataIndex: "begin_planned",
      key: "begin_planned",
      sorter: (a, b) => moment(a.begin_planned).unix() - moment(b.begin_planned).unix(),
      render: (text, record, index) => (<div>{moment(record.begin_planned).format("DD.MM.YYYY")}</div>)
    },
    {
      title: "Tähtaeg",
      dataIndex: "end_planned",
      key: "end_planned",
      sorter: (a, b) => moment(a.end_planned).unix() - moment(b.end_planned).unix(),
      render: (text, record, index) => (<div>{moment(record.end_planned).format("DD.MM.YYYY")}</div>)
    },
    {
      title: "Objektijuht",
      dataIndex: "obj_m_name",
      key: "obj_m_name",
      filters: filteredUsers,
      filteredValue: filters?.filtered?.obj_m_name || null,
      onFilter: (value, record) => {
        if (record) return record.obj_m_name === value
      },
      sorter: (a, b) => (a.obj_m_name ?? '').localeCompare(b.obj_m_name ?? ''),
      render: (text, record, index) => (<div>{record.obj_m_name} <br />
        <small>{record.obj_m_phone} </small>
      </div>)
    },
    {
      title: "Projektijuht",
      dataIndex: "proj_m_name",
      key: "proj_m_name",
      filters: filteredUsers,
      filteredValue: filters?.filtered?.proj_m_name || null,
      onFilter: (value, record) => {
        if (record) return record.proj_m_name === value
      },
      sorter: (a, b) => (a.proj_m_name ?? '').localeCompare(b.proj_m_name ?? ''),
      render: (text, record, index) => (<div>{record.proj_m_name} <br />
        <small>{record.proj_m_phone} </small>
      </div>)
    },
    {
      title: "Tegelik seis",
      dataIndex: "idobject",
      key: "actual_state",
      children: [
        {
          title: "Tulu",
          dataIndex: "actual_income_sum",
          key: "actual_income_sum",
          className: 'projection_actual',
          align: 'right',
          sorter: (a, b) => a.actual_income_sum - b.actual_income_sum,
          render: formatCurrency
        },
        {
          title: "Kulu",
          dataIndex: "actual_cost_sum",
          key: "actual_cost_sum",
          className: 'projection',
          align: 'right',
          sorter: (a, b) => a.actual_cost_sum - b.actual_cost_sum,
          render: formatCurrency
        }, {
          title: "Kate",
          dataIndex: "actual_gross_profit",
          key: "actual_gross_profit",
          align: 'right',
          className: 'projection_highlight',
          sorter: (a, b) => a.actual_gross_profit - b.actual_gross_profit,
          render: (text, record, index) => ({
            props: {
              className: text < 0 ? 'projection_highlight_bad' : 'projection_highlight_good'

            },
            children: formatCurrency(text)
          })
        }, {
          title: "Üldkulud",
          dataIndex: "actual_overhead",
          key: "actual_overhead",
          className: 'projection_final',
          align: 'right',
          sorter: (a, b) => a.actual_overhead - b.actual_overhead,
          render: formatCurrency
        }, {
          title: "Tulem",
          dataIndex: "actual_net_profit",
          key: "actual_net_profit",
          align: 'right',
          className: 'projection_actual',
          sorter: (a, b) => a.actual_net_profit - b.actual_net_profit,
          //render: text => text ? formatCurrency(text) : 0
          render: (text, record, index) => ({
            props: {
              className: text < 0 ? 'projection_highlight_bad' : 'projection_highlight_good'

            },
            children: formatCurrency(text)
          })
        }, {
          title: "%",
          dataIndex: "actual_net_profit",
          key: "actual_margin",
          align: 'right',
          className: 'projection',
          //sorter: (a, b) => a.actual_net_profit - b.actual_net_profit,
          //render: text => text ? formatCurrency(text) : 0
          render: (text, record, index) => ({
            props: {
              className: (record.actual_net_profit / record.actual_income_sum) < 0 ? 'projection_highlight_bad' : 'projection_highlight_good'

            },
            children: !Number.isNaN(record.actual_net_profit / record.actual_income_sum) ? (record.actual_net_profit / record.actual_income_sum * 100).toFixed(2) + "%"
              :
              null
          })
        }
      ]
    },
  ]

  //const searchColumns = ["name", "shiffer", "user_positions_name", "proj_m_name",]

  return <>
    <FilterMenu
      filters={[
        ...columns.filter(c => c.filters && c.filters.length),
        {
          title: 'Ajavahemik',
          key: 'dateTimes',
          filters: 'datetime',
          filteredValue: filters?.filtered?.dateTimes || null,
        },
      ]}
      onChange={filterValues => setFilters({ ...filters, filtered: filterValues })}
    />
    <div className="smallOverviewTable">
      <ProTable
        columns={columns}
        loading={loading}
        pagination={pagination}
        dataSource={filters?.filtered?.dateTimes && filters?.filtered?.dateTimes.length > 0 ? dataSource.filter(({ begin_planned, end_planned }) => {
          const dates = filters.filtered.dateTimes
          return moment(begin_planned).isBetween(dates[0], dates[1], 'day', '[]') || moment(end_planned).isBetween(dates[0], dates[1], 'day', '[]')
        }) : dataSource}
        options={{
          search: false,
        }}
        rowKey="idobject"
        columnsStateMap={columnsStateMap}
        onColumnsStateChange={(map) => {
          setColumnsStateMap(map)
          setFilters({ columns: map })
        }}
        search={false}
        dateFormatter="string"
      />
    </div>
  </>

}

export default SmallObjectsOverview



