
// the component that displays icon and popover for cost row. Records must be single record

import {useState} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import CostDivisionContext from "../../../../CostDivisionContext";
import { PieChartOutlined } from '@ant-design/icons';
import {Button,  Popover, Tag, Tooltip} from "antd";
import React from "react";
import makeAssignment from "../makeAssignment"
import PaCostBillDivisionContent from "./costBillDivisionContent"


// the component for setting assignments for the whole group at the time
const PaGroupBillDivision = ({records, costType}) => {

    const [totalAssigned, setTotalAssigned] = useState(0)
    //const baseBudget = useStoreState(state => state.object.budget)
    const meta = useStoreState(state => state.object.meta)
    const assign = useStoreActions(actions => actions.object.apiSetAssignment)
    const apiLoadCosts = useStoreActions(actions => actions.object.apiLoadCosts)
    //const apiLoadBudget = useStoreActions(actions => actions.object.apiLoadBudget)
    const editAllowed = useStoreState(state => state.user.editAllowed)

    const [loendid, setLoendid] = useState([])
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)


    //useEffect(() => {
    //    let myBudget = baseBudget
    //        .filter((row) => row.cost_type === costType)
    //        .map((row) => ({
    //            id: row.id,  // budget row id
    //            name: row.bills_types_name,
    //            assigned: 0,
    //            special_name: row.art_name
    //        }))
    //    //setLoendid(myBudget)
    //}, [baseBudget])

    const handleVisibleChange = (value) => setVisible(value)

    const handleSubmit = async () => {
        await setLoading(true)
        await assign(makeAssignment(loendid, records))

        await setVisible(false)
        await apiLoadCosts(meta.idobject)
        //await apiLoadBudget(meta.idobject)
        await setLoading(false)

    }

    return (
        <CostDivisionContext.Provider value={{totalAssigned, setTotalAssigned, loendid, setLoendid}}>

            <Popover placement="left"
                     visible={visible}
                     onVisibleChange={handleVisibleChange}
                     title={
                         <div className="m-2 d-flex justify-content-between align-content-center">
                             <div>Jaotus loendite vahel</div>

                             {totalAssigned === 100 ?
                                 <div>
                                     <Button
                                         disabled={totalAssigned !== 100}
                                         onClick={handleSubmit}
                                     >{totalAssigned < 100 ? totalAssigned.toFixed(3) + "%" : "Salvesta"}</Button>
                                 </div>
                                 :
                                 <div className="d-flex align-content-center">
                                     <div className="align-content-center">
                                         <small className="text-muted mr-2">Vajad salvestamiseks 100%</small>
                                         <Tag color="geekblue">{totalAssigned.toFixed(0)} %</Tag>
                                     </div>
                                 </div>
                             }

                         </div>}
                     content={<PaCostBillDivisionContent records={records} loading={loading} costType={costType}/>}
                     trigger="click">
                <Tooltip title="Määra bill kogu kulugrupile">
                    <Button icon={<PieChartOutlined />} shape="circle" className="mr-1" disabled={!editAllowed}></Button>
                </Tooltip>
            </Popover>

        </CostDivisionContext.Provider>
    );
}

export default PaGroupBillDivision