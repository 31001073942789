import React from 'react'
import { Modal, Button } from 'antd'

import ActParametersForm from './ActParametersForm'

const ActModal = ({ visible, loading, onSave, onCancel, ...formProps }) => {
  return (
    <Modal
      visible={visible}
      title={'Lisa uus akt'}
      okText={'Lisa'}
      onCancel={onCancel}
      onOk={onSave}
      footer={[
        <Button key="back" onClick={onCancel}>
          Tühista
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onSave}>
          Loo akt
        </Button>
      ]}
    >
      <ActParametersForm {...formProps} />
    </Modal>
  )
}

export default ActModal