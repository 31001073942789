import React, { useEffect, useState, useContext, createRef } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Table, Button, Row, Col, Modal, Divider } from 'antd'
import { DeleteOutlined, PlusCircleOutlined, ExclamationCircleOutlined, FileExcelOutlined, DownloadOutlined } from '@ant-design/icons'
import moment from 'moment'
import InputCell from '../../InputCell'
import Text from 'antd/lib/typography/Text'
import CostOptionsContext from '../../CostOptionsContext'
import { UploadReport } from './Upload'
import { getS3FileURL } from '../../../utils/aws'
import ReportForm from './ReportForm'

const { confirm } = Modal

const showConfirm = (deleteRow) => {
  confirm({
    title: 'Oled sa kindel, et soovid rea kustutada?',
    icon: <ExclamationCircleOutlined />,
    //content: 'Some descriptions',
    onOk() {
      console.log('OK')
      deleteRow()
    },
    onCancel() {
      console.log('Cancel')
    }
  })
}

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name
  })
}

const getCellReference = ({ index, field, horizontal = false, vertical = false, rows, taborder = [] }) => {
  //console.log("index, field, horizontal, vertical", index, field, horizontal, vertical)
  if (vertical === -1 && index > 0) return (index - 1).toString() + field // up
  if (vertical === 1 && index < rows - 1) return (index + 1).toString() + field // down

  let horizontal_index = taborder.indexOf(field)
  let horizontal_max = taborder.length - 1

  if (horizontal_index > -1) {
    //value actually exists
    if (horizontal === -1 && horizontal_index > 0) return index.toString() + taborder[horizontal_index - 1] // left
    if (horizontal === 1 && horizontal_index < horizontal_max) return index.toString() + taborder[horizontal_index + 1] // right
    if (horizontal === -1 && horizontal_index === 0 && index > 0) return (index - 1).toString() + taborder[horizontal_max] // left
    if (horizontal === 1 && horizontal_index === horizontal_max && index < rows - 1) return false //(index + 1).toString() + taborder[0] // right end of row reached
    if (horizontal === 1 && horizontal_index === horizontal_max && index === rows - 1) return false // end of table right bottom, nothing selected
  }

  return index.toString() + field
}

const inputTypes = {
  number: 'number',
  string: 'string',
  date: 'date'
}

const AsfalteerimineTable = () => {
  const [activeReport, setActiveReport] = useState()
  return activeReport ? (
    <AsphaltReport activeReport={activeReport} setActiveReport={setActiveReport} />
  ) : (
    <AsphaltTable setActiveReport={setActiveReport} />
  )
}

const AsphaltReport = ({ setActiveReport, activeReport }) => {
  const user = useStoreState((state) => state.user.me)
  const userId = user?.id

  const exportReport = useStoreActions((actions) => actions.asphalt.export)
  const updateReport = useStoreActions((actions) => actions.asphalt.updateGeneral)

  const [visible, setVisible] = useState(false)
  console.log(activeReport)
  return (
    <>
      <Row style={{ marginBottom: 18 }} gutter={4}>
        <Button
          type="secondary"
          onClick={() => {
            setActiveReport()
          }}>
          {`< Tagasi`}
        </Button>
      </Row>
      <Row justify="end" gutter={4} align="middle">
        <Col flex="1" style={{ fontSize: 18, fontWeight: 400 }}>
          <Text>Asfaldi vahetuse aruanne nr.{activeReport?.number}</Text>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true)
            }}>
            Täida üldandmed
          </Button>
        </Col>
        <Col>
          <Button
            onClick={(e) => {
              exportReport(activeReport?.id)
            }}
            icon={<FileExcelOutlined style={{ color: 'green' }} />}>
            Lae vorm alla
          </Button>
        </Col>
        <Col>
          <Divider type="vertical" />
        </Col>
        <Col>
          {activeReport?.filename ? (
            <Button>
              <a target="_blank" rel="noopener noreferrer" href={getS3FileURL(activeReport?.filename)}>
                <DownloadOutlined /> Lae aruanne alla
              </a>
            </Button>
          ) : (
            <Text style={{ color: 'red' }}>Aruanne puudub</Text>
          )}
        </Col>
      </Row>
      <Row style={{ marginBottom: 18 }} gutter={4}>
        <Divider />
      </Row>
      <Row style={{ marginBottom: 18 }} gutter={4}>
        <Col style={{ width: '100%' }}>
          <UploadReport reportId={activeReport?.id} userId={userId} />
        </Col>
      </Row>

      <ReportForm
        visible={visible}
        onSave={updateReport}
        onCancel={() => {
          setVisible(false)
        }}
        reportId={activeReport?.id}
        userId={userId}
        initialValues={{
          client: activeReport?.client,
          contractor: activeReport?.contractor,
          date: moment(activeReport?.date),
          time: moment(activeReport?.time)
        }}
      />
    </>
  )
}

const AsphaltTable = ({ setActiveReport }) => {
  const meta = useStoreState((state) => state.object.meta)
  const user = useStoreState((state) => state.user.me)
  const userId = user?.id
  const objectId = meta?.idobject
  const objectActiveDate = useStoreState((state) => state.object.activeDate)

  const fetchUsers = useStoreActions((actions) => actions.users.fetch)
  const users = useStoreState((state) => state.users.list)
  const fetchReports = useStoreActions((actions) => actions.asphalt.fetch)
  const reports = useStoreState((state) => state.asphalt.list)
  const reportsItems = useStoreState((state) => state.asphalt.items)
  const addReport = useStoreActions((actions) => actions.asphalt.add)
  const updateReport = useStoreActions((actions) => actions.asphalt.update)
  const removeReport = useStoreActions((actions) => actions.asphalt.delete)
  const loadingReport = useStoreState((state) => state.asphalt.loading)

  console.log(users)

  useEffect(() => {
    fetchUsers()
    fetchReports(objectId)
  }, [objectId])

  const dataSource = reports

  const [activeCell, setActiveCell] = useState(false)
  const rows = reports ? reports.length : 0
  const taborder = [
    'road_number',
    'name',
    'start',
    'end',
    'area',
    'date',
    'cancel_date',
    'covering_state',
    'type',
    'temp_air',
    'temp_covering',
    'temp_bonding',
    'notes'
  ]

  const handleChange = async ({ value, field, index, rowId, type, update }) => {
    console.log('handling change...', { value, field, index, rowId, roadItem: reportsItems[rowId][field] })

    if ((type === inputTypes.number && /^\d+\.\d+$|^\d+$/.test(value)) || (type !== inputTypes.number && typeof value === 'string')) {
      const newValueString = value?.toString()
      const oldValueString = reportsItems[rowId][field]

      if (newValueString !== oldValueString) {
        setActiveCell(false)
        await update({
          id: rowId,
          field: field,
          value: value,
          type: type,
          iduser: userId
        })
      }
    }
  }

  const getInputCell = (index, field, record, type, roundedTo = 2) => {
    let defaultValue = record[field]
    if (record[field] && !isNaN(record[field])) {
      if (typeof record[field] !== 'string' && record[field] % 1 !== 0) {
        defaultValue = parseFloat(record[field].toFixed(roundedTo))
      }
    }
    return (
      <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference({ index, field, rows, taborder }))}
        onBlur={(value) => handleChange({ value, field, index, rowId: record.id, type, update: updateReport })}
        onUpdate={(value) => handleChange({ value, field, index, rowId: record.id, type, update: updateReport })}
        onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
        onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
        active={activeCell === getCellReference({ index, field, rows, taborder })}
        defaultValue={defaultValue} // record[field]
      />
    )
  }

  const getDateInputCell = (index, field, record) => (
    <InputCell
      type="datetime"
      onClick={() => setActiveCell(getCellReference({ index, field, rows, taborder }))}
      onBlur={(value) => handleChange({ value, field, index, rowId: record.id, type: inputTypes.date, update: updateReport })}
      onUpdate={(value) => handleChange({ value, field, index, rowId: record.id, type: inputTypes.date, update: updateReport })}
      onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
      onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
      onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
      onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
      active={activeCell === getCellReference({ index, field, rows, taborder })}
      defaultValue={record[field] ? moment(record[field]).format('DD.MM.YYYY') : null}
    />
  )

  const columns = [
    {
      title: 'Aruande nr.',
      dataIndex: 'number',
      key: 'number',
      render: (text, record, index) => getInputCell(index, 'number', record, inputTypes.string)
    },
    {
      title: 'Nimetus',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => getInputCell(index, 'name', record, inputTypes.string)
    },
    {
      title: 'Aruande kuupäev',
      dataIndex: 'date',
      key: 'date',
      render: (text, record, index) => getDateInputCell(index, 'date', record)
    },
    {
      title: 'Koostaja',
      dataIndex: 'idcreator',
      key: 'idcreator',
      render: (text, record, index) => {
        const user = users?.find(({ iduser }) => iduser === record.idcreator)
        return record?.idcreator ? `${user?.user_name} ${user?.user_surname}` : '-'
      }
    },
    {
      title: 'Muudetud',
      dataIndex: 'changed',
      key: 'changed',
      render: (text, record, index) => (record?.changed ? moment(record?.changed).format('DD.MM.YYYY HH:mm') : '-')
    },
    {
      title: 'Muutja',
      dataIndex: 'idchanger',
      key: 'idchanger',
      render: (text, record, index) => {
        const user = users?.find(({ iduser }) => iduser === record.idchanger)
        return record?.idchanger ? `${user?.user_name} ${user?.user_surname}` : '-'
      }
    },
    {
      title: 'Toimingud',
      width: 50,
      render: (text, record, index) => {
        return (
          <Button
            onClick={() => {
              setActiveReport(record)
            }}>
            Täida
          </Button>
        )
      }
    },
    {
      title: (
        <Row gutter={[4, 4]} justify="center">
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {
                addReport({ idobject: objectId, iduser: userId })
              }}
            />
          </Col>
        </Row>
      ),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <Row gutter={[4, 4]} justify="center">
            <Col>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  console.log(record)
                  showConfirm(() => {
                    removeReport({ id: record?.id, obj_id: objectId })
                  })
                }}
              />
            </Col>
          </Row>
        )
      }
    }
  ]

  return (
    <Table
      size="small"
      loading={loadingReport}
      dataSource={dataSource}
      columns={columns}
      rowSelection={rowSelection}
      rowKey={(record) => `asphalt-${record?.id}`}
    />
  )
}

const MixturesTable = ({ reportId }) => {
  const tableName = 'asphalt_mixtures'

  const { formOptions } = useContext(CostOptionsContext)

  const fetch = useStoreActions((actions) => actions.asphalt.fetchExtras)
  const add = useStoreActions((actions) => actions.asphalt.addExtra)
  const update = useStoreActions((actions) => actions.asphalt.updateExtra)
  const del = useStoreActions((actions) => actions.asphalt.deleteExtra)

  const items = useStoreState((state) => state.asphalt.extras)
  const list = useStoreState((state) => state.asphalt.listExtras)
  const loading = useStoreState((state) => state.asphalt.loading)
  const reportsItems = list(tableName)

  useEffect(() => {
    fetch({ idreport: reportId, table: tableName })
  }, [reportId])

  const [activeCell, setActiveCell] = useState(false)
  const rows = reportsItems ? reportsItems.length : 0

  const taborder = ['idresource', 'start', 'end', 'vp', 'amount', 'width', 'covered_road', 'covered_other', 'temp_max', 'temp_min']

  const dataSource = reportsItems ? Object.values(reportsItems) : []

  const handleChange = async ({ value, field, index, rowId, type, update }) => {
    console.log('handling change...', { value, field, index, rowId, roadItem: reportsItems[rowId][field] })

    if ((type === inputTypes.number && /^\d+\.\d+$|^\d+$/.test(value)) || (type !== inputTypes.number && typeof value === 'string')) {
      const newValueString = value?.toString()
      const oldValueString = reportsItems[rowId][field]

      if (newValueString !== oldValueString) {
        setActiveCell(false)
        await update({
          id: rowId,
          field: field,
          value: value,
          type: type,
          table: tableName
          //iduser: userId
        })
      }
    }
  }

  const getInputCell = (index, field, record, type, roundedTo = 2) => {
    let defaultValue = record[field]
    if (record[field] && !isNaN(record[field])) {
      if (typeof record[field] !== 'string' && record[field] % 1 !== 0) {
        defaultValue = parseFloat(record[field].toFixed(roundedTo))
      }
    }
    return (
      <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference({ index, field, rows, taborder }))}
        onBlur={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onUpdate={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
        onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
        active={activeCell === getCellReference({ index, field, rows, taborder })}
        defaultValue={defaultValue} // record[field]
      />
    )
  }

  const getSelectCell = (index, valueField, titleField, rowsCount, selectProps, record) => {
    const material = formOptions?.materials && formOptions?.materials?.find((mat) => mat.idresources === record.idresource)
    return (
      <InputCell
        type="select"
        selectProps={selectProps}
        onClick={() => setActiveCell(getCellReference({ index, field: titleField }))}
        onUpdate={(value) => handleChange({ value, field: valueField, index, rowId: record.id, type: inputTypes.number, update })}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: -1, vertical: false }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: 1, vertical: false }))}
        active={activeCell === getCellReference({ index, field: titleField })}
        defaultValue={record[valueField]}
        defaultTitle={material ? material?.res_name1 : ''}
      />
    )
  }

  const columns = [
    {
      title: 'Segu liik',
      dataIndex: 'idresource',
      key: 'idresource',
      render: (text, record, index) =>
        getSelectCell(
          index,
          'idresource',
          'idresource',
          rows,
          {
            optionsList: formOptions?.materials,
            valueField: 'idresources',
            titleField: 'resource_string'
          },
          record
        )
    },
    {
      title: 'Algus',
      dataIndex: 'start',
      key: 'start',
      render: (text, record, index) => getInputCell(index, 'start', record, inputTypes.string)
    },
    {
      title: 'Lõpp',
      dataIndex: 'end',
      key: 'end',
      render: (text, record, index) => getInputCell(index, 'end', record, inputTypes.string)
    },
    {
      title: 'V/P',
      dataIndex: 'vp',
      key: 'vp',
      render: (text, record, index) => getInputCell(index, 'vp', record, inputTypes.string)
    },
    {
      title: 'Segu kogus (t)',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record, index) => getInputCell(index, 'amount', record, inputTypes.number)
    },
    {
      title: 'Laius (m)',
      dataIndex: 'width',
      key: 'width',
      render: (text, record, index) => getInputCell(index, 'width', record, inputTypes.number)
    },
    {
      title: 'Tee',
      dataIndex: 'covered_road',
      key: 'covered_road',
      render: (text, record, index) => getInputCell(index, 'covered_road', record, inputTypes.number)
    },
    {
      title: 'Muud',
      dataIndex: 'covered_other',
      key: 'covered_other',
      render: (text, record, index) => getInputCell(index, 'covered_other', record, inputTypes.number)
    },
    {
      title: 'Kokku',
      key: 'covered_total',
      render: (text, record, index) => record?.covered_road + record?.covered_other
    },
    {
      title: 'Kulu (kg/m2)',
      key: 'expense',
      render: (text, record, index) =>
        record?.amount && (record?.covered_road || record?.covered_other)
          ? parseFloat((record?.amount / (record?.covered_road + record?.covered_other)) * 1000).toFixed(2)
          : '-'
    },
    {
      title: 'Max',
      dataIndex: 'temp_max',
      key: 'temp_max',
      render: (text, record, index) => getInputCell(index, 'temp_max', record, inputTypes.number)
    },
    {
      title: 'Keskmine',
      key: 'temp_avg',
      render: (text, record, index) => (record?.temp_max + record?.temp_min) / 2
    },
    {
      title: 'Minimaalne',
      dataIndex: 'temp_min',
      key: 'temp_min',
      render: (text, record, index) => getInputCell(index, 'temp_min', record, inputTypes.number)
    },
    {
      width: 48,
      title: (
        <Row gutter={[4, 4]} justify="center">
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {
                add({ idreport: reportId, table: tableName })
              }}
            />
          </Col>
        </Row>
      ),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <Row gutter={[4, 4]} justify="center">
            <Col>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  console.log(record)
                  showConfirm(() => {
                    del({ id: record?.id, idreport: reportId, table: tableName })
                  })
                }}
              />
            </Col>
          </Row>
        )
      }
    }
  ]

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600, marginBottom: 8 }}>Segu Laotamine</Text>
      <Table size="small" loading={loading} dataSource={dataSource} columns={columns} rowKey={(record) => `mixtures-${record?.id}`} />
    </>
  )
}

const UnsuitableMixturesTable = ({ reportId }) => {
  const tableName = 'asphalt_unsuitable'

  const { formOptions } = useContext(CostOptionsContext)

  const fetch = useStoreActions((actions) => actions.asphalt.fetchExtras)
  const add = useStoreActions((actions) => actions.asphalt.addExtra)
  const update = useStoreActions((actions) => actions.asphalt.updateExtra)
  const del = useStoreActions((actions) => actions.asphalt.deleteExtra)

  const items = useStoreState((state) => state.asphalt.extras)
  const list = useStoreState((state) => state.asphalt.listExtras)
  const loading = useStoreState((state) => state.asphalt.loading)
  const reportsItems = list(tableName)

  useEffect(() => {
    fetch({ idreport: reportId, table: tableName })
  }, [reportId])

  const [activeCell, setActiveCell] = useState(false)
  const rows = reportsItems ? reportsItems.length : 0

  const taborder = ['idresource', 'amount', 'picket', 'notes']

  const dataSource = reportsItems ? Object.values(reportsItems) : []

  const handleChange = async ({ value, field, index, rowId, type, update }) => {
    console.log('handling change...', { value, field, index, rowId, roadItem: reportsItems[rowId][field] })

    if ((type === inputTypes.number && /^\d+\.\d+$|^\d+$/.test(value)) || (type !== inputTypes.number && typeof value === 'string')) {
      const newValueString = value?.toString()
      const oldValueString = reportsItems[rowId][field]

      if (newValueString !== oldValueString) {
        setActiveCell(false)
        await update({
          id: rowId,
          field: field,
          value: value,
          type: type,
          table: tableName
          //iduser: userId
        })
      }
    }
  }

  const getInputCell = (index, field, record, type, roundedTo = 2) => {
    let defaultValue = record[field]
    if (record[field] && !isNaN(record[field])) {
      if (typeof record[field] !== 'string' && record[field] % 1 !== 0) {
        defaultValue = parseFloat(record[field].toFixed(roundedTo))
      }
    }
    return (
      <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference({ index, field, rows, taborder }))}
        onBlur={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onUpdate={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
        onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
        active={activeCell === getCellReference({ index, field, rows, taborder })}
        defaultValue={defaultValue} // record[field]
      />
    )
  }

  const getSelectCell = (index, valueField, titleField, rowsCount, selectProps, record) => {
    const material = formOptions?.materials && formOptions?.materials?.find((mat) => mat.idresources === record.idresource)
    return (
      <InputCell
        type="select"
        selectProps={selectProps}
        onClick={() => setActiveCell(getCellReference({ index, field: titleField }))}
        onUpdate={(value) => handleChange({ value, field: valueField, index, rowId: record.id, type: inputTypes.number, update })}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: -1, vertical: false }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: 1, vertical: false }))}
        active={activeCell === getCellReference({ index, field: titleField })}
        defaultValue={record[valueField]}
        defaultTitle={material ? material?.res_name1 : ''}
      />
    )
  }

  const columns = [
    {
      title: 'Segu liik',
      dataIndex: 'idresource',
      key: 'idresource',
      render: (text, record, index) =>
        getSelectCell(
          index,
          'idresource',
          'idresource',
          rows,
          {
            optionsList: formOptions?.materials,
            valueField: 'idresources',
            titleField: 'resource_string'
          },
          record
        )
    },
    {
      title: 'Segu kogus (t)',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record, index) => getInputCell(index, 'amount', record, inputTypes.number)
    },
    {
      title: 'Pikett',
      dataIndex: 'picket',
      key: 'picket',
      render: (text, record, index) => getInputCell(index, 'picket', record, inputTypes.string)
    },
    {
      title: 'Põhjus',
      dataIndex: 'notes',
      key: 'notes',
      render: (text, record, index) => getInputCell(index, 'notes', record, inputTypes.string)
    },
    {
      width: 48,
      title: (
        <Row gutter={[4, 4]} justify="center">
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={async () => {
                await add({ idreport: reportId, table: tableName })
              }}
            />
          </Col>
        </Row>
      ),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <Row gutter={[4, 4]} justify="center">
            <Col>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  console.log(record)
                  showConfirm(() => {
                    del({ id: record?.id, idreport: reportId, table: tableName })
                  })
                }}
              />
            </Col>
          </Row>
        )
      }
    }
  ]

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600, marginBottom: 8 }}>Kõlbmatu Segu</Text>
      <Table size="small" loading={loading} dataSource={dataSource} columns={columns} rowKey={(record) => `unsuitable-${record?.id}`} />
    </>
  )
}

const WorksTable = ({ reportId }) => {
  const tableName = 'asphalt_works'

  const fetch = useStoreActions((actions) => actions.asphalt.fetchExtras)
  const add = useStoreActions((actions) => actions.asphalt.addExtra)
  const update = useStoreActions((actions) => actions.asphalt.updateExtra)
  const del = useStoreActions((actions) => actions.asphalt.deleteExtra)

  const items = useStoreState((state) => state.asphalt.extras)
  const list = useStoreState((state) => state.asphalt.listExtras)
  const loading = useStoreState((state) => state.asphalt.loading)
  const reportsItems = list(tableName)

  useEffect(() => {
    fetch({ idreport: reportId, table: tableName })
  }, [reportId])

  const [activeCell, setActiveCell] = useState(false)
  const rows = reportsItems ? reportsItems.length : 0

  const taborder = ['code', 'name', 'unit', 'amount', 'price']

  const dataSource = reportsItems ? Object.values(reportsItems) : []

  const handleChange = async ({ value, field, index, rowId, type, update }) => {
    console.log('handling change...', { value, field, index, rowId, roadItem: reportsItems[rowId][field] })

    if ((type === inputTypes.number && /^\d+\.\d+$|^\d+$/.test(value)) || (type !== inputTypes.number && typeof value === 'string')) {
      const newValueString = value?.toString()
      const oldValueString = reportsItems[rowId][field]

      if (newValueString !== oldValueString) {
        setActiveCell(false)
        await update({
          id: rowId,
          field: field,
          value: value,
          type: type,
          table: tableName
          //iduser: userId
        })
      }
    }
  }

  const getInputCell = (index, field, record, type, roundedTo = 2) => {
    let defaultValue = record[field]
    if (record[field] && !isNaN(record[field])) {
      if (typeof record[field] !== 'string' && record[field] % 1 !== 0) {
        defaultValue = parseFloat(record[field].toFixed(roundedTo))
      }
    }
    return (
      <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference({ index, field, rows, taborder }))}
        onBlur={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onUpdate={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
        onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
        active={activeCell === getCellReference({ index, field, rows, taborder })}
        defaultValue={defaultValue} // record[field]
      />
    )
  }

  const columns = [
    {
      title: 'Kood',
      dataIndex: 'code',
      key: 'code',
      render: (text, record, index) => getInputCell(index, 'code', record, inputTypes.string)
    },
    {
      title: 'Tööliik',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => getInputCell(index, 'name', record, inputTypes.string)
    },
    {
      title: 'Ühik',
      dataIndex: 'unit',
      key: 'unit',
      render: (text, record, index) => getInputCell(index, 'unit', record, inputTypes.string)
    },
    {
      title: 'Kogus',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record, index) => getInputCell(index, 'amount', record, inputTypes.number)
    },
    {
      title: 'Ühiku hind',
      dataIndex: 'price',
      key: 'price',
      render: (text, record, index) => getInputCell(index, 'price', record, inputTypes.number)
    },
    {
      width: 48,
      title: (
        <Row gutter={[4, 4]} justify="center">
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={async () => {
                await add({ idreport: reportId, table: tableName })
              }}
            />
          </Col>
        </Row>
      ),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <Row gutter={[4, 4]} justify="center">
            <Col>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  console.log(record)
                  showConfirm(() => {
                    del({ id: record?.id, idreport: reportId, table: tableName })
                  })
                }}
              />
            </Col>
          </Row>
        )
      }
    }
  ]

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600, marginBottom: 8 }}>Lisatööd</Text>
      <Table size="small" loading={loading} dataSource={dataSource} columns={columns} rowKey={(record) => `works-${record?.id}`} />
    </>
  )
}

const SamplesTable = ({ reportId }) => {
  const tableName = 'asphalt_samples'

  const fetch = useStoreActions((actions) => actions.asphalt.fetchExtras)
  const add = useStoreActions((actions) => actions.asphalt.addExtra)
  const update = useStoreActions((actions) => actions.asphalt.updateExtra)
  const del = useStoreActions((actions) => actions.asphalt.deleteExtra)

  const fetchUsers = useStoreActions((actions) => actions.users.fetch)
  const users = useStoreState((state) => state.users.list)

  const items = useStoreState((state) => state.asphalt.extras)
  const list = useStoreState((state) => state.asphalt.listExtras)
  const loading = useStoreState((state) => state.asphalt.loading)
  const reportsItems = list(tableName)

  useEffect(() => {
    fetchUsers()
    fetch({ idreport: reportId, table: tableName })
  }, [reportId])

  const [activeCell, setActiveCell] = useState(false)
  const rows = reportsItems ? reportsItems.length : 0

  const taborder = ['idresource', 'amount', 'picket', 'notes']

  const dataSource = reportsItems ? Object.values(reportsItems) : []

  const handleChange = async ({ value, field, index, rowId, type, update }) => {
    console.log('handling change...', { value, field, index, rowId })

    if ((type === inputTypes.number && /^\d+\.\d+$|^\d+$/.test(value)) || (type !== inputTypes.number && typeof value === 'string')) {
      const newValueString = value?.toString()
      const oldValueString = reportsItems[rowId][field]

      if (newValueString !== oldValueString) {
        setActiveCell(false)
        await update({
          id: rowId,
          field: field,
          value: value,
          type: type,
          table: tableName
          //iduser: userId
        })
      }
    }
  }

  const getInputCell = (index, field, record, type, roundedTo = 2) => {
    let defaultValue = record[field]
    if (record[field] && !isNaN(record[field])) {
      if (typeof record[field] !== 'string' && record[field] % 1 !== 0) {
        defaultValue = parseFloat(record[field].toFixed(roundedTo))
      }
    }
    return (
      <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference({ index, field, rows, taborder }))}
        onBlur={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onUpdate={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
        onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
        active={activeCell === getCellReference({ index, field, rows, taborder })}
        defaultValue={defaultValue} // record[field]
      />
    )
  }

  const getSelectCell = (index, valueField, titleField, rowsCount, selectProps, record) => {
    const user = users && users?.find((usr) => usr.iduser === record.iduser)
    return (
      <InputCell
        type="select"
        selectProps={selectProps}
        onClick={() => setActiveCell(getCellReference({ index, field: titleField }))}
        onUpdate={(value) => handleChange({ value, field: valueField, index, rowId: record.id, type: inputTypes.number, update })}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: -1, vertical: false }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: 1, vertical: false }))}
        active={activeCell === getCellReference({ index, field: titleField })}
        defaultValue={record[valueField]}
        defaultTitle={user ? `${user.user_fullname}` : ''}
      />
    )
  }

  const getTimeInputCell = (index, field, rowsCount, record) => (
    <InputCell
      type="timeinput"
      onClick={() => setActiveCell(getCellReference({ index, field }))}
      onBlur={(value) =>
        handleChange({
          value: moment(value, 'HH:mm').format('YYYY-MM-DD h:mm:ss'),
          field,
          index,
          rowId: record.id,
          type: inputTypes.string,
          update
        })
      }
      onUpdate={(value) =>
        handleChange({
          value: moment(value, 'HH:mm').format('YYYY-MM-DD h:mm:ss'),
          field,
          index,
          rowId: record.id,
          type: inputTypes.string,
          update
        })
      }
      onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
      onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
      onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
      onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
      active={activeCell === getCellReference({ index, field, rows, taborder })}
      defaultValue={record[field] ? moment(record[field]).format('hh:mm') : ''}
    />
  )

  const columns = [
    {
      title: 'Proovi võttis',
      dataIndex: 'iduser',
      key: 'iduser',
      render: (text, record, index) =>
        getSelectCell(
          index,
          'iduser',
          'iduser',
          rows,
          {
            optionsList: users,
            valueField: 'iduser',
            titleField: 'user_fullname'
          },
          record
        )
    },
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      render: (text, record, index) => getInputCell(index, 'number', record, inputTypes.string)
    },
    {
      title: 'Asukoht',
      dataIndex: 'location',
      key: 'location',
      render: (text, record, index) => getInputCell(index, 'location', record, inputTypes.string)
    },
    {
      title: 'Kell',
      dataIndex: 'time',
      key: 'time',
      render: (text, record, index) => getTimeInputCell(index, 'time', rows, record)
    },
    {
      title: 'Katseprotokolli number',
      dataIndex: 'protocol_number',
      key: 'protocol_number',
      render: (text, record, index) => getInputCell(index, 'protocol_number', record, inputTypes.string)
    },
    {
      title: 'Katsetaja',
      dataIndex: 'sampler',
      key: 'sampler',
      render: (text, record, index) => getInputCell(index, 'sampler', record, inputTypes.string)
    },
    {
      width: 48,
      title: (
        <Row gutter={[4, 4]} justify="center">
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={async () => {
                await add({ idreport: reportId, table: tableName })
              }}
            />
          </Col>
        </Row>
      ),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <Row gutter={[4, 4]} justify="center">
            <Col>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  console.log(record)
                  showConfirm(() => {
                    del({ id: record?.id, idreport: reportId, table: tableName })
                  })
                }}
              />
            </Col>
          </Row>
        )
      }
    }
  ]

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600, marginBottom: 8 }}>Seguproovid</Text>
      <Table size="small" loading={loading} dataSource={dataSource} columns={columns} rowKey={(record) => `samples-${record?.id}`} />
    </>
  )
}

const ProcessingTable = ({ reportId }) => {
  const tableName = 'asphalt_processing'

  const fetch = useStoreActions((actions) => actions.asphalt.fetchExtras)
  const add = useStoreActions((actions) => actions.asphalt.addExtra)
  const update = useStoreActions((actions) => actions.asphalt.updateExtra)
  const del = useStoreActions((actions) => actions.asphalt.deleteExtra)

  const items = useStoreState((state) => state.asphalt.extras)
  const list = useStoreState((state) => state.asphalt.listExtras)
  const loading = useStoreState((state) => state.asphalt.loading)
  const reportsItems = list(tableName)

  useEffect(() => {
    fetch({ idreport: reportId, table: tableName })
  }, [reportId])

  const [activeCell, setActiveCell] = useState(false)
  const rows = reportsItems ? reportsItems.length : 0

  const taborder = ['idresource', 'amount', 'picket', 'notes']

  const dataSource = reportsItems ? Object.values(reportsItems) : []

  const handleChange = async ({ value, field, index, rowId, type, update }) => {
    console.log('handling change...', { value, field, index, rowId, roadItem: reportsItems[rowId][field] })

    if ((type === inputTypes.number && /^\d+\.\d+$|^\d+$/.test(value)) || (type !== inputTypes.number && typeof value === 'string')) {
      const newValueString = value?.toString()
      const oldValueString = reportsItems[rowId][field]

      if (newValueString !== oldValueString) {
        setActiveCell(false)
        await update({
          id: rowId,
          field: field,
          value: value,
          type: type,
          table: tableName
          //iduser: userId
        })
      }
    }
  }

  const getInputCell = (index, field, record, type, roundedTo = 2) => {
    let defaultValue = record[field]
    if (record[field] && !isNaN(record[field])) {
      if (typeof record[field] !== 'string' && record[field] % 1 !== 0) {
        defaultValue = parseFloat(record[field].toFixed(roundedTo))
      }
    }
    return (
      <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference({ index, field, rows, taborder }))}
        onBlur={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onUpdate={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
        onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
        active={activeCell === getCellReference({ index, field, rows, taborder })}
        defaultValue={defaultValue} // record[field]
      />
    )
  }

  const columns = [
    {
      title: 'Liik',
      dataIndex: 'arrived_type',
      key: 'arrived_type',
      render: (text, record, index) => getInputCell(index, 'arrived_type', record, inputTypes.string)
    },
    {
      title: 'Kogus (kg)',
      dataIndex: 'arrived_amount',
      key: 'arrived_amount',
      render: (text, record, index) => getInputCell(index, 'arrived_amount', record, inputTypes.number)
    },
    {
      title: 'Saate dokument',
      dataIndex: 'arrived_document',
      key: 'arrived_document',
      render: (text, record, index) => getInputCell(index, 'arrived_document', record, inputTypes.string)
    },
    {
      title: 'Liik',
      dataIndex: 'used_type',
      key: 'used_type',
      render: (text, record, index) => getInputCell(index, 'used_type', record, inputTypes.string)
    },
    {
      title: 'Kulunorm',
      dataIndex: 'used_norm',
      key: 'used_norm',
      render: (text, record, index) => getInputCell(index, 'used_norm', record, inputTypes.number)
    },
    {
      title: 'Kulunormi ühik',
      dataIndex: 'used_unit',
      key: 'used_unit',
      render: (text, record, index) => getInputCell(index, 'used_unit', record, inputTypes.string)
    },
    {
      title: 'kg',
      dataIndex: 'used_kg',
      key: 'used_kg',
      render: (text, record, index) => record?.used_norm * record?.used_s
    },
    {
      title: 'S (m2)',
      dataIndex: 'used_s',
      key: 'used_s',
      render: (text, record, index) => getInputCell(index, 'used_s', record, inputTypes.number)
    },
    {
      title: 'gr/m',
      dataIndex: 'used_grm',
      key: 'used_grm',
      render: (text, record, index) => getInputCell(index, 'used_grm', record, inputTypes.number)
    },
    {
      title: 'Kvaliteet',
      dataIndex: 'used_quality',
      key: 'used_quality',
      render: (text, record, index) => getInputCell(index, 'used_quality', record, inputTypes.string)
    },
    {
      width: 48,
      title: (
        <Row gutter={[4, 4]} justify="center">
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={async () => {
                await add({ idreport: reportId, table: tableName })
              }}
            />
          </Col>
        </Row>
      ),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <Row gutter={[4, 4]} justify="center">
            <Col>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  console.log(record)
                  showConfirm(() => {
                    del({ id: record?.id, idreport: reportId, table: tableName })
                  })
                }}
              />
            </Col>
          </Row>
        )
      }
    }
  ]

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600, marginBottom: 8 }}>Kruntimine, Kuumutamine, Freesimine</Text>
      <Table size="small" loading={loading} dataSource={dataSource} columns={columns} rowKey={(record) => `processing-${record?.id}`} />
    </>
  )
}

const ExtrasTable = ({ reportId }) => {
  const tableName = 'asphalt_extra'

  const fetch = useStoreActions((actions) => actions.asphalt.fetchExtras)
  const add = useStoreActions((actions) => actions.asphalt.addExtra)
  const update = useStoreActions((actions) => actions.asphalt.updateExtra)
  const del = useStoreActions((actions) => actions.asphalt.deleteExtra)

  const items = useStoreState((state) => state.asphalt.extras)
  const list = useStoreState((state) => state.asphalt.listExtras)
  const loading = useStoreState((state) => state.asphalt.loading)
  const reportsItems = list(tableName)

  useEffect(() => {
    fetch({ idreport: reportId, table: tableName })
  }, [reportId])

  const [activeCell, setActiveCell] = useState(false)
  const rows = reportsItems ? reportsItems.length : 0

  const taborder = ['idresource', 'amount', 'picket', 'notes']

  const dataSource = reportsItems ? Object.values(reportsItems) : []

  const handleChange = async ({ value, field, index, rowId, type, update }) => {
    console.log('handling change...', { value, field, index, rowId, roadItem: reportsItems[rowId][field] })

    if ((type === inputTypes.number && /^\d+\.\d+$|^\d+$/.test(value)) || (type !== inputTypes.number && typeof value === 'string')) {
      const newValueString = value?.toString()
      const oldValueString = reportsItems[rowId][field]

      if (newValueString !== oldValueString) {
        setActiveCell(false)
        await update({
          id: rowId,
          field: field,
          value: value,
          type: type,
          table: tableName
          //iduser: userId
        })
      }
    }
  }

  const getInputCell = (index, field, record, type, roundedTo = 2) => {
    let defaultValue = record[field]
    if (record[field] && !isNaN(record[field])) {
      if (typeof record[field] !== 'string' && record[field] % 1 !== 0) {
        defaultValue = parseFloat(record[field].toFixed(roundedTo))
      }
    }
    return (
      <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference({ index, field, rows, taborder }))}
        onBlur={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onUpdate={(value) => handleChange({ value, field, index, rowId: record.id, type, update })}
        onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
        onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
        active={activeCell === getCellReference({ index, field, rows, taborder })}
        defaultValue={defaultValue} // record[field]
      />
    )
  }

  const getTimeInputCell = (index, field, rowsCount, record) => (
    <InputCell
      type="timeinput"
      onClick={() => setActiveCell(getCellReference({ index, field }))}
      onBlur={(value) =>
        handleChange({
          value: moment(value, 'HH:mm').format('YYYY-MM-DD h:mm:ss'),
          field,
          index,
          rowId: record.id,
          type: inputTypes.string,
          update
        })
      }
      onUpdate={(value) =>
        handleChange({
          value: moment(value, 'HH:mm').format('YYYY-MM-DD h:mm:ss'),
          field,
          index,
          rowId: record.id,
          type: inputTypes.string,
          update
        })
      }
      onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
      onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
      onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
      onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
      active={activeCell === getCellReference({ index, field, rows, taborder })}
      defaultValue={record[field] ? moment(record[field]).format('hh:mm') : ''}
    />
  )

  const columns = [
    {
      title: 'Temperatuur C',
      dataIndex: 'temp',
      key: 'temp',
      render: (text, record, index) => getInputCell(index, 'temp', record, inputTypes.number)
    },
    {
      title: 'Ilm',
      dataIndex: 'weather',
      key: 'weather',
      render: (text, record, index) => getInputCell(index, 'weather', record, inputTypes.string)
    },
    {
      title: 'Kell',
      dataIndex: 'time',
      key: 'time',
      render: (text, record, index) => getTimeInputCell(index, 'time', rows, record)
    },
    {
      title: 'Laadur töötas',
      dataIndex: 'time_working',
      key: 'time_working',
      render: (text, record, index) => getTimeInputCell(index, 'time_working', rows, record)
    },
    {
      title: 'Katkestused ja põhjused',
      dataIndex: 'notes',
      key: 'notes',
      render: (text, record, index) => getInputCell(index, 'notes', record, inputTypes.string)
    },
    {
      width: 48,
      title: (
        <Row gutter={[4, 4]} justify="center">
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={async () => {
                await add({ idreport: reportId, table: tableName })
              }}
            />
          </Col>
        </Row>
      ),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <Row gutter={[4, 4]} justify="center">
            <Col>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  console.log(record)
                  showConfirm(() => {
                    del({ id: record?.id, idreport: reportId, table: tableName })
                  })
                }}
              />
            </Col>
          </Row>
        )
      }
    }
  ]

  return (
    <>
      <Text style={{ fontSize: 24, fontWeight: 600, marginBottom: 8 }}>Muud andmed vahetuse ajal</Text>
      <Table size="small" loading={loading} dataSource={dataSource} columns={columns} rowKey={(record) => `extras-${record?.id}`} />
    </>
  )
}

export default AsfalteerimineTable
