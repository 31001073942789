import { useEffect, useState} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Card, DatePicker, Modal } from "antd";
import React from "react";
import moment from 'moment'
import dateLocale from 'antd/es/date-picker/locale/et_EE'


import ObjectNavigator from "../../../../components/ObjectNavigator";

const PaCostsCopyDayModal = ({isOpen, onClose}) => {

    const objectActiveDate = useStoreState(state => state.object.activeDate)
    const meta = useStoreState(state => state.object.meta)
    const costs = useStoreState(state => state.object.costs)
    const sourceCostDates = useStoreState(state => state.object.costDates) //source object dates list
    //const objects = useStoreState(state => state.objects.objects)

    const copyCosts = useStoreActions(actions => actions.object.apiCopyCosts);
    const loadCosts = useStoreActions(actions => actions.object.apiLoadCosts);
    const loadCostDates = useStoreActions(actions => actions.object.apiFetchCostDates);
    const loadObjects = useStoreActions(actions => actions.objects.apiLoadObjects);

    const meObjid = meta.idobject

    // for copying from another day from this project
    const [mySourceDate, setMySourceDate] = useState(false)

    // for copying from foreign project
    const [foreignSourceDate, setForeignSourceDate] = useState(false)
    const [foreignSourceObject, setForeignSourceObject] = useState(false)
    const [foreignDatepickerVisible, setForeignDatepickerVisible] = useState(false)

    const [loading, setLoading] = useState(false)

    function handleCopyFromTemplate() {
        copyFromObject(meObjid, meObjid, moment("1900-01-01"), objectActiveDate)
            .then(() => console.log("copied from template"))
    }

    function handleCopyFromDate() {
        copyFromObject(meObjid, meObjid, mySourceDate, objectActiveDate)
            .then(() => console.log("copied from date"))
    }

    function handleCopyFromForeign() {
        copyFromObject(foreignSourceObject, meObjid, foreignSourceDate, objectActiveDate)
            .then(() => console.log("copied from other"))
    }

    async function copyFromObject(sourceObjId, targetObjId, mySourceDate, myTargetDate) {
        await setLoading(true)
        await copyCosts({
            source_obj_id: sourceObjId,
            source_date: mySourceDate.format("YYYY-MM-DD 00:00:00"),
            target_obj_id: targetObjId,
            target_date: myTargetDate.format("YYYY-MM-DD 00:00:00")
        })
        await loadCosts(meObjid)
        console.log("copied from object", sourceObjId, targetObjId, mySourceDate, myTargetDate)
        onClose()
        await setLoading(false)

    }

    useEffect(() => {
        (async () => {
            loadObjects()
        })()
         // eslint-disable-next-line
    }, []);


    let costDates = []
    let costDates2 = []

    Object.values(costs).forEach((cost) => {
        if (!costDates.includes(cost.cost_date)) {
            costDates.push(cost.cost_date)
            costDates2.push(moment(cost.cost_date).format('DD.MM.YY'))
        }
    })


    let sourceObjDates = sourceCostDates.map(v => {
        return moment(v.cost_date).format('DD.MM.YY')
        //return (
        //    {
        //        label: moment(v.cost_date).format('DD.MM.YYYY'),
        //        key: moment(v.cost_date).format('YYYY-MM-DD')
        //    }
        //)
    })

    //console.log("cost_list: ", costDates)
    //console.log("other object dates: ", sourceObjDates)
    //console.log("foreign date: ", foreignSourceDate)

    const dateFormat = 'DD.MM.YYYY';

    return (
        <Modal
            visible={isOpen}
            //icon="info-sign"
            onCancel={onClose}
            onOk={onClose}
            title={"Kopeeri päev"}
            //width={"70%"}
            destroyOnClose={true}
            okText={"OK"}
            cancelText={"Tühista"}
        >
            {Object.values(costs)
                .filter(c => moment(c.cost_date).format('L') === objectActiveDate.format('L'))
                .length > 0
                ? <Alert message="Sul on juba kulud tänases päevas. Kindel, et soovid midagi kopida?" type="warning"/>
                : null
            }

            {loading ?
                <div> Kopeerimine... <LoadingOutlined /></div>
                :
                <div>
                    <Card title="Täida päevaaruanne põhjalt" bordered={false}>
                        <Button type="primary" onClick={() => handleCopyFromTemplate()}>Täida põhjalt</Button>


                    </Card>
                    <Card title="Kopeeri päevaaruanne olemasolevalt päevalt" bordered={false}>
                        <div>
                            <DatePicker
                                locale={dateLocale}
                                placeholder="Vali kuupäev"
                                className="mr-2"
                                format={dateFormat}
                                allowClear={false}
                                onChange={(v) => setMySourceDate(v)}
                                dateRender={current => {
                                    const style = {};
                                    if (costDates2.includes(current.format('DD.MM.YY'))) {
                                        console.log("current_datepicker", current)
                                        style.border = '1px solid #1890ff';
                                        style.borderRadius = '50%';
                                    }
                                    return (
                                        <div
                                            className="ant-calendar-date"
                                            style={style}
                                        >
                                            {current.date()}
                                        </div>
                                    );
                                }}
                            />
                            <Button
                                type="primary"
                                disabled={!mySourceDate}
                                onClick={() => handleCopyFromDate()}>Kopeeri kulud</Button>
                        </div>
                    </Card>
                    <Card title="Kopeeri päevaaruanne muult objektilt" bordered={false}>
                        <div>

                            <ObjectNavigator
                                style={{width: 200}}
                                onSelect={(v) => {
                                    setForeignSourceObject(v)
                                    //console.log(v)
                                    setForeignDatepickerVisible(true)
                                    loadCostDates(v)
                                }}/>

                            {/* <Select
                                className="mr-2 mb-2"
                                onChange={(v) => {
                                    setForeignSourceObject(v)
                                    console.log(v)
                                    setForeignDatepickerVisible(true)
                                    loadCostDates(v)
                                }}
                                dropdownMatchSelectWidth={false}
                                placeholder="Vali objekt"
                                style={{width: 200}}>
                                {objects.map((obj) =>
                                    <Option
                                        value={obj.idobject}
                                        key={obj.idobject}
                                    > {obj.name} </Option>)
                                }
                            </Select> */}

                            <DatePicker
                                locale={dateLocale}
                                placeholder="Vali kuupäev"
                                className="mr-2"
                                disabled={!foreignDatepickerVisible}
                                format={dateFormat}
                                allowClear={false}
                                onChange={(v) => setForeignSourceDate(v)}
                                dateRender={current => {
                                    const style = {};
                                    if (sourceObjDates.includes(current.format('DD.MM.YY'))) {
                                        console.log("current_datepicker", current)
                                        style.border = '1px solid #1890ff';
                                        style.borderRadius = '50%';
                                    }
                                    return (
                                        <div
                                            className="ant-calendar-date"
                                            style={style}
                                        >
                                            {current.date()}
                                        </div>
                                    );
                                }}
                            />

                            {/* <Select
                                //defaultValue={foreignSourceDate}
                                style={{width: 200}}
                                className="mr-2"
                                disabled={!foreignDatepickerVisible}
                                onChange={v => setForeignSourceDate(moment(v))}
                                placeholder="Vali kuupäev">
                                {
                                    sourceObjDates.map(x =>
                                        <Option
                                            value={x.key}
                                            key={x.key}
                                        >
                                            {x.label}
                                        </Option>)
                                }

                            </Select> */}

                            <Button type="primary"
                                    className="mt-2"
                                    disabled={!foreignSourceDate}
                                    onClick={() => handleCopyFromForeign()}
                            >Kopeeri kulud</Button>
                        </div>
                    </Card>
                </div>}

        </Modal>
    );

}

export default PaCostsCopyDayModal