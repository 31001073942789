import {action, thunk, computed} from "easy-peasy";
import API from "../../services/api";

export const budgetModel = {

    budget: [],
    loading: true,

    setLoading: action((state, payload) => state.loading = payload),
    setBudget: action((state, payload) => state.budget = payload),

    budgetSubtotals: computed(
        [
            state => state.budget,
            (state, storeState) => storeState,

        ],
        (budget, myStoreState ) => {

            const bills = myStoreState.bills.bills
            const costs = myStoreState.object.costs

            let subtotals = {}
            if (bills && budget && bills.hasOwnProperty('flat')) {

                subtotals = bills.flat.map(r => r)
                subtotals.forEach(b => {

                    b['subtotalContract'] = budget
                        .filter(r => r.bill_path && r.bill_path.startsWith(b.path))
                        .reduce((sum, row) => sum + (row.amount1_at_offer * row.amount2_at_offer * row.unit_price_at_offer), 0)
                    b['subtotalTarget'] = budget
                        .filter(r => r.bill_path && r.bill_path.startsWith(b.path))
                        .reduce((sum, row) => sum + (row.amount1_target * row.amount2_target * row.unit_price_target), 0)
                    b['difference'] = b['subtotalTarget'] - b['subtotalContract']
                })

                if (costs && Object.values(costs).length > 0) {

                    //console.log("here!!!!!")

                    let myAssignments = []

                    Object.values(costs).forEach(r => {
                        if (r.assignments) r.assignments.forEach(v => myAssignments.push(v))
                    })

                    subtotals.forEach(b => {
                        b['subtotalCompletedCosts'] = myAssignments.filter(r => r.bill_path && r.bill_path.startsWith(b.path))
                            .reduce((sum, row) => sum + row.assignment_sum, 0)

                    })

                    //console.log("myassignments", myAssignments)
                }
            }


            //console.log("subtotals", subtotals)
            return subtotals
        }
    ),

    apiFetchBudget: thunk(async (actions, payload) => {
        const res = await API("/api/budget", false, 'get', payload)
        actions.setBudget(res.data)
    }),

    apiUpdateBudgetRow:
        thunk(async (actions, payload) => {
            await API("/api/budget", false, "post", payload)
        }),

    // add budget row, not entire budgets, entire budget added in BudgetS model
    apiAddBudgetRow:
        thunk(async (actions, payload) => {
            await API("/api/budget", false, "put", payload)
        }),

    apiDeleteBudgetRow:
        thunk(async (actions, payload) => {
            await API("/api/budget", false, "delete", payload)
        }),

}

export default budgetModel