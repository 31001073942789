import { useEffect, useState } from "react"
import { useStoreActions } from "easy-peasy"
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Modal } from "antd"
import React from "react"

import ObjectNavigator from "../../../ObjectNavigator"

const PaCostsCopyDayModal = ({ isOpen, onClose, costs }) => {
  const moveCosts = useStoreActions(actions => actions.control.moveCosts)
  const loadObjects = useStoreActions(actions => actions.objects.apiLoadObjects)
  const loadCosts = useStoreActions(actions => actions.object.apiLoadCosts)

  const [targetId, setTargetId] = useState()
  const [loading, setLoading] = useState(false)

  const costIds = costs.map(({ idcosts }) => idcosts)
  console.log({ costs, costIds })

  const handleSubmit = async () => {
    setLoading(true)
    await moveCosts({ costIds, targetId })
    await loadCosts()
    setLoading(false)
  }

  useEffect(() => {
    (async () => {
      loadObjects()
    })()
  }, [])

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      onOk={onClose}
      title={"Tõsta päev ümber"}
      destroyOnClose={true}
      okText={"OK"}
      cancelText={"Tühista"}
    >
      {loading ?
        <LoadingOutlined />
        :
        <Card title="Tõsta kõik read valitud kuupäeval teise objekti alla" bordered={false}>
          <ObjectNavigator
            style={{ width: 200 }}
            onSelect={(v) => {
              setTargetId(v)
              console.log(targetId)
            }} />
          <Button type="primary"
            className="mt-2"
            disabled={!targetId}
            onClick={() => handleSubmit()}
          >Tõsta kulud</Button>
        </Card>}
    </Modal>
  )

}

export default PaCostsCopyDayModal
