import React, { useEffect, useState } from "react"
import PageWithHeader from '../components/PageWithHeader'
import { Card, Avatar, Typography, Alert, Col, Row } from "antd"
import '../App.css'
import { withRouter, Redirect } from "react-router"
import { useStoreActions, useStoreState } from "easy-peasy"
import { PaObjectsTable, PaObjectsList2, PaObjectsMap } from '../pages/objects'
import { PaObjectTotalsV2 } from "../components/object/projections/Projection"
import useWindowSize from "../services/useWindowSize"
import gravatar from "gravatar"
import { smartSearch } from "../services/smartSearch"


const { Title } = Typography

let accountDropdownProps = {
  avatarURL: '/images/logo.png',
  name: '',
  description: 'Administrator',
}

export default withRouter(({ children }) => {

  const user = useStoreState(state => state.user.me)
  const allObjects = useStoreState(state => state.objects.objects)
  //const objects = useStoreState(state => state.objects.objects);
  const loadObjects = useStoreActions(actions => actions.objects.apiLoadObjects)
  const fetchObjectsTotals = useStoreActions(actions => actions.resultV2.apiFetchObjectsTotals)

  const [loading, setLoading] = useState(true)

  const viewPortDimensions = useWindowSize()

  if (user && user.id) {
    accountDropdownProps.name = `${user.name} ${user.surname}`
    accountDropdownProps.email = `${user.username}`

    accountDropdownProps.description = `${user.position_name}`
    accountDropdownProps.avatarURL = gravatar.url(user.username, { d: '404' })
  }

  const objectFilter = [3, 4, 5, 6, 7]
  const search = '' //accountDropdownProps.name
  const searchColumns = ["name", "shiffer", "user_positions_name", "proj_m_name", "obj_m_name"]

  useEffect(() => {
    (async () => {
      if (user) {
        await loadObjects()
        await setLoading(false)
        await fetchObjectsTotals({ objects: user.prefs.myobjects })
      }

    })()
    // eslint-disable-next-line
  }, [user])


  let objects = []
  if (user?.prefs?.myobjects) objects = allObjects.filter(r => user.prefs.myobjects.includes(r.idobject))
  let dataSource = smartSearch(objects.filter(r => objectFilter.includes(r.status)), search, searchColumns)

  return (

    user.position === 7 ?

      <Redirect to="/objects" />
      :
      <PageWithHeader>

        <div>
          <Card>
            <div className="d-flex justify-content-between pt-2 pb-2">
              <div className="d-flex">
                <Avatar
                  //shape="square"
                  size={40}
                  style={{ backgroundColor: '#87d068' }}
                  src={"https:" + accountDropdownProps.avatarURL}
                >
                  {user && `${user.name} ${user.surname}`}
                </Avatar>
                <div>
                  <div className="ml-2">
                    <b>{user.name} {user.surname}</b>
                  </div>
                  <div className="ml-2">
                    <span className="text-muted"> {user.position_name}</span>
                  </div>
                </div>
              </div>
              <div className="">

              </div>
            </div>
          </Card>
        </div>


        {viewPortDimensions.width < 600 ?

          <PaObjectsList2
            dataSource={dataSource}
            itemClassName="m-0 border-right-0 border-left-0"
            className={"table-striped-rows"}
            style={{ overflowX: 'auto' }}
            itemStyle={{ minWidth: 200 }}
            handleEdit={() => null}
            loading={loading}
            pagination={{
              defaultPageSize: 100,
              showSizeChanger: false,
              onChange: (page, pageSize) => console.log("page: ", page, "pagesize: ", pageSize),
              onShowSizeChange: (current, size) => console.log("page: ", current, "pagesize: ", size)
            }}
          />

          :
          <div>
            <Row>
              <Col span={24}>

                <Card className="m-2">
                  <div><Title level={4}>Kokkuvõte ja asukohad</Title> "Minu objektid" vaates olevate objektide koondtulem</div>
                  <div className="d-flex">
                    <div style={{ width: '67%' }}>
                      <PaObjectTotalsV2 showTotals="totalsonly" />
                    </div>
                    <PaObjectsMap
                      dataSource={dataSource}
                      className="pb-2 ml-2"
                      style={{ width: '33%' }}
                    />
                  </div>
                </Card>

              </Col>

            </Row>
            <Row>
              <Col span={24}>

                <Card className="mb-2 ml-2 mr-2">
                  <div><Title level={4}>Minu objektid</Title></div>
                  {
                    !user?.prefs?.myobjects || user?.prefs?.myobjects.length < 1 ?
                      <Alert
                        className="mb-2"
                        message={<h4>Kus mu objektid on?</h4>}
                        description={<div><p>Nüüd saavad kõik määrata ise oma
                                                    lemmikobjektid!</p>
                          <p> Selleks pead kõigi objektide vaates klikkima objekti nimetuse ees <i
                            className="fas fa-star"> </i></p></div>}
                        type="success"
                      /> : null
                  }
                  <PaObjectsTable
                    dataSource={dataSource}
                    className={"table-striped-rows"}
                    handleEdit={() => null}
                    loading={loading}
                    pagination={{
                      defaultPageSize: 100,
                      showSizeChanger: false,
                      onChange: (page, pageSize) => console.log("page: ", page, "pagesize: ", pageSize),
                      onShowSizeChange: (current, size) => console.log("page: ", current, "pagesize: ", size)
                    }}
                  />
                </Card>

              </Col>

            </Row>
          </div>
        }

      </PageWithHeader>
  )
})



