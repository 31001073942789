import React, { forwardRef, useImperativeHandle } from 'react'
import { Input, Form, DatePicker, TimePicker, Modal } from 'antd'
import moment from 'moment'
import TextArea from 'antd/lib/input/TextArea'
import dateLocale from 'antd/es/date-picker/locale/et_EE'

const ReportForm = ({ visible, onSave, onCancel, initialValues, reportId, userId }, ref) => {
  const [form] = Form.useForm()
  return (
    <Modal
      visible={visible}
      title="Täida aruande üldandmed"
      okText="Uuenda"
      cancelText="Tühista"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onSave({ id: reportId, iduser: userId, ...values})
            onCancel()
          })
          .catch((info) => {
            console.log('Validate Failed:', info)
          })
      }}>
      <Form form={form} layout="vertical" name="form_in_modal" initialValues={initialValues}>
        <Form.Item name="client" label="Tellija">
          <Input />
        </Form.Item>
        <Form.Item name="contractor" label="Töövõtja">
          <Input />
        </Form.Item>
        <Form.Item name="date" label="Kuupäev">
          <DatePicker locale={dateLocale} format={'DD.MM.YYYY'} />
        </Form.Item>
        <Form.Item name="time" label="Kell" className="collection-create-form_last-form-item">
          <TimePicker format={'H:mm'} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ReportForm
