import React, {useContext, useEffect, useState} from "react";
import { DeleteOutlined, MoreOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Table, Menu, Tooltip, Dropdown } from "antd";
import '../../../App.css';
import {useStoreActions, useStoreState} from "easy-peasy";
import InputCell from "../../../components/InputCell";
import CostOptionsContext from "../../CostOptionsContext";


function getCellReference(index, field) {
    return index.toString() + field
}

export const PaCostBudgetTable = ({offer_nr}) => {

    const [activeCell, setActiveCell] = useState(false)

    const {formOptions, setFormOptions} = useContext(CostOptionsContext)

    const bills = useStoreState(state => state.bills.bills)
    const budget = useStoreState(state => state.budget.budget)
    const budgetSubtotals = useStoreState(state => state.budget.budgetSubtotals)
    const loading = useStoreState(state => state.budget.loading)
    const setLoading = useStoreActions(actions => actions.budget.setLoading)
    const fetchBudget = useStoreActions(actions => actions.budget.apiFetchBudget)
    const delRow = useStoreActions(actions => actions.budget.apiDeleteBudgetRow)
    const addRow = useStoreActions(actions => actions.budget.apiAddBudgetRow)
    const updateRow = useStoreActions(actions => actions.budget.apiUpdateBudgetRow)

    const handleRowAdd = async (record) => {
        await addRow({
            offer_nr: offer_nr,
            sortorder: record.sortorder,
            idoffer: record.idoffer
        })
    }

    const handleRowDelete = async (id) => {
        await delRow({id: id})
        await fetchBudget({offer_nr: offer_nr})
    }

    const handleRowUpdate = async (payload) => await updateRow(payload)

    useEffect(() => {
        //console.log("trigger load")
        let payload = {offer_nr: offer_nr}
        fetchBudget(payload)
    }, [offer_nr])



    async function handleChange(value, field, index, record) {

        console.log("handling change...", value, field, index, record['id'])
        // if either previous value or new value exist
        if (record[field] || value) {

            let newValueString = value ? value.toString() : false
            let oldValueString = record[field] ? record[field].toString() : false

            if (newValueString !== oldValueString) {
                console.log("ONBLUR/onchange/change detected, updating...")
                record[field] = value
                let payload = {
                    value: value,
                    field: field,
                    id: record['id'],
                }

                await handleRowUpdate(payload)
                await setActiveCell(false)
                await fetchBudget({offer_nr: offer_nr})

            } else setActiveCell(false)
        } else setActiveCell(false)
    }

    const getInputCell = (index, field, rowsCount, record, round = 0) => record.row_type === 'budget' ? <InputCell
            type="input"
            onClick={() => setActiveCell(getCellReference(index, field))}
            onBlur={value => handleChange(value, field, index, record)}
            onUpdate={value => handleChange(value, field, index, record)}
            onMoveUp={(value) => setActiveCell(getCellReference(index > 0 ? index + value : 0, field))}
            onMoveDown={(value) => setActiveCell(getCellReference(index < rowsCount - 1 ? index + value : rowsCount - 1, field))}
            active={activeCell === getCellReference(index, field)}
            defaultValue={field !== "amount1_at_offer" && typeof  record[field] === 'number' && round > 0 ? record[field].toFixed(round) : field === "amount1_at_offer" && record[field] === 1 ? null : record[field]}/>
        :
        ['bill_path', 'cost_description', 'sum_at_offer', 'sum_target'].includes(field) ? record[field] : null

    const getSelectCell = (index, valueField, titleField, rowsCount, selectProps, record) => record.row_type === 'budget' ?
        <InputCell
            type="select"
            selectProps={selectProps}
            onClick={() => setActiveCell(getCellReference(index, titleField))}
            onUpdate={value => handleChange(value, valueField, index, record)}
            onMoveLeft={(value) => setActiveCell(getCellReference(index, titleField, -1, false))}
            onMoveRight={(value) => setActiveCell(getCellReference(index, titleField, 1, false))}

            active={activeCell === getCellReference(index, titleField)}
            defaultValue={record[valueField]}
            defaultTitle={record[titleField]}/>
        :
        ['cost_type'].includes(titleField) ? record[titleField] : null

    let rows = budget ? budget.length : 0


    function returnSelectPropsByCostType(costType) {

        const mapping = {
            2: 14,
            3: 14,
            4: 15,
            5: 11
        }

        let value = 0
        if (mapping.hasOwnProperty(costType)) value = mapping[costType]


        return {
            optionsList: formOptions.formoptions[value],
            valueField: "value",
            titleField: "name"

        }
    }


    const columns = [
        //{title: "#", dataIndex: "idoffer", key: "idoffer",},
        //{title: "#", dataIndex: "budgets_id", key: "budgets_id",},
        //{title: "#", dataIndex: "sortorder", key: "sortorder",},
        {
            title: 'Bill', dataIndex: 'bill_path', key: 'bill_path',
            render: text => <Tooltip
                title={bills.flat ? bills.flat.find(bill => bill.path === text)['full_string'] : ''}> {text } </Tooltip>
            //render: text => text
        },
        {
            title: "Nimetus", dataIndex: "cost_description", key: "cost_description",
            render: (text, record, index) => {

                let myText = text

                if (record.cost_description && record.cost_standard) {
                    if (record.cost_description !== record.cost_standard) myText = <div>{text} <br/>
                        <small style={{color: 'gray'}}>{record.cost_standard}</small>
                    </div>
                }

                if (record.row_type === 'budget') return <div className="ml-3">{myText}</div>
                if (record.row_type === 'costtype') return <div className="ml-1">{myText}</div>
                return myText
            }
        },
        {
            title: "Standard", dataIndex: "cost_standard", key: "cost_standard",
            //render: (text, record, index) => getSelectCell(index, 'cost_identifier', 'cost_identifier', rows, {
            //    optionsList: formOptions.formoptions[12],
            //    valueField: "value",
            //    titleField: "name"
            //
            //}, record)
            render: (text, record, index) => getSelectCell(index, 'cost_standard', 'cost_standard', rows, returnSelectPropsByCostType(record.cost_type) , record)
        },
        {
            title: "Ident", dataIndex: "cost_identifier", key: "cost_identifier",
            render: (text, record, index) => getSelectCell(index, 'cost_identifier', 'cost_identifier', rows, {
                optionsList: formOptions.formoptions[12],
                valueField: "value",
                titleField: "name"
            }, record)
        },
        {
            title: "Kulutüüp", dataIndex: "cost_type_name", key: "cost_type_name",
            render: (text, record, index) => getSelectCell(index, 'cost_type', 'cost_type_name', rows, {
                optionsList: formOptions.cost_types,
                valueField: "id",
                titleField: "name"
            }, record)
        },
        {
            title: "Ühik", dataIndex: "unit", key: "unit",
            render: (text, record, index) => getInputCell(index, 'unit', rows, record)
        },
        {
            title: "Leping", children: [

                {
                    title: "Kogus 1", dataIndex: "amount1_at_offer", key: "amount1_at_offer", align: "right",
                    //className: 'projection_actual',
                    render: (text, record, index) => getInputCell(index, 'amount1_at_offer', rows, record, 2)
                },
                {
                    title: "Kogus 2", dataIndex: "amount2_at_offer", key: "amount2_at_offer", align: "right",
                    //className: 'projection_actual',
                    render: (text, record, index) => getInputCell(index, 'amount2_at_offer', rows, record, 2)
                },
                {
                    title: "Ühiku hind ", dataIndex: "unit_price_at_offer", key: "unit_price_at_offer", align: "right",
                    //className: 'projection_actual',
                    render: (text, record, index) => getInputCell(index, 'unit_price_at_offer', rows, record, 2)
                },
                {
                    title: "Summa", dataIndex: "sum_at_offer", key: "sum_at_offer", align: "right",
                    //className: 'projection_actual',
                    //render: (text, record, index) => getInputCell(index, 'sum_at_offer', rows, record, 2)
                    render: (text, record, index) => record.row_type === 'other' ?
                        (budgetSubtotals ? budgetSubtotals.find(r => r.path === record.bill_path).subtotalContract : 0).toFixed(0)
                        :
                        (record.amount1_at_offer* record.amount2_at_offer*record.unit_price_at_offer).toFixed(0)
                },]
        },
        {
            title: "Eesmärk", children: [

                {
                    title: "Kogus 1", dataIndex: "amount1_target", key: "amount1_target", align: "right",
                    //className: 'projection_actual',
                    render: (text, record, index) => getInputCell(index, 'amount1_target', rows, record, 2)
                },
                {
                    title: "Kogus 2", dataIndex: "amount2_target", key: "amount2_target", align: "right",
                    //className: 'projection_actual',
                    render: (text, record, index) => getInputCell(index, 'amount2_target', rows, record, 2)
                },
                {
                    title: "Ühiku hind ", dataIndex: "unit_price_target", key: "unit_price_target", align: "right",
                    //className: 'projection_actual',
                    render: (text, record, index) => getInputCell(index, 'unit_price_target', rows, record, 2)
                },
                {
                    title: "Summa", dataIndex: "sum_target", key: "sum_target", align: "right",
                    //className: 'projection_actual',
                    //render: (text, record, index) => getInputCell(index, 'sum_target', rows, record, 2)
                     render: (text, record, index) => record.row_type === 'other' ?
                         (budgetSubtotals ? budgetSubtotals.find(r => r.path === record.bill_path).subtotalTarget : 0).toFixed(0)
                        :
                         (record.amount1_target* record.amount2_target*record.unit_price_target).toFixed(0)
                },]
        },
        {
            title: "Vahe", dataIndex: "sum_target", key: "diff", align: "right",
            //className: 'projection_actual',
            //render: (text, record, index) => record.sum_target - record.sum_at_offer
            render: (text, record, index) => record.row_type === 'other' ?
                         (budgetSubtotals ? budgetSubtotals.find(r => r.path === record.bill_path).difference : 0).toFixed(0)
                        :
                ((record.amount1_target* record.amount2_target*record.unit_price_target) - (record.amount1_at_offer* record.amount2_at_offer*record.unit_price_at_offer)).toFixed(0)
        },

        {
            title: <MoreOutlined />, dataIndex: "obj_id", key: "obj_id",
            render: (text, record, index) =>
                record.row_type === 'budget' ?
                    <Dropdown overlay={rowMenu(record)} trigger={['click']}>

                        <MoreOutlined onClick={(e) => e.preventDefault()} />

                    </Dropdown>
                    : null
            ,
        },
    ]


    function rowMenu(record) {
        return (
            <Menu>
                <Menu.Item key="0" onClick={() => handleRowAdd(record)} disabled>
                    <PlusCircleOutlined /> Lisa rida
                </Menu.Item>
                <Menu.Item key="0" onClick={() => handleRowUpdate(record)} disabled>
                    <PlusCircleOutlined /> Muuda rida
                </Menu.Item>

                <Menu.Divider/>
                <Menu.Item
                    key="3"
                    //disabled={record.fulfilled || record.is_special_bill_row ? true : false}
                    onClick={() => handleRowDelete(record.id)}><DeleteOutlined /> Kustuta rida
                </Menu.Item>
            </Menu>
        );
    }

    return (
        <Table
            columns={columns}
            //dataSource={offer.sort((a, b) => a.sortorder - b.sortorder)}
            rowClassName={(record, index) => {
                if (record.row_type === 'other') return 'budget_titlerow'
                if (record.row_type === 'costtype') return 'budget_costtyperow'
            }}
            dataSource={budget}
            //className={"table-striped-rows"}
            size="middle"
            rowKey={"idoffer"}
            //loading={loading}
            pagination={{
                defaultPageSize: 40,
                showSizeChanger: true,
                onChange: (page, pageSize) => console.log("page: ", page, "pagesize: ", pageSize),
                onShowSizeChange: (current, size) => console.log("page: ", current, "pagesize: ", size)
            }}
        />
    )
}

export default PaCostBudgetTable
