import fetch from 'unfetch'
import { notification } from 'antd'
import Cookies from 'js-cookie'

const openNotification = (args) => {
  notification.open(args)
}

export const URL = () => {
  let url = 'https://uuspa.herokuapp.com'
  if (window.location.hostname.includes('localhost')) url = 'http://localhost:5000'
  if (window.location.hostname.includes('127.0.0.1')) url = 'http://127.0.0.1:5000'

  if (window.location.hostname.includes('staging')) url = 'https://uuspa-staging.herokuapp.com'
  if (window.location.hostname.includes('demo')) url = 'https://uuspa-demo-staging.herokuapp.com'
  return url
}

export const URLforFW = () => {
  let url = 'https://fw.verston.ee'
  if (window.location.hostname.includes('localhost')) url = 'http://fieldworks.vercel.app'

  if (window.location.hostname.includes('staging')) url = 'https://fieldworks.vercel.app'
  if (window.location.hostname.includes('demo')) url = 'https://fieldworks.vercel.app'
  return url
}

async function API(endpoint, jwt = false, method = 'GET', body = false) {
  let myJwt = jwt ? jwt : Cookies.get('jwt')
  let url = URL()

  let params = ''
  let request = {
    headers: {
      Authorization: 'Bearer ' + myJwt,
      'Content-Type': 'application/json'
    },
    method: method
  }

  if (method === 'GET' || method === 'get') {
    body ? (params = '?' + new URLSearchParams(body).toString()) : (params = '')
  } else {
    request['body'] = JSON.stringify(body)
  }

  //console.log("Url, endpoint, params, request: ", url, endpoint, params, request, body)

  let response = await fetch(url + endpoint + params, request)
  let data = await response.json().catch((reason) => console.log('not json response!!!', reason))

  if (response.status > 299) {
    console.log('error: ', data)
    openNotification({
      message: 'Viga: ' + response.status.toString(),
      description: data.message ? data.message : JSON.stringify(data),
      duration: 10
    })
    return {
      ...data,
      status: response.status
    }
  }
  if (response.status === 401) {
    Cookies.remove('jwt')
    window.location.reload()
    return []
  }

  //console.log("Resolved data: ", data)
  return data
}

export async function mapAPI(endpoint, jwt = false, method = 'GET', body = false) {
  let url
  let mapUrl = 'https://maps.googleapis.com/maps/api/staticmap'
  let addressQueryUrl = 'https://maps.googleapis.com/maps/api/geocode/json'
  //let addressQueryUrl = "https://maps.googleapis.com/maps/api/place/findplacefromtext/json"

  endpoint === 'map' ? (url = mapUrl) : (url = addressQueryUrl)

  let params = ''
  let request = {
    // headers: {
    //     'Authorization': "Bearer " + myJwt,
    //     'Content-Type': 'application/json'
    // },
    method: method
  }

  if (method === 'GET' || method === 'get') {
    body ? (params = '?' + new URLSearchParams(body).toString()) : (params = '')
  } else {
    request['body'] = JSON.stringify(body)
  }

  //console.log("Url, endpoint, params, request: ", url, endpoint, params, request)

  let response = await fetch(url + params, request)
  let data = await response.json().catch((reason) => console.log('not json response!!!', reason))

  if (response.status !== 200) {
    console.log('error: ', data)
    openNotification({
      message: 'Viga: ' + response.status.toString(),
      description: JSON.stringify(data),
      duration: 0
    })
  }

  console.log('Resolved data: ', data)
  return data
}

export default API
