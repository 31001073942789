import { action, actionOn, computed, thunk } from "easy-peasy"
import API from "../../services/api"
import moment from "moment"



export const objectModel = {
  costs: {},              // costs with assignments, up to 10k rows
  objectOffers: [],
  income: [],             // income by date
  incomeDates: [],        // income sums grouped by by date
  activeDate: moment(),   // currently interesting date
  budget: [],             // set budget for cost assignments
  meta: {},               // metadata of this object
  prognosis: [],          // projections
  costDates: [],          // helper for cost copying
  undo: [],               // list to keep before and after states of costs for undo functionality

  selectedCostsIds: [],    // list of costs ID-s selected for further actions
  setSelectedCostsIds: action((state, payload) => state.selected = payload),

  // computed
  paverInUseDays: computed(state => {
    const myCosts = state.costs ? Object.values(state.costs) : null
    let dates = []

    // creating tables list
    if (myCosts && myCosts.length > 0) myCosts.forEach((cost) => {
      //console.log('cost_date', cost.cost_date, cost.res_name2)
      if (!dates.includes(cost.cost_date) && moment(cost.cost_date).format('YYYY-MM-DD') !== "1900-01-01" && cost.res_name2 && cost.res_name2.includes('laotur')) {

        dates.push(cost.cost_date)
      }
    })

    return dates.length

  }),

  costs_rows: computed(state => state.costs ? Object.values(state.costs).length : 0),

  totals: computed(state => {

    let costsToDate = state.costs ? Object.values(state.costs).reduce((sum, r) => sum + r.row_sum, 0) : 0
    let costsAsTargetToDate = state.income ? state.income.reduce((sum, r) => r.is_target_offer === 1 ? sum + (r.fulfilled_amount * r.unit_price) : sum, 0) : 0
    let incomeToDate = state.income ? state.income.reduce((sum, r) => r.is_target_offer === 1 ? sum + (r.fulfilled_income_total) : sum, 0) : 0
    let resultToDate = incomeToDate - costsToDate

    let offeredToClientTarget = state.income ? state.income.reduce((sum, r) => sum + r.hinnapakkumise_total, 0) : 0
    let akteeritavToClientTarget = state.income ? state.income.reduce((sum, r) => sum + r.akteerimiseks_total, 0) : 0
    let costTarget = state.income ? state.income.reduce((sum, val) => val.offered_total ? sum + val.offered_total : sum, 0) : 0

    //console.log('TYPES', typeof costTarget, typeof akteeritavToClientTarget)

    return {

      costsToDate: costsToDate, //.toFixed(0),
      costsAsTargetPriceToDate: costsAsTargetToDate, //.toFixed(0),
      incomeToDate: incomeToDate, // .toFixed(0),
      resultToDate: resultToDate, //.toFixed(0),
      grossMarginToDate: resultToDate / incomeToDate,

      offeredToClientTarget: offeredToClientTarget,
      akteeritavToClientTarget: akteeritavToClientTarget,
      costTarget: costTarget,
      grossMarginTarget: (parseFloat(akteeritavToClientTarget) - parseFloat(costTarget)) / parseFloat(akteeritavToClientTarget),
      prognosedIncome: 0,
      prognosedCost: 0,
      prognosedResult: 0
    }

  }),

  // returns a list of offers
  incomeOffersList: computed(state => {
    let list = []
    if (state.income) state.income.forEach(r => {
      if (!list.includes(r.offer_nr)) list.push(r.offer_nr)
    })
    return list
  }),

  // returns structured prognosis data for prognosis sheet - main logic
  structuredPrognosisData: computed(state => {
    if (state.prognosis) {
      // setting state - current confirmed prognosis date (status ===1)
      let actives = state.prognosis.filter((row) => row.is_current === 1)
      //let activePrognosis = actives.length > 0 ? moment(actives[0].date) : 0

      // filtering out target offers

      // creating a deep copy because we reallly reallly do not want to mess with original object income
      let myIncomeDeepCopy = JSON.parse(JSON.stringify(state.income))

      let targetIncome = myIncomeDeepCopy.filter((row) => row.is_target_offer === 1) || [{}]

      // filtering out target costs (where assignments contain special_row meaning specialrow_idoffer has value
      let targetedCosts = {}

      Object.values(state.costs).forEach((cost) => {
        if ('assignments' in cost && cost.assignments.length > 0) {

          cost.assignments.forEach((ass) => {
            if (ass.specialrow_idoffer) {
              if (ass.assignment_sum > 0) {
                if (!(ass.specialrow_idoffer in targetedCosts)) {
                  targetedCosts[ass.specialrow_idoffer] = ass.assignment_sum
                  console.log("initial", ass.specialrow_idoffer, ass.assignment_sum)
                } else {
                  targetedCosts[ass.specialrow_idoffer] += ass.assignment_sum
                  //console.log("add", ass.specialrow_idoffer, ass.assignment_sum)
                }
              }
            }
          })
        }
      })

      //console.log("STATETARGETEDCOSTS", targetedCosts)

      //filtering out target prognosis (date match)
      //let targetPrognosis = []
      //if (activePrognosis) {
      //  let  targetPrognosis = state.prognosis.filter(
      //        (row) => moment(row.date).format("DD.MM.YYYY") === activePrognosis.format("DD.MM.YYYY"))
      //}

      let targetPrognosis = actives

      //filtering out draft prognosis should there be any (status match is_current ==2)
      let draftPrognosis = state.prognosis.filter((row) => row.is_current === 2)

      // mapping prognosis with offer
      let filteredIncome = []

      targetIncome.forEach((row) => {
        let myPrognosis = targetPrognosis.filter((prognosisRow) => prognosisRow.offer_id === row.idoffer && prognosisRow.is_current === 1)
        let myDraftPrognosis = draftPrognosis.filter((prognosisRow) => prognosisRow.offer_id === row.idoffer)

        // in case there is prognosis to be found
        if (myPrognosis.length > 0) {
          console.log("prognosis match", myPrognosis)
          row['prognosis_id'] = myPrognosis[0].id
          row['prognosis_amount'] = myPrognosis[0].amount
          row['prognosis_price'] = myPrognosis[0].price
          row['prognosis_total'] = row['prognosis_amount'] * row['prognosis_price']
          row['income_prognosis_total'] = row['prognosis_amount'] * row['akteerimiseks_unit_price']
          row['result_prognosis_total'] = row['income_prognosis_total'] - row['prognosis_total']
          row['has_prognosis'] = true
        } else {
          row['prognosis_id'] = false
          row['prognosis_amount'] = row.offered
          row['prognosis_price'] = row.unit_price
          row['prognosis_total'] = row['prognosis_amount'] * row['prognosis_price']
          row['income_prognosis_total'] = row['prognosis_amount'] * row['akteerimiseks_unit_price']
          row['result_prognosis_total'] = row['income_prognosis_total'] - row['prognosis_total']
          row['has_prognosis'] = false
        }

        if (myDraftPrognosis.length > 0) {
          row['draft_id'] = myDraftPrognosis[0].id
          row['draft_amount'] = myDraftPrognosis[0].amount
          row['draft_price'] = myDraftPrognosis[0].price
          row['draft_total'] = row['draft_amount'] * row['draft_price']
          row['draft_income_total'] = row['draft_amount'] * row.akteerimiseks_unit_price
          row['has_draft'] = true
        } else {
          row['draft_id'] = false
          row['draft_amount'] = myPrognosis.length > 0 ? myPrognosis[0].amount : row.offered
          row['draft_price'] = myPrognosis.length > 0 ? myPrognosis[0].price : row.unit_price
          row['draft_total'] = row['draft_amount'] * row['draft_price']
          row['draft_income_total'] = row['draft_amount'] * row.akteerimiseks_unit_price
          row['has_draft'] = false
        }

        // setting an identificator for later on to determine if this is a content row
        row['is_content_row'] = true

        filteredIncome.push(row)
      })


      // this now on only for visual representation, sum rows etc. All business logic above.
      let billslist = []
      filteredIncome.forEach((row) => {
        if (!billslist.includes(row.bill_name)) {
          billslist.push(row.bill_name)
        }
      })

      // creating tree data for table layout, sums etc
      let strucPrognosisData = []
      billslist.forEach((item, index) => {

        // filtering out specific rows from filtered income where bill_name matches name in bills list
        let children = filteredIncome.filter((row) => row['bill_name'] === item)

        // filtering out only special bills rows
        children = children.filter((row) => row['is_special_bill_row'] === 1)

        // giving each child row a prop to know how many there are in a group, used later on
        // for up/dn arrow navigation
        children.forEach((row) => row['rowCount'] = children.length)

        let row = {
          'idoffer': item, // this is here only because Table component needs a key
          'is_content_row': false, // to disable editing etc
          'art_name': item,
          'art_id': '',
          'offered_total': filteredIncome.reduce(
            (running_total, row) => row['bill_name'] === item ? row.offered_total + running_total : running_total, 0),
          'income_prognosis_total': filteredIncome.reduce(
            (running_total, row) => row['bill_name'] === item ? row.income_prognosis_total + running_total : running_total, 0),
          'prognosis_total': filteredIncome.reduce(
            (running_total, row) => row['bill_name'] === item ? row.prognosis_total + running_total : running_total, 0),
          'total': filteredIncome.reduce(
            (running_total, row) => row['bill_name'] === item ? row.total + running_total : running_total, 0),
          'actual_cost_to_date': filteredIncome.reduce(
            (running_total, row) => row['bill_name'] === item ? row.fulfilled_total + running_total : running_total, 0),
          'actual_income_to_date': filteredIncome.reduce(
            (running_total, row) => row['bill_name'] === item ? row.fulfilled_income_total + running_total : running_total, 0),
          'draft_total': filteredIncome.reduce(
            (running_total, row) => row['bill_name'] === item ? row.draft_total + running_total : running_total, 0),
          'draft_income_total': filteredIncome.reduce(
            (running_total, row) => row['bill_name'] === item ? row.draft_income_total + running_total : running_total, 0),
          'children': children,


        }
        strucPrognosisData.push(row)
        console.log("strprognosis", strucPrognosisData)
      })

      return strucPrognosisData
    }
  }),

  // actions
  setCostDates: action((state, payload) => state.costDates = payload),
  setIncomeDates: action((state, payload) => state.incomeDates = payload),
  setObjectMeta: action((state, payload) => state.meta = payload),
  setObjectCosts: action((state, payload) => state.costs = payload),
  setObjectOffers: action((state, payload) => state.objectOffers = payload),
  setObjectActiveDate: action((state, payload) => state.activeDate = payload),

  setCostRow: action((state, payload) => state.costs[Object.keys(payload)[0]] = Object.values(payload)[0]),
  delCostRow: action((state, payload) => delete state.costs[payload]),

  setBudget: action((state, payload) => state.budget = payload),
  setIncome: action((state, payload) => state.income = payload),
  setPrognosis: action((state, payload) => state.prognosis = payload),

  addUndo: action((state, payload) => state.undo.push(payload)),
  setUndo: action((state, payload) => state.undo = payload),

  // thunks - Prognosis
  apiFetchObjectOffers: thunk(async (actions, id) => {
    const { data } = await API("/api/object/offers?idobject=" + id)
    actions.setObjectOffers(data)
    return data
  }),

  apiFetchIncomeDates: thunk(async (actions, obj_id) => {
    const dates = await API("/api/object/income/dates?obj_id=" + obj_id)
    actions.setIncomeDates(dates.data)
    console.log("getting dates", dates.data)
  }),

  apiFetchCostDates: thunk(async (actions, obj_id) => {
    const dates = await API("/api/object/costdates?obj_id=" + obj_id)
    actions.setCostDates(dates.data)
    console.log("getting dates", dates.data)
  }),

  apiFetchPrognosis: thunk(async (actions, payload) => {
    //console.log("getting prognosis: ", payload)
    const prognosis = await API("/api/object/prognosis", false, "GET", payload)
    actions.setPrognosis(prognosis.data)
  }),

  apiUpdatePrognosis: thunk(async (actions, payload, helpers) => {
    //console.log("getting prognosis: ", payload)
    let idobject = helpers.getStoreState().object.meta.idobject
    console.log("idobject from state: ", idobject, payload)
    await API("/api/object/prognosis", false, "POST", payload)
    actions.apiFetchPrognosis({ obj_id: idobject })
  }),

  apiActivatePrognosis: thunk(async (actions, payload) => {
    await API("/api/object/prognosis", false, "PUT", payload)
    actions.apiFetchPrognosis(payload)
  }),

  // thunks - Income
  apiLoadIncome: thunk(async (actions, payload) => {
    console.log("getting income:", payload)
    const income = await API("/api/object/income", false, "GET", payload) // date, objid
    actions.setIncome(income.data)
  }),
  apiUpdateIncome: thunk(async (actions, payload) => {
    await API("/api/object/income", false, "POST", payload)
    actions.apiLoadIncome(payload)
  }),

  // thunks - META
  apiLoadMeta: thunk(async (actions, objid) => {
    const { data } = await API("/api/object/meta?objid=" + objid)
    data.workers = data?.workers ? data.workers.split(',').map(str => parseInt(str)) : []
    actions.setObjectMeta(data)
  }),

  // thunks - Costs

  apiCopyCosts: thunk(async (actions, payload) => {
    await API("/api/object/copy", false, "get", payload)
  }),

  apiLoadCosts: thunk(async (actions, objid) => {
    const objectCosts = await API("/api/object/costs?objid=" + objid)
    actions.setObjectCosts(objectCosts.data)
  }),

  apiLoadCostRow: thunk(async (actions, payload) => {
    const costRow = await API("/api/object/costs", false, 'GET', payload)
    actions.setCostRow(costRow.data)
  }),

  apiRollBackCost: thunk(async (actions, payload, helpers) => {

    let undo = helpers.getStoreState().object.undo.filter(r => r) // copy array
    if (undo.length > 0) {
      let costRow = undo[undo.length - 1]
      if (costRow.event === 'add') {
        const deletedRow = await API("/api/object/costs", false, "DELETE", costRow.rollbackPayload)
        actions.delCostRow(deletedRow.data)
      }
      if (costRow.event === 'update' || costRow.event === 'delete') {
        const costRowFetched = await API("/api/object/costs", false, "POST", costRow.rollbackPayload)
        actions.setCostRow(costRowFetched.data)
      }
      undo.splice(-1, 1)
      actions.setUndo(undo)
    }

  }),

  apiUpdateCost: thunk(async (actions, payload, helpers) => {

    const costs = helpers.getStoreState().object.costs

    let after = {}, before = {}
    before[payload.idcosts] = Object.assign({}, costs[payload.idcosts])
    after[payload.idcosts] = Object.assign({}, costs[payload.idcosts])
    after[payload.idcosts][payload.field] = payload.value

    let rollbackPayload = {
      value: before[payload.idcosts][payload.field],
      idcosts: payload.idcosts,
      field: payload.field
    }

    //console.log("UPDATE ROW:", payload,  before, after, rollbackPayload)

    actions.addUndo({
      'idcosts': payload.idcosts,
      'event': 'update',
      'before': before,
      'rollbackPayload': rollbackPayload,
      'after': after
    })

    const costRow = await API("/api/object/costs", false, "POST", payload)
    actions.setCostRow(costRow.data)
  }),

  apiAddCost: thunk(async (actions, payload) => {

    const addedRow = await API("/api/object/costs", false, "PUT", payload)

    let added = {}
    let idcost = Object.keys(addedRow.data)[0]

    added[idcost] = Object.values(addedRow.data)[0]

    actions.addUndo({
      'idcosts': Object.keys(added)[0],
      'event': 'add',
      'before': {},
      'rollbackPayload': { 'idcosts': idcost },
      'after': added
    })

    actions.setCostRow(addedRow.data)
  }),

  apiDeleteCost: thunk(async (actions, payload, helpers) => {

    const costs = helpers.getStoreState().object.costs

    let after = {}, before = {}
    before[payload.idcosts] = Object.assign({}, costs[payload.idcosts])

    //console.log("UPDATE ROW: new row", myRow, Object.keys(myRow)[0], Object.values(myRow)[0], "old row:",  costData[payload.idcosts])

    actions.addUndo({
      'idcosts': payload.idcosts,
      'event': 'delete',
      'before': before,
      'rollbackPayload': {
        value: before[payload.idcosts]['status'],
        idcosts: payload.idcosts,
        field: 'status'
      },
      'after': after
    })


    const deletedRow = await API("/api/object/costs", false, "DELETE", payload)
    actions.delCostRow(deletedRow.data)
  }),

  // thunks Assignments

  // Assignment to be set: can be any number of cost rows, that all receive the same assignment
  // This is triggered when row or rows ID-s are set

  assignmentsActiveCostRows: [],
  setAssignmentsActiveCostRows: action((state, payload) => state.assignmentsActiveCostRows = payload),

  // Active assignments to start with and later manipulate, triggered when ActiveIdCost changes
  // Only relevant, if only one row in Active Rows

  assignmentsCurrentList: [],
  setAssignmentsCurrentList: action((state, payload) => state.assignmentsCurrentList = payload),

  // if the active rows change and new list is one row, calculate assignmentsCurrentList
  onSetAssignmentsActiveCostRows: actionOn(
    (actions, storeActions) => storeActions.object.setAssignmentsActiveCostRows,
    (state, target) => {

      let myAssignments = []
      if (state.assignmentsActiveCostRows.length === 1) {

        let record = state.assignmentsActiveCostRows[0]

        if ('assignments' in record) {
          myAssignments = record.assignments.map(assignment => ({
            id: assignment.id,  // budget row id
            name: assignment.name,
            assigned: assignment.assignment,
            special_name: null,
            bill_path: assignment.bill_path
          }))
        }

      }
      state.assignmentsCurrentList = myAssignments
    }),

  assignmentsInProgressTotal: computed(state => {

    let currentAssignments = 0
    if (state.assignmentsCurrentList) {
      currentAssignments = state.assignmentsCurrentList.reduce((sum, current) => sum + current.assigned, 0)
    }
    return currentAssignments * 100

  }),


  apiLoadBudget:
    thunk(async (actions, objid) => {
      const budget = await API("/api/object/budget?objid=" + objid)
      actions.setBudget(budget.data)

    }),

  apiSetAssignment:
    thunk(async (actions, payload) => {
      console.log("apiSetAssignment: ", payload)
      await API("/api/object/assign", false, "POST", payload)
    })


}

