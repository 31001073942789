import React from 'react'
import { DatePicker } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import moment from 'moment'
import dateLocale from 'antd/es/date-picker/locale/et_EE'

const PaObjectTabsDatepicker = () => {
  const objectActiveDate = useStoreState((state) => state.object.activeDate)
  const setObjectActiveDate = useStoreActions((actions) => actions.object.setObjectActiveDate)
  const costs = useStoreState((state) => state.object.costs)
  const undo = useStoreState((state) => state.object.undo)
  const setUndo = useStoreActions((actions) => actions.object.setUndo)

  const dateFormat = 'DD.MM.YY'

  let costDates2 = []

  Object.values(costs).forEach((cost) => {
    if (!costDates2.includes(cost.cost_date)) {
      costDates2.push(moment(cost.cost_date).format('DD.MM.YY'))
    }
  })

  function onChange(e) {
    setObjectActiveDate(e)
    setUndo([])
  }

  return (
    <DatePicker
      locale={dateLocale}
      placeholder="Vali kuupäev"
      value={objectActiveDate}
      className="mr-1"
      style={{ maxWidth: 120 }}
      format={dateFormat}
      onChange={(v) => onChange(v)}
      allowClear={false}
      dateRender={(current) => {
        const style = {}
        if (costDates2.includes(current.format('DD.MM.YY'))) {
          //console.log("current_datepicker", current)
          style.border = '1px solid #1890ff'
          style.borderRadius = '50%'
        }
        return (
          <div className="ant-calendar-date" style={style}>
            {current.date()}
          </div>
        )
      }}
    />
  )
}

export default PaObjectTabsDatepicker
