import React, { useState} from 'react';
import {useFormik} from 'formik';

import {useStoreActions} from "easy-peasy";
import {Row, Col, Input, Button, Modal, Card, Alert, notification} from 'antd'
import API from "../services/api";

const UserProfile = ({visible, onClose, closable}) => {

    const [error, setError] = useState({})

    const getMe = useStoreActions(state => state.user.apiFetchMe)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            newPassword1: '',
            newPassword2: ''
        },
        onSubmit: async (values) => {
            if (validate(values)) {

                let payload = Object.assign({}, values)

                API("/api/selfreset", false, 'POST', payload)
                    .then((v) => {
                        if (v.data === "ok") {
                            onClose()
                            getMe()

                        }
                        if (v.data === 'no current password match') notification.open({
                            message: "Viga",
                            description: "Valesti sisestatud PRAEGUNE parool!",
                            duration: 4
                        })
                    })
            }

        }
    });


    function validate(values) {

        let myErrors = {}
        if (values.newPassword1 !== values.newPassword2) {
            myErrors = {newPassword2: "Katsume ikka samasugused paroolid kirjutada!", ...myErrors}
        }

        if (values.newPassword1.length < 8) {
            myErrors = {newPassword2: "Parool peaks olema vähemalt 8 tähemärki!", ...myErrors}
        }

        if (values.newPassword1 === "uusparool") {
            myErrors = {newPassword2: "Seda parooli ei saa uuesti panna!", ...myErrors}
        }

        console.log("errors found", myErrors, Object.keys(myErrors).length)
        setError(myErrors)

        return Object.keys(myErrors).length < 1
    }


    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            onClose={onClose}
            footer={null}
            closable={closable}
            maskClosable={closable}

        >

            <Card bordered={false}>
                {Object.keys(error).length > 0 ?
                    <Row>
                        <Col span={24}>
                            <Alert
                                message="Viga:"
                                description={Object.values(error).map(e => <div>{e}</div>)}
                                type="error"
                                showIcon
                            />
                        </Col>
                    </Row>
                    :
                    null
                }


                <form onSubmit={formik.handleSubmit}>

                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="mt-2">
                                <label htmlFor="password">Praegune parool</label>
                            </div>
                            <Input
                                id="password"
                                name="password"
                                type="password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="mt-2">
                                <label htmlFor="newPassword1">Uus parool</label>
                            </div>
                            <Input
                                id="newPassword1"
                                name="newPassword1"
                                type="password"
                                onChange={formik.handleChange}
                                value={formik.values.newPassword1}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="mt-2">
                                <label htmlFor="NewPassword2">Uus parool uuesti</label>
                            </div>
                            <Input
                                id="newPassword2"
                                name="newPassword2"
                                type="password"
                                onChange={formik.handleChange}
                                value={formik.values.newPassword2}
                                onPressEnter={formik.submitForm}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" className="mt-3" onClick={() => formik.submitForm()}>Muuda</Button>
                        </Col>
                    </Row>
                </form>

            </Card>

        </Modal>

    );
};

export default UserProfile