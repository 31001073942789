import React from 'react'
import { Tooltip } from "antd"
import { useStoreState, useStoreActions } from "easy-peasy"


const PaObjectsFavourite = ({ idobject }) => {
  const me = useStoreState(state => state.user.me)
  const setMe = useStoreActions(actions => actions.user.apiPostMe)

  const { prefs } = me

  const isFav = prefs && prefs.myobjects ? prefs.myobjects.includes(idobject) : null

  const toggleFav = () => {
    if (idobject === '-') return
    
    const myObjects = [...prefs.myobjects]
    if (isFav) {
      setMe({ prefs: { ...prefs, myobjects: myObjects.filter(id => id !== idobject) } })
    } else {
      myObjects.push(idobject)
      setMe({ prefs: { ...prefs, myobjects: myObjects } })
    }
  }

  return (isFav
    ?
    <span><Tooltip title="Lemmik"> <i className="fas fa-star" style={{ color: '#e3c30e' }} onClick={() => toggleFav()}> </i></Tooltip> </span>
    :
    <span> <Tooltip title="Tee lemmikuks"><i className="fas fa-star " style={{ color: '#bbc2c3' }} onClick={() => toggleFav()}> </i></Tooltip> </span>
  )
}

export default PaObjectsFavourite