import React from 'react';
import { AppstoreOutlined, FileTextOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from "antd";


const PaObjectsStatus = ({status, type = "icon"}) => {

    if (type === 'icon') {
        if (status === 3) return <span><Tooltip title="Töös"><FileTextOutlined style={{color: 'green'}} /></Tooltip></span>;
        if (status === 4) return <span><Tooltip title="Uus"><AppstoreOutlined style={{color: 'orange'}} /></Tooltip></span>;
        if (status === 6) return <span><Tooltip title="Arhiveeritud"><i className="fas fa-archive"> </i></Tooltip></span>
        return status
    }

    if (type === 'tag') {
        if (status === 3) return <Tag color="geekblue"> Töös </Tag>
        if (status === 4) return <Tag color="gold"> Uus </Tag>
        if (status === 6) return <Tag> Objekt ei ole töös </Tag>
        return <Tag> Objekt ei ole töös </Tag>
    }

}

export default PaObjectsStatus