import { useContext, useEffect, useState } from 'react'
import CostOptionsContext from '../../../CostOptionsContext'
import { useStoreActions, useStoreState } from 'easy-peasy'
import InputCell from '../../../InputCell'

import {
  ClockCircleOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  PieChartOutlined,
  PieChartTwoTone,
  QuestionCircleOutlined,
  FileDoneOutlined,
  CloseOutlined
} from '@ant-design/icons'

import { Icon as LegacyIcon } from '@ant-design/compatible'

import { URLforFW } from '../../../../services/api'

import { Button, Table, Tag, Tooltip, Empty, Collapse } from 'antd'
import React from 'react'
import moment from 'moment'
import StatusIcon from '../../StatusIcon'
import PaBeginRefresh from '../../../PaBeginRefresh'

import PaCostRowMenu from '../costs/CostRowMenu'
import PaCostBillDivisionV1 from '../bills/v1/CostBillDivision'
import PaCostBillDivisionV2 from '../bills/v2/CostBillDivision'
import PaGroupBillDivision from '../bills/v1/GroupBillDivision'
import PaGroupBillDivisionV2 from '../bills/v2/GroupBillDivision'
import PaObjectSorter from '../../../ObjectSorter'

const { Panel } = Collapse

const PaObjectDayCostTable = ({ index, addButton = true, billsButton = true, showfooter = false, filterOutET = false }) => {
  //console.log("Testing a, b", PaObjectSorter(undefined, undefined))

  // is this temporary? for Eesti Teed personnell confirmation ONLY
  //const updateCosts = useStoreActions(actions => actions.control.apiUpdateCosts)
  const meta = useStoreState((state) => state.object.meta)
  const user = useStoreState((state) => state.user.me)
  const userId = user?.id
  const objectId = meta?.idobject

  const { formOptions, setFormOptions } = useContext(CostOptionsContext)
  const [activeCell, setActiveCell] = useState(false)
  const [selectedMaterialWarehouse, setSelectedMaterialWarehouse] = useState()

  const isBeginLoading = useStoreState((state) => state.beginSync.loading)
  const editAllowed = useStoreState((state) => state.user.editAllowed)

  const costData = useStoreState((state) => state.object.costs)
  const updateCostData = useStoreActions((actions) => actions.object.apiUpdateCost)
  //const setCostRowTmp = useStoreActions(actions => actions.object.setCostRow);
  const addCostData = useStoreActions((actions) => actions.object.apiAddCost)
  const objectActiveDate = useStoreState((state) => state.object.activeDate)

  const selected = useStoreState((state) => state.object.selectedCostsIds)
  const setSelected = useStoreState((actions) => actions.object.setSelectedCostsIds)

  const fetchRoads = useStoreActions((actions) => actions.road.fetch)
  const addRoad = useStoreActions((actions) => actions.road.add)
  const roads = useStoreState((state) => state.road.list)

  const fetchOfferRows = useStoreActions((actions) => actions.road.fetchOffers)
  const objectOfferRows = useStoreState((state) => state.road.offers)

  const mappedRoads = roads
    .filter((road) => moment(road?.date).isSame(objectActiveDate, 'day'))
    .map(({ idroad, name, road_number, start, end }) => ({
      label: `(${road_number || 'määramata'}) ${name || 'määramata'} [${start || 'määramata'} - ${end || 'määramata'} km]`,
      value: idroad
    }))
  const mappedOffers = objectOfferRows.map(({ art_name, art_id }) => ({ label: art_name, value: art_id }))

  useEffect(() => {
    fetchRoads(objectId)
    fetchOfferRows(objectId)
  }, [])

  async function handleChange(value, field, index, rowId) {
    //console.log("handling change...", value, field, index, rowId)
    // if either previous value or new value exist
    if (field === 'from_warehouse' && value) {
      setSelectedMaterialWarehouse(value)
    }
    if ((costData[rowId][field] && typeof costData[rowId][field] === 'object') || (value && typeof value === 'object')) {
      setActiveCell(false)
      return
    }
    if (costData[rowId][field] || (value && (typeof value === 'string' || typeof value === 'number'))) {
      let newValueString = value ? value.toString() : false
      let oldValueString = costData[rowId][field] ? costData[rowId][field].toString() : false

      if (newValueString !== oldValueString) {
        //console.log("ONBLUR/onchange/change detected, updating...")
        let payload = {
          value: value,
          idcosts: rowId,
          field: field
        }

        setActiveCell(false)
        await handlePayload(payload)
      } else setActiveCell(false)
    } else setActiveCell(false)
  }

  async function handlePayload(payload) {
    await updateCostData(payload)
  }

  const CostTypeColumnDefs = [
    // # 1 on list, index 0
    {
      costType: 8,
      title: 'Tööjõud tööaja arvestusest',
      taborder: [],
      columns: [
        {
          title: <PieChartOutlined />,
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            record.status !== 4 ? (
              meta.costing_version === 2 ? (
                <PaCostBillDivisionV2 records={record} />
              ) : (
                <PaCostBillDivisionV1 records={record} />
              )
            ) : (
              <Tooltip title="Kinnitamata töölehega tööajale ei saa bille määrata/muuta">
                <PieChartTwoTone twoToneColor="#e8e8e8" />
              </Tooltip>
            )
        },
        //{
        //    title: 'Töötaja', dataIndex: 'cost_description', key: 'cost_description',
        //    sorter: (a, b) => PaObjectSorter(a.cost_description, b.cost_description),
        //},
        {
          title: (
            <Tooltip title={'Kulu on seotud töölehega'}>
              <FileDoneOutlined />
            </Tooltip>
          ),
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            record.idworksheet ? (
              <Tooltip title={<div>Tööleht #{record.idworksheet} Kliki, et avada!</div>}>
                <a>
                  <FileDoneOutlined
                    onClick={() => window.open(URLforFW() + '/objects/' + record.obj_id + '/worksheets/' + record.idworksheet, '_blank')}
                  />
                </a>
              </Tooltip>
            ) : null
        },
        {
          title: 'Töötaja',
          dataIndex: 'cost_description',
          key: 'cost_description',
          sorter: (a, b) => PaObjectSorter(a.cost_description, b.cost_description),
          render: (text, record, index) => (text ? text : record.res_name1)
        },
        {
          title: 'Algus',
          dataIndex: 'cost_start',
          key: 'cost_start',
          align: 'right',
          sorter: (a, b) => PaObjectSorter(a.cost_start, b.cost_start)
        },
        {
          title: 'Lõpp',
          dataIndex: 'cost_end',
          key: 'cost_end',
          align: 'right',
          sorter: (a, b) => PaObjectSorter(a.cost_end, b.cost_end)
        },
        {
          title: 'Tunde',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          sorter: (a, b) => a.amount - b.amount
        },
        {
          title: 'Sh. lisatud',
          dataIndex: 'amount_added',
          key: 'amount_added',
          align: 'right',
          sorter: (a, b) => a.amount_added - b.amount_added
        },
        {
          title: 'Tüüp',
          dataIndex: 'unit',
          key: 'unit',
          sorter: (a, b) => a.unit - b.unit
        },
        {
          title: 'Tariif',
          dataIndex: 'unit_price',
          key: 'unit_price',
          align: 'right',
          sorter: (a, b) => a.unit_price - b.unit_price
        },
        {
          title: 'Summa',
          dataIndex: 'row_sum',
          key: 'row_sum',
          align: 'right',
          sorter: (a, b) => a.row_sum - b.row_sum,
          render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
        },
        {
          title: <InfoCircleOutlined />,
          dataIndex: 'status',
          key: 'status',
          align: 'right',
          render: (text, record, index) =>
            text !== 4 ? (
              <StatusIcon value={record.status} ignore={['4']} />
            ) : (
              <Tooltip title="Tööaeg on kinnitamata">
                <i className="far fa-check-circle" style={{ color: '#fa8c16' }}></i>
              </Tooltip>
            )
        }
      ]
    },
    {
      // index 1

      //
      // Heads UP: contains temporary changes to accommodate ET integration!!
      //

      costType: 1,
      title: <div>Lisatud tööjõukulud</div>,
      taborder: ['res_name1', 'cost_start', 'cost_end', 'amount', 'unit', 'unit_price'],
      columns: [
        //{title: 'id', dataIndex: 'resource_id', key: 'resource_id_temp'},
        {
          title: <PieChartOutlined />,
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            meta.costing_version === 2 ? <PaCostBillDivisionV2 records={record} /> : <PaCostBillDivisionV1 records={record} />
        },

        {
          title: 'Töötaja',
          dataIndex: 'resource_id',
          key: 'resource_id',
          sorter: (a, b) => PaObjectSorter(a.res_name1, b.res_name1),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'resource_id',
              'res_name1',
              rows,
              {
                optionsList: formOptions.employee_list,
                valueField: 'idresources',
                titleField: 'resource'
              },
              record
            )
        },

        {
          title: 'Algus',
          dataIndex: 'cost_start',
          key: 'cost_start',
          align: 'right',
          sorter: (a, b) => PaObjectSorter(a.cost_start, b.cost_start),
          render: (text, record, index) => getTimeInputCell(index, 'cost_start', rows, record)
        },
        {
          title: 'Lõpp',
          dataIndex: 'cost_end',
          key: 'cost_end',
          align: 'right',
          sorter: (a, b) => PaObjectSorter(a.cost_end, b.cost_end),
          render: (text, record, index) => getTimeInputCell(index, 'cost_end', rows, record)
        },
        {
          title: 'Kogus',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          sorter: (a, b) => a.amount - b.amount,
          render: (text, record, index) => getInputCell(index, 'amount', rows, record)
        },
        // {
        //     title: 'Sh. lisatud', dataIndex: 'amount_added', key: 'amount_added', align: "right",
        //     render: (text, record, index) => getInputCell(index, 'amount_added', rows, record)
        // },
        {
          title: 'Tüüp',
          dataIndex: 'unit',
          key: 'unit',
          sorter: (a, b) => PaObjectSorter(a.unit, b.unit),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'unit',
              'unit',
              rows,
              {
                optionsList: formOptions.formoptions[1],
                valueField: 'value',
                titleField: 'name'
              },
              record
            )
        },
        {
          title: 'Tariif',
          dataIndex: 'unit_price',
          key: 'unit_price',
          align: 'right',
          sorter: (a, b) => a.unit_price - b.unit_price,
          render: (text, record, index) =>
            getSelectCell(
              index,
              'unit_price',
              'unit_price',
              rows,
              {
                optionsList: formOptions.formoptions[2],
                valueField: 'value',
                titleField: 'name'
              },
              record
            )
        },
        {
          title: 'Summa',
          dataIndex: 'row_sum',
          key: 'row_sum',
          align: 'right',
          sorter: (a, b) => a.row_sum - b.row_sum,
          render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
        },
        //{
        //    title: '', dataIndex: 'row_sum', key: 'actions', width: "20px",
        //    render: (text, record, index) =>
        //        record.temp_is_ET_resource ?
        //            <div
        //                onClick={() => handleChange(5, 'status', index, record.idcosts)}
        //            >
        //                <ClockCircleOutlined style={record.status === 5 ? {color: 'green'} : {color: 'red'}}/>
        //            </div>
        //            :
        //            null
        //},
        {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',
          render: (text, record, index) => (record.status === 5 ? null : <PaCostRowMenu idcosts={record.idcosts} />)
        }
      ]
    },
    {
      //index 2 xx
      costType: 2,
      title: 'Mehhanismid Verston',
      taborder: ['res_name2', 'amount'],
      columns: [
        // {title: 'idcost', dataIndex: 'idcosts', key: 'idcosts'},
        // {title: 'id', dataIndex: 'resource_id', key: 'resource_id_temp'},
        {
          title: <PieChartOutlined />,
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            !(record.status == 4 && record.idworksheet) ? (
              meta.costing_version === 2 ? (
                <PaCostBillDivisionV2 records={record} />
              ) : (
                <PaCostBillDivisionV1 records={record} />
              )
            ) : (
              <Tooltip title="Kinnitamata töölehega mehhanismile ei saa bille määrata/muuta">
                <PieChartTwoTone twoToneColor="#e8e8e8" />
              </Tooltip>
            )
        },
        //{
        //  title: <PieChartOutlined />, dataIndex: 'idcosts', key: 'idcosts',
        //  render: (text, record, index) => meta.costing_version === 2 ?
        //    <PaCostBillDivisionV2 records={record} /> : <PaCostBillDivisionV1 records={record} />
        //},
        {
          title: (
            <Tooltip title={'Kulu on seotud töölehega'}>
              <FileDoneOutlined />
            </Tooltip>
          ),
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            record.idworksheet ? (
              <Tooltip title={<div>Tööleht #{record.idworksheet} Kliki, et avada!</div>}>
                <a>
                  <FileDoneOutlined
                    onClick={() => window.open(URLforFW() + '/objects/' + record.obj_id + '/worksheets/' + record.idworksheet, '_blank')}
                  />
                </a>
              </Tooltip>
            ) : null
        },
        //{
        //    title: '', dataIndex: 'temp_is_ET_resource', key: 'temp_is_ET_resource', align: "right",
        //    sorter: (a, b) => PaObjectSorter(a.temp_is_ET_resource, b.temp_is_ET_resource),
        //    render: (text, record, index) => text ?
        //        <Tooltip title="Eesti Teede mehhanism"> <Tag color="geekblue">ET</Tag></Tooltip> :
        //        <Tooltip title="Verston Ehituse mehhanism"><Tag color="red">VE</Tag></Tooltip>
        //},
        {
          title: '#',
          dataIndex: 'res_name1',
          key: 'res_name1',
          sorter: (a, b) => PaObjectSorter(a.res_name1, b.res_name1)
        },

        {
          title: 'Mehhanism',
          dataIndex: 'resource_id',
          key: 'resource_id',
          sorter: (a, b) => PaObjectSorter(a.res_name2, b.res_name2),
          render: (text, record, index) =>
            record.idworksheet && [3, 4, 5].includes(record.status) ? (
              <div>{record.res_name2}</div>
            ) : (
              getSelectCell(
                index,
                'resource_id',
                'res_name2',
                rows,
                {
                  optionsList: formOptions.equipment_list,
                  valueField: 'idresources',
                  titleField: 'resource'
                },
                record
              )
            )
        },
        {
          title: 'Kogus',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          sorter: (a, b) => a.amount - b.amount,
          render: (text, record, index) =>
            record.idworksheet && [3, 4, 5].includes(record.status) ? <div>{record.amount}</div> : getInputCell(index, 'amount', rows, record)
        },
        {
          title: 'Ühik  ',
          dataIndex: 'unit',
          key: 'unit',
          align: 'right',
          sorter: (a, b) => PaObjectSorter(a.unit, b.unit)
          // render: (text, record, index) =>
          //     getSelectCell(index, 'unit', 'unit', rows, {
          //         optionsList: formOptions.formoptions[4],
          //         valueField: "value",
          //         titleField: "name"
          //     }, record)
        },
        {
          title: 'Tariif',
          dataIndex: 'unit_price',
          key: 'unit_price',
          align: 'right',
          sorter: (a, b) => a.unit_price - b.unit_price
        },
        {
          title: 'Summa',
          dataIndex: 'row_sum',
          key: 'row_sum',
          align: 'right',
          sorter: (a, b) => a.row_sum - b.row_sum,
          render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
        },
        {
          title: <InfoCircleOutlined />,
          dataIndex: 'status',
          key: 'status',
          align: 'right',
          render: (text, record, index) =>
            record.status !== 4 ? (
              <StatusIcon value={record.status} ignore={[4]} />
            ) : record.idworksheet ? (
              <Tooltip title="Tööleht on kinnitamata">
                <i className="far fa-check-circle" style={{ color: '#fa8c16' }}></i>
              </Tooltip>
            ) : null
        },

        {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',
          render: (text, record, index) => <PaCostRowMenu idcosts={record.idcosts} />
        }
      ]
    },
    {
      //index 3
      costType: 3,
      title: 'Mehhanismid rent',
      taborder: ['org_name', 'ident1', 'cost_description', 'cost_start', 'cost_end', 'amount', 'unit', 'unit_price'],
      columns: [
        //{
        //    title: "#", dataIndex: 'idcosts', key: 'index',
        //    render: (text, record, index) => index
        //},
        {
          title: <PieChartOutlined />,
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            meta.costing_version === 2 ? <PaCostBillDivisionV2 records={record} /> : <PaCostBillDivisionV1 records={record} />
        },
        {
          title: 'Ettevõte',
          dataIndex: 'company',
          sorter: (a, b) => PaObjectSorter(a.org_name, b.org_name),
          key: 'company',

          //render: (text, record, index) => record.org_name
          render: (text, record, index) =>
            getSelectCell(
              index,
              'company',
              'org_name',
              rows,
              {
                optionsList: formOptions.companies_list,
                valueField: 'company',
                titleField: 'org_name'
              },
              record
            )
        },
        {
          title: 'Mehhanism',
          dataIndex: 'ident1',
          key: 'ident1',
          sorter: (a, b) => PaObjectSorter(a.ident1, b.ident1),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'ident1',
              'ident1',
              rows,
              {
                optionsList: formOptions.formoptions[14],
                valueField: 'value',
                titleField: 'name'
              },
              record
            )
        },
        {
          title: 'Täpsustus',
          dataIndex: 'cost_description',
          key: 'cost_description',
          sorter: (a, b) => PaObjectSorter(a.cost_description, b.cost_description),
          render: (text, record, index) =>
            getAutocompleteCell(
              index,
              'cost_description',
              'cost_description',
              rows,
              {
                optionsList: formOptions.cost_description,
                valueField: 'cost_description',
                titleField: 'cost_description'
              },
              record
            )
        },
        {
          title: 'Algus',
          dataIndex: 'cost_start',
          key: 'cost_start',
          align: 'right',
          sorter: (a, b) => PaObjectSorter(a.cost_start, b.cost_start),
          render: (text, record, index) => getTimeInputCell(index, 'cost_start', rows, record)
        },
        {
          title: 'Lõpp',
          dataIndex: 'cost_end',
          key: 'cost_end',
          align: 'right',
          sorter: (a, b) => PaObjectSorter(a.cost_end, b.cost_end),
          render: (text, record, index) => getTimeInputCell(index, 'cost_end', rows, record)
        },
        {
          title: 'Kogus',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          sorter: (a, b) => a.amount - b.amount,
          render: (text, record, index) => getInputCell(index, 'amount', rows, record)
        },
        {
          title: 'Ühik  ',
          dataIndex: 'unit',
          key: 'unit',
          align: 'right',
          sorter: (a, b) => PaObjectSorter(a.unit, b.unit),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'unit',
              'unit',
              rows,
              {
                optionsList: formOptions.formoptions[6],
                valueField: 'value',
                titleField: 'name'
              },
              record
            )
        },
        {
          title: 'Tariif',
          dataIndex: 'unit_price',
          key: 'unit_price',
          align: 'right',
          sorter: (a, b) => a.unit_price - b.unit_price,
          render: (text, record, index) => getInputCell(index, 'unit_price', rows, record)
        },
        {
          title: 'Summa',
          dataIndex: 'row_sum',
          key: 'row_sum',
          align: 'right',
          sorter: (a, b) => a.row_sum - b.row_sum,
          render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
        },
        {
          title: <InfoCircleOutlined />,
          dataIndex: 'status',
          key: 'status',
          align: 'right',
          render: (text, record, index) => (text !== 4 ? <StatusIcon value={record.status} ignore={['4']} /> : null)
        },
        {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',

          render: (text, record, index) => <PaCostRowMenu idcosts={record.idcosts} />
        }
      ]
    },
    {
      //index 4
      costType: 4,
      title: 'Transport',
      taborder: [
        'cost_description',
        'org_name',
        'unit',
        'ident1',
        'cost_comment',
        'ident2',
        'cost_start',
        'cost_end',
        'amount_description',
        'amount2',
        'amount',
        'unit_price'
      ],
      columns: [
        {
          title: <PieChartOutlined />,
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            meta.costing_version === 2 ? <PaCostBillDivisionV2 records={record} /> : <PaCostBillDivisionV1 records={record} />
        },
        {
          title: 'Reg. nr.',
          dataIndex: 'cost_description',
          key: 'cost_description',
          //sorter: (a, b) => a.cost_description ? a.cost_description.localeCompare(b.cost_description? b.cost_description : '') : 1,
          sorter: (a, b) => PaObjectSorter(a.cost_description, b.cost_description),
          //sorter: (a, b) => a.cost_description - b.cost_description,
          render: (text, record, index) =>
            getAutocompleteCell(
              index,
              'cost_description',
              'cost_description',
              rows,
              {
                optionsList: formOptions.cost_description,
                valueField: 'cost_description',
                titleField: 'cost_description'
              },
              record
            )
        },
        {
          title: 'Ettevõte',
          dataIndex: 'company',
          key: 'company',
          sorter: (a, b) => PaObjectSorter(a.org_name, b.org_name),
          //sorter: (a, b) => a.org_name.localeCompare(b.org_name),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'company',
              'org_name',
              rows,
              {
                optionsList: formOptions.companies_list,
                valueField: 'company',
                titleField: 'org_name'
              },
              record
            )
        },
        {
          title: 'Ühik  ',
          dataIndex: 'unit',
          key: 'unit',
          sorter: (a, b) => PaObjectSorter(a.unit, b.unit),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'unit',
              'unit',
              rows,
              {
                optionsList: formOptions.formoptions[8],
                valueField: 'value',
                titleField: 'name'
              },
              record
            )
        },
        {
          title: 'Millega veeti?',
          dataIndex: 'ident1',
          key: 'ident1',
          sorter: (a, b) => PaObjectSorter(a.ident1, b.ident1),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'ident1',
              'ident1',
              rows,
              {
                optionsList: formOptions.formoptions[15],
                valueField: 'value',
                titleField: 'name'
              },
              record
            )
        },

        {
          title: 'Kommentaar',
          dataIndex: 'cost_comment',
          key: 'cost_comment',
          sorter: (a, b) => PaObjectSorter(a.cost_comment, b.cost_comment),
          render: (text, record, index) => getInputCell(index, 'cost_comment', rows, record)
        },
        {
          title: 'Täpsustus (fraktsioon vms)',
          dataIndex: 'ident2',
          key: 'ident2',
          sorter: (a, b) => PaObjectSorter(a.ident2, b.ident2),
          render: (text, record, index) => getInputCell(index, 'ident2', rows, record)
        },
        {
          title: 'Algus',
          dataIndex: 'cost_start',
          key: 'cost_start',
          align: 'right',
          sorter: (a, b) => PaObjectSorter(a.cost_start, b.cost_start),
          render: (text, record, index) => getTimeInputCell(index, 'cost_start', rows, record)
        },
        {
          title: 'Lõpp',
          dataIndex: 'cost_end',
          key: 'cost_end',
          align: 'right',
          sorter: (a, b) => PaObjectSorter(a.cost_end, b.cost_end),
          render: (text, record, index) => getTimeInputCell(index, 'cost_end', rows, record)
        },
        {
          title: 'Kogused (T/H)',
          dataIndex: 'amount_description',
          key: 'amount_description',
          render: (text, record, index) => getInputCell(index, 'amount_description', rows, record)
        },
        {
          title: 'Km',
          dataIndex: 'amount2',
          key: 'amount2',
          align: 'right',
          sorter: (a, b) => a.amount2 - b.amount2,
          render: (text, record, index) => getInputCell(index, 'amount2', rows, record)
        },
        {
          title: 'Kogus',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          render: (text, record, index) => getInputCell(index, 'amount', rows, record)
        },
        {
          title: 'Ühiku hind',
          dataIndex: 'unit_price',
          key: 'unit_price',
          align: 'right',
          sorter: (a, b) => a.unit_price - b.unit_price,
          render: (text, record, index) => getInputCell(index, 'unit_price', rows, record, 3)
        },
        {
          title: 'Summa',
          dataIndex: 'row_sum',
          key: 'row_sum',
          align: 'right',
          sorter: (a, b) => a.row_sum - b.row_sum,
          render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
        },
        {
          title: <InfoCircleOutlined />,
          dataIndex: 'status',
          key: 'status',
          align: 'right',
          render: (text, record, index) => <StatusIcon value={record.status} ignore={['4']} />
        },
        {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',
          render: (text, record, index) => <PaCostRowMenu idcosts={record.idcosts} />
        }
      ]
    },
    {
      //index 5
      costType: 5,
      title: 'Materjalid',
      taborder: [
        'factory',
        'org_name',
        'from_warehouse_name',
        'resource_string',
        'cost_comment',
        'amount_description',
        'amount',
        'unit',
        'unit_price'
      ],
      columns: [
        {
          title: <PieChartOutlined />,
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            meta.costing_version === 2 ? <PaCostBillDivisionV2 records={record} /> : <PaCostBillDivisionV1 records={record} />
        },
        {
          title: (
            <Tooltip title={'Kulu on seotud töölehega'}>
              <FileDoneOutlined />
            </Tooltip>
          ),
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            record.idworksheet ? (
              <Tooltip title={<div>Tööleht #{record.idworksheet}</div>}>
                <FileDoneOutlined />
              </Tooltip>
            ) : null
        },
        // {
        //     title: () => <Icon type="copy"/>, dataIndex: 'idcosts', key: 'idcosts',
        //     render: (text, record, index) => <Tooltip title="kopeeri rida"><Icon type="copy"/></Tooltip>
        // },
        //{title: 'id', dataIndex: 'idcosts', key: 'idcosts'},
        {
          title: 'Tehas/karjäär',
          dataIndex: 'factory',
          key: 'factory',
          render: (text, record, index) =>
            getAutocompleteCell(
              index,
              'factory',
              'factory',
              rows,
              {
                optionsList: formOptions.factory,
                valueField: 'factory',
                titleField: 'factory'
              },
              record
            )
        },
        {
          title: 'Ettevõte',
          dataIndex: 'company',
          key: 'company',
          render: (text, record, index) =>
            getSelectCell(
              index,
              'company',
              'org_name',
              rows,
              {
                optionsList: formOptions.companies_list,
                valueField: 'company',
                titleField: 'org_name'
              },
              record
            )
        },
        //  {
        //      title: <div><Tooltip
        //          title="Millisest laost pärineb materjal? Vajalik täita kui on kasutatud Eesti Teede / Verstoni laos olnud materjali. Kui ei, jäta tühjaks."> Ladu <Icon
        //          type="question-circle"/> </Tooltip></div>, dataIndex: 'from_warehouse', key: 'from_warehouse',
        //      render: (text, record, index) =>
        //          getSelectCell(index, 'from_warehouse', 'from_warehouse_name', rows, {
        //              optionsList: formOptions.formoptions[17],
        //              valueField: "value",
        //              titleField: "name"
        //          }, record)
        //  },
        //  {
        //      title: '', dataIndex: 'from_warehouse', key: 'from_warehouse2',
        //      render: (text, record, index) => record.from_warehouse ?
        //          <Tooltip title="Ekslik lao kirje? Vajuta X kui eksisid.">
        //              <Button onClick={() => handleChange(null, 'from_warehouse', index, record['idcosts'])}>X</Button>
        //          </Tooltip>
        //          : null
        //  },
        {
          title: (
            <div>
              <Tooltip title="Materjal"> Materjal </Tooltip>
            </div>
          ),
          dataIndex: 'cost_description',
          key: 'cost_description',
          render: (text, record, index) =>
            //getAutocompleteCell(index, 'cost_description', 'cost_description', rows, {
            //    optionsList: formOptions.cost_description,
            //    valueField: "cost_description",
            //    titleField: "cost_description"
            //}, record)
            getSelectCell(
              index,
              'cost_description',
              'cost_description',
              rows,
              {
                optionsList: formOptions.formoptions[11],
                valueField: 'value',
                titleField: 'name'
              },
              record
            )

          //getSelectCell(index, 'resource_id', 'resource_string', rows, {
          //    optionsList: formOptions.materials,
          //    valueField: "idresources",
          //    titleField: "resource_string"
          //}, record)
        },
        // {
        //     title: <div><Tooltip
        //         title="Materjalid on standardiseeritud ja koodidega. Kui sinu materjal on puudu, täida KOMMENTAAR ja anna Olgale teada."> Materjalid <Icon
        //         type="question-circle"/> </Tooltip></div>, dataIndex: 'resource_id', key: 'resource_id',
        //     render: (text, record, index) =>
        //         //getAutocompleteCell(index, 'cost_description', 'cost_description', rows, {
        //         //    optionsList: formOptions.cost_description,
        //         //    valueField: "cost_description",
        //         //    titleField: "cost_description"
        //         //}, record)
        //         //getSelectCell(index, 'cost_description', 'cost_description', rows, {
        //         //    optionsList: formOptions.formoptions[11],
        //         //    valueField: "value",
        //         //    titleField: "name"
        //         //}, record)
        //
        //         getSelectCell(index, 'resource_id', 'resource_string', rows, {
        //             optionsList: formOptions.materials,
        //             valueField: "idresources",
        //             titleField: "resource_string"
        //         }, record)
        // },

        {
          title: 'Kommentaar',
          dataIndex: 'cost_comment',
          key: 'cost_comment',
          render: (text, record, index) => getInputCell(index, 'cost_comment', rows, record)
        },
        {
          title: 'Ident1',
          dataIndex: 'ident1',
          key: 'ident1',
          sorter: (a, b) => PaObjectSorter(a.ident1, b.ident1),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'ident1',
              'ident1',
              rows,
              {
                optionsList: formOptions.formoptions[12],
                valueField: 'value',
                titleField: 'name'
              },
              record
            )
        },
        {
          title: 'Ident2',
          dataIndex: 'ident2',
          key: 'ident2',
          sorter: (a, b) => PaObjectSorter(a.ident2, b.ident2),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'ident2',
              'ident2',
              rows,
              {
                optionsList: formOptions.formoptions[13],
                valueField: 'value',
                titleField: 'name'
              },
              record
            )
        },
        {
          title: 'Kogused',
          dataIndex: 'amount_description',
          key: 'amount_description',
          sorter: (a, b) => PaObjectSorter(a.amount_description, b.amount_description),
          render: (text, record, index) => getInputCell(index, 'amount_description', rows, record)
        },
        {
          title: 'Kogus',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          sorter: (a, b) => a.amount - b.amount,
          render: (text, record, index) => getInputCell(index, 'amount', rows, record)
        },
        {
          title: 'Ühik  ',
          dataIndex: 'unit',
          key: 'unit',
          align: 'right',
          sorter: (a, b) => PaObjectSorter(a.unit, b.unit),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'unit',
              'unit',
              rows,
              {
                optionsList: formOptions.formoptions[10],
                valueField: 'value',
                titleField: 'name'
              },
              record
            )
        },
        {
          title: 'Ühiku hind',
          dataIndex: 'unit_price',
          key: 'unit_price',
          align: 'right',
          sorter: (a, b) => a.unit_price - b.unit_price,
          render: (text, record, index) => getInputCell(index, 'unit_price', rows, record)
        },
        {
          title: 'Summa',
          dataIndex: 'row_sum',
          key: 'row_sum',
          align: 'right',
          sorter: (a, b) => a.row_sum - b.row_sum,
          render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
        },
        {
          title: <InfoCircleOutlined />,
          dataIndex: 'status',
          key: 'status',
          align: 'right',
          render: (text, record, index) => <StatusIcon value={record.status} ignore={['4']} />
        },
        {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',
          render: (text, record, index) => <PaCostRowMenu idcosts={record.idcosts} />
        }
      ]
    },
    {
      //index 6
      costType: 6,
      title: 'Muud kulud',
      taborder: ['org_name', 'cost_description', 'amount', 'unit', 'unit_price'],
      columns: [
        {
          title: <PieChartOutlined />,
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            meta.costing_version === 2 ? <PaCostBillDivisionV2 records={record} /> : <PaCostBillDivisionV1 records={record} />
        },
        {
          title: 'Ettevõte',
          dataIndex: 'company',
          key: 'company',
          sorter: (a, b) => PaObjectSorter(a.org_name, b.org_name),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'company',
              'org_name',
              rows,
              {
                optionsList: formOptions.companies_list,
                valueField: 'company',
                titleField: 'org_name'
              },
              record
            )
        },
        {
          title: 'Teenus',
          dataIndex: 'cost_description',
          key: 'cost_description',
          sorter: (a, b) => PaObjectSorter(a.cost_description, b.cost_description),
          render: (text, record, index) =>
            getAutocompleteCell(
              index,
              'cost_description',
              'cost_description',
              rows,
              {
                optionsList: formOptions.cost_description,
                valueField: 'cost_description',
                titleField: 'cost_description'
              },
              record
            )
        },
        {
          title: 'Tehnika',
          dataIndex: 'resource_id',
          key: 'resource_id',
          render: (text, record, index) => {
            return getSelectCell(
              index,
              'resource_id',
              'resource_string',
              rows,
              {
                optionsList: formOptions?.equipment_list ?? [],
                valueField: 'idresources',
                titleField: 'resource'
              },
              record
            )
          }
        },
        {
          title: 'Kogus',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          sorter: (a, b) => a.amount - b.amount,
          render: (text, record, index) => getInputCell(index, 'amount', rows, record)
        },
        {
          title: 'Ühik  ',
          dataIndex: 'unit',
          key: 'unit',
          sorter: (a, b) => PaObjectSorter(a.unit, b.unit),
          render: (text, record, index) => getInputCell(index, 'unit', rows, record)
        },
        {
          title: 'Ühiku hind',
          dataIndex: 'unit_price',
          key: 'unit_price',
          align: 'right',
          sorter: (a, b) => a.unit_price - b.unit_price,
          render: (text, record, index) => getInputCell(index, 'unit_price', rows, record)
        },
        {
          title: 'Summa',
          dataIndex: 'row_sum',
          key: 'row_sum',
          align: 'right',
          sorter: (a, b) => a.row_sum - b.row_sum,
          render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
        },
        {
          title: <InfoCircleOutlined />,
          dataIndex: 'status',
          key: 'status',
          align: 'right',
          render: (text, record, index) => <StatusIcon value={record.status} ignore={['4']} />
        },
        {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',
          render: (text, record, index) => <PaCostRowMenu idcosts={record.idcosts} />
        }
      ]
    },
    {
      //index 7
      costType: 7,
      title: 'Kommentaarid',
      taborder: ['cost_comment'],
      columns: [

        {
          title: 'Tekst',
          dataIndex: 'cost_comment',
          key: 'cost_comment',
          sorter: (a, b) => PaObjectSorter(a.cost_comment, b.cost_comment),
          render: (text, record, index) => getInputCell(index, 'cost_comment', rows, record)
        },
        {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',
          render: (text, record, index) => <PaCostRowMenu idcosts={record.idcosts} />
        }
      ]
    },
    {
      //index 8
      costType: 9,
      title: 'Tootmise üldkulud',
      taborder: ['cost_description',  'amount', 'unit_price'],
      columns: [
        {
          title: 'Kulu',
          dataIndex: 'cost_description',
          key: 'cost_description',
          render: (text, record, index) =>
            getAutocompleteCell(
              index,
              'cost_description',
              'cost_description',
              rows,
              {
                optionsList: formOptions.cost_description,
                valueField: 'cost_description',
                titleField: 'cost_description'
              },
              record
            )
        },
        {
          title: 'Kogus',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          //render: (text, record, index) => (text ? text.toFixed(2) : null)
          render: (text, record, index) => getInputCell(index, 'amount', rows, record)
        },
        {
          title: 'Ühiku hind',
          dataIndex: 'unit_price',
          key: 'unit_price',
          align: 'right',
          //render: (text, record, index) => (text ? text.toFixed(2) : null)
          render: (text, record, index) => getInputCell(index, 'unit_price', rows, record)
        },
        {
          title: 'Summa',
          dataIndex: 'row_sum',
          key: 'row_sum',
          align: 'right',
          render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
        },
          {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',
          render: (text, record, index) => <PaCostRowMenu idcosts={record.idcosts} />
        }
      ]
    },
    {
      //index 9
      costType: 10,
      title: 'Alltöövõtt',
      taborder: ['org_name', 'cost_description', 'amount', 'unit', 'unit_price'],
      columns: [
        //{ title:"id", key:'id', dataIndex:'idcosts'},
        {
          title: <PieChartOutlined />,
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            meta.costing_version === 2 ? <PaCostBillDivisionV2 records={record} /> : <PaCostBillDivisionV1 records={record} />
        },
        {
          title: 'Ettevõte',
          dataIndex: 'company',
          key: 'company',
          render: (text, record, index) =>
            getSelectCell(
              index,
              'company',
              'org_name',
              rows,
              {
                optionsList: formOptions.companies_list,
                valueField: 'company',
                titleField: 'org_name'
              },
              record
            )
        },
        {
          title: 'Teenus',
          dataIndex: 'cost_description',
          key: 'cost_description',
          render: (text, record, index) =>
            getAutocompleteCell(
              index,
              'cost_description',
              'cost_description',
              rows,
              {
                optionsList: formOptions.cost_description,
                valueField: 'cost_description',
                titleField: 'cost_description'
              },
              record
            )
        },
        {
          title: 'Kogus',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          render: (text, record, index) => getInputCell(index, 'amount', rows, record)
        },
        {
          title: 'Ühik  ',
          dataIndex: 'unit',
          key: 'unit',
          render: (text, record, index) => getInputCell(index, 'unit', rows, record)
        },
        {
          title: 'Ühiku hind',
          dataIndex: 'unit_price',
          key: 'unit_price',
          align: 'right',
          render: (text, record, index) => getInputCell(index, 'unit_price', rows, record)
        },
        {
          title: 'Summa',
          dataIndex: 'row_sum',
          key: 'row_sum',
          align: 'right',
          render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
        },
        {
          title: <InfoCircleOutlined />,
          dataIndex: 'status',
          key: 'status',
          align: 'right',
          render: (text, record, index) => <StatusIcon value={record.status} ignore={['4']} />
        },
        {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',
          render: (text, record, index) => <PaCostRowMenu idcosts={record.idcosts} />
        }
      ]
    },
    {
      // index 10
      costType: 7,
      title: 'Kommentaarid',
      taborder: ['ident1', 'cost_comment', 'amount'],
      columns: [
          {
          title: 'Kommentaar',
          dataIndex: 'ident1',
          key: 'ident1',
          sorter: (a, b) => PaObjectSorter(a.ident1, b.ident1),
          render: (text, record, index) =>
            getSelectCell(
              index,
              'ident1',
              'ident1',
              rows,
              {
                optionsList: formOptions.comment_types,
                valueField: 'value',
                titleField: 'name'
              },
              record
            )
        },
        {
          title: 'Tekst',
          dataIndex: 'cost_comment',
          key: 'cost_comment',
          render: (text, record, index) => getInputCell(index, 'cost_comment', rows, record)
        },
        {
          title: 'Kogus',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          render: (text, record, index) => getInputCell(index, 'amount', rows, record)
        },
        {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',
          render: (text, record, index) => <PaCostRowMenu idcosts={record.idcosts} />
        }
      ]
    },
    {
      //index 11 ET mechanisms temporary separation table
      costType: 2,
      title: 'Mehhanismid Eesti Teed',
      taborder: ['res_name2', 'amount'],
      columns: [
        // {title: 'idcost', dataIndex: 'idcosts', key: 'idcosts'},
        // {title: 'id', dataIndex: 'resource_id', key: 'resource_id_temp'},
        {
          title: <PieChartOutlined />,
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            meta.costing_version === 2 ? <PaCostBillDivisionV2 records={record} /> : <PaCostBillDivisionV1 records={record} />
        },
        {
          title: '',
          dataIndex: 'temp_ET_source',
          key: 'temp_ET_source',
          align: 'right',
          render: (text, record, index) =>
            text ? (
              <Tooltip title="Eesti Teede mehhanism">
                {' '}
                <Tag color="geekblue">ET</Tag>
              </Tooltip>
            ) : (
              <Tag color="red">VE</Tag>
            )
        },
        { title: 'id#', dataIndex: 'res_name1', key: 'res_name1' },
        {
          title: 'Mehhanism',
          dataIndex: 'resource_id',
          key: 'resource_id',
          render: (text, record, index) =>
            getSelectCell(
              index,
              'resource_id',
              'res_name2',
              rows,
              {
                optionsList: formOptions.equipment_list,
                valueField: 'idresources',
                titleField: 'resource'
              },
              record
            )
        },
        {
          title: 'Kogus',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          render: (text, record, index) => getInputCell(index, 'amount', rows, record)
        },
        {
          title: 'Ühik  ',
          dataIndex: 'unit',
          key: 'unit',
          align: 'right'
          // render: (text, record, index) =>
          //     getSelectCell(index, 'unit', 'unit', rows, {
          //         optionsList: formOptions.formoptions[4],
          //         valueField: "value",
          //         titleField: "name"
          //     }, record)
        },
        { title: 'Tariif', dataIndex: 'unit_price', key: 'unit_price', align: 'right' },
        {
          title: 'Summa',
          dataIndex: 'row_sum',
          key: 'row_sum',
          align: 'right',
          render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
        },
        {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',
          render: (text, record, index) => <PaCostRowMenu idcosts={record.idcosts} />
        }
      ]
    },
    {
      //index 12 ET project code materials input
      costType: 5,
      title: 'Materjalid',
      taborder: [
        'factory',
        'org_name',
        'from_warehouse_name',
        'resource_string',
        'idroad',
        'layer',
        'cost_comment',
        'amount_description',
        'amount',
        'unit',
        'unit_price'
      ],
      columns: [
        //{
        //  title: <PieChartOutlined />, dataIndex: 'idcosts', key: 'idcosts',
        //  render: (text, record, index) => meta.costing_version === 2 ?
        //    <PaCostBillDivisionV2 records={record} /> : <PaCostBillDivisionV1 records={record} />
        //},
        {
          title: <PieChartOutlined />,
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            record.status === 4 && record.idworksheet ? (
              <Tooltip title="Kinnitamata töölehega materjali kulule ei saa bille määrata/muuta">
                <PieChartTwoTone twoToneColor="#e8e8e8" />
              </Tooltip>
            ) : meta.costing_version === 2 ? (
              <PaCostBillDivisionV2 records={record} />
            ) : (
              <PaCostBillDivisionV1 records={record} />
            )
        },
        {
          title: (
            <Tooltip title={'Kulu on seotud töölehega'}>
              <FileDoneOutlined />
            </Tooltip>
          ),
          dataIndex: 'idcosts',
          key: 'idcosts',
          render: (text, record, index) =>
            record.idworksheet ? (
              <Tooltip title={<div>Tööleht #{record.idworksheet} Kliki, et avada!</div>}>
                <a>
                  <FileDoneOutlined
                    onClick={() => window.open(URLforFW() + '/objects/' + record.obj_id + '/worksheets/' + record.idworksheet, '_blank')}
                  />
                </a>
              </Tooltip>
            ) : null
        },
        // {
        //     title: () => <Icon type="copy"/>, dataIndex: 'idcosts', key: 'idcosts',
        //     render: (text, record, index) => <Tooltip title="kopeeri rida"><Icon type="copy"/></Tooltip>
        // },
        //{title: 'id', dataIndex: 'idcosts', key: 'idcosts'},
        {
          title: 'Tehas/karjäär',
          dataIndex: 'factory',
          key: 'factory',
          render: (text, record, index) =>
            record.idworksheet && record.status === 4 ? (
              <div>{record.factory}</div>
            ) : (
              getAutocompleteCell(
                index,
                'factory',
                'factory',
                rows,
                {
                  optionsList: formOptions.factory,
                  valueField: 'factory',
                  titleField: 'factory'
                },
                record
              )
            )
        },
        {
          title: 'Ettevõte',
          dataIndex: 'company',
          key: 'company',
          render: (text, record, index) =>
            record.idworksheet && [3, 4, 5].includes(record.status) ? (
              <div>{record.org_name}</div>
            ) : (
              getSelectCell(
                index,
                'company',
                'org_name',
                rows,
                {
                  optionsList: formOptions.companies_list,
                  valueField: 'company',
                  titleField: 'org_name'
                },
                record
              )
            )
        },
        {
          title: (
            <div>
              <Tooltip title="Millisest laost pärineb materjal? Vajalik täita kui on kasutatud Verstoni laos olnud materjali. Kui ei, jäta tühjaks.">
                {' '}
                Ladu <QuestionCircleOutlined />
              </Tooltip>
            </div>
          ),
          dataIndex: 'from_warehouse',
          key: 'from_warehouse',
          render: (text, record, index) =>
            record.idworksheet && [3, 4, 5].includes(record.status) ? (
              <div>{record.from_warehouse_name}</div>
            ) : (
              <div>
                {getSelectCell(
                  index,
                  'from_warehouse',
                  'from_warehouse_name',
                  rows,
                  {
                    optionsList: formOptions.formoptions[17],
                    valueField: 'value',
                    titleField: 'name'
                  },
                  record
                )}
              </div>
            )
        },
        {
          title: '',
          dataIndex: 'from_warehouse',
          key: 'from_warehouse2',
          render: (text, record, index) => {
            //if (record.from_warehouse && record.status === 5) return <small>Laokirje<br/> töödeldud</small>
            if (record.from_warehouse && !record.idworksheet) {
              return (
                <Tooltip title="Vajuta, kui soovid ladu kulu küljest eemaldada.">
                  <Button
                    shape={'circle'}
                    onClick={() => {
                      handleChange(null, 'from_warehouse', index, record['idcosts'])
                      setSelectedMaterialWarehouse(null)
                    }}>
                    <CloseOutlined />
                  </Button>
                </Tooltip>
              )
            }
          }
        },
        {
          title: (
            <div>
              <Tooltip title="Materjalid on standardiseeritud ja koodidega. Kui sinu materjal on puudu, täida KOMMENTAAR ja anna Olgale teada.">
                Materjalid <QuestionCircleOutlined />
              </Tooltip>
            </div>
          ),
          dataIndex: 'resource_id',
          key: 'resource_id',
          render: (text, record, index) =>
            //getAutocompleteCell(index, 'cost_description', 'cost_description', rows, {
            //    optionsList: formOptions.cost_description,
            //    valueField: "cost_description",
            //    titleField: "cost_description"
            //}, record)
            //getSelectCell(index, 'cost_description', 'cost_description', rows, {
            //    optionsList: formOptions.formoptions[11],
            //    valueField: "value",
            //    titleField: "name"
            //}, record)
            record.idworksheet && [3, 4, 5].includes(record.status) ? (
              <div>{record.resource_string}</div>
            ) : (
              getSelectCell(
                index,
                'resource_id',
                'resource_string',
                rows,
                {
                  optionsList: record?.from_warehouse
                    ? formOptions?.materials.filter((mat) => mat.warehouse_id === record.from_warehouse)
                    : selectedMaterialWarehouse
                    ? formOptions?.materials?.filter((mat) => mat?.warehouse_id === selectedMaterialWarehouse)
                    : formOptions?.materials,
                  valueField: 'idresources',
                  titleField: 'resource_string'
                },
                record
              )
            )
        },
        {
          title: 'Materjal',
          dataIndex: 'cost_description',
          key: 'cost_description',
          render: (text, record, index) => text + ' ' + (record.ident1 ? record.ident1 : '') + ' ' + (record.ident2 ? record.ident2 : '')
        },
        {
          title: 'Tee',
          dataIndex: 'idroad',
          key: 'idroad',
          render: (text, record, index) =>
            getGoodSelectCell(index, 'idroad', record, 'idroad', mappedRoads, {
              withAdding: true,
              addingItems: mappedOffers,
              addFunc: addRoad,
              objectId: objectId,
              userId: userId,
              objectActiveDate
            })
        },
        {
          title: 'Kiht',
          dataIndex: 'layer',
          key: 'layer',
          render: (text, record, index) => getInputCell(index, 'layer', rows, record)
        },
        {
          title: 'Kommentaar',
          dataIndex: 'cost_comment',
          key: 'cost_comment',
          render: (text, record, index) =>
            record.idworksheet && [4].includes(record.status) ? <div>{record.cost_comment}</div> : getInputCell(index, 'cost_comment', rows, record)
        },
        //{
        //    title: 'Ident1', dataIndex: 'ident1', key: 'ident1', render: (text, record, index) =>
        //        getSelectCell(index, 'ident1', 'ident1', rows, {
        //            optionsList: formOptions.formoptions[12],
        //            valueField: "value",
        //            titleField: "name"
        //        }, record)
        //}, {
        //    title: 'Ident2', dataIndex: 'ident2', key: 'ident2', render: (text, record, index) =>
        //        getSelectCell(index, 'ident2', 'ident2', rows, {
        //            optionsList: formOptions.formoptions[13],
        //            valueField: "value",
        //            titleField: "name"
        //        }, record)
        //},
        {
          title: 'Kogused',
          dataIndex: 'amount_description',
          key: 'amount_description',
          render: (text, record, index) =>
            record.idworksheet && [3, 4, 5].includes(record.status) ? (
              <div>{record.amount_description}</div>
            ) : (
              getInputCell(index, 'amount_description', rows, record)
            )
        },
        {
          title: 'Kogus',
          dataIndex: 'amount',
          key: 'amount',
          align: 'right',
          render: (text, record, index) =>
            record.idworksheet && [3, 4, 5].includes(record.status) ? <div>{record.amount}</div> : getInputCell(index, 'amount', rows, record)
        },
        {
          title: 'Ühik  ',
          dataIndex: 'unit',
          key: 'unit',
          align: 'right',
          render: (text, record, index) =>
            record.idworksheet && [3, 4, 5].includes(record.status) ? (
              <div>{record.unit}</div>
            ) : (
              getSelectCell(
                index,
                'unit',
                'unit',
                rows,
                {
                  optionsList: formOptions.formoptions[10],
                  valueField: 'value',
                  titleField: 'name'
                },
                record
              )
            )
        },
        {
          title: (
            <Tooltip title="Ühiku hind">
              {' '}
              Ühiku hind <QuestionCircleOutlined />
            </Tooltip>
          ),
          dataIndex: 'unit_price',
          key: 'unit_price',
          align: 'right',
          render: (text, record, index) =>
            record.idworksheet && [4].includes(record.status) ? <div>{record.unit_price}</div> : getInputCell(index, 'unit_price', rows, record)
        },
        {
          title: 'Summa',
          dataIndex: 'row_sum',
          key: 'row_sum',
          align: 'right',
          render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
        },
        //{
        //  title: <InfoCircleOutlined />, dataIndex: "status", key: "status", align: "right",
        //  render: (text, record, index) => <StatusIcon value={record.status} ignore={['4']} />
        //},

        {
          title: <InfoCircleOutlined />,
          dataIndex: 'status',
          key: 'status',
          align: 'right',
          render: (text, record, index) =>
            record.status !== 4 ? (
              <StatusIcon value={record.status} ignore={[4]} />
            ) : record.idworksheet ? (
              <Tooltip title="Tööleht on kinnitamata">
                <i className="far fa-check-circle" style={{ color: '#fa8c16' }}></i>
              </Tooltip>
            ) : //  : record.from_warehouse && record.status === 4 ?
            //  <Tooltip
            //      title={"Ilma tööleheta laost tehtud kulud pead ise kinnitama. Vajuta, et kinnitada!"}>
            //      <Button
            //          shape={"circle"}
            //          onClick={() => handleChange(5, 'status', index, record['idcosts'])}
            //      >
            //          <i className="far fa-check-circle" style={{color: 'green'}}> </i>
            //      </Button>
            //  </Tooltip>
            null
        },
        {
          title: <MoreOutlined />,
          dataIndex: 'row_sum',
          key: 'actions',
          width: '20px',
          render: (text, record, index) => <PaCostRowMenu idcosts={record.idcosts} />
        }
      ]
    }
  ]

  let columns = CostTypeColumnDefs[index].columns
  let taborder = CostTypeColumnDefs[index].taborder

  // removing bills indicator that has column key idcosts
  if (!billsButton || '1900-01-01' === objectActiveDate.format('YYYY-MM-DD')) {
    columns = columns.filter((column) => column.key !== 'idcosts')
  }

  const title = CostTypeColumnDefs[index].title
  const costType = CostTypeColumnDefs[index].costType
  let rows = 0

  let filteredDataSource = Object.values(costData).filter((row) => {
    if (row.cost_type === costType && moment(row.cost_date).startOf('day').isSame(objectActiveDate.startOf('day'))) {
      return true
    }
    return false
  })

  //.sort((a, b) => PaObjectSorter(a.cost_description, b.cost_description))

  //if (filterOutET === 'ET') filteredDataSource = filteredDataSource.filter(row => row.temp_is_ET_resource === 1 ? row : null)
  //if (filterOutET === 'VE') filteredDataSource = filteredDataSource.filter(row => row.temp_is_ET_resource !== 1 ? row : null)

  filteredDataSource = filteredDataSource.map((row) => {
    return {
      allowedToEditEtSpecial: !(row.status === 5 && row.from_warehouse),
      ...row
    }
  })

  // group by logic

  // commented out because of race condition happening when editable cell got focus but
  // groupByList recalculated. Need to think something else out - effect?

  //let groupByList = []
  //filteredDataSource.forEach(r => {
  //    if (!groupByList.includes(r.org_name)) groupByList.push(r.org_name)
  //})
  //
  //let groupedDataSource = []
  //groupByList.forEach(item => {
  //    groupedDataSource.push({
  //        iAmSubtotal: true,
  //        amount: filteredDataSource.filter(r=> r.org_name === item).reduce((sum, r) => r.org_name === item ? sum + r.amount : sum, 0),
  //        amount2: null,
  //        amount_added: null,
  //        amount_description: null,
  //        company: null,
  //        cost_comment: null,
  //        cost_date: null,
  //        cost_description: null,
  //        cost_end: null,
  //        cost_start: null,
  //        cost_type: null,
  //        cost_type_name: null,
  //        factory: null,
  //        from_warehouse: null,
  //        from_warehouse_name: null,
  //        idcosts: item,
  //        ident1: null,
  //        ident2: null,
  //        obj_id: null,
  //        org_name: item,
  //        res_group_name: null,
  //        res_name1: null,
  //        res_name2: null,
  //        resource_id: null,
  //        resource_string: null,
  //        row_sum: filteredDataSource.filter(r=> r.org_name === item).reduce((sum, r) => r.org_name === item ? sum + r.row_sum : sum, 0),
  //        status: null,
  //        temp_ET_source: null,
  //        temp_is_ET_resource: null,
  //        unit: null,
  //        unit_price: null,
  //        unit_price_multiplier: null,
  //        unit_sum: null,
  //        children: [...filteredDataSource.filter(r=> r.org_name === item)]
  //    })
  //
  //})
  //
  //console.log("grouped", groupedDataSource)

  rows = filteredDataSource.length
  let sum = filteredDataSource.reduce((sum, current) => (sum = sum + current.row_sum), 0).toFixed(2)
  let amounts = filteredDataSource.reduce((sum, current) => sum + current.amount, 0)
  let amount2s = filteredDataSource.reduce((sum, current) => sum + current.amount2, 0)

  //filteredDataSource = groupedDataSource

  function handleAddRow(idcosts = null) {
    let payload = {
      cost_date: objectActiveDate.format('YYYY-MM-DD 00:00:00'),
      cost_type: costType,
      obj_id: meta.idobject,
      idcosts: idcosts
    }
    handleRowAddPayload(payload).then((res) => console.log(res))
  }

  function getCellReference(index, field, horizontal = false, vertical = false) {
    // this is to limit editing of certain fields after they have been already processed by other systems
    if (editAllowed) {
      // && !(filteredDataSource[index].from_warehouse && filteredDataSource[index].status === 5)) {

      //limit editing cells that have worksheet id attached to it and not confirmed
      if (filteredDataSource[index].idworksheet && filteredDataSource[index].status === 4) return null

      //console.log("index, field, horizontal, vertical", index, field, horizontal, vertical)
      if (vertical === -1 && index > 0) return (index - 1).toString() + field // up
      if (vertical === 1 && index < rows - 1) return (index + 1).toString() + field // down

      let horizontal_index = taborder.indexOf(field)
      let horizontal_max = taborder.length - 1

      if (horizontal_index > -1) {
        //value actually exists
        if (horizontal === -1 && horizontal_index > 0) return index.toString() + taborder[horizontal_index - 1] // left
        if (horizontal === 1 && horizontal_index < horizontal_max) return index.toString() + taborder[horizontal_index + 1] // right
        if (horizontal === -1 && horizontal_index === 0 && index > 0) return (index - 1).toString() + taborder[horizontal_max] // left
        if (horizontal === 1 && horizontal_index === horizontal_max && index < rows - 1) return false //(index + 1).toString() + taborder[0] // right end of row reached
        if (horizontal === 1 && horizontal_index === horizontal_max && index === rows - 1) return false // end of table right bottom, nothing selected
      }

      return index.toString() + field
    }
    return null
  }

  const getInputCell = (index, field, rowsCount, record, roundedTo = 2) => {
    let defaultValue = record[field]
    if (record[field] && !isNaN(record[field])) {
      if (typeof record[field] !== 'string' && record[field] % 1 !== 0) {
        defaultValue = parseFloat(record[field].toFixed(roundedTo))
      }
    }

    //if (record.iAmSubtotal) return <b>{defaultValue}</b>

    return (
      <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference(index, field))}
        onBlur={(value) => handleChange(value, field, index, record.idcosts)}
        onUpdate={(value) => handleChange(value, field, index, record.idcosts)}
        onMoveUp={(value) => setActiveCell(getCellReference(index, field, false, -1))}
        onMoveDown={(value) => setActiveCell(getCellReference(index, field, false, 1))}
        onMoveLeft={(value) => setActiveCell(getCellReference(index, field, -1, false))}
        onMoveRight={(value) => setActiveCell(getCellReference(index, field, 1, false))}
        active={activeCell === getCellReference(index, field)}
        defaultValue={defaultValue} // record[field]
      />
    )
  }

  const getTimeInputCell = (index, field, rowsCount, record) =>
    record.iAmSubtotal ? (
      <b>{record[field]}</b>
    ) : (
      <InputCell
        type="timeinput"
        onClick={() => setActiveCell(getCellReference(index, field))}
        onBlur={(value) => handleChange(value, field, index, record.idcosts)}
        onUpdate={(value) => handleChange(value, field, index, record.idcosts)}
        onMoveUp={(value) => setActiveCell(getCellReference(index, field, false, -1))}
        onMoveDown={(value) => setActiveCell(getCellReference(index, field, false, 1))}
        onMoveLeft={(value) => setActiveCell(getCellReference(index, field, -1, false))}
        onMoveRight={(value) => setActiveCell(getCellReference(index, field, 1, false))}
        active={activeCell === getCellReference(index, field)}
        defaultValue={record[field]}
      />
    )

  const getSelectCell = (index, valueField, titleField, rowsCount, selectProps, record) =>
    record.iAmSubtotal ? (
      <b>{record[titleField]}</b>
    ) : (
      <InputCell
        type="select"
        selectProps={selectProps}
        onClick={() => setActiveCell(getCellReference(index, titleField))}
        onUpdate={(value) => handleChange(value, valueField, index, record.idcosts)}
        onMoveLeft={(value) => setActiveCell(getCellReference(index, titleField, -1, false))}
        onMoveRight={(value) => setActiveCell(getCellReference(index, titleField, 1, false))}
        active={activeCell === getCellReference(index, titleField)}
        defaultValue={record[valueField]}
        defaultTitle={record[titleField]}
      />
    )

  const getGoodSelectCell = (index, valueField, record, titleField, selectOptions, selectProps) => {
    const road = roads.find(({ idroad }) => idroad === record.idroad)
    return (
      <InputCell
        type="goodSelect"
        selectOptions={selectOptions}
        selectProps={selectProps}
        onClick={() => setActiveCell(getCellReference(index, titleField))}
        onUpdate={(value) => handleChange(value, valueField, index, record.idcosts)}
        onMoveLeft={(value) => setActiveCell(getCellReference(index, titleField, -1, false))}
        onMoveRight={(value) => setActiveCell(getCellReference(index, titleField, 1, false))}
        active={activeCell === getCellReference(index, titleField)}
        defaultValue={record.idroad}
        defaultTitle={road ? `(${road?.road_number}) ${road?.name}` : record?.idroad ? `Valitud tee on kustutatud` : ''}
      />
    )
  }

  const getAutocompleteCell = (index, valueField, titleField, rowsCount, selectProps, record) =>
    record.iAmSubtotal ? (
      <b>{record[titleField]}</b>
    ) : (
      <InputCell
        type="autocomplete"
        selectProps={selectProps}
        onBlur={(value) => handleChange(value, valueField, index, record.idcosts)}
        onClick={() => setActiveCell(getCellReference(index, titleField))}
        onUpdate={(value) => handleChange(value, valueField, index, record.idcosts)}
        onMoveLeft={(value) => setActiveCell(getCellReference(index, titleField, -1, false))}
        onMoveRight={(value) => setActiveCell(getCellReference(index, titleField, 1, false))}
        active={activeCell === getCellReference(index, titleField)}
        defaultValue={record[valueField]}
        defaultTitle={record[titleField]}
      />
    )

  async function handleRowAddPayload(payload) {
    addCostData(payload)
  }

  //async function handleRowCopyPayload(payload) {
  //    copyCostData(payload)
  //}

  //async function handleRowDeletePayload(payload) { deleteCostData(payload)}

  const footer = () => {
    let amountsText = 'Kogus kokku: ' + (amounts ? parseFloat(amounts).toFixed(2) : 0).toString()
    let amounts2Text = index === 4 ? 'Km kokku: ' + amount2s : null
    let sumText = 'Summa kokku: € ' + sum

    return costType !== 7 ? (
      <div style={{ width: '100%' }} className="d-flex justify-content-end mr-3">
        <div>
          <b>
            {' '}
            {amounts2Text} {amountsText} {sumText}{' '}
          </b>
        </div>
        <div className="ml-2 mr-2"></div>
      </div>
    ) : null
  }

  const information_columns = [
    { title: '', dataIndex: 'icon', key: 'icon' },
    { title: 'Sündmus', dataIndex: 'event', key: 'event' },
    { title: 'Juhis', dataIndex: 'direction', key: 'direction' }
  ]

  const information_content = [
    {
      icon: <FileDoneOutlined />,
      event: 'Materjal kulus laost ja kulu on töölehel',
      direction:
        'Kui materjali kulu on töölehel ilmuvad siia peale töölehe lõpetamist. Enne töölehe kinnitamist neid siin muuta ei saa. Töölehe saad kinnitada FieldWorksis, misjärel saad siin muuta veel bille ja ühikhinda, karjääri ja lisada kommentaari. Ladu, materjal ja kogus tuleb täita korrektselt töölehel. Materjal kantakse laost maha selle rea alusel automaatselt.'
    },
    {
      icon: <FileDoneOutlined />,
      event: 'Matejal kulus laost, aga ei ole siin näha, ega ei ole ka töölehel',
      direction:
        'Sul on 2 varianti: kas avad töölehe Fieldworks-is ja lisad materjali seal enne kinnitamist töölehele või lisad materjali laost kuluks käsitsi.'
    },
    {
      icon: '',
      event: 'Matejali kulus laost, soovin selle lisada käsitsi',
      direction: 'Lisa uus rida ja täida ära Ladu (vali, kust laost kulutasid materjali) ja kui palju. Seda rida enam täiendavalt kinnitama ei pea.'
    },
    {
      icon: '',
      event: 'Tellisin materjali objektile tarnijalt',
      direction:
        'Lisa uus rida, täida väljad, aga jäta Ladu täitmata (kuna Sa ei saanud materjali verstoni laost). Seda rida enam täiendavalt kinnitama ei pea.'
    }
  ]

  // remove costs descriptions from 10 nov 2021
  if (index === 12 && objectActiveDate.isSameOrAfter(moment('2021-11-10'))) {
    CostTypeColumnDefs[index].columns.splice(7, 1)
  }

  return (
    <div style={{ overflowX: 'auto', background: '#fff' }} className={'p-2 pb-2'}>
      <div className="d-flex justify-content-between m-1">
        <h5>{title}</h5>
        <div>
          {index === 0 && '1900-01-01' !== objectActiveDate.format('YYYY-MM-DD') ? <div>{/* <PaBeginRefresh/> */}</div> : null}
          {billsButton && '1900-01-01' !== objectActiveDate.format('YYYY-MM-DD') ? (
            meta.costing_version === 2 ? (
              <PaGroupBillDivisionV2 records={filteredDataSource} costType={costType} />
            ) : (
              <PaGroupBillDivision records={filteredDataSource} costType={costType} />
            )
          ) : null}

          {addButton ? (
            <Tooltip title="Lisa rida">
              <Button icon={<LegacyIcon type={'plus'} />} shape="circle" disabled={!editAllowed} onClick={() => handleAddRow()} />
            </Tooltip>
          ) : null}
        </div>
      </div>
      {index === 12 ? (
        <div className="pb-1">
          <Collapse accordion>
            <Panel title={'Ava noolest, kui soovid juhiseid materjalide täitmiseks'}>
              <Table
                dataSource={information_content}
                columns={information_columns}
                pagination={{
                  defaultPageSize: 1000,
                  showSizeChanger: false,
                  hideOnSinglePage: true
                }}
              />
            </Panel>
          </Collapse>
        </div>
      ) : null}

      <Table
        columns={columns}
        loading={!!(isBeginLoading && index === 0)}
        dataSource={filteredDataSource}
        locale={{
          emptyText: (
            <div>
              <Empty
                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                imageStyle={{
                  height: 60
                }}
                description={<span>Tabelis ei ole infot.</span>}>
                {editAllowed && index !== 0 ? (
                  <Button type="primary" onClick={() => handleAddRow()}>
                    <b>Lisa rida</b>
                  </Button>
                ) : null}
              </Empty>
            </div>
          )
        }}
        //className={"table-striped-rows"}
        size="middle"
        rowKey={'idcosts'}
        footer={footer}
        pagination={{
          defaultPageSize: 1000,
          showSizeChanger: false,
          hideOnSinglePage: true
        }}
        onRow={(record, rowIndex) => {
          if (selected.includes(record.idcosts))
            return {
              // paint even rows gray
              style: { background: '#e6f7ff' }
            }

          if (!record.unit_price || record.unit_price === 0)
            return {
              style: { background: '#FFB6C1' }
            }
          // if (record.from_warehouse && record.status === 4 && record.idworksheet === null) return {
          //     style: {background: '#ffffb8'}
          // }
          if (rowIndex % 2 !== 0)
            return {
              // paint even rows gray
              style: { background: '#f9f9f9' }
            }
        }}
      />
    </div>
  )
}

export default PaObjectDayCostTable
