import React from 'react';
import {useFormik} from 'formik';

import {useStoreActions} from "easy-peasy";
import {Card, Row, Col, Input, Button} from 'antd'

const SignInForm = () => {

    const login = useStoreActions(actions => actions.user.apiLogin)

    let formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: async (values) => {
            console.log('logging in...')
            login(values)
        }
    });

    return (
        <div style={{
            height: '100%',
            width: '100%',
            margin: '0',
            display: 'flex',
            position: 'absolute'
        }}>

            <Card title='Palun logi sisse' style={{width: '18rem', margin: 'auto'}}>


                    <form onSubmit={formik.handleSubmit}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <label htmlFor="email">E-post</label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <div className="mt-2">
                                    <label htmlFor="password">Parool</label>
                                </div>
                                <Input
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    onPressEnter={formik.handleSubmit}
                                    value={formik.values.password}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Button type="primary" className="mt-3" onClick={() => formik.submitForm()}>Logi
                                    sisse</Button>
                            </Col>
                        </Row>
                    </form>

            </Card>
        </div>

    );
};
export default SignInForm