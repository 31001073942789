import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Steps, Alert } from "antd"
import { PaOffersTable, PaOffersTableV2, PaBudgetsTable } from "../../pages/offers"
import { useStoreState } from "easy-peasy"
import { PaUploadOffer } from "../budget/upload/budgetUpload"

const { Step } = Steps

const PaObjectOffers = () => {

  const allOffers = useStoreState(state => state.offers.allOffers)
  const meta = useStoreState(state => state.object.meta)

  //console.log("meta idobject:", meta.idobject)
  let filteredOffers = allOffers.filter((offer) => offer.obj_id === meta.idobject)

  const [currentStep, setCurrentStep] = useState(0)


  useEffect(() => {
    if (filteredOffers.length > 0) {
      if (filteredOffers[0].is_target_offer === 1)
        setCurrentStep(2)
      else
        setCurrentStep(1)
    }
    // eslint-disable-next-line
  }, [filteredOffers])

  return (
    <div className="mt-3 p-2" style={{ background: "#fff" }}>

      <div className="m-2 p-2" hidden>
        <Alert
          message="Eelarvete põhjad üles laadimiseks"
          description={<div>
            <span>Hetkel kehtiva tulu-kulu-eelarve (pakkumise) põhja leiad </span>
            <Link to="/files/template1.xlsx" target="_blank" rel="noopener noreferrer" download>siit</Link>

          </div>}
          type="info"
          showIcon />
      </div>
      <div className="m-2 p-2">
        <PaUploadOffer />
      </div>
      <div>

        <div className="m-2 p-2">
          {meta.costing_version === 2 ?
            <div>
              <b>Tulueelarved</b>
              <PaOffersTableV2 offers={filteredOffers} />
              <b>Kulueelarved</b>
              <PaBudgetsTable idobject={meta.idobject} />
            </div>
            :
            <PaOffersTable offers={filteredOffers} />
          }
        </div>

      </div>
    </div>
  )
}


export default PaObjectOffers