import React, { useEffect, useState } from 'react'
import { useStoreActions } from 'easy-peasy'
import { Row, Col, Card, Button } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import PindamineTable from './PindamineTable'
import AsfalteerimineTable from './AsfalteerimineTable'
import PaObjectTabsDatepicker from '../ObjectTabsDatepicker'
import * as XLSX from 'xlsx/xlsx.mjs'

const TypeSelectOptions = {
  1: '1,5x',
  2: '1x',
  3: '2x',
  4: '2xE',
  5: '2,5x'
}

const exportAll = (data) => {
  const rows = []
  const flatExportData = data.map((road) => {
    return {
      // Road
      roadNumber: road.road_number,
      name: road.name,
      start: road.start,
      end: road.end,
      area: road.area,
      date: road.date,
      cancelDate: road.cancel_date,
      type: road.type ? TypeSelectOptions[road.type] : '-',
      coveringState: road.covering_state,
      tempAir: road.temp_air,
      tempCovering: road.temp_covering,
      tempBonding: road.temp_bonding,
      // Materials
      gravelFraction: road.gravel_fraction,
      gravel2Fraction: road.gravel2_fraction,
      bondingBrand: road.bonding_brand,
      bondingSupplier: road.bonding_supplier,
      bondingExpense: (road.bonding_amount / road.area) * 1000 || '-',
      gravelExpense: (road.gravel_amount / road.area) * 1000 || '-',
      gravel2Expense: (road.gravel2_amount / road.area) * 1000 || '-',
      bondingFiber: road.bonding_fiber,
      bondingAmount: road.bonding_amount,
      gravelAmount: road.gravel_amount,
      gravel2Amount: road.gravel2_amount,
      bondingFiberAmount: road.bonding_fiber,
      bondingBillNumber: road.bonding_bill_number,
      notes: '',
      addedBy: road.user_name,
      projectId: road.object_shiffer,
      project: road.object_name
    }
  })

  const worksheet = XLSX.utils.json_to_sheet(flatExportData)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates')
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        'TeeNr',
        'Nimetus',
        'AlgusKm',
        'LõppKm',
        'Pindala',
        'Kuupäev',
        'PiiranguteKpv',
        'PindamiseLiik',
        'KatteSeisund',
        'TempÕhk',
        'TempKate',
        'TempSideaine',
        'KillustikMaterjal',
        'KillustikMaterjal2',
        'SideaineMaterjal',
        'SideaineTarnija',
        'SideaineKulu',
        'KillustikKulu',
        'KillustikKulu2',
        'FiiberkiudKulu',
        'SideaineKogus',
        'KillustikKogus',
        'KillustikKogus2',
        'FiiberkiudKogus',
        'SideaineSaateleheNr',
        'Märkused',
        'Lisas',
        'ProjektID',
        'Projekt'
      ]
    ],
    { origin: 'A1' }
  )

  worksheet['!cols'] = [
    { wch: 10 },
    { wch: 40 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 }
  ]

  XLSX.writeFile(workbook, 'Pindamispäevik.xlsx')
}

export const ConstructionDiary = () => {
  const [activeTab, setActiveTab] = useState('pindamine')
  const fetchExportData = useStoreActions((actions) => actions.road.export)
  const exportData = useStoreActions((state) => state.road.exportList)

  const tabList = [
    {
      key: 'pindamine',
      tab: 'Pindamine'
    },
    {
      key: 'asfalteerimine',
      tab: 'Asfalteerimine'
    }
  ]

  const contentList = {
    pindamine: (
      <>
        <Row justify="end" style={{ marginBottom: 18 }} gutter={4}>
          <Col>
            <Button
              onClick={async (e) => {
                const data = await fetchExportData()
                exportAll(data)
              }}
              icon={<FileExcelOutlined style={{ color: 'green' }} />}>
              Ekspordi kõik teed
            </Button>
          </Col>
          <Col>
            <PaObjectTabsDatepicker />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <PindamineTable />
          </Col>
        </Row>
      </>
    ),
    asfalteerimine: (
      <div className="mt-1">
        <Row>
          <Col span={24}>
            <AsfalteerimineTable />
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className="mt-2">
      <Card
        tabList={tabList}
        activeTabKey={activeTab}
        onTabChange={setActiveTab}>
        {contentList[activeTab]}
      </Card>
    </div>
  )
}

export default ConstructionDiary
