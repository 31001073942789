import React from 'react';
import Card from "react-bootstrap/Card";
import { LoadingOutlined } from '@ant-design/icons';

const Loading = () => {
    
    return (
        <div style={{
            height: '100%',
            width: '100%',
            margin: '0',
            display: 'flex',
            position: 'absolute'
        }}>

            <Card style={{width: '18rem', height: '18rem', margin: 'auto'}}>
                <Card.Body>
                    <h1><LoadingOutlined /></h1>
                </Card.Body>
            </Card>
        </div>
    );
};
export default Loading