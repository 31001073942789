// the component that displays icon and popover for cost row. Records must be single record

import { useContext, useEffect, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import {
  LeftSquareOutlined,
  LoadingOutlined,
  RightSquareOutlined
} from '@ant-design/icons'
import {
  Badge,
  Button,
  Col,
  Empty,
  Row,
  Slider,
  Table,
  InputNumber,
  Alert,
  Typography,
  Progress,
  Card
} from 'antd'
import React from 'react'

const { Title } = Typography

// v2
const PaCostBillDivisionContent = ({ records, costType, loading = false }) => {
  const assignmentsActiveCostRows = useStoreState(
    (state) => state.object.assignmentsActiveCostRows
  )
  const assignmentsCurrentList = useStoreState(
    (state) => state.object.assignmentsCurrentList
  )
  const assignmentsInProgressTotal = useStoreState(
    (state) => state.object.assignmentsInProgressTotal
  )

  const setAssignmentsCurrentList = useStoreActions(
    (actions) => actions.object.setAssignmentsCurrentList
  )

  const baseBudget = useStoreState((state) => state.object.budget)
  const costData = useStoreState((state) => state.object.costs)
  const fetchBills = useStoreActions(
    (actions) => actions.bills.apiFetchBillsWithoutArchived
  )
  let bills = useStoreState((state) => state.bills.billsWithoutArchived)

  useEffect(() => {
    fetchBills()
  }, [])

  bills = bills ? bills.structured.sort((a, b) => a.number - b.number) : []

  let record = {}
  if (assignmentsActiveCostRows) record = assignmentsActiveCostRows[0]

  const loendid = assignmentsCurrentList

  // needs refactoring to use state on loendid
  function calculateMaxSliderValue(value, loendindex) {
    return 100 - assignmentsInProgressTotal
  }

  function handleAssignmentChange(value, index, path) {
    let assignments = assignmentsCurrentList.map((d) => d)
    console.log(value, index, path, assignments)

    index = assignments.findIndex((d) => d.bill_path === path)

    if (index !== -1) assignments[index]['assigned'] = value / 100
    else
      assignments.push({
        id: -1, // budget row id
        name: null,
        assigned: value / 100,
        special_name: null,
        bill_path: path
      })

    setAssignmentsCurrentList(assignments)
  }

  let rows = loendid.map((d, index) => (
    <PaCostBillDivisionContentRow
      key={index}
      data={d}
      amount={record.amount ? record.amount : false}
      maxAdditionalSliderVal={100 - assignmentsInProgressTotal}
      onChange={(value) => handleAssignmentChange(value, index, d.bill_path)}
    />
  ))

  console.log('RECORDS', records)

  if (Array.isArray(records) && records.length < 1)
    return (
      <Empty
        imageStyle={{ height: 60 }}
        //image={<div className="mt-4"><Icon type="empty" style={{fontSize: 24, fontWeight: 'bold', color: 'blue'}}/></div>}
        description={<span>Sul ei ole grupis / päeval ridu...</span>}></Empty>
    )

  if (loading)
    return (
      <div>
        <Empty
          imageStyle={{ height: 60 }}
          image={
            <div className="mt-4">
              <LoadingOutlined
                style={{ fontSize: 24, fontWeight: 'bold', color: 'green' }}
              />
            </div>
          }
          description={<span>Määran bille...</span>}></Empty>
      </div>
    )

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      render: (text) => '',
      width: 50
    },
    {
      title: '#',
      key: 'number',
      dataIndex: 'number',
      width: 50,
      align: 'right',
      className: 'projection_actual'
    },
    {
      title: 'Bill',
      key: 'name',
      dataIndex: 'name',
      className: 'projection_actual',
      render: (text, record) => <small> {text}</small>
    },
    {
      title: 'Jaotus %',
      key: 'Jaotus',
      width: 100,
      dataIndex: 'name',
      //className: 'projection_highlight',
      render: (text, record) =>
        record.has_children === 0 ? (
          <PaCostBillDivisionNumberInput
            loendid={loendid}
            path={record.path}
            maxAdditionalSliderVal={100 - assignmentsInProgressTotal}
            onChange={(value, index) =>
              handleAssignmentChange(value, index, record.path)
            }
          />
        ) : (
          ' '
        )
    }
  ]

  return (
    <div style={{ width: 450, overflowY: 'auto', maxHeight: 600 }}>
      {rows.length > 0 ? (
        <div className="mb-2">
          <div className="d-flex justify-content-between">
            <div>
              <h5>Määratud jaotus:</h5>
            </div>
            <div className="mr-2" style={{ width: 200 }}>
              <Progress percent={assignmentsInProgressTotal.toFixed(0)} />
            </div>
          </div>
          <small className="text-muted mb-2">
            Siin on näha jaotus ja saab olemasolevat jaotust kiirelt ümber teha
          </small>
          <Card size="small">
            <Row>
              <Col span={11}>Bill</Col>
              <Col span={1}></Col>

              <Col span={8}>Jaotus %</Col>
              <Col span={4}>Kogus</Col>
            </Row>
            {rows}
          </Card>
        </div>
      ) : (
        <div></div>
      )}
      <h5>Jaota kogus billide vahel:</h5>
      <small className="text-muted mb-2">
        Siin saad jaotada koguse billide vahel:
      </small>
      <Table
        columns={columns}
        dataSource={bills}
        loading={loading}
        indentSize={2}
        rowKey="id"
        size="small"
        pagination={false}
        scroll={{ y: 300 }}
      />
    </div>
  )
}

const PaCostBillDivisionNumberInput = ({
  loendid,
  path,
  maxAdditionalSliderVal,
  onChange
}) => {
  let myIndex = loendid.findIndex((loend) => loend.bill_path === path)
  let myValue = 0

  if (myIndex !== -1) myValue = loendid[myIndex].assigned * 100

  function returnValidValue(value) {
    if (value > myValue) {
      if (value <= myValue + maxAdditionalSliderVal) return value
      else return myValue
    } else return value
  }

  return (
    <InputNumber
      value={myValue}
      min={0}
      //max={maxAdditionalSliderVal}
      formatter={(value) => `${value}%`}
      size="small"
      onChange={(a) => onChange(returnValidValue(a), myIndex)}
      onPressEnter={(e) => onChange(returnValidValue(myValue), myIndex)}
    />
  )
}

const PaCostBillDivisionContentRow = ({
  data,
  amount,
  maxAdditionalSliderVal,
  onChange
}) => {
  let myValue = data.assigned * 100

  function returnValidValue(value) {
    if (value > myValue) {
      if (value <= myValue + maxAdditionalSliderVal) return value
      else return myValue
    } else return value
  }

  let style = data.special_name ? { background: '#e6f7ff' } : {}

  //console.log("ROW CONTENT data: ", data)

  return (
    <div style={style} className="pl-2">
      <Row type="flex" align="middle">
        {data.special_name ? (
          <Col span={11}>
            <div> {data.special_name}</div>
            <small className="text-muted">{data.name}</small>
          </Col>
        ) : (
          <Col span={11}>
            <div>
              {' '}
              {data.bill_path ? data.bill_path.replaceAll('/', '.') : ''}
            </div>
          </Col>
        )}
        <Col span={1}>
          <LeftSquareOutlined onClick={() => onChange(0)} />
        </Col>
        <Col span={7}>
          <Slider
            min={0}
            max={100}
            step={5}
            onChange={(a) => onChange(returnValidValue(a))}
            value={myValue}
            style={{ marginLeft: 10, marginRight: 10 }}
          />
        </Col>
        <Col span={1}>
          <div>
            <RightSquareOutlined
              onClick={() => onChange(myValue + maxAdditionalSliderVal)}
            />
          </div>
        </Col>
        <Col span={4}>
          <div className="ml-2">
            {amount ? (amount * data.assigned).toFixed(2) : '-'}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PaCostBillDivisionContent
