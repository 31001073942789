import { action, computed, thunk, debug } from 'easy-peasy'
import API, { URL } from '../../services/api'

export const roadMaterialsModel = {
  items: {},
  list: computed((state) => Object.values(state.items)),
  byId: computed((state) => (idroad) => state.items[idroad]),

  loading: false,
  error: {},

  // Actions
  setLoading: action((state, loading) => {
    state.loading = loading
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  fetched: action((state, { data, idroad }) => {
    /*
    idroad: {
      id: { ... }
    }
    */

    console.log('WEH ERE', data, idroad)

    state.items[idroad] = data.reduce((acc, item) => {
      const { id } = item

      if (!acc[id]) acc[id] = {}
      acc[id] = item

      return acc
    }, {})
    console.log(debug(state.items))
  }),
  saved: action((state, data) => {
    /*
    idroad: {
      id: { ... }
    }
    */
    if (!state.items[data.idroad]) state.items[data.idroad] = {}
    state.items[data.idroad][data.id] = data
  }),
  // Thunks
  fetch: thunk(async (actions, id) => {
    actions.setLoading(true)
    const { data } = await API(`/api/road/materials?idroad=${id}`)
    console.log('fetched road materials: ', data)
    actions.fetched({ data, idroad: id })
    actions.setLoading(false)
    return data
  }),
  prefill: thunk(async (actions, { id, date }) => {
    const data = await API(`/api/road/prefill`, false, 'post', { id, date })
    actions.fetch(id)
  }),
  add: thunk(async (actions, { idroad, iduser }) => {
    const res = await API('/api/road/materials', false, 'post', { idroad, iduser })
    actions.saved(res.data)
  }),
  update: thunk(async (actions, payload) => {
    const res = await API('/api/road/materials', false, 'put', payload)
    actions.saved(res.data)
  }),
  delete: thunk(async (actions, { id, idroad }) => {
    await API('/api/road/materials', false, 'delete', { id })
    actions.fetch(idroad)
  })
}
