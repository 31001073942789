
// the component that displays icon and popover for cost row. Records must be single record

import {useContext, useEffect, useState} from "react";
import {useStoreState} from "easy-peasy";
import CostDivisionContext from "../../../../CostDivisionContext";
import { LeftSquareOutlined, LoadingOutlined, RightSquareOutlined } from '@ant-design/icons';
import { Col, Empty, Row, Slider } from "antd";
import React from "react";


const PaCostBillDivisionContent = ({records, costType, loading = false}) => {

    const {totalAssigned, setTotalAssigned, loendid, setLoendid} = useContext(CostDivisionContext)

    const [maxAdditionalSliderVal, setMaxAdditionalSliderVal] = useState(0)
    const baseBudget = useStoreState(state => state.object.budget)
    const costData = useStoreState(state => state.object.costs)

    let record = {}
    if (!Array.isArray(records)) record = costData[records.idcosts]

    useEffect(() => {

        let myBudget = baseBudget
            .filter((row) => row.cost_type === costType)
            .map((row) => ({
                id: row.id,  // budget row id
                name: row.bills_types_name,
                assigned: 0,
                special_name: row.art_name
            }))

        console.log("myBudget", myBudget, baseBudget)

        //setLoendid(myBudget)

        let myBudgetModified = myBudget

        if (!Array.isArray(records) && 'assignments' in record) {
            const assignments = record.assignments
            myBudgetModified = myBudget.map(bill => {
                return (
                    {
                        ...bill,
                        //assigned: assignments.filter(v => v.bills_id == loend.id).reduce((sum, current) => sum + current.assignment, 0)
                        assigned: assignments.filter(v => v.costs_budgets_id === bill.id).reduce((sum, current) => sum + current.assignment, 0)
                    }
                )
            })

            let currentAssignments = myBudgetModified.reduce((sum, current) => sum + current.assigned, 0)
            setTotalAssigned(currentAssignments * 100)
        }

        //console.log("MY BILLS", myBudgetModified, record)
        setLoendid(myBudgetModified)
         // eslint-disable-next-line
    }, [costData])


    // needs refactoring to use state on loendid
    function calculateMaxSliderValue(value, loendindex) {
        // console.log(value, loendindex)

        loendid[loendindex]['assigned'] = value / 100
        let currentassignments = loendid.reduce((sum, current) => sum + current.assigned, 0)

        setMaxAdditionalSliderVal((1 - currentassignments) * 100)
        setTotalAssigned(currentassignments * 100)

        // console.log("Loendid: ", loendid, currentassignments)
    }

    let rows = loendid.map((d, index) =>
        <PaCostBillDivisionContentRow
            key={index}
            data={d}
            amount={record.amount ? record.amount : false}
            maxAdditionalSliderVal={maxAdditionalSliderVal}
            onChange={(value) => calculateMaxSliderValue(value, index)}
        />)

    if (loading) return (
        <div>
            <Empty
                imageStyle={{height: 60}}
                image={<div className="mt-4"><LoadingOutlined style={{fontSize: 24, fontWeight: 'bold', color: 'green'}} /></div>}
                description={
                    <span>Määran bille...</span>}
            >
            </Empty>
        </div>
    );

    //console.log("Rows", rows)

    return (
        <div style={{width: 450, overflowY: 'auto', maxHeight: 600}} className="pl-2">
            {rows.length > 0 ?
                <div style={{overflowX: 'auto', maxHeight: 550 }}>
                    <Row>
                        <Col span={1}>

                        </Col>
                        <Col span={8}>
                            Jaotus %
                        </Col>
                        <Col span={4}>
                            Kogus
                        </Col>
                        <Col span={11}>
                            Nimetus
                        </Col>
                    </Row>
                    {rows}
                </div>
                :
                <div>
                    <Empty
                        imageStyle={{height: 60}}
                        description={
                            <span>Sul ei ole sellele kulu tüübile eelarvet?<br/>Kas on eelarves määramata?<br/>Kui peaks olema, kontakteeru eelarve osakonnaga või oma projektijuhiga.</span>}
                    >
                    </Empty>
                </div>
            }
        </div>)

}


const PaCostBillDivisionContentRow = ({data, amount, maxAdditionalSliderVal, onChange}) => {

    let myValue = data.assigned * 100

    function returnValidValue(value) {
        if (value > myValue) {
            if (value <= myValue + maxAdditionalSliderVal) return value
            else return myValue
        } else return value
    }

    let style = data.special_name ? {background: '#e6f7ff'} : {}

    //console.log("ROW CONTENT data: ", data)

    return (
        <div style={style} className="pl-2">
            <Row type="flex" align="middle">
                <Col span={1}>
                    <LeftSquareOutlined onClick={() => onChange(0)} />
                </Col>
                <Col span={7}>
                    <Slider min={0}
                            max={100}
                            step={5}
                            onChange={(a) => onChange(returnValidValue(a))}
                            value={myValue}
                            style={{marginLeft: 10, marginRight: 10}}
                    />
                </Col>
                <Col span={1}>
                    <div><RightSquareOutlined onClick={() => onChange(myValue + maxAdditionalSliderVal)} /></div>
                </Col>
                <Col span={4}>
                    <div className="ml-2">{amount ? (amount * data.assigned).toFixed(2) : "-"}</div>
                </Col>
                {data.special_name ?
                    <Col span={11}>
                        <div> {data.special_name}</div>
                        <small className="text-muted">{data.name}</small>
                    </Col>
                    :
                    <Col span={11}>
                        <div> {data.name}</div>
                    </Col>
                }
            </Row>
        </div>
    );
}

export default PaCostBillDivisionContent