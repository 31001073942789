import {action, thunk} from "easy-peasy";
import API from "../../services/api";

export const billsModel = {

    bills: {
        flat: [],
        structured: []
    },
    billsWithoutArchived: {
        flat: [],
        structured: []
    },
    loading: true,

    setLoading: action((state, payload) => state.loading = payload),
    setBills: action((state, payload) => state.bills = payload),
    setBillsWithoutArchived: action((state, payload) => state.billsWithoutArchived = payload),

    apiFetchBills: thunk(async (actions) => {
        const res = await API(`/api/bills`)
        actions.setBills(res.data)
    }),

    apiFetchBillsWithoutArchived: thunk(async (actions) => {
        const res = await API(`/api/bills?withoutArchived=1`)
        actions.setBillsWithoutArchived(res.data)
    }),

    apiUpdateBills: thunk(async (actions, payload) => {
        const res = await API("/api/bills", false, "post", payload)
        actions.setBills(res.data)
    }),

    apiAddBills: thunk(async (actions, payload) => {
        const res = await API("/api/bills", false, "put", payload)
        actions.setBills(res.data)
    }),

}