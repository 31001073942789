function makeAssignment(loendid, costData) {

    console.log("Got input for assigning: ", loendid, costData)
    let return_payload = []

    costData.forEach((costRow) => {
        loendid.forEach((assignRow) => {
                let row = {
                    'idcosts': costRow.idcosts,
                    'budget_id': assignRow.id,
                    'assigned_amount': assignRow.assigned.toFixed(3),
                    'bill_path': null
                }
                return_payload.push(row)
            }
        )
    })
    return return_payload
}

export default makeAssignment