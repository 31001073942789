//import {Button, ButtonGroup} from "@blueprintjs/core";
import React, { useState, useEffect, useCallback } from 'react'
import PageWithHeader from '../components/PageWithHeader'

import { EditOutlined, FileTextOutlined, InfoCircleOutlined, LockOutlined, StarOutlined, SaveOutlined } from '@ant-design/icons'
import { Table, Button, Input, Card, Typography, Tooltip, Tag, Badge, Spin } from 'antd'
import ProTable, { IntlProvider, createIntl } from '@ant-design/pro-table'
import '../App.css'
import moment from 'moment'
import { useHistory, withRouter } from 'react-router'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { smartSearch } from '../services/smartSearch'
import PaObjectsStatus from '../components/PaObjectsStatus'
import PaObjectsFavourite from '../components/PaObjectsFavourite'
import { SmallObjectsOverview } from './smallObjectsOverview'
import { useFilters } from '../utils/hooks'
import GoogleMapReact from 'google-map-react'
import { Statuses } from '../helpers/statuses'
import FilterMenu from '../components/FilterMenu'

const { Search } = Input
const { Title } = Typography

const etProTableLocale = {
  tableForm: {
    search: 'Otsing',
    reset: 'Taasta',
    submit: 'Kinnita',
    collapsed: 'Laienda',
    expand: 'Sulge',
    inputPlaceholder: 'Sisestage tekst',
    selectPlaceholder: 'Palun valige'
  },
  alert: {
    clear: 'Tühjenda'
  },
  tableToolBar: {
    leftPin: 'Kinnita vasakule',
    rightPin: 'Kinnita paremale',
    noPin: 'Eemalda kinnitus',
    leftFixedTitle: 'Vasakule kinnitatud',
    rightFixedTitle: 'Paremale kinnitatud',
    noFixedTitle: 'Kinnitamata',
    reset: 'Taasta valikud',
    columnDisplay: 'Tulbad',
    columnSetting: 'Muuda tulpade kuvamist',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    reload: 'Värskenda andmed',
    density: 'Tabeli tihedus',
    densityDefault: 'Vaikimisi',
    densityLarger: 'Suurem',
    densityMiddle: 'Keskmine',
    densitySmall: 'Väiksem'
  }
}

const PaObjects = () => {
  const objects = useStoreState((state) => state.objects.objects)
  const loadingObjects = useStoreState((state) => state.objects.loading)

  let history = useHistory()

  const [search, setSearch] = useState('')
  const [activeTabKey, setActiveTabKey] = useState('ehitus')

  const searchColumns = ['idobject', 'name', 'shiffer', 'user_positions_name', 'proj_m_name', 'obj_m_name']
  const tabList = [
    {
      key: 'ehitus',
      tab: 'Ehitus'
    },
    {
      key: 'tootmine',
      tab: 'Tootmine'
    },
    {
      key: 'small',
      tab: 'Väikeobjektid'
    }
  ]

  const contentList = {
    ehitus: (
      <PaObjectsTable
        dataSource={smartSearch(
          objects.filter((r) => r.type === 'Ehitus'),
          search,
          searchColumns
        )}
        className={'table-striped-rows'}
        tableName="ehitus"
        handleEdit={handleEdit}
        loading={loadingObjects}
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          onChange: (page, pageSize) => console.log('page: ', page, 'pagesize: ', pageSize),
          onShowSizeChange: (current, size) => console.log('page: ', current, 'pagesize: ', size)
        }}
      />
    ),
    tootmine: (
      <PaObjectsTable
        dataSource={smartSearch(
          objects.filter((r) => r.type === 'Tootmine'),
          search,
          searchColumns
        )}
        className={'table-striped-rows'}
        tableName="tootmine"
        handleEdit={handleEdit}
        loading={loadingObjects}
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          onChange: (page, pageSize) => console.log('page: ', page, 'pagesize: ', pageSize),
          onShowSizeChange: (current, size) => console.log('page: ', current, 'pagesize: ', size)
        }}
      />
    ),
    small: <SmallObjectsOverview loading={loadingObjects} search={search} />
  }

  function handleEdit(record) {
    console.log('click!', record)
    history.push('/objects/update/' + record.idobject)
  }

  return (
    <PageWithHeader>
      <div className="m-2">
        <Card
          title={<div className="ml-2">Objektid</div>}
          bordered={false}
          bodyStyle={{ padding: 0 }}
          tabList={tabList}
          activeTabKey={activeTabKey}
          onTabChange={(key) => setActiveTabKey(key)}
          extra={
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <Search value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Otsi..." />
              </div>
              <div className="mr-2">
                <Button type="primary" onClick={() => history.push('/objects/new')} className="ml-2">
                  Lisa uus
                </Button>
              </div>
            </div>
          }>
          <div
            className="m-1"
            style={{
              overflowX: 'auto',
              background: '#fff'
            }}>
            {contentList[activeTabKey]}
          </div>
        </Card>
      </div>
    </PageWithHeader>
  )
}

export const PaObjectsTable = ({ dataSource, loading, pagination, tableName = 'objects' }) => {
  const fetchUsers = useStoreActions((actions) => actions.users.fetch)
  const fetchFilters = useStoreActions((actions) => actions.filters.fetchFilters)
  const users = useStoreState((state) => state.users.list)
  const currentUser = useStoreState((state) => state.user.me)
  const setPrefs = useStoreActions((actions) => actions.user.apiPostMe)
  const divisions = useStoreState((state) => state.divisions.divisions)

  const et_EE = createIntl('et_EE', etProTableLocale)

  // Update filters + columns in user prefs
  const saveTablePrefs = useCallback(
    (payload) => {
      console.log(payload)
      if (currentUser) {
        const { prefs } = currentUser
        if (!prefs['tables']) prefs['tables'] = {}
        const table = prefs['tables'][tableName]
        prefs['tables'][tableName] = { ...table, ...payload }
        setPrefs({ prefs })
      }
    },
    [currentUser, setPrefs, tableName]
  )

  const roundInt = (text) => {
    return text !== '-' ? `${parseInt(text).toLocaleString('et-EE')}€` : text
  }

  moment.locale('et')

  useEffect(() => {
    fetchFilters()
  }, [])

  const [filters, setFilters] = useFilters(tableName)
  const [columnsStateMap, setColumnsStateMap] = useState(filters?.columns ? filters.columns : {})

  useEffect(() => {
    fetchUsers()
  }, [])

  const filteredUsers = users.map(({ user_name, user_surname }) => ({
    text: `${user_name} ${user_surname}`,
    value: `${user_name} ${user_surname}`
  }))

  const filteredDivisions = divisions.map(({ name, id }) => ({
    text: name,
    value: id
  }))

  let history = useHistory()
  function handleEditX(record) {
    history.push('/objects/update/' + record.idobject)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'idobject',
      key: 'idobject',
      sorter: (a, b) => a.idobject - b.idobject,
      render: (text, record, index) => record?.idobject
    },
    {
      title: <StarOutlined />,
      dataIndex: 'favorite',
      key: 'favorite',
      //sorter: (a, b) => a.status - b.status,
      render: (text, record, index) => <PaObjectsFavourite idobject={record?.idobject} />,
      width: 30
    },
    {
      title: (
        <>
          <FileTextOutlined style={{ color: 'green' }} />
          {` Staatus`}
        </>
      ),
      dataIndex: 'status',
      key: 'status',
      filters: Object.keys(Statuses).map((id) => ({ text: Statuses[id], value: id })),
      filteredValue: filters?.filtered?.status || null,
      onFilter: (value, record) => {
        if (record) return record.status === Number(value)
      },
      sorter: (a, b) => a.status - b.status,
      render: (status) => <PaObjectsStatus status={status} />
    },
    {
      title: <Tooltip title="Kuluarvestuse versioon: 1 - kuni 2020, 2 - alates 2021">Ver</Tooltip>,
      dataIndex: 'costing_version',
      key: 'costing_version',
      filters: [
        { text: 'Vana', value: 1 },
        { text: 'Uus', value: 2 }
      ],
      filteredValue: filters?.filtered?.costing_version || null,
      onFilter: (value, record) => {
        if (record) return record.costing_version === value
      },
      sorter: (a, b) => a.costing_version - b.costing_version,
      render: (costing_version) => (
        <Tooltip title={'Kuluarvestuse loogika  ' + costing_version === 1 ? ' kuni 2020' : ' alates 2021'}>
          {' '}
          {costing_version === 1 ? <Tag color="gold">1</Tag> : <Tag color="blue">2</Tag>}{' '}
        </Tooltip>
      )
    },
    {
      title: 'Šiffer',
      dataIndex: 'shiffer',
      key: 'shiffer',
      sorter: (a, b) => a.shiffer.localeCompare(b.shiffer)
    },
    {
      title: 'Nimi',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, index) => (
        <a
          onClick={() => history.push('/object/' + record.idobject)}
          //onClick={() => (<Redirect to={"/object/"+record.idobject}/>)}
        >
          {record.restricted === 1 ? <LockOutlined style={{ color: 'red' }} /> : null} <b>{record.name}</b>
          <br />
          <small>{record.address} </small>
          <br />
          <small>{record.is_small_object ? <Tag>Väikeobjekt</Tag> : ''}</small>
        </a>
      )
    },
    {
      title: 'Üksus',
      dataIndex: 'iddivision',
      key: 'iddivision',
      filters: filteredDivisions,
      filteredValue: filters?.filtered?.iddivision || null,
      onFilter: (value, record) => {
        if (record) return record.iddivision === Number(value)
      },
      sorter: (a, b) => {
        const divNameA = divisions.find(({ id }) => a.iddivision === id)?.name
        const divNameB = divisions.find(({ id }) => b.iddivision === id)?.name
        return (divNameA ?? '').localeCompare(divNameB ?? '')
      },
      render: (text, record, index) => {
        const divName = divisions.find(({ id }) => record.iddivision === id)?.name
        return divName
      }
    },
    {
      title: 'Asukoht',
      dataIndex: 'location',
      key: 'location',
      //sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, index) => (
        <>
          <small>Lat: {parseFloat(record.location_lat).toFixed(7)}</small>
          <br />
          <small>Long: {parseFloat(record.location_long).toFixed(7)}</small>
        </>
      )
    },
    {
      title: 'Algus',
      dataIndex: 'begin_planned',
      key: 'begin_planned',
      sorter: (a, b) => moment(a.begin_planned).unix() - moment(b.begin_planned).unix(),
      render: (text, record, index) => <div>{moment(record.begin_planned).format('DD.MM.YYYY')}</div>
    },
    {
      title: 'Tähtaeg',
      dataIndex: 'end_planned',
      key: 'end_planned',
      sorter: (a, b) => moment(a.end_planned).unix() - moment(b.end_planned).unix(),
      render: (text, record, index) => <div>{moment(record.end_planned).format('DD.MM.YYYY')}</div>
    },
    {
      title: 'Muudetud',
      dataIndex: 'changed_date',
      key: 'changed_date',
      sorter: (a, b) => moment(a.changed_date).unix() - moment(b.changed_date).unix(),
      render: (text, record, index) => <div>{moment(record.changed_date).format('DD.MM.YYYY')}</div>
    },
    {
      title: 'Objektijuht',
      dataIndex: 'obj_m_name',
      key: 'obj_m_name',
      filters: filteredUsers,
      filteredValue: filters?.filtered?.obj_m_name || null,
      onFilter: (value, record) => {
        if (record) return record.obj_m_name === value
      },
      sorter: (a, b) => (a.obj_m_name ?? '').localeCompare(b.obj_m_name ?? ''),
      render: (text, record, index) => (
        <div>
          {record.obj_m_name} <br />
          <small>{record.obj_m_phone} </small>
        </div>
      )
    },
    {
      title: 'Projektijuht',
      dataIndex: 'proj_m_name',
      key: 'proj_m_name',
      filters: filteredUsers,
      filteredValue: filters?.filtered?.proj_m_name || null,
      onFilter: (value, record) => {
        if (record) return record.proj_m_name === value
      },
      sorter: (a, b) => (a.proj_m_name ?? '').localeCompare(b.proj_m_name ?? ''),
      render: (text, record, index) => (
        <div>
          {record.proj_m_name} <br />
          <small>{record.proj_m_phone} </small>
        </div>
      )
    },
    {
      title: 'Meeskond',
      dataIndex: 'team',
      key: 'team',
      //sorter: (a, b) => a.client_contact - b.actual_overhead,
      render: (text, record) => {
        const members = record?.team && record.team.substr(1, record.team.length - 2).split(',')
        return (
          members &&
          members.map((iduser, index) => {
            const member = users && users.find((user) => user.iduser === parseInt(iduser))
            if (member) return <Tag key={`object-user-${index}`}>{`${member.user_name} ${member.user_surname}`}</Tag>
          })
        )
      }
    },
    {
      title: (
        <Tooltip title="Probleemsete kulude arv">
          <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'problematic_cost_count',
      key: 'problematic_cost_count',
      filters: [
        { text: 'Probleemsete kuludega', value: 1 },
        { text: 'Ilma probleemsete kuludeta', value: 0 }
      ],
      filteredValue: filters?.filtered?.problematic_cost_count || null,
      onFilter: (value, record) => {
        if (record) return value === 0 ? record.problematic_cost_count === value : record.problematic_cost_count >= value
      },
      sorter: (a, b) => a.problematic_cost_count - b.problematic_cost_count,
      render: (text, record, index) => <Badge count={text} />
    },
    {
      title: 'Tegevus',
      dataIndex: 'idobject',
      key: 'actions',
      render: (text, record, index) => (
        <Tooltip title="Muuda objekti seadeid">
          <span>
            <a onClick={(event) => handleEditX(record)}>
              <EditOutlined />
            </a>
          </span>
        </Tooltip>
      )
    },
    {
      title: 'Kliendi kontakt',
      dataIndex: 'client_contact',
      key: 'client_contact',
      //sorter: (a, b) => a.client_contact - b.actual_overhead,
      render: (text, record, index) => text
    },
    {
      title: 'Klient',
      dataIndex: 'client_name',
      key: 'client_name',
      //sorter: (a, b) => a.client_contact - b.actual_overhead,
      render: (text, record, index) => text
    },
    {
      title: 'Üldkulu tonnihind',
      dataIndex: 'overhead_price_per_ton',
      key: 'overhead_price_per_ton',
      //sorter: (a, b) => a.client_contact - b.actual_overhead,
      render: (text, record, index) => (text !== '-' ? parseFloat(text).toFixed(2) : text)
    },
    {
      title: 'Üldkulu määr',
      dataIndex: 'overhead_rate',
      key: 'overhead_rate',
      //sorter: (a, b) => a.client_contact - b.actual_overhead,
      render: (text, record, index) => (record?.overhead_rate ? `${parseFloat(record.overhead_rate) * 100}%` : '-')
    },
    {
      title: 'Toode',
      dataIndex: 'product',
      key: 'product',
      //sorter: (a, b) => a.client_contact - b.actual_overhead,
      render: (text, record, index) => (
        <>
          {record?.product}
          <br />
          <small>{record?.product_description}</small>
        </>
      )
    },
    /*     {
          title: "product_description",
          dataIndex: "product_description",
          key: "product_description",
          //sorter: (a, b) => a.client_contact - b.actual_overhead,
          render: (text, record, index) => text
        }, */
    {
      title: 'Tootegrupp',
      dataIndex: 'product_group',
      key: 'product_group',
      sorter: (a, b) => a.product_group - b.product_group,
      render: (text, record, index) => text
    },
    {
      title: 'Töödejuht',
      dataIndex: 'superviser_company',
      key: 'superviser_company',
      sorter: (a, b) => a.superviser_company - b.superviser_company,
      render: (text, record, index) => text
    },
    {
      title: 'Töödejuhi kontakt',
      dataIndex: 'superviser_contact',
      key: 'superviser_contact',
      sorter: (a, b) => a.superviser_contact - b.superviser_contact,
      render: (text, record, index) => text
    },
    {
      title: 'Eesmärk',
      key: 'target',
      children: [
        {
          title: 'Tulu',
          align: 'center',
          className: 'projection',
          dataIndex: 'target_income_sum',
          key: 'target_income_sum',
          sorter: (a, b) => a.target_income_sum - b.target_income_sum,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Kulu',
          className: 'projection',
          align: 'center',
          dataIndex: 'target_cost_sum',
          key: 'target_cost_sum',
          sorter: (a, b) => a.target_cost_sum - b.target_cost_sum,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Kate',
          className: 'projection',
          align: 'center',
          dataIndex: 'target_gross_profit',
          key: 'target_gross_profit',
          sorter: (a, b) => a.target_gross_profit - b.target_gross_profit,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Üldkulu',
          className: 'projection',
          align: 'center',
          dataIndex: 'target_overhead',
          key: 'target_overhead',
          sorter: (a, b) => a.target_overhead - b.target_overhead,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Tulem',
          className: 'projection',
          align: 'center',
          dataIndex: 'target_net_profit',
          key: 'target_net_profit',
          sorter: (a, b) => a.target_net_profit - b.target_net_profit,
          render: (text, record, index) => roundInt(text)
        }
      ]
    },
    {
      title: 'Teostatud',
      key: 'completed',
      children: [
        {
          title: 'Tulu',
          className: 'projection_actual',
          align: 'center',
          dataIndex: 'completed_by_income',
          key: 'completed_by_income',
          //sorter: (a, b) => a.client_contact - b.actual_overhead,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Kulu',
          className: 'projection_actual',
          align: 'center',
          dataIndex: 'completed_by_cost',
          key: 'completed_by_cost',
          //sorter: (a, b) => a.client_contact - b.actual_overhead,
          render: (text, record, index) => roundInt(text)
        }
      ]
    },
    {
      title: 'Tegelik',
      key: 'actual',
      children: [
        {
          title: 'Tulu',
          className: 'projection_highlight',
          align: 'center',
          dataIndex: 'actual_income_sum',
          key: 'actual_income_sum',
          sorter: (a, b) => a.actual_income_sum - b.actual_income_sum,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Kulu',
          className: 'projection_highlight',
          align: 'center',
          dataIndex: 'actual_cost_sum',
          key: 'actual_cost_sum',
          sorter: (a, b) => a.actual_cost_sum - b.actual_cost_sum,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Kate',
          className: 'projection_highlight',
          align: 'center',
          dataIndex: 'actual_gross_profit',
          key: 'actual_gross_profit',
          sorter: (a, b) => a.actual_gross_profit - b.actual_gross_profit,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Üldkulu',
          className: 'projection_highlight',
          align: 'center',
          dataIndex: 'actual_overhead',
          key: 'actual_overhead',
          sorter: (a, b) => a.actual_overhead - b.actual_overhead,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Tulem',
          className: 'projection_highlight',
          align: 'center',
          dataIndex: 'actual_net_profit',
          key: 'actual_net_profit',
          sorter: (a, b) => a.actual_net_profit - b.actual_net_profit,
          render: (text, record, index) => roundInt(text)
        }
      ]
    },
    {
      title: 'Järel',
      key: 'remaining',
      children: [
        {
          title: 'Tulu',
          className: 'projection_final',
          align: 'center',
          dataIndex: 'remaining_income_sum',
          key: 'remaining_income_sum',
          sorter: (a, b) => a.remaining_income_sum - b.remaining_income_sum,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Kulu',
          className: 'projection_final',
          align: 'center',
          dataIndex: 'remaining_cost_sum',
          key: 'remaining_cost_sum',
          sorter: (a, b) => a.remaining_cost_sum - b.remaining_cost_sum,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Kate',
          className: 'projection_final',
          align: 'center',
          dataIndex: 'remaining_gross_profit',
          key: 'remaining_gross_profit',
          sorter: (a, b) => a.remaining_gross_profit - b.remaining_gross_profit,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Üldkulu',
          className: 'projection_final',
          align: 'center',
          dataIndex: 'remaining_overhead',
          key: 'remaining_overhead',
          sorter: (a, b) => a.remaining_overhead - b.remaining_overhead,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Tulem',
          className: 'projection_final',
          align: 'center',
          dataIndex: 'remaining_net_profit',
          key: 'remaining_net_profit',
          sorter: (a, b) => a.remaining_net_profit - b.remaining_net_profit,
          render: (text, record, index) => roundInt(text)
        }
      ]
    },
    {
      title: 'Prognoos',
      key: 'prognosis',
      children: [
        {
          title: 'Tulu',
          className: 'projection_actual',
          align: 'center',
          dataIndex: 'prognosis_income_sum',
          key: 'prognosis_income_sum',
          sorter: (a, b) => a.prognosis_income_sum - b.prognosis_income_sum,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Kulu',
          className: 'projection_actual',
          align: 'center',
          dataIndex: 'prognosis_cost_sum',
          key: 'prognosis_cost_sum',
          sorter: (a, b) => a.prognosis_cost_sum - b.prognosis_cost_sum,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Kate',
          className: 'projection_actual',
          align: 'center',
          dataIndex: 'prognosis_gross_profit',
          key: 'prognosis_gross_profit',
          sorter: (a, b) => a.prognosis_gross_profit - b.prognosis_gross_profit,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Üldkulu',
          className: 'projection_actual',
          align: 'center',
          dataIndex: 'prognosis_overhead',
          key: 'prognosis_overhead',
          sorter: (a, b) => a.prognosis_overhead - b.prognosis_overhead,
          render: (text, record, index) => roundInt(text)
        },
        {
          title: 'Tulem',
          className: 'projection_actual',
          align: 'center',
          dataIndex: 'prognosis_net_profit',
          key: 'prognosis_net_profit',
          sorter: (a, b) => a.prognosis_net_profit - b.prognosis_net_profit,
          render: (text, record, index) => roundInt(text)
        }
      ]
    }
  ]

  return (
    <>
      <div style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 18 }}>
        <FilterMenu
          right={
            <Button style={{ float: 'right' }} type="primary" onClick={() => saveTablePrefs(filters)}>
              <SaveOutlined /> Salvesta tabeli seaded
            </Button>
          }
          filters={[
            ...columns.filter((c) => c.filters && c.filters.length),
            {
              title: 'Ajavahemik',
              key: 'dateTimes',
              filters: 'datetime',
              filteredValue: filters?.filtered?.dateTimes || null
            }
          ]}
          onChange={(filterValues) => setFilters({ ...filters, filtered: filterValues })}
        />
      </div>
      <IntlProvider value={et_EE}>
        <ProTable
          columns={columns}
          loading={loading}
          pagination={{
            ...pagination,
            showTotal: (total, range) => <div>{`Kuvatud on ${range[0]}-${range[1]}, Kokku ${total} rida`}</div>
          }}
          dataSource={
            filters?.filtered?.dateTimes && filters?.filtered?.dateTimes.length > 0
              ? dataSource.filter(({ begin_planned, end_planned }) => {
                  const dates = filters.filtered.dateTimes
                  return (
                    moment(begin_planned).isBetween(dates[0], dates[1], 'day', '[]') ||
                    moment(end_planned).isBetween(dates[0], dates[1], 'day', '[]')
                  )
                })
              : dataSource
          }
          options={{
            search: false,
            fullScreen: false
          }}
          tableStyle={{ overflowX: 'scroll' }}
          rowKey="key"
          columnsStateMap={columnsStateMap}
          onColumnsStateChange={(map) => {
            setColumnsStateMap(map)
            setFilters({ columns: map })
          }}
          search={false}
          dateFormatter="string"
        />
      </IntlProvider>
    </>
  )
}

const PaObjectMarker = ({ lat, lng, text, badgeCount = 0 }) => (
  <div className="pin3 d-flex align-items-center">
    <div>
      {' '}
      <b>{text}</b>
    </div>
  </div>
)

export const PaObjectsMap = ({ dataSource, className = null, style = { height: '50vh', width: '100%' } }) => {
  let history = useHistory()

  return (
    // Important! Always set the container height explicitly
    <div style={style} className={className}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyATtrIbRLIdQcVx-ybqUwrs5JvbGLzUGQc', language: 'ET' }}
        defaultCenter={{
          lat: 58.88711,
          lng: 25.569944
        }}
        defaultZoom={7}>
        {dataSource
          ? dataSource.map((r, index) => <PaObjectMarker key={`marker-${index}`} lat={r.location_lat} lng={r.location_long} text={r.shiffer} />)
          : null}
      </GoogleMapReact>
    </div>
  )
}

const PaObjectCard = ({ object, style = false, className = false }) => {
  let history = useHistory()

  return (
    <div style={style} className={className}>
      <div className="d-flex">
        <Title level={4}>{object.shiffer}</Title>
        <div className="ml-2">{object.name}</div>
      </div>
      <div>
        <Button.Group>
          <Button onClick={() => history.push('/livepaver/' + object.idobject)}>Jälgi koormaid</Button>
          <Button onClick={() => history.push('/object/' + object.idobject)}>Päev</Button>
        </Button.Group>
      </div>
    </div>
  )
}

export const PaObjectsList = ({
  dataSource,
  style = false,
  itemStyle = false,
  className = false,
  itemClassName = false,
  size = 'middle',
  pagination,
  handleEdit,
  loading = false
}) => {
  let objects = dataSource.map((d, index) => <PaObjectCard key={`card-${index}`} object={d} style={itemStyle} className={itemClassName} />)

  return (
    <div className={className} style={style}>
      {objects}
    </div>
  )
}

export const PaObjectsList2 = ({
  dataSource,
  style = false,
  itemStyle = false,
  className = false,
  itemClassName = false,
  size = 'middle',
  pagination,
  handleEdit,
  loading = false
}) => {
  let objects = dataSource.map((data, index) => ({
    id: index,
    content: <PaObjectCard object={data} style={itemStyle} className={itemClassName} />
  }))

  const columns = [
    {
      title: null,
      dataIndex: 'content',
      key: 'key'
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={objects}
      className={className}
      size={size}
      rowKey={'id'}
      showHeader={false}
      //pagination={pagination}
      loading={loading}
    />
  )
}

export default withRouter(PaObjects)
