import React, { useState, useCallback } from 'react'
import { DownOutlined, FilterTwoTone, CloseOutlined } from '@ant-design/icons'
import { Row, Col, Dropdown, Button, Menu, Checkbox, Tag, DatePicker, Input } from 'antd'
import moment from 'moment'
import { formatDatetime, longDateFormat } from '../utils/formats'
import './FilterMenu.css'
import { orderBy } from 'lodash'
import dateLocale from 'antd/es/date-picker/locale/et_EE'


export const FilterDropdown = ({ filterName, onChange, title, options, filteredValue, sortAlphabetically = true, style = { marginRight: 8 } }) => {
  const [visible, setVisible] = useState(false)
  const [searchValue, setSearchValue] = useState()
  let filteredOptions = options.filter(({ text, value }) => !searchValue || searchValue && String(text.toString()).toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 || value.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)

  if (sortAlphabetically) {
    filteredOptions = orderBy(filteredOptions, [option => option.text?.toLowerCase()], ['desc']).reverse()
  }
  return (
    <Dropdown
      onVisibleChange={(visible) => setVisible(visible)}
      visible={visible}
      overlay={(
        <Menu
          style={{ maxHeight: 300, overflowY: 'scroll', paddingTop: 0, marginTop: 4 }}
        >
          <Menu.Item disabled key={`filterSearch`} onClick={({ domEvent }) => domEvent.stopPropagation()} style={{ position: 'sticky', top: 0, zIndex: 1, paddingTop: 8, backgroundColor: 'white' }}>
            <Input.Search allowClear size="small" value={searchValue} onChange={({ target: { value } }) => setSearchValue(value)} />
          </Menu.Item>
          {filteredOptions.map(({ text, value }) => (
            <Menu.Item disabled key={`filterValue-${value}`} onClick={({ domEvent }) => domEvent.stopPropagation()}>
              <Checkbox value={value} checked={filteredValue && filteredValue.includes(value)} onChange={({ target: { value } }) => onChange({ filterName, value })}>{text}</Checkbox>
            </Menu.Item>
          ))}
          <Menu.Item style={{ position: 'sticky', bottom: -8, zIndex: 1, margin: 0, padding: 0, marginBottom: 8, backgroundColor: 'white' }}>
            <Menu>
              <Menu.Item key={`filterValue-all`} onClick={({ domEvent }) => domEvent.stopPropagation()} style={{ margin: 0, padding: 0 }}>
                <Button type='link' onClick={() => onChange({ filterName, value: 'all', options })}>
                  Vali kõik
                </Button>
              </Menu.Item>
              <Menu.Item key={`filterValue-clear`} style={{ margin: 0, padding: 0 }}>
                <Button type='link' onClick={() => onChange({ filterName, value: 'clear' })}>
                  Tühista kõik
                </Button>
              </Menu.Item>
            </Menu>
          </Menu.Item>
        </Menu>
      )}
    >
      <Button style={style}>
        {title} <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export const FilterDates = ({ filterName, onChange, title, filteredValue }) => {
  const [visible, setVisible] = useState(false)
  return (
    <Dropdown
      onVisibleChange={(visible) => setVisible(visible)}
      visible={visible}
      overlay={(
        <Menu>
          <Menu.Item>
            <DatePicker.RangePicker
              locale={dateLocale}
              value={filteredValue && filteredValue.length ? [moment(filteredValue[0]).startOf('day'), moment(filteredValue[1]).endOf('day')] : false}
              onChange={value => {
                onChange({ filterName, value: [moment(value[0]).startOf('day'), moment(value[1]).endOf('day')] })
              }}
              ranges={{
                ['Täna']: [moment().startOf('day'), moment().endOf('day')],
                ['See kuu']: [moment().startOf('month'), moment().endOf('month')],
                ['Eelmine kuu']: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
              }}
            />
          </Menu.Item>
        </Menu>
      )}
    >
      <Button style={{ marginRight: 8 }}>
        {title} <DownOutlined />
      </Button>
    </Dropdown>
  )
}

const addOrRemove = (arr, item) => arr.includes(item) ? arr.filter(i => i !== item) : [...arr, item]

const ActiveFilterList = ({ onChange, customFilters, filters, filterValues = {}, filterOptions = {} }) => {
  const allValues = Object.values(filterValues)
  const allKeys = Object.keys(filterValues)
  const filterTitles = Object.assign({}, ...filters.map(({ key, title }) => ({
    [key]: title
  })))
  return (
    <div className="activeFilters">
      <FilterTwoTone />{' Filter  '}
      {/* Regular tags */}
      {allValues && allValues.map((items = [], i) => (
        customFilters.datetime.indexOf(allKeys[i]) === -1 && customFilters.orderObjectSearch.indexOf(allKeys[i]) === -1 && items && items.map((itemId) => (
          <Tag key={itemId} closable onClose={() => onChange({ filterName: allKeys[i], value: itemId })} color="blue">{filterTitles[allKeys[i]]}: {filterOptions[itemId]}</Tag>
        ))
      ))}
      {/* Date time ranges */}
      {allValues && allValues.map((items = [], i) => (
        customFilters.datetime.indexOf(allKeys[i]) !== -1 && items && items.length ? <Tag key={`dateTimes-tag-${allKeys[i]}`} closable onClose={() => onChange({ filterName: allKeys[i], value: [] })} color="blue">
          Ajavahemik : {formatDatetime(items[0], longDateFormat)} - {formatDatetime(items[1], longDateFormat)}
        </Tag> : null
      ))}
    </div>
  )
}

const FilterMenu = ({ children, right, onChange = () => null, filters: origFilters = [], buttonOrder, style }) => {
  const filters = buttonOrder ? origFilters.slice(0).sort((a, b) => buttonOrder.indexOf(a.key) - buttonOrder.indexOf(b.key)) : origFilters
  const filterValues = Object.assign({}, ...filters.map(({ key, filteredValue }) => ({
    [key]: filteredValue
  })))

  const datetimeFiltersNames = filters.filter(({ filters }) => filters === 'datetime').map(({ key }) => key)
  const equipmentFiltersNames = ['orderedEquipment.equipmentUID', 'orderedEquipment.typeUID']

  const customFilters = {
    datetime: datetimeFiltersNames,
    orderObjectSearch: filters.filter(({ filters }) => filters === 'orderObjectSearch').map(({ key }) => key)
  }
  const hasActiveFilters = filters.some(({ filteredValue }) => filteredValue && filteredValue.length)

  const filterChange = useCallback(({ filterName, value, options }) => {
    let newFilterValues = { ...filterValues }
    if (datetimeFiltersNames.indexOf(filterName) !== -1) {
      newFilterValues[filterName] = value
    } else {
      newFilterValues[filterName] = addOrRemove(newFilterValues[filterName] || [], value)
    }
    if (equipmentFiltersNames.indexOf(filterName) !== -1) {
      if (filterName === equipmentFiltersNames[0]) {
        newFilterValues[equipmentFiltersNames[1]] = []
      } else {
        newFilterValues[equipmentFiltersNames[0]] = []
      }
    }
    if (value === 'all') {
      newFilterValues[filterName] = [...options.map(({ value }) => value)]
    }
    if (value === 'clear') {
      newFilterValues[filterName] = []
    }
    onChange(newFilterValues)
  }, [filterValues, datetimeFiltersNames, equipmentFiltersNames, onChange])

  const filterClear = useCallback(() => {
    onChange({})
  }, [onChange])

  const filterButtons = filters.map(({ key: filterName, title, filters: options, filteredValue }) => {
    const key = `filter-${filterName}`
    const props = { key, onChange: filterChange, filterName, title, options, filteredValue }
    return (
      datetimeFiltersNames.indexOf(filterName) !== -1 ? <FilterDates {...props} /> : <FilterDropdown {...props} />
    )
  })

  const filterOptions = filters.reduce((acc, { filters: options }) => {
    for (const { text, value } of options) {
      acc[value] = text
    }
    return acc
  }, [])

  const filterMenu = (
    <div className="filterMenu" style={style}>
      <Row type="flex">
        <Col span={16}>
          {filterButtons}
        </Col>
        {right && <Col span={6} offset={2}>
          {right}
        </Col>}
      </Row>
    </div>
  )

  const activeFilters = (
    <>
      {hasActiveFilters && <ActiveFilterList filters={filters} filterValues={filterValues} filterOptions={filterOptions} customFilters={customFilters} onChange={filterChange} />}
      {hasActiveFilters && <Button type="dashed" size="small" onClick={() => filterClear()} style={{ marginBottom: 24 }}><CloseOutlined />Eemalda filtrid</Button>}
    </>
  )

  if (children) {
    return children({ filterMenu, activeFilters })
  }

  return (
    <>
      {filterMenu}
      {activeFilters}
    </>
  )
}

export default FilterMenu
