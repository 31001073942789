import { action, thunk } from "easy-peasy"
import API from "../../services/api"

function removeByKey(myObj, deleteKey) {
  return Object.keys(myObj)
    .filter(key => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = myObj[current]
      return result
    }, {})
}

export const offerModel = {
  offer: [],
  activeOfferId: null,

  setOffer: action((state, payload) => state.offer = payload),
  setActiveOfferId: action((state, payload) => state.activeOfferId = payload),

  // apiLoadOffer
  apiLoadOffer: thunk(async (actions, offer_nr) => {
    const offer = await API("/api/offer", false, 'get', { offer_nr: offer_nr })
    actions.setOffer(offer.data)
  }),

  // apiUpdateOffer
  apiUpdateOffer: thunk(async (actions, payload, { getState, getStoreState }) => {
    let state = getState()
    console.log("Updating offer: ", payload)
    console.log("Current offer state: ", state)
    const row = await API("/api/offer", false, 'POST', payload)
    console.log("Retrieved row", row.data)
    actions.setOffer({ ...state.offer, ...row.data })
  }),
  // api AddRow
  apiAddRowOffer: thunk(async (actions, payload, { getState, getStoreState }) => {
    let state = getState()
    const row = await API("/api/offer", false, 'PUT', payload)
    actions.setOffer({ ...state.offer, ...row.data })
  }),

  // api DeleteRow
  apiDelRowOffer: thunk(async (actions, idoffer, { getState, getStoreState }) => {
    let current_state = getState()
    console.log("state", current_state)
    const row = await API("/api/offer", false, 'DELETE', { idoffer: idoffer })
    let new_state = removeByKey(current_state.offer, row.data.deleted.toString())
    console.log('new state', new_state)
    actions.setOffer({ ...new_state })
  }),

}