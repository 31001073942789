import React, { useState, useEffect, useRef, useCallback } from 'react'
import { EditOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { AutoComplete, Select, Button, Input, DatePicker, Divider, Space } from 'antd'
import moment from 'moment'
import { useFormik } from 'formik'
import dateLocale from 'antd/es/date-picker/locale/et_EE'

const { Option } = Select

export const MultiInput = ({ active = false, onClick, defaultValue, onBlur, onUpdate, record, loading = false }) => {
  const inputRef1 = useRef()
  const inputRef2 = useRef()
  const inputRef3 = useRef()

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      added_amount: record['added_amount'] ? record['added_amount'].toFixed(3) : null,
      purchase_price: record['purch_price'] ? parseFloat(record['purch_price']).toFixed(3) : null,
      purchase_price_plain: record['purch_price_plain'] ? parseFloat(record['purch_price_plain']).toFixed(3) : null
    },
    // eslint-disable-next-line
    onSubmit: useCallback(async (values) => {
      //console.log("values", values)

      onBlur(values)
    })
  })

  useEffect(() => {
    if (active) {
      inputRef1.current.focus()
    }
    // eslint-disable-next-line
  }, [active])

  if (active) {
    ///console.log("activecell values", formik.values)
    return (
      <div className="d-flex align-items-end">
        <div style={{ textAlign: 'left' }} className="mr-2">
          <div>Kogus</div>
          <div>
            <input
              ref={inputRef1}
              style={{
                width: '85px'
                //border: "none",
                //padding: "-15px",
                //marginTop: "-10px"
              }}
              id="added_amount"
              name="added_amount"
              disabled={loading}
              value={formik.values.added_amount}
              onFocus={(e) => e.target.select()}
              placeholder="Kogus"
              //onBlur={(event) => onBlur(value)}
              onBlur={(event) => inputRef2.current.focus()}
              onChange={formik.handleChange}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  inputRef2.current.focus()
                }
              }}
            />
          </div>
        </div>
        <div style={{ textAlign: 'left' }} className="mr-2">
          <div>Ühikhind transpordiga</div>
          <div>
            <Input
              ref={inputRef2}
              style={{
                width: '85px'
                //border: "none",
                //padding: "-15px",
                //marginTop: "-10px"
              }}
              id="purchase_price"
              name="purchase_price"
              disabled={loading}
              value={formik.values.purchase_price}
              onFocus={(e) => e.target.select()}
              placeholder="Hind"
              onBlur={(event) => inputRef3.current.focus()}
              onChange={formik.handleChange}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  formik.submitForm()
                }
              }}
            />
          </div>
        </div>
        <div style={{ textAlign: 'left' }}>
          <div>Ühikhind</div>
          <div>
            <Input
              ref={inputRef3}
              style={{
                width: '85px'
                //border: "none",
                //padding: "-15px",
                //marginTop: "-10px"
              }}
              id="purchase_price_plain"
              name="purchase_price_plain"
              disabled={loading}
              value={formik.values.purchase_price_plain}
              onFocus={(e) => e.target.select()}
              placeholder="Hind"
              onBlur={(event) => formik.submitForm()}
              onChange={formik.handleChange}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  formik.submitForm()
                }
              }}
            />
          </div>
        </div>

        <div style={{ textAlign: 'left' }}>
          <div className="pl-2"> Kinnita</div>
          <div>
            <Button className="ml-2" disabled={loading} type="primary" onClick={() => formik.submitForm()}>
              {loading ? <LoadingOutlined /> : 'OK'}
            </Button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div onClick={onClick} style={{ display: 'inline' }}>
        <small className="text-muted">
          <EditOutlined />
        </small>
        {defaultValue}
      </div>
    )
  }
}

const InputCell = ({
  defaultValue,
  onClick,
  onBlur,
  onMoveUp,
  onMoveDown,
  onUpdate,
  active = false,
  type = 'input',
  autocompleteProps = {}, // redundant
  selectProps = {},
  selectOptions = [],
  defaultTitle = false,
  valid = true,
  loading = false,
  disabled = false,
  onMoveLeft = () => null,
  onMoveRight = () => null
}) => {
  let value = defaultValue
  const inputRef = useRef()

  const [isValid, setIsValid] = useState(true)

  const [autoCompleteSearch, setAutoCompleteSearch] = useState([])
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('')

  useEffect(() => {
    if (active && type !== 'select' && type !== 'goodSelect') {
      inputRef.current.focus()
    }
    // eslint-disable-next-line
  }, [active])

  const ifIsTime = (event) => {
    // return true if empty value
    if (event.target.value.length === 0) {
      setIsValid(true)
      return true
    }

    // return true if value is valid time HH:mm or H:mm
    let isTime = moment(event.target.value.toString(), ['HH:mm', 'H:mm'], true).isValid()
    isTime ? setIsValid(true) : setIsValid(false)
    //console.log(moment(event.target.value.toString(), "HH:mm").format("DD-MM-YYYY HH:mm"))
    return isTime
  }

  if (active) {
    //if (disabled) return <Icon type="loading" />

    if (type === 'input') {
      if (loading)
        return (
          <div ref={inputRef}>
            <LoadingOutlined />
          </div>
        )
      return (
        <Input
          ref={inputRef}
          defaultValue={defaultValue}
          disabled={loading}
          onFocus={(e) => e.target.select()}
          placeholder=""
          onBlur={(event) => onBlur(value)}
          onChange={(event) => (value = event.target.value)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              if (onUpdate(value)) {
                onMoveRight()
              }
            }
            if (event.keyCode === 9) {
              event.preventDefault()
              if (event.shiftKey) onMoveLeft()
              if (!event.shiftKey) onMoveRight()
            }
          }}
          onKeyDown={(event) => {
            switch (event.keyCode) {
              case 27:
                onUpdate(defaultValue)
                break // escape
              case 38:
                onUpdate(value)
                onMoveUp(-1)
                break // arrow up
              case 40:
                onUpdate(value)
                console.log('down')
                onMoveDown(1)
                break // arrow down
              case 9: {
                event.preventDefault()
                onUpdate(value)
                if (event.shiftKey) onMoveLeft()
                if (!event.shiftKey) onMoveRight()
                break
              }
            }
          }}
        />
      )
    }
    if (type === 'timeinput') {
      return (
        <Input
          ref={inputRef}
          className="ant-select-focused"
          defaultValue={defaultValue}
          placeholder="hh:mm"
          onBlur={(event) => (isValid ? onBlur(event.target.value) : onBlur(defaultValue))}
          onChange={(event) => {
            value = event.target.value
            console.log(event.target.value)
            ifIsTime(event)
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              if (isValid) {
                onUpdate(event.target.value)
                onMoveRight()
              }
            }
          }}
          onKeyDown={(event) => {
            switch (event.keyCode) {
              case 27:
                onUpdate(defaultValue)
                break // escape
              case 38:
                if (isValid) onUpdate(event.target.value)
                onMoveUp(-1)
                break // arrow up
              case 40:
                if (isValid) onUpdate(event.target.value)
                onMoveDown(1)
                break // arrow down
              case 9: {
                event.preventDefault()
                if (isValid) onUpdate(event.target.value)
                if (event.shiftKey) onMoveLeft()
                if (!event.shiftKey) onMoveRight()
                break // Tab and Shift-Tab
              }
            }
          }}
        />
      )
    }
    if (type === 'datetime') {
      return (
        <DatePicker
          locale={dateLocale}
          ref={inputRef}
          className="ant-select-focused"
          format={'DD.MM.YYYY'}
          //value={moment(dateValue, 'DD.MM.YYYY')}
          defaultValue={defaultValue ? moment(defaultValue, 'DD.MM.YYYY') : null}
          onChange={(date, dateString) => {
            if (date) {
              console.log(date, dateString)
              onUpdate(moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD'))
              onMoveRight()
            }
          }}
          onBlur={onBlur}
        />
      )
    }
    if (type === 'select') {
      //console.log("Options_list: ", selectProps.optionsList)

      // this deserves explanation:
      // Select in sinle mode does not receive focus when selected. A bug.
      // This resulted in Tab moving order not working on Select nodes
      // A workaround is to have Select working in Multiple mode
      // And tweaking the look and feel by not showing Tags and empty tag Placeholder set to search icon

      return (
        <Select
          //ref={inputRef}
          showAction={['focus', 'click']}
          mode="multiple"
          maxTagCount={0}
          removeIcon={<div></div>}
          maxTagPlaceholder={<SearchOutlined />}
          //showAction='focus'
          autoFocus={true}
          defaultOpen={true}
          dropdownMatchSelectWidth={false}
          showSearch={true}
          style={{ minWidth: 70 }}
          placeholder="Vali.."
          optionFilterProp="children"
          onFocus={(e) => console.log('focus got')}
          //onChange={(value) => onUpdate(value)}
          onSelect={(value) => {
            onUpdate(value)
            onMoveRight()
          }}
          //onFocus={onFocus}
          onBlur={(value) => onUpdate(value)}
          //value={defaultTitle ? defaultTitle : defaultValue}
          value={defaultValue}
          onSearch={(v) => console.log(v)}
          //filterOption={(input, option) =>
          //    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          //}
          onInputKeyDown={(event) => {
            switch (event.keyCode) {
              case 9: {
                event.preventDefault()
                console.log('select tab')
                if (event.shiftKey) onMoveLeft()
                if (!event.shiftKey) onMoveRight()
                break // Tab and Shift-Tab
              }
            }
          }}>
          {selectProps?.optionsList?.map((a, index) => {
            return (
              <Option key={`${a[selectProps.valueField]}-${index}`} value={a[selectProps.valueField]}>
                {`${a[selectProps.titleField]} ${a?.warehouse_id ? `(${a?.amount} ${a?.res_unit} / ${a?.unit_price})` : ''}`}
              </Option>
            )
          })}
        </Select>
      )
    }
    if (type === 'goodSelect') {
      if (selectProps?.withAdding) {
        const addRoad = selectProps?.addFunc
        return (
          <Select
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Select
                  onSelect={async (value, option) => {
                    console.log(option)
                    const { data } = await addRoad({
                      idobject: selectProps?.objectId,
                      iduser: selectProps?.userId,
                      name: option?.children,
                      road_number: option?.value,
                      date: selectProps?.objectActiveDate.format("YYYY-MM-DD 00:00:00")
                    })
                    console.log('added new', data)
                    onUpdate(data.idroad)
                    onMoveRight()
                  }}
                  placeholder="Vali siit eelarve rida"
                  style={{ width: '100%', paddingLeft: 8, paddingRight: 8, paddingBottom: 4 }}>
                  {selectProps?.addingItems.map((item, index) => (
                    <Option key={`${item.label}-${index}`} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </>
            )}
            showAction={['focus', 'click']}
            mode="multiple"
            maxTagCount={0}
            removeIcon={<div></div>}
            maxTagPlaceholder={<SearchOutlined />}
            autoFocus={true}
            defaultOpen={true}
            dropdownMatchSelectWidth={false}
            style={{ minWidth: 70 }}
            placeholder="Vali.."
            options={selectOptions}
            onSelect={(value, option) => {
              onUpdate(value)
              onMoveRight()
            }}
            //onBlur={(value) => onUpdate(value)}
            value={defaultValue}
            onInputKeyDown={(event) => {
              switch (event.keyCode) {
                case 9: {
                  event.preventDefault()
                  console.log('select tab')
                  if (event.shiftKey) onMoveLeft()
                  if (!event.shiftKey) onMoveRight()
                  break // Tab and Shift-Tab
                }
              }
            }}
          />
        )
      } else {
        return (
          <Select
            showAction={['focus', 'click']}
            mode="multiple"
            maxTagCount={0}
            removeIcon={<div></div>}
            maxTagPlaceholder={<SearchOutlined />}
            autoFocus={true}
            defaultOpen={true}
            dropdownMatchSelectWidth={false}
            style={{ minWidth: 70 }}
            placeholder="Vali.."
            options={selectOptions}
            onSelect={(value, option) => {
              onUpdate(value)
              onMoveRight()
            }}
            onBlur={(value) => onUpdate(value)}
            value={defaultValue}
            onInputKeyDown={(event) => {
              switch (event.keyCode) {
                case 9: {
                  event.preventDefault()
                  console.log('select tab')
                  if (event.shiftKey) onMoveLeft()
                  if (!event.shiftKey) onMoveRight()
                  break // Tab and Shift-Tab
                }
              }
            }}
          />
        )
      }
    }
    if (type === 'goodautocomplete') {
      const optionsList = selectProps && selectProps.optionsList && selectProps.optionsList.length > 0 ? selectProps.optionsList : []
      return (
        <AutoComplete
          ref={inputRef}
          dataSource={optionsList.map((option, index) => (
            <Option key={`${index}-${option}`} value={option}>
              {option}
            </Option>
          ))}
          style={{
            width: '100%',
            border: 'none',
            padding: '-15px'
            //marginTop: "-10px"
          }}
          defaultValue={defaultValue}
          onBlur={(e) => {
            onUpdate(e.target.value)
          }}
          onSelect={(value) => {
            console.log('selectvalue', value)
            onUpdate(value)
            onMoveRight()
          }}
          filterOption={(inputValue, option) => option?.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1}>
          <Input
            onKeyDown={(event) => {
              switch (event.keyCode) {
                case 27: {
                  onUpdate(defaultValue)
                  break // escape
                }
                case 13: {
                  onUpdate(autoCompleteInputValue)
                  onMoveRight()
                }
                case 9: {
                  event.preventDefault()
                  onUpdate(value)
                  if (event.shiftKey) onMoveLeft()
                  if (!event.shiftKey) onMoveRight()
                  break // Tab and Shift-Tab
                }
              }
            }}
          />
        </AutoComplete>
      )
    }
    if (type === 'autocomplete') {
      const optionsList = selectProps && selectProps.optionsList && selectProps.optionsList.length > 0 ? selectProps.optionsList : []
      const onSearch = (value) => setAutoCompleteSearch(value ? [value] : [])
      return (
        <AutoComplete
          ref={inputRef}
          style={{
            width: '100%',
            border: 'none',
            padding: '-15px'
            //marginTop: "-10px"
          }}
          defaultValue={defaultValue}
          options={[...autoCompleteSearch, ...optionsList].map((option) => ({ value: option, label: option }))}
          onBlur={(e) => {
            onUpdate(e.target.value)
          }}
          onSelect={(value) => {
            console.log('selectvalue', value)
            onUpdate(value)
            onMoveRight()
          }}
          onChange={(value) => setAutoCompleteInputValue(value)}
          onSearch={onSearch}
          filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
          <Input
            onKeyDown={(event) => {
              switch (event.keyCode) {
                case 27: {
                  onUpdate(defaultValue)
                  break // escape
                }
                case 13: {
                  onUpdate(autoCompleteInputValue)
                  onMoveRight()
                }
                case 9: {
                  event.preventDefault()

                  onUpdate(value)
                  if (event.shiftKey) onMoveLeft()
                  if (!event.shiftKey) onMoveRight()
                  break // Tab and Shift-Tab
                }
              }
            }}
          />
        </AutoComplete>
      )
    }
  }

  if (defaultValue || defaultValue === 0 || defaultTitle) {
    if (defaultTitle && defaultTitle.length > 2)
      return (
        <div onClick={onClick} style={{ display: 'inline' }}>
          <small className="text-muted">
            <EditOutlined />
          </small>
          {defaultTitle}
        </div>
      )
    if (!defaultTitle && defaultValue && defaultValue.length > 2)
      return (
        <div onClick={onClick} style={{ display: 'inline' }}>
          <small className="text-muted">
            <EditOutlined />
          </small>
          {defaultValue}
        </div>
      )
    else
      return (
        <div onClick={onClick} style={{ display: 'inline' }}>
          <small className="text-muted">
            <EditOutlined />
          </small>
          {defaultTitle ? defaultTitle : defaultValue}
        </div>
      )
  } else {
    return (
      <div onClick={onClick} style={{ display: 'inline' }}>
        <small className="text-muted">
          <EditOutlined />
        </small>
      </div>
    )
  }
}

export default InputCell
