import {action, thunk} from "easy-peasy";
import API from "../../services/api";

export const budgetsModel = {

    budgets: [],
    loading: true,

    setLoading: action((state, payload) => state.loading = payload),
    setBudgets: action((state, payload) => state.budgets = payload),

    apiFetchBudgets: thunk(async (actions) => {
        const res = await API("/api/budgets")
        actions.setBudgets(res.data)
    }),

    apiDeleteBudgets: thunk(async (actions, payload) => {
       await API("/api/budgets", false, "delete", payload)
    }),

}

