import React, {useCallback, useContext, useEffect, useState} from "react";
import PageWithHeader from '../components/PageWithHeader'
import {useFormik} from "formik";

import {
    CheckCircleOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    FileExcelOutlined,
    FullscreenOutlined,
    InfoCircleOutlined,
    MinusCircleOutlined,
} from '@ant-design/icons';

import {
    Table,
    Card,
    Tag,
    Modal,
    Tabs,
    Button,
    DatePicker,
    Select,
    Input,
    Badge,
    Radio,
    Tooltip,
    Affix,
    Popover,
    Alert,
} from "antd";
import '../App.css';

import moment from 'moment';
import {useStoreState, useStoreActions} from "easy-peasy";
import {smartSearch} from "../services/smartSearch";
import PaRangeSelect from "../components/RangeSelect"
import CostOptionsContext from "../components/CostOptionsContext";
import StatusIcon from "../components/object/StatusIcon"
import {URL} from "../services/api"


const {Option} = Select
const {CheckableTag} = Tag;
const {Search} = Input;
const {RangePicker} = DatePicker;

const InputGroup = Input.Group;

const PaActions = () => {

    return (
        <PageWithHeader>
            <ControlMenu/>
            <div>sss</div>
        </PageWithHeader>
    )
}

const PaControlContent = () => {


    return (
        <div>

            <div className="m-2 p-2">
                <ControlTable/>
            </div>
        </div>
    )
}

const ControlMenu = () => {

    const objects = useStoreState(state => state.objects.objects)
    const jwt = useStoreState(state => state.user.jwt)
    //const [selectedObjects, setSelectedObjects] = useState([0])

    const selectedObjects = useStoreState(state => state.control.selectedObjects)
    const setSelectedObjects = useStoreActions(actions => actions.control.setSelectedObjects)

    const selectedDates = useStoreState(state => state.control.selectedDates)
    const setSelectedDates = useStoreActions(actions => actions.control.setSelectedDates)

    const fetchObjects = useStoreActions(actions => actions.objects.apiLoadObjects)
    const postMe = useStoreActions(actions => actions.user.apiPostMe)
    const me = useStoreState(state => state.user.me)

    const isClearToLoad = useStoreState(state => state.control.isClearToLoad)
    const isFailedToLoad = useStoreState(state => state.control.isFailedToLoad)
    const filterTabs = useStoreState(state => state.control.filterTabs)
    const setFilterTabs = useStoreActions(actions => actions.control.setFilterTabs)

    const fetchControlCosts = useStoreActions(actions => actions.control.apiFetchCosts)
    const fetchControlProductionWh = useStoreActions(actions => actions.control.apiFetchProductionWh)

    const setLoading = useStoreActions(actions => actions.control.setIsConstructionLoading)
    const setLoadingProduction = useStoreActions(actions => actions.control.setIsProductionLoading)

    const changeDisabled = useStoreState(state => state.control.changeDisabled)

    //const [selectedDates, setSelectedDates] = useState([moment().subtract(2, "months"), moment()])
    const [isModalOpen, setIsModalOpen] = useState(false)


    const [showWarning, setShowWarning] = useState(false)

    useEffect(() => {
        if (selectedObjects.includes(0) && moment.duration(selectedDates[1].diff(selectedDates[0])).asDays() > 95)
            setShowWarning(true)
        else setShowWarning(false)
    }, [selectedDates, selectedObjects])


    const objectsSelect = [];
    objectsSelect.push(
        <Option key={0}
                value={0}
                label={'Kõik'}>
            <div>Kõik</div>
        </Option>);
    objects.forEach((object) => {

        objectsSelect.push(
            <Option key={object.idobject}
                    value={object.idobject}
                    label={object.shiffer}>
                {object.shiffer} {object.name}
            </Option>);
    })

    const filters = [
        {val: 4, title: "Uued"},
        {val: 3, title: "Uuendatud"},
        {val: 5, title: "Kinnitatud"},
        {val: 7, title: "Probleemsed"},
    ]


    function saveFav(target, val) {

        let tempMe = Object.assign({}, me)

        if (!tempMe.prefs.control) {
            tempMe.prefs = {
                ...tempMe.prefs,
                control: {
                    selectedDates: [moment().subtract(2, "months"), moment()],
                    selectedObjects: [0],
                    filterTabs: [4, 3, 5, 7]
                }
            }
        }

        tempMe.prefs.control[target] = val
        postMe(tempMe)
    }


    useEffect(() => {
        fetchObjects()
        fetchData()
    }, [isClearToLoad])


    async function handleCheck(checked, val) {
        let current = filterTabs
        await checked ? current = [val, ...current] : current = filterTabs.filter(v => v !== val)
        await setFilterTabs(current)
        saveFav('filterTabs', current)
    }

    async function handleObjectsChange(e) {
        await setSelectedObjects(e)
        saveFav('selectedObjects', e)

    }

    async function handleDateChange(e) {
        await setSelectedDates(e)
        saveFav('selectedDates', e)

    }

    async function handleClickToFetch(excel = false) {
        setLoading(true)
        setLoadingProduction(true)
        await fetchData(excel = false)
        setShowWarning(false)
    }

    async function fetchData(excel = false) {

        let payload = {
            start_date: selectedDates[0].format('YYYY-MM-DD 00:00:00'),
            end_date: selectedDates[1].format('YYYY-MM-DD 23:59:59'),
            objects: selectedObjects
        }

        if (excel) {

            let request = {
                headers: {
                    'Authorization': "Bearer " + jwt,
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            }

            // this works, but no way of passing headers
            //window.location.href = URL() + '/api/control/excel?' + new URLSearchParams(payload).toString()
            //console.log(await URL() + '/api/control/excel?' + new URLSearchParams(payload).toString())
            window.location.href = URL() + '/api/control/excel?' + new URLSearchParams(payload).toString()


        } else {

            await fetchControlCosts(payload)
            setLoading(false)
            await fetchControlProductionWh(payload)
            setLoadingProduction(false)
        }

    }


    const [affixed, setAffixed] = useState(false)

    return (
        <div>

            <div>
                <PaControlChange
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}

                />
            </div>
            <Affix offsetTop={1} onChange={affix => setAffixed(affix)}>
                <div className="pl-3 ml-2 pt-1 pr-4" style={{background: 'rgb(240, 242, 245)'}}>
                    <div className="d-flex mb-1 mt-2">
                        <div className="mr-2">
                            <div className="ml-1">
                                <b> Ajavahemik:</b>
                            </div>
                            <InputGroup compact>
                                <RangePicker
                                    value={selectedDates}
                                    style={{width: '250px'}}
                                    format='DD.MM.YY'
                                    onChange={handleDateChange}
                                />
                                <PaRangeSelect onSelect={handleDateChange}/>
                            </InputGroup>
                        </div>
                        <div>
                            <div className="ml-1">
                                <b>Objektid:</b>
                            </div>
                            <Select
                                mode="multiple"
                                optionLabelProp="label"
                                showSearch
                                optionFilterProp="children"
                                style={{width: '300px'}}
                                placeholder="Vali objekt(id) või kõik objektid"
                                dropdownMatchSelectWidth={false}
                                value={selectedObjects}
                                onChange={handleObjectsChange}
                                className="mr-2"
                            >
                                {objectsSelect}
                            </Select>
                        </div>


                    </div>
                    <div className="d-flex">
                        <div>
                            <Popover placement="bottomLeft"
                                //title="Kaua kestev päring"
                                     content={showWarning || isFailedToLoad ?
                                         isFailedToLoad ?
                                             <Alert
                                                 message="Päring ebaõnnestus"
                                                 description={<div>Kitsenda kuupäevi või objektide valikut.</div>}
                                                 type="error"
                                                 showIcon
                                             />
                                             : <Alert
                                                 message="Kaua kestev päring"
                                                 description={<div>Küsid <b>kõikide</b> objektide info pika ajaperioodi
                                                     kohta. <br/> See päring saad kesta kuni 30 sekundit.</div>}
                                                 type="warning"
                                                 showIcon
                                             />
                                         :
                                         null}
                                     visible={showWarning || isFailedToLoad}
                                     trigger="none">
                                <Button onClick={() => handleClickToFetch(false)}

                                        style={{background: '#2f54eb', color: 'white'}}
                                        className="mr-3">Leia...
                                </Button>
                            </Popover>
                            <Button className="mr-1" disabled={changeDisabled}
                                    onClick={() => setIsModalOpen(true)}><EditOutlined /> Muuda </Button>
                            <Button className="mr-1" onClick={() => fetchData(true)}><FileExcelOutlined /> Excelisse
                            </Button>
                        </div>

                        <div className="ml-2 mt-1"> Filter: {filters.map(f =>
                            <CheckableTag key={f.val} checked={filterTabs ? filterTabs.includes(f.val) : true}
                                          onChange={checked => handleCheck(checked, f.val)}>{f.title}</CheckableTag>
                        )}
                        </div>


                    </div>
                </div>
            </Affix>
        </div>
    );

}

const PaControlChange = ({isOpen, onClose}) => {

    const {formOptions, setFormOptions} = useContext(CostOptionsContext)
    const selected = useStoreState(state => state.control.selected)
    const selectedProduction = useStoreState(state => state.control.selectedProduction)
    const costs = useStoreState(state => state.control.costs)
    const productionWh = useStoreState(state => state.control.productionWh)
    const updateCosts = useStoreActions(actions => actions.control.apiUpdateCosts)
    const updateProductionWh = useStoreActions(actions => actions.control.apiUpdateProductionWh)

    const activeTab = useStoreState(state => state.control.activeTab)

    const emptyModalData = {
        idcosts: null,
        company: null,
        amount_description: null,
        amount: null,
        amount2: null,
        unit_price: null,
        status: null
    }

    const emptyModalDataProduction = {
        id: null,
        amount: null,
        unit_price: null,
        wh_status: null
    }

    const [modalData, setModalData] = useState(emptyModalData)
    const [modalDataProduction, setModalDataProduction] = useState(emptyModalDataProduction)

    useEffect(() => {
        console.log("setting select")
        if (selected.length === 1) {
            console.log("one", costs.filter(r => r.idcosts === selected[0])[0])
            setModalData(costs.filter(r => r.idcosts === selected[0])[0])
        } else {
            console.log('many')
            setModalData(emptyModalData)
        }

        if (selectedProduction.length === 1) {
            console.log("one", productionWh.filter(r => r.id === selectedProduction[0])[0])
            setModalDataProduction(productionWh.filter(r => r.id === selectedProduction[0])[0])
        } else {
            console.log('many')
            setModalDataProduction(emptyModalDataProduction)
        }


    }, [selected, selectedProduction])


    let formik = useFormik({
        enableReinitialize: true,
        initialValues: modalData,
        onSubmit: useCallback(async (values) => {
            console.log("values", values)

            updateCosts({
                values: values,
                costs: selected
            })
        })
    });

    let formikProduction = useFormik({
        enableReinitialize: true,
        initialValues: modalDataProduction,
        onSubmit: useCallback(async (values) => {
            console.log("values", values)

            updateProductionWh({
                values: values,
                records: selectedProduction
            })
        })
    });

    return (
        activeTab === 'ehitus' ?
            <Modal
                title="Muuda"
                visible={isOpen}
                onOk={
                    () => {
                        formik.submitForm()
                        onClose()
                    }
                }

                //confirmLoading={confirmLoading}
                //style={{width: '80%'}}
                //width='80%'
                onCancel={onClose}
            >
                <Card
                    style={{width: '100%'}}
                    //title={<div className="m-2"><h4>Tehase LIVE</h4></div>}
                    //tabList={tabList}
                    //activeTabKey={activeTabKey}
                    //tabBarExtraContent={}
                    className="mt-2"
                    bordered={false}
                    //onTabChange={key => setActiveTabKey(key)}
                >

                    <form onSubmit={formik.handleSubmit} className="container">
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="inputPosition">Ettevõte:</label>
                            </div>
                            <div className="col-md-8 mb-3">

                                <Select id="inputPosition"
                                        showSearch
                                        name="position"
                                        value={formik.values.company}
                                        onSelect={v => formik.setFieldValue('company', v)}
                                        optionFilterProp="children"
                                        required>
                                    {'companies_list' in formOptions ?
                                        formOptions.companies_list.map(v => <Option key={v.company}
                                                                                    value={v.company}>{v.org_name}</Option>)
                                        :
                                        <Option value={0}>Loading...</Option>
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="inputPosition">Koguse valem:</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <Input
                                    id="amount_description"
                                    name="amount_description"
                                    onChange={formik.handleChange}
                                    value={formik.values.amount_description}
                                    placeholder="Valem või number"
                                    required/>
                                <div id="invfeedback1" className="invalid-feedback">
                                    Viga
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="inputPosition">Kogus:</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <Input
                                    id="amount"
                                    name="amount"
                                    onChange={formik.handleChange}
                                    value={formik.values.amount}
                                    placeholder="Valem või number"
                                    required/>
                                <div id="invfeedback1" className="invalid-feedback">
                                    Viga
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="inputPosition">km t*km korral:</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <Input
                                    id="amount2"
                                    name="amount2"
                                    onChange={formik.handleChange}
                                    value={formik.values.amount2}
                                    placeholder="Valem või number"
                                    required/>
                                <div id="invfeedback1" className="invalid-feedback">
                                    Viga
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="inputPosition">Ühiku hind:</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <Input
                                    id="unit_price"
                                    name="unit_price"
                                    onChange={formik.handleChange}
                                    value={formik.values.unit_price}
                                    placeholder="Valem või number"
                                    required/>
                                <div id="invfeedback1" className="invalid-feedback">
                                    Viga
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="inputPosition">Tegevus:</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <Radio.Group value={formik.values.status} buttonStyle="solid"
                                             onChange={v => formik.setFieldValue('status', v.target.value)}>
                                    <Radio.Button value={5}> OK </Radio.Button>
                                    <Radio.Button value={7}> Probleemne </Radio.Button>
                                    <Radio.Button value={4}> Uus </Radio.Button>
                                </Radio.Group>
                                <div id="invfeedback1" className="invalid-feedback">
                                    Viga
                                </div>
                            </div>

                        </div>
                    </form>

                </Card>
            </Modal>
            :
            <Modal
                title="Muuda"
                visible={isOpen}
                onOk={
                    () => {
                        formikProduction.submitForm()
                        onClose()
                    }
                }
                onCancel={onClose}
            >
                <Card
                    style={{width: '100%'}}
                    className="mt-2"
                    bordered={false}
                >

                    <form onSubmit={formikProduction.handleSubmit} className="container">

                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="inputPosition">Kogus:</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <Input
                                    id="amount"
                                    name="amount"
                                    onChange={formikProduction.handleChange}
                                    value={formikProduction.values.amount}
                                    placeholder="Kogus"
                                    required/>
                                <div id="invfeedback1" className="invalid-feedback">
                                    Viga
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="inputPosition">Ühiku hind:</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <Input
                                    id="unit_price"
                                    name="unit_price"
                                    onChange={formikProduction.handleChange}
                                    value={formikProduction.values.unit_price}
                                    placeholder="Hind"
                                    required/>
                                <div id="invfeedback1" className="invalid-feedback">
                                    Viga
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="inputPosition">Tegevus:</label>
                            </div>
                            <div className="col-md-8 mb-3">
                                <Radio.Group value={formikProduction.values.wh_status} buttonStyle="solid"
                                             onChange={v => formikProduction.setFieldValue('wh_status', v.target.value)}>
                                    <Radio.Button value={5}> OK </Radio.Button>
                                    <Radio.Button value={7}> Probleemne </Radio.Button>
                                    <Radio.Button value={4}> Uus </Radio.Button>
                                </Radio.Group>
                                <div id="invfeedback1" className="invalid-feedback">
                                    Viga
                                </div>
                            </div>

                        </div>
                    </form>

                </Card>

            </Modal>

    )
}

const ButtonGroup = Button.Group;

const Btns = ({record}) => {

    const updateCosts = useStoreActions(actions => actions.control.apiUpdateCosts)

    const handleConfirmReject = (idcosts, status) => {
        //console.log("confirmreject", idcosts)
        updateCosts({
            values: {
                status: status
            },
            costs: [idcosts,]
        })
    }

    return (
        <ButtonGroup>
            <Button onClick={() => handleConfirmReject(record.idcosts, 5)} type="primary" icon={<CheckCircleOutlined />}/>
            <Button onClick={() => handleConfirmReject(record.idcosts, 7)} type="danger" icon={<ExclamationCircleOutlined />}/>
        </ButtonGroup>
    );
}

const BtnsProduction = ({record}) => {

    const updateProductionWh = useStoreActions(actions => actions.control.apiUpdateProductionWh)
    //
    const handleConfirmReject = (id, status) => {
        console.log("production cornfirm/reject", id)
        updateProductionWh({
            values: {
                wh_status: status
            },
            records: [id,]
        })
    }

    return (
        <ButtonGroup>
            <Button onClick={() => handleConfirmReject(record.id, 5)} type="primary" icon={<CheckCircleOutlined />}/>
            <Button onClick={() => handleConfirmReject(record.id, 7)} type="danger" icon={<ExclamationCircleOutlined />}/>
        </ButtonGroup>
    );
}

const ControlTable = () => {

    const setFullscreen = useStoreActions(actions => actions.layout.setFullScreen)
    const fullScreenComponent = useStoreState(state => state.layout.fullScreenComponent)

    const costs = useStoreState(state => state.control.costs)
    const productionWh = useStoreState(state => state.control.productionWh)

    const selected = useStoreState(state => state.control.selected)
    const setSelected = useStoreActions(actions => actions.control.setSelected)

    const selectedProduction = useStoreState(state => state.control.selectedProduction)
    const setSelectedProduction = useStoreActions(actions => actions.control.setSelectedProduction)

    const filterTabs = useStoreState(state => state.control.filterTabs)

    const activeTab = useStoreState(state => state.control.activeTab)
    const setActiveTab = useStoreActions(actions => actions.control.setActiveTab)

    const isLoading = useStoreState(state => state.control.isConstructionLoading)
    const isLoadingProduction = useStoreState(state => state.control.isProductionLoading)

    const [search, setSearch] = useState(window.localStorage.getItem('controlSearch'))
    //const [search, setSearch] = useState('')

    const handleSelectClick = (val) => {
        let current = selected
        current.includes(val) ? current = current.filter(v => v !== val) : current = [val, ...current]
        setSelected(current)
    }

    const handleSelectClickProduction = (val) => {
        let current = selectedProduction
        current.includes(val) ? current = current.filter(v => v !== val) : current = [val, ...current]
        setSelectedProduction(current)
    }


    function handleSearch(val) {
            setSearch(val)
            window.localStorage.setItem('controlSearch', val)
    }

    const selectAll = () => {

        let mySelected = []

        if (activeTab === "ehitus") {
            filteredCosts.forEach(r => mySelected.push(r.idcosts))
            setSelected(mySelected)
        } else {
            filteredProductionWh.forEach(r => mySelected.push(r.id))
            setSelectedProduction(mySelected)
        }

    }

    const deSelectAll = (dataSource) => {
        if (activeTab === "ehitus") {
            setSelected([])
        } else {
            setSelectedProduction([])
        }
    }

    const searchColumns = ["idcosts", "cost_date_string", "cost_type", "cost_description", "resource", "ident1", "ident2", "amount", "unit", "unit_price", "total", "obj_name", "shiffer", "org_name", "obj_m"]
    const searchColumnsProduction = ["date", "name", "res_property_ident2", "res_name1", 'res_name2', 'org_name', "amount", "res_unit", "unit_price", "row_sum"]


    const productionColumns = [
        //{title: "id", dataIndex: "id", key: "id",},
        {title: "Objekt", dataIndex: "name", key: "name", sorter: (a, b) => a.name.localeCompare(b.name)},
        {
            title: "Kuupäev",
            dataIndex: "date",
            key: "date",
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
            render: (text) => moment(text).format("DD.MM.YY"),
        },
        {
            title: "Kood",
            dataIndex: "res_property_ident2",
            key: "res_property_ident2",
            sorter: (a, b) => a.res_property_ident2.localeCompare(b.res_property_ident2)
        },
        {
            title: "Toode",
            dataIndex: "res_name1",
            key: "res_name1",
            sorter: (a, b) => a.res_name1.localeCompare(b.res_name1)
        },
        {
            title: "Grupp",
            dataIndex: "res_name2",
            key: "res_name2",
            sorter: (a, b) => a.res_name2.localeCompare(b.res_name2)
        },
        {title: "Tehas", dataIndex: "res_property_factory", key: "res_property_factory",},
        {
            title: "Tarnija",
            dataIndex: "org_name",
            key: "org_name",
            sorter: (a, b) => a.org_name.localeCompare(b.org_name)
        },
        {title: "Kogus", dataIndex: "amount", key: "amount", sorter: (a, b) => a.amount - b.amount},
        {title: "Ühik", dataIndex: "res_unit", key: "res_unit", sorter: (a, b) => a.unit - b.unit},
        {
            title: "Ühikhind transpordiga",
            dataIndex: "unit_price",
            key: "unit_price",
            sorter: (a, b) => a.unit_price - b.unit_price
        },
        {
            title: "Ühikhind ",
            dataIndex: "unit_price_plain",
            key: "unit_price_plain",
            sorter: (a, b) => a.unit_price_plain - b.unit_price_plain
        },
        {
            title: "Summa transpordiga", dataIndex: "row_sum", key: "row_sum", sorter: (a, b) => a.row_sum - b.row_sum,
            render: (text, record, index) => text ? "€ " + text.toFixed(2).toString() : null
        },
        {
            title: "Summa",
            dataIndex: "row_sum_plain",
            key: "row_sum_plain",
            sorter: (a, b) => a.row_sum_plain - b.row_sum_plain,
            render: (text, record, index) => text ? "€ " + text.toFixed(2).toString() : null
        },
        {
            title: <InfoCircleOutlined />, dataIndex: "status", key: "status", align: "right", width: 40,
            render: (text, record, index) => <Tooltip title={record.muutja}> <StatusIcon
                value={record.wh_status}/></Tooltip>

        },
        {
            title: "", dataIndex: null, key: "btn", width: '100px',
            render: (text, record, index) => <BtnsProduction record={record}/>
        },

    ]

    const columns = [
        //{title: "Kulu ID", dataIndex: "idcosts", key: "idcosts",},
        {
            title: 'Kuupäev', dataIndex: "cost_date", key: "cost_date",
            render: (text, record, index) => moment(text).format("DD.MM.YY"),
            sorter: (a, b) => moment(a.cost_date).unix() - moment(b.cost_date).unix(),
            onHeaderCell: () => {
                return {style: {position: 'sticky', top: 50}}
            },
            //width: 100,
            //fixed: 'left'

        },
        {
            title: "Shiffer", dataIndex: "shiffer", key: "shiffer",
            sorter: (a, b) => a.shiffer.localeCompare(b.shiffer),
            //width: 100,
            //fixed: "left"
        },
        {
            title: "Objekt", dataIndex: "obj_name", key: "obj_name",
            sorter: (a, b) => a.obj_name.localeCompare(b.obj_name),
            ellipsis: true,
            //width: 180,
        },
        {
            title: "Obj.juht", dataIndex: "obj_m", key: "obj_m",
            sorter: (a, b) => a.obj_m - b.obj_m,
            ellipsis: true,
            //width: 150,
        },
        {
            title: "Kulukoht", dataIndex: "cost_type", key: "cost_type",
            sorter: (a, b) => a.cost_type - b.cost_type,
            //width: 200
        },
        {
            title: "Ressurss", dataIndex: "resource", key: "cost_description",
            render: (text, record, index) => record.resource + " " + (record.cost_description === null ? '' : record.cost_description),
            //width: 200
        },
        {
            title: "Ettevõte", dataIndex: "org_name", key: "org_name",
            sorter: (a, b) => a.org_name.localeCompare(b.org_name),
            //width: 200
        },
        {
            title: "Ident2", dataIndex: "ident1", key: "ident1",
            //sorter: (a, b) => a.ident1.localeCompare(b.ident1),
            //width: 100
        },
        {
            title: "Ident1", dataIndex: "ident2", key: "ident2",
            //width: 100,
            //sorter: (a, b) => a.ident2.localeCompare(b.ident2)
        },
        {
            title: "Koef", dataIndex: "unit_price_multiplier", key: "unit_price_multiplier", align: "right",
            sorter: (a, b) => a.unit_price_multiplier - b.unit_price_multiplier,
            //width:100
        },
        {
            title: "Kogus", dataIndex: "amount", key: "amount", align: "right",
            sorter: (a, b) => a.amount - b.amount,
            //width:100
        },
        {
            title: "Ühik", dataIndex: "unit", key: "unit",
            sorter: (a, b) => a.unit - b.unit,
            //width: 70,
            ellipsis: true
        },
        {
            title: "Tariif", dataIndex: "unit_price", key: "unit_price", align: "right",
            sorter: (a, b) => a.unit_price - b.unit_price,
            width: 80
        },
        {
            title: "Maksumus", dataIndex: "total", key: "total", align: "right",
            render: (text, record, index) => text ? "€ " + text.toFixed(2).toString() : null,
            width: 100
        },
        {
            title: <InfoCircleOutlined />, dataIndex: "status", key: "status", align: "right",
            render: (text, record, index) => <Tooltip title={record.muutja}> <StatusIcon
                value={record.status}/></Tooltip>,
            //fixed: "right",
            width: 50

        },
        {
            title: "", dataIndex: null, key: "btn",
            render: (text, record, index) => <Btns record={record}/>,
            width: 80,
            //fixed: "right"
        },
    ]

    let filteredCostsPre = costs.filter(r => filterTabs.includes(r.status)) //.filter(r=> r.cost_type !== 'TÖÖJÕUD (begin)')
    let filteredCosts = smartSearch(filteredCostsPre, search, searchColumns)

    let filteredProductionWhPre = productionWh.filter(r => filterTabs.includes(r.wh_status))
    let filteredProductionWh = smartSearch(filteredProductionWhPre, search, searchColumnsProduction)

    let amount = 0
    let rows = 0
    let sum = 0
    let filteredRows = 0

    let footerStats = {
        construction: {
            amount: 0,
            rows: 0,
            sum: 0,
            filteredRows: 0,
        },
        production: {
            amount: 0,
            rows: 0,
            sum: 0,
            filteredRows: 0,
        }
    }

    if (filteredCosts) {
        footerStats.construction.amount = filteredCosts.reduce((sum, a) => sum + a.amount, 0)
        footerStats.construction.sum = filteredCosts.reduce((sum, a) => sum + a.total, 0)
        footerStats.construction.filteredRows = filteredCosts.reduce((sum, a) => sum + 1, 0)
        footerStats.construction.rows = costs.reduce((sum, a) => sum + 1, 0)
    }

    if (filteredProductionWh) {
        footerStats.production.amount = filteredProductionWh.reduce((sum, a) => sum + a.amount, 0)
        footerStats.production.sum = filteredProductionWh.reduce((sum, a) => sum + a.row_sum, 0)
        footerStats.production.filteredRows = filteredProductionWh.reduce((sum, a) => sum + 1, 0)
        footerStats.production.rows = productionWh.reduce((sum, a) => sum + 1, 0)
    }


    const tabList = [
        {
            key: 'ehitus',
            tab: 'Kulud',
        },
        {
            key: 'tootmine',
            tab: 'Tootmise lao sissetulek',
        },
    ];

    //let myMode=whatsMyMode()

    // add tabcontent here and replace card content w tab content

    return (
        <Card
            title="Koondtabel"
            //tabList={myMode === "development" ? tabList : null}
            tabList={tabList}
            activeTabKey={activeTab}
            onTabChange={key => {
                setActiveTab(key);
            }}
            extra={
                <div className="d-flex">

                    {activeTab === 'ehitus' ?
                        selected.length > 0 ?
                            <div className="mr-2 mt-1">
                                Valitud: <Badge count={selected.length}
                                                style={{backgroundColor: '#52c41a'}}/>
                            </div> : null
                        :
                        selectedProduction.length > 0 ?
                            <div className="mr-2 mt-1">
                                Valitud: <Badge count={selectedProduction.length}
                                                style={{backgroundColor: '#52c41a'}}/>
                            </div> : null
                    }

                    <div className="mr-2">
                        <ButtonGroup>
                            <Button onClick={() => selectAll()}><CheckCircleOutlined twoToneColor="#eb2f96" /></Button>
                            <Button onClick={() => deSelectAll()}><MinusCircleOutlined twoToneColor="#52c41a" /></Button>
                        </ButtonGroup>
                    </div>

                    <div>
                        <Search
                            value={search}
                            onChange={e => handleSearch(e.target.value)}
                            className="mr-2"
                            placeholder="Otsi..."
                            allowClear={true}
                        />
                    </div>
                    <Button className="ml-2"
                            onClick={() => fullScreenComponent ? setFullscreen(false) : setFullscreen(
                                <PaControlContent/>)}>
                        <FullscreenOutlined />
                    </Button>
                </div>
            }
        >
            <div style={{overflowX: 'auto'}}>
                {/* selected.map(s => <span>{s}</span>) */}
                {activeTab === 'ehitus'
                    ?
                    <Table
                        dataSource={filteredCosts}
                        loading={isLoading}
                        columns={columns}
                        size="middle"
                        rowKey={"idcosts"}
                        useFixedHeader={true}
                        rowClassName={(record, index) => selected.includes(record.idcosts) ? "selected" : null}
                        footer={() =>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex ">
                                    <div>Kokku ridu: <b>{footerStats.construction.rows}</b></div>
                                </div>
                                <div style={{width: '100%'}} className="d-flex justify-content-end  mr-3">

                                    <div><b>Filter:</b> Ridu: <b>{footerStats.construction.filteredRows}</b></div>
                                    <div className="ml-2"> Kogus: <b>{footerStats.construction.amount.toFixed(2)}</b>
                                    </div>
                                    <div className="ml-2"> Summa: <b>{footerStats.construction.sum.toFixed(2)}</b></div>
                                    <div className="ml-2 mr-2"></div>
                                </div>
                            </div>}
                        //style={{position: 'relative', borderCollapse: 'collapse'}}
                        pagination={{
                            defaultPageSize: 50,
                            showSizeChanger: true,
                            hideOnSinglePage: true
                        }}
                        scroll={{y: '75vh', x: '1000'}}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    handleSelectClick(record.idcosts)
                                }, // click row
                                onDoubleClick: event => {
                                }, // double click row
                                onContextMenu: event => {
                                }, // right button click row
                                onMouseEnter: event => {
                                }, // mouse enter row
                                onMouseLeave: event => {
                                }, // mouse leave row
                            };
                        }}
                    />
                    :
                    <Table
                        dataSource={filteredProductionWh}
                        columns={productionColumns}
                        loading={isLoadingProduction}
                        size="middle"
                        rowKey={"id"}
                        useFixedHeader={true}
                        rowClassName={(record, index) => selectedProduction.includes(record.id) ? "selected" : null}
                        footer={() =>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex ">
                                    <div>Kokku ridu: <b>{footerStats.production.rows}</b></div>
                                </div>
                                <div style={{width: '100%'}} className="d-flex justify-content-end  mr-3">

                                    <div><b>Filter:</b> Ridu: <b>{footerStats.production.filteredRows}</b></div>
                                    <div className="ml-2"> Kogus: <b>{footerStats.production.amount.toFixed(2)}</b>
                                    </div>
                                    <div className="ml-2"> Summa: <b>{footerStats.production.sum.toFixed(2)}</b></div>
                                    <div className="ml-2 mr-2"></div>
                                </div>
                            </div>}
                        //style={{position: 'relative', borderCollapse: 'collapse'}}
                        pagination={{
                            defaultPageSize: 50,
                            showSizeChanger: true,
                            hideOnSinglePage: true
                        }}
                        scroll={{y: '60vh', x: '2000'}}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    handleSelectClickProduction(record.id)
                                }, // click row
                                onDoubleClick: event => {
                                }, // double click row
                                onContextMenu: event => {
                                }, // right button click row
                                onMouseEnter: event => {
                                }, // mouse enter row
                                onMouseLeave: event => {
                                }, // mouse leave row
                            };
                        }}
                    />}
            </div>
        </Card>
    );
}

export default PaActions
