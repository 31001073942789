import { action, computed, thunk } from "easy-peasy"
import API from "../../services/api"
import Cookies from "js-cookie"
import { merge } from 'lodash'
import LogRocket from 'logrocket'

const readJwt = () => {
  let jwt = Cookies.get('jwt')
  return jwt ? jwt : false
}

export const meModel = {
  isAuthenticated: !!readJwt(), // reading the jwt data else false
  jwt: readJwt(),
  me: {},                  // me meta
  //myObjects: [],           //list of id-s calculated from objects

  setJwt: action((state, payload) => state.jwt = payload),
  setIsAuthenticated: action((state, payload) => state.isAuthenticated = payload),
  setMe: action((state, payload) => state.me = payload),

  // computed state stating am I allowed to Edit ?

  editAllowed: computed(
    [state => state.me, (state, storeState) => storeState.object.meta], (me, meta) => {
      if (me && meta) {
        //console.log("Object: ", meta)
        //console.log("Me: ", me.id)
        //return true // if not restricted, restriction control to be added
        if (meta.restricted === 1) {
          if (meta.team.includes(me.id)) return true     // if i'm in the team
          if (meta.proj_manager === me.id) return true   // if i'm the project manager
          if (me.group === 2) return true             // if i'm admin
          return false
        }
      }
      return true
    }),
  apiFetchMe: thunk(async (actions) => {
    const res = await API('/api/me')
    if (res.data) {
      LogRocket.identify(res.data?.id, {
        name: `${res.data?.name} ${res.data?.surname}`,
        email:  res.data?.username,
      })
      actions.setMe(res.data)
      return res.data
    }
  }),
  apiPostMe: thunk(async (actions, payload) => {
    const res = await API('/api/me', false, 'POST', payload)
    if (res.data) {
      actions.apiFetchMe()
    }
  }),
  apiLogin: thunk(async (actions, values) => {
    const res = await API('/api/login', false, 'POST', values)
    console.log("retrieved jwt:", res)
    if (res.jwt) {
      Cookies.set('jwt', res.jwt, { expires: 100 })
      actions.setJwt(res.jwt)
      actions.setIsAuthenticated(true)
      actions.apiFetchMe()
    }
  }),
  apiLogout: thunk(async (actions, values) => {
    const res = await API('/api/logout', false, 'GET', values)
    console.log("logout:", res)
    Cookies.remove('jwt')
    actions.setIsAuthenticated(false)
  }),
}
