import React, { useContext, useEffect, useState } from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Card, Table, Tag, Modal, Button, Input } from 'antd'
import API from '../../services/api'
import CostOptionsContext from '../CostOptionsContext'
import InputCell from '../../components/InputCell'
import { smartSearch } from '../../services/smartSearch'
import { useStoreActions, useStoreState } from 'easy-peasy'

const { Search } = Input

const PaObjectOptions = ({ isOpen, onClose }) => {
  const { formOptions, setFormOptions } = useContext(CostOptionsContext)

  useEffect(() => {
    API('/api/object/costs/options').then((res) => setFormOptions(res))
    // eslint-disable-next-line
  }, [])

  //console.log('formoptions', formOptions)

  const [activeTabKey, setActiveTabKey] = useState('ettevotted')
  const tabList = [
    { key: 'ettevotted', tab: 'Ettevõtted' },
    { key: 'tehnika', tab: 'Oma tehnika' },
    { key: 'materjalid', tab: 'Materjalid (standard)' },
    { key: 'tootajad', tab: 'Töötajad' },
    { key: 'tooyhikud', tab: 'Töö ühikud' },
    { key: 'tootariifid', tab: 'Töö tariifid' },
    { key: 'tehnikayhikud', tab: 'Oma tehnika ühikud' },
    { key: 'rendiyhikud', tab: 'Renditehnika ühikud' },
    { key: 'rendityybid', tab: 'Tehnika tüübid' },
    { key: 'transpordiyhikud', tab: 'Transpordi ühikud' },
    { key: 'transpordityybid', tab: 'Transpordi tehnika tüübid' },
    { key: 'materialyhikud', tab: 'Materjali ühikud' },
    { key: 'materialartiklid', tab: 'Materjali artiklid' },
    { key: 'ident1', tab: 'Ident1' },
    { key: 'ident2', tab: 'Ident2' },
    { key: 'warehouses', tab: 'Laod' }
  ]

  const contentList = {
    ettevotted: <CompaniesList />,
    tehnika: <EquipmentAndEmployeeList type="equipment_list" />,
    materjalid: <MaterialsList />,
    tootajad: <EquipmentAndEmployeeList type="employee_list" />,
    tooyhikud: <FormOptionsList group_id={1} />,
    tootariifid: <FormOptionsList group_id={2} />,
    tehnikayhikud: <FormOptionsList group_id={4} />,
    rendiyhikud: <FormOptionsList group_id={6} />,
    rendityybid: <FormOptionsList group_id={14} />,
    transpordiyhikud: <FormOptionsList group_id={8} />,
    transpordityybid: <FormOptionsList group_id={15} />,
    materialyhikud: <FormOptionsList group_id={10} />,
    materialartiklid: <FormOptionsList group_id={11} />,
    ident1: <FormOptionsList group_id={12} />,
    ident2: <FormOptionsList group_id={13} />,
    warehouses: <FormOptionsList group_id={17} />
  }

  return (
    <Modal visible={isOpen} onOk={onClose} style={{ width: '80%' }} width="80%" onCancel={onClose}>
      <Card
        style={{ width: '100%' }}
        tabList={tabList}
        activeTabKey={activeTabKey}
        className="mt-2"
        bordered={false}
        onTabChange={(key) => setActiveTabKey(key)}>
        {contentList[activeTabKey]}
      </Card>
    </Modal>
  )
}

function getCellReference(index, field) {
  return index.toString() + field
}

async function handleChange(value, field, index, row, setActiveCell, idFieldName, handlePayload) {
  console.log('handling change...', value, field, index, row)
  // if either previous value or new value exist
  if (row[field] || value) {
    let newValueString = value ? value.toString() : false
    let oldValueString = row[field] ? row[field].toString() : false

    if (newValueString !== oldValueString) {
      //console.log("ONBLUR/onchange/change detected, updating...")
      let payload = {
        value: value,
        id: row[idFieldName],
        field: field
      }
      await handlePayload(payload)
      console.log('Setting cell false')
      await setActiveCell(false)
    } else setActiveCell(false)
  } else setActiveCell(false)
}

const CompaniesList = () => {
  const { formOptions, setFormOptions } = useContext(CostOptionsContext)
  const [activeCell, setActiveCell] = useState(false)
  const [search, setSearch] = useState('')

  const refreshList = async () => {
    let res = await API('/api/object/costs/options')
    await setFormOptions(res)
  }

  const handleAddRow = async () => {
    await API('/api/object/costs/options/companies', false, 'PUT')
    await refreshList()
  }

  const handleDelRow = async (id) => {
    await API('/api/object/costs/options/companies', false, 'DELETE', { id: id })
    await refreshList()
  }

  const handlePayload = async (payload) => {
    console.log(payload)
    await API('/api/object/costs/options/companies', false, 'POST', payload)
    await refreshList()
  }

  let filteredDataSource = formOptions.companies_list

  const getInputCell = (index, field, rowsCount, record) => (
    <InputCell
      type="input"
      onClick={() => setActiveCell(getCellReference(record.company, field))}
      onBlur={(value) => handleChange(value, field, index, record, setActiveCell, 'company', handlePayload)}
      onUpdate={(value) => handleChange(value, field, index, record, setActiveCell, 'company', handlePayload)}
      onMoveUp={(value) => console.log(value)}
      onMoveDown={(value) => console.log(value)}
      active={activeCell === getCellReference(record.company, field)}
      defaultValue={record[field]}
    />
  )

  const columns = [
    //{
    //    title: 'ID',
    //    key: "company",
    //    dataIndex: "company",
    //},
    {
      title: 'Ettevõte',
      key: 'org_name',
      dataIndex: 'org_name',
      render: (text, record, index) => getInputCell(index, 'org_name', 10, record)
    },
    {
      title: '',
      key: 'org_status',
      dataIndex: 'org_status',
      render: (text, record, index) => (record.org_status === '4' ? <Tag color="#2db7f5">See on uus rida</Tag> : null)
    },
    {
      //title: <i className="far fa-trash-alt pr-2"/>,
      title: <Button icon={<LegacyIcon type={'plus'} />} shape="circle" onClick={() => handleAddRow()} />,
      align: 'right',
      key: 'idorg',
      dataIndex: '',
      render: (text, record, index) => <i onClick={() => handleDelRow(record.company)} className="far fa-trash-alt pr-2" />
    }
  ]

  const searchColumns = ['org_name']

  return (
    <div>
      <Search value={search} onChange={(e) => setSearch(e.target.value)} className="mb-2" placeholder="Otsi..." />
      <Table
        //dataSource={filteredDataSource}
        dataSource={smartSearch(filteredDataSource, search, searchColumns)}
        rowId="company"
        columns={columns}
        size="small"
        onRow={(record, rowIndex) => {
          if (record.org_status === '4')
            return {
              style: { background: '#f6ffed' }
            }
        }}
      />
    </div>
  )
}

const EquipmentAndEmployeeList = ({ type }) => {
  const { formOptions, setFormOptions } = useContext(CostOptionsContext)
  const [activeCell, setActiveCell] = useState(false)

  const [search, setSearch] = useState('')

  const refreshList = async () => {
    let res = await API('/api/object/costs/options')
    await setFormOptions(res)
  }

  const handleAddRow = async () => {
    let payload = {
      type: type === 'employee_list' ? 1 : 2
    }
    await API('/api/object/costs/options/resources', false, 'PUT', payload)
    await refreshList()
  }

  const handleDelRow = async (id) => {
    await API('/api/object/costs/options/resources', false, 'DELETE', { id: id })
    await refreshList()
  }

  const handlePayload = async (payload) => {
    //console.log(payload)
    await API('/api/object/costs/options/resources', false, 'POST', payload)
    await refreshList()
  }

  let filteredDataSource = type === 'employee_list' ? formOptions.employee_list : formOptions.equipment_list
  const searchColumns =
    type === 'employee_list' ? ['res_name1', 'res_price'] : ['res_name1', 'res_name2', 'res_group_name', 'res_unit', 'res_price']

  const getInputCell = (index, field, rowsCount, record) => (
    <InputCell
      type="input"
      onClick={() => setActiveCell(getCellReference(record.idresources, field))}
      onBlur={(value) => handleChange(value, field, index, record, setActiveCell, 'idresources', handlePayload)}
      onUpdate={(value) => handleChange(value, field, index, record, setActiveCell, 'idresources', handlePayload)}
      onMoveUp={(value) => console.log(value)}
      onMoveDown={(value) => console.log(value)}
      active={activeCell === getCellReference(record.idresources, field)}
      defaultValue={record[field]}
    />
  )

  const getSelectCell = (index, valueField, titleField, rowsCount, selectProps, record) => (
    <InputCell
      type="select"
      selectProps={selectProps}
      onClick={() => setActiveCell(getCellReference(index, titleField))}
      onUpdate={(value) => handleChange(value, valueField, index, record, setActiveCell, 'idresources', handlePayload)}
      onMoveLeft={(value) => setActiveCell(getCellReference(index, titleField, -1, false))}
      onMoveRight={(value) => setActiveCell(getCellReference(index, titleField, 1, false))}
      active={activeCell === getCellReference(index, titleField)}
      defaultValue={record[valueField]}
      defaultTitle={valueField === 'refueling' ? (record?.refueling === 1 ? 'Jah' : 'Ei') : record[titleField]}
    />
  )

  const columns = {
    equipment_list: [
      //{
      //    title: 'ID',
      //    key: "idresources",
      //    dataIndex: "idresources",
      //},
      {
        title: 'Nr',
        key: 'res_name1',
        dataIndex: 'res_name1',
        render: (text, record, index) => getInputCell(index, 'res_name1', 10, record)
      },
      {
        title: 'Nimi',
        key: 'res_name2',
        dataIndex: 'res_name2',
        render: (text, record, index) => getInputCell(index, 'res_name2', 10, record)
      },
      {
        title: 'Väline kood',
        key: 'external_id',
        dataIndex: 'external_id',
        render: (text, record, index) => getInputCell(index, 'external_id', 10, record)
      },
      {
        title: 'Tehnika tüüp',
        key: 'res_group_name',
        dataIndex: 'res_group_name',
        render: (text, record, index) =>
          //getInputCell(index, 'res_group_name', 10, record)
          getSelectCell(
            index,
            'res_group_name',
            'res_group_name',
            10,
            {
              optionsList: formOptions.formoptions[14],
              valueField: 'value',
              titleField: 'name'
            },
            record
          )
      },

      {
        title: 'Ühik',
        key: 'res_unit',
        dataIndex: 'res_unit',
        render: (text, record, index) => getInputCell(index, 'res_unit', 10, record)
      },
      {
        title: 'Hind',
        key: 'res_price',
        dataIndex: 'res_price',
        render: (text, record, index) => getInputCell(index, 'res_price', 10, record)
      },
      {
        title: 'Min Kogus',
        key: 'res_min_amount',
        dataIndex: 'res_min_amount',
        render: (text, record, index) => getInputCell(index, 'res_min_amount', 10, record)
      },
      {
        title: 'Tankimine?',
        key: 'refueling',
        dataIndex: 'refueling',
        render: (text, record, index) =>
          //getInputCell(index, 'res_group_name', 10, record)
          getSelectCell(
            index,
            'refueling',
            'refueling',
            10,
            {
              optionsList: [
                { value: 0, label: 'Ei' },
                { value: 1, label: 'Jah ' }
              ],
              valueField: 'value',
              titleField: 'label'
            },
            record
          )
      },
      {
        title: '',
        key: 'res_status',
        dataIndex: 'res_status',
        render: (text, record, index) => (record.res_status === 4 ? <Tag color="#2db7f5">See on uus rida</Tag> : null)
      },
      {
        //title: <i className="far fa-trash-alt pr-2"/>,
        title: <Button icon={<LegacyIcon type={'plus'} />} shape="circle" onClick={() => handleAddRow()} />,
        align: 'right',
        key: 'idorg',
        dataIndex: '',
        render: (text, record, index) => <i onClick={() => handleDelRow(record.idresources)} className="far fa-trash-alt pr-2" />
      }
    ],
    employee_list: [
      //{
      //    title: 'ID',
      //    key: "idresources",
      //    dataIndex: "idresources",
      //},
      {
        title: 'Nimi',
        key: 'res_name1',
        dataIndex: 'res_name1',
        render: (text, record, index) => getInputCell(index, 'res_name1', 10, record)
      },
      {
        title: 'Väline kood',
        key: 'external_id',
        dataIndex: 'external_id',
        render: (text, record, index) => getInputCell(index, 'external_id', 10, record)
      },
      {
        title: 'Tariif',
        key: 'res_price',
        dataIndex: 'res_price',
        render: (text, record, index) => getInputCell(index, 'res_price', 10, record)
      },
      {
        title: '',
        key: 'res_status',
        dataIndex: 'res_status',
        render: (text, record, index) => (record.res_status === 4 ? <Tag color="#2db7f5">See on uus rida</Tag> : null)
      },
      {
        //title: <i className="far fa-trash-alt pr-2"/>,
        title: <Button icon={<LegacyIcon type={'plus'} />} shape="circle" onClick={() => handleAddRow()} />,
        align: 'right',
        key: 'idorg',
        dataIndex: '',
        render: (text, record, index) => <i onClick={() => handleDelRow(record.idresources)} className="far fa-trash-alt pr-2" />
      }
    ]
  }

  return (
    <div>
      <Search value={search} onChange={(e) => setSearch(e.target.value)} className="mb-2" placeholder="Otsi..." />
      <Table
        dataSource={smartSearch(filteredDataSource, search, searchColumns)}
        rowId="idformoptions"
        columns={columns[type]}
        size="small"
        onRow={(record, rowIndex) => {
          if (record.res_status === 4)
            return {
              style: { background: '#f6ffed' }
            }
        }}
      />
    </div>
  )
}

const FormOptionsList = ({ group_id }) => {
  const { formOptions, setFormOptions } = useContext(CostOptionsContext)
  const [activeCell, setActiveCell] = useState(false)
  const [search, setSearch] = useState('')

  const refreshList = async () => {
    let res = await API('/api/object/costs/options')
    await setFormOptions(res)
  }

  const handleAddRow = async () => {
    await API('/api/object/costs/options/formoptions', false, 'PUT', { group_id: group_id })
    await refreshList()
  }

  const handleDelRow = async (id) => {
    await API('/api/object/costs/options/formoptions', false, 'DELETE', { id: id })
    await refreshList()
  }

  const handlePayload = async (payload) => {
    let myPayload = {
      group_id: group_id,
      ...payload
    }
    await API('/api/object/costs/options/formoptions', false, 'POST', myPayload)
    await refreshList()
  }

  let filteredDataSource = formOptions.formoptions[group_id]
  const searchColumns = ['name', 'value']

  const getInputCell = (index, field, rowsCount, record) => (
    <InputCell
      type="input"
      onClick={() => setActiveCell(getCellReference(record.idformoptions, field))}
      onBlur={(value) => handleChange(value, field, index, record, setActiveCell, 'idformoptions', handlePayload)}
      onUpdate={(value) => handleChange(value, field, index, record, setActiveCell, 'idformoptions', handlePayload)}
      onMoveUp={(value) => console.log(value)}
      onMoveDown={(value) => console.log(value)}
      active={activeCell === getCellReference(record.idformoptions, field)}
      defaultValue={record[field]}
    />
  )

  const columns = [
    // {
    //     title: 'ID',
    //     key: "idformoptions",
    //     dataIndex: "idformoptions",
    // },
    //  {
    //     title: 'group_id',
    //     key: "group_id",
    //     dataIndex: "group_id",
    //     render: (text, record, index) =>
    //         getInputCell(index, 'group_id', 10, record)
    // },
    {
      title: 'Näidatakse',
      key: 'name',
      dataIndex: 'name',
      render: (text, record, index) => getInputCell(index, 'name', 10, record)
    },
    {
      title: 'Kirja läheb',
      key: 'value',
      dataIndex: 'value',
      render: (text, record, index) => getInputCell(index, 'value', 10, record)
    },
    {
      title: 'Väline kood',
      key: 'external_id',
      dataIndex: 'external_id',
      render: (text, record, index) => getInputCell(index, 'external_id', 10, record)
    },
    {
      title: '',
      key: 'optionstatus',
      dataIndex: 'optionstatus',
      render: (text, record, index) => (record.optionstatus === 4 ? <Tag color="#2db7f5">See on uus rida</Tag> : null)
    },
    {
      //title: <i className="far fa-trash-alt pr-2"/>,
      title: <Button icon={<LegacyIcon type={'plus'} />} shape="circle" onClick={() => handleAddRow()} />,
      align: 'right',
      key: 'action',
      dataIndex: '',
      render: (text, record, index) => <i onClick={() => handleDelRow(record.idformoptions)} className="far fa-trash-alt pr-2" />
    }
  ]

  return (
    <div>
      <Search value={search} onChange={(e) => setSearch(e.target.value)} className="mb-2" placeholder="Otsi..." />
      <Table
        dataSource={smartSearch(filteredDataSource, search, searchColumns)}
        rowId="idformoptions"
        columns={columns}
        size="small"
        onRow={(record, rowIndex) => {
          if (record.optionstatus === 4)
            return {
              style: { background: '#f6ffed' }
            }
        }}
      />
    </div>
  )
}

const MaterialsList = ({ isOpen, onClose }) => {
  const { formOptions, setFormOptions } = useContext(CostOptionsContext)
  //const res = useStoreState(state => state.warehouse.resources);
  const fetchRes = useStoreActions((actions) => actions.warehouse.apiFetchResources)
  const updateRes = useStoreActions((actions) => actions.warehouse.apiUpdateResources)
  const deleteRes = useStoreActions((actions) => actions.warehouse.apiDeleteResources)
  const addRes = useStoreActions((actions) => actions.warehouse.apiAddResources)

  const [search, setSearch] = useState('')
  const [activeCell, setActiveCell] = useState(false)

  const res = formOptions.materials

  console.log('FormOptions', formOptions.materials)

  function getCellReference(index, field) {
    return index.toString() + field
  }

  const getInputCell = (index, field, rowsCount, record) => (
    <InputCell
      type="input"
      onClick={() => setActiveCell(getCellReference(index, field))}
      onBlur={(value) => handleMaterialChange(value, field, index, record)}
      onUpdate={(value) => handleMaterialChange(value, field, index, record)}
      onMoveUp={(value) => null}
      onMoveDown={(value) => null}
      active={activeCell === getCellReference(index, field)}
      defaultValue={record[field]}
    />
  )

  const getSelectCell = (index, valueField, titleField, rowsCount, selectProps, record) => (
    <InputCell
      type="select"
      selectProps={selectProps}
      onClick={() => setActiveCell(getCellReference(index, titleField))}
      onUpdate={(value) => handleMaterialChange(value, valueField, index, record)}
      active={activeCell === getCellReference(index, titleField)}
      defaultValue={record[valueField]}
      defaultTitle={record[titleField]}
    />
  )

  const columns = [
    {
      title: 'Verstoni ID',
      dataIndex: 'idresources',
      key: 'idresources',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.idresources > b.idresources
    },
    //{
    //    title: "Materjal", dataIndex: "res_name1", key: "res_name1",
    //    render: (text, record, index) => getInputCell(index, 'res_name1', 0, record)
    //},
    //{
    //    title: "Materjal", dataIndex: "res_name2", key: "res_name2",
    //    render: (text, record, index) => getSelectCell(index, 'res_name2', 'res_name2', 0, {
    //        optionsList: [
    //            {value: "Täitematerjal", title: "Täitematerjal"},
    //            {value: "Kütus", title: "Kütus"},
    //            {value: "Sideaine", title: "Sideaine"},
    //            {value: "Muu materjal", title: "Muu materjal"},
    //        ],
    //        valueField: "value",
    //        titleField: "title"
    //    }, record)
    //},
    {
      title: 'Materjal',
      dataIndex: 'res_name1',
      key: 'res_name1',
      render: (text, record, index) => getInputCell(index, 'res_name1', 0, record)
    },
    {
      title: 'Grupp',
      dataIndex: 'res_group_name',
      key: 'res_group_name',
      render: (text, record, index) => getInputCell(index, 'res_group_name', 0, record)
    },
    {
      title: 'Muu kood (NAV)',
      dataIndex: 'external_id',
      key: 'external_id',
      render: (text, record, index) => getInputCell(index, 'external_id', 0, record)
    },
    //{
    //    title: "Karjäär", dataIndex: "res_property_factory", key: "res_property_factory",
    //    render: (text, record, index) => getInputCell(index, 'res_property_factory', 0, record)
    //},
    //{
    //    title: "Tarnija", dataIndex: "res_property_org", key: "res_property_org",
    //    render: (text, record, index) => getSelectCell(index, 'res_property_org', 'res_property_orgname', 0, {
    //        optionsList: formOptions.companies_list,
    //        valueField: "company",
    //        titleField: "org_name"
    //    }, record)
    //
    //},
    {
      title: 'Ident1',
      dataIndex: 'res_property_ident1',
      key: 'res_property_ident1',
      render: (text, record, index) => getInputCell(index, 'res_property_ident1', 0, record)
    },
    {
      title: 'Ident2',
      dataIndex: 'res_property_ident2',
      key: 'res_property_ident2',
      render: (text, record, index) => getInputCell(index, 'res_property_ident2', 0, record)
    },
    //{
    //    title: "Ühik", dataIndex: "res_unit", key: "res_unit",
    //    render: (text, record, index) => getInputCell(index, 'res_unit', 0, record)
    //},
    {
      title: <i className="far fa-trash-alt" style={{ color: 'red' }}></i>,
      dataIndex: 'idresources',
      key: 'idresources_x',
      render: function (text, record, index) {
        return <i className="far fa-trash-alt" onClick={() => handleDelRow(record['idresources'])} style={{ color: 'red' }}></i>
      }
    }
  ]

  const searchColumns = ['res_name1', 'res_name2', 'res_group_name', 'external_id', 'res_property_ident1', 'res_property_ident2']

  const refreshList = async () => {
    const res = await API('/api/object/costs/options')
    console.log('refreshedRes', res)
    await setFormOptions(res)
  }

  const handleAddRow = async () => {
    let payload = {
      type: 5
    }
    await API('/api/object/costs/options/resources', false, 'PUT', payload)
    await refreshList()
  }

  const handleDelRow = async (id) => {
    await API('/api/object/costs/options/resources', false, 'DELETE', { id: id })
    await refreshList()
  }

  async function handleMaterialChange(value, field, index, row) {
    console.log('handling change...', value, field, index, row)
    // if either previous value or new value exist
    if (row[field] || value) {
      let newValueString = value ? value.toString() : false
      let oldValueString = row[field] ? row[field].toString() : false

      if (newValueString !== oldValueString) {
        //console.log("ONBLUR/onchange/change detected, updating...")
        let payload = {
          value: value,
          id: row['idresources'],
          field: field
        }
        await handlePayload(payload)
        console.log('Setting cell false')
        setActiveCell(false)
      } else setActiveCell(false)
    } else setActiveCell(false)
  }

  const handlePayload = async (payload) => {
    //console.log(payload)
    await API('/api/object/costs/options/resources', false, 'POST', payload)
    await refreshList()
  }

  return (
    <div>
      <div className="d-flex mb-2">
        <Search value={search} onChange={(e) => setSearch(e.target.value)} className="mr-2" placeholder="Otsi..." />
        <Button onClick={() => handleAddRow()}>Lisa</Button>
      </div>
      <Table dataSource={smartSearch(res, search, searchColumns)} columns={columns} />
    </div>
  )
}

export default PaObjectOptions
