// the component that displays icon and popover for cost row. Records must be single record

import {useState} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import CostDivisionContext from "../../../../CostDivisionContext";
import { PieChartOutlined } from '@ant-design/icons';
import { Button, Popover, Tag, Tooltip } from "antd";
import React from "react";
import makeAssignment from "../makeAssignmentV2"
import PaCostBillDivisionContent from "../v2/costBillDivisionContent"


// the component for setting assignments for the whole group at the time
const PaGroupBillDivision = ({records, costType, shape = "circle", placement = "left", fullday = false}) => {


    const assign = useStoreActions(actions => actions.object.apiSetAssignment)
    //const apiLoadCostRow = useStoreActions(actions => actions.object.apiLoadCostRow)
    const apiLoadCosts = useStoreActions(actions => actions.object.apiLoadCosts)
    const meta = useStoreState(state => state.object.meta)
    const editAllowed = useStoreState(state => state.user.editAllowed)
    const assignmentsTotal = useStoreState(state => state.object.assignmentsInProgressTotal)

    const assignments = useStoreState(state => state.object.assignmentsCurrentList)
    const setAssignmentsActiveCostRows = useStoreActions(actions => actions.object.setAssignmentsActiveCostRows)

    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleVisibleChange = (value) => setVisible(value)

    const handleSubmit = async () => {

            await setLoading(true)
            await assign(makeAssignment(assignments, records))

            await setVisible(false)
            await apiLoadCosts(meta.idobject)
            //await apiLoadBudget(meta.idobject)
            await setLoading(false)
    }

    return (
        <Popover placement={placement}
                 visible={visible}
                 onVisibleChange={handleVisibleChange}
                 title={
                     <div className="m-2 d-flex justify-content-between align-content-center">
                         <div>Jaotus loendite vahel</div>

                         {assignmentsTotal === 100 ?
                             <div>
                                 <Button
                                     disabled={assignmentsTotal !== 100}
                                     onClick={handleSubmit}
                                 >{assignmentsTotal < 100 ? assignmentsTotal.toFixed(3) + "%" : "Salvesta"}</Button>
                             </div>
                             :
                             <div className="d-flex align-content-center">
                                 <div className="align-content-center">
                                     <small className="text-muted mr-2">Vajad salvestamiseks 100%</small>

                                 </div>
                             </div>
                         }

                     </div>}
                 content={<PaCostBillDivisionContent records={records} loading={loading} costType={costType}/>}
                 trigger="click">
            {fullday ?
                <a onClick={() => !visible ? setAssignmentsActiveCostRows([records]) : null}> Määra bill kogu päevale </a>
                :
                <Tooltip title="Määra bill kogu kulugrupile">

                    <Button
                        icon={<PieChartOutlined/>}
                        shape={shape}
                        className="mr-1"
                        disabled={!editAllowed}
                        onClick={() => !visible ? setAssignmentsActiveCostRows([records]) : null}>
                    </Button>
                </Tooltip>
            }
        </Popover>
    );
}

export default PaGroupBillDivision