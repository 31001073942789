import React, { forwardRef, useImperativeHandle } from 'react'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Switch, DatePicker } from 'antd';
import moment from 'moment'
import TextArea from 'antd/lib/input/TextArea'
import dateLocale from 'antd/es/date-picker/locale/et_EE'


const formItemLayoutVertical = {
  layout: 'vertical',
  style: { width: '90%', maxWidth: 873 },
  wrapperCol: { span: 24 },
}

const ActParametersForm = ({ form, form: { getFieldDecorator } }, ref) => {
  useImperativeHandle(ref, () => ({
    form,
  }))
  const message = 'Kontrolli see väli üle'
  return (
    <Form layout='inline' {...formItemLayoutVertical}>
      {/* act_nr */}
      <Form.Item label={"Akti number"} hasFeedback>
        {getFieldDecorator('act_nr', {
          rules: [{ required: true, message }],
        })(
          <Input />,
        )}
      </Form.Item>
      {/* date_document */}
      <Form.Item label={"Kuupäev"} hasFeedback>
        {getFieldDecorator('date_document', {
          initialValue: moment(),
          rules: [{ required: true, message }],
        })(
          <DatePicker locale={dateLocale} format={'DD.MM.YYYY'} />,
        )}
      </Form.Item>
      {/* work_start */}
      <Form.Item label={"Töö algus"} hasFeedback>
        {getFieldDecorator('work_start', {
          initialValue: moment(),
          rules: [{ required: true, message }],
        })(
          <DatePicker locale={dateLocale} format={'DD.MM.YYYY'} />,
        )}
      </Form.Item>
      {/* work_end */}
      <Form.Item label={"Töö lõpp"} hasFeedback>
        {getFieldDecorator('work_end', {
          initialValue: moment(),
          rules: [{ required: true, message }],
        })(
          <DatePicker locale={dateLocale} format={'DD.MM.YYYY'} />,
        )}
      </Form.Item>
      <Form.Item label={"Eeltäida andmed (Päev-Tulu tabelist)"}>
        {getFieldDecorator('prefill', {
          initialValue: false,
        })(
          <Switch />,
        )}
      </Form.Item>
      {/* description */}
      <Form.Item label={"Märkused"} hasFeedback>
        {getFieldDecorator('description', {
          initialValue: '',
          rules: [{ required: false }],
        })(
          <TextArea />,
        )}
      </Form.Item>
    </Form>
  )
}

export default Form.create('actParametersForm')(forwardRef(ActParametersForm))
