import { useCallback } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

export const useFilters = key => {
  const filters = useStoreState(state => state.filters.items[key])
  const saveFilters = useStoreActions(actions => actions.filters.save)

  const saveKeyFilters = useCallback((payload) => {
    if (payload?.columns) {
      const mergedPayload = { ...filters, ...payload }
      saveFilters({ key, ...mergedPayload })
    } else {
      saveFilters({ key, ...payload })
    }
  }, [filters, key, saveFilters])
  // TODO: should also check if there are any new values that are missing from the saved localstorage state and add them
  const filtersFallback = filters ?? { filtered: {}, pagination: { pageSize: 100 }, sorted: {} }
  return [filtersFallback, saveKeyFilters]
}