import {action, computed, thunk} from "easy-peasy";
import {smartSearch} from "../../services/smartSearch";
import API from "../../services/api";

export const warehouseModel = {
    costs: [],
    problematic: [],
    resources: [],
    searchColumns: ["res_name1", "res_property_factory", "res_property_orgname", "res_property_ident1", "res_property_ident2", "begin_amount", "added_amount", "amount", "rest_amount", "unit_price"],

    // production incoming: all incoming amounts for all resources in object
    incoming: [],
    activeResource: {name: null, id: null},

    // filters
    amountsAvailableOnly: false,
    withIncome: false,
    withCost: false,
    searchString: '',

    setWithIncome: action((state, payload) => state.withIncome = payload),
    setWithCost: action((state, payload) => state.withCost = payload),
    setAmountsAvailableOnly: action((state, payload) => state.amountsAvailableOnly = payload),
    setCosts: action((state, payload) => state.costs = payload),
    setResources: action((state, payload) => state.resources = payload),
    setProblematic: action((state, payload) => state.problematic = payload),
    setSearchString: action((state, payload) => state.searchString = payload),
    setIncoming: action((state, payload) => state.incoming = payload),
    setActiveResource: action((state, payload) => state.activeResource = payload),

    movements: computed(
        [
            state => state,
            (state, storeState) => storeState.object.costs], (state, costs) => {

            let dates = []

            if (state.incoming && costs && state.activeResource.id) {

                Object.values(costs).forEach(c => !dates.includes(c.cost_date) ? dates.push(c.cost_date) : null)
                state.incoming.forEach(c => !dates.includes(c.date) ? dates.push(c.date) : null)

            }

            return dates
        }),


    costs_grouped_by: computed(state => {
        let grouped = []
        let material_list = []

        function filterBy(res_name2) {
            let res = state.costs.filter(r => r.res_name2 === res_name2)

            // applying further filters and searches
            if (state.amountsAvailableOnly) res = res.filter(r => r.amounts_available === true)
            if (state.withIncome) res = res.filter(r => r.added_amount > 0)
            if (state.withCost) res = res.filter(r => r.amount > 0)

            return smartSearch(res, state.searchString, state.searchColumns)
        }

        if (state.costs) {
            state.costs.forEach(row => {
                if (!material_list.includes(row.res_name2)) {
                    let grouped_entry = {
                        res_name1: row.res_name2,
                        grouper_row: true,
                        begin_amount: 1,
                        row_sum: state.costs.reduce((sum, a) => a.res_name2 === row.res_name2 ? sum + a['row_sum'] : sum, 0),
                        count_problematic_wh: state.costs.reduce((sum, a) => a.res_name2 === row.res_name2 && a.wh_status === 7 ? sum + 1 : sum, 0),
                        count_zero_purch: state.costs.reduce((sum, a) => a.res_name2 === row.res_name2 && a.purch_price === "0" ? sum + 1 : sum, 0),
                        children: filterBy(row.res_name2)
                    }
                    grouped.push(grouped_entry)
                    material_list.push(row.res_name2)
                }
            })
        }
        return grouped
    }),


    apiFetchCosts: thunk(async (actions, payload) => {
        const costs = await API("/api/object/warehouse", false, 'get', payload)

        let data = []
        costs.data.forEach((row, index) => {
            data.push({index: index, ...row})
        })
        //console.log("fetched wh data: ", costs)
        await actions.setCosts(data)
    }),

    apiUpdateCosts: thunk(async (actions, payload) => {
        await API("/api/object/warehouse", false, 'post', payload)
        await actions.apiFetchCosts(payload)
    }),

    apiPrefillWarehouse: thunk(async (actions, payload) => {
        await API("/api/object/warehouse/prefill", false, 'get', payload)
        await actions.apiFetchCosts(payload)
    }),

    apiFetchResources: thunk(async (actions) => {
        const res = await API("/api/object/warehouse/resources")
        actions.setResources(res.data)
    }),

    apiFetchIncoming: thunk(async (actions, payload) => {
        const res = await API("/api/object/warehouse/transactionsincoming", false, 'get', payload)
        actions.setIncoming(res.data)
    }),

    apiFetchProblematic: thunk(async (actions, payload) => {
        const res = await API("/api/object/warehouse/problematic", false, 'get', payload)
        actions.setProblematic(res.data)
    }),

    apiUpdateResources: thunk(async (actions, payload) => {
        await API("/api/object/warehouse/resources", false, "POST", payload)
        actions.apiFetchResources()
    }),

    apiAddResources: thunk(async (actions, payload) => {
        await API("/api/object/warehouse/resources", false, "PUT", payload)
        actions.apiFetchResources()
    }),

    apiDeleteResources: thunk(async (actions, payload) => {
        await API("/api/object/warehouse/resources", false, "DELETE", payload)
        actions.apiFetchResources()
    }),
}
