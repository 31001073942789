import React, { useState, useEffect } from "react"
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Table, Button, Row, Col, message } from "antd"
import { useStoreState, useStoreActions } from "easy-peasy"
import { smartSearch } from "../../../services/smartSearch"
import moment from 'moment'
import { EditableCell, EditableFormRow } from './EditableCell'

const { Search } = Input


const ActTable = ({ act: { id: actId, status: actStatus }, exportAct, objectId }) => {
  const saveActRow = useStoreActions(actions => actions.actRows.save)
  const loadingRows = useStoreState(state => state.actRows.loadingRows)
  const fetchActs = useStoreActions(actions => actions.acts.fetch)
  const fetchActRows = useStoreActions(actions => actions.actRows.fetch)
  const addEmptyRow = useStoreActions(actions => actions.actRows.addEmptyRow)
  const actRows = useStoreState(state => state.actRows.items)

  const actOffers = useStoreState(state => state.actRows.offers)
  const loadingOffers = useStoreState(state => state.actRows.loadingOffers)
  const offersList = useStoreState(state => state.actRows.offersList)

  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    fetchActRows(actId)
  }, [])

  useEffect(() => {
    fetchActs(objectId)
  }, [actRows])

  let filteredDataSource = []

  if (actId && !loadingRows && !loadingOffers) {
    filteredDataSource = Object.values(actOffers) ? offersList(actId) : []
    console.log('datasource', filteredDataSource)
  }

  const handleSave = ({ record, values, values: { type, value } }) => {
    if (record.amount === Number(value) || moment(record.date_akt).isSame(value, 'day') || value.length === 0 || !record.unit_price) {
      if (!record.unit_price) {
        message.error('Enne aktirea loomist tuleb määrata ühikhind eelarves. Tagantjärele muudetud hind ei kajastu juba loodud ridades.', 20)
      }
      console.log('saving stopped...')
      return
    }
    if (!loadingOffers) {
      console.log('saving act row', { record, values })
      const { offer_nr } = actOffers && offersList(actId).filter(offer => offer.act_offer_id === record.offer_id)[0]
      let payload = {}

      if (record.id) payload['id'] = record.id
      else payload = record

      if (type === 'dateTime') {
        payload['date_akt'] = value.format('YY-MM-DD')
      } else {
        payload['amount'] = Number(value)
      }
      saveActRow({ payload, offer_nr })
    }
  }

  const renderSum = (text, record, index) => (text ? "€ " + text.toFixed(2).toString() : '-')

  const footer = () => {
    let amountsText = "Kogus kokku: "
    let sumText = "Summa kokku: € "
    if (!loadingRows) {
      const rows = actRows[actId]
      if (rows) {
        amountsText = amountsText + Object.values(rows).reduce((acc, { amount }) => {
          return acc + amount
        }, 0)
        sumText = sumText + Object.values(rows).reduce((acc, { amount, unit_price }) => {
          return acc + amount * unit_price
        }, 0)
      }
    }
    return (
      <div style={{ width: '100%' }} className="d-flex justify-content-end mr-3">
        <div><b> {`${amountsText} ${sumText}`} </b></div>
        <div className="ml-2 mr-2"></div>
      </div>
    )
  }

  const columns = [
    {
      title: 'Töö',
      dataIndex: 'art_name',
      key: 'offer_id',
      onCell: record => record.editable &&
        ({
          record,
          editable: true,
          dateTime: true,
          dataIndex: 'art_name',
          title: 'Kuupäev',
          handleSave: handleSave,
        }),
      render: (text, record, index) => {
        if (record.isTitle) return <b>{text}</b>
        if (record.adding) {
          return <Button onClick={() => {
            console.log(record)
            if (!record.unit_price) {
              message.error('Enne aktirea loomist tuleb määrata ühikhind eelarves. Tagantjärele muudetud hind ei kajastu juba loodud ridades.', 20)
            } else {
              addEmptyRow({
                data: {
                  act_id: actId,
                  offer_id: record.offer_id,
                  obj_id: objectId,
                  offer_nr: record.offer_nr,
                  amount: 0,
                  unit_price: record.unit_price,
                  date_akt: moment().format('YYYY-MM-DD'),
                  isDate: true
                }
              })
            }
          }}>Lisa uus rida</Button>
        }
        return record.isDate ? moment(text).format('DD.MM.YYYY') : text
      }
    },
    {
      title: 'Ühik',
      dataIndex: 'unit',
      key: 'unit_type'
    },
    {
      title: 'Akteerin',
      children: [
        {
          title: 'Maht',
          dataIndex: 'acting_amount',
          key: 'acting_amount',
          editable: true,
          align: 'left',
          onCell: record => record.editable &&
            ({
              record,
              editable: true,
              dateTime: false,
              dataIndex: 'acting_amount',
              title: 'Maht',
              handleSave: handleSave,
            }),
          className: 'projection_highlight',
        },
        {
          title: 'Summa',
          dataIndex: 'acting_sum',
          key: 'acting_sum',
          align: 'right',
          className: 'projection_highlight',
          render: (text, record, index) => {
            if (record.editable) return renderSum(record.acting_amount * record.unit_price, record, index)
            return renderSum(record.acting_amount * record.offer_unit_price, record, index)
          }
        },
      ]
    },
    {
      title: 'Mahud',
      children: [
        {
          title: 'Pakutud',
          dataIndex: 'offer_amount',
          key: 'offer_amount',
          align: 'right',
          className: 'projection_actual',
          //render: (text, record, index) => text ? text.toFixed(2).toString() : ""
        },
        {
          title: 'Kinnitatud',
          dataIndex: 'actualised_amount',
          key: 'actualised_amount',
          align: 'right',
          className: 'projection_actual',
          //render: (text, record, index) => text ? text.toFixed(2).toString() : ""
        },
        {
          title: 'Järel kinnitada',
          dataIndex: 'to_actualise_amount',
          key: 'to_actualise_amount',
          align: 'right',
          className: 'projection_actual',
          //render: format_end_diff
        },
        {
          title: 'Teostatud',
          dataIndex: 'completed_amount',
          key: 'completed_amount',
          align: 'right',
          className: 'projection_actual',
        },
        {
          title: 'Järel teostada',
          dataIndex: 'left_amount',
          key: 'left_amount',
          align: 'right',
          className: 'projection_actual',
          //render: (text, record, index) => text ? text.toFixed(2).toString() : ""
        },
      ]
    },
    {
      title: 'Summad',
      children: [
        {
          title: 'Pakutud',
          dataIndex: 'offer_sum',
          key: 'offer_sum',
          align: 'right',
          className: 'projection_final',
          render: renderSum
        },
        {
          title: 'Kinnitatud',
          dataIndex: 'actualised_sum',
          key: 'actualised_sum',
          align: 'right',
          className: 'projection_final',
          render: renderSum
        },
        {
          title: 'Järel kinnitada',
          dataIndex: 'to_actualise_sum',
          key: 'to_actualise_sum',
          align: 'right',
          className: 'projection_final',
          render: renderSum
        },
        {
          title: 'Teostatud',
          dataIndex: 'completed_sum',
          key: 'completed_sum',
          align: 'right',
          className: 'projection_final',
          render: renderSum
        },
        {
          title: 'Järel teostada',
          dataIndex: 'left_sum',
          key: 'left_sum',
          align: 'right',
          className: 'projection_final',
          render: renderSum
        },
      ]
    },
  ]

  const searchColumns = ['actualised_amount', 'actualised_sum', 'acting_amount', 'acting_sum', 'art_name', 'completed_amount', 'completed_sum', 'left_amount', 'left_sum', 'offer_amount', 'offer_sum', 'to_actualise_amount', 'to_actualise_sum', 'unit']

  return (
    <div>
      <div className="d-flex justify-content-between  p-2">
        <Row type='flex' style={{ borderRadius: 5, width: '100%' }}>
          <Col span={6}>
            <Search
              value={search}
              onChange={e => setSearch(e.target.value)}
              className="mr-2"
              placeholder="Otsi..."
            />
          </Col>
          <Col span={6}>
          </Col>
          <Col span={6}>
          </Col>
          <Col span={6}>
            <Button style={{ float: 'right' }} onClick={() => exportAct(actId)}>Eelvaade</Button>
          </Col>
        </Row>
      </div>
      <div style={{ overflowX: "auto", background: "#fff" }} className={"p-2 pb-2"}>
        <Table
          loading={loadingOffers}
          footer={footer}
          size="small"
          indentSize={0}
          childrenColumnName={['rows']}
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell,
            },
          }}
          columns={columns}
          onExpandedRowsChange={(rows) => {
            if (!loadingOffers) setExpandedRowKeys(rows)
          }}
          expandedRowKeys={expandedRowKeys}
          defaultExpandAllRowsnpm start
          dataSource={smartSearch(
            filteredDataSource
              .sort((a, b) => a.sortorder - b.sortorder), search, searchColumns)}
          rowKey={(record) => {
            if (record.key) return record.key
            if (record.id) return `${record.offer_id}-${record.id}-row`
            return `${record.offer_nr}-${record.act_offer_id}-${record.art_name}`
          }}
          rowClassName={() => 'editable-row'}
          onRow={(record, rowIndex) => {
            if (record.acting_row_id)
              return {
                style: { background: '#ffffb8' }
              }
          }}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            hideOnSinglePage: true
          }} />
      </div>
    </div>

  )
}


export default ActTable