import moment from "moment";
import {action, computed, thunk} from "easy-peasy";
import API from "../../services/api";

export const productionModel = {

    income: [],

    overviewDateRange: [moment().startOf('month'), moment()],
    setOverviewDateRange: action((state, payload) => state.overviewDateRange = payload),

    overviewDailyReportsData: computed([
        state => state,
        (state, storeState) => storeState], (myState, myStoreState) => {
        console.log("XY:", myState, myStoreState)

        let tableData = [], dates = [], costs = [], income = []

        if (myStoreState && myState && myState.overviewDateRange) {
            costs = (myStoreState.object.costs ? Object.values(myStoreState.object.costs) : [])
                .filter(r => moment(r.cost_date) >= myState.overviewDateRange[0] && moment(r.cost_date) <= myState.overviewDateRange[1])

            income = (myStoreState.production.income ? myStoreState.production.income : [])
                .filter(r => r.apply_general_cost === 1)
                .filter(r => r.apply_general_cost === 1 && moment(r.production_date) >= myState.overviewDateRange[0] && moment(r.production_date) <= myState.overviewDateRange[1])

            // creating tables list
            costs.forEach(cost => !dates.includes(cost.cost_date) ? dates.push(cost.cost_date) : null)
            income.forEach(income => !dates.includes(income.production_date) ? dates.push(income.production_date) : null)

            // this is not dependant on the dates - problematics are shown regardless of the date range picked
            if (myStoreState.warehouse.problematic) myStoreState.warehouse.problematic.forEach(prob => !dates.includes(prob.date) ? dates.push(prob.date) : null)


            // joining costs and incomes
            dates.forEach((date) => {
                let costReportRow = {
                    date: date,
                    costs: costs.reduce((sum, row) => row.cost_date === date ? sum + row.row_sum : sum, 0),
                    income: income.reduce((sum, row) => row.production_date === date ? sum + row.row_sum : sum, 0),
                    result: income.reduce((sum, row) => row.production_date === date ? sum + row.row_sum : sum, 0) -
                        costs.reduce((sum, row) => row.cost_date === date ? sum + row.row_sum : sum, 0),
                    internalProduced: income.reduce((sum, row) => row.production_date === date && row.client_group === "Sisemüük" ? sum + row.amount : sum, 0),
                    externalProduced: income.reduce((sum, row) => row.production_date === date && row.client_group === "Välismüük" ? sum + row.amount : sum, 0),
                    totalProduced: income.reduce((sum, row) => row.production_date === date ? sum + row.amount : sum, 0),
                    problematic: myStoreState.warehouse.problematic ? myStoreState.warehouse.problematic.reduce((sum, row) => row.date === date ? sum + row.count : sum, 0) : 0

                }

                tableData.push(costReportRow)
            })
        }


        return tableData

    }),

    overviewInRange: computed((state, storeState) => {

        console.log('storeState', storeState)

        let totals = {
            internalProduction: {
                amount: 0,
                sum: 0
            },
            externalProduction: {
                amount: 0,
                sum: 0
            },
            totalProduction: {
                amount: 0,
                sum: 0
            }

        }

        if (state.income) {

            let daterange = state.overviewDateRange
            let income = state.income
                .filter(r => r.apply_general_cost === 1)
                .filter(r => moment(r.production_date) >= daterange[0] && moment(r.production_date) <= daterange[1])

            totals = {
                internalProduction: {
                    amount: income.reduce((sum, val) => val.client_group === "Sisemüük" ? sum + val.amount : sum, 0),
                    sum: income.reduce((sum, val) => val.client_group === "Sisemüük" ? sum + val.row_sum : sum, 0),
                },
                externalProduction: {
                    amount: income.reduce((sum, val) => val.client_group === "Välismüük" ? sum + val.amount : sum, 0),
                    sum: income.reduce((sum, val) => val.client_group === "Välismüük" ? sum + val.row_sum : sum, 0),
                },
                totalProduction: {
                    amount: income.reduce((sum, val) => sum + val.amount, 0),
                    sum: income.reduce((sum, val) => sum + val.row_sum, 0),
                }

            }

        }

        return totals

    }),

    overviewActiveDate: computed((state, storeState) => {

        //console.log("storestate", storeState)

        let totals = {
            internal: {
                amount: 0,
                sum: 0
            },
            external: {
                amount: 0,
                sum: 0
            },
            total: {
                amount: 0,
                sum: 0
            }
        }

        if (state.income) {

            let filteredDataSource = state.income
                .filter(r => r.apply_general_cost === 1)
            //.filter(r => moment(r.production_date).format('YYYY-MM-DD') === storeState.object.activeDate.format('YYYY-MM-DD'))

            totals = {
                internal: {
                    amount: filteredDataSource.reduce((sum, val) => val.client_group === "Sisemüük" ? sum + val.amount : sum, 0),
                    sum: filteredDataSource.reduce((sum, val) => val.client_group === "Sisemüük" ? sum + val.row_sum : sum, 0)
                },
                external: {
                    amount: filteredDataSource.reduce((sum, val) => val.client_group === "Välismüük" ? sum + val.amount : sum, 0),
                    sum: filteredDataSource.reduce((sum, val) => val.client_group === "Välismüük" ? sum + val.row_sum : sum, 0)
                },
                total: {
                    amount: filteredDataSource.reduce((sum, val) => sum + val.amount, 0),
                    sum: filteredDataSource.reduce((sum, val) => sum + val.row_sum, 0)
                }
            }
        }
        return totals
    }),

    setIncome: action((state, payload) => state.income = payload),

    apiFetchIncome: thunk(async (actions, payload) => {
        const res = await API("/api/object/production/income", false, "get", payload)
        actions.setIncome(res.data)
    }),

    apiIncomePrefill: thunk(async (actions, payload) => {
        await API("/api/object/production/prefill", false, "get", payload)
        actions.apiFetchIncome(payload)
    }),

    apiUpdateIncome: thunk(async (actions, payload) => {
        await API("/api/object/production/income", false, "post", payload)
        actions.apiFetchIncome(payload)
    }),

    apiAddIncome: thunk(async (actions, payload) => {
        await API("/api/object/production/income", false, "put", payload)
        actions.apiFetchIncome(payload)
    }),

}
