import React, {useEffect, useState} from "react";
import PageWithHeader from '../components/PageWithHeader'
import dateLocale from 'antd/es/date-picker/locale/et_EE'


import {
    ArrowLeftOutlined,
    BorderOutlined,
    CarOutlined,
    ClockCircleOutlined,
    GoogleOutlined,
    LoginOutlined,
    LogoutOutlined,
} from '@ant-design/icons';

import {
    Table,
    Affix,
    Alert,
    Card,
    Tag,
    Modal,
    Button,
    DatePicker,
    Row,
    Col,
    Statistic,
    Select,
    List,
    Progress,
    Popover,
    Timeline,
    Avatar,
} from "antd";
import {Badge} from 'react-bootstrap'
import '../App.css';
import {useStoreActions, useStoreState} from "easy-peasy";
import moment from 'moment'
import {Bar} from 'react-chartjs-2'
import InputCell from "../components/InputCell";
import useInterval from "../services/useInterval";

import {useParams} from "react-router";

const {Option} = Select
const FactoryTabs = () => {

    const [activeTabKey, setActiveTabKey] = useState('Live')

    const tabListNoTitle = [
        {
            key: 'Live',
            tab: 'Live',
        },
        {
            key: 'Toodang',
            tab: 'Toodang',
        },

    ];

    const contentListNoTitle = {
        Live: <FactoryLiveTable/>,
        Toodang: <FactoryLiveChart/>,
    };

    return (
        <Card
            style={{width: '100%'}}
            //title={<div className="m-2"><h4>Tehase LIVE</h4></div>}
            tabList={tabListNoTitle}
            activeTabKey={activeTabKey}
            tabBarExtraContent={<FactoryMenu/>}
            onTabChange={key => setActiveTabKey(key)}
        >
            {contentListNoTitle[activeTabKey]}
        </Card>
    )
}

const FactoryMenu = () => {

    const activeDate = useStoreState(state => state.factory.activeDate);
    const filters = useStoreState(state => state.factory.filters);
    const setFIlters = useStoreActions(actions => actions.factory.setFilters);
    const setDate = useStoreActions(actions => actions.factory.setActiveDate);
    const fetchEvents = useStoreActions(actions => actions.factory.apiFetchScaleEvents);

    useEffect(() => {
        fetchEvents({
            date: activeDate.format("YYYY-MM-DD")
        })
        // eslint-disable-next-line
    }, [activeDate])

    useInterval(() => {
        fetchEvents({
            date: activeDate.format("YYYY-MM-DD")
        })
    }, 20000);


    function handleFilterClick(filter) {

        let current = filters.filter(a => a)

        if (filters.includes(filter)) current = filters.filter(v => v !== filter)
        else current = [filter, ...current]
        setFIlters(current)
    }

    return (
        <div>

            <Button.Group>
                <Button type={filters.includes('in') ? "primary" : null}
                        onClick={() => handleFilterClick('in')}>
                    <span>
                        <i className="fas fa-industry"> </i> <i className="fas fa-arrow-left"> </i> Sisse
                        </span>
                </Button>
                <Button type={filters.includes('out') ? "primary" : null} onClick={() => handleFilterClick('out')}>
                    <span>
                        <i className="fas fa-arrow-right"> </i> <i className="fas fa-industry"> </i> Välja
                        </span>

                </Button>
            </Button.Group>

            <DatePicker
                locale={dateLocale}
                className="ml-2"
                defaultValue={activeDate}
                onChange={setDate}
                format='DD.MM.YYYY'/>

        </div>
    )
}

const FactoryLiveChart = () => {

    const events = useStoreState(state => state.factory.scaleEvents);
    const products = useStoreState(state => state.factory.products);

    //let products = []
    //events.forEach(r => {
    //    if (!products.includes(r.product_name)) products.push(r.product_name)
    //})

    const [activeProduct, setActiveProduct] = useState(products ? products[0] : false)

    let totals = []
    products.forEach((product, index) => totals.push({
        index: index + 1,
        product: product,
        pcs: (events.filter(e => e.product_name === product).reduce((sum, row) => sum + 1, 0)),
        amount: (events.filter(e => e.product_name === product).reduce((sum, row) => sum + row.amount, 0) / 1000).toFixed(3)
    }))

    let filteredEvents = events
    if (activeProduct) filteredEvents = events.filter(e => e.product_name === activeProduct)

    console.log("filteredevents:", filteredEvents)

    const columns = [
        {title: '#', dataIndex: 'index', key: 'index',},
        {
            title: 'Toode', dataIndex: 'product', key: 'product',
            render: (text, record, index) => <div onClick={() => setActiveProduct(text)}><b>{text}</b></div>
        },
        {title: 'Tk', dataIndex: 'pcs', key: 'pcs', align: "right"},
        {title: 'Kogus', dataIndex: 'amount', key: 'amount', align: "right"},
    ]

    const data = {
        labels: filteredEvents.map(a => moment(a.date_in)),
        datasets: [
            {
                label: activeProduct ? activeProduct : "Kogu toodang",
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                barThickness: 10,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: filteredEvents.map(a => a.amount)
            }
        ]
    };

    const options = {
        scales: {
            xAxes: [{
                type: "time",
                distribution: 'linear',
                time: {
                    unit: 'minute',
                    round: 'minute',
                    displayFormats: {
                        minute: 'HH:mm'
                    }
                }
            }],
            // yAxes: [{
            //   ticks: {
            //     beginAtZero: true
            //   }
            // }]
        }
    }


    return (
        <Row gutter={8}>
            <Col span={15}>
                <Bar
                    data={data}
                    width="100%"
                    height="40%"
                    redraw={true}
                    options={options}
                />
            </Col>
            <Col span={9}>
                <Table
                    dataSource={totals}
                    columns={columns}
                    footer={() =>
                        <div className="d-flex justify-content-between">
                            <div onClick={() => setActiveProduct(false)}><b>Kokku</b></div>
                            <div>{events.reduce((sum, b) => sum + b.amount, 0) / 1000}</div>
                        </div>}
                    size="middle"/>
            </Col>
        </Row>)

}

export const FactoryLiveTable = ({obj = false, type = false}) => {

    let events = useStoreState(state => state.factory.scaleEvents);
    const activeDate = useStoreState(state => state.factory.activeDate);
    const fetchEvents = useStoreActions(actions => actions.factory.apiFetchScaleEvents);
    const postEvents = useStoreActions(actions => actions.factory.apiPostScaleEvents);
    const filters = useStoreState(state => state.factory.filters);


    if (obj) events = events.filter(e => e.obj_id === obj)
    if (type) events = events.filter(e => e.is_product_or_material === type)

    events = events.filter(e => filters.includes(e.direction))

    const [activeCell, setActiveCell] = useState(false)

    const handleDirectionChange = async (record, direction) => {
        let payload = {
            id: record.id,
            field: "direction",
            value: direction
        }
        await handleUpdate(payload)
    }

    const handleDelete = async (record) => {
        let payload = {
            id: record.id,
            field: 'status',
            value: 1
        }
        await handleUpdate(payload)

    }

    function getCellReference(index, field) {
        return index.toString() + field
    }

    const handleAmountChange = async (record, amount) => {
        let payload = {
            id: record.id,
            field: 'amount',
            value: amount
        }
        await handleUpdate(payload)
    }

    const handleUpdate = async (payload) => {
        await postEvents(payload)
        await fetchEvents({
            date: activeDate.format("YYYY-MM-DD")
        })
        setActiveCell(false)
    }

    let rows = 0

    const getInputCell = (index, field, rowsCount, record) => <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference(index, field))}
        onBlur={value => handleAmountChange(record, value)}
        onUpdate={value => handleAmountChange(record, value)}
        onMoveUp={(value) => null}
        onMoveDown={(value) => null}
        active={activeCell === getCellReference(index, field)}
        defaultValue={record.amount}/>

    const getSelectCell = (index, valueField, titleField, rowsCount, selectProps, record) => <InputCell
        type="select"
        selectProps={selectProps}
        onClick={() => setActiveCell(getCellReference(index, titleField))}
        onUpdate={value => handleDirectionChange(record, value)}
        active={activeCell === getCellReference(index, titleField)}
        defaultValue={record.direction}
        defaultTitle={record.direction === "in" ?
            <span> <i className="fas fa-industry"> </i> <i className="fas fa-arrow-left"> </i></span>
            :
            <span> <i className="fas fa-industry"> </i> <i className="fas fa-arrow-right"> </i></span>
        }/>

    const columns = [
        //{title: 'id', dataIndex: 'id', key: 'id',},
        {
            title: <ArrowLeftOutlined />, dataIndex: 'direction', key: 'direction',
            //render: (text, record, index) => text === 'in' ?
            //    <div><i className="fas fa-industry"> </i> <i className="fas fa-arrow-left"> </i></div> :
            //    <div><i className="fas fa-industry"></i> <i className="fas fa-arrow-right"> </i></div>,
            render: (text, record, index) => getSelectCell(index, 'direction', 'directionTitle', rows, {
                optionsList: [
                    {
                        label: <span><i className="fas fa-industry"> </i> <i className="fas fa-arrow-left"> </i> Sisse
                        </span>, value: "in"
                    },
                    {
                        label: <span><i className="fas fa-industry"> </i> <i className="fas fa-arrow-right"> </i> Välja
                        </span>, value: "out"
                    }],
                valueField: "value",
                titleField: "label"
            }, record)
        },
        {title: 'Reg. nr', dataIndex: 'regnr', key: 'regnr',},
        {
            title: 'Sisse', dataIndex: 'date_in', key: 'date_in',
            render: (text, record, index) => text ? moment.utc(text).format('HH:mm:ss') : ''
        }
        ,
        {
            title: 'Välja', dataIndex: 'date_out', key: 'date_out',
            render: (text, record, index) => text ? moment.utc(text).format('HH:mm:ss') : ''
        }
        ,
        {
            title: 'Sees', dataIndex: 'date_out', key: 'in',
            render: (text, record, index) => {
                if (record.date_in && record.date_out) {
                    //return (moment(record.date_out) - moment(record.date_in)).humanize()
                    return moment.duration(moment(record.date_out).diff(moment(record.date_in))).locale('et').humanize()
                } else return ''
            }
        },
        {
            title: 'Laotatud', dataIndex: 'date_received', key: 'received',
            render: (text, record, index) => (text) ? moment.utc(text).format('HH:mm:ss') : ''
        },
        {
            title: 'Teel', dataIndex: 'enroute', key: 'enroute',
            render: (text, record, index) => {
                if (record.date_received && record.date_out) {
                    //return (moment(record.date_out) - moment(record.date_in)).humanize()
                    return moment.duration(moment(record.date_received).diff(moment(record.date_out))).locale('et').humanize()
                } else return ''
            }

        }, {
            title: 'Kogus', dataIndex: 'amount', key: 'amount',
            render: (text, record, index) => getInputCell(index, 'amount', rows, record)
        }, {
            title: 'Kus?', dataIndex: 'status', key: 'status',
            render: (text) => {
                if (text === 5) {
                    return <i className="far fa-check-circle" style={{color: "green"}}> </i>
                } else if (text === 4) {
                    return <i className="fas fa-road" style={{color: "black"}}> </i>
                } else if (text === 3) {
                    return <i className="fas fa-flag" style={{color: "orange"}}> </i>
                } else if (text === 1) {
                    return <i className="fas fa-times-circle" style={{color: "red"}}> </i>
                }
            }
        }
        ,
        {title: 'Toode', dataIndex: 'product_name', key: 'product_name',},
        {title: 'Objekt', dataIndex: 'object_name', key: 'object_name',},
        {title: 'Klient', dataIndex: 'client_name', key: 'client_name',},
        {
            // should only be visible, if user position NOT in [6, 4]
            title: <i className="far fa-trash-alt"> </i>, dataIndex: 'client_name', key: 'x',
            render: (text, record, index) => <i className="fas fa-times" onClick={() => handleDelete(record)}> </i>
        }
    ]

    return (
        <Table
            columns={columns}
            dataSource={events}
            //className={"table-striped-rows"}
            size="middle"
            rowKey={"id"}
            rowClassName={(record, index) => {
                if (record.status === "4") {
                    return 'production_warning'
                }
                if (record.direction === "in") {
                    return 'production_incoming'
                }
            }}
            //footer={footer}
            pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                //hideOnSinglePage: true
            }}
        />
    )
}

const Status = ({text}) => {
    if (text === 5) {
        return <h3><i className="far fa-check-circle" style={{color: "green"}}> </i></h3>
    } else if (text === 4) {
        return <h3><i className="fas fa-road" style={{color: "black"}}> </i></h3>
    } else if (text === 3) {
        return <i className="fas fa-flag" style={{color: "orange"}}> </i>
    } else if (text === 1) {
        return <i className="fas fa-times-circle" style={{color: "red"}}> </i>
    }
}

const FactoryLive = () => {

    const events = useStoreState(state => state.factory.scaleEvents);
    const cars = useStoreState(state => state.factory.cars);
    const returning = useStoreState(state => state.factory.carsReturning);
    const avgTimeIn = useStoreState(state => state.factory.avgTimeIn);
    const returnEvents = useStoreState(state => state.factory.returnEvents);
    //console.log("events:", events)

    let produced = events.reduce((sum, val) => val.direction !== 'in' ? sum + val.amount : sum, 0)
    let onTheRoad = events.reduce((sum, val) => val.status === 4 && val.direction !== 'in' ? sum + val.amount : sum, 0)
    let arrived = produced - onTheRoad


    return (
        <PageWithHeader>
            <div className="m-3 p-3">
                <Card className="mb-2" title="Tehas">
                    <Row gutter={16}>
                        <Col span={4}>
                            <Statistic title="Toodetud" value={produced / 1000} precision={0}/>
                        </Col>
                        <Col span={4}>
                            <Statistic title="Teel" value={onTheRoad / 1000} precision={0}/>
                        </Col>
                        <Col span={4}>
                            <Statistic title="Laotatud" value={arrived / 1000} precision={0}/>
                        </Col>
                        <Col span={4}>
                            <Statistic title="Auto tehases keskm." value={avgTimeIn.format("HH:mm:ss")}/>
                        </Col>
                        <Col span={4}>
                            <Statistic title="Teenindab" value={cars ? cars.length : ''}/>
                        </Col>
                        <Col span={4}>
                            <Statistic title="Tagasiteel" value={returning ? returning.length : ''}/>
                        </Col>

                    </Row>
                    {returnEvents.length > 0 ?
                        <Row>
                            <Col>
                                <div className="mt-2">
                                    <FactoryReturning/>
                                </div>
                            </Col>
                        </Row> : null
                    }

                </Card>

                <FactoryTabs/>
            </div>
        </PageWithHeader>
    )
}


export const FactoryStatistics = ({obj = false, appearance = "thick"}) => {

    let events = useStoreState(state => state.factory.scaleEvents);
    if (obj) events = events.filter(e => e.obj_id === obj)

    let produced = events.reduce((sum, val) => val.direction !== 'in' && val.obj_id === obj ? sum + val.amount : sum, 0)
    let onTheRoad = events.reduce((sum, val) => val.status === 4 && val.direction !== 'in' && val.obj_id === obj ? sum + val.amount : sum, 0)
    let arrived = produced - onTheRoad

    if (appearance === 'thick') return <Row gutter={16}>
        <Col span={6}>

        </Col>
        <Col span={4}>
            <Statistic title="Toodetud" value={produced / 1000} precision={0}/>
        </Col>
        <Col span={4}>
            <Statistic title="Teel" value={onTheRoad / 1000} precision={0}/>
        </Col>
        <Col span={4}>
            <Statistic title="Laotatud" value={arrived / 1000} precision={0}/>
        </Col>
    </Row>

    else return <div className="d-flex">
        Toodetud: {produced / 1000}, Teel: {onTheRoad / 1000}, Laotatud: {arrived / 1000}
    </div>

}


export const FactoryLivePaverModal = ({content, isOpen, onClose}) => {

    const postEvents = useStoreActions(actions => actions.factory.apiPostScaleEvents);

    const handleOK = async (item) => {
        await postEvents({
            id: item.id,
            field: 'status',
            value: "5"
        })
        onClose()
    }

    return (<Modal
            visible={isOpen}
            //icon="info-sign"
            onCancel={onClose}
            onOk={() => handleOK(content)}
            title={"Laotamine"}
            //width={"70%"}
            centered
            destroyOnClose={true}
            okText={"Laotatud"}
            cancelText={"Tühista"}
        >
            <div className="mt-4" style={{textAlign: 'center'}}><h2> {content.regnr}</h2></div>
            <div className="mt-4" style={{textAlign: 'center'}}><h4>{content.amount / 1000} Tonni</h4></div>
            <div className="mt-4" style={{textAlign: 'center'}}><h5>{content.product_name}</h5></div>
            <div className="mt-4" style={{textAlign: 'center'}}><Button onClick={() => handleOK(content)} size="large"
                                                                        style={{
                                                                            color: 'white',
                                                                            background: 'green'
                                                                        }}>Laotatud!</Button></div>
        </Modal>

    )
}

export const FactoryLivePaver = () => {

    let {id} = useParams()

    //const obj = useStoreState(state => state.site.paverViewIdobject);
    const obj = parseInt(id)
    const setObj = useStoreActions(actions => actions.site.setPaverViewIdobject);
    const activeDate = useStoreState(state => state.factory.activeDate);
    const setDate = useStoreActions(actions => actions.factory.setActiveDate);
    const fetchEvents = useStoreActions(actions => actions.factory.apiFetchScaleEvents);

    const fetchObjects = useStoreActions(actions => actions.objects.apiLoadObjects);
    const events = useStoreState(state => state.factory.scaleEvents);
    const objects = useStoreState(state => state.objects.objects)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState(true)
    const [showSelect, setShowSelect] = useState(false)

    const [commentMode, setCommentMode] = useState(false)

    //console.log("obj:", obj)

    const objectsSelect = objects
        .map((object) =>
            <Option key={object.idobject}
                    value={object.idobject}
                    searchString={object.shiffer + ' ' + object.name}>
                <div>{object.shiffer} {object.name}</div>
            </Option>
        )

    useEffect(() => {
        fetchEvents({
            date: activeDate.format("YYYY-MM-DD")
        })
        fetchObjects()
        // eslint-disable-next-line
    }, [activeDate])

    useInterval(() => {
        fetchEvents({
            date: activeDate.format("YYYY-MM-DD")
        })
    }, 20000);


    const handleClick = (item) => {
        setModalContent(item)
        setIsModalOpen(true)
    }

    const handleClose = async () => {
        await setIsModalOpen(false)
        await fetchEvents({
            date: activeDate.format("YYYY-MM-DD")
        })
    }

    let object = objects.find(r => r.idobject === obj)
    //console.log("OBJECT: ", object)

    let filteredLoads = []
    if (events && object) filteredLoads = events.filter(event => event.object_shiffer.toUpperCase() === object.shiffer.toUpperCase())

    let produced = filteredLoads.reduce((sum, val) => val.direction !== 'in' ? sum + val.amount : sum, 0) / 1000
    let onTheRoad = filteredLoads.reduce((sum, val) => val.status === 4 && val.direction !== 'in' ? sum + val.amount : sum, 0) / 1000
    let arrived = produced - onTheRoad


    return (
        <PageWithHeader>
            <div className="p-1">
                <FactoryLivePaverModal
                    content={modalContent}
                    isOpen={isModalOpen}
                    onClose={() => handleClose()}
                />
                {object ?

                    <div className="d-flex justify-content-between p-1" style={{background: 'white'}}>
                        <div></div>
                        <DatePicker
                            locale={dateLocale}
                            defaultValue={activeDate}
                            onChange={setDate}
                            format='DD.MM.YYYY'/>

                    </div>

                    :
                    null
                }
                <Affix offsetTop={63}>
                    <Alert
                        type="success"
                        message={
                            <h5>Laotatud: {arrived.toFixed(0)} Teel: {onTheRoad.toFixed(0)} Toodetud: {produced.toFixed(0)}</h5>
                        }/>
                </Affix>

                <Card className="mb-2"
                      title={!showSelect ?
                          <div onClick={() => setShowSelect(true)}>
                              {object ? object.shiffer + ' ' + object.name : 'Vali objekt'}
                              {object ? <small><br/> {object.address}</small> : null}
                          </div>
                          :
                          <Select
                              //mode="multiple"
                              optionLabelProp="label"
                              showSearch
                              //dropdownMatchSelectWidth={false}
                              filterOption={(inputValue, option) => option.props.searchString.toLowerCase().includes(inputValue.toLowerCase())}
                              defaultValue={obj}
                              value={obj}
                              defaultOpen={true}
                              onChange={item => {
                                  setObj(item);
                                  setShowSelect(false)
                              }}
                              //onSearch={val => handleSearch(val)}
                              placeholder="Vali objekt.."
                              className="mr-2"
                              style={{width: '100%'}}
                          >
                              {objectsSelect}
                          </Select>
                      }
                      bordered={false}>


                    <div className="mt-2" style={{display: 'none'}}>

                        <Timeline>
                            <Timeline.Item color="red">
                                <div><h6>Kommentaar</h6>Kommentaarid on liigsed</div>
                            </Timeline.Item>
                            {
                                filteredLoads.map(item =>
                                    <Timeline.Item>
                                        <div onClick={() => handleClick(item)}>

                                            <div style={{
                                                borderStyle: 'none none solid none',
                                                borderWidth: '1px',
                                                borderColor: 'gray'
                                            }}>
                                                <div className="d-flex justify-content-between">
                                                    {commentMode ? <div className="mt-2"><BorderOutlined style={{textSize: '16'}} /></div>
                                                        :
                                                        null
                                                    }
                                                    <div className="">
                                                        <h3><Badge variant="secondary">{item.regnr}</Badge></h3>
                                                        <h5 className=" ml-1">{moment.utc(item.date_out).format('HH:mm')}</h5>
                                                    </div>
                                                    <div className=" ml-2">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h6>{item.product_name}</h6>
                                                            </div>
                                                        </div>
                                                        <div>Teel: {moment.duration(moment.utc(moment().format('YYYY-MM-DD HH:mm:ss')).diff(moment(item.date_out))).locale('et').humanize(false)}</div>
                                                        {
                                                            item.status === 4 ?
                                                                <ETA item={item}/>
                                                                :
                                                                null
                                                        }

                                                    </div>

                                                    <div>
                                                        <div className="">
                                                            <div className="ml-2"><Status text={item.status}/>
                                                            </div>
                                                            {
                                                                item.status === 5 ?
                                                                    <h5 className="ml-1">{moment.utc(item.date_received).format('HH:mm')}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </Timeline.Item>
                                )
                            }


                        </Timeline>
                        <Affix offsetBottom={10}>
                            <Button type="primary" onClick={() => setCommentMode(true)}>Lisa kommentaar</Button>
                        </Affix>

                    </div>

                    <List
                        itemLayout="horizontal"
                        dataSource={filteredLoads}
                        bordered
                        renderItem={item =>
                            <List.Item onClick={() => handleClick(item)}>
                                <List.Item.Meta
                                    avatar={
                                        <div className="mt-2">
                                            <h3><Badge variant="secondary">{item.regnr}</Badge></h3>
                                            <h5 className=" ml-1">{moment.utc(item.date_out).format('HH:mm')}</h5>
                                        </div>
                                    }
                                    title={
                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <div className="mt-2 ml-2">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div>{item.product_name}</div>
                                                        </div>
                                                    </div>
                                                    <div>Teel: {moment.duration(moment.utc(moment().format('YYYY-MM-DD HH:mm:ss')).diff(moment(item.date_out))).locale('et').humanize(false)}</div>
                                                    {
                                                        item.status === 4 ?
                                                            <ETA item={item}/>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                <div>

                                                </div>
                                                <div>
                                                    <div className="mt-2">
                                                        <div className="ml-2"><Status text={item.status}/></div>
                                                        {
                                                            item.status === 5 ?
                                                                <h5 className="ml-1">{moment.utc(item.date_received).format('HH:mm')}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    //description={ }
                                />
                            </List.Item>

                        }
                    />


                </Card>

            </div>
        </PageWithHeader>
    );
}

const ETA = ({item}) => {

    const dateOut = moment.utc(item.date_out)
    //console.log("dateout", dateOut)

    if (item.outgoing_google_estimate) {
        const google_estimate = JSON.parse(item.outgoing_google_estimate)
        const eta = dateOut.add(google_estimate.rows[0].elements[0].duration_in_traffic.value, 'seconds')
        return (
            <div className="mt-1 p-2 rounded border"
                 style={{background: '#bae7ff'}}>Eeldatavalt saabub: {eta.format("HH:mm")}
            </div>)
    }

    return null

}


const BackAtFactory = ({record}) => {

    const departed = moment.utc(record.date_received)
    let duration = 0

    if (record.returning_google_estimate) {
        const google_estimate = JSON.parse(record.returning_google_estimate)
        duration = google_estimate.rows[0].elements[0].duration_in_traffic.value
        let arrival = departed.add(duration, 'seconds')
        return <div><ClockCircleOutlined /> <b>{arrival.format('HH:mm')}</b></div>;
    }

    return '-'

}

const EnRoute = ({record}) => {

    let now = moment().format('YYYY-MM-DD HH:mm:ss') // this makes NOW timezone naive string
    let duration = moment.duration(moment.utc(now).diff(moment(record.date_received)))
    let duration_seconds = duration.as('seconds')

    let duration_humanized = duration.locale('et').humanize()
    if (duration_seconds > 86400) duration_humanized = "-"

    if (record.returning_google_estimate) {
        const google_estimate = JSON.parse(record.returning_google_estimate)
        const duration_eta = google_estimate.rows[0].elements[0].duration.value
        let covered = duration_seconds / duration_eta * 100

        let duration_as_hh_mm = moment.utc(duration.as('milliseconds')).format("HH:mm:ss")
        if (duration_seconds > 86400) duration_as_hh_mm = "- ilmselt kohal"


        return (
            <div style={{width: 150}}>
                <Progress size="small" percent={covered} showInfo={false}/>
                <small> {duration_as_hh_mm} </small>
            </div>
        )
    }
    return duration_humanized
}

const Distance = ({record}) => {

    if (record.returning_google_estimate) {
        const google_estimate = JSON.parse(record.returning_google_estimate)
        const duration_eta = google_estimate.rows[0].elements[0].duration_in_traffic.text
        const distance = google_estimate.rows[0].elements[0].distance.text


        return (
            <Popover placement="bottomLeft"
                     title={<div className="m-2"><h5>Google maps ennustus</h5></div>}
                     content={
                         <div className="p-2">
                             <Timeline>
                                 <Timeline.Item dot={<LogoutOutlined style={{fontSize: '16px'}} />}>
                                     <div className="ml-2"> {moment.utc(record.date_received).format('HH:mm')} </div>
                                     <div className="ml-2">{google_estimate.origin_addresses[0]}</div>
                                 </Timeline.Item>

                                 <Timeline.Item dot={<CarOutlined style={{fontSize: '16px'}} />}>
                                     <div className="ml-2"><b>{distance}</b></div>
                                     <div className={'ml-2'}><b>Sõiduaeg: {duration_eta}</b></div>
                                 </Timeline.Item>
                                 <Timeline.Item dot={<LoginOutlined style={{fontSize: '16px'}} />}>
                                     <div
                                         className="ml-2">{moment.utc(record.date_received).add(google_estimate.rows[0].elements[0].duration_in_traffic.value, 'seconds').format('HH:mm')}</div>
                                     <div className={'ml-2'}>{google_estimate.destination_addresses[0]}</div>
                                 </Timeline.Item>
                             </Timeline>
                         </div>
                     }
                     trigger="click">
                <div><Tag><GoogleOutlined /> info</Tag> {distance} / {duration_eta}</div>
            </Popover>
        );
    }
    return "Prognoosi pole"
}


const FactoryReturning = () => {
    const events = useStoreState(state => state.factory.returnEvents);

    let now = moment().format('YYYY-MM-DD HH:mm:ss') // this makes NOW timezone naive string
    //console.log("now", now)
    const columns = [
        {
            title: 'Reg. nr', dataIndex: 'regnr', key: 'regnr',
            render: (text, record, index) => <Tag> {text} </Tag>
        },
        {
            title: 'Lahkus objektilt', dataIndex: 'date_received', key: 'left',
            render: (text, record, index) => moment.utc(record.date_received).format('HH:mm') + ' ' + record.object_name
        },
        {
            title: 'Tehasesse', dataIndex: 'regnr', key: 'teel',
            render: (text, record, index) => <Distance record={record}/>
        },
        {
            title: 'Teel', dataIndex: 'regnr', key: 'teel',
            render: (text, record, index) => <EnRoute record={record}/>
        },
        {
            title: 'Tehases tagasi', dataIndex: 'regnr', key: 'tagasi',
            render: (text, record, index) => <BackAtFactory record={record}/>
        },

        {
            title: 'Viimane vedu', dataIndex: 'date_received', key: 'load',
            render: (text, record, index) => record.product_name
        },
        {
            title: 'Kogus', dataIndex: 'amount', key: 'amount',
            render: (text, record, index) => text ? (text / 1000).toFixed(3) : 0
        },
    ]
    return (
        <Table dataSource={events}
               columns={columns}
               size="small"
               pagination={false}/>
    )
}

//const FactoryReturning_old = () => { // _18.11.2020
//    const events = useStoreState(state => state.factory.returnEvents);
//
//    let now = moment().format('YYYY-MM-DD HH:mm:ss') // this makes NOW timezone naive string
//    console.log("now", now)
//    const columns = [
//        {
//            title: 'Reg. nr', dataIndex: 'regnr', key: 'regnr',
//            render: (text, record, index) => <Tag> {text} </Tag>
//        },
//        {
//            title: 'Tagasiteel', dataIndex: 'regnr', key: 'teel',
//            render: (text, record, index) => <b>{moment
//                .duration(moment(record.date_received)
//                    .diff(moment.utc(now)))
//                .locale('et')
//                .humanize()}</b>
//        },
//        {
//            title: 'Lahkus objektilt', dataIndex: 'date_received', key: 'left',
//            render: (text, record, index) => moment.utc(record.date_received).format('HH:mm') + ' ' + record.object_name
//        },
//        {
//            title: 'Viimane vedu', dataIndex: 'date_received', key: 'load',
//            render: (text, record, index) => record.product_name
//        },
//        {
//            title: 'Kogus', dataIndex: 'amount', key: 'amount',
//            render: (text, record, index) => text ? (text / 1000).toFixed(3) : 0
//        },
//    ]
//    return (
//        <Table dataSource={events} columns={columns} size="small" pagination={false}/>
//    )
//}

export default FactoryLive
