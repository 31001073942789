import React, {useEffect, useState} from "react";
import { CheckOutlined } from '@ant-design/icons';
import { Select, Input, Table } from "antd";
import {useStoreState, useStoreActions} from "easy-peasy";
import {smartSearch} from "../../../../services/smartSearch";
import InputCell from "../../../InputCell"

const {Search} = Input;
const {Option} = Select;

const PaObjectDayIncomeTable = () => {

    const meta = useStoreState(state => state.object.meta);
    const income = useStoreState(state => state.object.income);
    const incomeOffersList = useStoreState(state => state.object.incomeOffersList);
    const updateIncome = useStoreActions(actions => actions.object.apiUpdateIncome);
    const fetchIncome = useStoreActions(actions => actions.object.apiLoadIncome);
    const editAllowed = useStoreState(state => state.user.editAllowed)

    const objectActiveDate = useStoreState(state => state.object.activeDate);

    const [activeCell, setActiveCell] = useState(false);
    const [activeOffer, setActiveOffer] = useState(false);
    const [search, setSearch] = useState('');

    let filteredDataSource = income
    let rows = filteredDataSource.length

    //let subtitles = filteredDataSource.filter((row) => row.is_subtitle === 1)
    //console.log("Subtitles: ", subtitles)

    function handleChange(value, field, row) {

        console.log("handling change...", value, field, row)
        // if either previous value or new value exist
        if (row[field] || value) {

            let newValueString = value ? value.toString() : false
            let oldValueString = row[field] ? row[field].toString() : false

            if (newValueString !== oldValueString) {
                console.log("ONBLUR/onchange/change detected, updating...")
                row[field] = value
                let payload = {
                    value: value,
                    idoffer: row['idoffer'],
                    date: objectActiveDate.format("YYYY-MM-DD 00:00:00"),
                    obj_id: meta.idobject
                }

                updateIncome(payload).then(() => setActiveCell(false))

            } else setActiveCell(false)
        } else setActiveCell(false)
    }


    function getCellReference(index, field) {
        return editAllowed ? index.toString() + field : null
    }

    useEffect(() => {
        if (meta.idobject) {
            fetchIncome({obj_id: meta.idobject, date: objectActiveDate.format("YYYY-MM-DD 00:00:00")})
        }
        // eslint-disable-next-line
    }, [objectActiveDate])

    useEffect(() => {
        if (incomeOffersList) setActiveOffer(incomeOffersList[0])
        // eslint-disable-next-line
    }, [])

    const getInputCell = (index, field, rowsCount, row) => <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference(index, field))}
        onBlur={value => handleChange(value, field, row)}
        onUpdate={value => handleChange(value, field, row)}
        onMoveUp={(value) => setActiveCell(getCellReference(index > 0 ? index + value : 0, field))}
        onMoveDown={(value) => setActiveCell(getCellReference(index < rowsCount - 1 ? index + value : rowsCount - 1, field))}
        active={activeCell === getCellReference(index, field)}
        defaultValue={row[field]}/>


    const columns = [
        // {title: 'idoffer', dataIndex: 'idoffer', key: 'idoffer',},
        // {title: 'offer_nr', dataIndex: 'offer_nr', key: 'offer_nr'},
        // {title: 'sortorder', dataIndex: 'sortorder', key: 'sortorder',},
        {
            title: 'Bill', dataIndex: 'bill_path', key: 'bill_path', width: 70,
            render: text => text ? text.replaceAll('/', '.') : ''
        },
        {title: 'Art', dataIndex: 'art_id', key: 'art_id',width: 70,},
        {title: 'Nimetus', dataIndex: 'art_name', key: 'art_name', },
        {
            title: 'Täna teostatud',
            children: [

                {
                    title: 'Teostatud kogus',
                    dataIndex: 'fulfilled',
                    key: 'fulfilled',
                    align: 'right',
                    className: 'projection_highlight',
                    width: 80,
                    render: (text, record, index) =>  getInputCell(index, 'fulfilled', rows, record)
                },
                {title: 'Ühik', dataIndex: 'unit', key: 'unit', align: 'right', className: 'projection_highlight',width: 70,},
            ]
        },
        {
            title: 'Lepinguline',
            children: [
                {
                    title: 'Ühiku hind',
                    dataIndex: 'akteerimiseks_unit_price',
                    key: 'akteerimiseks_unit_price',
                    align: 'right',
                    className: 'projection_actual',
                    width: 70,
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""
                },
                {
                    title: 'Summa',
                    dataIndex: 'total_akti_hinnas',
                    key: 'total_akti_hinnas',
                    align: 'right',
                    className: 'projection_actual',
                    width: 70,
                    render: (text, record, index) => text ? "€ " + text.toFixed(2).toString() : ""
                },
            ]
        },
        {
            title: 'Eelarveline',
            children: [
                {
                    title: 'Eesmärk ühiku hind', dataIndex: 'unit_price',
                    key: 'target_unit_price', align: 'right', className: 'projection',
                    width: 76,
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""
                },
                {
                    title: 'Eesmärk summa', dataIndex: 'total',
                    key: 'total_pakkumise_hinnas', align: 'right', className: 'projection',
                    width: 76,
                    render: (text, record, index) => text ? "€ " + text.toFixed(2).toString() : ""
                },]
        },
        {
            title: 'Mahud',
            children: [
                {
                    title: 'Pakutud maht',
                    dataIndex: 'offered',
                    key: 'offered',
                    align: 'right',
                    width: 72,
                    className: 'projection_final',
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""

                },
                {
                    title: 'Kokku teostatud maht',
                    dataIndex: 'fulfilled_amount',
                    key: 'fulfilled_amount',
                    className: 'projection_final',
                    align: 'right',
                    width: 72,
                    render: (text, record, index) => text ? text.toFixed(2) : record.offered ? "0.00" : null
                },
                {
                    title: 'Järel maht',
                    dataIndex: 'remaining_amount',
                    key: 'remaining_amount',
                    align: 'right',
                    width: 72,
                    className: 'projection_final',
                    render: format_end_diff
                },]
        }
    ].filter(({ title }) => (meta.is_small_object && title !== "Eelarveline") || !meta.is_small_object)

    function format_end_diff(text, record, index) {
        return {
            props: {
                className: text < 0 ? 'projection_highlight_bad' : 'projection_highlight_good'

            },
            children: text ?
                <span className="p-1">{text.toFixed(2)}</span>
                :
                record.offered ?
                    <span className="p-1"> 0.00 <CheckOutlined style={{background: '#d9f7be'}} /> </span> : null
        };
    }

    const searchColumns = ['art_id', 'art_name', 'fulfilled', 'unit', 'total', 'total_akti_hinnas', 'akteerimiseks_unit_price', 'offered', 'fulfilled_amount', 'remaining_amount']

    return (
        <div>
            <div className="d-flex justify-content-between  p-2">
                <div>
                    <Select
                        defaultActiveFirstOption={true}
                        value={activeOffer}
                        onChange={v => setActiveOffer(v)}
                        dropdownMatchSelectWidth={false}
                    >
                        {incomeOffersList.map(r => <Option key={r} value={r}>{r}</Option>)}
                    </Select>
                </div>
                <div>
                    <Search
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className="mr-2"
                        placeholder="Otsi..."
                    />
                </div>
            </div>
            <div style={{overflowX: "auto", background: "#fff"}} className={"p-2 pb-2"}>


                <Table
                    columns={columns}
                    dataSource={smartSearch(
                        filteredDataSource
                            .filter(r => r.offer_nr === activeOffer)
                            .sort((a, b) => a.sortorder - b.sortorder), search, searchColumns)}
                    //dataSource={filteredDataSource.filter(r=> r.offer_nr === activeOffer).sort((a, b) => a.sortorder - b.sortorder)}
                    //className={"table-striped-rows"}
                    size="small"
                    rowKey={"idoffer"}
                    onRow={(record, rowIndex) => {
                        if (record.is_highlight === 1)
                            return {
                                style: {background: '#ffffb8'}
                            }
                    }}
                    scroll={{ y: '85vh' }}
                    pagination={{
                        defaultPageSize: 1000,
                        showSizeChanger: false,
                        hideOnSinglePage: true
                    }}/>
            </div>
        </div>

    )
}


export default PaObjectDayIncomeTable