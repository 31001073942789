import {action, thunk} from "easy-peasy";
import API from "../../services/api";

export const analysisModel = {

    costs: [],
    costsV2: [],
    income: [],
    bills: [],
    billsV2: [],
    productionIncome: [],
    targets: [],
    worksheets: [],

    setCosts: action((state, payload) => state.costs = payload),
    setCostsV2: action((state, payload) => state.costsV2 = payload),
    setIncome: action((state, payload) => state.income = payload),
    setBills: action((state, payload) => state.bills = payload),
    setBillsV2: action((state, payload) => state.billsV2 = payload),
    setProductionIncome: action((state, payload) => state.productionIncome = payload),
    setTargets: action((state, payload) => state.targets = payload),
    setWorksheets: action((state, payload) => state.worksheets = payload),

    apiFetchCosts: thunk(async (actions, payload) => {
        const events = await API("/api/object/analyse/costs", false, 'GET', payload)
        actions.setCosts(events.data)
    }),

    apiFetchCostsV2: thunk(async (actions, payload) => {
        const events = await API("/api/object/analyse/costsV2", false, 'GET', payload)
        actions.setCostsV2(events.data)
    }),

    apiFetchIncome: thunk(async (actions, payload) => {
        const events = await API("/api/object/analyse/income", false, 'GET', payload)
        actions.setIncome(events.data)
    }),

    apiFetchProductionIncome: thunk(async (actions, payload) => {
        const events = await API("/api/object/analyse/productionincome", false, 'GET', payload)
        actions.setProductionIncome(events.data)
    }),

    apiFetchBills: thunk(async (actions, payload) => {
        const events = await API("/api/object/analyse/bills", false, 'GET', payload)
        actions.setBills(events.data)
    }),

    apiFetchBillsV2: thunk(async (actions, payload) => {
        const events = await API("/api/object/analyse/billsV2", false, 'GET', payload)
        actions.setBillsV2(events.data)
    }),

    apiFetchTargets: thunk(async (actions, payload) => {
        const events = await API("/api/object/analyse/targets", false, 'GET', payload)
        actions.setTargets(events.data)
    }),

    apiFetchWorksheets: thunk(async (actions, payload) => {
        const events = await API("/api/worksheets", false, 'GET', payload)
        actions.setWorksheets(events.data)
    }),
}
