import {useStoreActions, useStoreState} from "easy-peasy";
import { CopyOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from "antd";
import React from "react";

const PaCostRowMenu = ({idcosts}) => {

    const deleteCostData = useStoreActions(actions => actions.object.apiDeleteCost);
    const copyCostData = useStoreActions(actions => actions.object.apiAddCost);
    const editAllowed = useStoreState(state => state.user.editAllowed)

    const menu = (
        <Menu>

            <Menu.Item key="0" onClick={() => deleteCostData({'idcosts': idcosts})} disabled={!editAllowed}>
                <DeleteOutlined /> Kustuta rida
            </Menu.Item>
            <Menu.Item key="1" onClick={() => copyCostData({'idcosts': idcosts})} disabled={!editAllowed}>
                <CopyOutlined /> Kopeeri rida
            </Menu.Item>
            {/* <Menu.Divider/>
            <Menu.Item key="3">3rd menu item</Menu.Item> */}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <MoreOutlined />
        </Dropdown>
    );
}

export default PaCostRowMenu