
// the component that displays icon and popover for cost row. Records must be single record

import {useState} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import CostDivisionContext from "../../../../CostDivisionContext";
import { PieChartTwoTone } from '@ant-design/icons';
import { Button, Popover, Tag } from "antd";
import React from "react";

import makeAssignment from "../makeAssignment"
import PaCostBillDivisionContent from "./costBillDivisionContent"

const PaCostBillDivision = ({records}) => {

    const [totalAssigned, setTotalAssigned] = useState(0)
    //const baseBudget = useStoreState(state => state.object.budget)
    const assign = useStoreActions(actions => actions.object.apiSetAssignment)
    const apiLoadCostRow = useStoreActions(actions => actions.object.apiLoadCostRow)
    //const apiLoadBudget = useStoreActions(actions => actions.object.apiLoadBudget)
    const meta = useStoreState(state => state.object.meta)
    const editAllowed = useStoreState(state => state.user.editAllowed)

    const [loendid, setLoendid] = useState([])
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const costType = records.cost_type

    //useEffect(() => {
    //    console.log("budget change triggered")
    //    let myBudget = baseBudget
    //        .filter((row) => row.cost_type === costType)
    //        .map((row) => ({
    //            id: row.id,
    //            name: row.bills_types_name,
    //            assigned: 0,  // this will be filled later
    //            special_name: row.art_name
    //        }))
    //    //setLoendid(myBudget)
    //    console.log("BASE BUDGET and ROW BUDGET", baseBudget, myBudget)
    //}, [baseBudget])

    const handleVisibleChange = (value) => setVisible(value)

    const handleSubmit = async () => {
        console.log("recordsxxxx", records)
        await setLoading(true)
        await assign(makeAssignment(loendid, [records]))
        await setVisible(false)

        apiLoadCostRow({objid: meta.idobject, idcosts: records.idcosts})
        //await apiLoadCostRow({idobject: meta.idobject, })
        //await apiLoadBudget(meta.idobject)
        await setLoading(false)
    }

    let color = "#e8e8e8"
    if (records.amount) color = "#f5222d"
    if ('assignments' in records) color = "#7cb305"


    if (records.amount)
        return (
            <CostDivisionContext.Provider value={{totalAssigned, setTotalAssigned, loendid, setLoendid}}>
                <Popover placement="right"
                         visible={visible}
                         onVisibleChange={handleVisibleChange}
                         title={<div className="m-2 d-flex justify-content-between align-content-center">
                             <div>Jaotus billide vahel</div>

                             {totalAssigned === 100 ?
                                 <div>
                                     <Button
                                         disabled={totalAssigned !== 100 || !editAllowed}
                                         onClick={handleSubmit}
                                     >{totalAssigned < 100 ? totalAssigned.toFixed(3) + "%" : "Salvesta"}</Button>
                                 </div>
                                 :
                                 <div className="d-flex align-content-center">
                                     <div className="align-content-center">
                                         <small className="text-muted mr-2">Vajad salvestamiseks 100%</small>
                                         <Tag color="geekblue">{totalAssigned.toFixed(0)} %</Tag>
                                     </div>
                                 </div>
                             }
                         </div>}
                         content={<PaCostBillDivisionContent records={records} loading={loading} costType={costType}/>}
                         trigger="click">
                    <PieChartTwoTone twoToneColor={color} />
                </Popover>
            </CostDivisionContext.Provider>
        );
    else return <PieChartTwoTone twoToneColor={color} />;

}

export default PaCostBillDivision