import {action, thunk} from "easy-peasy";
import moment from 'moment'
import API from "../../services/api";
import useInterval from "../../services/useInterval";

export const roadinfoModel = {

    actions: [],
    events: [],

    stats: {
        events: 0,
        eventsWithActions: 0,
        actions: 0,
        lastEvent: null
    },

    dates: [moment().startOf('day'), moment().endOf('day')], //from and to
    selectedEvents: [],
    selectedAction: null,

    actionsState: {
        loading: true,
        pageSize: 10,
        currentPage: 1,
        total: 200,
        actionFilter: null,

    },

    eventsState: {
        loading: true,
        pageSize: 10,
        currentPage: 1,
        total: 200,
        countyFilter: null,
        search: null
    },

    isActionModalOpen: false,
    isEventModalOpen: false,

    setActionsState: action((state, payload) => state.actionsState = payload),
    setEventsState: action((state, payload) => state.eventsState = payload),
    setActions: action((state, payload) => state.actions = payload),
    setEvents: action((state, payload) => state.events = payload),

    setIsEventModalOpen: action((state, payload) => state.isEventModalOpen = payload),
    setIsActionModalOpen: action((state, payload) => state.isActionModalOpen = payload),

    setSelectedEvents: action((state, payload) => state.selectedEvents = payload),
    setSelectedAction: action((state, payload) => state.selectedAction = payload),

    setDates: action((state, payload) => {
        let start = payload[0].startOf('day')
        let end = payload[1].endOf('day')
        state.dates = [start, end]
    }),
    setStats: action((state, payload) => state.stats = payload),

    apiFetchEvents: thunk(async (actions, payload, {getState}) => {

        let dates = Object.assign({}, getState().dates)

        await actions.setEventsState({...getState().eventsState, loading: true,})

        const myPayload = {
            ...payload,
            'dates': [dates[0].format("YYYY-MM-DD HH:mm:ss"), dates[1].format("YYYY-MM-DD HH:mm:ss")],
            'pageSize': getState().eventsState.pageSize,
            'currentPage': getState().eventsState.currentPage,
            'countyFilter': getState().eventsState.countyFilter,
            'search': getState().eventsState.search,
        }

        const res = await API("/api/roadinfo/events", false, 'get', myPayload)
        await actions.setEvents(res.data.result)
        await actions.setEventsState(
            {
                ...getState().eventsState,
                loading: false,
                total: res.data.totalRows[0].totalRows
            }
        )
    }),

    apiFetchActions: thunk(async (actions, payload, {getState}) => {

        let dates = Object.assign({}, getState().dates)

        let selectedEvents = getState().selectedEvents
        await actions.setActionsState({...getState().actionsState, loading: true,})

        let myPayload = {
            ...payload,
            //'dates': [dates[0].format("YYYY-MM-DD HH:mm:ss"), dates[1].endOf("day").format("YYYY-MM-DD HH:mm:ss") ]
        }

        if (selectedEvents.length === 1) myPayload = {
            ...payload,
            'eventID': selectedEvents[0].id,
            'pageSize': getState().actionsState.pageSize,
            'currentPage': getState().actionsState.currentPage,
            'actionsFilter': getState().actionsState.actionFilter,
        }

        const res = await API("/api/roadinfo/actions", false, 'get', myPayload)
        await actions.setActions(res.data.result)
        await actions.setActionsState(
            {
                ...getState().actionsState,
                loading: false,
                total: res.data.totalRows[0].totalRows
            }
        )
    }),

    apiUpdateEvents: thunk(async (actions, payload) => {
        const res = await API("/api/roadinfo/events", false, 'post', payload)
    }),

    apiUpdateActions: thunk(async (actions, payload) => {
        const res = await API("/api/roadinfo/actions", false, 'post', payload)
    }),

    apiAddEvents: thunk(async (actions, payload) => {
        const res = await API("/api/roadinfo/events", false, 'put', payload)
    }),

    apiAddActions: thunk(async (actions, payload) => {
        const res = await API("/api/roadinfo/actions", false, 'put', payload)
    }),

    apiDeleteEvents: thunk(async (actions, payload) => {
        const res = await API("/api/roadinfo/events", false, 'delete', payload)
    }),

    apiDeleteActions: thunk(async (actions, payload) => {
        const res = await API("/api/roadinfo/actions", false, 'delete', payload)
    }),


}