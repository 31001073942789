import { action, computed, thunk } from "easy-peasy"
import to from 'await-to-js'
import API, { URL } from "../../services/api"
import Cookies from 'js-cookie'
import moment from 'moment'


export const actsModel = {
  items: {},
  list: computed(state => Object.values(state.items)),
  byId: computed(state => id => state.items[id]),

  loading: false,
  error: {},

  // Actions
  setLoading: action((state, loading) => {
    state.loading = loading
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  fetched: action((state, payload) => {
    state.items = payload.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {})
  }),
  fetchedRows: action((state, { payload, id }) => {
    state.byId(id)['rows'] = payload
  }),
  saved: action((state, payload) => {
    state.items[payload.id] = payload
  }),

  // Thunks
  fetch: thunk(async (actions, id) => {
    actions.setLoading(true)
    const { data } = await API(`/api/object/acts?obj_id=${id}`)
    console.log('fetched acts: ', { data })
    actions.fetched(data)
    actions.setLoading(false)
    return data
  }),
  fetchOne: thunk(async (actions, id) => {
    actions.setLoading(true)
    const { data } = await API(`/api/object/acts?id=${id}`)
    console.log('fetched act: ', { data })
    actions.fetched(data)
    actions.setLoading(false)
    return data
  }),
  export: thunk(async (actions, id) => {
    const jwt = Cookies.get('jwt')
    const request = {
      headers: {
        'Authorization': "Bearer " + jwt,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      method: 'GET',
    }
    fetch(`${URL()}/api/object/acts/export?act_id=${id}`, request)
      .then(response => response.blob())
      .then(blob => {
        var file = window.URL.createObjectURL(blob)
        window.location.assign(file)
      })
      .catch(error => console.log(error))
  }),
  update: thunk(async (actions, payload) => {
    const res = await API("/api/object/acts", false, "put", payload)
    actions.fetched(res.data)
  }),
  delete: thunk(async (actions, { id, obj_id }) => {
    await API('/api/object/acts', false, 'delete', { id })
    actions.fetch(obj_id)
  }),
  save: thunk(async (actions, payload) => {
    const { id, ...params } = payload
    console.log('saving act', moment(params.work_start).format('YY-MM-DD hh:mm:ss'))
    actions.setLoading(true)
    if (id) {
      const [error, { data } = {}] = await to(API(`/api/object/acts`, false, 'put', { id, ...params }))
      if (error) {
        actions.setError({ ...error, response: data })
        actions.setLoading(false)
        throw error
      }
      actions.saved(data)
      actions.setLoading(false)
      return data
    } else {
      const [error, { data } = {}] = await to(API(`/api/object/acts`, false, 'post', params))
      if (error) {
        actions.setError({ ...error, response: data })
        actions.setLoading(false)
        throw error
      }
      actions.saved(data)
      actions.setLoading(false)
      return data
    }
  }),

}