import { action, computed, thunk } from 'easy-peasy'
import API, { URL } from '../../services/api'
import Cookies from 'js-cookie'
import fileSaver from '../../utils/fileSaver'
import axios from 'axios'

export const asphaltModel = {
  items: {},
  asphalt_mixtures: {},
  asphalt_unsuitable: {},
  asphalt_extra: {},
  asphalt_processing: {},
  asphalt_samples: {},
  asphalt_works: {},

  list: computed((state) => Object.values(state.items)),
  listExtras: computed((state) => (table) => state[table]),
  byId: computed((state) => (id) => state.items[id]),

  loading: false,
  error: {},

  // Actions
  setLoading: action((state, loading) => {
    state.loading = loading
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  fetched: action((state, payload) => {
    state.items = payload.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {})
  }),
  saved: action((state, payload) => {
    state.items[payload.id] = payload
  }),
  fetchedExtras: action((state, { table, data }) => {
    state[table] = data.reduce((acc, item) => {
      if (!acc) {
        acc = {}
      }
      acc[item.id] = item
      return acc
    }, {})
  }),
  savedExtra: action((state, { table, data }) => {
    if (!state[table]) {
      state[table] = {}
    }
    state[table][data.id] = data
  }),

  // Thunks
  // Fetching reports
  fetch: thunk(async (actions, id) => {
    actions.setLoading(true)
    const { data } = await API(`/api/asphalt?idobject=${id}`)
    if (data) {
      console.log('data GOT', data)
      actions.fetched(data)
      actions.setLoading(false)
      return data
    }
    actions.setLoading(false)
  }),
  // Fetching extras
  fetchExtras: thunk(async (actions, { idreport, table }) => {
    actions.setLoading(true)
    const { data } = await API(`/api/asphalt/extras?idreport=${idreport}&table=${table}`)
    if (data) {
      console.log('extras GOT', data)
      actions.fetchedExtras({ table, data })
      actions.setLoading(false)
      return data
    }
    actions.setLoading(false)
  }),
  // Export
  export: thunk(async (actions, id) => {
    const jwt = Cookies.get('jwt')
    const request = {
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob'
    }
    actions.setLoading(true)
    const { data } = await axios.get(`${URL()}/api/asphalt/export?idreport=${id}`, request)
    if (data) {
      fileSaver(data, 'asfaldipaevik.xlsx')
    }
    actions.setLoading(false)
  }),
  // Reports CRUD
  add: thunk(async (actions, { idobject, iduser }) => {
    const res = await API('/api/asphalt', false, 'post', { idobject, iduser })
    actions.saved(res.data)
  }),
  update: thunk(async (actions, payload) => {
    const res = await API('/api/asphalt', false, 'put', payload)
    actions.saved(res.data)
  }),
  updateGeneral: thunk(async (actions, payload) => {
    const res = await API('/api/asphalt/export', false, 'put', payload)
    actions.saved(res.data)
  }),
  delete: thunk(async (actions, { id, obj_id }) => {
    await API('/api/asphalt', false, 'delete', { id })
    actions.fetch(obj_id)
  }),
  // Extras CRUD
  addExtra: thunk(async (actions, { idreport, table }) => {
    const res = await API('/api/asphalt/extras', false, 'post', { idreport, table })
    actions.savedExtra({ table, data: res?.data })
  }),
  updateExtra: thunk(async (actions, payload) => {
    const res = await API('/api/asphalt/extras', false, 'put', payload)
    actions.savedExtra({ table: payload?.table, data: res?.data })
  }),
  deleteExtra: thunk(async (actions, { id, idreport, table }) => {
    await API('/api/asphalt/extras', false, 'delete', { id, table })
    actions.fetchExtras({ idreport, table })
  })
}
