import React from "react";
import {Table} from "antd";
import {useStoreState} from "easy-peasy";
import moment from "moment";
import {Line} from "react-chartjs-2";


const PaResourceMovementsContent = () => {

    const activeResource = useStoreState(state => state.warehouse.activeResource)
    const costsRaw = useStoreState(state => state.object.costs)
    const incomingRaw = useStoreState(state => state.warehouse.incoming)

    let dates = [], data = []

    if (incomingRaw && costsRaw && activeResource.id) {

        let costs = Object.values(costsRaw).filter(r => r.resource_id === activeResource.id)
        let incoming = incomingRaw.filter(r => r.resource_id === activeResource.id)

        console.log("raw:", costs, incoming)


        costs.forEach(c => !dates.includes(c.cost_date) ? dates.push(c.cost_date) : null)
        incoming.forEach(c => !dates.includes(c.date) ? dates.push(c.date) : null)

        incoming.forEach(r => console.log(moment("Tue, 09 Jun 2020 00:00:00 GMT").format('YYYYMMDD'), moment(r.date).format('YYYYMMDD'), r.amount, moment(r.date).format('YYYYMMDD') === moment("Tue, 09 Jun 2020 00:00:00 GMT").format('YYYYMMDD') ? "yes" : 'no'))

        dates.forEach(d => {

            const incoming_before_date = incoming.reduce((sum, c) => moment(c.date).isBefore(d, 'day') ? sum + c.amount : sum, 0) || 0
            const incoming_at_date = incoming.reduce((sum, c) => moment(c.date).isSame(d, 'day') ? sum + c.amount : sum, 0) || 0
            const costs_before_date = costs.reduce((sum, c) => moment(c.cost_date).isBefore(d, 'day') ? sum + c.amount : sum, 0) || 0
            const costs_at_date = costs.reduce((sum, c) => moment(c.cost_date).isSame(d, 'day') ? sum + c.amount : sum, 0) || 0

            data.push({
                date: d,
                begin: (incoming_before_date - costs_before_date).toFixed(2),
                incoming: incoming_at_date.toFixed(2),
                outgoing: costs_at_date.toFixed(2),
                remaining: ((incoming_before_date - costs_before_date) + incoming_at_date - costs_at_date).toFixed(2)

            })

        })


    }

    const chartData = {

        labels: data.map(v => moment(v.date)),
        datasets: [
            {
                label: 'Jääk',
                backgroundColor: '#ffccc7',
                borderColor: '#ff7875',
                borderWidth: 1,
                hoverBackgroundColor: '#ff7875',
                hoverBorderColor: '#f5222d',
                //hidden: true,
                data: data.map(v => v.remaining)
            }
        ]
    }

    const columns = [
        {
            title: 'Kuupäev',
            dataIndex: 'date',
            defaultSortOrder: 'descend',
            key: 'date',
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
            render: text => moment(text).format('DD.MM.YY')
        },
        {title: 'Algseis', dataIndex: 'begin', key: 'begin', align: 'right',},
        {title: 'Juurde', dataIndex: 'incoming', key: 'incoming', align: 'right',},
        {title: 'Kulu', dataIndex: 'outgoing', key: 'outgoing', align: 'right',},
        {title: 'jääk', dataIndex: 'remaining', key: 'remaining', align: 'right',},
    ]

    return <div>
        <Line
            data={chartData}
            redraw={true}
            //width={'100%'}
            height={120}
            //options={{maintainAspectRatio: false}}
            options={{
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'day',
                            distribution: 'series',
                            displayFormats: {
                                day: 'DD.MM'
                            }
                        }
                    }]
                }
            }}
        />
        <Table dataSource={data} columns={columns}
               scroll={{y: 600}}
               pagination={{
                   defaultPageSize: 1000,
                   showSizeChanger: false,
                   hideOnSinglePage: true
               }}/></div>

}

export default PaResourceMovementsContent