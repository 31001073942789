import React, {useEffect, useState} from "react";
import PageWithHeader from '../../components/PageWithHeader'
import {useParams} from 'react-router'
import {
    Card,
    Input,
} from "antd";
import '../../App.css';
import {useStoreActions, useStoreState} from "easy-peasy";
import {PaCostBudgetTable} from './cost/PaCostBudgetTable'


export const PaCostBudget = ({target = 'target'}) => {

    const [search, setSearch] = useState('')

    let {doc_id} = useParams()

    return (
        <PageWithHeader>
            <div className="m-2 p-2">
                <Card
                    title={<div className="ml-3 mr-3">{doc_id}</div>}
                    extra={<div className="d-flex">
                        <div>
                            <Input
                                placeholder="Otsi..."
                                className="mr-2"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                            />
                        </div>
                    </div>}
                >
                    <div className="m-2" style={{
                        overflowX: "auto",
                        background: "#fff"
                    }}>
                        <PaCostBudgetTable offer_nr={doc_id} search={search}/>
                    </div>
                </Card>
            </div>
        </PageWithHeader>

    )
}

export default PaCostBudget
