import React, {useEffect, useState} from "react";
import { EditOutlined } from '@ant-design/icons';
import { Input, Button, Table } from "antd";
import {useStoreState, useStoreActions} from "easy-peasy";
import moment from "moment";
import {smartSearch} from "../../../../services/smartSearch";
import InputCell from "../../../InputCell"
import ObjectNavigator from "../../../ObjectNavigator"

const {Search} = Input;

const PaObjectDayProductionIncomeTable = () => {

    const meta = useStoreState(state => state.object.meta);
    const income = useStoreState(state => state.production.income);
    const objects = useStoreState(state => state.objects.objects)
    const fetchIncome = useStoreActions(actions => actions.production.apiFetchIncome);
    const updateIncome = useStoreActions(actions => actions.production.apiUpdateIncome);
    const addIncome = useStoreActions(actions => actions.production.apiAddIncome);

    const objectActiveDate = useStoreState(state => state.object.activeDate);

    const [activeCell, setActiveCell] = useState(false);
    const [search, setSearch] = useState('');

    let filteredDataSource = income
    //.filter(r => r.apply_general_cost === 1)
        .filter(r => moment(r.production_date).format('YYYY-MM-DD') === objectActiveDate.format('YYYY-MM-DD'))

    let totals = {
        internal: {
            amount: filteredDataSource ? filteredDataSource.reduce((sum, val) => val.client_group === "Sisemüük" ? sum + val.amount : sum, 0) : 0,
            sum: filteredDataSource ? filteredDataSource.reduce((sum, val) => val.client_group === "Sisemüük" ? sum + val.row_sum : sum, 0) : 0
        },
        external: {
            amount: filteredDataSource ? filteredDataSource.reduce((sum, val) => val.client_group === "Välismüük" ? sum + val.amount : sum, 0) : 0,
            sum: filteredDataSource ? filteredDataSource.reduce((sum, val) => val.client_group === "Välismüük" ? sum + val.row_sum : sum, 0) : 0
        },
        total: {
            amount: filteredDataSource ? filteredDataSource.reduce((sum, val) => sum + val.amount, 0) : 0,
            sum: filteredDataSource ? filteredDataSource.reduce((sum, val) => sum + val.row_sum, 0) : 0
        }
    }

    console.log("production income totals", totals)

    let rows = filteredDataSource.length

    //let subtitles = filteredDataSource.filter((row) => row.is_subtitle === 1)
    //console.log("Subtitles: ", subtitles)

    function handleChange(value, field, row) {

        //console.log("handling change...", value, field, row)
        // if either previous value or new value exist
        if (row[field] || value) {

            let newValueString = value ? value.toString() : false
            let oldValueString = row[field] ? row[field].toString() : false

            if (newValueString !== oldValueString) {
                //console.log("ONBLUR/onchange/change detected, updating...")
                row[field] = value
                let payload = {
                    value: value,
                    id: row['id'],
                    field: field,
                    date: objectActiveDate.format("YYYY-MM-DD 00:00:00"),
                    objid: meta.idobject
                }

                updateIncome(payload).then(() => setActiveCell(false))

            } else setActiveCell(false)
        } else setActiveCell(false)
    }

    function handleObjectChange(value, field, row) {

        const object = objects.find(o => o.idobject === value)
        console.log("object", object, row, value)
        let payload = {
            value: value,
            id: row['id'],
            field: field,
            objectName: object.shiffer + ' ' + object.name,
            date: objectActiveDate.format("YYYY-MM-DD 00:00:00"),
            objid: meta.idobject
        }
        updateIncome(payload).then(() => setActiveCell(false))
    }

    function handleAddRow() {

        addIncome({objid: meta.idobject, date: objectActiveDate.format("YYYY-MM-DD 00:00:00")})
    }


    function getCellReference(index, field) {
        return index.toString() + field
    }

    useEffect(() => {
        if (meta.idobject) {
            fetchIncome({objid: meta.idobject, date: objectActiveDate.format("YYYY-MM-DD 00:00:00")})
        }
         // eslint-disable-next-line
    }, [objectActiveDate])


    const getInputCell = (index, field, rowsCount, row) => <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference(index, field))}
        onBlur={value => handleChange(value, field, row)}
        onUpdate={value => handleChange(value, field, row)}
        onMoveUp={(value) => setActiveCell(getCellReference(index > 0 ? index + value : 0, field))}
        onMoveDown={(value) => setActiveCell(getCellReference(index < rowsCount - 1 ? index + value : rowsCount - 1, field))}
        active={activeCell === getCellReference(index, field)}
        defaultValue={row[field]}/>

    const getSelectCell = (index, valueField, titleField, title, rowsCount, selectProps, record) => <InputCell
        type="select"
        selectProps={selectProps}
        onClick={() => setActiveCell(getCellReference(index, titleField))}
        onUpdate={value => handleChange(value, valueField, record)}
        active={activeCell === getCellReference(index, titleField)}
        defaultValue={record[valueField]}
        defaultTitle={title}
    />

    const getObjectSelector = (index, text, record) => {
        if (activeCell === getCellReference(index, 'dest_object_name')) {
            return <ObjectNavigator onSelect={value => handleObjectChange(value, 'dest_object_id', record)}/>
        }
        return (
            <div onClick={() => setActiveCell(getCellReference(index, 'dest_object_name'))}>
                {text ? null : <small className="text-muted"><EditOutlined /></small>} {text}</div>
        );
    }


    const columns = [
        //{title: 'id', dataIndex: 'id', key: 'id',},
        {
            title: 'Kood', dataIndex: 'product_id', key: 'product_id',
            render: (text, record, index) => getInputCell(index, 'product_id', rows, record)

        },
        {
            title: 'Kliendigrupp', dataIndex: 'client_group', key: 'client_group',
            //render: (text, record, index) => text
            render: (text, record, index) => getSelectCell(index, 'client_group', 'client_group', '', rows, {
                optionsList: [{"client_group": 'Sisemüük'}, {"client_group": 'Välismüük'}],
                valueField: "client_group",
                titleField: "client_group"
            }, record)
        },

        //  {
        //      title: 'Toodang?', dataIndex: 'apply_general_cost', key: 'apply_general_cost',
        //      render: (text, record, index) => getSelectCell(index, 'apply_general_cost', 'apply_general_cost', record.apply_general_cost === 1 ? "Jah" : "Ei", 0, {
        //          optionsList: [
        //              {value: 1, title: "Jah"},
        //              {value: 0, title: "Ei"},
        //          ],
        //          valueField: "value",
        //          titleField: "title"
        //      }, record)
        //  },
        {
            title: 'Segu mark', dataIndex: 'product_name', key: 'product_name',
            render: (text, record, index) => getInputCell(index, 'product_name', rows, record)
        }, {
            title: 'Kliendi nimi', dataIndex: 'client_name', key: 'client_name',
            render: (text, record, index) => getInputCell(index, 'client_name', rows, record)
        },
        {
            title: 'Objekt', dataIndex: 'dest_object_name', key: 'dest_object_name',
            render: (text, record, index) =>
                record.client_group === 'Välismüük' ?
                    getInputCell(index, 'dest_object_name', rows, record)
                    :
                    getObjectSelector(index, text, record)
        },
        {
            title: 'Kogus', dataIndex: 'amount', key: 'amount', align: 'right',
            render: (text, record, index) => getInputCell(index, 'amount', rows, record)
        },

        {
            title: 'Koormaid', dataIndex: 'load_amount', key: 'load_amount', align: 'right',
            render: (text, record, index) => getInputCell(index, 'load_amount', rows, record)
        },
        {
            title: 'Hind', dataIndex: 'unit_price', key: 'unit_price', align: 'right',
            render: (text, record, index) => getInputCell(index, 'unit_price', rows, record)
        },
        {
            title: 'Ühik', dataIndex: 'amount', key: 'unit', align: 'right',
            render: (text, record, index) => "Tonn"
        },
        {
            title: 'Summa', dataIndex: 'row_sum', key: 'row_sum', align: 'right',
            render: (text, record, index) => text ? "€ " + text.toFixed(2).toString() : ""
        }
    ]

    const searchColumns = ['product_id', 'apply_general_cost', 'product_name', 'client_name', 'dest_object_name', 'amount', 'load_amount', 'unit_price', 'row_sum']

    return (
        <div>

            <div className="d-flex justify-content-between p-2">
                <div>
                    <h5>Toodetud asfaltbetoonsegu müük</h5>
                </div>
                <div className="d-flex">
                    <Search
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className="mr-2"
                        placeholder="Otsi..."
                    />
                    <Button className="ml-2" onClick={() => handleAddRow()}> Lisa rida </Button>
                </div>
            </div>
            <div style={{overflowX: "auto", background: "#fff"}} className={"p-2 pb-2"}>

                <Table
                    columns={columns}
                    dataSource={smartSearch(filteredDataSource, search, searchColumns)}
                    className={"table-striped-rows"}
                    size="middle"
                    rowKey={"id"}
                    onRow={(record, rowIndex) => {
                        if (record.is_highlight === 1)
                            return {
                                style: {background: '#ffffb8'}
                            }
                    }}
                    footer={() =>
                        <div className="d-flex justify-content-between">
                            <div>Sisemüük kokku: {parseFloat(totals.internal.amount).toFixed(2)} T /
                                € {totals.internal.sum.toFixed(2)} Välismüük
                                kokku: {parseFloat(totals.external.amount).toFixed(2)} T /
                                € {totals.external.sum.toFixed(2)}
                            </div>
                            <div> Müük kokku: {parseFloat(totals.total.amount).toFixed(2)} T /
                                € {totals.total.sum.toFixed(2)}
                            </div>
                        </div>
                    }
                    pagination={{
                        defaultPageSize: 50,
                        showSizeChanger: true,
                        hideOnSinglePage: true
                    }}/>
            </div>
        </div>

    )
}

export default PaObjectDayProductionIncomeTable