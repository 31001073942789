import React from "react";
import {useEffect, useState} from "react";
import { CheckOutlined } from '@ant-design/icons';
import { Select, Input, Table } from "antd";
import {useStoreState, useStoreActions} from "easy-peasy";
import {smartSearch} from "../../../services/smartSearch";
import InputCell from "../../InputCell"

const {Search} = Input;
const {Option} = Select;


export const PaIncomePrognosisTableV2 = () => {

    const meta = useStoreState(state => state.object.meta);
    const income = useStoreState(state => state.object.income);
    const incomeOffersList = useStoreState(state => state.object.incomeOffersList);
    const updateOffer = useStoreActions(actions => actions.offer.apiUpdateOffer);
    const fetchIncome = useStoreActions(actions => actions.object.apiLoadIncome);
    const editAllowed = useStoreState(state => state.user.editAllowed)

    const fetchResultAndPrognosis = useStoreActions(actions => actions.resultV2.apiFetchResult)
    const fetchObjectsTotals = useStoreActions(actions => actions.resultV2.apiFetchObjectsTotals)

    const objectActiveDate = useStoreState(state => state.object.activeDate);

    const [activeCell, setActiveCell] = useState(false);
    const [activeOffer, setActiveOffer] = useState(false);
    const [search, setSearch] = useState('');

    let filteredDataSource = income
    let rows = filteredDataSource.length

    //let subtitles = filteredDataSource.filter((row) => row.is_subtitle === 1)
    //console.log("Subtitles: ", subtitles)

    async function handleChange(value, field, row) {

        console.log("handling change...", value, field, row)
        // if either previous value or new value exist
        if (row[field] || value) {

            let newValueString = value ? value.toString() : false
            let oldValueString = row[field] ? row[field].toString() : false

            if (newValueString !== oldValueString) {
                //console.log("ONBLUR/onchange/change detected, updating...")
                row[field] = value
                let payload = {
                    value: value,
                    field: field,
                    idoffer: row['idoffer'],

                }

                const res = await updateOffer(payload)
                setActiveCell(false)
                await fetchIncome({obj_id: meta.idobject, date: objectActiveDate.format("YYYY-MM-DD 00:00:00")})
                fetchResultAndPrognosis({obj_id: meta.idobject})
                fetchObjectsTotals({objects: meta.idobject})

            } else setActiveCell(false)
        } else setActiveCell(false)
    }


    function getCellReference(index, field) {
        return editAllowed ? index.toString() + field : null
    }

    useEffect(() => {
        if (meta.idobject) {
            fetchIncome({obj_id: meta.idobject, date: objectActiveDate.format("YYYY-MM-DD 00:00:00")})
        }
        // eslint-disable-next-line
    }, [objectActiveDate])

    useEffect(() => {
        if (incomeOffersList) setActiveOffer(incomeOffersList[0])
        // eslint-disable-next-line
    }, [])

    const getInputCell = (index, field, rowsCount, row) => {

        if (row && row.hinnapakkumise_amount + row.hinnapakkumise_unit_price + row.fulfilled_amount > 0) return <InputCell
            type="input"
            onClick={() => setActiveCell(getCellReference(index, field))}
            onBlur={value => handleChange(value, field, row)}
            onUpdate={value => handleChange(value, field, row)}
            onMoveUp={(value) => setActiveCell(getCellReference(index > 0 ? index + value : 0, field))}
            onMoveDown={(value) => setActiveCell(getCellReference(index < rowsCount - 1 ? index + value : rowsCount - 1, field))}
            active={activeCell === getCellReference(index, field)}
            defaultValue={row[field]}/>

        return row[field]

    }


    const columns = [
        // {title: 'idoffer', dataIndex: 'idoffer', key: 'idoffer',},
        // {title: 'offer_nr', dataIndex: 'offer_nr', key: 'offer_nr'},
        // {title: 'sortorder', dataIndex: 'sortorder', key: 'sortorder',},
        {title: '', dataIndex: 'bill_path', key: 'bill_path',},
        {title: 'Art', dataIndex: 'art_id', key: 'art_id',},
        {title: 'Nimetus', dataIndex: 'art_name', key: 'art_name',},

        {
            title: 'Leping',
            children: [
                {
                    title: 'Ühikuid',
                    dataIndex: 'hinnapakkumise_amount',
                    key: 'hinnapakkumise_amount',
                    align: 'right',
                    className: 'projection_actual',
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""
                },
                {
                    title: 'Ühiku hind',
                    dataIndex: 'hinnapakkumise_unit_price',
                    key: 'hinnapakkumise_unit_price',
                    align: 'right',
                    className: 'projection_actual',
                    render: (text, record, index) => text ? text.toFixed(2).toString() : ""
                },
                {
                    title: 'Summa',
                    dataIndex: 'hinnapakkumise_total',
                    key: 'hinnapakkumise_total',
                    align: 'right',
                    className: 'projection_actual',
                    render: (text, record, index) => text ? "€ " + text.toFixed(2).toString() : ""
                },
            ]
        },


        {
            title: 'Teostatud',
            children: [

                {
                    title: 'Ühikuid',
                    dataIndex: 'fulfilled_amount',
                    key: 'fulfilled_amount',
                    className: 'projection',
                    align: 'right',
                    render: (text, record, index) => text ? text.toFixed(2) : ""
                },
                {
                    title: 'Summa',
                    dataIndex: 'fulfilled_income_total',
                    key: 'fulfilled_income_total',
                    className: 'projection',
                    align: 'right',
                    render: (text, record, index) => text ? "€ " + text.toFixed(2) : ""
                },
            ]
        },
        {
            title: 'Järel prognoosist',
            children: [
                {
                    title: 'Maht',
                    dataIndex: 'remaining_amount',
                    key: 'remaining_amount',
                    align: 'right',
                    className: 'projection_final',
                    //render: (text, record, index) => text ? text.toFixed(2) : ""
                    render:  (text, record, index) => format_end_diff(record.prognosis_amount - record.fulfilled_amount, record, index)
                },
                {
                    title: 'Summa',
                    dataIndex: 'remaining_amount',
                    key: 'remaining_total',
                    align: 'right',
                    className: 'projection_final',
                    render: (text, record, index) => text ? "€ " + (record.prognosis_total - record.fulfilled_income_total).toFixed(2) : ""
                },
            ]
        },
        {
            title: 'Lõpp-prognoos',
            children: [
                {
                    title: 'Maht',
                    dataIndex: 'prognosis_amount',
                    key: 'prognosis_amount',
                    align: 'right',
                    className: 'projection_actual',
                    render: (text, record, index) => getInputCell(index, 'prognosis_amount', rows, record)

                },
                {
                    title: 'Ühiku hind',
                    dataIndex: 'prognosis_unit_price',
                    key: 'prognosis_unit_price',
                    align: 'right',
                    className: 'projection_actual',
                    render: (text, record, index) => getInputCell(index, 'prognosis_unit_price', rows, record)
                },
                {
                    title: 'Summa',
                    dataIndex: 'prognosis_total',
                    key: 'prognosis_total',
                    align: 'right',
                    className: 'projection_actual',
                    render: (text, record, index) => text ? "€ " + text.toFixed(2) : ""
                },
            ]
        }
    ]


    function format_end_diff(text, record, index) {
        return {
            props: {
                className: text < 0 ? 'projection_highlight_bad' : 'projection_highlight_good'

            },
            children: text ?
                <span className="p-1">{text.toFixed(2)}</span>
                :
                record.offered ?
                    <span className="p-1"> 0.00 <CheckOutlined style={{background: '#d9f7be'}} /> </span> : null
        };
    }

    const searchColumns = ['bill_path', 'art_id', 'art_name', 'hinnapakkumise_amount', 'hinnapakkumise_unit_price', 'hinnapakkumise_total', 'fulfilled_amount', 'fulfilled_income_total', 'remaining_amount', 'prognosis_amount', 'prognosis_unit_price', 'prognosis_total']

    return (
        <div>
            <div className="d-flex justify-content-between  p-2">
                <div>
                    <Select
                        defaultActiveFirstOption={true}
                        value={activeOffer}
                        onChange={v => setActiveOffer(v)}
                        dropdownMatchSelectWidth={false}
                    >
                        {incomeOffersList.map(r => <Option key={r} value={r}>{r}</Option>)}
                    </Select>
                </div>
                <div>
                    <Search
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className="mr-2"
                        placeholder="Otsi..."
                    />
                </div>
            </div>
            <div style={{overflowX: "auto", background: "#fff"}} className={"p-2 pb-2"}>


                <Table
                    columns={columns}
                    dataSource={smartSearch(
                        filteredDataSource
                            .filter(r => r.offer_nr === activeOffer)
                            .sort((a, b) => a.sortorder - b.sortorder), search, searchColumns)}
                    //dataSource={filteredDataSource.filter(r=> r.offer_nr === activeOffer).sort((a, b) => a.sortorder - b.sortorder)}
                    //className={"table-striped-rows"}
                    size="small"
                    rowKey={"idoffer"}
                    onRow={(record, rowIndex) => {
                        if (record.is_highlight === 1)
                            return {
                                style: {background: '#ffffb8'}
                            }
                    }}
                    //scroll={{ y: 600}}
                    pagination={{
                        defaultPageSize: 50,
                        showSizeChanger: true,
                        hideOnSinglePage: true
                    }}/>
            </div>
        </div>

    )

}

export default PaIncomePrognosisTableV2