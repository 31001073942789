import React, {useCallback, useEffect, useState} from "react";

import PageWithHeader from '../components/PageWithHeader'

import {DeleteOutlined, DeleteFilled, UserOutlined, ToolOutlined, EditOutlined} from '@ant-design/icons';

import {
    Table,
    Button,
    Input,
    Card,
    Typography,
    Badge,
    Row,
    Col,
    Statistic,
    DatePicker,
    Avatar,
    Empty,
    Modal, Select, TimePicker
} from "antd";
import '../App.css';
import moment from "moment"
import {withRouter} from "react-router";
import {useStoreActions, useStoreState} from "easy-peasy";

import dateLocale from "antd/es/date-picker/locale/et_EE";
import {useFormik} from "formik";
import PaRangeSelect from "../components/RangeSelect"
import useInterval from "../services/useInterval";

const {Search} = Input;
const {Title} = Typography;
const {TextArea} = Input;
const ButtonGroup = Button.Group;
const {RangePicker} = DatePicker;
const {Option} = Select;
const InputGroup = Input.Group;

const Roadinfo = () => {

    const fetchEvents = useStoreActions(actions => actions.roadInfo.apiFetchEvents);
    const fetchActions = useStoreActions(actions => actions.roadInfo.apiFetchActions);
    const selectedEvents = useStoreState(state => state.roadInfo.selectedEvents);
    const dates = useStoreState(state => state.roadInfo.dates);

    const mode = useStoreState(state => state.site.mode)

    const setDates = useStoreActions(actions => actions.roadInfo.setDates);

    async function handleDateChange(e) {
        await setDates(e)
        fetchEvents()
        fetchActions()
    }


    useEffect(() => {
        (async () => {
            await fetchEvents()
        })()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        (async () => {
            if (selectedEvents && selectedEvents.length === 1) {
                console.log(selectedEvents[0].id)
                await fetchActions({eventID: [selectedEvents[0].id]})
            }
        })()
        // eslint-disable-next-line
    }, [selectedEvents]);

    useInterval(() => {
        fetchEvents()
        fetchActions()
    }, 60000);

    const data = null

    return (
        <PageWithHeader>

                <div>
                    <div className="m-2">
                        <Card title={<div>Teeinfo</div>}
                              bordered={false}
                              extra={
                                  <div>
                                      <InputGroup>
                                          <Button onClick={() => {
                                              fetchEvents()
                                              fetchActions()
                                          }}>Värskenda</Button>

                                          <RangePicker
                                              locale={dateLocale}
                                              value={dates}
                                              style={{width: '250px'}}
                                              format='DD.MM.YY'
                                              onChange={handleDateChange}
                                          />
                                          <PaRangeSelect onSelect={handleDateChange}/>
                                      </InputGroup>
                                  </div>
                              }      // todo: new base bill add logic
                        >
                            {data ? <RoadinfoStatistics/> : null}
                            <div className="m-1" style={{
                                "overflowX": "auto",
                                "background": "#fff"
                            }}>
                                <RoadInfoFilters/>
                                <RoadinfoEvents/>

                            </div>
                        </Card>
                    </div>

                    <div className="m-2">
                        <Card title={<div>Tegevused</div>}
                              bordered={false}
                            //extra={<Button onClick={() => null}>Lisa uus bill</Button>}      // todo: new base bill add logic
                        >
                            <div className="m-1" style={{
                                "overflowX": "auto",
                                "background": "#fff"
                            }}>
                                <RoadinfoActions/>
                            </div>
                        </Card>
                    </div>
                </div>


        </PageWithHeader>
    )
}

export const RoadInfoFilters = () => {
    const eventsState = useStoreState(state => state.roadInfo.eventsState);
    const actionsState = useStoreState(state => state.roadInfo.actionsState);
    const setEventsState = useStoreActions(actions => actions.roadInfo.setEventsState);
    const setActionsState = useStoreActions(actions => actions.roadInfo.setActionsState);
    const fetchEvents = useStoreActions(actions => actions.roadInfo.apiFetchEvents);
    const fetchActions = useStoreActions(actions => actions.roadInfo.apiFetchActions);

    async function handleSearch(e) {
        console.log(e.target.value)
        const myEventState = {...eventsState}
        await setEventsState({
            ...myEventState,
            search: e.target.value
        })
        await fetchEvents()
    }

    async function handleCountySelect(value) {
        setEventsState({...eventsState, countyFilter: value})
        await fetchEvents()
    }

    async function handleActionSelect(value) {
        setActionsState({...actionsState, actionFilter: value})
        await fetchActions()
    }

    return (
        <div className="d-flex justify-content-between">
            <div className="d-flex">
                <div className="mr-2">
                    <Select placeholder="Maakond"
                            dropdownMatchSelectWidth={false}
                            onSelect={e => handleCountySelect(e)}
                            onClear={e => handleCountySelect(null)}
                            allowClear>

                        <option key={1} value={"Keila - Verston"}>Keila - Verston</option>
                        <option key={2} value={"Keila - Keskkonnateenused"}>Keila - Keskkonnateenused</option>
                        <option key={3} value={"Lääne-Harju"}>Lääne-Harju</option>
                        <option key={4} value={"Pärnu"}>Pärnu</option>
                        <option key={5} value={"Lääne-Viru"}>Lääne-Viru</option>

                        <option key={6} value={"Viru"}>Viru</option>
                        <option key={7} value={"Saare"}>Saare</option>
                        <option key={8} value={"Tartu"}>Tartu</option>
                        <option key={9} value={"Võru"}>Võru</option>
                    </Select>
                </div>
                <div>
                    <Select placeholder="Tegevused"
                            onSelect={e => handleActionSelect(e)}
                            onClear={e => handleActionSelect(null)}
                            dropdownMatchSelectWidth={false}
                            allowClear>

                        <option key={1} value={1}>Sahkamine</option>
                        <option key={2} value={2}>Puistamine</option>
                        <option key={3} value={3}>Sahkamine + puistamine</option>
                        <option key={4} value={4}>Liivatamine</option>
                        <option key={5} value={5}>Infoks</option>
                        <option key={6} value={6}>Patrullile info kontrollimiseks</option>
                    </Select>
                </div>
            </div>
            <div>
                <Input placeholder="Otsi..." onChange={handleSearch} onPressEnter={handleSearch}/>
            </div>

        </div>
    )
}

export const RoadinfoStatistics = ({obj = false, appearance = "thick"}) => {

    return <Row gutter={16}>
        <Col span={4}>
            <Statistic title="Sündmuseid" value={30} precision={0}/>
        </Col>
        <Col span={4}>
            <Statistic title="Reageeritud" value={20} precision={0}/>
        </Col>
        <Col span={4}>
            <Statistic title="Tegevusi" value={3} precision={0}/>
        </Col>
        <Col span={4}>
            <Statistic title="Viimane sündmus" value={moment().format("HH:mm:ss")}/>
        </Col>
        <Col span={8}>
            <Statistic title="Ilm" value={6}/>
        </Col>

    </Row>

}

export const RoadinfoEvents = () => {

    const events = useStoreState(state => state.roadInfo.events);
    const eventsState = useStoreState(state => state.roadInfo.eventsState);

    const selectedEvent = useStoreState(state => state.roadInfo.selectedEvents);
    const deleteEvents = useStoreActions(actions => actions.roadInfo.apiDeleteEvents);
    const updateEvents = useStoreActions(actions => actions.roadInfo.apiUpdateEvents);
    const fetchEvents = useStoreActions(actions => actions.roadInfo.apiFetchEvents);
    const setSelectedEvents = useStoreActions(actions => actions.roadInfo.setSelectedEvents);
    const setEventsState = useStoreActions(actions => actions.roadInfo.setEventsState);

    const isModalOpen = useStoreState(state => state.roadInfo.isEventModalOpen);
    const setIsModalOpen = useStoreActions(actions => actions.roadInfo.setIsEventModalOpen)

    const [editedEvent, setEditedEvent] = useState(false)

    function handleAddAction() {
    }

    async function handleDelete(record) {
        await deleteEvents({id: [record.id]})
        await fetchEvents()
    }

    async function handleFinish(record) {
        await updateEvents({...record, endDate: moment()})
        await fetchEvents()
    }

    async function handleTableChange(pagination, filters, sorter) {
        const myEventState = {...eventsState}
        await setEventsState({
            ...myEventState,
            pageSize: pagination.pageSize,
            currentPage: pagination.current,
        })
        await fetchEvents()
    }

    const columns = [
        {
            title: 'ID',
            key: "id",
            dataIndex: "id"
        },
        {
            title: 'Kuupäev',
            key: "date",
            dataIndex: "date",
            render: (text, record, index) => text ? moment(text).format("dd DD.MM.YY") : ''
        },
        {
            title: 'Lisas',
            key: "addedIduser",
            dataIndex: "addedIduser",
            render: (text, record) => <div className="d-flex align-items-center">
                <Avatar
                    size="small"
                    style={{
                        backgroundColor: stringToColor(record.user_username),
                    }}
                    icon={<UserOutlined/>}
                />
                <div className="ml-2">
                    <div>{record.user_name} {record.user_surname}</div>
                    <div> {record.user_username}</div>
                </div>
            </div>

        },
        {
            title: 'Maakond',
            key: "countyIdentifier",
            dataIndex: "countyIdentifier",
            //render: (text, record, index) => getInputCell(index, 'name', 10, record)
        },
        {
            title: 'Sündmus',
            key: "event",
            dataIndex: "event",
            render: (text, record, index) => <div>
                <div onClick={() => {
                    setSelectedEvents([record]);
                }}>
                    <b>{text}</b>
                </div>
                <div>{record.notes}</div>
            </div>
        },
        {
            title: 'Alustatud',
            key: "beginDate",
            dataIndex: "beginDate",
            render: (text, record, index) => text ? moment(text).format("HH:mm") : ''
        },
        {
            title: 'Lõpetatud',
            key: "endDate",
            dataIndex: "endDate",
            render: (text, record, index) => text ?
                moment(text).format("HH:mm")
                :
                <Button onClick={() => handleFinish(record)}>Lõpeta</Button>
        },
        {
            title: 'Aeg',
            key: "name",
            dataIndex: "name",
            render: (text, record, index) => record.endDate ? moment.duration(moment(record.endDate).diff(moment(record.beginDate))).humanize() : ''
        },

        //{
        //    title: '',
        //    key: "status",
        //    dataIndex: "status",
        //    //render: text => text === 3 ? <Badge color="green" text="Kasutusel"/> :
        //    //    <Badge color="gray" text="Arhiveeritud"/>
        //
        //},
        {
            title: 'Tegevusi',
            key: "actionsCount",
            dataIndex: "actionsCount",
            render: text => text > 0 ? <Badge color="green" text={text}/> :
                <div className="d-flex align-items-center"><Badge color="gray" text={text}/></div>

        },
        {
            title: '',
            key: "actions",
            dataIndex: "id",
            render: (text, record) => <div className="d-flex">

                {record.actionsCount === 0 ? // && !record.endDate ?
                    <Button onClick={() => handleDelete(record)}><DeleteFilled/></Button> : null}
                <Button onClick={() => {
                    setSelectedEvents([record]);
                    setIsModalOpen(true)
                }}>
                    <EditOutlined/>
                </Button>

            </div>
        },
    ]

    return (
        <div className="mt-2">
            <RoadinfoAddEventModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                modalData={selectedEvent ? selectedEvent[0] : null}
            />
            <Table columns={columns}
                   dataSource={events}
                   loading={eventsState.loading}
                   pagination={{
                       pageSize: eventsState.pageSize,
                       showSizeChanger: true,
                       hideOnSinglePage: false,
                       current: eventsState.currentPage,
                       total: eventsState.total
                   }}
                   size="small"
                   onChange={handleTableChange}
                   footer={() => <div>
                       <Button className="ml-2" onClick={() => {
                           setSelectedEvents([]);
                           setIsModalOpen(true)
                       }} type="primary">Lisa uus sündmus</Button>
                   </div>}
                   rowClassName={(record) => selectedEvent.length === 1 && selectedEvent[0].id === record.id ? "selected" : null}
                   rowKey="id"/>
        </div>
    )


}

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const RoadinfoActions = ({obj = false, appearance = "thick"}) => {

    const selectedEvents = useStoreState(state => state.roadInfo.selectedEvents);
    const actions = useStoreState(state => state.roadInfo.actions);
    const actionsState = useStoreState(state => state.roadInfo.actionsState);

    const setIsModalOpen = useStoreActions(actions => actions.roadInfo.setIsActionModalOpen);
    const setSelectedAction = useStoreActions(actions => actions.roadInfo.setSelectedAction);
    const fetchActions = useStoreActions(actions => actions.roadInfo.apiFetchActions);
    const updateActions = useStoreActions(actions => actions.roadInfo.apiUpdateActions);
    const deleteActions = useStoreActions(actions => actions.roadInfo.apiDeleteActions);

    async function handleDelete(record) {
        await deleteActions({id: record.id})
        await fetchActions()
    }

    async function handleFinish(record) {
        await updateActions({...record, endDate: moment()})
        await fetchActions()
    }

    const columns = [
        {
            title: 'ID',
            key: "id",
            dataIndex: "id"
        },
        {
            title: 'Kuupäev',
            key: "date",
            dataIndex: "date",
            render: (text, record, index) => text ? moment(text).format("dd DD.MM.YY") : ''

        },
        {
            title: 'Lisas',
            key: "addedIduser",
            dataIndex: "addedIduser",
            render: (text, record) => <div className="d-flex align-items-center">
                <Avatar
                    size="small"
                    style={{
                        backgroundColor: () => stringToColor(record.user_username),
                    }}
                    icon={<UserOutlined/>}
                />
                <div className="ml-2">
                    <div>{record.user_name} {record.user_surname}</div>
                    <div> {record.user_username}</div>
                </div>
            </div>

        },
        {
            title: 'Tegevus',
            key: "actionTypeName",
            dataIndex: "actionTypeName",

        },
        {
            title: 'Selgitus',
            key: "action",
            dataIndex: "action",
            render: (text, record, index) => <div onClick={() => {
                setSelectedAction(record);
                setIsModalOpen(true)
            }}>
                <b>{text}</b>
            </div>
        },
        {
            title: 'Alustatud',
            key: "beginDate",
            dataIndex: "beginDate",
            render: (text, record, index) => text ? moment(text).format("HH:mm") : ''
            //render: (text, record, index) => getInputCell(index, 'name', 10, record)
        },
        {
            title: 'Lõpetatud',
            key: "endDate",
            dataIndex: "endDate",
            render: (text, record, index) => text ?
                moment(text).format("HH:mm")
                :
                <Button onClick={() => handleFinish(record)}>Lõpeta</Button>
        },
        {
            title: 'Aeg',
            key: "name",
            dataIndex: "name",
            render: (text, record, index) => record.endDate ? moment.duration(moment(record.endDate).diff(moment(record.beginDate))).humanize() : ''

        },

        //{
        //    title: '',
        //    key: "status",
        //    dataIndex: "status",
        //    //render: text => text === 3 ? <Badge color="green" text="Kasutusel"/> :
        //    //    <Badge color="gray" text="Arhiveeritud"/>
        //
        //},
        {
            title: 'Tegevused',
            key: "actions",
            dataIndex: "id",
            render: (text, record) => <div>

                {record.endDate ? null : null}
               <Button onClick={() => handleDelete(record)}><DeleteFilled/></Button>

                <Button onClick={() => {
                    setSelectedAction(record);
                    setIsModalOpen(true)
                }}>
                    <EditOutlined/>
                </Button>
            </div>
        },
    ]

    const handleTableChange = (pagination, filters, sorter) => console.log(pagination, filters, sorter)

    return (
        <div className="mt-2">
            <RoadinfoAddActionModal/>
            {selectedEvents.length === 0 ?
                <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{height: 80}}
                    description={<div><b>Vali mõni sündmus, et näha selle tegevusi</b></div>}
                >
                </Empty>
                :

                actions.length === 0 ?
                    <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{height: 80}}
                        description={<div>
                            <div><b>Siin ei ole veel tegevusi</b></div>
                            <div className="mt-2">
                                <Button onClick={() => setIsModalOpen(true)}>Lisa tegevus</Button>
                            </div>
                        </div>}
                    > </Empty>
                    :
                    <Table columns={columns}
                           size="small"
                           dataSource={actions}
                           pagination={{
                               pageSize: actionsState.pageSize,
                               showSizeChanger: true,
                               hideOnSinglePage: false,
                               current: actionsState.currentPage,
                               total: actionsState.total
                           }}
                           loading={actionsState.loading}
                           onChange={handleTableChange}
                           footer={() => <div>
                               <Button className="ml-2" onClick={() => {
                                   setSelectedAction(null);
                                   setIsModalOpen(true)
                               }} type="primary">Lisa uus tegevus</Button>
                           </div>}
                           rowKey="id"/>

            }

        </div>
    )


}

const RoadinfoAddEventModal = () => {

    const divisions = useStoreState(state => state.divisions.divisions)
    const updateEvents = useStoreActions(actions => actions.roadInfo.apiUpdateEvents);
    const addEvents = useStoreActions(actions => actions.roadInfo.apiAddEvents);
    const fetchEvents = useStoreActions(actions => actions.roadInfo.apiFetchEvents);

    const amIOpen = useStoreState(state => state.roadInfo.isEventModalOpen);
    const setAmIOpen = useStoreActions(actions => actions.roadInfo.setIsEventModalOpen);
    const setSelectedEvents = useStoreActions(actions => actions.roadInfo.setSelectedEvents);

    const selectedEvents = useStoreState(state => state.roadInfo.selectedEvents);

    let modalData = {
        id: null,
        countyIdentifier: ' ',
        divisionID: "Lääne-Harju",
        event: ' ',
        notes: ' ',

    }

    if (selectedEvents.length === 1) modalData = {...selectedEvents[0]}

    let formik = useFormik({

        enableReinitialize: true,

        initialValues: {
            id: modalData['id'],
            countyIdentifier: modalData['countyIdentifier'],
            divisionID: modalData['divisionID'],
            event: modalData['event'],
            notes: modalData['notes'],
            //beginDate: modalData['beginDate'], //? modalData['beginDate'].format("HH:mm") : null,
            //endDate: modalData['endDate'],
        },

        // eslint-disable-next-line
        onSubmit: useCallback(async (values) => {

            console.log("values", values)

            //if (values.beginDate) values.beginDate = values.beginDate.format("dd.mm.YYYY HH:mm")
            //if (values.endDate) values.endDate = values.endDate.format("dd.mm.YYYY HH:mm")


            if (values.id) {
                await updateEvents(values)
                await fetchEvents()
                setAmIOpen(false)
                setSelectedEvents([])
            } else {
                await addEvents(values)
                await fetchEvents()
                setAmIOpen(false)
                setSelectedEvents([])
            }
        })
    })

    const handleSubmit = () => formik.submitForm()

    return (
        <Modal
            visible={amIOpen}
            //icon="info-sign"
            onCancel={() => setAmIOpen(false)}
            onOk={handleSubmit}
            title={"Sündmus"}
            width={"70%"}
            destroyOnClose={true}
            okText={'OK'}
            cancelText={'Tühista'}
            footer={[
                <Button type="primary" onClick={handleSubmit}>OK</Button>,
                <Button onClick={() => setAmIOpen(false)}>Tühista</Button>
            ]}
        >
            <div>
                <form onSubmit={formik.handleSubmit} className="container">
                    <div className="row">
                        <div className="col-md-6 mb-3">

                            <label htmlFor="event">Sündmus</label>
                            <Input type="text"
                                   id="event"
                                   name="event"
                                   onChange={formik.handleChange}
                                   value={formik.values.event}
                                   placeholder="Lisa siia sündmus"
                                   required/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="notes">Märkmed</label>
                            <TextArea
                                name="notes"
                                value={formik.values.notes}
                                onChange={formik.handleChange}
                                placeholder="Lisa siia märkmed"
                                autoSize={{minRows: 3, maxRows: 5}}
                            />
                            <div id="invfeedback1" className="invalid-feedback">
                                Palun sisesta märkmed
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        { 0 ===1 ? <div className="col-md-3 mb-3">
                            <label htmlFor="notes">Algus</label>
                            <TimePicker
                                defaultValue={moment(formik.values.beginDate)}
                                format={'HH:mm'}
                            />
                            <TextArea
                                name="startDate"
                                value={formik.values.beginDate}
                                onChange={formik.handleChange}
                                placeholder="algusaeg"
                                autoSize={{minRows: 3, maxRows: 5}}
                            />
                        </div> : null }
                        {formik.values.endDate && 0===1 ?
                            <div className="col-md-3 mb-3">
                                <label htmlFor="notes">Lõpp</label>
                                <TimePicker
                                    defaultValue={moment(formik.values.endDate)}
                                    format={'HH:mm'}
                                />
                                <TextArea
                                    name="endDate"
                                    value={formik.values.endDate}
                                    onChange={formik.handleChange}
                                    placeholder="lõppaeg"
                                    autoSize={{minRows: 3, maxRows: 5}}
                                />
                            </div>
                            :
                            null
                        }
                    </div>

                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <label htmlFor="phone">Maakond</label>
                            <Select id="countyIdentifier"
                                    name="countyIdentifier"
                                    showSearch
                                    optionFilterProp="children"
                                    value={formik.values.countyIdentifier}
                                    onChange={v => formik.setFieldValue('countyIdentifier', v)}
                                    required>



                                <option key={3} value={"Lääne-Harju"}>Lääne-Harju</option>
                                <option key={4} value={"Pärnu"}>Pärnu</option>
                                <option key={6} value={"Lääne-Viru"}>Lääne-Viru</option>


                                <option key={7} value={"Saare"}>Saare</option>
                                <option key={8} value={"Tartu"}>Tartu</option>
                                <option key={9} value={"Võru"}>Võru</option>

                                <option key={1} value={"Keila - Verston"} disabled>Keila - Verston</option>
                                <option key={2} value={"Keila - Keskkonnateenused"} disabled>Keila - Keskkonnateenused</option>
                                <option key={5} value={"Viru"} disabled>Viru</option>
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <label htmlFor="divisionID">Üksus</label>
                            <Select id="divisionID"
                                    name="divisionID"
                                    showSearch
                                    optionFilterProp="children"
                                    value={formik.values.divisionID}
                                    onChange={v => formik.setFieldValue('divisionID', v)}
                                    required>
                                {divisions.map((x, y) => <option
                                    key={x.id}
                                    value={x.id}

                                >{x.external_id} {x.name}</option>)}
                            </Select>
                        </div>
                    </div>

                </form>
            </div>
        </Modal>
    )
}

const RoadinfoAddActionModal = () => {

    const divisions = useStoreState(state => state.divisions.divisions)
    const updateActions = useStoreActions(actions => actions.roadInfo.apiUpdateActions);
    const addActions = useStoreActions(actions => actions.roadInfo.apiAddActions);
    const fetchActions = useStoreActions(actions => actions.roadInfo.apiFetchActions);

    const amIOpen = useStoreState(state => state.roadInfo.isActionModalOpen);
    const setAmIOpen = useStoreActions(actions => actions.roadInfo.setIsActionModalOpen);
    const setSelectedAction = useStoreActions(actions => actions.roadInfo.setSelectedAction);

    const selectedEvents = useStoreState(state => state.roadInfo.selectedEvents);
    const selectedAction = useStoreState(state => state.roadInfo.selectedAction);

    let modalData = {
        id: null,
        eventID: null,
        actionTypeID: null,
        action: null,
        //notes: null,

    }

    if (selectedEvents.length === 1) modalData.eventID = selectedEvents[0].id
    if (selectedAction) modalData = {...selectedAction}

    let formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: modalData.id,
            eventID: modalData.eventID,
            actionTypeID: modalData.actionTypeID,
            action: modalData.action,
            //notes: modalData.notes,

        },
        // eslint-disable-next-line
        onSubmit: useCallback(async (values) => {

            //console.log("values", values)
            if (values.id) await updateActions(values)
            else await addActions(values)

            await fetchActions()
            setAmIOpen(false)
            setSelectedAction(null)

        })
    })

    const handleSubmit = () => formik.submitForm()

    return (
        <Modal
            visible={amIOpen}
            //icon="info-sign"
            onCancel={() => setAmIOpen(false)}
            onOk={handleSubmit}
            title={"Tegevus"}
            width={"70%"}
            destroyOnClose={true}
            okText={'OK'}
            cancelText={'Tühista'}
            footer={[
                <Button type="primary" onClick={handleSubmit}>OK</Button>,
                <Button onClick={() => setAmIOpen(false)}>Tühista</Button>
            ]}
        >
            <div>


                <form onSubmit={formik.handleSubmit} className="container">


                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="action">Märkmed</label>
                            <TextArea
                                name="action"
                                value={formik.values.action}
                                onChange={formik.handleChange}
                                placeholder="Lisa siia tegevus"
                                autoSize={{minRows: 3, maxRows: 5}}
                            />
                            <div id="invfeedback1" className="invalid-feedback">
                                Palun sisesta märkmed
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <label htmlFor="actionTypeID">Tegevuse tüüp</label>
                            <Select id="actionTypeID"
                                    name="actionTypeID"
                                    showSearch
                                    optionFilterProp="children"
                                    value={formik.values.actionTypeID}
                                    onChange={v => formik.setFieldValue('actionTypeID', v)}
                                    required>
                                <option key={1} value={1}>Sahkamine</option>
                                <option key={2} value={2}>Puistamine</option>
                                <option key={3} value={3}>Sahkamine + puistamine</option>
                                <option key={4} value={4}>Liivatamine</option>
                                <option key={5} value={5}>Infoks</option>
                                <option key={6} value={6}>Patrullile info kontrollimiseks</option>
                            </Select>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}


export default withRouter(Roadinfo)
