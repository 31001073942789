import moment from "moment"
import { action, actionOn, computed, thunk } from "easy-peasy"
import API from "../../services/api"

export const controlModel = {

  activeTab: "ehitus",
  costs: [],
  productionWh: [],
  selected: [],
  selectedProduction: [],

  // filter specific state
  filterTabs: [4, 3, 5, 7],
  selectedDates: [moment().subtract(2, "months"), moment()],
  selectedObjects: [0],


  // loading states
  isConstructionLoading: true,
  isProductionLoading: true,
  isClearToLoad: false,
  isFailedToLoad: false,

  setIsConstructionLoading: action((state, payload) => state.isConstructionLoading = payload),
  setIsProductionLoading: action((state, payload) => state.isProductionLoading = payload),

  setFailedToLoad: action((state, payload) => state.isFailedToLoad = payload),
  setActiveTab: action((state, payload) => state.activeTab = payload),
  setCosts: action((state, payload) => state.costs = payload),
  setProductionWh: action((state, payload) => state.productionWh = payload),
  setSelected: action((state, payload) => state.selected = payload),
  setSelectedProduction: action((state, payload) => state.selectedProduction = payload),

  // filter setting
  setFilterTabs: action((state, payload) => state.filterTabs = payload),
  setSelectedDates: action((state, payload) => state.selectedDates = payload),
  setSelectedObjects: action((state, payload) => state.selectedObjects = payload),


  // when we have successfully received prefs from users.setMe
  onSetMe: actionOn((actions, storeActions) => storeActions.user.setMe, (state, target) => {
    const prefs = target.payload.prefs
    console.log("onsetme", prefs)
    if (prefs && prefs.control) {
      if (prefs.control.filterTabs) state.filterTabs = prefs.control.filterTabs
      if (prefs.control.selectedDates) state.selectedDates = [moment(prefs.control.selectedDates[0]), moment(prefs.control.selectedDates[1])]
      if (prefs.control.selectedObjects) state.selectedObjects = prefs.control.selectedObjects
    }

    state.isClearToLoad = true
  }),

  changeDisabled: computed(state => {
    if (state.activeTab === 'tootmine' && state.selectedProduction.length > 0) return false
    if (state.activeTab === 'ehitus' && state.selected.length > 0) return false
    return true
  }),


  apiFetchCosts: thunk(async (actions, payload) => {
    await actions.setFailedToLoad(false)
    const costs = await API("/api/control/costs", false, 'get', payload)
    if (costs.status) await actions.setFailedToLoad(true)
    actions.setCosts(costs.data)
  }),

  apiFetchProductionWh: thunk(async (actions, payload) => {
    const res = await API("/api/control/production", false, 'get', payload)
    actions.setProductionWh(res.data)
  }),

  apiUpdateCosts: thunk(async (actions, payload, { getState }) => {
    const costs = await API("/api/control/costs", false, 'post', payload)
    const currentState = getState()

    //console.log("state", currentState)
    const currentCosts = currentState.costs.map(v => v)

    Object.keys(costs.data).forEach(key => {
      let index = currentCosts.findIndex(v => v.idcosts.toString() === key)
      currentCosts[index] = costs.data[key]
      //console.log(key, currentCosts[index], index)
    })

    //console.log(currentCosts, costs.data)
    actions.setCosts(currentCosts)
  }),

  apiUpdateProductionWh: thunk(async (actions, payload, { getState }) => {
    const costs = await API("/api/control/production", false, 'post', payload)
    const currentState = getState()

    console.log("state", currentState)
    const currentCosts = currentState.productionWh.map(v => v)

    Object.keys(costs.data).forEach(key => {
      let index = currentCosts.findIndex(v => v.id.toString() === key)
      currentCosts[index] = costs.data[key]
      console.log(key, currentCosts[index], index)
    })

    console.log(currentCosts, costs.data)

    //actions.setCosts([])
    actions.setProductionWh(currentCosts)
  }),
  cleanCosts: thunk(async (actions, emptyIds) => {
    console.log(emptyIds)
    const res = await API(`/api/object/costs/clean?empty_ids=${emptyIds ? emptyIds.map(id => `${id}`) : ''}`, false, 'delete')
    return res
  }),
  moveCosts: thunk(async (actions, { costIds, targetId }) => {
    console.log(targetId)
    if (costIds && targetId) {
      const res = await API(`/api/object/costs/move?ids=${costIds ? costIds.map(id => `${id}`) : ''}&target_id=${targetId}`, false, 'put')
      return res
    }
  })
}