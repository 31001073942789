// the component that displays icon and popover for cost row. Records must be single record

import {useState} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import { PieChartTwoTone } from '@ant-design/icons';
import { Button, Popover, Tag, Tooltip } from "antd";
import React from "react";

import makeAssignment from "../makeAssignmentV2"
import PaCostBillDivisionContent from "../v2/costBillDivisionContent"

const PaCostBillDivisionV2 = ({records}) => {

    const assign = useStoreActions(actions => actions.object.apiSetAssignment)
    const apiLoadCostRow = useStoreActions(actions => actions.object.apiLoadCostRow)
    const meta = useStoreState(state => state.object.meta)
    const editAllowed = useStoreState(state => state.user.editAllowed)
    const assignmentsTotal = useStoreState(state => state.object.assignmentsInProgressTotal)

    const assignments = useStoreState(state => state.object.assignmentsCurrentList)
    const setAssignmentsActiveCostRows = useStoreActions(actions => actions.object.setAssignmentsActiveCostRows)

    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const costType = records.cost_type

    const handleVisibleChange = (value) => setVisible(value)

    const handleSubmit = async () => {

        await setLoading(true)
        await assign(makeAssignment(assignments, [records]))
        await setVisible(false)

        apiLoadCostRow({objid: meta.idobject, idcosts: records.idcosts})
        await setLoading(false)
    }

    let color = "#e8e8e8"
    let title = ""
    if (records.amount) color = "#f5222d"
    if (records.amount) title = "Billid on määramata!"
    if ('assignments' in records) color = "#7cb305"
    if ('assignments' in records) title = "Billid on määratud"


    if (records.amount)
        return (
            <Popover placement="right"
                     visible={visible}
                     onVisibleChange={handleVisibleChange}
                     title={<div className="m-2 d-flex justify-content-between align-content-center">
                         <div>Jaotus billide vahel v2</div>

                         {assignmentsTotal === 100 ?
                             <div>
                                 <Button
                                     disabled={assignmentsTotal !== 100 || !editAllowed}
                                     onClick={handleSubmit}
                                 >{assignmentsTotal < 100 ? assignmentsTotal.toFixed(3) + "%" : "Salvesta"}</Button>
                             </div>
                             :
                             <div className="d-flex align-content-center">
                                 <div className="align-content-center">
                                     <small className="text-muted mr-2">Vajad salvestamiseks 100%</small>

                                 </div>
                             </div>
                         }
                     </div>}
                     trigger="click"
                     content={<PaCostBillDivisionContent records={records} loading={loading} costType={costType}/>}
            >
                <Tooltip title={title}><PieChartTwoTone
                    twoToneColor={color}
                    onClick={() => !visible ? setAssignmentsActiveCostRows([records]) : null} /></Tooltip>
            </Popover>
        );
    else return <Tooltip title={title}><PieChartTwoTone twoToneColor={color} /></Tooltip>;

}

export default PaCostBillDivisionV2