export function smartSearch(list, searchString, columns) {

    if (!searchString) searchString = ''

    let search = searchString.split(' ')
    let result = list
    //console.log(search)

    function checkMatch(row, bit) {
        const res = columns.some(col => {
            if (typeof row[col] === "string" || typeof row[col] === "number") {
                return row[col].toString().toLowerCase().includes(bit.toLowerCase())
            }
            return false
        })
        //console.log("res", res)
        return res
    }

    if (searchString.length > 0) {
        search.forEach(bit => {
            result = result.filter(row => checkMatch(row, bit))
        })
    }

    return result

}

