import {action, thunk} from "easy-peasy";
import API from "../../services/api";

export const offersModel = {
    allOffers: [],         // list of all offers

    // actions
    setAllOffers: action((state, payload) => state.allOffers = payload),
    getAssigned: action((state, objId) => state.allOffers.filter(row => row.obj_id === objId)),
    getUnAssigned: action((state) => state.allOffers.filter(row => row.obj_id === 0)),

    // thunks
    apiLoadOffers: thunk(async (actions) => {
        const res = await API("/api/offers")
        actions.setAllOffers(res.data)
    }),

    apiSetOfferTargetStatus: thunk(async (actions, payload) => {
        await API("/api/offers", false, "POST", payload)
    }),

    apiAddOffer: thunk(async (actions, payload) => {
        const data = await API("/api/offers", false, "PUT", payload)
        return data
    }),

    apiDeleteOffers: thunk(async (actions, payload) => {
        await API("/api/offers", false, "DELETE", payload)
        console.log("delete offer", payload)
    }),
}