import React, {useEffect, useState} from 'react'
import { FileExcelOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Card, Button, DatePicker, Select } from "antd";
import {useStoreActions, useStoreState} from "easy-peasy";
import moment from 'moment'
import PivotTableUI from 'react-pivottable/PivotTableUI';
import PivotTable from 'react-pivottable/PivotTable';
import {URL} from '../../../services/api'

const {RangePicker} = DatePicker;
const {Option} = Select

const TotalsTrial = () => {

    const meta = useStoreState(state => state.object.meta)
    const costs = useStoreState(state => state.analysis.costs)
    const income = useStoreState(state => state.analysis.income)
    //const bills = useStoreState(state => state.analysis.bills)
    const targets = useStoreState(state => state.analysis.targets)

    let costsTotal = costs ? costs.reduce((acc, curr) => acc + curr['Rea summa'], 0) : 0
    let incomeTotal = costs ? income.reduce((acc, curr) => acc + curr['Summa (akteeritavas hinnas)'], 0) : 0

    let target = {
        offered: targets ? targets.reduce((acc, curr) => acc + curr['hinnapakkumise_total'], 0) : 0,
        act: targets ? targets.reduce((acc, curr) => acc + curr['akteerimiseks_total'], 0) : 0,
        target: targets ? targets.reduce((acc, curr) => acc + curr['offered_total'], 0) : 0,
    }

    target['generalCost'] = target.offered * meta.overhead_rate

    return (
        <div className="d-flex">
            <div className="border m-2 p-2">Eesmärk<br/>
                Hinnap:{target.offered}<br/>
                Akt:{target.act}<br/>
                Eesmärk:{target.target} <br/>
                üldkulu: {target.generalCost}

            </div>
            <div className="border m-2 p-2">Tegelik<br/>
                kulud: {costsTotal} <br/>
                kulud koos üldkuludega {costsTotal + (incomeTotal * meta.overhead_rate)}<br/>
                tulud: {incomeTotal} <br/>
            </div>
        </div>)
}

const PaObjectPivotTable = () => {

    const objects = useStoreState(state => state.objects.objects)

    const meta = useStoreState(state => state.object.meta)
    const fetchCosts = useStoreActions(actions => actions.analysis.apiFetchCosts)
    const fetchCostsV2 = useStoreActions(actions => actions.analysis.apiFetchCostsV2)
    const fetchIncome = useStoreActions(actions => actions.analysis.apiFetchIncome)
    const fetchBills = useStoreActions(actions => actions.analysis.apiFetchBills)
    const fetchBillsV2 = useStoreActions(actions => actions.analysis.apiFetchBillsV2)
    const fetchTargets = useStoreActions(actions => actions.analysis.apiFetchTargets)
    const mode = useStoreState(state => state.site.mode)

    const [activeTabKey, setActiveTabKey] = useState('kulud')
    const [isTableCompact, setTableCompact] = useState(false)

    const [selectedObjects, setSelectedObjects] = useState([meta.idobject])
    const [selectedDates, setSelectedDates] = useState([moment('2020-01-01'), moment()])

    const tabList = [
        {
            key: 'kulud',
            tab: 'Kulud',
        },
        {
            key: 'tulud',
            tab: 'Tulud',
        }, {
            key: 'billid',
            tab: 'Kulud / billid',
        },
    ];

    const contentList = {
        kulud: <PaAnalysisCosts isTableCompact={isTableCompact}/>,
        tulud: <PaAnalysisIncome isTableCompact={isTableCompact}/>,
        billid: meta && meta.costing_version === 2 ? <PaAnalysisBillsV2 isTableCompact={isTableCompact}/> :
            <PaAnalysisBills isTableCompact={isTableCompact}/>
    };

    async function fetchExcelData(tab, dates) {

        let payload = {
            dates: dates.map(d => d.format("YYYY-MM-DD 00:00:00")),
            objects: selectedObjects,
            tab: tab
        }

        // this works, but no way of passing headers
        window.location.href = URL() + '/api/object/analyse/excel?' + new URLSearchParams(payload).toString()

    }

    const objectsSelect = [];
    objects.forEach((object) => {
        objectsSelect.push(
            <Option key={object.idobject}
                    value={object.idobject}
                    label={object.shiffer}>
                {object.shiffer} {object.name}
            </Option>);
    })

    async function handleDateChange(e) {
        await setSelectedDates(e)
        await console.log(e)
        handleUpdate(e, selectedObjects)
    }

    async function handleObjectsChange(e) {
        await setSelectedObjects(e)
        await console.log(e)
        handleUpdate(selectedDates, e)
    }

    async function handleUpdate(dates, objects) {
        dates[1].add(1, 'days')
        let payload = {
            dates: dates.map(d => d.format("YYYY-MM-DD 00:00:00")),
            objects: objects,
        }
        console.log("payload", payload)
        fetchCosts(payload)
        //fetchCostsV2(payload)
        fetchIncome(payload)
        fetchBills(payload)
        fetchBillsV2(payload)
        fetchTargets(payload)
    }


    const newWindow = () => {
        window.open("/object/" + meta.idobject + "/5")
    }

    useEffect(() => {
        handleUpdate(selectedDates, selectedObjects)
        // eslint-disable-next-line
    }, [])

    const menu =
        <div>
            <Button className="mr-2" onClick={() => handleUpdate(selectedDates, selectedObjects)}>Värskenda andmed</Button>
            <Button className="mr-2" onClick={() => newWindow()}>Ava eraldi aknas</Button>
            <Select
                mode="multiple"
                optionLabelProp="label"
                showSearch
                optionFilterProp="children"
                placeholder="Vali objekt(id)"
                dropdownMatchSelectWidth={false}
                defaultValue={selectedObjects}
                onChange={handleObjectsChange}
                className="mr-2"
            >
                {objectsSelect}
            </Select>
            <RangePicker
                defaultValue={selectedDates}
                style={{width: '250px'}}
                format='DD.MM.YY'
                onChange={handleDateChange}
            />
            <Button className="ml-2" onClick={() => fetchExcelData(activeTabKey, selectedDates)}><FileExcelOutlined /></Button>
            <Button className="ml-2" onClick={() => setTableCompact(!isTableCompact)}><LegacyIcon
                type={isTableCompact ? 'fullscreen-exit' : 'fullscreen'}/></Button>
        </div>


    return (
        <Card
            style={{width: '100%'}}
            //title={<div className="m-2"><h4>Tehase LIVE</h4></div>}
            tabList={tabList}
            activeTabKey={activeTabKey}
            tabBarExtraContent={menu}
            className="mt-2"
            onTabChange={key => setActiveTabKey(key)}
        >
            {/* mode === "development" ? <TotalsTrial/> : null */}
            {contentList[activeTabKey]}
        </Card>
    )

}


const PaAnalysisCosts = ({isTableCompact = false}) => {

    const me = useStoreState(state => state.user.me)
    const postMe = useStoreActions(actions => actions.user.apiPostMe)

    const dataOfInterest = useStoreState(state => state.analysis.costs)
    const dataOfInterestV2 = useStoreState(state => state.analysis.costsV2)
    const [state, setState] = useState()

    //if (window.localStorage.getItem('controlSearch'))
    //    setState(JSON.parse(window.localStorage.getItem('controlSearch')))

    //console.log("state", state)

    //function saveStateToUserProfile(target, val) {
    //
    //    let tempMe = JSON.parse(JSON.stringify(me))
    //    if (!'analysisCostsTable' in tempMe.prefs) Object.defineProperty(tempMe, 'analysisCostsTable', val)
    //    else tempMe.prefs.analysisCostsTable = val
    //
    //    console.log("ME", me, tempMe, JSON.stringify(val))
    //    postMe(tempMe) // setMe happens in thunk
    //}
    //
    //useEffect(() => {
    //    //if (me && 'analysisCostsTable' in me.prefs) setState(me.prefs.analysisCostsTable)
    //}, [me])

    return (
        <div style={{overflowX: 'auto',}}>

            {isTableCompact ?
                <div style={{overflowY: 'scroll', height: '90vh'}}>

                    <PivotTable data={dataOfInterest}
                                vals={["Rea summa"]}
                                rows={["Objekti nimi", "Kulutüüp", "Kulu grupp", "Kulu kirjeldus"]}
                                cols={["Kulu kuu", "Kulu kuupäev"]}
                                aggregatorName="Sum"
                                unusedAttrsVertical={false}
                                hiddenAttributes={["Kulu algus", "Kulu lõpp", "Tegelik lõpp",
                                    "Obj. Perenimi", "Obj. Eesnimi", "Kulu lõpp",
                                    "Adminkulude määr", "Shiffer", "Kulu ID", "ALgus", "Kirje staatus", "Objekti aadress", "Tähtaeg"]}
                        //derivedAttributes={{"Kulu kuu": dateFormat("Kulu kuupäev", "%m")}}
                                onChange={state => {
                                    delete state.data
                                    //saveStateToUserProfile('analysisCostsTable', state)
                                    setState(state)
                                }}
                                {...state}
                    />

                </div>
                :
                <div style={{overflowY: 'scroll', height: '90vh'}}>
                    <PivotTableUI data={dataOfInterest}
                                  vals={["Rea summa"]}
                                  rows={["Objekti nimi", "Kulutüüp", "Kulu grupp", "Kulu kirjeldus"]}
                                  cols={["Kulu kuu", "Kulu kuupäev"]}
                                  aggregatorName="Sum"
                                  unusedAttrsVertical={false}
                                  hiddenAttributes={["Kulu algus", "Kulu lõpp", "Tegelik lõpp",
                                      "Obj. Perenimi", "Obj. Eesnimi", "Kulu lõpp",
                                      "Adminkulude määr", "Shiffer", "Kulu ID", "ALgus", "Kirje staatus", "Objekti aadress", "Tähtaeg"]}
                        //derivedAttributes={{"Kulu kuu": dateFormat("Kulu kuupäev", "%m")}}
                                  onChange={state => {
                                      delete state.data
                                      //window.localStorage.setItem('analysisCostTable', JSON.stringify(state))
                                      //saveStateToUserProfile('analysisCostsTable', state)
                                      setState(state)
                                  }}
                                  {...state}
                    />
                </div>
            }

        </div>
    )
}

const PaAnalysisIncome = ({isTableCompact = false}) => {

    const dataOfInterest = useStoreState(state => state.analysis.income)
    const [state, setState] = useState()
    //console.log("state", state)

    return (
        <div style={{overflowX: 'auto'}}>
            {isTableCompact ?
                <PivotTable data={dataOfInterest}
                            vals={["Summa (akteeritavas hinnas)"]}
                            rows={["Objekti nimi2", "Nimi"]}
                            cols={["Tulu kuu", "Tulu kuupäev"]}
                            aggregatorName="Sum"
                            unusedAttrsVertical={false}
                            hiddenAttributes={["verston_id", "Shiffer", "Pakkumise kuupäev"]}
                    //derivedAttributes={{"Kulu kuu": dateFormat("Kulu kuupäev", "%m")}}
                            onChange={state => {
                                delete state.data
                                setState(state)
                            }}
                            {...state}
                />
                :
                <PivotTableUI data={dataOfInterest}
                              vals={["Summa (akteeritavas hinnas)"]}
                              rows={["Objekti nimi2", "Nimi"]}
                              cols={["Tulu kuu", "Tulu kuupäev"]}
                              aggregatorName="Sum"
                              unusedAttrsVertical={false}
                              hiddenAttributes={["verston_id", "Shiffer", "Pakkumise kuupäev"]}
                    //derivedAttributes={{"Kulu kuu": dateFormat("Kulu kuupäev", "%m")}}
                              onChange={state => {
                                  delete state.data
                                  setState(state)
                              }}
                              {...state}
                />

            }

        </div>
    )
}

const PaAnalysisBills = ({isTableCompact = false}) => {

    const dataOfInterest = useStoreState(state => state.analysis.bills)
    const [state, setState] = useState()
    //console.log("state", state)

    return (
        <div style={{overflowX: 'auto'}}>
            {isTableCompact ?
                <PivotTable data={dataOfInterest}
                            vals={["Rea summa"]}
                            rows={["Kulu kuu", "Kulu kuupäev", "Billi nimetus"]}
                            cols={["Kulutüüp"]}
                            aggregatorName="Sum"
                            unusedAttrsVertical={false}
                            hiddenAttributes={["Kulu algus", "Kulu lõpp", "Tegelik lõpp",
                                "Obj. Perenimi", "Obj. Eesnimi", "Kulu lõpp",
                                "Adminkulude määr", "Shiffer", "Kulu ID", "ALgus", "Kirje staatus", "Objekti aadress", "Tähtaeg", 'rea_kogu_summa', 't2.idcosts', 'idofferx', 'idcosts', 'id', 'budget id', 'assignment']}
                            onChange={state => {
                                delete state.data
                                setState(state)
                            }}
                            {...state}
                />
                :
                <PivotTableUI data={dataOfInterest}
                              vals={["Rea summa"]}
                              rows={["Kulu kuu", "Kulu kuupäev", "Billi nimetus"]}
                              cols={["Kulutüüp"]}
                              aggregatorName="Sum"
                              unusedAttrsVertical={false}
                              hiddenAttributes={["Kulu algus", "Kulu lõpp", "Tegelik lõpp",
                                  "Obj. Perenimi", "Obj. Eesnimi", "Kulu lõpp",
                                  "Adminkulude määr", "Shiffer", "Kulu ID", "ALgus", "Kirje staatus", "Objekti aadress", "Tähtaeg", 'rea_kogu_summa', 't2.idcosts', 'idofferx', 'idcosts', 'id', 'budget id', 'assignment']}
                              onChange={state => {
                                  delete state.data
                                  setState(state)
                              }}
                              {...state}
                />
            }
        </div>
    )
}

const PaAnalysisBillsV2 = ({isTableCompact = false}) => {

    const dataOfInterest = useStoreState(state => state.analysis.billsV2)
    const [state, setState] = useState()
    //console.log("state", state)

    return (
        <div style={{overflowX: 'auto'}}>
            {isTableCompact ?
                <PivotTable data={dataOfInterest}
                            vals={["Rea summa"]}
                            rows={["Kulu kuu", "Kulu kuupäev", "Billi nimetus"]}
                            cols={["Kulutüüp"]}
                            aggregatorName="Sum"
                            unusedAttrsVertical={false}
                            hiddenAttributes={["Kulu algus", "Kulu lõpp", "Tegelik lõpp",
                                "Obj. Perenimi", "Obj. Eesnimi", "Kulu lõpp",
                                "Adminkulude määr", "Shiffer", "Kulu ID", "ALgus", "Kirje staatus", "Objekti aadress", "Tähtaeg", 'rea_kogu_summa', 't2.idcosts', 'idofferx', 'idcosts', 'id', 'budget id', 'assignment']}
                            onChange={state => {
                                delete state.data
                                setState(state)
                            }}
                            {...state}
                />
                :
                <PivotTableUI data={dataOfInterest}
                              vals={["Rea summa"]}
                              rows={["Kulu kuu", "Kulu kuupäev", "Billi nimetus"]}
                              cols={["Kulutüüp"]}
                              aggregatorName="Sum"
                              unusedAttrsVertical={false}
                              hiddenAttributes={["Kulu algus", "Kulu lõpp", "Tegelik lõpp",
                                  "Obj. Perenimi", "Obj. Eesnimi", "Kulu lõpp",
                                  "Adminkulude määr", "Shiffer", "Kulu ID", "ALgus", "Kirje staatus", "Objekti aadress", "Tähtaeg", 'rea_kogu_summa', 't2.idcosts', 'idofferx', 'idcosts', 'id', 'budget id', 'assignment']}
                              onChange={state => {
                                  delete state.data
                                  setState(state)
                              }}
                              {...state}
                />
            }
        </div>
    )
}

export default PaObjectPivotTable