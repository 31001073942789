import { createStore, thunk, action } from 'easy-peasy'
import API from '../../services/api'

import { meModel } from './meModel'
import { siteModeModel } from './siteModeModel'
import { budgetModel } from './budgetModel'
import { offersModel } from './offersModel'
import { offerModel } from './offerModel'
import { objectsModel } from './objectsModel'
import { actsModel } from './actsModel'
import { actRowsModel } from './actRowsModel'
import { objectModel } from './objectModel'
import { factoryModel } from './factoryModel'
import { analysisModel } from './analysisModel'
import { controlModel } from './controlModel'
import { warehouseModel } from './warehouseModel'
import { productionModel } from './productionModel'
import { billsModel } from './billsModel'
import { budgetsModel } from './budgetsModel'
import { usersModel } from './usersModel'
import { roadinfoModel } from './roadinfoModel'
import { roadModel } from './roadModel'
import filtersModel from './filtersModel'
import { roadMaterialsModel } from './roadMaterialsModel'
import { asphaltModel } from './asphaltModel'



const costOptionsModel = { // todo: finish this
  costOptions: [],
  setCosts: action((state, payload) => state.costs = payload),
}

const layoutModel = {
  fullScreenComponent: false,
  setFullScreen: action((state, payload) => state.fullScreenComponent = payload),
}

const beginSyncModel = {

  loading: false,
  setLoading: action((state, payload) => state.loading = payload),
  apiStartUpdate: thunk(async (actions, payload) => {

    await API("/api/beginsync", false, "get", payload)
  })
}

const resultModelV2 = {

  result: [],
  objectsTotals: [],
  objectsTotalsLoading: true,

  setResult: action((state, payload) => state.result = payload),
  setObjectsTotals: action((state, payload) => state.objectsTotals = payload),
  setObjectsTotalsLoading: action((state, payload) => state.objectsTotalsLoading = payload),

  // Note how we are returning a function instead of state
  //                          👇
  //subtotalSumAtOfferByBill: computed(state => path => state.result
  //    //.filter(r => r.bill_path.startsWith(path) || r.bill_path_cost.startsWith(path)))
  //    .filter(r => r.path.startsWith(path))
  //    .reduce((sum, val) => sum = sum + val.sum_at_offer, 0)
  //),

  apiFetchResult: thunk(async (actions, payload) => {
    const res = await API('/api/v2/result/detailed', false, 'get', payload)
    if (res.data) { actions.setResult(res.data) }
  }),

  apiFetchObjectsTotals: thunk(async (actions, payload) => {
    //await actions.setObjectsTotalsLoading(true)
    const res = await API('/api/v2/result/oneliner', false, 'get', payload)
    if (res.data) { await actions.setObjectsTotals(res.data) }
    //await actions.setObjectsTotalsLoading(false)
  }),

  apiSetPrognosis: thunk(async (actions, payload) => {
    const res = await API('/api/v2/prognosis', false, 'post', payload)
    if (res.data) actions.setResult(res.data)
  }),
}


const divisionsModel = {

  divisions: [],
  setDivisions: action((state, payload) => state.divisions = payload),
  apiFetchDivisions: thunk(async (actions) => {
    const res = await API('/api/divisions', false, 'get', null)
    if (res.data) { actions.setDivisions(res.data) }
  }),

}


const storeModel = {
  bills: billsModel,
  site: siteModeModel,
  layout: layoutModel,
  objects: objectsModel,
  acts: actsModel,
  actRows: actRowsModel,
  object: objectModel,
  costOptions: costOptionsModel,
  user: meModel,
  offers: offersModel,
  budgets: budgetsModel,
  budget: budgetModel,
  resultV2: resultModelV2,            // running result of object and prognosis view in same dataset
  offer: offerModel,
  factory: factoryModel,
  analysis: analysisModel,
  control: controlModel,
  warehouse: warehouseModel,
  production: productionModel,
  beginSync: beginSyncModel,
  filters: filtersModel,
  users: usersModel,
  divisions: divisionsModel,
  roadInfo: roadinfoModel,
  road: roadModel,
  roadMaterials: roadMaterialsModel,
  asphalt: asphaltModel
}

const store = createStore(storeModel)

export default store

