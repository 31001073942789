import { action, computed, thunk } from 'easy-peasy'
import API, { URL } from '../../services/api'
import Cookies from 'js-cookie'
import axios from 'axios'
import fileSaver from '../../utils/fileSaver'

export const roadModel = {
  items: {},
  offers: [],
  exportList: [],
  list: computed((state) => Object.values(state.items)),
  byId: computed((state) => (idroad) => state.items[idroad]),

  loading: false,
  error: {},

  // Actions
  setLoading: action((state, loading) => {
    state.loading = loading
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  setExportList: action((state, data) => {
    state.exportList = data
  }),
  fetched: action((state, payload) => {
    state.items = payload.reduce((acc, item) => {
      acc[item.idroad] = item
      return acc
    }, {})
  }),
  fetchedOffers: action((state, payload) => {
    state.offers = payload
  }),
  saved: action((state, payload) => {
    state.items[payload.idroad] = payload
  }),

  // Thunks
  fetch: thunk(async (actions, id) => {
    actions.setLoading(true)
    const { data } = await API(`/api/road?idobject=${id}`)
    if (data) {
      const { materials } = await API(`/api/road/materials?idroad=${data.idroad}`)
      console.log('fetched roads: ', { data, materials })
      actions.fetched(data)
      actions.setLoading(false)
      return data
    }
    actions.setLoading(false)
  }),
  fetchOne: thunk(async (actions, id) => {
    actions.setLoading(true)
    const { data } = await API(`/api/road?id=${id}`)
    console.log('fetched act: ', { data })
    actions.fetched(data)
    actions.setLoading(false)
    return data
  }),
  fetchOffers: thunk(async (actions, id) => {
    //actions.setLoading(true)
    const { data } = await API(`/api/object/offers/rows?idobject=${id}`)
    if (data) {
      console.log('fetched offers: ', { data })
      actions.fetchedOffers(data)
      //actions.setLoading(false)
      return data
    }
    //actions.setLoading(false)
  }),
  export: thunk(async (actions, id) => {
    const { data } = await API('/api/road/export')
    console.log('From API', data)
    actions.setExportList(data)
    return data
  }),
  // payload: { idobject, iduser, name, road_number }
  add: thunk(async (actions, payload) => {
    const res = await API('/api/road', false, 'post', payload)
    actions.saved(res.data)
    return res
  }),
  update: thunk(async (actions, payload) => {
    const res = await API('/api/road', false, 'put', payload)
    actions.saved(res.data)
  }),
  delete: thunk(async (actions, { id, obj_id }) => {
    await API('/api/road', false, 'delete', { id })
    actions.fetch(obj_id)
  })
}
