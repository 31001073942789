import { action, computed, thunk, debug } from "easy-peasy"
import to from 'await-to-js'
import API from "../../services/api"

export const actRowsModel = {
  items: {},
  offers: {},
  list: computed(state => Object.values(state.items)),
  offersList: computed(state => act_id => {
    if (!state.loadingOffers && !state.loadingRows) {
      if (act_id && Object.keys(state.offers).length > 0) {
        return Object.values(state.offers[act_id]).reduce((acc, cur) => {
          if (Object.values(cur).map(offer => offer.offer_nr).length > 1) acc.push({
            key: `${act_id}-${Object.values(cur)[0].offer_nr}-title`,
            art_name: Object.values(cur)[0].offer_nr,
            isTitle: true
          })
          const values = Object.values(cur)
            .map(offer => ({ ...offer, rows: [...Object.values(state.items[act_id]).filter(actRow => actRow.offer_id === offer.act_offer_id), { adding: true, key: `${act_id}-${offer.act_offer_id}-adding`, unit_price: offer.offer_unit_price, offer_id: offer.act_offer_id, offer_nr: offer.offer_nr }] }))
          acc.push(...values)
          return acc
        }, [])
      } else {
        return []
      }
    }
  }),

  loadingRows: false,
  loadingOffers: false,
  loadingCell: false,
  error: {},

  // Actions
  setLoading: action((state, { loading, type = 'rows' }) => {
    if (type === 'rows') {
      state.loadingRows = loading
    }
    if (type === 'offers') {
      state.loadingOffers = loading
    }
    if (type === 'cell') {
      state.loadingCell = loading
    }
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  fetched: action((state, { data, act_id }) => {
    /*
    act_id: {
      id: { ... }
    }
    */
    state.items[act_id] = data.reduce((acc, item) => {
      const { id } = item

      if (!acc[id]) acc[id] = {}
      acc[id] = { editable: true, isDate: true, ...item }

      return acc
    }, {})
    console.log(debug(state.items))
  }),
  fetchedOffers: action((state, { data, act_id, update = false }) => {
    /* 
    act_id: {
      offer_nr: {
        act_offer_id: { ... }
      }
    }
    */
    if (update) {
      state.offers[act_id][update] = data.reduce((acc, item) => {
        acc[item.act_offer_id] = item
        return acc
      }, {})
    } else {
      state.offers[act_id] = data.reduce((acc, item) => {
        const { offer_nr, act_offer_id } = item

        if (!acc[offer_nr]) acc[offer_nr] = {}
        const actRows = acc[offer_nr]

        actRows[act_offer_id] = item
        return acc
      }, {})
    }
  }),
  saved: action((state, data) => {
    /*
    act_id: {
      id: { ... }
    }
    */
    console.log('saved actrow', data)
    state.items[data.act_id][data.id] = { ...data, editable: true, isDate: true, art_name: data.date_akt, acting_amount: data.amount }
    //console.log(debug(state.items))
  }),
  addEmptyRow: action((state, { data }) => {
    console.log('added empty row', data)
    const key = `emptyRow-${data.act_id}-${data.offer_id}-${Object.keys(state.items[data.act_id])}`
    state.items[data.act_id][key] = { ...data, editable: true, art_name: data.date_akt, acting_amount: data.amount, key: key }
  }),
  deleteEmptyRow: action((state, { data }) => {
    delete state.items[data.act_id][data.key]
  }),

  // Thunks
  fetch: thunk(async (actions, id) => {
    actions.setLoading({ loading: true, type: 'rows' })
    const { data } = await API(`/api/object/actrows?act_id=${id}`)
    console.log('fetched actRows: ', { data })
    actions.fetched({ data, act_id: id })
    actions.setLoading({ loading: false, type: 'rows' })
    return data
  }),
  fetchOffers: thunk(async (actions, { offer_nrs, act_id, update, loading = true }) => {
    if (loading) actions.setLoading({ loading: true, type: 'offers' })
    const { data } = await API(`/api/object/actrows/offers?act_id=${act_id}&offer_nrs=${offer_nrs ? offer_nrs.map(offer_nr => `${encodeURIComponent(offer_nr)}`) : ''}`)
    console.log('fetched actRow offers: ', { data })
    actions.fetchedOffers({ data, act_id, update })
    actions.setLoading({ loading: false, type: 'offers' })
    return data
  }),
  save: thunk(async (actions, { payload, offer_nr }) => {
    const { id, ...params } = payload
    if (id) {
      console.log('saving with id', id)
      actions.setLoading({ loading: true, type: 'cell' })
      const [error, { data } = {}] = await to(API(`/api/object/actrows`, false, 'put', { id, ...params }))
      if (error) {
        actions.setError({ ...error, response: data })
        throw error
      }
      actions.saved(data)
      actions.setLoading({ loading: false, type: 'cell' })
      actions.fetchOffers({ offer_nrs: [offer_nr], act_id: data.act_id, update: offer_nr, loading: false })
      return data
    } else {
      const [error, { data } = {}] = await to(API(`/api/object/actrows`, false, 'post', params))
      if (error) {
        actions.setError({ ...error, response: data })
        throw error
      }
      actions.saved(data)
      actions.deleteEmptyRow({ data: params })
      actions.fetchOffers({ offer_nrs: [offer_nr], act_id: data.act_id })
      return data
    }
  }),

}