import { action, computed, thunk } from "easy-peasy"
import API from "../../services/api"

export const objectsModel = {
  objects: [],         //list of Objects objects
  loading: true,
  // computed
  objectsCount: computed(state => state.objects ? Object.values(state.objects).length : 0),

  // actions
  setLoading: action((state, loading) => {
    state.loading = loading
  }),
  setObjects: action((state, payload) => state.objects = payload),

  // thunks
  apiLoadObjects: thunk(async (actions) => {
    actions.setLoading(true)
    const objects = await API("/api/objects")
    actions.setObjects(objects.data)
    actions.setLoading(false)
  }),
}