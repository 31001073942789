import { action, computed, thunk } from "easy-peasy"
import API from "../../services/api"


export const usersModel = {
  items: {},
  list: computed(state => Object.values(state.items)),
  byId: computed(state => id => state.items[id]),

  loading: false,
  error: {},

  // Actions
  setLoading: action((state, loading) => {
    state.loading = loading
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  fetched: action((state, payload) => {
    state.items = payload.reduce((acc, item) => {
      acc[item.iduser] = item
      return acc
    }, {})
  }),

  // Thunks
  fetch: thunk(async (actions) => {
    actions.setLoading(true)
    const { data } = await API(`/api/users`)
    console.log('fetched users: ', { data })
    actions.fetched(data)
    actions.setLoading(false)
    return data
  }),
  fetchOne: thunk(async (actions, id) => {
    actions.setLoading(true)
    const { data } = await API(`/api/users?id=${id}`)
    console.log('fetched act: ', { data })
    actions.fetched(data)
    actions.setLoading(false)
    return data
  }),
}