import SignInForm from "../pages/login";
import FactoryLive, {FactoryLivePaver} from "../pages/factoryLive";
import PaControl from "../pages/control";
import PaUsers from "../pages/users";
import {PaOffer} from "../pages/offer";
import {PaOffers} from "../pages/offers";
import PaObjectsUpdate from "../pages/objectsNewOrUpdate";
import PaObjects from "../pages/objects";
import PaObject from "../pages/object";
import Welcome from '../pages/main'
import PaBills from '../pages/bills'
import Roadinfo from '../pages/roadinfo'

import React from "react";

import Icon, {
    BookOutlined,
    ClockCircleOutlined,
    DatabaseOutlined,
    HomeOutlined,
    OrderedListOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    SafetyOutlined
} from '@ant-design/icons';

import { Menu } from "antd";


const {SubMenu} = Menu;


// not in use, changing this does not change anything

export const mainRrouting = [

    {path: "/road", name: "road", breadcrumbName: "Road", component: {Roadinfo}},
    {path: "/login", name: "login", breadcrumbName: "Login", component: {SignInForm}},
    {path: "/live", name: "live", breadcrumbName: "Tehas", component: {FactoryLive}},
    {path: "/livepaver/:id", name: "live", breadcrumbName: "Tehas", component: {FactoryLivePaver}},
    {path: "/control", name: "control", breadcrumbName: "Koondtabel", component: {PaControl}},
    {path: "/users", name: "login", breadcrumbName: "Kasutajad", component: {PaUsers}},
    {path: "/offer/:offer_nr", name: "login", breadcrumbName: ":offer_nr", component: {PaOffer}},
    {path: "/offers", name: "login", breadcrumbName: "Hinnapakkumised", component: {PaOffers}},
    {path: "/bills", name: "bills", breadcrumbName: "Bills", component: {PaBills}},

    {path: "/objects/new", name: "newobject", breadcrumbName: "Uus", component: {PaObjectsUpdate}},
    {path: "/objects/update/:id", name: "newobject", breadcrumbName: "Uus", component: {PaObjectsUpdate}},
    {path: "/objects", name: "login", breadcrumbName: "Objektid", component: {PaObjects}},
    {path: "/object/:id", name: "object", breadcrumbName: ":id", component: {PaObject}},
    {path: "/object/:id/:tab", name: "object", breadcrumbName: ":id", component: {PaObject}},

    {path: "/", name: "home", breadcrumbName: "Kodu", component: {Welcome}}
]

const RoadSvg = () => (

    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="road"
         className="svg-inline--fa fa-road fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path fill="currentColor"
              d="M573.19 402.67l-139.79-320C428.43 71.29 417.6 64 405.68 64h-97.59l2.45 23.16c.5 4.72-3.21 8.84-7.96 8.84h-29.16c-4.75 0-8.46-4.12-7.96-8.84L267.91 64h-97.59c-11.93 0-22.76 7.29-27.73 18.67L2.8 402.67C-6.45 423.86 8.31 448 30.54 448h196.84l10.31-97.68c.86-8.14 7.72-14.32 15.91-14.32h68.8c8.19 0 15.05 6.18 15.91 14.32L348.62 448h196.84c22.23 0 36.99-24.14 27.73-45.33zM260.4 135.16a8 8 0 0 1 7.96-7.16h39.29c4.09 0 7.53 3.09 7.96 7.16l4.6 43.58c.75 7.09-4.81 13.26-11.93 13.26h-40.54c-7.13 0-12.68-6.17-11.93-13.26l4.59-43.58zM315.64 304h-55.29c-9.5 0-16.91-8.23-15.91-17.68l5.07-48c.86-8.14 7.72-14.32 15.91-14.32h45.15c8.19 0 15.05 6.18 15.91 14.32l5.07 48c1 9.45-6.41 17.68-15.91 17.68z"></path>
    </svg>
)

export const mainMenu = [
    {
        path: '/', menuItem: true, menuIndex: 1, requiresRole: [1,2,3,4,5,6,7,9], allowed_If_InGroup: [],
        menuContent:
            <Menu.Item key="/" to="/">
                <HomeOutlined />
                <span>Kodu</span>
            </Menu.Item>
    },

    {
        path: '/objects', menuItem: true, menuIndex: 2, requiresRole: [1,2,3,4,5,6,9],allowed_If_InGroup: [],
        menuContent:
            <Menu.Item key="/objects" to="/objects">
                <ClockCircleOutlined />
                <span>Objektid</span>
            </Menu.Item>
    },
    {
        path: '/control', menuItem: true, menuIndex: 3, requiresRole: [4,3],allowed_If_InGroup: [2],
        menuContent:
            <Menu.Item key="/control" to="/control">
                <BookOutlined />
                <span>Koondtabel</span>
            </Menu.Item>
    },
    {
        path: '/live', menuItem: true, menuIndex: 4, requiresRole: [1,2,3,4,5,6],allowed_If_InGroup: [],
        menuContent:
            <Menu.Item key="/live" to="/live">
                <ShoppingCartOutlined />
                <span>Tehas</span>
            </Menu.Item>
    },
    {

        path: '/road', menuItem: true, menuIndex: 5, requiresRole: [1,2,3,4,5,6,9],allowed_If_InGroup: [],
        menuContent:
            <Menu.Item key="/road" to="/road">
                <SafetyOutlined />
                <span>Teeinfo</span>
            </Menu.Item>
    },
    {
        path: '', menuItem: true, menuIndex: 6, requiresRole: [], allowed_If_InGroup: [2],

        menuContent:
            <SubMenu
                key="sub1"
                title={
                    <span>
                            <SettingOutlined />
                            <span>Admin</span>
                            </span>
                }
            >
                <Menu.Item key="/users" to="/users">
                    <UserOutlined />
                    <span>Kasutajad</span>
                </Menu.Item>
                <Menu.Item key="/offers" to="/offers">
                    <OrderedListOutlined />
                    <span>Eelarved</span>
                </Menu.Item>
                 <Menu.Item key="/bills" to="/bills">
                    <DatabaseOutlined />
                    <span>Billid</span>
                </Menu.Item>
                <Menu.Item key="/worksheets" to="/worksheets">
                    <DatabaseOutlined />
                    <span>Töölehed</span>
                </Menu.Item>
            </SubMenu>
    },
    {
        path: '/livepaver/0', menuItem: true, menuIndex: 7, requiresRole: [7],allowed_If_InGroup: [],
        menuContent:
            <Menu.Item key="/livepaver" to="/livepaver/0">
                <Icon component={RoadSvg} style={{fontSize: 30}}/>
                <span>Laotamine</span>
            </Menu.Item>
    },

]
