
import React, { useState, useEffect } from 'react'

import './App.css'
import 'antd/dist/antd.css'
import './custom-react-table.css'
import PaObjects from './pages/objects'
import { PaOffers } from './pages/offers'
import { PaOffer } from './pages/offer'
import SignInForm from './pages/login'
import Welcome from './pages/main'
import PaBills from './pages/bills'
import PaWorksheets from './pages/worksheets'
import { PaCostBudget } from './components/budget/budgetMain'
import "tabler-react/dist/Tabler.css"
import Roadinfo from './pages/roadinfo'


import { useStoreActions, useStoreState } from 'easy-peasy'
import CostOptionsContext from './components/CostOptionsContext'

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom"


import PaUsers from "./pages/users";
import FactoryLive, {FactoryLivePaver} from "./pages/factoryLive";
import PaControl from "./pages/control";
import PaActions from "./pages/actions";
import PaObject from "./pages/object";

import PaObjectsUpdate from "./pages/objectsNewOrUpdate"
import Loading from "./pages/loading"
import API from "./services/api"


function App() {

    const fetchMe = useStoreActions(actions => actions.user.apiFetchMe)
    const fetchDivisions = useStoreActions(actions => actions.divisions.apiFetchDivisions)
    const paverView = useStoreState(state => state.site.paverView)
    const isAuthenticated = useStoreState(state => state.user.isAuthenticated)
    const user = useStoreState(state => state.user.me)

    const fetchBills = useStoreActions(actions => actions.bills.apiFetchBills)
    const loadObjects = useStoreActions(actions => actions.objects.apiLoadObjects)

    const [formOptions, setFormOptions] = useState({ formoptions: {} })
    const [loading, setLoading] = useState(true)

    // getting costs options, needs refactoring to Store todo: move to store

    useEffect(() => {
        (async () => {
            //console.log("my location: ", window.location, user, isAuthenticated)

            if (isAuthenticated && user && Object.keys(user).length === 0) { // if no user data
                await fetchMe()
                setLoading(false)
            }

            if (user && Object.keys(user).length > 0 && isAuthenticated) {
                console.log("user isauth", user, isAuthenticated)
                await fetchBills()
                await loadObjects()
                await fetchDivisions()

                // todo: refactor this to use globalstore
                // todo: refactor backend, currently stupid, takes too long
                await API("/api/object/costs/options").then((res) => setFormOptions(res))
            }

        })()
        // eslint-disable-next-line
    }, [user, isAuthenticated])


    // if loading show loading, else, if not authenticated, authenticate
    if (loading && isAuthenticated) return <Loading />
    if (!isAuthenticated) return <SignInForm />

    if (user) {
        if (user.position === 7 || paverView) { //laoturijuht
            //return <FactoryLivePaver/>
        }
    }

    return (
        <CostOptionsContext.Provider value={{ formOptions, setFormOptions }}>
            <Router>
                <Switch>

                    <Route path="/road" name="roadinfo" breadcrumbName="Roadinfo" component={Roadinfo}/>
                    <Route path="/login" name="login" breadcrumbName="Login" component={SignInForm}/>
                    <Route path="/actions" name="actions" breadcrumbName="actions" component={PaActions}/>
                    <Route path="/live" name="live" breadcrumbName="Tehas" component={FactoryLive}/>
                    <Route path="/livepaver/:id" name="live" breadcrumbName="Tehas" component={FactoryLivePaver}/>
                    <Route path="/control" name="control" breadcrumbName="Koondtabel" component={PaControl}/>
                    <Route path="/users" name="login" breadcrumbName="Kasutajad" component={PaUsers}/>
                    <Route path="/budget/offer/:doc_id" name="login" breadcrumbName=":doc_id" component={PaOffer}/>
                    <Route path="/budget/cost/:doc_id" name="login" breadcrumbName=":doc_id" component={PaCostBudget}/>
                    <Route path="/offer/:offer_nr" name="login" breadcrumbName=":offer_nr" component={PaOffer}/>
                    <Route path="/offers" name="login" breadcrumbName="Hinnapakkumised" component={PaOffers}/>
                    <Route path="/bills" name="bills" breadcrumbName="Billid" component={PaBills}/>
                    <Route path="/worksheets" name="bills" breadcrumbName="Billid" component={PaWorksheets}/>
                    <Route path="/objects/new" name="newobject" breadcrumbName="Uus" component={PaObjectsUpdate}/>
                    <Route path="/objects/update/:id" name="newobject" breadcrumbName="Uus"
                           component={PaObjectsUpdate}/>
                    <Route path="/objects" name="login" breadcrumbName="Objektid" component={PaObjects}/>

                    <Route path="/object/:id/:tab" name="object" breadcrumbName=":id" component={PaObject}/>
                    <Route path="/object/:id" name="object" breadcrumbName=":id" component={PaObject}/>

                    <Route path="/" name="home" breadcrumbName="Kodu" component={Welcome}>
                    </Route>
                </Switch>
            </Router>
        </CostOptionsContext.Provider>
    )
}

export default App

