import React, {useEffect, useState} from 'react'
import { FileExcelOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Card, Button, DatePicker, Select } from "antd";

import {useStoreActions, useStoreState} from "easy-peasy";
import {URL} from "../../../services/api";
import moment from 'moment'
import PaRangeSelect from "../../RangeSelect";
import PivotTableUI from 'react-pivottable/PivotTableUI';
import PivotTable from 'react-pivottable/PivotTable';


const {RangePicker} = DatePicker;
const {Option} = Select

export const PaProductionObjectPivotTable = () => {

    const objects = useStoreState(state => state.objects.objects)
    const meta = useStoreState(state => state.object.meta)
    const fetchCosts = useStoreActions(actions => actions.analysis.apiFetchCosts)
    const fetchIncome = useStoreActions(actions => actions.analysis.apiFetchIncome)
    const fetchProductionIncome = useStoreActions(actions => actions.analysis.apiFetchProductionIncome)

    const [activeTabKey, setActiveTabKey] = useState('kulud')
    const [isTableCompact, setTableCompact] = useState(false)

    const [selectedObjects, setSelectedObjects] = useState([meta.idobject])
    const [selectedDates, setSelectedDates] = useState([moment('2020-01-01'), moment()])

    const tabList = [
        {
            key: 'kulud',
            tab: 'Kulud',
        },
        {
            key: 'tulud',
            tab: 'Tulud',
        },

    ];

    const contentList = {
        kulud: <PaProductionAnalysisCosts isTableCompact={isTableCompact}/>,
        tulud: <PaProductionAnalysisIncome isTableCompact={isTableCompact}/>,
    };

    async function fetchExcelData(tab, dates) {

        let payload = {
            dates: dates.map(d => d.format("YYYY-MM-DD 00:00:00")),
            objects: selectedObjects,
            tab: tab
        }

        // this works, but no way of passing headers
        window.location.href = URL() + '/api/object/analyse/excel?' + new URLSearchParams(payload).toString()

    }

    const objectsSelect = [];
    objects.forEach((object) => {
        objectsSelect.push(
            <Option key={object.idobject}
                    value={object.idobject}
                    label={object.shiffer}>
                {object.shiffer} {object.name}
            </Option>);
    })

    async function handleDateChange(e) {
        await setSelectedDates(e)
        await console.log(e)
        handleUpdate(e, selectedObjects)
    }

    async function handleObjectsChange(e) {
        await setSelectedObjects(e)
        await console.log(e)
        handleUpdate(selectedDates, e)
    }

    async function handleUpdate(dates, objects) {
        dates[1].add(1, 'days')
        let payload = {
            dates: dates.map(d => d.format("YYYY-MM-DD 00:00:00")),
            objects: objects,
        }
        console.log("payload", payload)
        fetchCosts(payload)
        fetchIncome(payload)
        fetchProductionIncome(payload)

    }

    const newWindow = () => {window.open("/object/"+meta.idobject+"/5")}

    useEffect(() => {
        handleUpdate(selectedDates, selectedObjects)
         // eslint-disable-next-line
    }, [])

    const menu =
        <div>
            <Button className="mr-2" onClick={() => newWindow()}>Ava eraldi aknas</Button>
            <Select
                mode="multiple"
                optionLabelProp="label"
                showSearch
                optionFilterProp="children"
                placeholder="Vali objekt(id)"
                dropdownMatchSelectWidth={false}
                defaultValue={selectedObjects}
                onChange={handleObjectsChange}
                className="mr-2"
            >
                {objectsSelect}
            </Select>
            <Button.Group>
                <RangePicker
                    value={selectedDates}
                    style={{width: '250px'}}
                    format='DD.MM.YY'
                    onChange={handleDateChange}
                    allowClear={false}

                />
                <PaRangeSelect onSelect={handleDateChange}/>
            </Button.Group>
            <Button className="ml-2" onClick={() => fetchExcelData(activeTabKey, selectedDates)}><FileExcelOutlined /></Button>
            <Button className="ml-2" onClick={() => setTableCompact(!isTableCompact)}><LegacyIcon
                type={isTableCompact ? 'fullscreen-exit' : 'fullscreen'}/></Button>
        </div>


    return (
        <Card
            style={{width: '100%'}}
            //title={<div className="m-2"><h4>Tehase LIVE</h4></div>}
            tabList={tabList}
            activeTabKey={activeTabKey}
            tabBarExtraContent={menu}
            className="mt-2"
            onTabChange={key => setActiveTabKey(key)}
        >
            {contentList[activeTabKey]}
        </Card>
    )

}

const PaProductionAnalysisCosts = ({isTableCompact = false}) => {

    const dataOfInterest = useStoreState(state => state.analysis.costs)
    const [state, setState] = useState()
    //console.log("state", state)

    return (
        <div style={{overflowX: 'auto'}}>
            {isTableCompact ?
                <PivotTable data={dataOfInterest}
                            vals={["Rea summa"]}
                            rows={["Kulu kuupäev"]}
                            cols={["Kulutüüp", "Kulu grupp"]}
                            aggregatorName="Sum"
                            unusedAttrsVertical={false}
                            hiddenAttributes={["Kulu algus", "Kulu lõpp", "Tegelik lõpp", 'Kogus (km)', 'Koefitsent (ja km)', 'Koguse kirjeldus (valem)',
                                "Obj. Perenimi", "Obj. Eesnimi", "Kulu lõpp",
                                "Adminkulude määr", "Shiffer", "Kulu ID", "ALgus", "Kirje staatus", "Objekti aadress", "Tähtaeg"]}
                    //derivedAttributes={{"Kulu kuu": dateFormat("Kulu kuupäev", "%m")}}
                            onChange={state => {
                                delete state.data
                                setState(state)
                            }}
                            {...state}
                />
                :
                <PivotTableUI data={dataOfInterest}
                              vals={["Rea summa"]}
                              rows={["Kulu kuupäev"]}
                              cols={["Kulutüüp", "Kulu grupp"]}
                              aggregatorName="Sum"
                              unusedAttrsVertical={false}
                              hiddenAttributes={["Kulu algus", "Kulu lõpp", "Tegelik lõpp", 'Kogus (km)', 'Koefitsent (ja km)', 'Koguse kirjeldus (valem)',
                                  "Obj. Perenimi", "Obj. Eesnimi", "Kulu lõpp",
                                  "Adminkulude määr", "Shiffer", "Kulu ID", "ALgus", "Kirje staatus", "Objekti aadress", "Tähtaeg"]}
                    //derivedAttributes={{"Kulu kuu": dateFormat("Kulu kuupäev", "%m")}}
                              onChange={state => {
                                  delete state.data
                                  setState(state)
                              }}
                              {...state}
                />
            }
        </div>
    )
}

const PaProductionAnalysisIncome = ({isTableCompact = false}) => {

    const dataOfInterest = useStoreState(state => state.analysis.productionIncome)
    const [state, setState] = useState()
    //console.log("state", state)

    return (
        <div style={{overflowX: 'auto'}}>
            {isTableCompact ?
                <PivotTable data={dataOfInterest}
                            vals={["Summa"]}
                            rows={['Toode', 'Objektile']}
                            cols={['Tootmise kuu']}
                            aggregatorName="Sum"
                            unusedAttrsVertical={false}
                    //hiddenAttributes={["verston_id", "Shiffer", "Pakkumise kuupäev"]}
                    //derivedAttributes={{"Kulu kuu": dateFormat("Kulu kuupäev", "%m")}}
                            onChange={state => {
                                delete state.data
                                setState(state)
                            }}
                            {...state}
                />
                :
                <PivotTableUI data={dataOfInterest}
                              vals={["Summa"]}
                              rows={['Toode', 'Objektile']}
                              cols={['Tootmise kuu']}
                              aggregatorName="Sum"
                              unusedAttrsVertical={false}
                    //hiddenAttributes={["verston_id", "Shiffer", "Pakkumise kuupäev"]}
                    //derivedAttributes={{"Kulu kuu": dateFormat("Kulu kuupäev", "%m")}}
                              onChange={state => {
                                  delete state.data
                                  setState(state)
                              }}
                              {...state}
                />

            }

        </div>
    )
}
