import React from 'react'
import { MoreOutlined } from '@ant-design/icons';
import { Menu, Button, Dropdown } from "antd";
import moment from 'moment'

const PaRangeSelect = ({title = <MoreOutlined />, onSelect, style = {width: '250px'}}) => {


    const ranges = [
        {key: '1', title: "Täna", beginDate: moment().startOf('day'), endDate: moment().endOf('day') },
        {key: '2', title: "Eile", beginDate: moment().subtract(1, 'days').startOf('day'), endDate: moment().subtract(1, 'days').endOf('day') },
        {key: '3', title: "See nädal", beginDate: moment().startOf('week'), endDate: moment() },
        {key: '4', title: "See kuu", beginDate: moment().startOf('month'), endDate: moment() },
        {key: '5', title: "Eelmine kuu", beginDate:  moment().subtract(1, 'months').startOf('month'), endDate: moment().subtract(1, 'months').endOf('month') },
        {key: '6', title: "Eelmise kuu algusest tänaseni", beginDate: moment().subtract(1, 'months').startOf('day'), endDate: moment() },
        {key: '7', title: "Viimased 3 kuud", beginDate: moment().subtract(2, 'months'), endDate: moment() },
        {key: '8', title: "See aasta", beginDate: moment().startOf('year'), endDate: moment() },

    ]


    const handleMenuClick = (v) => {
        console.log("key klicked", v)
        let range = ranges.find(e => e.key === v.key)
        onSelect([range.beginDate, range.endDate])
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1"> Täna</Menu.Item>
            <Menu.Item key="2"> Eile </Menu.Item>
            <Menu.Item key="3"> See nädal </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="4"> See kuu </Menu.Item>
            <Menu.Item key="5"> Eelmine kuu </Menu.Item>
            <Menu.Item key="6"> Eelmise kuu algusest tänaseni</Menu.Item>
            <Menu.Item key="7"> Viimased 3 kuud </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="8"> See aasta </Menu.Item>

        </Menu>
    )

    return (
        <Dropdown overlay={menu}>
            <Button>
                {title}
            </Button>
        </Dropdown>)
}
export default PaRangeSelect
