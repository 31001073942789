import moment from "moment";
import {action, computed, thunk} from "easy-peasy";
import API from "../../services/api";

export const factoryModel = {
    scaleEvents: [],         //list of scale  objects
    activeDate: moment(),

    filters: ['in', 'out'],
    setFilters: action((state, payload) => state.filters = payload),


    products: computed(state => {
        let products = []
        if (state.scaleEvents) {
            state.scaleEvents.filter(e => e.is_product_or_material === "product").forEach(r => {
                if (!products.includes(r.product_name)) products.push(r.product_name)
            })
        }
        return products
    }),

    avgTimeIn: computed(state => {
        let timeIn = moment.duration('00:00:00')
        if (state.scaleEvents) {
            timeIn = state.scaleEvents
                .filter(d => d.direction === "out" && d.date_out && d.date_in)
                .map(d => moment(d.date_out).diff(moment(d.date_in)))
            if (timeIn.length > 0) {
                timeIn = (timeIn.reduce((sum, current) => sum + current, 0) / timeIn.length)
            } else timeIn = 0

        }
        return moment.utc(timeIn)
    }),

    cars: computed(state => {
        let trucks = []
        if (state.scaleEvents) {
            state.scaleEvents.forEach(row => {
                if (!trucks.includes(row.regnr)) trucks.push(row.regnr)
            })
        }
        return trucks
    }),

    // filter out all cars that have at least one delivery - one scale event that has date_received set
    carsHaveDeliveredOnce: computed(state => {
        let trucks = []
        if (state.scaleEvents) {
            state.scaleEvents.filter(r => !!r.date_received).forEach(row => {
                if (!trucks.includes(row.regnr)) trucks.push(row.regnr)
            })
        }
        return trucks
    }),

    // cars currently performing delivery: sorting out scale events, where
    // status is not 5 (delivered) and that have no date_received date
    // accounts for both product delivery from and aggregate delivery to factory

    carsOnDelivery: computed(state => {
        let trucks = []
        if (state.scaleEvents && state.carsHaveDeliveredOnce) {
            state.scaleEvents
                .filter(r => !r.date_received && !r.status === 5 && state.carsHaveDeliveredOnce.includes(r.regnr))
                .forEach(row => {
                    if (!trucks.includes(row.regnr)) trucks.push(row.regnr)
                })
        }
        return trucks
    }),

    carsReturning: computed(state => {
        let difference = []
        if (state.carsHaveDeliveredOnce && state.carsOnDelivery) {
            difference = state.carsHaveDeliveredOnce.filter(x => !state.carsOnDelivery.includes(x));
        }
        return difference
    }),

    returnEvents: computed(state => {
        let results = []
        let used_regnr = []
        if (state.scaleEvents && state.carsReturning) {
            state.scaleEvents
                .filter(r => !!r.date_received && state.carsReturning.includes(r.regnr))
                .forEach(r => {
                    if (!used_regnr.includes(r.regnr)) {
                        used_regnr.push(r.regnr)
                        results.push(r)
                    }
                })
        }
        return results
    }),


    // actions
    setScaleEvents: action((state, payload) => state.scaleEvents = payload),
    setActiveDate: action((state, payload) => state.activeDate = payload),

    // thunks
    apiFetchScaleEvents: thunk(async (actions, payload) => {
        const events = await API("/api/factory", false, 'GET', payload)
        actions.setScaleEvents(events.data)
    }),

    apiPostScaleEvents: thunk(async (actions, payload) => {
        const events = await API("/api/factory", false, 'post', payload)
        actions.setScaleEvents(events.data)
    }),

};
