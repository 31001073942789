import React from "react"
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, DatePicker } from "antd"
import moment from "moment"
import './EditableCell.css'
import dateLocale from 'antd/es/date-picker/locale/et_EE'


const EditableContext = React.createContext()

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
)

export const EditableFormRow = Form.create()(EditableRow)

export class EditableCell extends React.Component {
  state = {
    editing: false,
    datePickerOpen: true
  };

  toggleEdit = () => {
    const editing = !this.state.editing
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus()
      }
    })
  };

  save = (e) => {
    const { record, handleSave } = this.props
    this.form.validateFields((error, values) => {
      if (values.type === 'dateTime') {
        this.toggleEdit()
        handleSave({ record, values: { type: values.type, value: e } })
        return
      } else {
        if (error && error[e.currentTarget.id]) {
          return
        }
        this.toggleEdit()
        handleSave({ record, values: { type: values.type, value: values.acting_amount } })
      }
    })
  };

  renderInput = form => {
    this.form = form
    const { children, dataIndex, record, title } = this.props
    const { editing } = this.state
    return editing ? (<>
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          initialValue: record[dataIndex] === 0 ? '' : record[dataIndex],
        })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
      <Form.Item style={{ display: 'none' }}>
        {form.getFieldDecorator('type', {
          initialValue: 'input',
        })(<Input />)}
      </Form.Item>
    </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    )
  };

  renderDateTime = form => {
    this.form = form
    const { children, dataIndex, record, title } = this.props
    const { editing, datePickerOpen } = this.state
    return editing ? (<>
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: moment(record[dataIndex]).isValid() ? moment(record[dataIndex]) : moment(),
        })(<DatePicker
          locale={dateLocale}
          ref={node => (this.input = node)}
          format={'DD.MM.YYYY'}
          onClick={(e) => e.preventDefault()}
          onChange={(date) => {
            console.log('change')
            //this.setState({ datePickerOpen: false })
            if (date) {
              this.save(date)
            }
          }}
        />)}
      </Form.Item>
      <Form.Item style={{ display: 'none' }}>
        {form.getFieldDecorator('type', {
          initialValue: 'dateTime',
        })(<Input />)}
      </Form.Item>
    </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    )
  };

  render() {
    const {
      editable,
      dateTime,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{dateTime ? this.renderDateTime : this.renderInput}</EditableContext.Consumer>
        ) : (children)}
      </td>
    )
  }
}