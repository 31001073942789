import React, {useEffect, useState} from 'react'
import {ArrowDownOutlined, ArrowUpOutlined, InfoCircleOutlined, PieChartOutlined} from '@ant-design/icons';
import {
    Card,
    Col,
    Progress,
    Row,
    Statistic,
    Table,
    Tag,
    Badge,
    Tooltip,
    Empty,
    Divider,
} from "antd";

import {useStoreActions, useStoreState} from "easy-peasy";
import API from "../../../services/api";
import moment from 'moment'
import {Bar} from 'react-chartjs-2';
import InputCell from "../../InputCell";
import {PaObjectTotalsV2} from "../projections/Projection"
import useWindowSize from '../../../services/useWindowSize'
import CalendarHeatmap from 'react-calendar-heatmap'
import 'react-calendar-heatmap/dist/styles.css'
import '../../../heatmap.css'

const pageDivStyle = {padding: '10px 0px'}

const formatCurrency = (text) => {

    const nodata = 0
    if (text) {
        if (typeof text == 'number') return text.toLocaleString('et-ee', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        })
        else return text
    }
    return nodata.toLocaleString('et-ee', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    })
}

const PaCostBudgetOverview = (size = "middle") => {

    const objectCostData = useStoreState(state => state.object.costs)
    const meta = useStoreState(state => state.object.meta)
    const [resultData, setResultData] = useState([{}])

    useEffect(() => {
            let payload = {
                obj_id: meta.idobject
            }
            API("/api/result", false, "get", payload)
                .then((res) => setResultData(res.data))
        },
        // eslint-disable-next-line
        [objectCostData])


    const columns = [
        {
            title: '',
            key: "opener"
        },
        {
            title: '#',
            dataIndex: 'bill_type',
            key: 'bill_type',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Kulude loend / kulurida',
            dataIndex: 'bill_name',
            key: 'bill_name',
        },
        {
            title: 'Eesmärk',
            dataIndex: 'target_sum',
            key: 'target_sum',
            render: (text, record, index) => text ? "€ " + text.toFixed(0).toString() : ""
        }, {
            title: <small>Tööjõud begin</small>,
            key: "ToojoudBegin",
            dataIndex: "budget8",
            render: (text, record, index) => ResultBlock(record, 8)
        }, {
            title: <small>Tööjõud </small>,
            key: "Toojoud",
            dataIndex: "budget1",
            render: (text, record, index) => ResultBlock(record, 1)
        }, {
            title: <small>Mehhanismid Verston</small>,
            key: "ToojoudBegin",
            dataIndex: "budget2",
            render: (text, record, index) => ResultBlock(record, 2)
        }, {
            title: <small>Mehhanismid rent</small>,
            key: "ToojoudBegin",
            dataIndex: "budget3",
            render: (text, record, index) => ResultBlock(record, 3)
        }, {
            title: <small>Transport</small>,
            key: "ToojoudBegin",
            dataIndex: "budget4",
            render: (text, record, index) => ResultBlock(record, 4)
        }, {
            title: <small>Materjalid</small>,
            key: "ToojoudBegin",
            dataIndex: "budget5",
            render: (text, record, index) => ResultBlock(record, 5)
        }, {
            title: <small>Muud kulud</small>,
            key: "ToojoudBegin",
            dataIndex: "budget6",
            render: (text, record, index) => ResultBlock(record, 6)
        }, {
            title: <small>Üldkulud</small>,
            key: "ToojoudBegin",
            dataIndex: "budget9",
            render: (text, record, index) => ResultBlock(record, 9)
        }, {
            title: <small>Alltöövõtt</small>,
            key: "subcontracting",
            dataIndex: "budget10",
            render: (text, record, index) => ResultBlock(record, 10)
        }

    ]


    return (
        <Table columns={columns} dataSource={resultData} size="small"/>
    )
}

function getCellReference(index, field, record) {

    let uniq_index = 0
    if ('uniq_index' in record) {
        uniq_index = record.uniq_index
    }

    return uniq_index.toString() + index.toString() + '-' + field
}

export const PaCostBudgetPerOffer = ({size = "middle", offer_nr, content = false}) => {

    const [resultData, setResultData] = useState([{}])
    const [activeCell, setActiveCell] = useState([false])
    const [loading, setLoading] = useState(true)

    let payload = {offer_nr: offer_nr}

    const handleRowUpdate = async (payload) => {
        await API("/api/budget", false, "post", payload)
        await fetchResult(payload)
    }

    const fetchResult = async (payload) => {
        let res = await API("/api/result", false, "get", payload)
        await setResultData(res.data)
    }

    //console.log("offer_nr", offer_nr)

    useEffect(() => {
            fetchResult(payload).then((r) => setLoading(false))
        },
        // eslint-disable-next-line
        [offer_nr])

    let rows = resultData.length

    function handleChange(value, field, index, record, cost_type, cellref) {

        //console.log("handling change...", value, field, index, record['idoffer'])
        //console.log("cellref", cellref)
        // if either previous value or new value exist
        if (record[field] || value) {

            let newValueString = value ? value.toString() : false
            let oldValueString = record[field] ? record[field].toString() : false

            if (newValueString !== oldValueString) {
                //console.log("ONBLUR/onchange/change detected, updating...")

                let payload = {
                    idoffer: record.idoffer ? record.idoffer : null,
                    cost_type: cost_type,
                    offer_nr: offer_nr,
                    id_bills_types: record.bill_type || record.has_bill_type,
                    sum: value,
                }

                console.log('cahnge', payload, value, field, index, record)

                handleRowUpdate(payload).then(() => setActiveCell(false))

            } else setActiveCell(false)
        } else setActiveCell(false)
    }


    const getInputCell = (index, field, rowsCount, record, cost_type) => <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference(index, field, record))}
        onBlur={value => handleChange(value, field, index, record, cost_type, getCellReference(index, field, record))}
        onUpdate={value => handleChange(value, field, index, record, cost_type, getCellReference(index, field, record))}
        onMoveUp={(value) => setActiveCell(getCellReference(index > 0 ? index + value : 0, field, record))}
        onMoveDown={(value) => setActiveCell(getCellReference(index < rowsCount - 1 ? index + value : rowsCount - 1, field, record))}
        active={activeCell === getCellReference(index, field, record)}
        defaultValue={record[field]}/>

    const columns = [
        {
            title: '',
            key: "opener"
        },
        {
            title: 'Bill #',
            dataIndex: 'bill_type',
            key: 'bill_type',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Billi nimetus / kulurida',
            dataIndex: 'bill_name',
            key: 'bill_name',
        },
        {
            title: 'Eesmärk',
            dataIndex: 'target_sum',
            key: 'target_sum',
            render: (text, record, index) => text ? "€ " + text.toFixed(0).toString() : ""
        }, {
            title: <small>Tööjõud begin</small>,
            key: "budget8",
            dataIndex: "budget8",
            render: (text, record, index) => content ? getInputCell(index, 'budget8', rows, record, 8) : ResultBlock(record, 8)
        }, {
            title: <small>Tööjõud </small>,
            key: "budget1",
            dataIndex: "budget1",
            render: (text, record, index) => content ? getInputCell(index, 'budget1', rows, record, 1) : ResultBlock(record, 1)
        }, {
            title: <small>Mehhanismid Verston</small>,
            key: "budget2",
            dataIndex: "budget2",
            render: (text, record, index) => content ? getInputCell(index, 'budget2', rows, record, 2) : ResultBlock(record, 2)
        }, {
            title: <small>Mehhanismid rent</small>,
            key: "budget3",
            dataIndex: "budget3",
            render: (text, record, index) => content ? getInputCell(index, 'budget3', rows, record, 3) : ResultBlock(record, 3)
        }, {
            title: <small>Transport</small>,
            key: "budget4",
            dataIndex: "budget4",
            render: (text, record, index) => content ? getInputCell(index, 'budget4', rows, record, 4) : ResultBlock(record, 4)
        }, {
            title: <small>Materjalid</small>,
            key: "budget5",
            dataIndex: "budget5",
            render: (text, record, index) => content ? getInputCell(index, 'budget5', rows, record, 5) : ResultBlock(record, 5)
        }, {
            title: <small>Muud kulud</small>,
            key: "budget6",
            dataIndex: "budget6",
            render: (text, record, index) => content ? getInputCell(index, 'budget6', rows, record, 6) : ResultBlock(record, 6)
        }, {
            title: <small>Alltöövõtt</small>,
            key: "budget10",
            dataIndex: "budget10",
            render: (text, record, index) => content ? getInputCell(index, 'budget10', rows, record, 10) : ResultBlock(record, 10)
        }

    ]


    return (
        <Table columns={columns} dataSource={resultData} size={size} loading={loading}/>
    )
}


const ResultBlock = (row, costType) => {


    let budgetId = "budget" + costType.toString()
    let fulfilledId = "fulfilled" + costType.toString()

    let budget = row[budgetId] ? row[budgetId] : null
    let fulfilled = row[fulfilledId] ? row[fulfilledId] : 0
    let percent = (fulfilled / budget * 100).toFixed(0)

    let colour = hsl_col_perc(percent, 160, red);

    return <div>
        <div style={{background: colour, opacity: '50%'}}>
            {fulfilled ? fulfilled.toFixed(0) + "/" : null}
            {budget ? budget.toFixed(0) : null}
        </div>
        <div>
            {budget ? percent <= 100 ?
                <Progress percent={percent} size="small"/>
                :
                <Progress percent={percent - 100} status="exception" size="small"/>
                : null}
        </div>
    </div>
}

const red = 0

function hsl_col_perc(percent, start, end) {
    var a = percent / 100,
        b = (end - start) * a,
        c = b + start;

    // Return a CSS HSL string
    return 'hsl(' + c + ', 100%, 90%)';
}


const BudgetBlockV2 = () => {
    const meta = useStoreState(state => state.object.meta)
    const resultAndPrognosis = useStoreState(state => state.resultV2.result)
    const incomeData = useStoreState(state => state.object.income);

    const offeredToClient = incomeData.reduce((sum, r) => sum + r.hinnapakkumise_total, 0).toFixed(0)
    const offeredTarget = incomeData.reduce((sum, r) => sum + r.offered_total, 0).toFixed(0)
    const incomePrognosisTotal = incomeData.reduce((sum, r) => sum + r.prognosis_total, 0).toFixed(0)

    console.log("incomeprognosistotal", incomePrognosisTotal)

    const objectCostData = useStoreState(state => state.object.costs)

    const dataRowsComposition = [
        {
            bill_name: 'Platsikulud',
            path: '0.',
            in: ['0']
        },
        {
            bill_name: 'Üldised',
            path: '1.',
            in: ['1']
        },
        {
            bill_name: 'Ettevalmistustööd',
            path: '2.',
            in: ['2']
        },
        {
            bill_name: 'Mullatööd',
            path: '3.',
            in: ['3']
        },
        {
            bill_name: 'Katend',
            path: '4.',
            in: ['4']
        },
        {
            bill_name: 'Tehnovõrgud',
            path: '5-9.',
            in: ['5', '6', '7', '8', '9']
        },
        {
            bill_name: 'Muu',
            path: '10.',
            in: ['10']
        },
    ]

    let data = []

    dataRowsComposition.forEach(datarow => {

        let resultSum = 0
        let resultData = objectCostData ? Object.values(objectCostData).filter(r => r.assignments) : []

        resultData.forEach(r => {
            r.assignments.forEach(v => {
                const assignmentBill = v.bill_path.split('/')[0]
                if (datarow.in.includes(assignmentBill)) resultSum = resultSum + v.assignment_sum
            })
        })

        const budgetSum = resultAndPrognosis.reduce((sum, row) => datarow.in.includes(row.bill_1st_level) && row.result_row_type === 'budgetrow' ? sum = sum + row.sum_at_offer : sum, 0)
        const targetSum = resultAndPrognosis.reduce((sum, row) => datarow.in.includes(row.bill_1st_level) && row.result_row_type === 'budgetrow' ? sum = sum + row.sum_target : sum, 0)

        const resultKpi = targetSum > resultSum ?
            <div><ArrowDownOutlined style={{color: 'green'}}/> {((1 - (resultSum / targetSum)) * 100).toFixed(1)} %
            </div>
            :
            <div><ArrowUpOutlined style={{color: 'red'}}/> {(((resultSum / targetSum)) * 100).toFixed(1)} %</div>

        data.push({
            path: datarow.path,
            bill_name: datarow.bill_name,
            unit: '',
            budgetKPI: '',
            budgetSum: budgetSum,
            targetKPI: '',
            targetSum: targetSum,
            resultKPI: targetSum && resultSum ? resultKpi : null,
            resultSum: resultSum,
            prognosisKPI: '',
            prognosisSum: resultAndPrognosis.reduce((sum, row) => datarow.in.includes(row.bill_1st_level) && row.result_row_type === 'budgetrow' ? sum = sum + row.sum_in_the_end : sum, 0),
        })
    })

    let unassignedActualCostsSum = objectCostData ?
        Object.values(objectCostData)
            .filter(r => !r.assignments)
            .reduce((sum, r) => sum + r.row_sum, 0).toFixed(0)
        :
        0

    let actualCostsSum = objectCostData ?
        Object.values(objectCostData).reduce((sum, r) => sum + r.row_sum, 0).toFixed(0)
        :
        0

    const incomeSoFar = incomeData.reduce((sum, r) => r.is_target_offer === 1 ? sum + (r.fulfilled_income_total) : sum, 0)

    const objectProfit = incomeSoFar - actualCostsSum
    const objectCostInTheEnd = resultAndPrognosis.reduce((sum, row) => row.result_row_type === 'budgetrow' ? sum = sum + row.sum_in_the_end : sum, 0)
    const objectProfitInTheEnd = incomePrognosisTotal - objectCostInTheEnd

    // unassigned total:
    data.push({
        path: '',
        bill_name: 'Billideta kulud',
        unit: 'EUR',
        budgetKPI: '',
        budgetSum: resultAndPrognosis.reduce((sum, row) => !row.bill_1st_level && row.result_row_type === 'budgetrow' ? sum = sum + row.sum_at_offer : sum, 0),
        targetKPI: '',
        targetSum: resultAndPrognosis.reduce((sum, row) => !row.bill_1st_level && row.result_row_type === 'budgetrow' ? sum = sum + row.sum_target : sum, 0),
        resultKPI: '',
        resultSum: unassignedActualCostsSum,
        prognosisKPI: '',
        prognosisSum: resultAndPrognosis.reduce((sum, row) => !row.bill_1st_level && row.result_row_type === 'budgetrow' ? sum = sum + row.sum_in_the_end : sum, 0),
    })


    // costs total:

    data.push({
        path: '',
        bill_name: 'Kulud kokku',
        unit: 'EUR',
        budgetKPI: '',
        budgetSum: resultAndPrognosis.reduce((sum, row) => row.result_row_type === 'budgetrow' ? sum = sum + row.sum_at_offer : sum, 0),
        targetKPI: '',
        targetSum: resultAndPrognosis.reduce((sum, row) => row.result_row_type === 'budgetrow' ? sum = sum + row.sum_target : sum, 0),
        resultKPI: '',
        resultSum: actualCostsSum,
        prognosisKPI: '',
        prognosisSum: objectCostInTheEnd
    })

    data.push({
        path: '',
        bill_name: 'Projekti kasum',
        unit: 'EUR',
        budgetKPI: '',
        budgetSum: offeredToClient - resultAndPrognosis.reduce((sum, row) => row.result_row_type === 'budgetrow' ? sum = sum + row.sum_at_offer : sum, 0),
        targetKPI: '',
        targetSum: offeredTarget - resultAndPrognosis.reduce((sum, row) => row.result_row_type === 'budgetrow' ? sum = sum + row.sum_target : sum, 0),
        resultKPI: '',
        resultSum: objectProfit,
        prognosisKPI: '',
        prognosisSum: objectProfitInTheEnd
    })

    data.push({
        path: '',
        bill_name: 'Üldkulu',
        unit: 'EUR',
        budgetKPI: (meta.overhead_rate * 100).toString() + '%',
        budgetSum: offeredToClient * meta.overhead_rate,
        targetKPI: '',
        targetSum: offeredTarget * meta.overhead_rate,
        resultKPI: '',
        resultSum: incomeSoFar * meta.overhead_rate,
        prognosisKPI: '',
        prognosisSum: incomePrognosisTotal * meta.overhead_rate,
    })

    data.push({
        path: '',
        bill_name: 'Kasum',
        unit: 'EUR',
        budgetKPI: '',
        budgetSum: offeredToClient - (offeredToClient * meta.overhead_rate) - resultAndPrognosis.reduce((sum, row) => row.result_row_type === 'budgetrow' ? sum = sum + row.sum_at_offer : sum, 0),
        targetKPI: '',
        targetSum: offeredTarget - (offeredTarget * meta.overhead_rate) - resultAndPrognosis.reduce((sum, row) => row.result_row_type === 'budgetrow' ? sum = sum + row.sum_target : sum, 0),
        resultKPI: '',
        resultSum: objectProfit - (incomeSoFar * meta.overhead_rate),
        prognosisKPI: '',
        prognosisSum: objectProfitInTheEnd - (incomePrognosisTotal * meta.overhead_rate)
    })

    data.push({
        path: '',
        bill_name: 'Tulud kokku',
        unit: 'EUR',
        budgetKPI: '',
        budgetSum: offeredToClient,
        targetKPI: '',
        targetSum: offeredTarget,
        resultKPI: '',
        resultSum: incomeSoFar,
        prognosisKPI: '',
        prognosisSum: incomePrognosisTotal
    })

    const dataRow =
        {
            path: '',
            bill_name: 'ss',
            unit: '',
            budgetKPI: '',
            budgetSum: '',
            targetKPI: '',
            targetSum: '',
            resultKPI: '',
            resultSum: '',
            prognosisKPI: '',
            prognosisSum: '',
        }

    function format_sum(text, record, index) {
        //return text ? parseFloat(text / 1000).toFixed(0) + ' k' : ''
        return text ? parseFloat(text).toFixed(0) : ''
        //return text //? parseFloat(text / 1000).toFixed(0) + ' k' : ''
    }

    const columns = [
        {
            title: '',
            dataIndex: 'path',
            key: 'path',


        },
        {
            title: 'Bill',
            dataIndex: 'bill_name',
            key: 'bill_name',
            render: (text, record, index) => record.path ? text : <b>{text}</b>

        },
        {
            title: ' ',
            dataIndex: 'unit',
            key: 'unit',

        },

        {
            title: '',
            dataIndex: 'budgetKPI',
            key: 'budgetKPI',
            className: 'projection_actual'
        },
        {
            title: 'Pakkumine',
            dataIndex: 'budgetSum',
            key: 'budgetSum',
            align: 'right',
            className: 'projection_actual',
            render: format_sum

        },

        {
            title: '',
            dataIndex: 'targetKPI',
            key: 'targetKPI',
            className: 'projection_highlight',
        },
        {
            title: 'Eesmärk',
            dataIndex: 'targetSum',
            key: 'targetSum',
            align: 'right',
            className: 'projection_highlight',
            render: format_sum
        },


        {
            title: '',
            dataIndex: 'resultKPI',
            key: 'resultKPI',
            className: 'projection_final',
        },
        {
            title: 'Tegelik',
            dataIndex: 'resultSum',
            key: 'resultSum',
            align: 'right',
            className: 'projection_final',
            render: format_sum
        },

        {
            title: '',
            dataIndex: 'prognosisKPI',
            key: 'amount_at_offer',
            className: 'projection_actual',
        },
        {
            title: 'Prognoos',
            dataIndex: 'prognosisSum',
            key: 'prognosisSum',
            align: 'right',
            className: 'projection_actual',
            render: format_sum

        }
    ]

    return <Table dataSource={data}
                  columns={columns}
                  size="small"
                  pagination={false} //{{
        // defaultPageSize: 40,
        // showSizeChanger: false
        // }}
        //rowClassName={(record, index) => {
        //    if (record.result_row_type === 'bill') return 'budget_titlerow'
        //}}
        //scroll={{x: 1900, y: 600}}
        //pagination={{
        //    defaultPageSize: 40,
        //    showSizeChanger: true,
        //    onChange: (page, pageSize) => console.log("page: ", page, "pagesize: ", pageSize),
        //    onShowSizeChange: (current, size) => console.log("page: ", current, "pagesize: ", size)
        //}}
    />

}

const OverviewV2 = () => {

    const [activeTab, setActiveTab] = useState('project')
    const meta = useStoreState(state => state.object.meta)


    const tabList = [
        {
            key: 'project',
            tab: 'Üldine',
        },
        {
            key: 'detail',
            tab: 'Billide kaupa',
        },

    ];

    const contentList = {
        detail: <BudgetBlockV2/>,
        project: <PaObjectTotalsV2 objectSelector={true}/>,
    };

    return (
        <div className="mt-1">
            <Card
                style={{width: '100%'}}
                title={<div className="ml-2">Ülevaade</div>}
                extra={
                    <div> Projektijuht: <b>{meta.proj_m_name}</b> Tähtaeg: <b>{moment(meta ? meta.end_planned : null).format("DD.MM.YY")}</b>
                    </div>}
                tabList={tabList}
                activeTabKey={activeTab}
                onTabChange={key => setActiveTab(key)}
            >
                {contentList[activeTab]}
            </Card>
        </div>
    )

}

const StatisticsBlock = () => {

    const meta = useStoreState(state => state.object.meta)
    const objectCostData = useStoreState(state => state.object.costs)
    const paverInUseDays = useStoreState(state => state.object.paverInUseDays)
    const incomeData = useStoreState(state => state.object.income);
    const prognosisData = useStoreState(state => state.object.prognosis);

    const strprognosis = useStoreState(state => state.object.structuredPrognosisData)
    let subtotals = useStoreState(state => state.resultV2.objectsTotals)

    const offeredToClient = incomeData.reduce((sum, r) => sum + r.hinnapakkumise_total, 0).toFixed(0)
    const akteeritavToClient = incomeData.reduce((sum, r) => sum + r.akteerimiseks_total, 0).toFixed(0)


    const target = strprognosis.reduce((sum, val) => val.offered_total ? sum + val.offered_total : sum, 0).toFixed(0)

    const targetProfit = akteeritavToClient - target - (akteeritavToClient * meta.overhead_rate)
    const targetProfitablility = (targetProfit / akteeritavToClient) * 100

    const costCurrent = objectCostData ? Object.values(objectCostData).reduce((sum, r) => sum + r.row_sum, 0) : 0
    const costCurrentPercent = ((costCurrent / target) * 100).toFixed(2)

    const prognosed = strprognosis.reduce((sum, val) => val.prognosis_total ? sum + val.prognosis_total : sum, 0).toFixed(0)
    const prognosedIncome = strprognosis.reduce((sum, val) => val.income_prognosis_total ? sum + val.income_prognosis_total : sum, 0).toFixed(0)
    const prognosedWithOverhead = (prognosed / 1) + (prognosedIncome * meta.overhead_rate)

    const incomeCurrentTarget = incomeData.reduce((sum, r) => r.is_target_offer === 1 ? sum + (r.fulfilled_income_total) : sum, 0)
    const incomeCurrentTargetPercent = (incomeCurrentTarget / akteeritavToClient * 100).toFixed()

    const incomeCurrentOther = incomeData.reduce((sum, r) => r.is_target_offer !== 1 ? sum + (r.fulfilled_income_total) : sum, 0).toFixed(0)

    const costOverheadCurrent = (incomeCurrentTarget * meta.overhead_rate) + (incomeCurrentOther * meta.overhead_rate)
    const costCurrentWithOverhead = costOverheadCurrent + (costCurrent / 1)

    const resultCurrent = (incomeCurrentTarget / 1) + (incomeCurrentOther / 1) - (costCurrent / 1)
    const resultCurrentPercent = resultCurrent / ((incomeCurrentTarget / 1) + (incomeCurrentOther / 1)) * 100

    if (subtotals) subtotals = subtotals.find(r => r.idobject === meta.idobject)

    const prognosedToTargetBudgetDifference = (prognosed / target) * 100
    const prognosedToTargetIncomeDifference = (prognosedIncome / akteeritavToClient) * 100

    const objectComplete = incomeCurrentTarget / akteeritavToClient

    const overheadTargetCompleted = () => {
        if (resultCurrent > (akteeritavToClient * meta.overhead_rate)) {
            return 100
        } else {
            return (resultCurrent / (akteeritavToClient * meta.overhead_rate)) * 100
        }
    }


    //console.log("prognosedincome", prognosedIncome)

    //console.log("prognosis", prognosisData)
    //console.log("oh_rate", prognosedIncome * meta.overhead_rate)

    if (meta && meta.costing_version === 2) return (
        <div>
            {/* <Row gutter={16}>
                <Card className="m-1 p-1" size="small">
                    <div className="d-flex justify-content-between">
                        <div>Üldkulu
                            määr: <b>{(meta.overhead_rate * 100).toFixed(2)}%</b> Projektijuht: <b>{meta.proj_m_name}</b> Tähtaeg: <b>{moment(meta ? meta.end_planned : null).format("DD.MM.YY")}</b>
                        </div>
                        {paverInUseDays > 0 ?
                            <div>Laotatud päevi: <Badge count={paverInUseDays} style={{backgroundColor: '#1890ff'}}/>
                            </div> : null}
                        <div>Objekt valminud: <Progress percent={(objectComplete * 100).toFixed(0)} size="middle"
                                                        style={{width: 150}}/></div>
                    </div>
                </Card>
            </Row> */}

            <Row gutter={12}>
                <Col md={16} sm={24}>

                    <OverviewV2/>

                </Col>

                <Col md={8} sm={24}>
                    <Card title="Teostatud"
                          className="mt-1"
                          extra={paverInUseDays > 0 ?
                              <div>Laotatud päevi: <Badge count={paverInUseDays}
                                                          style={{backgroundColor: '#1890ff'}}/>
                              </div> : null}
                          bordered={false}
                        // extra={<Tag color={resultCurrent > 0 ? "green" : "red"}>€ {resultCurrent.toFixed(0)} </Tag>}>
                        //extra={
                        //    <div>
                        //        <div className="mobileonly">
                        //            <div>
                        //                <small>Tulem:</small>
                        //                <Tag
                        //                    color={resultCurrent > 0 ? '#3f8600' : '#860401'}>
                        //                    {((resultCurrent - costOverheadCurrent) / 1000).toFixed(0).toString() + 'k'}
                        //                </Tag>
                        //                <div style={{color: resultCurrentPercent > 0 ? '#3f8600' : '#860401'}}>
                        //                    {resultCurrentPercent > 0 ? <Icon type="arrow-up"/> :
                        //                        <Icon type="arrow-down"/>} {resultCurrentPercent.toFixed(2)} %
                        //                </div>
                        //            </div>
                        //        </div>
                        //        <div className="fullscreenonly">
                        //            <div className='d-flex align-items-baseline'>
                        //                <small>Reaalne tulem:</small>
                        //                <Statistic
                        //                    valueStyle={{color: resultCurrent > 0 ? '#3f8600' : '#860401'}}
                        //                    value={((resultCurrent - costOverheadCurrent) / 1000).toFixed(0).toString() + 'k'}
                        //                    className="mr-2"
                        //                />
                        //                <Statistic
                        //                    value={resultCurrentPercent}
                        //                    valueStyle={{color: resultCurrentPercent > 0 ? '#3f8600' : '#860401'}}
                        //                    prefix={resultCurrentPercent > 0 ? <Icon type="arrow-up"/> :
                        //                        <Icon type="arrow-down"/>}
                        //                    precision={2}
                        //                    suffix="%"
                        //                />
                        //            </div>
                        //        </div>
                        //    </div>
                        //}
                    >
                        <Row>
                            <Col span={11}>
                                <div className="d-flex justify-content-between">
                                    <Statistic
                                        title="Kasum"
                                        valueStyle={{color: resultCurrent > 0 ? '#3f8600' : '#860401'}}
                                        value={formatCurrency(resultCurrent - costOverheadCurrent)}
                                        className="mr-2"
                                    />

                                </div>
                            </Col>
                            <Col span={2}/>
                            <Col span={11}>
                                {/* <div>
                                    <div>Objekt valminud:</div>
                                    <div><Progress percent={(objectComplete * 100).toFixed(0)}
                                                   size="middle"
                                                   style={{width: 150}}/>
                                    </div>
                                </div> */}
                                <div className="d-flex">

                                    <div>
                                        <Statistic
                                            title="Marginaal"
                                            value={Number.isFinite(resultCurrentPercent) ? resultCurrentPercent.toFixed(2) : 0}
                                            valueStyle={{color: resultCurrentPercent > 0 ? '#3f8600' : '#860401'}}
                                            prefix={resultCurrentPercent > 0 ? <ArrowUpOutlined/> :
                                                <ArrowDownOutlined/>}
                                            precision={2}
                                            suffix="%"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}><Divider/> </Col>

                        </Row>
                        <Row>
                            <Col span={11}>
                                <Statistic title="Kulud" value={formatCurrency(costCurrent)}/>
                                <div style={{width: '80%'}}>
                                    <Progress percent={subtotals ? parseInt(subtotals.completed_by_cost * 100) : 0}
                                              size={"small"}/>
                                </div>
                            </Col>
                            <Col span={2}/>
                            <Col span={11}>
                                <Statistic title="Tulud" value={formatCurrency(incomeCurrentTarget)}/>
                                <div style={{width: '80%'}}>
                                    <Progress percent={subtotals ? parseInt(subtotals.completed_by_income * 100) : 0}
                                              size={"small"}/>
                                </div>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col span={11}>
                                <Statistic
                                    title="Kulud koos üldkuludega"
                                    value={costCurrentWithOverhead}
                                    precision={2}
                                    valueStyle={{color: '#3f8600'}}
                                />
                            </Col>
                            <Col span={2}/>
                            <Col span={11}>
                                <Statistic title="Tulud lisatöödest (bill 10) " value={incomeCurrentOther}/>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col span={24}><Divider/> </Col>

                        </Row>
                        {/* <Row>
                            <Col span={6}>
                                <h5>Kasum:</h5>

                            </Col>
                            <Col span={2}/>
                            <Col span={16}>

                                <div className="d-flex">
                                    <Statistic
                                        valueStyle={{color: resultCurrent > 0 ? '#3f8600' : '#860401'}}
                                        value={'€ ' + ((resultCurrent - costOverheadCurrent)).toFixed(0).toString()}
                                        className="mr-2"
                                    />
                                    <Statistic
                                        value={resultCurrentPercent}
                                        valueStyle={{color: resultCurrentPercent > 0 ? '#3f8600' : '#860401'}}
                                        prefix={resultCurrentPercent > 0 ? <Icon type="arrow-up"/> :
                                            <Icon type="arrow-down"/>}
                                        precision={2}
                                        suffix="%"
                                    />

                                </div>


                                <div>

                                    <Progress
                                        percent={overheadTargetCompleted().toFixed(0)}
                                        size={"small"}/></div>
                                 <span>Reaalne kasum</span>
                                <Progress
                                    percent={0}
                                    successPercent={0}
                                    size={"small"}/>
                            </Col>
                        </Row> */}

                    </Card>
                </Col>


            </Row>
        </div>
    );

    return (
        <div>
            <Row gutter={16}>
                <Card className="m-1 p-1" size="small">
                    <div className="d-flex justify-content-between">
                        <div>Üldkulu
                            määr: <b>{(meta.overhead_rate * 100).toFixed(2)}%</b> Projektijuht: <b>{meta.proj_m_name}</b> Tähtaeg: <b>{moment(meta ? meta.end_planned : null).format("DD.MM.YY")}</b>
                        </div>
                        {paverInUseDays > 0 ?
                            <div>Laotatud päevi: <Badge count={paverInUseDays} style={{backgroundColor: '#1890ff'}}/>
                            </div> : null}
                        <div>Objekt valminud: <Progress percent={(objectComplete * 100).toFixed(0)} size="middle"
                                                        style={{width: 150}}/></div>
                    </div>
                </Card>
            </Row>

            <Row gutter={12}>
                <Col md={8} sm={24}>
                    <Card title="Eesmärk"
                          className="mt-1"
                          bordered={false}
                        // extra={
                        //     <div>
                        //         <Tag color="green">{(targetProfit / 1000).toFixed(0)} k</Tag>
                        //         <Tag > Marginaal: {(targetProfitablility).toFixed(2)} %</Tag>
                        //     </div>
                        // }
                          extra={
                              <div>
                                  <div className="mobileonly">
                                      <div>
                                          <small>Tulem:</small>
                                          <Tag
                                              color={targetProfit > 0 ? '#3f8600' : '#860401'}>
                                              {(targetProfit / 1000).toFixed(0).toString() + 'k'}
                                          </Tag>
                                          <div style={{color: targetProfitablility > 0 ? '#3f8600' : '#860401'}}>
                                              {targetProfitablility > 0 ? <ArrowUpOutlined/> :
                                                  <ArrowDownOutlined/>} {targetProfitablility.toFixed(2)} %
                                          </div>
                                      </div>
                                  </div>
                                  <div className="fullscreenonly">
                                      <div className='d-flex align-items-baseline'>
                                          <small>Tulem:</small>
                                          <Statistic
                                              valueStyle={{color: targetProfit > 0 ? '#3f8600' : '#860401'}}
                                              value={(targetProfit / 1000).toFixed(0).toString() + 'k'}
                                              className="mr-2"
                                          />
                                          <Statistic
                                              value={targetProfitablility}
                                              valueStyle={{color: targetProfitablility > 0 ? '#3f8600' : '#860401'}}
                                              prefix={targetProfitablility > 0 ? <ArrowUpOutlined/> :
                                                  <ArrowDownOutlined/>}
                                              precision={2}
                                              suffix="%"
                                          />
                                      </div>
                                  </div>
                              </div>
                          }
                    >
                        <Row>
                            <Col span={12}>
                                <Statistic title="Hinnapakkumine" value={offeredToClient}/>
                            </Col>
                            <Col span={12}>
                                <Statistic
                                    title="Eesmärkeelarve (kulu)"
                                    value={target}/>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Statistic title="Kliendile akteeritav"
                                           value={akteeritavToClient}/>
                            </Col>
                            <Col span={12}>
                                <Statistic
                                    title="Üldkulu"
                                    value={akteeritavToClient * meta.overhead_rate}
                                    precision={0}/>
                            </Col>
                        </Row>

                    </Card>
                </Col>
                <Col md={8} sm={24}>
                    <Card title="Tegelik"
                          className="mt-1"
                          bordered={false}
                        // extra={<Tag color={resultCurrent > 0 ? "green" : "red"}>€ {resultCurrent.toFixed(0)} </Tag>}>
                          extra={
                              <div>
                                  <div className="mobileonly">
                                      <div>
                                          <small>Tulem:</small>
                                          <Tag
                                              color={resultCurrent > 0 ? '#3f8600' : '#860401'}>
                                              {((resultCurrent - costOverheadCurrent) / 1000).toFixed(0).toString() + 'k'}
                                          </Tag>
                                          <div style={{color: resultCurrentPercent > 0 ? '#3f8600' : '#860401'}}>
                                              {resultCurrentPercent > 0 ? <ArrowUpOutlined/> :
                                                  <ArrowDownOutlined/>} {resultCurrentPercent.toFixed(2)} %
                                          </div>
                                      </div>
                                  </div>
                                  <div className="fullscreenonly">
                                      <div className='d-flex align-items-baseline'>
                                          <small>Reaalne tulem:</small>
                                          <Statistic
                                              valueStyle={{color: resultCurrent > 0 ? '#3f8600' : '#860401'}}
                                              value={((resultCurrent - costOverheadCurrent) / 1000).toFixed(0).toString() + 'k'}
                                              className="mr-2"
                                          />
                                          <Statistic
                                              value={resultCurrentPercent}
                                              valueStyle={{color: resultCurrentPercent > 0 ? '#3f8600' : '#860401'}}
                                              prefix={resultCurrentPercent > 0 ? <ArrowUpOutlined/> :
                                                  <ArrowDownOutlined/>}
                                              precision={2}
                                              suffix="%"
                                          />
                                      </div>
                                  </div>
                              </div>
                          }>
                        <Row>
                            <Col span={11}>
                                <Statistic title="Kulud" value={costCurrent}/>
                                <Progress percent={costCurrentPercent} size={"small"}/>
                            </Col>
                            <Col span={2}/>
                            <Col span={11}>
                                <Statistic title="Tulud eesmärkeelarvest" value={incomeCurrentTarget}/>
                                <Progress percent={incomeCurrentTargetPercent} size={"small"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={11}>
                                <Statistic
                                    title="Kulud koos üldkuludega"
                                    value={costCurrentWithOverhead}
                                    precision={2}
                                    valueStyle={{color: '#3f8600'}}
                                />
                            </Col>
                            <Col span={2}/>
                            <Col span={11}>
                                <Statistic title="Tulud lisatöödest " value={incomeCurrentOther}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}><Divider/> </Col>

                        </Row>
                        <Row>
                            <Col span={11}>
                                <div>Üldkulu eesmärk:</div>

                            </Col>
                            <Col span={2}/>
                            <Col span={11}>
                                <div>
                                    <Progress
                                        percent={overheadTargetCompleted().toFixed(0)}
                                        size={"small"}/></div>
                                {/* <span>Reaalne kasum</span>
                                <Progress
                                    percent={0}
                                    successPercent={0}
                                    size={"small"}/> */}
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col md={8} sm={24}>
                    <Card title="Prognoos"
                          bordered={false}
                          className="mt-1"
                          extra={
                              <div>
                                  <div className="mobileonly">
                                      <div>
                                          <small>Tulem:</small>
                                          <Tag
                                              color={(prognosedIncome - prognosedWithOverhead) > 0 ? '#3f8600' : '#860401'}>
                                              {((prognosedIncome - prognosedWithOverhead) / 1000).toFixed(0).toString() + 'k'}
                                          </Tag>
                                          <div
                                              style={{color: ((prognosedIncome - prognosedWithOverhead) / prognosedIncome) > 0 ? '#3f8600' : '#860401'}}>
                                              {((prognosedIncome - prognosedWithOverhead) / (prognosedIncome)) * 100 > 0 ?
                                                  <ArrowUpOutlined/> :
                                                  <ArrowDownOutlined/>} {(((prognosedIncome - prognosedWithOverhead) / (prognosedIncome)) * 100).toFixed(2)} %
                                          </div>
                                      </div>
                                  </div>
                                  <div className="fullscreenonly">
                                      <div className='d-flex'>
                                          <Statistic
                                              valueStyle={{color: (prognosedIncome - prognosedWithOverhead) > 0 ? '#3f8600' : '#860401'}}
                                              value={((prognosedIncome - prognosedWithOverhead) / 1000).toFixed(0).toString() + 'k'}
                                              className="mr-2"
                                          />
                                          <Statistic
                                              value={((prognosedIncome - prognosedWithOverhead) / (prognosedIncome)) * 100}
                                              valueStyle={{color: ((prognosedIncome - prognosedWithOverhead) / prognosedIncome) > 0 ? '#3f8600' : '#860401'}}
                                              prefix={((prognosedIncome - prognosedWithOverhead) / (prognosedIncome)) > 0 ?
                                                  <ArrowUpOutlined/> :
                                                  <ArrowDownOutlined/>}
                                              precision={2}
                                              suffix="%"
                                          />
                                      </div>
                                  </div>
                              </div>
                          }>

                        {prognosisData && prognosisData.length > 0 ?
                            <div>
                                <Row>
                                    <Col span={12}>
                                        <Statistic title="Kulu prognoos" value={prognosed}/>
                                    </Col>
                                    <Col span={12}>
                                        <Statistic title="Tulu prognoos" value={prognosedIncome}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Statistic
                                            title="Vahe eesmärgiga"
                                            value={(prognosedToTargetBudgetDifference - 100)}
                                            precision={2}
                                            valueStyle={prognosedToTargetBudgetDifference < 0 ? {color: '#3f8600'} : {color: '#c10b06'}}
                                            prefix={prognosedToTargetBudgetDifference <= 100 ?
                                                <ArrowDownOutlined/> :
                                                <ArrowUpOutlined/>}
                                            //prefix={<Icon type="arrow-down"/>}
                                            suffix="%"
                                        />

                                    </Col>
                                    <Col span={12}>

                                        <Statistic
                                            title="Vahe eesmärgiga"
                                            value={(prognosedToTargetBudgetDifference - 100)}
                                            precision={2}
                                            valueStyle={prognosedToTargetBudgetDifference > 0 ? {color: '#3f8600'} : {color: '#c10b06'}}
                                            prefix={prognosedToTargetIncomeDifference <= 100 ?
                                                <ArrowDownOutlined/> :
                                                <ArrowUpOutlined/>}
                                            //prefix={<Icon type="arrow-down"/>}
                                            suffix="%"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            :
                            <div>
                                <Empty
                                    image={<div>
                                        <i className="fas fa-cogs mr-2" style={{color: 'orange', fontSize: 28}}> </i>
                                        <i className="fas fa-flag-checkered"
                                           style={{color: 'orange', fontSize: 28}}> </i>
                                    </div>}
                                    imageStyle={{
                                        height: 50,
                                    }}
                                    description={
                                        <span>
                                       Sellele objektile ei ole tehtud prognoosi. Prognoosi tegemiseks vajad eesmärk-eelarvet.
                                     </span>
                                    }
                                >
                                </Empty>
                            </div>
                        }
                    </Card>
                </Col>

            </Row>
        </div>
    );
}

const OverViewBlock = ({setActiveDayTab}) => {


    const [activeTabKey, setActiveTabKey] = useState('reports')
    //const winsize = useWindowSize()
    const meta = useStoreState(state => state.object.meta)
    const costs = useStoreState(state => state.object.costs)
    let tabList = []

    if (meta.costing_version === 1) tabList.push({key: 'overview', tab: 'Billid',})
    tabList.push({key: 'reports', tab: 'Päevaaruanded',})


    const contentList = {
        overview: <PaCostBudgetOverview size="small"/>,
        reports: <DailyReportsBlock setActiveDayTab={setActiveDayTab}/>,

    };

    let problematic = Object.values(costs).reduce((sum, row) => row.status === 7 ? sum + 1 : sum, 0)
    let unassigned = Object.values(costs).reduce((sum, row) => !row.assignments && row.row_sum > 0 ? sum + 1 : sum, 0)

    return (
        <Card
            style={{width: '100%'}}
            //title={<div className="m-2"><h4>Tehase LIVE</h4></div>}
            tabList={tabList}
            activeTabKey={activeTabKey}
            //tabBarExtraContent={}
            className="mt-2"
            onTabChange={key => setActiveTabKey(key)}
            tabBarExtraContent={
                <div className="mr-3">
                    <Tooltip title="Probleemseid kulusid"><Badge count={problematic}/></Tooltip>
                    <Tooltip title="Ilma billideta kulusid"><Badge count={unassigned} style={{backgroundColor: '#1890ff'}}/></Tooltip>
                </div>}
        >
            {contentList[activeTabKey]}
        </Card>
    )
}

const DailyReportsBlock = ({setActiveDayTab}) => {


    const objectCostData = useStoreState(state => state.object.costs)
    const setObjectActiveDate = useStoreActions(actions => actions.object.setObjectActiveDate)
    const objectIncomeDates = useStoreState(state => state.object.incomeDates)
    //const income = useStoreState(state => state.object.income);
    let tableData = []
    let dates = []

    // heatmap trial
    let heatmapValues = []


    // creating tables list
    Object.values(objectCostData).forEach((cost) => {
        if (!dates.includes(cost.cost_date) && moment(cost.cost_date).format('YYYY-MM-DD') !== "1900-01-01" && moment(cost.cost_date).isValid()) {
            dates.push(cost.cost_date)
        }
    })

    objectIncomeDates.forEach((income) => {
        if (!dates.includes(income.date_income)) {
            if (moment(income.date_income).isValid()) {
                dates.push(income.date_income)
            }
        }
    })

    //console.log("DATES", dates)

    // joining costs and incomes
    dates.forEach((date) => {
        let costReportRow = {}
        costReportRow['date'] = date
        costReportRow['costs'] = Object.values(objectCostData).reduce((sum, row) => row.cost_date === date ? sum + row.row_sum : sum, 0)
        costReportRow['income'] = objectIncomeDates.reduce((sum, row) => row.date_income === date ? sum + row.sum : sum, 0)
        costReportRow['problematic'] = Object.values(objectCostData).reduce((sum, row) => row.status === 7 && row.cost_date === date ? sum + 1 : sum, 0)
        costReportRow['assigned'] = Object.values(objectCostData).reduce((sum, row) => !row.assignments && row.cost_date === date && row.row_sum > 0 ? sum + 1 : sum, 0)
        tableData.push(costReportRow)

        let heatmapItem = {
            date: moment(costReportRow['date']).format('YYYY-MM-DD'),
            count: (costReportRow['costs'] ? 1 : 0) + (costReportRow['income'] ? 1 : 0),
            problematic: costReportRow['problematic'],
            notAssigned: costReportRow['assigned']
        }

        heatmapValues.push(heatmapItem)
    })

    const handleNavToDay = (day) => {
        console.log("navtodate", day)
        setObjectActiveDate(moment(day))
        setActiveDayTab("2")

    }

    const columns = [
        {
            title: 'Kuupäev',
            key: "date",
            dataIndex: "date",
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
            render: (text, record, index) => record.date ? <a><b>{moment(record.date).format("DD.MM.YY")}</b></a> : "",
        }, {
            title: 'Tulud',
            dataIndex: 'income',
            key: 'income',
            align: "right",
            sorter: (a, b) => a.income - b.income,
            render: text => text ? "€ " + text.toFixed(0) : "€ 0",
        },
        {
            title: 'Kulud',
            dataIndex: 'costs',
            key: 'costs',
            align: "right",
            sorter: (a, b) => a.costs - b.costs,
            render: text => text ? "€ " + text.toFixed(0) : "€ 0",
        }, {
            title: <Tooltip title="Probleemseid kulusid"><InfoCircleOutlined/></Tooltip>,
            key: "problematic",
            dataIndex: "problematic", width: 40, align: "center",
            sorter: (a, b) => a.problematic - b.problematic,
            render: (text, record, index) => <Badge count={record.problematic}/>
        },
        {
            title: <Tooltip title="Nii mitmel kulul on billid puudu"><PieChartOutlined/></Tooltip>,
            key: "assigned",
            dataIndex: "assigned", width: 40, align: "center",
            sorter: (a, b) => a.assigned - b.assigned,
            render: (text, record, index) => <Badge count={record.assigned} style={{backgroundColor: '#1890ff'}}/>
        },
        //{
        //    title: 'Tegevused',
        //    key: "actions",
        //    dataIndex: "date",
        //    //render: text => <div onClick={() => handleNavToDay(text)}>Päevaaruandesse</div>
        //    render: text => text
        //},
    ]

    const chartData = {
        labels: dates.map(v => moment(v).format("DD.MM.YY")),
        datasets: [
            {
                label: 'Kulud',
                backgroundColor: '#ffccc7',
                borderColor: '#ff7875',
                borderWidth: 1,
                hoverBackgroundColor: '#ff7875',
                hoverBorderColor: '#f5222d',
                data: tableData.map(v => v.costs)
            }, {
                label: 'Tulud',
                backgroundColor: '#d9f7be',
                borderColor: '#95de64',
                borderWidth: 1,
                hoverBackgroundColor: '#95de64',
                hoverBorderColor: '#52c41a',
                data: tableData.map(v => v.income)
            }
        ]

    };

    return <div>
        <Row gutter={16}>
            <Col md={16} sm={24}>
                <div className="fullscreenonly">
                    <div className="m-2"><b>Kas info on tõene (viimased 365 päeva)?</b></div>
                    <div className="ml-2">
                        <CalendarHeatmap
                            startDate={Date.now() - (365 * 24 * 60 * 60 * 1000)} // calculating -365 days
                            endDate={Date.now()}
                            monthLabels={['Jan', 'Veb', 'Mär', "Apr", 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Det']}
                            titleForValue={ value => value ? <HeatMapItem value={value}/> : null}
                            onClick={(value) => value ? handleNavToDay(value.date) : null}
                            showOutOfRangeDays={false}
                            values={heatmapValues}
                            classForValue={value => {
                                if (!value) {
                                    return 'color-empty';
                                }
                                if (value.problematic > 0) return 'color-problematic'
                                if (value.notAssigned > 0) return 'color-nobills'

                                else return `color-github-${value.count}`
                            }}
                        />
                    </div>
                    <div className='d-flex align-items-center ml-2'>
                        <div className="mr-2">Legend:</div>
                        <div style={{height: '10px', width: '10px', background: '#f60f0c'}} className="m-1"></div>
                        <div> Probleemsed</div>
                        <div style={{height: '10px', width: '10px', background: '#f6a209'}} className="m-1"></div>
                        <div> Billid puudu</div>
                        <div style={{height: '10px', width: '10px', background: '#ecf6b1'}} className="m-1"></div>
                        <div> Tulud puudu</div>
                        <div style={{height: '10px', width: '10px', background: '#8cc665'}} className="m-1"></div>
                        <div> Tulud ja kulud olemas</div>
                    </div>
                    <div className="m-2 pt-2"><b>Tulude-kulude graafik</b></div>
                    <Bar
                        data={chartData}
                        //width={'100%'}
                        height={'100%'}
                        //options={{maintainAspectRatio: false}}
                        onElementsClick={elems => {

                            if (elems[0] && elems[0]._index) handleNavToDay(dates[elems[0]._index])
                        }}
                    />
                </div>
                <div className="mobileonly">
                    <div className=" m-2 p-2 justify-content-center d-flex"><b>Keera seadet, et näha graafikut!</b>
                    </div>
                </div>
            </Col>
            <Col md={8} sm={24}>
                <Table columns={columns}
                       dataSource={tableData}
                       onRow={(record, rowIndex) => {
                           return {
                               onClick: () => handleNavToDay(record.date)
                           }
                       }}
                       scroll={{y: 340}}
                       pagination={{
                           defaultPageSize: 1000,
                           showSizeChanger: false,
                           hideOnSinglePage: true
                       }}
                       size="middle"/>
            </Col>
        </Row>
    </div>
}

const HeatMapItem = ({value}) => {

    return value ? <div>{moment(value.date).format('DD.MM.YYYY')}</div> : null


}

export const PaObjectOverview = ({setActiveTab}) => {

    const meta = useStoreState(state => state.object.meta)

    return <div style={pageDivStyle}>

        <StatisticsBlock setActiveTab={setActiveTab}/>
        <OverViewBlock setActiveDayTab={setActiveTab}/>
    </div>
}




