import moment from 'moment'

export const dateFormat = 'DD.MM.YYYY'
export const dateTimeFormat = 'DD.MM.YYYY HH:mm'
export const longDateFormat = 'dd, D. MMMM GGGG'
export const longMonthFormat = 'MMMM GGGG'
export const dateFormatShort = 'DD.MM'
export const timeFormat = 'HH:mm'

export const datetimeFormat = `${dateFormat} ${timeFormat}`
export const longDatetimeFormat = `${longDateFormat} ${timeFormat}`

export const formatDatetime = (date, format = datetimeFormat) => moment(date).format(format)
export const formatDuration = (hours, format = 'h[h]') => {
  if (hours < 1) return moment.duration(hours, 'hours').format('mm[m]')
  if (hours % 1 !== 0) return moment.duration(hours, 'hours').format('h[h] mm[m]')
  return moment.duration(hours, 'hours').format(format)
}
