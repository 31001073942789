//import {Button, ButtonGroup, Card} from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from 'react'
import PageWithHeader from '../components/PageWithHeader'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import dateLocale from 'antd/es/date-picker/locale/et_EE'
import { Table, Input, Card, Row, Col, Select, Tag, DatePicker, Alert, Button, TreeSelect } from 'antd'
import '../App.css'
import API, { mapAPI } from '../services/api'
import moment from 'moment'
import { useHistory, withRouter, useParams } from 'react-router'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useFormik } from 'formik'
import GoogleMapReact from 'google-map-react'
import PaObjectsStatus from '../components/PaObjectsStatus'
import ObjectNavigator from '../components/ObjectNavigator'

const { Option } = Select

const PaObjectsUpdate = () => {
  let { id } = useParams()

  const objects = useStoreState((state) => state.objects.objects)
  const meta = useStoreState((state) => state.object.meta)
  const editAllowed = useStoreState((state) => state.user.editAllowed)
  const loadObjects = useStoreActions((actions) => actions.objects.apiLoadObjects)
  const loadObject = useStoreActions((actions) => actions.object.apiLoadMeta)

  const divisions = useStoreState((state) => state.divisions.divisions)
  const bills = useStoreState((state) => state.bills.bills)

  let history = useHistory()
  let mapUrl = 'https://maps.googleapis.com/maps/api/staticmap?'

  let emptyFormValues = {
    address: '',
    description: '',
    begin_objid: null,
    begin_planned: moment().format('YYYY-MM-DD 00:00:00'),
    budget: 0,
    connected_factory_id: null,
    connected_factory_name: null,
    end_planned: moment().add(3, 'months').format('YYYY-MM-DD 00:00:00'),
    end_true: null,
    has_warehouse: 0,
    idobject: null,
    iddivision: null,
    location_lat: '59.16403989999999',
    location_long: '24.529792',
    location_radius: null,
    name: '',
    obj_manager: 1,
    overhead_price_per_ton: null,
    overhead_rate: 0,
    proj_manager: null,
    shiffer: '',
    status: 3,
    type: '',
    team: [],
    workers: [],
    restricted: 0,
    product: 'T1.1',
    product_group: 'T1',
    product_description: '',
    client_name: '',
    region: '',
    client_contact: '',
    superviser_company: '',
    superviser_contact: '',
    costing_version: 2,
    temp_is_ET_object: 0,
    is_small_object: 0,
    dafault_bills_types_id: null
  }

  const [formValues, setFormValues] = useState(emptyFormValues)

  useEffect(() => {
    if (id && meta) {
      const myFormValues = meta
      myFormValues['begin_planned'] = moment(meta.begin_planned).format('YYYY-MM-DD 00:00:00')
      myFormValues['end_planned'] = moment(meta.end_planned).format('YYYY-MM-DD 00:00:00')
      myFormValues['overhead_rate'] = (myFormValues['overhead_rate'] * 100).toFixed(2)

      setFormValues(myFormValues)
      let myMapData = mapData
      myMapData.markers = myFormValues.location_lat + ',' + myFormValues.location_long
      setMapData(myMapData)
    }
    // eslint-disable-next-line
  }, [id, meta])

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    onSubmit: useCallback(
      async (values) => {
        console.log({ values })
        if (validate(values)) {
          let payload = Object.assign({}, values)
          if (typeof payload.overhead_rate === 'string') {
            payload.overhead_rate = payload.overhead_rate.replace(',', '.')
            payload.overhead_rate = parseFloat(payload.overhead_rate)
          }
          payload.overhead_rate = payload.overhead_rate / 100
          API('/api/objects/new', false, 'POST', payload).then((v) => history.push('/object/' + v.data))
        }
      },
      [history]
    )
  })

  const treeData = [
    {
      title: 'ERA1 Erasektor Ehitus',
      value: 'ERA1',
      key: 'ERA1',
      selectable: false,
      children: [
        {
          title: 'ERA1.1 Ehitus alla €250K',
          value: 'ERA1.1',
          key: 'ERA1.1'
        },
        {
          title: 'ERA1.2 Ehitus €250K - 750K',
          value: 'ERA1.2',
          key: 'ERA1.2'
        },
        {
          title: 'ERA1.3 Ehitus €750K - 1.5M',
          value: 'ERA1.3',
          key: 'ERA1.3'
        },
        {
          title: 'ERA1.4 Ehitus €1.5M+',
          value: 'ERA1.4',
          key: 'ERA1.4'
        }
      ]
    },
    {
      title: 'ERA2 Erasektor Asfalt/pindamine',
      value: 'ERA2',
      key: 'ERA2',
      selectable: false,
      children: [
        {
          title: 'ERA2.1 Asfalt/pindamine alla €250K',
          value: 'ERA2.1',
          key: 'ERA2.1'
        },
        {
          title: 'ERA2.2 Asfalt/pindamine €250K - 750K',
          value: 'ERA2.2',
          key: 'ERA2.2'
        },
        {
          title: 'ERA2.3 Asfalt/pindamine €750K - 1.5M',
          value: 'ERA2.3',
          key: 'ERA2.3'
        },
        {
          title: 'ERA2.4 Asfalt/pindamine €1.5M+',
          value: 'ERA2.4',
          key: 'ERA2.4'
        }
      ]
    },
    {
      title: 'MAT1 Erasektor Asfaltbetoon',
      value: 'MAT1',
      key: 'MAT1',
      selectable: false,
      children: [
        {
          title: 'MAT1.1 Asfaltbetoon alla €250K',
          value: 'MAT1.1',
          key: 'MAT1.1'
        },
        {
          title: 'MAT1.2 Asfaltbetoon €250K - 750K',
          value: 'MAT1.2',
          key: 'MAT1.2'
        },
        {
          title: 'MAT1.3 Asfaltbetoon €750K - 1.5M',
          value: 'MAT1.3',
          key: 'MAT1.3'
        },
        {
          title: 'MAT1.4 Asfaltbetoon €1.5M+',
          value: 'MAT1.4',
          key: 'MAT1.4'
        }
      ]
    },
    {
      title: 'MAT2 Erasektor Puiste',
      value: 'MAT2',
      key: 'MAT2',
      selectable: false,
      children: [
        {
          title: 'MAT2.1 Puiste alla €250K',
          value: 'MAT2.1',
          key: 'MAT2.1'
        },
        {
          title: 'MAT2.2 Puiste €250K - 750K',
          value: 'MAT2.2',
          key: 'MAT2.2'
        },
        {
          title: 'MAT2.3 Puiste €750K - 1.5M',
          value: 'MAT2.3',
          key: 'MAT2.3'
        },
        {
          title: 'MAT2.4 Puiste €1.5M+',
          value: 'MAT2.4',
          key: 'MAT2.4'
        }
      ]
    },
    {
      title: 'KOV1 Kohalikud omavalitsused Ehitus',
      value: 'KOV1',
      key: 'KOV1',
      selectable: false,
      children: [
        {
          title: 'KOV1.1 Ehitus alla €250K',
          value: 'KOV1.1',
          key: 'KOV1.1'
        },
        {
          title: 'KOV1.2 Ehitus €250K - 750K',
          value: 'KOV1.2',
          key: 'KOV1.2'
        },
        {
          title: 'KOV1.3 Ehitus €750K - 1.5M',
          value: 'KOV1.3',
          key: 'KOV1.3'
        },
        {
          title: 'KOV1.4 Ehitus €1.5M+',
          value: 'KOV1.4',
          key: 'KOV1.4'
        }
      ]
    },
    {
      title: 'KOV2 Kohalikud omavalitsused Korrashoid',
      value: 'KOV2',
      key: 'KOV2',
      selectable: false,
      children: [
        {
          title: 'KOV2.1 Korrashoid alla €250K',
          value: 'KOV2.1',
          key: 'KOV2.1'
        },
        {
          title: 'KOV2.2 Korrashoid €250K - 750K',
          value: 'KOV2.2',
          key: 'KOV2.2'
        },
        {
          title: 'KOV2.3 Korrashoid €750K - 1.5M',
          value: 'KOV2.3',
          key: 'KOV2.3'
        },
        {
          title: 'KOV2.4 Korrashoid €1.5M+',
          value: 'KOV2.4',
          key: 'KOV2.4'
        }
      ]
    },
    {
      title: 'KOV3 Kohalikud omavalitsused Pindamine/Mustkate',
      value: 'KOV3',
      key: 'KOV3',
      selectable: false,
      children: [
        {
          title: 'KOV3.1 Pindamine/Mustkate alla €250K',
          value: 'KOV3.1',
          key: 'KOV3.1'
        },
        {
          title: 'KOV3.2 Pindamine/Mustkate €250K - 750K',
          value: 'KOV3.2',
          key: 'KOV3.2'
        },
        {
          title: 'KOV3.3 Pindamine/Mustkate €750K - 1.5M',
          value: 'KOV3.3',
          key: 'KOV3.3'
        },
        {
          title: 'KOV3.4 Pindamine/Mustkate €1.5M+',
          value: 'KOV3.4',
          key: 'KOV3.4'
        }
      ]
    },
    {
      title: 'KOV4 Kohalikud omavalitsused Kruus',
      value: 'KOV4',
      key: 'KOV4',
      selectable: false,
      children: [
        {
          title: 'KOV4.1 Kruus alla €250K',
          value: 'KOV4.1',
          key: 'KOV4.1'
        },
        {
          title: 'KOV4.2 Kruus €250K - 750K',
          value: 'KOV4.2',
          key: 'KOV4.2'
        },
        {
          title: 'KOV4.3 Kruus €750K - 1.5M',
          value: 'KOV4.3',
          key: 'KOV4.3'
        },
        {
          title: 'KOV4.4 Kruus €1.5M+',
          value: 'KOV4.4',
          key: 'KOV4.4'
        }
      ]
    },
    {
      title: 'TRA1 Transpordiamet Korrashoid',
      value: 'TRA1',
      key: 'TRA1',
      selectable: false,
      children: [
        {
          title: 'TRA1.1 Korrashoid alla €500K',
          value: 'TRA1.1',
          key: 'TRA1.1'
        },
        {
          title: 'TRA1.2 Korrashoid €500K - 1.5M',
          value: 'TRA1.2',
          key: 'TRA1.2'
        },
        {
          title: 'TRA1.3 Korrashoid €1.5M - 5M',
          value: 'TRA1.3',
          key: 'TRA1.3'
        },
        {
          title: 'TRA1.4 Korrashoid €5M+',
          value: 'TRA1.4',
          key: 'TRA1.4'
        }
      ]
    },
    {
      title: 'TRA2 Transpordiamet Kruusateed',
      value: 'TRA2',
      key: 'TRA2',
      selectable: false,
      children: [
        {
          title: 'TRA2.1 Kruusateed alla €500K',
          value: 'TRA2.1',
          key: 'TRA2.1'
        },
        {
          title: 'TRA2.2 Kruusateed €500K - 1.5M',
          value: 'TRA2.2',
          key: 'TRA2.2'
        },
        {
          title: 'TRA2.3 Kruusateed €1.5M - 5M',
          value: 'TRA2.3',
          key: 'TRA2.3'
        },
        {
          title: 'TRA2.4 Kruusateed €5M+',
          value: 'TRA2.4',
          key: 'TRA2.4'
        }
      ]
    },
    {
      title: 'TRA3 Transpordiamet Pindamine',
      value: 'TRA3',
      key: 'TRA3',
      selectable: false,
      children: [
        {
          title: 'TRA3.1 Pindamine alla €500K',
          value: 'TRA3.1',
          key: 'TRA3.1'
        },
        {
          title: 'TRA3.2 Pindamine €500K - 1.5M',
          value: 'TRA3.2',
          key: 'TRA3.2'
        },
        {
          title: 'TRA3.3 Pindamine €1.5M - 5M',
          value: 'TRA3.3',
          key: 'TRA3.3'
        },
        {
          title: 'TRA3.4 Pindamine €5M+',
          value: 'TRA3.4',
          key: 'TRA3.4'
        }
      ]
    },
    {
      title: 'TRA4 Transpordiamet Ülekate',
      value: 'TRA4',
      key: 'TRA4',
      selectable: false,
      children: [
        {
          title: 'TRA4.1 Ülekate alla €500K',
          value: 'TRA4.1',
          key: 'TRA4.1'
        },
        {
          title: 'TRA4.2 Ülekate €500K - 1.5M',
          value: 'TRA4.2',
          key: 'TRA4.2'
        },
        {
          title: 'TRA4.3 Ülekate €1.5M - 5M',
          value: 'TRA4.3',
          key: 'TRA4.3'
        },
        {
          title: 'TRA4.4 Ülekate €5M+',
          value: 'TRA4.4',
          key: 'TRA4.4'
        }
      ]
    },
    {
      title: 'TRA5 Transpordiamet Sillad',
      value: 'TRA5',
      key: 'TRA5',
      selectable: false,
      children: [
        {
          title: 'TRA5.1 Sillad alla €500K',
          value: 'TRA5.1',
          key: 'TRA5.1'
        },
        {
          title: 'TRA5.2 Sillad €500K - 1.5M',
          value: 'TRA5.2',
          key: 'TRA5.2'
        },
        {
          title: 'TRA5.3 Sillad €1.5M - 5M',
          value: 'TRA5.3',
          key: 'TRA5.3'
        },
        {
          title: 'TRA5.4 Sillad €5M+',
          value: 'TRA5.4',
          key: 'TRA5.4'
        }
      ]
    },
    {
      title: 'TRA6 Transpordiamet Rekonstrueerimine',
      value: 'TRA6',
      key: 'TRA6',
      selectable: false,
      children: [
        {
          title: 'TRA6.1 Rekonstrueerimine alla €500K',
          value: 'TRA6.1',
          key: 'TRA6.1'
        },
        {
          title: 'TRA6.2 Rekonstrueerimine €500K - 1.5M',
          value: 'TRA6.2',
          key: 'TRA6.2'
        },
        {
          title: 'TRA6.3 Rekonstrueerimine €1.5M - 5M',
          value: 'TRA6.3',
          key: 'TRA6.3'
        },
        {
          title: 'TRA6.4 Rekonstrueerimine €5M+',
          value: 'TRA6.4',
          key: 'TRA6.4'
        }
      ]
    },
    {
      title: 'TRA7 Transpordiamet Mustakate',
      value: 'TRA7',
      key: 'TRA7',
      selectable: false,
      children: [
        {
          title: 'TRA7.1 Mustakate alla €500K',
          value: 'TRA7.1',
          key: 'TRA7.1'
        },
        {
          title: 'TRA7.2 Mustakate €500K - 1.5M',
          value: 'TRA7.2',
          key: 'TRA7.2'
        },
        {
          title: 'TRA7.3 Mustakate €1.5M - 5M',
          value: 'TRA7.3',
          key: 'TRA7.3'
        },
        {
          title: 'TRA7.4 Mustakate €5M+',
          value: 'TRA7.4',
          key: 'TRA7.4'
        }
      ]
    },
    {
      title: 'TRA8 Transpordiamet Ehitus',
      value: 'TRA8',
      key: 'TRA8',
      selectable: false,
      children: [
        {
          title: 'TRA8.1 Ehitus alla €500K',
          value: 'TRA8.1',
          key: 'TRA8.1'
        },
        {
          title: 'TRA8.2 Ehitus €500K - 1.5M',
          value: 'TRA8.2',
          key: 'TRA8.2'
        },
        {
          title: 'TRA8.3 Ehitus €1.5M - 5M',
          value: 'TRA8.3',
          key: 'TRA8.3'
        },
        {
          title: 'TRA8.4 Ehitus €5M+',
          value: 'TRA8.4',
          key: 'TRA8.4'
        }
      ]
    },
    {
      title: 'VÕR1 Võrgustik',
      value: 'VÕR1',
      key: 'VÕR1',
      selectable: false,
      children: [
        {
          title: 'VÕR1.1 Võrgustik alla €500K',
          value: 'VÕR1.1',
          key: 'VÕR1.1'
        },
        {
          title: 'VÕR1.2 Võrgustik €500K - 1.5M',
          value: 'VÕR1.2',
          key: 'VÕR1.2'
        },
        {
          title: 'VÕR1.3 Võrgustik €1.5M - 5M',
          value: 'VÕR1.3',
          key: 'VÕR1.3'
        },
        {
          title: 'VÕR1.4 Võrgustik €5M+',
          value: 'VÕR1.4',
          key: 'VÕR1.4'
        }
      ]
    }
  ]

  function validate(values) {
    let myErrors = {}
    if (values?.shiffer?.length < 3) {
      myErrors = { shiffer: 'Shiffer peab olema märgitud ja vähemalt 3 märki pikk', ...myErrors }
    }
    if (values?.name?.length < 3) {
      myErrors = { name: 'Objektil peab olema nimi ja vähemalt 3 märki pikk', ...myErrors }
    }
    //if (!values.obj_manager) {
    //    myErrors = {obj_manager: "Objektil on ometi ju mõni objektijuht?", ...myErrors}
    //}
    if (!values?.proj_manager) {
      myErrors = { proj_manager: 'Vali objektile projektijuht', ...myErrors }
    }
    if (!values?.type) {
      myErrors = { type: 'Kas objekt on ehitus või mingi tootmisobjekt? (tehas, plats...?)', ...myErrors }
    }

    console.log('errors found', myErrors, Object.keys(myErrors).length)
    setError(myErrors)
    if (Object.keys(myErrors).length < 1) return true

    return false
  }

  const [mapData, setMapData] = useState({
    key: 'AIzaSyATtrIbRLIdQcVx-ybqUwrs5JvbGLzUGQc',
    markers: formValues.location_lat + ',' + formValues.location_long,
    zoom: 12,
    scale: 2,
    size: '400x250',
    language: 'et-ee'
  })

  let addressQueryData = {
    key: 'AIzaSyATtrIbRLIdQcVx-ybqUwrs5JvbGLzUGQc',
    language: 'et-ee',
    address: 'Tallinnn'
  }

  // for loading map image later
  let url = mapUrl + new URLSearchParams(mapData)

  const [users, setUsers] = useState(
    <Option value="" key={0}>
      Vali...
    </Option>
  )
  const [searchStr, setSearchStr] = useState('')
  const [searchRes, setSearchRes] = useState({ key: '', text: '' })
  const [error, setError] = useState({})

  const handleSearch = async (val) => {
    if (val.length > 0) {
      addressQueryData['address'] = val
    }
    await setSearchStr(val)

    let res = await mapAPI('address', false, 'get', addressQueryData)
    if (res.results.length > 0) {
      let lat = res['results'][0]['geometry']['location']['lat']
      let long = res['results'][0]['geometry']['location']['lng']
      setSearchRes({
        key: lat + ',' + long,
        lat: lat,
        long: long,
        text: res['results'][0]['formatted_address']
      })
    }
    console.log(res)
  }

  const onMapClick = async ({ x, y, lat, lng, event }) => {
    addressQueryData['address'] = lat.toString() + ' , ' + lng.toString()
    let res = await mapAPI('address', false, 'get', addressQueryData)

    if (res.results.length > 0) {
      let lat = res['results'][0]['geometry']['location']['lat']
      let long = res['results'][0]['geometry']['location']['lng']
      setSearchRes({
        key: lat + ',' + long,
        lat: lat,
        long: long,
        text: res['results'][0]['formatted_address']
      })
      setSearchStr(res['results'][0]['formatted_address'])
      formik.setFieldValue('address', res['results'][0]['formatted_address'])
      formik.setFieldValue('location_long', long)
      formik.setFieldValue('location_lat', lat)
    }

    console.log(res)
  }

  function handleChange(value, text) {
    console.log('selected: ', value, text)
    setSearchStr(text.props.value)

    if (searchRes.key.length > 0) {
      let myMapData = {
        key: 'AIzaSyATtrIbRLIdQcVx-ybqUwrs5JvbGLzUGQc',
        //markers: "59.16403989999999,24.529792",
        markers: value,
        zoom: 12,
        scale: 2,
        size: '400x250',
        language: 'et-ee'
      }
      console.log('setting mapdata', myMapData)
      setMapData(myMapData)
      //let myFormValues = formValues
      //myFormValues['location_lat'] = searchRes.lat
      //myFormValues['location_long'] = searchRes.long
      //myFormValues['address'] = searchRes.text

      formik.setFieldValue('location_lat', searchRes.lat)
      formik.setFieldValue('location_long', searchRes.long)
      formik.setFieldValue('address', searchRes.text)

      //console.log("myformvalues", myFormValues)
      //setFormValues(myFormValues)
    }
  }

  useEffect(() => {
    ;(async () => {
      await loadObjects()
      if (id) {
        await loadObject(id)
      }
      let res = await API('/api/users')
      //console.log(res)
      let myUsers = res.data.map((u) => (
        <option key={u.iduser} value={u.iduser} disabled={u.user_status !== 3}>
          {' '}
          {u.user_name} {u.user_surname}
        </option>
      ))
      setUsers(myUsers)
    })()
    // eslint-disable-next-line
  }, [id])

  console.log('META:', meta)

  const handleObjectCopy = (new_id) => {
    console.log('handling copy:', new_id)
    let newData = objects.filter((obj) => obj.idobject === new_id)

    // setting idobject to null
    newData[0].idobject = null
    newData[0].shiffer = null
    newData[0].begin_planned = moment(newData[0].begin_planned).format('YYYY-MM-DD 00:00:00')
    newData[0].end_planned = moment(newData[0].end_planned).format('YYYY-MM-DD 00:00:00')
    newData[0].overhead_rate = newData[0].overhead_rate * 100
    //newData[0].team = JSON.parse(newData[0].team)
    //newData[0].workers = JSON.parse(newData[0].workers)

    setFormValues(newData[0])
    let myMapData = mapData
    myMapData.markers = newData[0].location_lat + ',' + newData[0].location_long
    setMapData(myMapData)
  }

  const handleProductChange = (value, label, extra) => {
    //console.log("VLE", value, label, extra, label[0].substring(0,2))
    formik.setFieldValue('product', value)
    formik.setFieldValue('product_description', label[0])
    formik.setFieldValue('product_group', label[0].substring(0, 2))
  }

  return (
    <PageWithHeader>
      <div>
        <Card>
          <div className="d-flex justify-content-between pt-2 pb-2">
            <div className="d-flex">
              <div>
                <h4>
                  {formValues.idobject ? (
                    <div>
                      {formValues.name}
                      <br />
                      <small>{formValues.address}</small>
                    </div>
                  ) : (
                    'Lisa uus objekt'
                  )}
                </h4>
              </div>
            </div>
            <div className="d-flex">
              {formValues.idobject ? (
                <div className="mr-2">
                  <Select
                    className="ml-2"
                    id="status"
                    name="status"
                    onChange={(v) => formik.setFieldValue('status', v)}
                    value={formik.values.status}>
                    <Option key={3} value={3}>
                      {' '}
                      <PaObjectsStatus status={3} /> Objekt on töös{' '}
                    </Option>
                    <Option key={4} value={4}>
                      {' '}
                      <PaObjectsStatus status={4} /> Objekt läheb töösse hiljem
                    </Option>
                    <Option key={6} value={6}>
                      {' '}
                      <PaObjectsStatus status={6} /> Objekt on lõpetatud
                    </Option>
                  </Select>
                </div>
              ) : (
                <div className="ml-3">
                  Täida vorm teise objekti andmetega
                  <Select
                    //showSearch
                    //value={searchStr}
                    placeholder="Vali objekt.."
                    //defaultActiveFirstOption={false}
                    //showArrow={false}
                    //filterOption={false}
                    //onSearch={handleSearch}
                    onSelect={handleObjectCopy}
                    //notFoundContent={null}
                    style={{ width: '250px' }}
                    className="ml-2">
                    {objects.map((ob) => (
                      <Option key={ob.idobject} value={ob.idobject}>
                        {ob.shiffer} {ob.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
              <div className="ml-2">
                {formValues.idobject ? (
                  <Button type="primary" disabled={!editAllowed} onClick={() => formik.submitForm()}>
                    Muuda andmed
                  </Button>
                ) : (
                  <Button type="primary" onClick={() => formik.submitForm()}>
                    Loo objekt
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>

      {Object.keys(error).length > 0 ? (
        <div className="mt-2 mr-2 ml-2 p-2">
          <Row>
            <Col span={24}>
              <Alert
                message="Viga:"
                description={Object.values(error).map((e, index) => (
                  <div key={`desc-${index}`}>{e}</div>
                ))}
                type="error"
                showIcon
              />
            </Col>
          </Row>
        </div>
      ) : null}

      {editAllowed ? null : (
        <div className="mt-2 mr-2 ml-2 p-2">
          <Row>
            <Col span={24}>
              <Alert
                message="Sa ei tohi seda objekti muuta!"
                description="Sellel objektil on piiratud muutmisõigus! Kui Sa ikkagi oled kindel, siis pöördu oma projektijuhi poole!"
                type="error"
                showIcon
              />
            </Col>
          </Row>
        </div>
      )}

      <div className="m-2 p-2">
        <form className="needs-validation" id="form1" onSubmit={formik.handleSubmit}>
          <Row>
            <Col span={14}>
              <div className="mr-2">
                <Card title="Üldandmed">
                  <input name="action" value="update" hidden />
                  <input name="id_obj" value="{{ object.id }}" hidden />
                  <Row gutter={8}>
                    <Col span={16}>
                      <label htmlFor="name">Objekti nimetus</label>
                      <Form.Item validateStatus={error.name ? 'error' : ''}>
                        <Input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Objekti nimetus..."
                          name="name"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <label htmlFor="shiffer">Shiffer</label>
                      <Form.Item validateStatus={error.shiffer ? 'error' : ''}>
                        <Input
                          type="text"
                          className="form-control"
                          id="shiffer"
                          name="shiffer"
                          placeholder="Shiffer"
                          required
                          onChange={formik.handleChange}
                          value={formik.values.shiffer}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <label htmlFor="proj_manager">Projektijuht</label>
                      <Form.Item validateStatus={error.proj_manager ? 'error' : ''}>
                        <Select
                          id="proj_manager"
                          name="proj_manager"
                          showSearch
                          optionFilterProp="children"
                          onChange={(v) => formik.setFieldValue('proj_manager', v)}
                          value={formik.values.proj_manager}
                          placeholder="Vali..."
                          required>
                          {users}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <label htmlFor="obj_manager">Objektijuht</label>
                      <Form.Item validateStatus={error.obj_manager ? 'error' : ''}>
                        <Select
                          id="obj_manager"
                          name="obj_manager"
                          showSearch
                          optionFilterProp="children"
                          onChange={(v) => formik.setFieldValue('obj_manager', v)}
                          value={formik.values.obj_manager}
                          placeholder="Vali..."
                          required>
                          {users}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <label htmlFor="has_warehouse">Andmeid võivad muuta:</label>
                      <Form.Item validateStatus={error.restricted ? 'error' : ''}>
                        <Select
                          id="restricted"
                          name="restricted"
                          placeholder="vali.."
                          onChange={(v) => formik.setFieldValue('restricted', v)}
                          value={formik.values.restricted}
                          dropdownMatchSelectWidth={false}
                          required>
                          <option key={0} value={0}>
                            <UnlockOutlined style={{ color: 'green' }} />
                            Kõik võivad muuta
                          </option>
                          <option key={1} value={1}>
                            <LockOutlined style={{ color: 'red' }} />
                            Ainult objekti meeskond
                          </option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {formik.values.restricted === 1 ? null : null}
                    {/* Meeskonna valik */}
                    <Col span={24}>
                      <label htmlFor="team">
                        Objekti meeskond: töötajad, kes saavad kinnitada objektil tehtud töid (pane siia töödejuhid, teine objektijuht jms)
                      </label>
                      <Form.Item validateStatus={error.team ? 'error' : ''}>
                        <Select
                          id="team"
                          mode="multiple"
                          showSearch
                          optionFilterProp="children"
                          name="team"
                          onChange={(v) => formik.setFieldValue('team', v)}
                          value={formik.values.team}
                          placeholder="Vali..."
                          required>
                          {users}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* Tööliste valik */}
                    <Col span={24}>
                      <label htmlFor="workers">
                        Objekti töölised: kes on objektile 'määratud'? Selles nimekirjas olijale on tööaja arvestuses objekt 'lemmikuks'
                      </label>
                      <Form.Item validateStatus={error.workers ? 'error' : ''}>
                        <Select
                          id="workers"
                          mode="multiple"
                          showSearch
                          optionFilterProp="children"
                          name="workers"
                          onChange={(v) => formik.setFieldValue('workers', v)}
                          value={formik.values.workers}
                          placeholder="Vali...">
                          {users}
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* üksuse valik */}
                    <Col span={24}>
                      <label htmlFor="iddivision">Üksus</label>
                      <Form.Item validateStatus={error.iddivision ? 'error' : ''}>
                      <Select
                        id="iddivision"
                        name="iddivision"
                        value={formik.values.iddivision}
                        onChange={(v) => formik.setFieldValue('iddivision', v)}
                        required>
                        {divisions.map((x, y) => (
                          <option key={x.id} value={x.id}>
                            {x.external_id} {x.name}
                          </option>
                        ))}
                      </Select>
                      </Form.Item>
                    </Col>
                    {/* Märkmed */}
                    <Col span={24}>
                      <label htmlFor="description">Märkmed</label>
                      <Form.Item validateStatus={error.description ? 'error' : ''}>
                        <Input.TextArea
                          type="text"
                          className="form-control"
                          id="description"
                          name="description"
                          placeholder="Märkmed..."
                          required
                          onChange={formik.handleChange}
                          value={formik.values.description}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div className="mr-2 mt-2">
                <Card title="Sätted, mis mõjutavad PA loogikat">
                  <Row gutter={8}>
                    <Col span={12}>
                      <div>
                        <label htmlFor="begin_planned">Tööde algus</label>
                      </div>
                      <Form.Item validateStatus={error.begin_planned ? 'error' : ''}>
                        <DatePicker
                          //className="form-control"
                          locale={dateLocale}
                          allowClear={false}
                          style={{ width: '100%' }}
                          name="begin_planned"
                          format="DD.MM.YYYY"
                          inputReadOnly={true}
                          onChange={(moment, string) =>
                            string.length > 0 ? formik.setFieldValue('begin_planned', moment.format('YYYY-MM-DD 00:00:00')) : null
                          }
                          value={moment(formik.values.begin_planned)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div>
                        <label htmlFor="end_planned">Tööde lõpp</label>
                      </div>
                      <Form.Item validateStatus={error.end_planned ? 'error' : ''}>
                        <DatePicker
                          //className="form-control"
                          locale={dateLocale}
                          style={{ width: '100%' }}
                          allowClear={false}
                          format="DD.MM.YYYY"
                          name="end_planned"
                          onChange={(moment, string) =>
                            string.length > 0 ? formik.setFieldValue('end_planned', moment.format('YYYY-MM-DD 00:00:00')) : null
                          }
                          value={moment(formik.values.end_planned)}
                        />
                      </Form.Item>
                    </Col>

                    <input
                      type="number"
                      className="form-control"
                      id="budget"
                      placeholder="0000"
                      name="budget"
                      onChange={formik.handleChange}
                      value={formik.values.budget}
                      hidden
                    />

                    <Col span={12}>
                      <label htmlFor="overhead_rate">Üldkulude arvestuslik määr tuludest</label>
                      <Input
                        id="overhead_rate"
                        name="overhead_rate"
                        suffix={'%'}
                        //onChange={formik.handleChange}
                        onChange={formik.handleChange}
                        value={formik.values.overhead_rate}
                        required></Input>
                    </Col>

                    <Col span={12}>
                      <div>
                        <label htmlFor="validationServer02">Üldkulud tonni kohta tootmisobjektil</label>
                      </div>
                      <Input
                        type="text"
                        name="overhead_price_per_ton"
                        suffix={'€'}
                        onChange={formik.handleChange}
                        value={formik.values.overhead_price_per_ton}
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <div className="mt-2">
                        <label htmlFor="type">Objekti tüüp</label>
                      </div>
                      <Form.Item validateStatus={error.type ? 'error' : ''}>
                        <Select
                          className=""
                          id="type"
                          name="type"
                          onChange={(v) => formik.setFieldValue('type', v)}
                          value={formik.values.type}
                          required>
                          <option key={'Ehitus'} value="Ehitus">
                            Ehitus
                          </option>
                          <option key={'Tootmine'} value="Tootmine">
                            Tootmine
                          </option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {formik.values.type === 'Tootmine' ? (
                      <Col span={12}>
                        <div className="mt-2">
                          <label htmlFor="has_warehouse">Objektil on ladu?</label>
                        </div>
                        <Form.Item validateStatus={error.has_warehouse ? 'error' : ''}>
                          <Select
                            id="has_warehouse"
                            name="has_warehouse"
                            placeholder="vali.."
                            onChange={(v) => formik.setFieldValue('has_warehouse', v)}
                            value={formik.values.has_warehouse}
                            required>
                            <option key={0} value={0}>
                              Ei
                            </option>
                            <option key={1} value={1}>
                              Jah
                            </option>
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : null}
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <div className="mt-2">
                        <label htmlFor="type">
                          <b>Kas objekt on väikeobjekt?</b>
                        </label>
                        <div>
                          <small>
                            Väikeobjektidel saab aktid vormistada päevaaruandes, samuti on lihtsustatud tulu-eelarve (hinnapakkumise) sisestamine.
                            Väikeobjektid on näha Väikeobjektide vaates.
                          </small>
                        </div>
                      </div>
                      <Form.Item validateStatus={error.is_small_object ? 'error' : ''}>
                        <Select
                          className=""
                          id="is_small_object"
                          name="is_small_object"
                          onChange={(v) => formik.setFieldValue('is_small_object', v)}
                          value={formik.values.is_small_object}
                          required>
                          <option key={'small'} value={1}>
                            Jah, objekt on väikeobjekt
                          </option>
                          <option key={'big'} value={0}>
                            Ei, Ei ole
                          </option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div className="mt-2">
                        <label htmlFor="dafault_bills_types_id">
                          <b>Kas objekti kõik kulud saavad ALATI sama billi?</b>
                        </label>
                        <div>
                          <small>
                            Kui jah, siis saad siin määrata selle billi, mis pannakse kõikidele kuludele külge automaatselt kulurea loomisel. Saad
                            neid päeva peal igal ajal muuta. Ei kehti FieldWorksi'st toodud kuludele.
                          </small>
                        </div>
                      </div>
                      <Form.Item validateStatus={error.dafault_bills_types_id ? 'error' : ''}>
                        <Select
                          className=""
                          id="is_small_object"
                          name="is_small_object"
                          onChange={(v) => formik.setFieldValue('dafault_bills_types_id', v)}
                          value={formik.values.dafault_bills_types_id}
                          required>
                          <Option key={'null'} value={null}>
                            {' '}
                            Määramata
                          </Option>
                          {bills
                            ? bills.flat.map((bill) =>
                                bill.status === 3 ? (
                                  <Option key={bill.id} value={bill.id}>
                                    {' '}
                                    {bill.number} {bill.name}
                                  </Option>
                                ) : null
                              )
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col span={12}>
                      <div className="p-4 projection_highlight_bad">
                        <label htmlFor="temp_is_ET_object">Materjalide arvestuses on kasutuses tootekoodid</label>
                        <Select
                          id="temp_is_ET_object"
                          name="temp_is_ET_object"
                          style={{ width: '70%' }}
                          onChange={(v) => formik.setFieldValue('temp_is_ET_object', v)}
                          value={formik.values.temp_is_ET_object}>
                          <Option key={0} value={0}>
                            {' '}
                            Ei{' '}
                          </Option>
                          <Option key={1} value={1}>
                            {' '}
                            Jah{' '}
                          </Option>
                        </Select>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className="p-4 projection_highlight_good">
                        <label htmlFor="costing_version">Kuluarvestuse versioon</label>
                        <Form.Item validateStatus={error.costing_version ? 'error' : ''}>
                          <Select
                            id="costing_version"
                            name="costing_version"
                            placeholder="vali.."
                            onChange={(v) => formik.setFieldValue('costing_version', v)}
                            value={formik.values.costing_version}
                            required>
                            <option key={1} value={1}>
                              {' '}
                              v1: Kuni 2020 k.a.
                            </option>
                            <option key={2} value={2}>
                              {' '}
                              v2: Alates 2021
                            </option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
            <Col span={10}>
              <div>
                <Card title="Asukoht">
                  <div className="ml-2">Määra objekti asukoht kaardil klikkides</div>

                  <div className="m-2" style={{ width: '100%', height: '400px' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: 'AIzaSyATtrIbRLIdQcVx-ybqUwrs5JvbGLzUGQc',
                        language: 'ET'
                      }}
                      defaultCenter={{
                        lat: parseFloat(emptyFormValues.location_lat),
                        lng: parseFloat(emptyFormValues.location_long)
                      }}
                      defaultZoom={7}
                      onClick={onMapClick}>
                      {formValues ? (
                        <div className="pin3" lat={formik.values.location_lat} lng={formik.values.location_long}>
                          <div style={{ fontSize: '15px' }}>{formik.values.shiffer}</div>
                        </div>
                      ) : null}
                    </GoogleMapReact>
                  </div>

                  {/* <div className="m-2 p-2">
                                        <img id="map"
                                             className="card-img-top"
                                             src={url}
                                             alt="Kaart"/>
                                    </div> */}

                  <div className="input-group m-2 pt-2 mr-3">
                    <label htmlFor="address">Määra objekti asukoht aadressi või koordinaatide järgi (aadress, küla, koordinaadid):</label>
                    <Select
                      showSearch
                      value={searchStr}
                      placeholder="aadress"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={handleSearch}
                      onSelect={handleChange}
                      notFoundContent={null}
                      style={{ width: '90%' }}>
                      <Option key={searchRes.key} value={searchRes.key}>
                        {searchRes.text}
                      </Option>
                    </Select>
                  </div>

                  <div className="input-group m-2 pt-2 mr-3">
                    <label htmlFor="location_radius">Määra raadius objekti asukoha punktist, mille sees on lubatud alustada tööd:</label>
                    <Select
                      className=""
                      id="location_radius"
                      name="location_radius"
                      style={{ width: '90%' }}
                      onChange={(v) => formik.setFieldValue('location_radius', v)}
                      value={formik.values.location_radius}
                      required>
                      <option key={'0'} value={0}>
                        0m
                      </option>
                      <option key={'100'} value={100}>
                        100m
                      </option>
                      <option key={'500'} value={500}>
                        500m
                      </option>
                      <option key={'1000'} value={1000}>
                        1km
                      </option>
                      <option key={'3000'} value={3000}>
                        3km
                      </option>
                      <option key={'50000'} value={50000}>
                        50km
                      </option>
                      <option key={'100000'} value={100000}>
                        100km
                      </option>
                    </Select>
                  </div>
                </Card>
              </div>
              <div className="mt-2">
                <Card title="Klient ja toode">
                  <Row gutter={8}>
                    <Col span={16}>
                      <label htmlFor="type">Toode/mudel</label>
                      <TreeSelect
                        style={{ width: '100%' }}
                        value={formik.values.product}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={treeData}
                        placeholder="Please select"
                        //treeDefaultExpandAll
                        treeDefaultExpandedKeys={[formik.values.product_group]}
                        onChange={(value, label, extra) => handleProductChange(value, label, extra)}
                      />
                    </Col>

                    <Col span={8}>
                      <label htmlFor="has_warehouse">Regioon</label>
                      <Form.Item validateStatus={error.region ? 'error' : ''}>
                        <Select
                          className=""
                          id="region"
                          name="region"
                          onChange={(v) => formik.setFieldValue('region', v)}
                          value={formik.values.region}
                          required>
                          <option key={'Põhi'} value="Põhi">
                            Põhi
                          </option>
                          <option key={'Lõuna'} value="Lõuna">
                            Lõuna
                          </option>
                          <option key={'Ida'} value="Ida">
                            Ida
                          </option>
                          <option key={'Lääs'} value="Lääs">
                            Lääs
                          </option>
                          <option key={'Tallinn'} value="Tallinn">
                            Tallinn
                          </option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <label htmlFor="has_warehouse">Tellija nimi</label>
                      <Input type="text" name="client_name" onChange={formik.handleChange} value={formik.values.client_name} />
                    </Col>
                    <Col span={12}>
                      <label htmlFor="type">Tellija esindaja</label>
                      <Input type="text" name="client_contact" onChange={formik.handleChange} value={formik.values.client_contact} />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col span={12}>
                      <div className="mt-2">
                        <label htmlFor="has_warehouse">Järelvalve ettevõte</label>
                      </div>
                      <Input type="text" name="superviser_company" onChange={formik.handleChange} value={formik.values.superviser_company} />
                    </Col>
                    <Col span={12}>
                      <div className="mt-2">
                        <label htmlFor="has_warehouse">Järelvalve nimi</label>
                      </div>
                      <Input type="text" name="superviser_contact" onChange={formik.handleChange} value={formik.values.superviser_contact} />
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
          </Row>

          {id ? (
            <div className="mt-2">
              <PaBindBegin object={meta} />
            </div>
          ) : null}
        </form>
      </div>
    </PageWithHeader>
  )
}

const PaBindBegin = ({ object }) => {
  // retrieve begin objects
  // filter out interesting ones (similar shiffer)
  // display a list
  // bind callback

  const editAllowed = useStoreState((state) => state.user.editAllowed)

  console.log('object:', object)

  const [beginObjects, setBeginObjects] = useState([])
  const [loading, setLoading] = useState(true)
  const [beginOK, setBeginOk] = useState(true)
  const loadObject = useStoreActions((actions) => actions.object.apiLoadMeta)

  async function fetchBeginData() {
    let res = await API('/api/objects/bind')
    await setBeginObjects(res.data)
    if (res.data === undefined) await setBeginOk(false)
    await setLoading(false)
  }

  async function handleBind(begin_objid) {
    await setLoading(true)
    await console.log('begin:objid binding', begin_objid)
    await API('/api/objects/bind', false, 'POST', {
      begin_objid: begin_objid,
      idobject: object.idobject
    })
    await loadObject(object.idobject)
    await setLoading(false)
  }

  useEffect(() => {
    fetchBeginData()
  }, [object])

  let myObjects = []
  if (beginObjects) myObjects = beginObjects.filter((b) => b.object_name.includes(object.shiffer))

  const columns = [
    { title: 'Nimi', dataIndex: 'object_name', key: 'object_name' },
    { title: 'BeginID', dataIndex: 'objectid', key: 'objectid' },
    { title: 'Stastus', dataIndex: 'status', key: 'status' },
    {
      title: 'Seotud?',
      dataIndex: 'objectid',
      key: 'bind',
      render: (text, record) => {
        if (object.begin_objid) {
          if (record.objectid.toString() === object.begin_objid.toString()) return <Tag color="#87d068"> Seotud </Tag>
          return <Tag color="#2db7f5"> Sidumata </Tag>
        }
        return <Tag color="#2db7f5"> Sidumata </Tag>
      }
    },
    {
      title: 'Tegevus',
      dataIndex: 'objectid',
      key: 'action',
      render: (text, record) => {
        if (editAllowed) {
          if (object.begin_objid) {
            if (record.objectid.toString() !== object.begin_objid.toString()) return <Tag onClick={() => handleBind(record.objectid)}> Seo </Tag>
            return null
          } else return <Tag onClick={() => handleBind(record.objectid)}> Seo </Tag>
        } else return null
      }
    }
  ]

  //console.log("myobjects", myObjects)

  return (
    <Card
      className="mb-2"
      title="Begin"
      //extra={}
    >
      {beginOK ? (
        <Table dataSource={myObjects} rowKey="objectid" columns={columns} size="middle" loading={loading} />
      ) : (
        <Alert message="Hmm: Tundub, et begin.ee server on katki." description="Kontakteeru helpdeskiga" type="error" showIcon />
      )}
    </Card>
  )
}

export default withRouter(PaObjectsUpdate)
