import { action, thunk, persist } from 'easy-peasy'
import API from "../../services/api"

const defaultColumns = {
  actual: {
    show: false
  },
  begin_planned: {
    show: false
  },
  changed_date: {
    show: false
  },
  client_contact: {
    show: false
  },
  client_name: {
    show: false
  },
  completed: {
    show: false
  },
  location: {
    show: false
  },
  overhead_price_per_ton: {
    show: false
  },
  overhead_rate: {
    show: false
  },
  product: {
    show: false
  },
  product_group: {
    show: false
  },
  prognosis: {
    show: false
  },
  remaining: {
    show: false
  },
  superviser_company: {
    show: false
  },
  superviser_contact: {
    show: false
  },
  target: {
    show: false
  }
}

const filtersModel = {
  // states
  items: {
    objects: {
      columns: defaultColumns,
      filtered: {},
      pagination: { pageSize: 100 },
      sorted: {
        columnKey: 'dateCreated',
        order: 'descend'
      },
      view: {},
    },
    tootmine: {
      columns: defaultColumns,
      filtered: {},
      pagination: { pageSize: 100 },
      sorted: {
        columnKey: 'dateCreated',
        order: 'descend'
      },
      view: {},
    },
    ehitus: {
      columns: defaultColumns,
      filtered: {},
      pagination: { pageSize: 100 },
      sorted: {
        columnKey: 'dateCreated',
        order: 'descend'
      },
      view: {},
    },
    small: {
      filtered: {},
      pagination: { pageSize: 100 },
      sorted: {
        columnKey: 'dateCreated',
        order: 'descend'
      },
      view: {},
    },
  },
  // actions
  save: action((state, { key, ...payload }) => {
    state.items[key] = payload
  }),
  clear: action((state, placeKey) => {
    if (placeKey) {
      delete state.items[placeKey]
    } else {
      state.items = {}
    }
  }),

  // thunks
  fetchFilters: thunk(async (actions) => {
    const { data } = await API('/api/me')
    if (data) {
      const { prefs } = data
      if (prefs && prefs.tables) {
        for (const key in prefs.tables) {
          actions.save({ key, ...prefs['tables'][key] })
        }
      }
    }
  }),
}

export default persist(filtersModel, { whitelist: ['items'] })
