import React from "react";
import {Modal} from "antd";
import {useStoreState, useStoreActions} from "easy-peasy";

const PaProductionImportDayModal = ({isOpen, onClose}) => {

    const meta = useStoreState(state => state.object.meta);
    const objectActiveDate = useStoreState(state => state.object.activeDate);
    const prefillIncome = useStoreActions(actions => actions.production.apiIncomePrefill);
    const prefillWarehouse = useStoreActions(actions => actions.warehouse.apiPrefillWarehouse);
    const copyCosts = useStoreActions(actions => actions.object.apiCopyCosts);
    const loadCosts = useStoreActions(actions => actions.object.apiLoadCosts);

    async function handlePrefill() {
        let payload = {
            date: objectActiveDate.format("YYYY-MM-DD 00:00:00"),
            objid: meta.idobject,
            object_id: meta.idobject
        }
        await copyCosts({
            target_date: objectActiveDate.format("YYYY-MM-DD 00:00:00"),
            target_obj_id: meta.idobject,
            source_date: '1900-01-01 00:00:00',
            source_obj_id: meta.idobject
        })
        await prefillIncome(payload)
        await prefillWarehouse(payload)
        await loadCosts(meta.idobject)
        onClose()
    }

    return <Modal
        visible={isOpen}
        //icon="info-sign"
        onCancel={onClose}
        onOk={() => handlePrefill()}
        title={"Andmete eeltäitmine"}
        //width={"70%"}
        centered
        destroyOnClose={true}
        okText={"OK"}
        cancelText={"Tühista"}
    >
        Impordi ainult siis, kui <b>tootmine</b> ja <b>materjali vedu</b> on tänaseks läbi!<br/>
        Imporditakse:<br/>
        - materjali sissetulek kaalult<br/>
        - väljunud toodangu kogused kaalult<br/>
        - kulud päevaaruande põhjalt

    </Modal>

}

export default PaProductionImportDayModal