
import React, {useEffect, useState} from "react";

import PageWithHeader from '../components/PageWithHeader'

import { DeleteOutlined, DoubleRightOutlined } from '@ant-design/icons';

import { Table, Button, Input, Card, Typography, Badge } from "antd";
import {Row, Col} from 'react-bootstrap'
import '../App.css';
import moment from "moment"
import {useHistory, withRouter, Redirect} from "react-router";
import {useStoreActions, useStoreState} from "easy-peasy";
import {smartSearch} from "../services/smartSearch";
import PaObjectsStatus from '../components/PaObjectsStatus'
import PaObjectsFavourite from '../components/PaObjectsFavourite'
import InputCell from "../components/InputCell";

const {Search} = Input;
const {Title} = Typography;
const ButtonGroup = Button.Group;

const PaBills = () => {

    const bills = useStoreState(state => state.bills.bills);
    const loading = useStoreState(state => state.bills.loading);
    const setLoading = useStoreActions(actions => actions.bills.setLoading);
    const loadBills = useStoreActions(actions => actions.bills.apiFetchBills)
    const updateBills = useStoreActions(actions => actions.bills.apiUpdateBills)
    const addBills = useStoreActions(actions => actions.bills.apiAddBills)

    const [activeCell, setActiveCell] =useState(false)
    const editAllowed = true

    let history = useHistory()

    function getCellReference(index, field) {
        return index.toString() + field
    }

    //function getCellReference(index, field, horizontal = false, vertical = false) {
    //
    //    if (editAllowed) {
    //
    //        //console.log("index, field, horizontal, vertical", index, field, horizontal, vertical)
    //        if (vertical === -1 && index > 0) return (index - 1).toString() + field // up
    //        if (vertical === 1 && index < 10 - 1) return (index + 1).toString() + field // down
    //
    //        return index.toString() + field
    //    }
    //    return null
    //}

    const getInputCell = (index, field, rowsCount, record) => {

        let defaultValue = record[field]
        if (record[field] && !isNaN(record[field])) {

            if (typeof record[field] !== 'string' && record[field] % 1 !== 0) {

                defaultValue = parseFloat(record[field].toFixed(2))
            }
        }

        return (
            <InputCell
                type="input"
                onClick={() => {setActiveCell(getCellReference(record.id, field)); console.log(record)}}
                onBlur={value => handleChange(value, field, index, record)}
                onUpdate={value => handleChange(value, field, index, record)}
                onMoveUp={() => null}
                onMoveDown={() => null}
                onMoveLeft={() => null}
                onMoveRight={() => null}
                active={activeCell === getCellReference(record.id, field)}
                defaultValue={defaultValue} // record[field]
            />

        )
    }

    function handleChange(value, field, index, record) {

        const payload = {
            name: record.name,
            status: record.status,
            id: record.id
        }

        payload[field] = value

        updateBills(payload)
        setActiveCell(false)

    }

    function handleRemove(record) {

        const payload = {
            name: record.name,
            status: 4,
            id: record.id
        }

        updateBills(payload)

    }


    function handleAdd(record) {
        const payload = {...record}
        addBills(payload)
    }

    useEffect(() => {
        (async () => {
            await loadBills()
            await setLoading(false)
        })()
        // eslint-disable-next-line
    }, []);

    const columns = [
        {
            title: 'ID',
            key: "id",
            dataIndex: "id",
            render: (text) => ''
        },
        {
            title: 'Number',
            key: "number",
            dataIndex: "number",

        }, {
            title: 'Bill',
            key: "name",
            dataIndex: "name",
            render: (text, record, index) => getInputCell(index, 'name', 10, record)
        },
        {
            title: '',
            key: "status",
            dataIndex: "status",
            render: text => text === 3 ? <Badge color="green" text="Kasutusel" /> : <Badge color="gray" text="Arhiveeritud" />

        },
        {
            title: 'Tegevused',
            key: "actions",
            dataIndex: "id",
            render: (text, record) => <div>

                <Button onClick={() => handleAdd(record)}><DoubleRightOutlined /> Lisa alam-bill</Button>
                <Button className="ml-2" onClick={() => handleRemove(record)}><DeleteOutlined /> Arhiveeri</Button>
            </div>
        },
    ]

    const data = []

    return (
        <PageWithHeader>

            <div className="m-2">
                <Card title={<div className="ml-2">Billid</div>}
                      bordered={false}
                      //extra={<Button onClick={() => null}>Lisa uus bill</Button>}      // todo: new base bill add logic
                >
                    <div className="m-1" style={{
                        "overflowX": "auto",
                        "background": "#fff"
                    }}>
                        <Table columns={columns} dataSource={bills.structured} loading={loading} indentSize={2} rowKey="id"/>
                    </div>
                </Card>
            </div>
        </PageWithHeader>
    )
}


export default withRouter(PaBills)
