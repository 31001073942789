import { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import InputCell, { MultiInput } from '../../../InputCell'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Badge, Button, Drawer, Input, Table, Tooltip } from 'antd'
import React from 'react'
import StatusIcon from '../../StatusIcon'
import PaWhResModal from './WarehouseResourcesTable'
import PaResourceMovementsContent from './ResourceMovementsDrawer'

const { Search } = Input

const PaObjectDayWarehouseCostTable = () => {
  const [activeCell, setActiveCell] = useState(false)

  const meta = useStoreState((state) => state.object.meta)
  const activeResource = useStoreState((state) => state.warehouse.activeResource)
  //const costData = useStoreState(state => state.warehouse.costs);
  const costData = useStoreState((state) => state.warehouse.costs_grouped_by)
  const updateCostData = useStoreActions((actions) => actions.warehouse.apiUpdateCosts)
  const fetchCostData = useStoreActions((actions) => actions.warehouse.apiFetchCosts)
  const fetchIncoming = useStoreActions((actions) => actions.warehouse.apiFetchIncoming)

  const amountsOnly = useStoreState((state) => state.warehouse.amountsAvailableOnly)
  const setAmountsOnly = useStoreActions((actions) => actions.warehouse.setAmountsAvailableOnly)

  const withCost = useStoreState((state) => state.warehouse.withCost)
  const setWithCost = useStoreActions((actions) => actions.warehouse.setWithCost)

  const withIncome = useStoreState((state) => state.warehouse.withIncome)
  const setWithIncome = useStoreActions((actions) => actions.warehouse.setWithIncome)
  const setActiveResource = useStoreActions((actions) => actions.warehouse.setActiveResource)

  const objectActiveDate = useStoreState((state) => state.object.activeDate)

  const search = useStoreState((state) => state.warehouse.searchString)
  const setSearch = useStoreActions((actions) => actions.warehouse.setSearchString)
  const [isModalOpen, setIsModalOpen] = useState(false)

  // additional warehouse information drawer, right side
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  function getCellReference(index, field) {
    return index.toString() + field
  }

  async function handleAddAmount(values, record) {
    if (!submitting) {
      await setSubmitting(true)
      await console.log('setting wh entry', values)

      if (record.added_amount !== values.added_amount || record.purch_price !== values.purchase_price) {
        let payload = await {
          object_id: meta.idobject,
          date: objectActiveDate.format('YYYY-MM-DD 00:00:00'),
          idresources: record.idresources,
          added_amount: values.added_amount, // > 0 ? values.added_amount : 0,
          purchase_price: values.purchase_price, //  > 0 ? values.purchase_price : 0,
          purchase_price_plain: values.purchase_price_plain, //  > 0 ? values.purchase_price : 0,
          unit_price: record.unit_price,
          idcosts: record.idcosts
        }
        await updateCostData(payload)
        await console.log('Setting cell false')
        await setActiveCell(false)
      } else {
        await console.log('no change detected')
        await setActiveCell(false)
      }

      await setSubmitting(false)
    } else console.log('double submitting triggered')
  }

  async function handleUseAmount(value, record) {
    if (!submitting) {
      await setSubmitting(true)
      await console.log('setting cost entry', value)

      if (value || record.amount) {
        let payload = await {
          object_id: meta.idobject,
          date: objectActiveDate.format('YYYY-MM-DD 00:00:00'),
          idresources: record.idresources,
          amount: value,
          unit_price: record.unit_price,
          idcosts: record.idcosts
        }
        await updateCostData(payload)
        await console.log('Setting cell false')
        await setActiveCell(false)
      } else setActiveCell(false)

      await setSubmitting(false)
    } else console.log('double submitting triggered')
  }

  async function handleUnitPrice(value, record) {
    if (!submitting) {
      setSubmitting(true)
      console.log('setting cost entry', value)

      if (value) {
        let payload = {
          object_id: meta.idobject,
          date: objectActiveDate.format('YYYY-MM-DD 00:00:00'),
          idresources: record.idresources,
          amount: record.amount,
          unit_price: value,
          idcosts: record.idcosts
        }
        await updateCostData(payload)
        console.log('Setting cell false')
        setActiveCell(false)
      } else setActiveCell(false)

      setSubmitting(false)
    } else console.log('double submitting triggered')
  }

  const columns = [
    //{title: 'idcosts', dataIndex: 'idcosts', key: 'index',},
    //{title: 'idresources', dataIndex: 'idresources', key: 'idresources',},
    {
      //title: <div><Icon type="plus-square"/> Materjal</div>,
      title: <div className="ml-4"> Materjal</div>,
      dataIndex: 'res_name1',
      key: 'res_name1',
      render: (text, record, index) => (record.grouper_row ? <b>{text}</b> : <span onClick={() => handleDetailsRequest(record)}>{text}</span>)
    },
    { title: 'Tehas/karjäär', dataIndex: 'res_property_factory', key: 'res_property_factory' },
    { title: 'Ettevõte', dataIndex: 'res_property_orgname', key: 'res_property_orgname' },
    { title: 'Ident1 ', dataIndex: 'res_property_ident1', key: 'res_property_ident1' },
    { title: 'KOOD', dataIndex: 'res_property_ident2', key: 'res_property_ident2', align: 'right' },
    { title: 'Ühik', dataIndex: 'res_unit', key: 'res_unit' },

    {
      title: 'Algsaldo',
      dataIndex: 'begin_amount',
      key: 'begin_amount',
      align: 'right',
      className: 'production_incoming',
      render: (text, record, index) => (record.grouper_row ? null : text ? text.toFixed(3) : '0.000')
    },
    {
      title: 'Juurde',
      dataIndex: 'added_amount',
      key: 'added_amount',
      align: 'right',
      className: 'production_incoming',

      render: (text, record, index) => {
        if (!record.grouper_row) {
          let defaultValue = text.toFixed(3)
          if (record.purch_price) defaultValue = text.toFixed(3) + ' @ ' + parseFloat(record.purch_price).toFixed(3)
          return (
            <MultiInput
              defaultValue={defaultValue}
              onClick={() => setActiveCell(getCellReference(record.idresources, 'added_amount'))}
              active={activeCell === getCellReference(record.idresources, 'added_amount')}
              onChange={(v) => handleAddAmount(v, record)}
              onBlur={(v) => handleAddAmount(v, record)}
              onUpdate={(v) => handleAddAmount(v, record)}
              record={record}
              loading={submitting}
            />
          )
        }
        return ''
      }
    },
    {
      title: 'Kulu',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      className: 'production_incoming',
      render: (text, record, index) =>
        !record.grouper_row ? (
          <InputCell
            type="input"
            onClick={() => setActiveCell(getCellReference(record.idresources, 'amount'))}
            onBlur={(value) => handleUseAmount(value, record)}
            onUpdate={(value) => handleUseAmount(value, record)}
            onMoveUp={(value) => null}
            onMoveDown={(value) => null}
            active={activeCell === getCellReference(record.idresources, 'amount')}
            defaultValue={record['amount']}
            loading={submitting}
          />
        ) : null
    },
    {
      title: 'Jääk',
      dataIndex: 'rest_amount',
      key: 'rest_amount',
      align: 'right',
      className: 'production_incoming',
      render: (text, record, index) => (record.grouper_row ? null : text ? text.toFixed(3) : '0.000')
    },

    {
      title: 'Ühiku hind',
      dataIndex: 'unit_price',
      key: 'unit_price',
      align: 'right',
      render: (text, record, index) =>
        !record.grouper_row ? (
          <InputCell
            type="input"
            onClick={() => setActiveCell(getCellReference(record.idresources, 'unit_price'))}
            onBlur={(value) => handleUnitPrice(value, record)}
            onUpdate={(value) => handleUnitPrice(value, record)}
            onMoveUp={(value) => null}
            onMoveDown={(value) => null}
            active={activeCell === getCellReference(record.idresources, 'unit_price')}
            defaultValue={record['unit_price']}
            loading={submitting}
          />
        ) : null
    },

    {
      title: 'Summa',
      dataIndex: 'row_sum',
      key: 'row_sum',
      align: 'right',
      render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '')
    },
    {
      title: '',
      dataIndex: 'info',
      key: 'info',
      render: (text, record, index) =>
        record.added_amount ? (
          record.purch_price < 0.01 ? (
            <Tooltip title="Sissetulek ei tohi jääda 0 hinnaga!">
              <ExclamationCircleOutlined style={{ color: '#c47f22' }} />
            </Tooltip>
          ) : null
        ) : null
    },
    {
      title: <i className="far fa-check-circle"> </i>,
      dataIndex: 'wh_status',
      key: 'status',
      render: (text, record, index) => {
        let res1 = <StatusIcon value={text} ignore={['4']} />
        let res2,
          res3 = ''

        if (record.count_zero_purch) {
          res2 = (
            <Tooltip title="Sul on 0 hinnaga sissetulekuid!">
              <Badge count={record.count_zero_purch} style={{ backgroundColor: '#c47f22' }} />
            </Tooltip>
          )
        }
        if (record.count_problematic_wh) {
          res3 = (
            <Tooltip title="Sul on probleemseid lao sissetulekuid!">
              <Badge count={record.count_problematic_wh} />
            </Tooltip>
          )
        }

        return (
          <div>
            {res1}
            {res2}
            {res3}
          </div>
        )
      }
    }
  ]

  let sum = costData.reduce((sum, current) => (sum = sum + current.row_sum), 0).toFixed(2)

  let filteredData = costData

  console.log(costData)

  //if (filter) filteredData = costData.filter(r => r.begin_amount > 0)

  async function handleDetailsRequest(record) {
    setIsDrawerOpen(true)
    // console.log(record)
    setActiveResource({
      id: record.idresources,
      name: record.res_name1
    })
  }

  const footer = () => (
    <div style={{ width: '100%' }} className="d-flex justify-content-end mr-3">
      <div> € {sum}</div>
      <div className="ml-2 mr-2"></div>
    </div>
  )

  useEffect(() => {
    if (meta.idobject) {
      fetchCostData({ object_id: meta.idobject, date: objectActiveDate.format('YYYY-MM-DD 00:00:00') })
      fetchIncoming({ object_id: meta.idobject })
    }

    // eslint-disable-next-line
  }, [objectActiveDate, meta])

  //const searchColumns = ["res_name1", "res_property_factory", "res_property_orgname", "res_property_ident1", "res_property_ident2", "begin_amount", "added_amount", "amount", "rest_amount", "unit_price"]

  return (
    <div style={{ overflowX: 'auto', background: '#fff' }} className={'p-2 pb-2'}>
      <PaWhResModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <Drawer
        title={activeResource.name}
        width={600}
        placement="right"
        closable={true}
        onClose={() => setIsDrawerOpen(false)}
        visible={isDrawerOpen}>
        <PaResourceMovementsContent />
      </Drawer>
      <div className="d-flex justify-content-between m-1 pb-2">
        <div>
          <h5>Materjalid</h5>
        </div>
        <div className="d-flex align-items-center">
          <div className="mr-2" style={{ fontSize: '18px' }}>
            <b>{objectActiveDate.format('DD.MM.YYYY')}</b>
          </div>
          <div className="mr-2">
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
              {' '}
              Lao nimekiri{' '}
            </Button>
          </div>
          <div className="mr-2 d-flex">
            <Button.Group>
              <Button onClick={() => setAmountsOnly(false)} type={amountsOnly ? null : 'primary'}>
                Kõik read
              </Button>
              <Button onClick={() => setAmountsOnly(true)} type={amountsOnly ? 'primary' : null}>
                Ainult laoseisuga
              </Button>
            </Button.Group>
            <Button.Group>
              <Button className="ml-2" onClick={() => setWithIncome(!withIncome)} type={withIncome ? 'primary' : null}>
                Täna juurde
              </Button>
              <Button onClick={() => setWithCost(!withCost)} type={withCost ? 'primary' : null}>
                Täna kulu
              </Button>
            </Button.Group>
          </div>
          <div>
            <Search value={search} onChange={(e) => setSearch(e.target.value)} className="mr-2" placeholder="Otsi..." />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        //dataSource={costData}
        dataSource={filteredData}
        //dataSource={costData}
        //className={"table-striped-rows"}
        size="middle"
        rowKey={(record) => `${record.res_name1}-row`}
        footer={footer}
        expandable={{ expandRowByClick: true }}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: false,
          hideOnSinglePage: true
        }}
      />
    </div>
  )
}

export default PaObjectDayWarehouseCostTable
