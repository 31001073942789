import React, { useEffect, useState } from "react"
import PageWithHeader from '../components/PageWithHeader'

import {
  CheckCircleOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  LoadingOutlined,
  DownloadOutlined
} from '@ant-design/icons'

import {
  Table,
  Tag,
  Button,
  Card,
  Input,
  Upload,
  Tooltip,
  Menu,
  Dropdown,
  Modal,
  Row,
} from "antd"
import '../App.css'
import moment from "moment"
import { useStoreActions, useStoreState } from "easy-peasy"
import { smartSearch } from "../services/smartSearch"
import { getS3FileURL } from '../utils/aws'
import { useHistory } from "react-router"

export const PaOffersTable = ({ offers }) => {
  const meta = useStoreState(state => state.object.meta)
  const objectOffers = useStoreState(state => state.object.objectOffers)
  const objectActiveDate = useStoreState(state => state.object.activeDate) // for income update
  const me = useStoreState(state => state.user.me)
  const setOfferTargetStatus = useStoreActions(actions => actions.offers.apiSetOfferTargetStatus)
  const loadObjectOffers = useStoreActions(actions => actions.offers.apiLoadOffers)
  const loadObjectCosts = useStoreActions(actions => actions.object.apiLoadCosts)
  const loadObjectIncome = useStoreActions(actions => actions.object.apiLoadIncome)
  const deleteOffers = useStoreActions(actions => actions.offers.apiDeleteOffers)
  const fetchObjectOffers = useStoreActions(actions => actions.object.apiFetchObjectOffers)
  
  useEffect(() => {
    const { idobject } = meta
    if (idobject) fetchObjectOffers(idobject)
  }, [])

  console.log(objectOffers)

  const history = useHistory()

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deleteOffernr, setDeleteOfferNr] = useState(false)
  const [loading, setLoading] = useState(false)

  async function handleOfferTargetStatusChange(offer_nr, status, obj_id) {

    await setOfferTargetStatus({
      status: status,
      offer_nr: offer_nr
    })
    await loadObjectOffers({ obj_id: obj_id })
  }

  function showDeleteModal(offer_nr) {
    setDeleteModalVisible(true)
    setDeleteOfferNr(offer_nr)
  }

  function hideDeleteModal() {
    setDeleteModalVisible(false)
    setDeleteOfferNr(false)
  }

  async function handleOfferDelete() {
    await setLoading(true)
    console.log("META:", meta)
    console.log("meta.idobject:", meta.idobject)

    await deleteOffers({ offer_nr: deleteOffernr })

    if (meta.idobject) {
      await loadObjectOffers({ obj_id: meta.idobject })
      await loadObjectCosts(meta.idobject)
      await loadObjectIncome({ obj_id: meta.idobject, date: objectActiveDate.format("YYYY-MM-DD 00:00:00") })
    }

    await setLoading(false)
    await hideDeleteModal()
  }

  const columns = [
    // {title: "#", dataIndex: "idoffer", key: "idoffer"},
    // {title: "#", dataIndex: "obj_id", key: "obj_id"},
    {
      title: "Olek",
      dataIndex: "is_target_offer",
      key: "is_target_offer",
      sorter: (a, b) => a.is_target_offer - b.is_target_offer,
      render: (text, record, index) => text === 1 ? <Tag color="geekblue">Eesmärk</Tag> :
        <Tag color="green">Lisatööd</Tag>
    }, {
      title: () => <Tooltip title="Eelarve staatus"><FileTextOutlined /></Tooltip>,
      dataIndex: "offer_status", key: "offer_status",
      sorter: (a, b) => a.offer_status - b.offer_status,
      render: status => <FileTextOutlined style={{ color: status === 3 ? 'green' : 'red' }} />
    }, {
      title: "Number", dataIndex: "offer_nr", key: "offer_nr",
      sorter: (a, b) => a.offer_nr.localeCompare(b.offer_nr),
      render: (text, record, index) => <div onClick={() => history.push('/offer/' + text)}>{text}</div>
    }, {
      title: "Kuupäev", dataIndex: "offer_date", key: "offer_date",
      sorter: (a, b) => moment(a.offer_date).unix() - moment(b.offer_date).unix(),
      render: (text, record, index) => (<div>{moment(record.offer_date).format("DD.MM.YYYY")}</div>)
    },
    //{title: "Shiffer", dataIndex: "shiffer", key: "shiffer",}
    {
      title: "Objekt", dataIndex: "obj_id", key: "obj_id",

      render: (text, record, index) => (<div>{record.binded_name}</div>)
    }, {
      title: 'Summa', dataIndex: 'offersum', key: 'offersum',
      render: (text, record, index) => text ? "€ " + text.toFixed(2).toString() : ""
    }, {
      title: "Kommentaar", dataIndex: "comment", key: "comment",
      sorter: (a, b) => a.comment.localeCompare(b.comment)
    }, {
      title: "Lisas", dataIndex: "username", key: "username",
      sorter: (a, b) => a.username.localeCompare(b.username)
    },
    {
      title: "Tegevused", dataIndex: "is_target_offer", key: "is_target_offer_2",
      render: (text, record, index) => {
        const file = objectOffers && objectOffers.find(({ filename }) => filename === record?.offer_nr)
        const path = file && file.path
        return (<Dropdown overlay={
          <Menu>
            <Menu.Item key="0">
              <span onClick={() => history.push('/offer/' + record.offer_nr)}>
                <FolderOpenOutlined /> Ava eelarve
              </span>
            </Menu.Item>
            {path && <Menu.Item key="4">
              <a target="_blank" rel="noopener noreferrer" href={getS3FileURL(path)}></a><DownloadOutlined /> Lae alla originaalfail
            </Menu.Item>}
            {text !== 1 ?
              <Menu.Item key="1">
                <span style={{ color: "green" }}
                  onClick={() => handleOfferTargetStatusChange(record.offer_nr, 1, meta.objid)}>
                  <CheckCircleOutlined /> Määra eesmärgiks
                </span>
              </Menu.Item>
              : null
            }
            {record.obj_id === 0 ?
              <Menu.Item key="2">
                <span>
                  <CheckCircleOutlined /> Seo objektiga
                </span>
              </Menu.Item>
              : null
            }
            <Menu.Divider />
            {me.group === 2 || me.id === meta.proj_manager ? // if i'm admin or project manager
              <Menu.Item key="3">
                {/* <span style={{color: "red"}} onClick={() => handleOfferDelete(record.offer_nr,  meta.objid)}> */}
                <span style={{ color: "red" }} onClick={() => showDeleteModal(record.offer_nr)}>
                  <DeleteOutlined /> Kustuta eelarve
                </span>
              </Menu.Item>
              :
              <Menu.Item key="3" disabled>
                <span>
                  <DeleteOutlined /> Kustuta eelarve
                </span>
              </Menu.Item>
            }
          </Menu>
        }>

          <EllipsisOutlined />
        </Dropdown>)
      }
    }
  ]


  return (
    <div>
      <Modal
        title="Kustuta eelarve"
        visible={deleteModalVisible}
        okText="Kustuta"
        cancelText="Tühista"
        onOk={handleOfferDelete}
        onCancel={hideDeleteModal}
      >
        {loading ?
          <div>
            <p> Kustutamine... </p> <LoadingOutlined />
          </div>
          :
          <div>
            <p>Eelarve: {deleteOffernr} </p>
            <p> Kustutatada saab ainult "TÖÖS" objektide eelarveid</p>
            <p> Kustutatakse eelarvega seotud tööd ja teostatud mahud, hinnapakkumine, eelarved, billide
                            eelarved</p>
            <p>Kulusid ei kustutata, aga kustutatakse eelarve ridadega seotud billid</p>
          </div>
        }
      </Modal>

      <Table
        columns={columns}
        dataSource={offers.filter(row => row.offer_status === 3)}
        className={"table-striped-rows"}
        size="middle"
        rowKey="idoffer"
        pagination={{
          //defaultPageSize: 5,
          showSizeChanger: true,
          onChange: (page, pageSize) => console.log("page: ", page, "pagesize: ", pageSize),
          onShowSizeChange: (current, size) => console.log("page: ", current, "pagesize: ", size)
        }}
      />
    </div>
  )
}

export const PaOffersTableV2 = ({ offers }) => {
  const meta = useStoreState(state => state.object.meta)
  const objectOffers = useStoreState(state => state.object.objectOffers)
  const objectActiveDate = useStoreState(state => state.object.activeDate) // for income update
  const me = useStoreState(state => state.user.me)
  const setOfferTargetStatus = useStoreActions(actions => actions.offers.apiSetOfferTargetStatus)
  const loadObjectOffers = useStoreActions(actions => actions.offers.apiLoadOffers)
  const loadObjectCosts = useStoreActions(actions => actions.object.apiLoadCosts)
  const loadObjectIncome = useStoreActions(actions => actions.object.apiLoadIncome)
  const deleteOffers = useStoreActions(actions => actions.offers.apiDeleteOffers)
  const addOffer = useStoreActions(actions => actions.offers.apiAddOffer)
  const fetchObjectOffers = useStoreActions(actions => actions.object.apiFetchObjectOffers)

  const history = useHistory()

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deleteOffernr, setDeleteOfferNr] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const { idobject } = meta
    if (idobject) fetchObjectOffers(idobject)
  }, [fetchObjectOffers, meta])

  console.log(objectOffers)

  const objectHasOffers = offers && offers.length > 0

  async function handleOfferTargetStatusChange(offer_nr, status, obj_id) {

    await setOfferTargetStatus({
      status: status,
      offer_nr: offer_nr
    })
    await loadObjectOffers({ obj_id: obj_id })
  }

  function showDeleteModal(offer_nr) {
    setDeleteModalVisible(true)
    setDeleteOfferNr(offer_nr)
  }

  function hideDeleteModal() {
    setDeleteModalVisible(false)
    setDeleteOfferNr(false)
  }

  async function handleOfferDelete() {
    await setLoading(true)
    console.log("META:", meta)
    console.log("meta.idobject:", meta.idobject)

    await deleteOffers({ offer_nr: deleteOffernr })

    if (meta.idobject) {
      await loadObjectOffers({ obj_id: meta.idobject })
      await loadObjectCosts(meta.idobject)
      await loadObjectIncome({ obj_id: meta.idobject, date: objectActiveDate.format("YYYY-MM-DD 00:00:00") })
    }

    await setLoading(false)
    await hideDeleteModal()
  }

  const columns = [
    // {title: "#", dataIndex: "idoffer", key: "idoffer"},
    // {title: "#", dataIndex: "obj_id", key: "obj_id"},
    //{
    //    title: "Olek",
    //    dataIndex: "is_target_offer",
    //    key: "is_target_offer",
    //    sorter: (a, b) => a.is_target_offer - b.is_target_offer,
    //    render: (text, record, index) => text === 1 ? <Tag color="geekblue">Eesmärk</Tag> :
    //        <Tag color="green">Lisatööd</Tag>
    //},
    //{
    //    title: () => <Tooltip title="Eelarve staatus"><Icon type="file-text"/></Tooltip>,
    //    dataIndex: "offer_status", key: "offer_status",
    //    sorter: (a, b) => a.offer_status - b.offer_status,
    //    render: status => <Icon type="file-text" style={{color: status === 3 ? 'green' : 'red'}}/>
    //},
    { title: "Ver", dataIndex: "costing_version", key: "costing_version", render: text => <Tag color="blue">{text}</Tag> },
    {
      title: "Eelarve nimi", dataIndex: "offer_nr", key: "offer_nr",
      sorter: (a, b) => a.offer_nr.localeCompare(b.offer_nr),
      render: (text, record, index) => <a onClick={() => history.push('/offer/' + text)}><b>{text}</b></a>
    }, {
      title: "Kuupäev", dataIndex: "offer_date", key: "offer_date",
      sorter: (a, b) => moment(a.offer_date).unix() - moment(b.offer_date).unix(),
      render: (text, record, index) => (<div>{moment(record.offer_date).format("DD.MM.YYYY")}</div>)
    },
    //{title: "Shiffer", dataIndex: "shiffer", key: "shiffer",}
    //{
    //    title: "Objekt", dataIndex: "obj_id", key: "obj_id",
    //
    //    render: (text, record, index) => (<div>{record.binded_name}</div>)
    //},
    {
      title: 'Summa', dataIndex: 'offersum', key: 'offersum',
      render: (text, record, index) => text ? "€ " + text.toFixed(2).toString() : ""
    },
    //{
    //    title: "Kommentaar", dataIndex: "comment", key: "comment",
    //    sorter: (a, b) => a.comment.localeCompare(b.comment)
    //},
    {
      title: "Lisas", dataIndex: "username", key: "username",
      sorter: (a, b) => a.username.localeCompare(b.username)
    },
    {
      title: "Tegevused", dataIndex: "is_target_offer", key: "is_target_offer_2",
      render: (text, record, index) => {
        const file = objectOffers && objectOffers.find(({ filename }) => filename === record?.offer_nr)
        const path = file && file.path
        return (<Dropdown overlay={
          <Menu>
            <Menu.Item key="0">
              <span onClick={() => history.push('/offer/' + record.offer_nr)}>
                <FolderOpenOutlined /> Ava eelarve
              </span>
            </Menu.Item>
            {path && <Menu.Item key="4">
              <a target="_blank" rel="noopener noreferrer" href={getS3FileURL(path)}></a><DownloadOutlined /> Lae alla originaalfail
            </Menu.Item>}
            {text !== 1 ?
              <Menu.Item key="1">
                <span style={{ color: "green" }}
                  onClick={() => handleOfferTargetStatusChange(record.offer_nr, 1, meta.objid)}>
                  <CheckCircleOutlined /> Määra eesmärgiks
                </span>
              </Menu.Item>
              : null
            }
            {record.obj_id === 0 ?
              <Menu.Item key="2">
                <span>
                  <CheckCircleOutlined /> Seo objektiga
                </span>
              </Menu.Item>
              : null
            }
            <Menu.Divider />
            {me.group === 2 || me.id === meta.proj_manager ? // if i'm admin or project manager
              <Menu.Item key="3">
                {/* <span style={{color: "red"}} onClick={() => handleOfferDelete(record.offer_nr,  meta.objid)}> */}
                <span style={{ color: "red" }} onClick={() => showDeleteModal(record.offer_nr)}>
                  <DeleteOutlined /> Kustuta eelarve
                </span>
              </Menu.Item>
              :
              <Menu.Item key="3" disabled>
                <span>
                  <DeleteOutlined /> Kustuta eelarve
                </span>
              </Menu.Item>
            }
          </Menu>
        }>
          <EllipsisOutlined />
        </Dropdown>
        )
      }
    }
  ]

  return (
    <div>
      <Modal
        title="Kustuta eelarve"
        visible={deleteModalVisible}
        okText="Kustuta"
        cancelText="Tühista"
        onOk={handleOfferDelete}
        onCancel={hideDeleteModal}
      >
        {loading ?
          <div>
            <p> Kustutamine... </p> <LoadingOutlined />
          </div>
          :
          <div>
            <p>Eelarve: {deleteOffernr} </p>
            <p> Kustutatada saab ainult "TÖÖS" objektide eelarveid</p>
            <p> Kustutatakse eelarvega seotud tööd ja teostatud mahud, hinnapakkumine, eelarved, billide
                            eelarved</p>
            <p>Kulusid ei kustutata, aga kustutatakse eelarve ridadega seotud billid</p>
          </div>
        }
      </Modal>

      <Table
        columns={columns}
        dataSource={offers.filter(row => row.offer_status === 3)}
        className={"table-striped-rows"}
        size="middle"
        rowKey="idoffer"
        locale={{
          emptyText:
            !objectHasOffers && <>
              <Row style={{ marginBottom: 15 }}>Sul ei ole objektil hinnapakkumist. Hinnapakkumine saab edaspidi
                                akti põhjaks.</Row>
              <Button onClick={async () => {
                const dateOffer = moment().format('YYYY-MM-DD')
                const object = meta
                const offer_nr = (object.shiffer + '-' + object.name + '-' + dateOffer).replaceAll(',', '_')
                console.log(offer_nr)
                await addOffer({
                  offer_nr: offer_nr,
                  shiffer: object.shiffer,
                  obj_id: object.idobject,
                  dateoffer: dateOffer
                })
                history.push('/offer/' + encodeURIComponent(offer_nr))
              }}>Lisa hinnapakkumine</Button>
            </>
        }}
        pagination={{
          //defaultPageSize: 5,
          showSizeChanger: true,
          onChange: (page, pageSize) => console.log("page: ", page, "pagesize: ", pageSize),
          onShowSizeChange: (current, size) => console.log("page: ", current, "pagesize: ", size)
        }}
      />
    </div>
  )
}


export const PaBudgetsTable = ({ idobject }) => {

  const meta = useStoreState(state => state.object.meta)
  const me = useStoreState(state => state.user.me)
  const loading = useStoreState(state => state.budgets.loading)
  const setLoading = useStoreActions(actions => actions.budgets.setLoading)
  let budgets = useStoreState(state => state.budgets.budgets)
  const loadBudgets = useStoreActions(actions => actions.budgets.apiFetchBudgets)
  const deleteBudgets = useStoreActions(actions => actions.budgets.apiDeleteBudgets)

  const history = useHistory()

  if (idobject && budgets) budgets = budgets.filter(budget => budget.obj_id === idobject)

  useEffect(() => {
    loadBudgets()
    setLoading(false)
  }, [])

  async function handleDelete(offer_nr) {
    await deleteBudgets({ offer_nr: offer_nr })
    await loadBudgets()
  }

  const columns = [
    {
      title: "Ver",
      dataIndex: "costing_version",
      key: "costing_version",
      sorter: (a, b) => a.costing_version - b.costing_version,
      render: (text, record, index) => <Tag color="geekblue">{record.costing_version}</Tag>

    }, {
      title: "Eelarve nimi",
      dataIndex: "offer_nr", key: "offer_nr",
      sorter: (a, b) => a.offer_nr.localeCompare(b.offer_nr),
      render: (text, record, index) => <a onClick={() => history.push('/budget/cost/' + text)}><b>{text}</b></a>

    }, {
      title: "Summa planeeringus", dataIndex: "sum_at_offer", key: "sum_at_offer",
      sorter: (a, b) => a.sum_at_offer - b.sum_at_offer,
      render: (text, record, index) => text ? "€ " + text.toFixed(2).toString() : ""

    },
    {
      title: "Summa eesmärk", dataIndex: "sum_target", key: "sum_target",
      sorter: (a, b) => a.sum_target - b.sum_target,
      render: (text, record, index) => text ? "€ " + text.toFixed(2).toString() : ""

    },
    {
      title: "Tegevused", dataIndex: "costing_version", key: "costing_version",
      render: (text, record, index) => <Dropdown overlay={
        <Menu>
          <Menu.Item key="0">
            <span onClick={() => history.push('/budget/cost/' + record.offer_nr)}>
              <FolderOpenOutlined /> Ava eelarve
            </span>
          </Menu.Item>
          {record.obj_id === 0 ?
            <Menu.Item key="2">
              <span>
                <CheckCircleOutlined /> Seo objektiga
              </span>
            </Menu.Item>
            : null
          }
          <Menu.Divider />
          {me.group === 2 || me.id === meta.proj_manager ? // if i'm admin or project manager
            <Menu.Item key="3" onClick={() => handleDelete(record.offer_nr)}>
              <span style={{ color: "red" }} onClick={() => null}>
                <DeleteOutlined /> Kustuta eelarve
              </span>
            </Menu.Item>
            :
            <Menu.Item key="3" disabled>
              <span>
                <DeleteOutlined /> Kustuta eelarve
              </span>
            </Menu.Item>
          }
        </Menu>
      }>

        <EllipsisOutlined />
      </Dropdown>
    }
  ]


  return (
    <div>

      <Table
        columns={columns}
        dataSource={budgets}
        className={"table-striped-rows"}
        loading={loading}
        size="middle"
        rowKey="idoffer"
        pagination={{
          //defaultPageSize: 5,
          showSizeChanger: true,
          onChange: (page, pageSize) => console.log("page: ", page, "pagesize: ", pageSize),
          onShowSizeChange: (current, size) => console.log("page: ", current, "pagesize: ", size)
        }}
      />
    </div>
  )
}


export const PaOffers = () => {

  const offers = useStoreState(state => state.offers.allOffers)
  const getOffers = useStoreActions(actions => actions.offers.apiLoadOffers)

  const [search, setSearch] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const searchColumns = ['offer_nr', 'binded_name', 'username', 'offersum']

  const [activeTab, setActiveTab] = useState('income')

  useEffect(() => {
    (async () => {

      await getOffers()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const tabList = [
    {
      key: 'income',
      tab: 'Tulueelarved',
    },
    {
      key: 'cost',
      tab: 'Kulueelarved',
    },
  ]

  const tabContent = {
    income: <PaOffersTable offers={smartSearch(offers, search, searchColumns)} />,
    cost: <PaBudgetsTable />
  }

  return (
    <PageWithHeader>
      <div className="m-2 p-2">
        <Card
          title="Eelarved"
          extra={<div className="d-flex">
            <Input
              placeholder="Otsi..."
              className="mr-2"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <Button type="primary" onClick={() => setIsModalOpen(!isModalOpen)}>Lisa uus</Button>
          </div>}
          tabList={tabList}
          activeTabKey={activeTab}
          onTabChange={key => setActiveTab(key)}
        >
          <div className="m-2" style={{
            overflowX: "auto",
            background: "#fff"
          }}>
            {isModalOpen ?
              <PaAddOffer />
              :
              null
            }
            {tabContent[activeTab]}

          </div>
        </Card>
      </div>
    </PageWithHeader>

  )
}

const PaAddOffer = () => {

  return (
    <div>

      lisa: todo
      käsitsi lisamine on vaja alles arendada


    </div>
  )
}

export default PaOffers
