import React from 'react'

import {withRouter,  } from 'react-router-dom'
import {Select} from "antd";
import { useStoreState} from "easy-peasy";
import PaObjectsStatus from './PaObjectsStatus'

const {Option} = Select;

const ObjectNavigator = ({onSelect, statuses=[2,3,4,5,6], style={width: '500px'}, selected=null}) => {

    const objects = useStoreState(state => state.objects.objects)

    return (
        <Select
            //ref={inputRef}
            autoFocus={true}
            dropdownMatchSelectWidth={true}
            showSearch
            className="mr-2"
            style={style}
            placeholder="Vali objekt..."
            optionFilterProp="children"
            onSelect={(value) => onSelect(value)}
            selected={selected}
        >
            {objects
                .filter(object => statuses.includes(object.status))
                .map((a) => {

                return (<Option
                    key={a.idobject}
                    value={a.idobject}
                >
                    <PaObjectsStatus status={a.status}/>  {a.shiffer} {a.name}
                </Option>)
            })}

        </Select>)
}
export default withRouter(ObjectNavigator)
