import AWS from 'aws-sdk'

export const s3 = new AWS.S3({
  accessKeyId: 'AKIATWQCDGEBCBCGGYX5',
  secretAccessKey: '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',
  region: 'eu-west-1'
})

export const getS3FileURL = (path) => {
  const bucket = 'fieldworks-uploads'
  const region = 'eu-west-1'
  return `https://${bucket}.s3-${region}.amazonaws.com${path}`
}
