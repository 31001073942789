import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Table, Button, Row, Col, Modal } from 'antd'
import { DeleteOutlined, PlusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import InputCell from '../../InputCell'

const TypeSelectOptions = {
  1: '1,5x',
  2: '1x',
  3: '2x',
  4: '2xE',
  5: '2,5x'
}

const TypeSelectOptionsList = [
  { label: '1,5x', value: 1 },
  { label: '1x', value: 2 },
  { label: '2x', value: 3 },
  { label: '2xE', value: 4 },
  { label: '2,5x', value: 5 }
]

const { confirm } = Modal

const showConfirm = (deleteRow) => {
  confirm({
    title: 'Oled sa kindel, et soovid rea kustutada?',
    icon: <ExclamationCircleOutlined />,
    //content: 'Some descriptions',
    onOk() {
      console.log('OK')
      deleteRow()
    },
    onCancel() {
      console.log('Cancel')
    }
  })
}

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name
  })
}

const getCellReference = ({ index, field, horizontal = false, vertical = false, rows, taborder = [] }) => {
  //console.log("index, field, horizontal, vertical", index, field, horizontal, vertical)
  if (vertical === -1 && index > 0) return (index - 1).toString() + field // up
  if (vertical === 1 && index < rows - 1) return (index + 1).toString() + field // down

  let horizontal_index = taborder.indexOf(field)
  let horizontal_max = taborder.length - 1

  if (horizontal_index > -1) {
    //value actually exists
    if (horizontal === -1 && horizontal_index > 0) return index.toString() + taborder[horizontal_index - 1] // left
    if (horizontal === 1 && horizontal_index < horizontal_max) return index.toString() + taborder[horizontal_index + 1] // right
    if (horizontal === -1 && horizontal_index === 0 && index > 0) return (index - 1).toString() + taborder[horizontal_max] // left
    if (horizontal === 1 && horizontal_index === horizontal_max && index < rows - 1) return false //(index + 1).toString() + taborder[0] // right end of row reached
    if (horizontal === 1 && horizontal_index === horizontal_max && index === rows - 1) return false // end of table right bottom, nothing selected
  }

  return index.toString() + field
}

const inputTypes = {
  number: 'number',
  string: 'string',
  date: 'date'
}

const PindamineTable = () => {
  const meta = useStoreState((state) => state.object.meta)
  const user = useStoreState((state) => state.user.me)
  const userId = user?.id
  const objectId = meta?.idobject
  const objectActiveDate = useStoreState((state) => state.object.activeDate)

  const fetchRoads = useStoreActions((actions) => actions.road.fetch)
  const roads = useStoreState((state) => state.road.list)
  const roadsItems = useStoreState((state) => state.road.items)
  const addRoad = useStoreActions((actions) => actions.road.add)
  const updateRoad = useStoreActions((actions) => actions.road.update)
  const removeRoad = useStoreActions((actions) => actions.road.delete)
  const prefillRoad = useStoreActions((actions) => actions.roadMaterials.prefill)
  const loadingRoad = useStoreState((state) => state.road.loading)

  const fetchMaterials = useStoreActions((actions) => actions.roadMaterials.fetch)
  const materials = useStoreState((state) => state.roadMaterials.items)
  const addMaterial = useStoreActions((actions) => actions.roadMaterials.add)
  const updateMaterials = useStoreActions((actions) => actions.roadMaterials.update)
  const removeMaterial = useStoreActions((actions) => actions.roadMaterials.delete)
  const loadingMaterials = useStoreState((state) => state.roadMaterials.loading)

  const fetchOfferRows = useStoreActions((actions) => actions.road.fetchOffers)
  const objectOfferRows = useStoreState((state) => state.road.offers)

  useEffect(() => {
    fetchRoads(objectId)
    fetchOfferRows(objectId)
  }, [objectId])

  const autoCompleteOptions = objectOfferRows.map(({ art_name, art_id }) => ({ art_name, art_id }))

  const dataSource = roads.filter(road => moment(road?.date).isSame(objectActiveDate, 'day'))

  const [activeCell, setActiveCell] = useState(false)
  const rows = roads ? roads.length : 0
  const taborder = [
    'road_number',
    'name',
    'start',
    'end',
    'area',
    'date',
    'cancel_date',
    'covering_state',
    'type',
    'temp_air',
    'temp_covering',
    'temp_bonding',
    'notes'
  ]

  const handleChange = async ({ value, field, index, rowId, type, update }) => {
    console.log('handling change...', { value, field, index, rowId, roadItem: roadsItems[rowId][field] })

    if ((type === inputTypes.number && /^\d+\.\d+$|^\d+$/.test(value)) || (type !== inputTypes.number && typeof value === 'string')) {
      const newValueString = value?.toString()
      const oldValueString = roadsItems[rowId][field]

      if (newValueString !== oldValueString) {
        setActiveCell(false)
        await update({
          id: rowId,
          field: field,
          value: value,
          type: type
        })
        if (field === 'name') {
          const aco = autoCompleteOptions?.find(({ art_name }) => art_name === value)
          if (aco) {
            await update({
              id: rowId,
              field: 'road_number',
              value: aco?.art_id || null,
              type: type
            })
          }
        }
      }
      setActiveCell(false)
    }
  }

  const getInputCell = (index, field, record, type, roundedTo = 4) => {
    let defaultValue = record[field]
    if (record[field] && !isNaN(record[field])) {
      if (roundedTo && typeof record[field] !== 'string' && record[field] % 1 !== 0) {
        defaultValue = parseFloat(record[field].toFixed(roundedTo))
      }
    }
    return (
      <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference({ index, field, rows, taborder }))}
        onBlur={(value) => handleChange({ value, field, index, rowId: record.idroad, type, update: updateRoad })}
        onUpdate={(value) => handleChange({ value, field, index, rowId: record.idroad, type, update: updateRoad })}
        onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
        onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
        active={activeCell === getCellReference({ index, field, rows, taborder })}
        defaultValue={defaultValue} // record[field]
      />
    )
  }

  const getSelectCell = (index, valueField, record, titleField, selectOptions) => (
    <InputCell
      type="goodSelect"
      selectOptions={selectOptions}
      onClick={() => setActiveCell(getCellReference({ index, field: titleField, rows, taborder }))}
      onUpdate={(value) => handleChange({ value, field: valueField, index, rowId: record.idroad, type: inputTypes.number, update: updateRoad })}
      onMoveLeft={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: -1, vertical: false, rows, taborder }))}
      onMoveRight={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: 1, vertical: false, rows, taborder }))}
      active={activeCell === getCellReference({ index, field: titleField, rows, taborder })}
      defaultValue={record[valueField]}
      defaultTitle={TypeSelectOptions[record[valueField]]}
    />
  )

  const getDateInputCell = (index, field, record) => (
    <InputCell
      type="datetime"
      onClick={() => setActiveCell(getCellReference({ index, field, rows, taborder }))}
      onBlur={(value) => handleChange({ value, field, index, rowId: record.idroad, type: inputTypes.date, update: updateRoad })}
      onUpdate={(value) => handleChange({ value, field, index, rowId: record.idroad, type: inputTypes.date, update: updateRoad })}
      onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
      onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
      onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
      onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
      active={activeCell === getCellReference({ index, field, rows, taborder })}
      defaultValue={record[field] ? moment(record[field]).format('DD.MM.YYYY') : null}
    />
  )

  const getAutocompleteCell = (index, valueField, titleField, rowsCount, selectProps, record) => (
    <InputCell
      type="goodautocomplete"
      selectProps={selectProps}
      onBlur={(value) => handleChange({ value, field: valueField, index, rowId: record.idroad, type: inputTypes.string, update: updateRoad })}
      onClick={() => setActiveCell(getCellReference({ index, field: titleField }))}
      onUpdate={(value) => handleChange({ value, field: valueField, index, rowId: record.idroad, type: inputTypes.string, update: updateRoad })}
      onMoveLeft={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: -1, vertical: false }))}
      onMoveRight={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: 1, vertical: false }))}
      active={activeCell === getCellReference({ index, field: titleField })}
      defaultValue={record[valueField]}
      defaultTitle={record[titleField]}
    />
  )

  const columns = [
    {
      title: <div style={{ fontSize: 18, fontWeight: 'bold' }}>Pinnatav tee</div>,
      key: 'pinnatav',
      align: 'left',
      children: [
        {
          title: 'Tee nr.',
          dataIndex: 'road_number',
          key: 'road_number',
          render: (text, record, index) => getInputCell(index, 'road_number', record, inputTypes.number)
        },
        {
          title: 'Nimetus',
          dataIndex: 'name',
          key: 'name',
          render: (text, record, index) =>
            getAutocompleteCell(
              index,
              'name',
              'name',
              rows,
              {
                optionsList: autoCompleteOptions.map(({ art_name }) => art_name),
                valueField: 'name',
                titleField: 'name'
              },
              record
            )
          //getInputCell(index, 'name', record, inputTypes.string)
        },
        {
          title: 'Algus km',
          dataIndex: 'start',
          key: 'start',
          render: (text, record, index) => getInputCell(index, 'start', record, inputTypes.number, null)
        },
        {
          title: 'Lõpp km',
          dataIndex: 'end',
          key: 'end',
          render: (text, record, index) => getInputCell(index, 'end', record, inputTypes.number, null)
        },
        {
          title: 'Pindala (m2)',
          dataIndex: 'area',
          key: 'area',
          render: (text, record, index) => getInputCell(index, 'area', record, inputTypes.number, null)
        }
      ]
    },
    {
      title: <div style={{ fontSize: 18, fontWeight: 'bold' }}>Pindamine</div>,
      key: 'pindamine',
      align: 'left',
      children: [
        {
          title: 'Kuupäev',
          dataIndex: 'date',
          key: 'date',
          render: (text, record, index) => getDateInputCell(index, 'date', record)
        },
        {
          title: 'Piirangute tühistamine',
          dataIndex: 'cancel_date',
          key: 'cancel_date',
          render: (text, record, index) => getDateInputCell(index, 'cancel_date', record)
        },
        {
          title: 'Katteseisund',
          dataIndex: 'covering_state',
          key: 'covering_state',
          render: (text, record, index) => getInputCell(index, 'covering_state', record, inputTypes.string)
        },
        {
          title: 'Liik',
          dataIndex: 'type',
          key: 'type',
          render: (text, record, index) => getSelectCell(index, 'type', record, 'type', TypeSelectOptionsList)
        }
      ]
    },
    {
      title: <div style={{ fontSize: 18, fontWeight: 'bold' }}>Temperatuur</div>,
      key: 'temperatuur',
      align: 'left',
      children: [
        {
          title: 'Õhk',
          dataIndex: 'temp_air',
          key: 'temp_air',
          render: (text, record, index) => getInputCell(index, 'temp_air', record, inputTypes.number)
        },
        {
          title: 'Kate',
          dataIndex: 'temp_covering',
          key: 'temp_covering',
          render: (text, record, index) => getInputCell(index, 'temp_covering', record, inputTypes.number)
        },
        {
          title: 'Sideaine',
          dataIndex: 'temp_bonding',
          key: 'temp_bonding',
          render: (text, record, index) => getInputCell(index, 'temp_bonding', record, inputTypes.number)
        }
      ]
    },
    {
      title: <div style={{ fontSize: 18, fontWeight: 550 }}>Märkused</div>,
      width: '124px',
      dataIndex: 'notes',
      key: 'notes',
      render: (text, record, index) => getInputCell(index, 'notes', record, inputTypes.string)
    },
    {
      title: (
        <Row gutter={[4, 4]} justify="center">
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {
                addRoad({ idobject: objectId, iduser: userId, name: null, road_number: null, date: objectActiveDate.format("YYYY-MM-DD 00:00:00")})
              }}
            />
          </Col>
        </Row>
      ),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <Row gutter={[4, 4]} justify="center">
            <Col>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  console.log(record)
                  showConfirm(() => {
                    removeRoad({ id: record?.idroad, obj_id: objectId })
                  })
                }}
              />
            </Col>
          </Row>
        )
      }
    }
  ]

  return (
    <Table
      size="small"
      loading={loadingRoad}
      dataSource={dataSource}
      columns={columns}
      rowSelection={rowSelection}
      rowKey={(record) => `road-${record?.idroad}`}
      expandable={{
        expandedRowRender: (record) => (
          <RowSubTable
            addMaterial={addMaterial}
            updateMaterials={updateMaterials}
            removeMaterial={removeMaterial}
            fetchMaterials={fetchMaterials}
            prefillRoad={prefillRoad}
            materials={materials[record.idroad]}
            roadId={record.idroad}
            userId={userId}
            objectActiveDate={objectActiveDate}
            road={roads.find((road) => road.idroad === record.idroad)}
            loading={loadingMaterials}
          />
        )
      }}
    />
  )
}

const RowSubTable = ({
  addMaterial,
  updateMaterials,
  removeMaterial,
  fetchMaterials,
  prefillRoad,
  materials,
  roadId,
  userId,
  objectActiveDate,
  road,
  loading
}) => {
  useEffect(() => {
    fetchMaterials(roadId)
  }, [roadId])

  const [activeCell, setActiveCell] = useState(false)
  const rows = materials ? materials.length : 0
  const taborder = [
    'gravel_fraction',
    'gravel_amount',
    'gravel2_fraction',
    'gravel2_amount',
    'bonding_brand',
    'bonding_supplier',
    'bonding_bill_number',
    'bonding_amount',
    'bonding_norm',
    'bonding_fiber'
  ]

  const handleChange = async ({ value, field, index, rowId, type, update }) => {
    console.log('handling change...', { value, field, index, rowId, materials: materials[rowId][field] })

    if ((type === inputTypes.number && /^\d+\.\d+$|^\d+$/.test(value)) || (type !== inputTypes.number && typeof value === 'string')) {
      const newValueString = value?.toString()
      const oldValueString = materials[rowId][field]?.toString()

      if (newValueString !== oldValueString) {
        await update({
          id: rowId,
          field: field,
          value: value,
          type: type
        })
      }
    }
    setActiveCell(false)
  }

  const getInputCell = (index, field, record, type, roundedTo = 2) => {
    let defaultValue = record[field]
    if (record[field] && !isNaN(record[field])) {
      if (typeof record[field] !== 'string' && record[field] % 1 !== 0) {
        defaultValue = parseFloat(record[field].toFixed(roundedTo))
      }
    }
    return (
      <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference({ index, field, rows, taborder }))}
        onBlur={(value) => handleChange({ value, field, index, rowId: record.id, type, update: updateMaterials })}
        onUpdate={(value) => handleChange({ value, field, index, rowId: record.id, type, update: updateMaterials })}
        onMoveUp={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: -1, rows, taborder }))}
        onMoveDown={(value) => setActiveCell(getCellReference({ index, field, horizontal: false, vertical: 1, rows, taborder }))}
        onMoveLeft={(value) => setActiveCell(getCellReference({ index, field, horizontal: -1, vertical: false, rows, taborder }))}
        onMoveRight={(value) => setActiveCell(getCellReference({ index, field, horizontal: 1, vertical: false, rows, taborder }))}
        active={activeCell === getCellReference({ index, field, rows, taborder })}
        defaultValue={defaultValue} // record[field]
      />
    )
  }

  const getSelectCell = (index, valueField, record, titleField, selectProps) => (
    <InputCell
      type="select"
      selectProps={selectProps}
      onClick={() => setActiveCell(getCellReference({ index, field: titleField, rows, taborder }))}
      onUpdate={(value) => handleChange({ value, valueField, index, rowId: record.id, type: inputTypes.number, update: updateMaterials })}
      onMoveLeft={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: -1, vertical: false, rows, taborder }))}
      onMoveRight={(value) => setActiveCell(getCellReference({ index, field: titleField, horizontal: 1, vertical: false, rows, taborder }))}
      active={activeCell === getCellReference({ index, field: titleField, rows, taborder })}
      defaultValue={record[valueField]}
      defaultTitle={record[titleField]}
    />
  )

  const dataSource = materials ? Object.values(materials) : []
  console.log('intable', dataSource)

  const columns = [
    {
      title: <div style={{ fontSize: 16, fontWeight: 'bold' }}>Killustik</div>,
      key: 'killustik',
      align: 'left',
      children: [
        {
          title: 'Fraktsioon',
          dataIndex: 'gravel_fraction',
          key: 'gravel_fraction',
          render: (text, record, index) => getInputCell(index, 'gravel_fraction', record, inputTypes.number)
        },
        {
          title: 'Kogus (t)',
          dataIndex: 'gravel_amount',
          key: 'gravel_amount',
          render: (text, record, index) => getInputCell(index, 'gravel_amount', record, inputTypes.number)
        },
        {
          title: 'Kulu (kg/m2)',
          dataIndex: 'gravel_expense',
          key: 'gravel_expense',
          render: (text, record, index) => (record?.gravel_amount && road?.area ? (record?.gravel_amount / road?.area * 1000).toFixed(2) : '-')
        }
      ]
    },
    {
      title: <div style={{ fontSize: 16, fontWeight: 'bold' }}>Killustik 2</div>,
      key: 'killustik2',
      align: 'left',
      children: [
        {
          title: 'Fraktsioon',
          dataIndex: 'gravel2_fraction',
          key: 'gravel2_fraction',
          render: (text, record, index) => getInputCell(index, 'gravel2_fraction', record, inputTypes.number)
        },
        {
          title: 'Kogus (t)',
          dataIndex: 'gravel2_amount',
          key: 'gravel2_amount',
          render: (text, record, index) => getInputCell(index, 'gravel2_amount', record, inputTypes.number)
        },
        {
          title: 'Kulu (kg/m2)',
          dataIndex: 'gravel2_expense',
          key: 'gravel2_expense',
          render: (text, record, index) => (record?.gravel2_amount && road?.area ? (record?.gravel2_amount / road?.area * 1000)?.toFixed(2) : '-')
        }
      ]
    },
    {
      title: <div style={{ fontSize: 16, fontWeight: 'bold' }}>Sideaine</div>,
      key: 'sideaine',
      align: 'left',
      children: [
        {
          title: 'Mark',
          dataIndex: 'bonding_brand',
          key: 'bonding_brand',
          render: (text, record, index) => getInputCell(index, 'bonding_brand', record, inputTypes.string)
        },
        {
          title: 'Tarnija',
          dataIndex: 'bonding_supplier',
          key: 'bonding_supplier',
          render: (text, record, index) => getInputCell(index, 'bonding_supplier', record, inputTypes.string)
        },
        {
          title: 'Saatelehe nr.',
          dataIndex: 'bonding_bill_number',
          key: 'bonding_bill_number',
          render: (text, record, index) => getInputCell(index, 'bonding_bill_number', record, inputTypes.string)
        },
        {
          title: 'Kogus (t)',
          dataIndex: 'bonding_amount',
          key: 'bonding_amount',
          render: (text, record, index) => getInputCell(index, 'bonding_amount', record, inputTypes.number)
        },
        {
          title: 'Kulu (kg/m2)',
          dataIndex: 'bonding_expense',
          key: 'bonding_expense',
          render: (text, record, index) => (record?.bonding_amount && road?.area ? (record?.bonding_amount / road?.area * 1000).toFixed(2) : '-')
        },
        {
          title: 'Norm',
          dataIndex: 'bonding_norm',
          key: 'bonding_norm',
          render: (text, record, index) => getInputCell(index, 'bonding_norm', record, inputTypes.number)
        },
        {
          title: 'Fiiberkiud',
          dataIndex: 'bonding_fiber',
          key: 'bonding_fiber',
          render: (text, record, index) => getInputCell(index, 'bonding_fiber', record, inputTypes.number)
        }
      ]
    },
    {
      title: (
        <Row gutter={[4, 4]} justify="center">
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => {
                addMaterial({ idroad: roadId, iduser: userId })
              }}
            />
          </Col>
        </Row>
      ),
      key: 'material_actions',
      render: (text, record, index) => {
        return (
          <Row gutter={[4, 4]} justify="center">
            <Col>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  console.log(record)
                  showConfirm(() => {
                    removeMaterial({ id: record?.id, idroad: roadId })
                  })
                }}
              />
            </Col>
          </Row>
        )
      }
    }
  ]

  return (
    <Table
      size="small"
      loading={loading}
      rowKey={(record) => `$road-${roadId}-material-${record?.id}`}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      locale={{
        emptyText: (
          <>
            {/*             <Row style={{ marginBottom: 15 }}>Sul ei ole objektil hinnapakkumist. Hinnapakkumine saab edaspidi akti põhjaks.</Row>
             */}
            <Button
              onClick={async () => {
                await prefillRoad({ id: roadId, date: moment(objectActiveDate).format('YYYY-MM-DD') })
              }}>
              Eeltäida kulude põhjal
            </Button>
          </>
        )
      }}
    />
  )
}

export default PindamineTable
