import React, {useEffect, useState} from 'react'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic, Table, Badge, Tooltip, Input, DatePicker } from "antd";

import {useStoreActions, useStoreState} from "easy-peasy";
import moment from 'moment'
import {Bar, Line} from 'react-chartjs-2';
import PaRangeSelect from "../../RangeSelect";

const {RangePicker} = DatePicker;
const InputGroup = Input.Group;
const pageDivStyle = {padding: '10px 0px'}


const StatisticsBlockProduction = ({setActiveTab}) => {

    const daterange = useStoreState(state => state.production.overviewDateRange)
    const setDaterange = useStoreActions(actions => actions.production.setOverviewDateRange)

    const meta = useStoreState(state => state.object.meta)
    const objectCostData = useStoreState(state => state.object.costs)
    const productionTotals = useStoreState(state => state.production.overviewInRange);

    let costs = objectCostData ? Object.values(objectCostData) : []

    costs = costs.filter(r => moment(r.cost_date) >= daterange[0] && moment(r.cost_date) <= daterange[1])

    const tons = productionTotals.totalProduction.amount

    function getCostByType(type) {
        return costs ?
            costs.filter(r => r.cost_type === type).reduce((sum, r) => sum + r.row_sum, 0)
            :
            0
    }

    function getCostByMaterialType(type) {
        return costs ?
            costs.filter(r => r.res_name2 === type).reduce((sum, r) => sum + r.row_sum, 0)
            :
            0
    }

    const stats = {
        costTotalGross: (costs ? costs.reduce((sum, r) => sum + r.row_sum, 0) : 0) - (costs ? costs.reduce((sum, r) => r.cost_type === 9 ? sum + r.row_sum : sum, 0) : 0),
        incomeTotal: productionTotals.totalProduction.sum,
        //costOverheadTotal: productionTotals.totalProduction.amount * meta.overhead_price_per_ton,
        costOverheadTotal: (costs ? costs.reduce((sum, r) => r.cost_type === 9 ? sum + r.row_sum : sum, 0) : 0),
        costTotal: (costs ? costs.reduce((sum, r) => sum + r.row_sum, 0) : 0),
        costLabour: getCostByType(8) + getCostByType(1),
        costMaterials: getCostByType(5),
        costOther: getCostByType(6),
        costMaterialsFuel: getCostByMaterialType('Kütus'),
        costMaterialsGlue: getCostByMaterialType('Sideaine'),
        costMaterialsRock: getCostByMaterialType('Täitematerjal')

    }

    const seis = [
        {
            title: "Kasum:",
            value: (stats.incomeTotal - stats.costTotal).toFixed(2),
            ratio: (stats.incomeTotal - stats.costTotal) / tons || 0,
            type: 'bold',
            id:1
        },
        {
            title: "Tulud kokku:",
            value: stats.incomeTotal.toFixed(2),
            ratio: stats.incomeTotal / tons || 0,
            type: 'bold',
            id:2
        },
        {title: "Kulud kokku:", value: stats.costTotal.toFixed(2), ratio: stats.costTotal / tons || 0, type: 'bold', id: 3},
        {title: "Tööjõukulu:", value: stats.costLabour.toFixed(2), ratio: stats.costLabour / tons || 0, type: '', id: 4},
        {title: "Materjalid:", value: stats.costMaterials.toFixed(2), ratio: stats.costMaterials / tons || 0, type: '', id:5},
        {
            title: "- Kütus",
            value: stats.costMaterialsFuel.toFixed(2),
            ratio: stats.costMaterialsFuel / tons || 0,
            type: '',
            id:6
        },
        {
            title: "- Sideaine",
            value: stats.costMaterialsGlue.toFixed(2),
            ratio: stats.costMaterialsGlue / tons || 0,
            type: '',
            id:7
        },
        {
            title: "- Täitematerjal",
            value: stats.costMaterialsRock.toFixed(2),
            ratio: stats.costMaterialsRock / tons || 0,
            type: '',
            id:8
        },
        {title: "Muud kulud: ", value: stats.costOther.toFixed(2), ratio: stats.costOther / tons || 0, type: '', id:9},
        {
            title: "Tootmise üldkulud: ",
            value: stats.costOverheadTotal.toFixed(2),
            ratio: stats.costOverheadTotal / tons || 0,
            type: '',
            id:10
        },
    ]

    const columns = [
        {
            title: '', dataIndex: 'title', key: 'title',
            render: (text, record, index) => record.type === 'bold' ? <b>{text}</b> : text
        },
        {
            title: '€', dataIndex: 'value', key: 'value', align: 'right',
            render: (text, record, index) => text
        },
        {
            title: '€/t', dataIndex: 'ratio', key: 'ratio', align: 'right',
            render: (text, record, index) => text.toFixed(2)
        },
    ]

    return (
        <div>
            <Row gutter={16}>
                <Card className="m-2 p-1" size="small">
                    <div className="d-flex justify-content-between">
                        <div>Üldkulu
                            määr: <b>{meta.overhead_price_per_ton} </b> / T
                        </div>
                        <div>
                            <InputGroup compact>
                                <RangePicker
                                    value={daterange}
                                    allowClear={false}
                                    style={{width: '250px'}}
                                    format='DD.MM.YY'
                                    onChange={setDaterange}
                                />
                                <PaRangeSelect onSelect={setDaterange}/>
                            </InputGroup>

                        </div>
                    </div>
                </Card>
            </Row>

            <Row gutter={16}>

                <Col sm={24} md={16}>
                    <Row gutter={12}>
                        <Col sm={24} md={12}>
                            <Card title="Toodang"
                                  bordered={false}
                                // extra={
                                //     <div>
                                //         <Tag color="green">{(targetProfit / 1000).toFixed(0)} k</Tag>
                                //         <Tag > Marginaal: {(targetProfitablility).toFixed(2)} %</Tag>
                                //     </div>
                                // }
                                  extra={
                                      <div className='d-flex align-items-baseline'>
                                          <div style={{
                                              fontSize: '16px',
                                              fontWeight: 'bold'
                                          }}>{productionTotals.totalProduction.amount.toFixed(0)} T
                                          </div>
                                      </div>
                                  }
                            >
                                <Row>
                                    <Col span={12}>
                                        <Statistic title="Sisemüük T" value={productionTotals.internalProduction.amount}
                                                   precision={2}/>
                                    </Col>
                                    <Col span={12}>
                                        <Statistic
                                            title="Väljamüük T"
                                            precision={2}
                                            value={productionTotals.externalProduction.amount}/>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Statistic title="Sisemüük €"
                                                   precision={2}
                                                   value={productionTotals.internalProduction.sum}/>
                                    </Col>
                                    <Col span={12}>
                                        <Statistic
                                            title="Väljamüük €"
                                            precision={2}
                                            value={productionTotals.externalProduction.sum}/>
                                    </Col>

                                </Row>

                            </Card>
                        </Col>
                        <Col sm={24} md={12}>
                            <Card title="Tulem"
                                  bordered={false}
                                // extra={<Tag color={resultCurrent > 0 ? "green" : "red"}>€ {resultCurrent.toFixed(0)} </Tag>}>
                                // extra={<div style={{fontSize: '16px', fontWeight: 'bold'}}> €{((stats.incomeTotal / 1) - (stats.costTotal / 1)).toFixed(2)}</div>}
                                  extra={<ColoredValue value={((stats.incomeTotal / 1) - (stats.costTotal / 1))}/>}
                            >
                                <Row>
                                    <Col span={11}>
                                        <Statistic title="Kulud"
                                                   value={stats.costTotalGross}
                                                   precision={2}
                                                   valueStyle={{color: '#cf1322'}}
                                        />
                                    </Col>
                                    <Col span={2}/>
                                    <Col span={11}>
                                        <Statistic title="Tulud"
                                                   value={stats.incomeTotal}
                                                   precision={2}
                                                   valueStyle={{color: '#3f8600'}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <Statistic
                                            title="Kulud koos üldkuludega"
                                            value={stats.costTotal}
                                            precision={2}
                                            //valueStyle={{color: '#3f8600'}}
                                        />
                                    </Col>
                                    <Col span={2}/>
                                    <Col span={11}>
                                        <Statistic title="Tootmise üldkulud " value={stats.costOverheadTotal}
                                                   precision={2}/>
                                    </Col>
                                </Row>


                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={16}>

                        <Col span={24}>
                            <div className="fullscreenonly">
                                <OverViewBlock setActiveTab={setActiveTab}/>
                            </div>

                        </Col>

                    </Row>

                </Col>

                <Col sm={24} md={8}>
                    <Row gutter={12}>

                        <Card title="Seis"
                              bordered={false}
                              extra={
                                  <div className='d-flex'>

                                  </div>
                              }>

                            <div>
                                <Table rowKey="id" dataSource={seis} columns={columns} size="middle" pagination={false}/>
                            </div>

                        </Card>
                    </Row>
                </Col>

            </Row>


        </div>
    )
}

const ColoredValue = ({value}) => {
    if (value) {
        if (value > 0) return (
            <div style={{
                fontSize: '16px',
                fontWeight: 'bold', color: '#3f8600'
            }}><ArrowUpOutlined /> € {value.toFixed(2)}
            </div>
        );
        else return (
            <div style={{
                fontSize: '16px',
                fontWeight: 'bold', color: '#cf1322'
            }}><ArrowDownOutlined /> € {value.toFixed(2)}
            </div>
        );
    }
    return null
}

const OverViewBlock = ({setActiveTab}) => {

    const [activeTabKey, setActiveTabKey] = useState('reports')
    const objectCostProblematic = useStoreState(state => state.warehouse.problematic)

    const tabList = [
        {
            key: 'reports',
            tab: 'Päevaaruanded',
        },
        {
            key: 'production',
            tab: 'Toodang',
        },
        {
            key: 'kpi',
            tab: 'Suhtarvud',
        }

    ];

    const contentList = {
        reports: <DailyReportsBlock setActiveTab={setActiveTab}/>,
        production: <DailyReportsGraphBlock setActiveTab={setActiveTab} focus="production"/>,
        kpi: <DailyReportsGraphBlock setActiveTab={setActiveTab} focus="kpi"/>,
    };

    let problematic = objectCostProblematic.reduce((sum, row) => sum + row.count, 0)

    return (
        <Card
            style={{width: '100%'}}
            //title={<div className="m-2"><h4>Tehase LIVE</h4></div>}
            tabList={tabList}
            activeTabKey={activeTabKey}
            className="mt-2"
            onTabChange={key => setActiveTabKey(key)}
            tabBarExtraContent={
                <div className="mr-3">
                    <Tooltip title="Probleemseid lao sissetulekuid"><Badge count={problematic}/></Tooltip>
                </div>
            }
        >
            {contentList[activeTabKey]}
        </Card>
    )
}

const DailyReportsBlock = ({setActiveTab}) => {

    const meta = useStoreState(state => state.object.meta)
    const setObjectActiveDate = useStoreActions(actions => actions.object.setObjectActiveDate)
    const fetchProblematic = useStoreActions(actions => actions.warehouse.apiFetchProblematic)
    const tableData1 = useStoreState(state => state.production.overviewDailyReportsData)


    useEffect(() => {
        fetchProblematic({object_id: meta.idobject})
         // eslint-disable-next-line
    }, [])

    const handleNavToDay = (day) => {
        setObjectActiveDate(moment(day))
        setActiveTab("2")

    }

    const columns = [
        {
            title: 'Kuupäev',
            key: "date",
            dataIndex: "date",
            sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
            render: (text, record, index) => record.date ? moment(record.date).format("DD.MM.YY") : "",
        }, {
            title: 'Brutotulem',
            dataIndex: 'result',
            key: 'result',
            align: "right",
            sorter: (a, b) => a.result - b.result,
            render: text => text ? "€ " + text.toFixed(0) : "€ 0",
        },
        {
            title: 'Tulud',
            dataIndex: 'income',
            key: 'income',
            align: "right",
            sorter: (a, b) => a.income - b.income,
            render: text => text ? "€ " + text.toFixed(0) : "€ 0",
        },
        {
            title: 'Kulud',
            dataIndex: 'costs',
            key: 'costs',
            align: "right",
            sorter: (a, b) => a.costs - b.costs,
            render: text => text ? "€ " + text.toFixed(0) : "€ 0",
        },
        {
            title: 'Toodang kokku',
            dataIndex: 'totalProduced',
            key: 'totalProduced',
            align: "right",
            sorter: (a, b) => a.totalProduced - b.totalProduced,
            render: text => text ? text.toFixed(0) : 0,
        },
        {
            title: 'Toodetud sisemüük',
            dataIndex: 'internalProduced',
            key: 'internalProduced',
            align: "right",
            sorter: (a, b) => a.internalProduced - b.internalProduced,
            render: text => text ? text.toFixed(0) : 0,
        },
        {
            title: 'Toodetud välismüük',
            dataIndex: 'externalProduced',
            key: 'externalProduced',
            align: "right",
            sorter: (a, b) => a.externalProduced - b.externalProduced,
            render: text => text ? text.toFixed(0) : 0,
        },
        {
            title: 'Kulud / tonn',
            dataIndex: 'result',
            key: 'ratio',
            align: "right",
            sorter: (a, b) => (a.costs / a.totalProduced) - (b.costs / b.totalProduced),
            render: (text, record, index) => record.costs && record.totalProduced ? (record.costs / record.totalProduced).toFixed(3) : '',
        },
        {
            title: <i className="far fa-check-circle"> </i>,
            dataIndex: 'problematic',
            key: 'problematic',
            align: 'center',
            width: 35,
            render: (text, record, index) => text > 0 ? <Badge count={text} style={{backgroundColor: '#f5222d'}}/> :
                <i className="far fa-check-circle" style={{color: 'green'}}> </i>
        }

    ]


    return (
        <Table columns={columns}
               dataSource={tableData1}
               onRow={(record, rowIndex) => {
                   return {
                       onClick: () => handleNavToDay(record.date)
                   }
               }}
               rowKey="date"
               scroll={{y: 240}}
               pagination={{
                   defaultPageSize: 1000,
                   showSizeChanger: false,
                   hideOnSinglePage: true
               }}
               size="middle"
               rowClassName={(record, index) => record.result <= 0 ? 'object-warning' : 'object-success'}
        />
    )
}

const DailyReportsGraphBlock = ({setActiveTab, focus}) => {


    const objectCostData = useStoreState(state => state.object.costs)
    //const setObjectActiveDate = useStoreActions(actions => actions.object.setObjectActiveDate)
    const daterange = useStoreState(state => state.production.overviewDateRange)

    let income = useStoreState(state => state.production.income)
    let costs = objectCostData ? Object.values(objectCostData) : []

    income = income.filter(r => r.apply_general_cost === 1 && moment(r.production_date) >= daterange[0] && moment(r.production_date) <= daterange[1])
    costs = costs.filter(r => moment(r.cost_date) >= daterange[0] && moment(r.cost_date) <= daterange[1])

    let tableData = []
    let dates = []

    // creating tables list
    costs.forEach((cost) => {
        if (!dates.includes(cost.cost_date) && moment(cost.cost_date).format('YYYY-MM-DD') !== "1900-01-01") {
            dates.push(cost.cost_date)
        }
    })

    income.forEach((income) => {
        if (!dates.includes(income.production_date)) {
            dates.push(income.production_date)
        }
    })

    dates = dates.sort((a, b) => moment(a).unix() - moment(b).unix())

    // joining costs and incomes
    dates.forEach((date) => {

        let tons = income.reduce((sum, row) => row.production_date === date ? sum + row.amount : sum, 0)

        let costReportRow = {
            date: date,
            costs: costs.reduce((sum, row) => row.cost_date === date ? sum + row.row_sum : sum, 0),
            income: income.reduce((sum, row) => row.production_date === date ? sum + row.row_sum : sum, 0),
            result: income.reduce((sum, row) => row.production_date === date ? sum + row.row_sum : sum, 0) -
                costs.reduce((sum, row) => row.cost_date === date ? sum + row.row_sum : sum, 0),
            internalProduced: income.reduce((sum, row) => row.production_date === date && row.client_group === "Sisemüük" ? sum + row.amount : sum, 0),
            externalProduced: income.reduce((sum, row) => row.production_date === date && row.client_group === "Välismüük" ? sum + row.amount : sum, 0),
            totalProduced: income.reduce((sum, row) => row.production_date === date ? sum + row.amount : sum, 0),

            fuelPerTon: tons > 0 ? costs.reduce((sum, row) => row.cost_date === date && row.res_name2 === 'Kütus' ? sum + row.row_sum : sum, 0) / tons : 0,
            rockPerTon: tons > 0 ? costs.reduce((sum, row) => row.cost_date === date && row.res_name2 === 'Täitematerjal' ? sum + row.row_sum : sum, 0) / tons : 0,
            gluePerTon: tons > 0 ? costs.reduce((sum, row) => row.cost_date === date && row.res_name2 === 'Sideaine' ? sum + row.row_sum : sum, 0) / tons : 0,
            labourPerTon: tons > 0 ? costs.reduce((sum, row) => row.cost_date === date && [1, 8].includes(row.cost_type) ? sum + row.row_sum : sum, 0) / tons : 0,
            otherPerTon: tons > 0 ? costs.reduce((sum, row) => row.cost_date === date && row.cost_type === 6 ? sum + row.row_sum : sum, 0) / tons : 0,
        }
        tableData.push(costReportRow)
    })


    console.log("tableData", dates, tableData)

    //const handleNavToDay = (day) => {
    //
    //    setObjectActiveDate(moment(day))
    //    setActiveTab("2")
    //
    //}

    const chartData = {

        production: {
            labels: dates.map(v => moment(v).format("DD.MM.YY")),
            datasets: [
                {
                    label: 'Kulud',
                    backgroundColor: '#ffccc7',
                    borderColor: '#ff7875',
                    borderWidth: 1,
                    hoverBackgroundColor: '#ff7875',
                    hoverBorderColor: '#f5222d',
                    hidden: true,
                    data: tableData.map(v => v.costs.toFixed(0))
                }, {
                    label: 'Tulud',
                    backgroundColor: '#d9f7be',
                    borderColor: '#95de64',
                    borderWidth: 1,
                    hoverBackgroundColor: '#95de64',
                    hoverBorderColor: '#52c41a',
                    hidden: true,
                    data: tableData.map(v => v.income.toFixed(0))
                }, {
                    label: 'Tulem',
                    backgroundColor: '#f7f5c7',
                    borderColor: '#bab578',
                    borderWidth: 1,
                    hoverBackgroundColor: '#95de64',
                    hoverBorderColor: '#52c41a',
                    data: tableData.map(v => v.result.toFixed(0))
                }, {
                    label: 'Toodang',
                    backgroundColor: '#867e8f',
                    borderColor: '#383540',
                    borderWidth: 1,
                    hoverBackgroundColor: '#95de64',
                    hoverBorderColor: '#52c41a',
                    data: tableData.map(v => v.totalProduced.toFixed(0))
                }, {
                    label: 'Sisetoodang',
                    backgroundColor: '#514a8f',
                    borderColor: '#383540',
                    borderWidth: 1,
                    hoverBackgroundColor: '#93a1de',
                    hoverBorderColor: '#8471c4',
                    data: tableData.map(v => v.internalProduced.toFixed(0))
                }, {
                    label: 'Välistoodang',
                    backgroundColor: '#8f5057',
                    borderColor: '#a23844',
                    borderWidth: 1,
                    hoverBackgroundColor: '#de2d40',
                    hoverBorderColor: '#c44d17',
                    data: tableData.map(v => v.externalProduced.toFixed(0))
                }
            ]
        },
        kpi: {
            labels: dates.map(v => moment(v)),
            datasets: [
                {
                    label: 'Kütus / T',
                    backgroundColor: '#ffccc7',
                    borderColor: '#ff7875',
                    borderWidth: 1,
                    hoverBackgroundColor: '#ff7875',
                    hoverBorderColor: '#f5222d',
                    fill: false,
                    data: tableData.map(v => v.fuelPerTon.toFixed(2))
                }, {
                    label: 'Sideaine / T',
                    backgroundColor: '#d9f7be',
                    borderColor: '#95de64',
                    borderWidth: 1,
                    hoverBackgroundColor: '#95de64',
                    hoverBorderColor: '#52c41a',
                    data: tableData.map(v => v.gluePerTon.toFixed(2))
                }, {
                    label: 'Täitematerjal / T',
                    backgroundColor: '#f7f5c7',
                    borderColor: '#bab578',
                    borderWidth: 1,
                    hoverBackgroundColor: '#95de64',
                    hoverBorderColor: '#52c41a',
                    data: tableData.map(v => v.rockPerTon.toFixed(2))
                }, {
                    label: 'Tööjõukulu',
                    backgroundColor: '#867e8f',
                    borderColor: '#383540',
                    borderWidth: 1,
                    hoverBackgroundColor: '#b6c2ec',
                    hoverBorderColor: '#3534c4',
                    data: tableData.map(v => v.labourPerTon.toFixed(2))
                }, {
                    label: 'Muud kulud',
                    backgroundColor: '#8f1d25',
                    borderColor: '#8f1d25',
                    borderWidth: 1,
                    hoverBackgroundColor: '#95de64',
                    hoverBorderColor: '#52c41a',
                    data: tableData.map(v => v.otherPerTon.toFixed(2))
                }
            ]
        },
    };

    return <div>
        <Row gutter={16}>
            <Col span={24}>
                <div>
                    {focus === 'production' ?
                        <Bar
                            data={chartData['production']}
                            //width={'100%'}
                            redraw={true}
                            height={120}
                            //options={{maintainAspectRatio: false}}
                        />
                        :
                        <Line
                            data={chartData['kpi']}
                            redraw={true}
                            //width={'100%'}
                            height={120}
                            //options={{maintainAspectRatio: false}}
                            options={{
                                scales: {
                                    xAxes: [{
                                        type: 'time',
                                        time: {
                                            unit: 'day',
                                            distribution: 'series',
                                            displayFormats: {
                                                day: 'DD.MM'
                                            }
                                        }
                                    }]
                                }
                            }}
                        />
                    }
                </div>
            </Col>
                </Row>
    </div>
}

export const PaObjectOverviewProduction = ({setActiveTab}) => {

    return <div style={pageDivStyle}>
        <StatisticsBlockProduction setActiveTab={setActiveTab}/>
    </div>
}

