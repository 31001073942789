import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { UploadOutlined } from '@ant-design/icons'
import { Upload, notification } from 'antd'
import { s3, getS3FileURL } from '../../../utils/aws'
import { v4 as uuid } from 'uuid'
import axios from 'axios'

import '../../../App.css'

import { useStoreActions, useStoreState } from 'easy-peasy'
import API, { URL as PaURL } from '../../../services/api'

const { Dragger } = Upload

export const UploadReport = ({ reportId, userId }) => {
  const [fileNamePut, setFileNamePut] = useState()

  const props = {
    name: 'file',
    multiple: false,
    action: `${PaURL()}/api/asphalt`,
    data: {
      field: 'filename',
      value: fileNamePut,
      id: reportId,
      type: 'string',
      iduser: userId
    },
    method: 'put',
    beforeUpload: async (file) => {
      const uploadUuid = uuid()
      const fileName = file.name
      setFileNamePut(`/reports/${uploadUuid}-${fileName}`)
      console.log(fileNamePut)
      const presignedS3Url = s3.getSignedUrl('putObject', {
        Bucket: 'fieldworks-uploads',
        Key: `reports/${uploadUuid}-${fileName}`,
        ContentType: file.type
      })
      const { pathname } = new URL(presignedS3Url)
      const res = await axios.put(presignedS3Url, file, {
        headers: {
          'Content-Type': file.type
        }
      })
      if (res) {
        console.log({ res, pathname })
        await API('/api/aws/asphalt', false, 'post', { idreport: reportId, name: fileName, path: pathname })
      }
      return true
    },
    headers: {
      Authorization: 'Bearer ' + Cookies.get('jwt')
    },
    onChange(info) {
      console.log('info:', info)
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        //message.success(`${info.file.name} file uploaded successfully.`);
        notification.success({
          placement: 'topRight',
          message: `${info.file.name} edukalt üles laetud ja töödeldud.`,
          duration: 15
        })
      } else if (status === 'error') {
        //message.error(`${info.file.name} file upload failed.`);
        //alert(`${info.file.name} üles laadimine ei õnnestunud. Kas on sama nimega fail juba üles laetud või on excel vigane.`);
        console.log('file', info.file)
        notification.error({
          placement: 'topRight',
          message: (
            <div>
              {info.file.name} üles laadimine ei õnnestunud. {info.file.response ? info.file.response : ''}{' '}
            </div>
          ),
          duration: 0
        })
      }
    }
  }

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <UploadOutlined />
      </p>
      <p className="ant-upload-text">
        Kliki <b>siia</b>
        <br /> või lohista aruanne üles laadimiseks sellesse kasti
      </p>
      <p className="ant-upload-hint">Uue faili üles laadimine kirjutab eelneva üle!</p>
    </Dragger>
  )
}
