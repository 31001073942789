import React, { useEffect, useState } from "react"
import PageWithHeader from '../components/PageWithHeader'
import { useHistory, useParams } from 'react-router'

import {
  DeleteOutlined,
  EditOutlined,
  EyeTwoTone,
  MoreOutlined,
  PlayCircleTwoTone,
  PlusCircleOutlined,
  TableOutlined,
} from '@ant-design/icons'

import {
  Table,
  Card,
  Input,
  Menu,
  Tooltip,
  Select,
  Dropdown,
  Radio,
  Tag,
  Empty,
  Button,
} from "antd"

import '../App.css'

import { useStoreActions, useStoreState } from "easy-peasy"
import { smartSearch } from "../services/smartSearch"
import InputCell from "../components/InputCell"
import { PaCostBudgetPerOffer } from "../components/object/overview/ObjectOverview"

const { Option } = Select

export const PaOffer = ({ target = 'target' }) => {

  const [search, setSearch] = useState('')
  const searchColumns = ['art_id', 'art_name', 'unit', 'unit_price']
  const [activeTab, setActiveTab] = useState(target)
  const [editState, setEditState] = useState(true)
  const fetchOffer = useStoreActions(actions => actions.offer.apiLoadOffer)
  const offer_raw = useStoreState(state => state.offer.offer)
  const setOfferTargetStatus = useStoreActions(actions => actions.offers.apiSetOfferTargetStatus)

  const [loading, setLoading] = useState(true)

  const history = useHistory()

  async function handleOfferTargetStatusChange(offer_nr) {

    await setOfferTargetStatus({
      status: 1,
      offer_nr: offer_nr
    })

    fetchOffer(offer_nr)
  }

  let { offer_nr } = useParams()

  console.log('offer nr', offer_nr)

  let isTarget = 0
  let costing_version = 1
  let is_small_object = 0
  if (offer_raw) {
    isTarget = Object.values(offer_raw).length > 0 ? Object.values(offer_raw)[0].is_target_offer : 0
    is_small_object = Object.values(offer_raw).length > 0 ? Object.values(offer_raw)[0].is_small_object : 0
    costing_version = Object.values(offer_raw).length > 0 ? Object.values(offer_raw)[0].costing_version === 2 ? 2 : 1 : 1
  }

  console.log("istarget", isTarget)

  let tabList = [
    {
      key: 'target',
      tab: 'Eelarve',
    },
    {
      key: 'costs',
      tab: 'Billid',
    },
  ]

  let offer = offer_raw ? Object.values(offer_raw) : []


  if (offer[0] && offer[0].costing_version === 2) tabList = [
    {
      key: 'target',
      tab: 'Eelarve',
    }]


  const contentList = {
    target: <PaOfferTable
      offer={smartSearch(offer, search, searchColumns)}
      offer_nr={offer_nr}
      loading={loading}
      costing_version={costing_version}
      is_small_object={is_small_object}
    />,
    costs: isTarget === 1 ?
      <PaCostBudgetPerOffer offer_nr={offer_nr} size="middle" content={editState} />
      :
      <Empty
        //image="https://gw.alipayobjects.com/"
        imageStyle={{
          height: 60,
        }}
        description={
          <span>
            Kulude loend on ainult eesmärk-eelarvel
                    </span>
        }
      >
        <Button type="primary" onClick={() => handleOfferTargetStatusChange(offer_nr)}>Tee
                    eesmärk-eelarveks</Button>
      </Empty>
  }


  useEffect(() => {
    if (offer_nr) fetchOffer(offer_nr).then(() => setLoading(false))
  }, [offer_nr])


  const title = <div className="ml-3 mr-3">
    <Button style={{ marginRight: 10 }} onClick={(e) => {
      history.goBack()
    }}>{'< Tagasi'}
    </Button>
    {offer_nr}
    {isTarget === 1 ?
      <Tag className="ml-2" color="geekblue"> Eesmärk eelarve </Tag>
      :
      <Tag className="ml-2" color="green"> Lisaeelarve </Tag>} </div>

  return (
    <PageWithHeader>
      <div className="m-2 p-2">
        <Card
          title={title}
          tabList={tabList}
          activeTabKey={activeTab}
          onTabChange={key => setActiveTab(key)}
          extra={<div className="d-flex">
            {activeTab === 'costs' ?
              <div className="mr-2">
                <Radio.Group
                  value={editState}
                  onChange={v => setEditState(v.target.value)}
                >
                  <Radio.Button value={true}><EditOutlined /></Radio.Button>
                  <Radio.Button value={false}><TableOutlined /></Radio.Button>
                </Radio.Group>
              </div>
              :
              null
            }

            <div>
              {activeTab === "target" ?
                <Input
                  placeholder="Otsi..."
                  className="mr-2"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                : null
              }
            </div>
          </div>}
        >
          <div className="m-2" style={{
            overflowX: "auto",
            background: "#fff"
          }}>
            {contentList[activeTab]}
          </div>
        </Card>
      </div>
    </PageWithHeader>
  )
}

function getCellReference(index, field) {
  return index.toString() + field
}


export const PaOfferTable = ({ offer_nr, offer, loading = false, costing_version = 2, is_small_object = false }) => {

  const [activeCell, setActiveCell] = useState(false)

  const bills = useStoreState(state => state.bills.bills)
  const delRow = useStoreActions(actions => actions.offer.apiDelRowOffer)
  const addRow = useStoreActions(actions => actions.offer.apiAddRowOffer)
  const updateRow = useStoreActions(actions => actions.offer.apiUpdateOffer)

  // determine costing version


  const handleRowAdd = async (record) => {
    await addRow({
      offer_nr: offer_nr,
      sortorder: record.sortorder,
      idoffer: record.idoffer
    })
  }

  let lepinguline_maht = 0
  let akteeritav_maht = 0
  let kulu_maht = 0


  const handleRowDelete = async (idoffer) => await delRow(idoffer)
  const handleRowUpdate = async (payload) => await updateRow(payload)
  const handleRowMarkSpecial = async (record) => {
    await updateRow({
      value: 1,
      field: "is_special_bill_row",
      idoffer: record['idoffer'],
    })

  }

  async function handleBillChange(billId, billPath, record) {

    console.log("value, option", billId)
    await updateRow({
      value: billId,
      field: "has_bill_type",
      idoffer: record['idoffer'],
    })
    await updateRow({
      value: billPath,
      field: "bill_path",
      idoffer: record['idoffer'],
    })
    setActiveCell(false)
  }

  async function handleChange(value, field, index, record) {

    //console.log("handling change...", value, field, index, record['idoffer'])
    // if either previous value or new value exist
    if (record[field] || value) {

      let newValueString = value ? value.toString() : false
      let oldValueString = record[field] ? record[field].toString() : false

      if (newValueString !== oldValueString) {
        console.log("ONBLUR/onchange/change detected, updating...")
        record[field] = value
        let payload = {
          value: value,
          field: field,
          idoffer: record['idoffer'],
        }

        setActiveCell(false)
        await handleRowUpdate(payload)


      } else setActiveCell(false)
    } else setActiveCell(false)
  }

  const getBillSelect = (index, valueField, titleField, rowsCount, record) => {

    if (getCellReference(index, titleField) === activeCell) return <Select
      size={70}
      dropdownMatchSelectWidth={false}
      //selectProps={selectProps}
      //onClick={() => setActiveCell(getCellReference(index, titleField))}
      //onUpdate={value => handleBillChange(value, valueField, index, record)}
      onChange={(value, option) => handleBillChange(value, bills.flat.find(bill => bill.id === value).path, record)}
      onBlur={(value, option) => handleBillChange(value, bills.flat.find(bill => bill.id === value).path, record)}
      //onMoveLeft={(value) => setActiveCell(getCellReference(index, titleField, -1, false))}
      //onMoveRight={(value) => setActiveCell(getCellReference(index, titleField, 1, false))}
      //active={activeCell === getCellReference(index, titleField)}
      defaultValue={record[valueField]}
    //defaultTitle={record[titleField]}
    >
      {bills ? bills.flat.map(bill => <Option key={bill.id}
        value={bill.id}> {bill.number} {bill.name}</Option>) : null}
    </Select>

    return <div onClick={() => setActiveCell(getCellReference(index, titleField))}> {record[titleField] ? record[titleField] : <EditOutlined/>} </div>

  }


  const getInputCell = (index, field, rowsCount, record, round = 0) => <InputCell
    type="input"
    onClick={() => setActiveCell(getCellReference(index, field))}
    onBlur={value => handleChange(value, field, index, record)}
    onUpdate={value => handleChange(value, field, index, record)}
    onMoveUp={(value) => setActiveCell(getCellReference(index > 0 ? index + value : 0, field))}
    onMoveDown={(value) => setActiveCell(getCellReference(index < rowsCount - 1 ? index + value : rowsCount - 1, field))}
    active={activeCell === getCellReference(index, field)}
    defaultValue={typeof record[field] === 'number' && round > 0 ? record[field].toFixed(round) : record[field]} />

  let rows = 0
  rows = offer.length

  let columns = [
    //{title: "#", dataIndex: "idoffer", key: "idoffer",},
    //{title: "#", dataIndex: "sortorder", key: "sortorder",},
    {
      title: <Tooltip title="Rida on eraldi jälgitav rida">
        <div><EyeTwoTone twoToneColor="#52c41a" /></div>
      </Tooltip>, dataIndex: "is_special_bill_row", key: "is_special_bill_row", width: 30,
      render: (text, record, index) => text ? <EyeTwoTone twoToneColor="#52c41a" /> : null
    },

    //{ title: 'cv', dataIndex: 'art_name', key: 'art_name', render: (text, record, index) => costing_version},
    {
      title: <Tooltip title="Rida on juba päevaaruandes täidetud, ei saa kustutada">
        <div><PlayCircleTwoTone /></div>
      </Tooltip>, dataIndex: "fulfilled", key: "fulfilled", width: 30, v2: false,
      render: (text, record, index) => text ? <PlayCircleTwoTone /> : null
    },
    {
      title: "Billi nr", dataIndex: "has_bill_type", key: "has_bill_type", v2: true, showIfSmallObject: true,
      render: (text, record, index) => costing_version === 1 ?
        getInputCell(index, 'has_bill_type', rows, record)
        :
        getBillSelect(index, 'has_bill_type', 'bill_path', rows, record)
    },
    {
      title: "Art", dataIndex: "art_id", key: "art_id", v2: true, showIfSmallObject: true,
      render: (text, record, index) => getInputCell(index, 'art_id', rows, record)
    },

    {
      title: "Nimetus", dataIndex: "art_name", key: "art_name", v2: true, showIfSmallObject: true,
      render: (text, record, index) => getInputCell(index, 'art_name', rows, record)
    },
    {
      title: "Ühik", dataIndex: "unit", key: "unit", v2: true, showIfSmallObject: true,
      render: (text, record, index) => getInputCell(index, 'unit', rows, record)
    },
    {
      title: 'Leping', v2: true, showIfSmallObject: false,
      children: [
        {
          title: "Ühikuid", dataIndex: "esitamiseks_amount", key: "esitamiseks_amount", align: "right",
          className: 'projection_actual',
          render: (text, record, index) => getInputCell(index, 'esitamiseks_amount', rows, record, 2)
        },
        {
          title: "Ühikhind",
          dataIndex: "esitamiseks_unit_price",
          key: "esitamiseks_unit_price",
          align: "right",
          className: 'projection_actual',
          render: (text, record, index) => getInputCell(index, 'esitamiseks_unit_price', rows, record, 2)
        },
        {
          title: "Summa", dataIndex: "amount", key: "esitamiseks_summa", align: "right",
          className: 'projection_actual',
          render: (text, record, index) => record.esitamiseks_amount && record.esitamiseks_unit_price ? (record.esitamiseks_unit_price * record.esitamiseks_amount).toFixed(2) : ''
        },
      ]
    },

    {
      title: "Akteeritavaid ühikuid", dataIndex: "akt_amount", key: "akt_amount", align: "right", v2: false,
      render: (text, record, index) => getInputCell(index, 'akt_amount', rows, record, 2)
    },
    {
      title: "Akteeritav ühikhind", dataIndex: "akt_unit_price", key: "akt_unit_price", align: "right", v2: false,
      render: (text, record, index) => getInputCell(index, 'akt_unit_price', rows, record, 2)
    },
    {
      title: "Akteeritav summa", dataIndex: "amount", key: "akt_summat", align: "right", v2: false,
      render: (text, record, index) => record.akt_amount && record.akt_unit_price ? (record.akt_unit_price * record.akt_amount).toFixed(2) : ''
    },
    {
      title: is_small_object ? 'Kokkulepe / leping / pakkumine' : 'Eesmärk',
      v2: true, showIfSmallObject: true,
      children: [
        {
          title: "Ühikuid", dataIndex: "amount", key: "amount", align: "right",
          className: 'projection',
          render: (text, record, index) => getInputCell(index, 'amount', rows, record, 2)
        },
        {
          title: "Ühikhind", dataIndex: "unit_price", key: "unit_price", align: "right",
          className: 'projection',
          render: (text, record, index) => getInputCell(index, 'unit_price', rows, record, 2)
        },
        {
          title: "Summa", dataIndex: "unit_price", key: "sum_cost", align: "right",
          className: 'projection',
          render: (text, record, index) => {
            if (record.unit_price && record.amount) return '€ ' + (record.unit_price * record.amount).toFixed(2)
            else return ''
          }
        }
      ]
    },
    {
      title: "Tulu eesmärk summa",
      dataIndex: "unit_price",
      key: "sum_income",
      align: "right",
      v2: false,
      smallObject: false,
      className: 'projection_actual',
      render: (text, record, index) => {
        if (record.akt_unit_price && record.amount) return (record.akt_unit_price * record.amount).toFixed(2)
        else return ''
      }
    }, {
      title: "Tulem", dataIndex: "unit_price", key: "tulem", align: "right", v2: false, showIfSmallObject: false,
      className: 'projection_actual',
      render: (text, record, index) => {
        if (record.akt_unit_price && record.amount && record.unit_price)
          return ((record.akt_unit_price * record.amount) - (record.unit_price * record.amount)).toFixed(2)
        else return ''
      }
    },

    {
      title: <MoreOutlined />, dataIndex: "obj_id", key: "obj_id", v2: true, showIfSmallObject: true,
      className: 'projection_actual',
      render: (text, record, index) =>
        <Dropdown overlay={rowMenu(record)} trigger={['click']}>

          <MoreOutlined onClick={(e) => e.preventDefault()} />

        </Dropdown>,
    },
  ]

  if (costing_version === 2) {

    columns = columns.filter(r => r.v2 === true)

  }

  if (is_small_object === 1) {

    columns = columns.filter(r => r.showIfSmallObject === true)

  }

  function rowMenu(record) {
    return (
      <Menu>
        <Menu.Item key="0" onClick={() => handleRowAdd(record)}>
          <PlusCircleOutlined /> Lisa rida
                </Menu.Item>
        {record.costing_version === 2 ? null :
          <Menu.Item key="0" onClick={() => handleRowMarkSpecial(record)}>
            <EyeTwoTone twoToneColor="#52c41a" /> Tee eraldi jälgitavaks reaks
                    </Menu.Item>
        }
        <Menu.Divider />
        <Menu.Item
          key="3"
          disabled={record.fulfilled || record.is_special_bill_row ? true : false}
          onClick={() => handleRowDelete(record.idoffer)}><DeleteOutlined /> Kustuta rida
                </Menu.Item>
      </Menu>
    )
  }

  return (
    <Table
      columns={columns}
      dataSource={offer.sort((a, b) => a.sortorder - b.sortorder)}
      //className={"table-striped-rows"}
      size="middle"
      rowKey={"idoffer"}
      loading={loading}
      footer={() => <div className="d-flex justify-content-end">
        <div>
          Lepinguline summa:
                    <b>
            {offer ? offer.reduce((sum, current) => sum + (current.esitamiseks_unit_price * current.esitamiseks_amount), 0).toLocaleString('et-ee', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }) : 0}
          </b>
        </div>
        <div className="ml-2">
          Eesmärk summa:
                    <b>
            {offer ? offer.reduce((sum, current) => sum + (current.unit_price * current.amount), 0).toLocaleString('et-ee', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }) : 0}
          </b>
        </div>
      </div>
      }
      pagination={{
        //defaultPageSize: 5,
        showSizeChanger: true,
        onChange: (page, pageSize) => console.log("page: ", page, "pagesize: ", pageSize),
        onShowSizeChange: (current, size) => console.log("page: ", current, "pagesize: ", size)
      }}
    />
  )
}


export default PaOffer
