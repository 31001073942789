import React from 'react'


const StatusIcon = ({value, ignore=[]}) => {

    let data = null
    if (value) data = value.toString()

    if (data === "1" && !ignore.includes(data)) {
        return <i className="far fa-trash-alt"> </i>
    }
    if (data === "3" && !ignore.includes(data)) {
        return <i className="fas fa-fire" style={{color: 'orange'}}> </i>
    }
    if (data === "4" && !ignore.includes(data)) {
        return <i className="fas fa-star" style={{color: '#d3ab19'}}> </i>
    }
    if (data === "5" && !ignore.includes(data)) {
        return <i className="far fa-check-circle" style={{color: 'green'}}> </i>
    }
    if (data === "6" && !ignore.includes(data)) {
        return <i className="fas fa-lock"> </i>
    }
    if (data === "7" && !ignore.includes(data)) {
        return <i className="fas fa-exclamation-circle" style={{color: 'darkred'}}> </i>
    } else {
        return "";
    }
}



export default StatusIcon