import {action} from "easy-peasy";


function whatsMyMode() {
    let mode = 'production'
    if (window.location.hostname.includes('staging')) mode = "development"
    if (window.location.hostname.includes('localhost')) mode = "development"
    return mode
}

export const siteModeModel = {
    mode: whatsMyMode(),
    paverView: false,
    paverViewIdobject: false,

    setPaverView: action((state, payload) => state.paverView = payload),
    setPaverViewIdobject: action((state, payload) => state.paverViewIdobject = payload),
}
