//import {Button, ButtonGroup} from "@blueprintjs/core";
import React, {useEffect, useState} from "react";

import PageWithHeader from '../components/PageWithHeader'

import { DeleteOutlined, DoubleRightOutlined } from '@ant-design/icons';

import { Button, Input, Card, Typography } from "antd";
import '../App.css';
import moment from "moment"
import {useHistory, withRouter, Redirect} from "react-router";
import {useStoreActions, useStoreState} from "easy-peasy";

import PivotTableUI from 'react-pivottable/PivotTableUI';
import PivotTable from 'react-pivottable/PivotTable';

const {Search} = Input;
const {Title} = Typography;
const ButtonGroup = Button.Group;

const PaAnalysisWorksheets = ({isTableCompact = false}) => {

    const dataOfInterest = useStoreState(state => state.analysis.worksheets)
    const [state, setState] = useState()
    //console.log("state", state)

    return (
        <div style={{overflowY: 'scroll', height: '90vh'}}>
            {isTableCompact ?
                <PivotTable data={dataOfInterest}
                            //vals={["Summa (akteeritavas hinnas)"]}
                            //rows={["Objekti nimi2", "Nimi"]}
                            //cols={["Tulu kuu", "Tulu kuupäev"]}
                            aggregatorName="Sum"
                            //unusedAttrsVertical={false}
                            //hiddenAttributes={["verston_id", "Shiffer", "Pakkumise kuupäev"]}
                    //derivedAttributes={{"Kulu kuu": dateFormat("Kulu kuupäev", "%m")}}
                            onChange={state => {
                                delete state.data
                                setState(state)
                            }}
                            {...state}
                />
                :
                <PivotTableUI data={dataOfInterest}
                              //vals={["Summa (akteeritavas hinnas)"]}
                              //rows={["Objekti nimi2", "Nimi"]}
                              //cols={["Tulu kuu", "Tulu kuupäev"]}
                              aggregatorName="Sum"
                              //unusedAttrsVertical={false}
                              //hiddenAttributes={["verston_id", "Shiffer", "Pakkumise kuupäev"]}
                    //derivedAttributes={{"Kulu kuu": dateFormat("Kulu kuupäev", "%m")}}
                              onChange={state => {
                                  delete state.data
                                  setState(state)
                              }}
                              {...state}
                />

            }

        </div>
    )
}

const PaWorksheets = () => {

    const bills = useStoreState(state => state.bills.bills);
    const loading = useStoreState(state => state.bills.loading);
    const setLoading = useStoreActions(actions => actions.bills.setLoading);
    const loadBills = useStoreActions(actions => actions.bills.apiFetchBills)
    const updateBills = useStoreActions(actions => actions.bills.apiUpdateBills)
    const addBills = useStoreActions(actions => actions.bills.apiAddBills)

    const [activeCell, setActiveCell] =useState(false)
    const editAllowed = true

    const fetchWorksheets = useStoreActions(actions => actions.analysis.apiFetchWorksheets)
    const worksheets = useStoreActions(state => state.analysis.worksheets)

    let history = useHistory()

    useEffect(() => {
        fetchWorksheets()
        // eslint-disable-next-line
    }, [])

    return (
        <PageWithHeader>

            <div className="m-2">
                <Card title={<div className="ml-2">Töölehed</div>}
                      bordered={false}
                      extra={<Button onClick={() => fetchWorksheets()}>Värskenda</Button>}      // todo: new base bill add logic
                >
                    <div className="m-1" style={{
                        "overflowX": "auto",
                        "background": "#fff"
                    }}>
                        <PaAnalysisWorksheets/>
                    </div>
                </Card>
            </div>
        </PageWithHeader>
    )
}


export default withRouter(PaWorksheets)
