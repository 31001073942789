import {useContext, useEffect, useState} from "react";
import CostOptionsContext from "../../../CostOptionsContext";
import {useStoreActions, useStoreState} from "easy-peasy";
import InputCell from "../../../InputCell";
import {Button, Input, Modal, Table} from "antd";
import React from "react";
import {smartSearch} from "../../../../services/smartSearch";

const {Search} = Input;


const PaWhResModal = ({isOpen, onClose}) => {

    const {formOptions, setFormOptions} = useContext(CostOptionsContext)
    const res = useStoreState(state => state.warehouse.resources);
    const fetchRes = useStoreActions(actions => actions.warehouse.apiFetchResources);
    const updateRes = useStoreActions(actions => actions.warehouse.apiUpdateResources);
    const deleteRes = useStoreActions(actions => actions.warehouse.apiDeleteResources);
    const addRes = useStoreActions(actions => actions.warehouse.apiAddResources);

    const [search, setSearch] = useState('')
    const [activeCell, setActiveCell] = useState(false)

    function getCellReference(index, field) {
        return index.toString() + field
    }

    const getInputCell = (index, field, rowsCount, record) => <InputCell
        type="input"
        onClick={() => setActiveCell(getCellReference(index, field))}
        onBlur={value => handleChange(value, field, index, record)}
        onUpdate={value => handleChange(value, field, index, record)}
        onMoveUp={(value) => null}
        onMoveDown={(value) => null}
        active={activeCell === getCellReference(index, field)}
        defaultValue={record[field]}/>

    const getSelectCell = (index, valueField, titleField, rowsCount, selectProps, record) => <InputCell
        type="select"
        selectProps={selectProps}
        onClick={() => setActiveCell(getCellReference(index, titleField))}
        onUpdate={value => handleChange(value, valueField, index, record)}
        active={activeCell === getCellReference(index, titleField)}
        defaultValue={record[valueField]}
        defaultTitle={record[titleField]}/>

    const columns = [
        //{
        //    title: "id", dataIndex: "idresources", key: "idresources",
        //},

        {
            title: "Materjal", dataIndex: "res_name1", key: "res_name1",
            render: (text, record, index) => getInputCell(index, 'res_name1', 0, record)
        },
        {
            title: "Grupp", dataIndex: "res_name2", key: "res_name2",
            render: (text, record, index) => getSelectCell(index, 'res_name2', 'res_name2', 0, {
                optionsList: [
                    {value: "Täitematerjal", title: "Täitematerjal"},
                    {value: "Kütus", title: "Kütus"},
                    {value: "Sideaine", title: "Sideaine"},
                    {value: "Muu materjal", title: "Muu materjal"},
                ],
                valueField: "value",
                titleField: "title"
            }, record)
        },
        {
            title: "Karjäär", dataIndex: "res_property_factory", key: "res_property_factory",
            render: (text, record, index) => getInputCell(index, 'res_property_factory', 0, record)
        },
        {
            title: "Tarnija", dataIndex: "res_property_org", key: "res_property_org",
            render: (text, record, index) => getSelectCell(index, 'res_property_org', 'res_property_orgname', 0, {
                optionsList: formOptions.companies_list,
                valueField: "company",
                titleField: "org_name"
            }, record)

        },
        {
            title: "Ident", dataIndex: "res_property_ident1", key: "res_property_ident1",
            render: (text, record, index) => getInputCell(index, 'res_property_ident1', 0, record)
        },
        {
            title: "Kood", dataIndex: "res_property_ident2", key: "res_property_ident2",
            render: (text, record, index) => getInputCell(index, 'res_property_ident2', 0, record)
        },
        {
            title: "Ühik", dataIndex: "res_unit", key: "res_unit",
            render: (text, record, index) => getInputCell(index, 'res_unit', 0, record)
        },
        {
            title: <i className="far fa-trash-alt" style={{color: 'red'}}></i>,
            dataIndex: "idresources",
            key: "idresources_x",
            render: function (text, record, index) {
                return (
                    <i className="far fa-trash-alt" onClick={() => handleDelete(record)} style={{color: 'red'}}></i>)

            }
        }
    ]

    const searchColumns = ["res_name1", "res_name2", "res_property_factory", "res_property_orgname", "res_property_ident1", "res_property_ident2", "res_unit"]


    function handleChange(value, field, index, record) {
        console.log("whres: ", value, field, index, record)

        if (record[field] || value) {

            let newValueString = value ? value.toString() : false
            let oldValueString = record[field] ? record[field].toString() : false

            if (newValueString !== oldValueString) {

                console.log("ONBLUR/onchange/change detected, updating...")
                let payload = {
                    idresources: record.idresources,
                    field: field,
                    value: value
                }
                updateRes(payload).then(() => {
                    console.log("Setting cell false")
                    setActiveCell(false)
                })
            } else setActiveCell(false)
        } else setActiveCell(false)
    }

    function handleDelete(record) {
        console.log("whres_delete: ", record)
        deleteRes({idresources: record.idresources})
    }

    function handleAdd() {
        console.log("whres_add: ")
        addRes()
    }

    useEffect(() => {
        fetchRes()
    }, [])

    return (
        <Modal
            visible={isOpen}
            //icon="info-sign"
            onCancel={onClose}
            onOk={onClose}
            title={"Laomaterjalid"}
            width={"70%"}
            destroyOnClose={true}
            okText={'OK'}
            cancelText={'Tühista'}
        >
            <div className="d-flex mb-2">
                <Search
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className="mr-2"
                    placeholder="Otsi..."
                />
                <Button onClick={() => handleAdd()}>Lisa</Button>
            </div>
            <Table dataSource={smartSearch(res, search, searchColumns)} columns={columns}/>
        </Modal>
    )
}

export default PaWhResModal