import './ReactotronConfig'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from '../../../uuspa3/frontend/src/App'
import * as serviceWorker from './serviceWorker'
import { ConfigProvider } from 'antd'
import ant_et_EE from 'antd/lib/locale/et_EE'
import ant_en from 'antd/lib/locale/en_US'


import { StoreProvider } from 'easy-peasy'
import store from './components/globalStore/GlobalStore'

import moment from 'moment'
import 'moment/locale/en-gb'  // important!
import 'moment/locale/et'  // important!

import '@ant-design/pro-table/dist/table.css'

import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

moment.locale('et')

if (!window.location.hostname.includes('localhost')) {
  console.log('loading logrocket')
  LogRocket.init('n9skgs/pa-ipav6', {
    release: window.location.hostname.includes('staging') ? 'staging' : (window.location.hostname.includes('demo') ? 'demo' : 'production'),
    network: {
      isEnabled: true,
    },
  })
  setupLogRocketReact(LogRocket)
}

ReactDOM.render(
  <ConfigProvider locale={ant_en}>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </ConfigProvider>
  , document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()


