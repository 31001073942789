import React, { useEffect, useState } from 'react'

import { withRouter, useLocation, useHistory } from 'react-router'
import useWindowSize from '../services/useWindowSize'

import gravatar from 'gravatar'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Layout, Menu, Avatar, Dropdown, Alert, Affix } from "antd"
import { useStoreActions, useStoreState } from "easy-peasy"
import Logo from '../pics/logo.png'
import ObjectNavigator from './ObjectNavigator'
import UserProfile from "../pages/userProfile"

import { mainMenu } from './routing'

import moment from 'moment'

const { Header, Content, Sider } = Layout

//import {LinkComponent} from './tables/utils'

let accountDropdownProps = {
  avatarURL: '/images/logo.png',
  name: '',
  description: 'Administrator',
  options: [
    // { icon: 'user', value: 'Profile', to: '/profile' },
    // { icon: 'settings', value: 'Settings' },
    // { icon: 'mail', value: 'Inbox', badge: '6' },
    // { icon: 'send', value: 'Message' },
    // { isDivider: true },
    // { icon: 'help-circle', value: 'Need help?' },
    { icon: 'log-out', value: 'Sign out', to: '/logout' },
  ],
  //optionsRootComponent: LinkComponent,
}

export default withRouter(({ children }) => {

  const user = useStoreState(state => state.user.me)
  const mode = useStoreState(state => state.site.mode)
  //const paverView = useStoreState(state => state.site.paverView);
  //const setPaverViewIdobject = useStoreActions(actions => actions.site.setPaverViewIdobject);

  const viewPortDimensions = useWindowSize()

  const fullScreen = useStoreState(state => state.layout.fullScreenComponent)
  const logout = useStoreActions(actions => actions.user.apiLogout)

  if (user && user.id) {
    accountDropdownProps.name = `${user.name} ${user.surname}`
    accountDropdownProps.email = `${user.username}`

    accountDropdownProps.description = `${user.position_name}`
    accountDropdownProps.avatarURL = gravatar.url(user.username, { d: '404' })
  }

  //console.log("mode:", mode, accountDropdownProps)

  const [collapsed, setCollapsed] = useState(true)
  const [userProfileOpen, setUserProfileOpen] = useState(false)
  const [userProfileClosable, setUserProfileClosable] = useState(true)

  useEffect(() => {
    if (user && user.password_expired === true) {
      setUserProfileOpen(true)
      setUserProfileClosable(false)
    }
  }, [user])



  let history = useHistory()
  const location = useLocation()

  let selected = [location.pathname]

  const handleMenuClick = (e) => history.push(e.item.props.to)

  function onObjectSelect(value) {

    //  if (selected[0].includes('livepaver')) {
    //      window.location = '/livepaver/' + value
    //  }
    //  else {
    console.log(value)
    window.location = '/object/' + value
    //  }


  }

  if (fullScreen) return fullScreen

  return (
    viewPortDimensions.width < 600 ?
      <div>
        <Affix offsetTop={0}>
          <div style={{
            background: '#fff',
            padding: '15px 15px 15px 15px',
            boxShadow: "0 1px 4px 0 rgba(0, 21, 41, 0.12)",
          }}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Dropdown
                  overlay={
                    <Menu
                      theme="dark"
                      mode="inline"
                      defaultSelectedKeys={['1']}
                      selectedKeys={selected}
                      onClick={handleMenuClick}
                      style={{ width: '250px', padding: '15px 15px 15px 15px' }}
                    >
                      {mainMenu
                        .map(r =>
                          r.requiresRole
                            .includes(user.position) || r.allowed_If_InGroup
                            .includes(user.group)
                            ?
                            r.menuContent
                            :
                            null)
                      }
                    </Menu>
                  }
                  placement="bottomLeft"
                  trigger={['click']}
                >
                  <LegacyIcon
                    className="trigger"
                    type={collapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                      fontSize: "18px",
                      //"line-height": "50px",
                      "cursor": "pointer",
                      "transition": "color 0.3s"
                    }}
                  />
                </Dropdown>

              </div>
              <div>
                <ObjectNavigator
                  onSelect={onObjectSelect}
                  statuses={[3, 4]}
                  style={{ width: viewPortDimensions.width < 600 ? viewPortDimensions.width / 2 : 300 }}
                />
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item disabled>
                        <div>
                          <small
                            className="text-muted">{user.position_name}<br /> {user.username}
                          </small>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div onClick={() => logout()}>
                          Muuda parool
                        </div>
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item>
                        <div onClick={() => logout()}>
                          Logi välja
                        </div>
                      </Menu.Item>

                    </Menu>
                  }

                  placement="bottomRight">
                  <Avatar
                    style={{ backgroundColor: '#87d068' }}
                    src={"https:" + accountDropdownProps.avatarURL}
                  >
                    {user && `${user.name} ${user.surname}`}
                  </Avatar>

                </Dropdown>
              </div>
            </div>
          </div>
        </Affix>
        {children}
      </div>
      :
      <Layout
      //style={{ height: '100vh' }}
      >
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="mt-2"><img alt="Logo" src={Logo}
            style={{ width: 25, height: 25, display: 'block', margin: 'auto' }} />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['1']}
            selectedKeys={selected}
            onClick={handleMenuClick}
          >
            {mainMenu
              .map(r =>
                r.requiresRole
                  .includes(user.position) || r.allowed_If_InGroup
                  .includes(user.group)
                  ?
                  r.menuContent
                  :
                  null)
            }
          </Menu>
        </Sider>
        <Layout>
          <Header style={{
            background: '#fff',
            padding: '0 24px',
            boxShadow: "0 1px 4px 0 rgba(0, 21, 41, 0.12)"
          }}>
            <div className="d-flex justify-content-between">
              <div><LegacyIcon
                className="trigger"
                type={collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "18px",
                  //"line-height": "50px",
                  "cursor": "pointer",
                  "transition": "color 0.3s"
                }}
              /></div>
              <div>
                <ObjectNavigator onSelect={onObjectSelect} statuses={[3, 4]} />
                <Avatar
                  style={{ backgroundColor: '#87d068' }}
                  src={"https:" + accountDropdownProps.avatarURL}
                >
                  {user && `${user.name} ${user.surname}`}
                </Avatar>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item disabled>
                        <div>
                          <small
                            className="text-muted">{user.position_name}<br /> {user.username}
                          </small>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div onClick={() => setUserProfileOpen(true)}>
                          Muuda parool
                        </div>
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item>
                        <div onClick={() => logout()}>
                          Logi välja
                        </div>
                      </Menu.Item>
                    </Menu>
                  }

                  placement="bottomRight">

                  <span> {user.name} {user.surname}  </span>

                </Dropdown>

              </div>
            </div>

          </Header>
          <Content>
            {mode === 'development' ?
              <Alert
                message={
                  <div className="m-2">NB!!!! Siin on praegu TESTANDMED!!! Ära siia päris päevaaruannet täida!!</div>}
                type="success"
                closable
              />
              :
              null
            }
            {user && moment.duration(moment(user.password_expires).diff(moment())).asDays() < 10 && moment.duration(moment(user.password_expires).diff(moment())).asDays() > 0 ?
              <Alert
                message={
                  <div className="m-2">Sul on parooli aegumiseni {moment.duration(moment(user.password_expires).diff(moment())).locale('et').humanize()}! Kliki <u onClick={() => setUserProfileOpen(true)}>siia</u>, et vahetada!
                  </div>}
                type="warning"
                closable
              />
              :
              null
            }

            <UserProfile visible={userProfileOpen} onClose={() => setUserProfileOpen(false)} closable={userProfileClosable} />
            {children}
          </Content>
        </Layout>
      </Layout>

  )
}
)
