import React, { useCallback, useEffect, useState } from 'react'
import PageWithHeader from '../components/PageWithHeader'
import moment from 'moment'
import { useFormik } from 'formik'
import { UserOutlined } from '@ant-design/icons'
import {
  Table,
  Modal,
  Card,
  Tabs,
  Button as AntdButton,
  Input,
  Select,
  Alert,
  Switch,
  Tooltip,
  Tag
} from 'antd'

import {
  ClockCircleOutlined,
  CoffeeOutlined,
  ToolOutlined
} from '@ant-design/icons'

import '../App.css'
import API from '../services/api'
import { smartSearch } from '../services/smartSearch'
import { useStoreState } from 'easy-peasy'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'

const { TabPane } = Tabs
const { Search } = Input

const PaUsersModal = ({
  isOpen,
  modalData,
  onClose,
  groups,
  positions,
  okText,
  cancelText
}) => {
  const [newPass, setNewPass] = useState(null)
  const divisions = useStoreState((state) => state.divisions.divisions)

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      iduser: modalData['iduser'],
      name: modalData['user_name'],
      surname: modalData['user_surname'],
      email: modalData['user_username'],
      phone: modalData['phone'],
      position: modalData['user_position'],
      group: modalData['user_usergroup'],
      idresource: modalData['idresource'],
      iddivision: modalData['iddivision'],
      fuel_card: modalData['fuel_card'],
      user_nav_id: modalData['user_nav_id'],
      setidresource: false
    },
    // eslint-disable-next-line
    onSubmit: useCallback(async (values) => {
      console.log('values', values)
      if (values.iduser)
        API('/api/users/update', false, 'POST', values).then(onClose)
      else API('/api/users/update', false, 'PUT', values).then(onClose)
    })
  })

  const handleSubmit = () => formik.submitForm()
  const handlePassUpdate = (v) => setNewPass(v.target.value)
  const handlePassSubmit = () => {
    API('/api/users/reset', false, 'POST', {
      iduser: modalData['iduser'],
      pass: newPass
    }).then(onClose)
  }

  const handleDisable = () => {
    API('/api/users/update', false, 'DELETE', {
      iduser: modalData['iduser']
    }).then(onClose)
  }

  return (
    <Modal
      visible={isOpen}
      //icon="info-sign"
      onCancel={onClose}
      onOk={handleSubmit}
      title={'Kasutaja'}
      width={'70%'}
      destroyOnClose={true}
      okText={okText}
      cancelText={cancelText}
      footer={[
        <AntdButton type="danger" onClick={handleDisable}>
          Sulge konto
        </AntdButton>,
        <AntdButton type="primary" onClick={handleSubmit}>
          OK
        </AntdButton>,
        <AntdButton onClick={onClose}>Tühista</AntdButton>
      ]}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Andmed" key="1">
          <form onSubmit={formik.handleSubmit} className="container">
            <div className="row">
              <div className="col-md-6 mb-3">
                <input id="userid" name="id" value="" hidden />
                <input id="togglestatus" name="id" value="" hidden />
                <label htmlFor="name">Eesnimi</label>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  placeholder="Eesnimi"
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="surname">Perenimi</label>
                <Input
                  type="text"
                  id="surname"
                  name="surname"
                  onChange={formik.handleChange}
                  value={formik.values.surname}
                  placeholder="Perenimi"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="username">Kasutajanimi</label>
                <Input
                  type="email"
                  id="username"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  placeholder="eesnimi.perenimi@firma.ee"
                  required
                />
                <div id="invfeedback1" className="invalid-feedback">
                  Palun sisesta normaalne e-mail
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="inputPosition">Amet</label>
                <Select
                  id="inputPosition"
                  name="position"
                  value={formik.values.position}
                  onChange={(v) => formik.setFieldValue('position', v)}
                  required>
                  {positions.map((x, y) => (
                    <option key={x.iduser_positions} value={x.iduser_positions}>
                      {x.user_positions_name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="inputGroup">Grupp</label>
                <Select
                  id="inputGroup"
                  name="group"
                  value={formik.values.group}
                  onChange={(v) => formik.setFieldValue('group', v)}
                  required>
                  {groups.map((x, y) => (
                    <option key={x.iduser_groups} value={x.iduser_groups}>
                      {x.user_group_name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mb-3">
                <label htmlFor="phone">Telefon</label>
                <PhoneInput
                  country={'ee'}
                  inputClass="ant-input"
                  //containerClass="form-control"
                  containerStyle={{ width: '100%', height: '32px' }}
                  inputStyle={{ width: '100%', height: '32px' }}
                  onlyCountries={['ee']}
                  disableDropdown
                  countryCodeEditable={false}
                  value={formik.values.phone}
                  onChange={(value) =>
                    formik.setFieldValue('phone', `+${value}`)
                  }
                  placeholder=""
                  required
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="iddivision">Üksus</label>
                <Select
                  id="iddivision"
                  name="iddivision"
                  value={formik.values.iddivision}
                  onChange={(v) => formik.setFieldValue('iddivision', v)}
                  required>
                  {divisions.map((x, y) => (
                    <option key={x.id} value={x.id}>
                      {x.external_id} {x.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="username">Kütusekaardi number</label>
                <Input
                  id="fuel_card"
                  name="fuel_card"
                  onChange={formik.handleChange}
                  value={formik.values.fuel_card}
                  placeholder="Kaardi nr"
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="username">NAV id</label>
                <Input
                  id="user_nav_id"
                  name="user_nav_id"
                  onChange={formik.handleChange}
                  value={formik.values.user_nav_id}
                  placeholder="Kasutaja NAV id"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-6">
                <label htmlFor="setidresource"> </label>
                <Alert
                  message={
                    <div className="d-flex justify-content-between">
                      <div>Kas kasutaja teeb tunnitööd?</div>
                      <Switch
                        checkedChildren="Jah"
                        unCheckedChildren="Ei"
                        defaultChecked={!!formik.values.idresource}
                        disabled={formik.values.idresource}
                        onChange={(e) =>
                          formik.setFieldValue('setidresource', e)
                        }
                      />
                    </div>
                  }
                />
              </div>
              {modalData['worker_resource_id'] ? (
                <div className="col-md-6 mb-6">
                  <label htmlFor="setidresource"> </label>
                  <Alert
                    message={
                      <div>
                        <div className="d-flex justify-content-between">
                          <div>Kasutajale on seadistatud ressurss</div>
                          <div>
                            {modalData['worker_resource_id'] ? (
                              <Tag>{modalData['worker_resource_id']}</Tag>
                            ) : (
                              <Tag color="red">Ei</Tag>
                            )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>Kasutaja NAV id</div>
                          <div>
                            <Tag>{modalData['external_id']}</Tag>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>Kasutaja on teinud töölehti</div>
                          <div>
                            <Tag>{modalData['worksheets_count']}</Tag>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>Kasutajal on hetkel tööleht pooleli? </div>
                          <div>
                            <Tag
                              color={
                                modalData['worksheet_in_progress'] === 1
                                  ? 'green'
                                  : null
                              }>
                              {modalData['worksheet_in_progress'] === 1 ? (
                                <div>
                                  <ToolOutlined spin /> jah{' '}
                                </div>
                              ) : (
                                'ei'
                              )}
                            </Tag>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              ) : null}
            </div>
          </form>
        </TabPane>
        <TabPane
          tab="Parooli reset"
          disabled={Object.keys(modalData).length === 0}
          key="2">
          <div className="d-flex justify-content-between">
            <Input
              id="phone"
              type="text"
              className="mr-2"
              onChange={handlePassUpdate}
              name="newpass"
              placeholder="Sisesta uus parool"
              required
            />
            <div className="d-flex">
              <AntdButton className="mr-2" onClick={handlePassSubmit}>
                Reset
              </AntdButton>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  )
}

const PaUsers = () => {
  const [users, setUsers] = useState([])
  const [usersPositions, setUsersPositions] = useState([{}])
  const [usersGroups, setUsersGroups] = useState([{}])

  const emptyModalData = {
    iduser: null,
    user_name: null,
    user_surname: null,
    user_usernamel: null,
    phone: null,
    user_position: 1,
    user_usergroup: 1,
    idresource: null,
    iddivision: null,
    fuel_card: null,
    user_nav_id: null
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState(emptyModalData)
  const [search, setSearch] = useState('')

  const columns = [
    // {
    //     title: "#",
    //     dataIndex: "iduser",
    //     key: "iduser",
    // },
    {
      title: () => <UserOutlined />,
      dataIndex: 'user_status',
      key: 'user_status',
      sorter: (a, b) => a.user_status - b.user_status,
      render: (status) => (
        <UserOutlined style={{ color: status === 3 ? 'green' : 'red' }} />
      )
    },
    {
      title: 'Nimi',
      dataIndex: 'user_name',
      key: 'user_name',
      sorter: (a, b) => a.user_name.localeCompare(b.user_name)
    },
    {
      title: 'Perenimi',
      dataIndex: 'user_surname',
      key: 'user_surname',
      sorter: (a, b) => a.user_surname.localeCompare(b.user_surname)
    },
    {
      title: 'Kasutajanimi',
      dataIndex: 'user_username',
      key: 'user_username',
      sorter: (a, b) => a.user_username.localeCompare(b.user_username)
    },
    {
      title: 'Parool aegub',
      dataIndex: 'password_expires',
      key: 'password_exipres',
      render: (text) => (text ? moment(text).format('DD.MM.YYYY') : 'Ei aegu')
      //sorter: (a, b) => a.user_username.localeCompare(b.user_username),
    },
    {
      title: 'Tööaeg',
      dataIndex: 'idresource',
      key: 'idresource',

      //render: (text, record) => text ? <Tooltip title="Töötaja NAV ID"><Tag><ClockCircleOutlined style={{ color: 'green' }} color="#52c41a" /> # {record.external_id} </Tag> </Tooltip> : <Tooltip title="Töötaja ei osale tööaja mõõtmisel"><CoffeeOutlined style={{ color: 'brown' }} /></Tooltip>
      render: (text, record) =>
        text ? (
          <Tooltip title="Töötaja NAV ID">
            <Tag>
              <ClockCircleOutlined style={{ color: 'green' }} color="#52c41a" />{' '}
              # {record.external_id}
            </Tag>{' '}
          </Tooltip>
        ) : (
          <Tooltip title="Töötaja ei osale tööaja mõõtmisel">
            <CoffeeOutlined style={{ color: 'brown' }} />
          </Tooltip>
        )

      //sorter: (a, b) => a.user_username.localeCompare(b.user_username),
    },
    {
      title: 'Töölehti',
      dataIndex: 'worksheets_count',
      key: 'worksheets_count',
      //render: (text, record) => text > 0 ? <div><Tag>{text}</Tag> {record.worksheet_in_progress > 0 ? <Tooltip titile={"Lõpetamata tööleht"}> <ToolOutlined spin /></Tooltip> : null} </div> : null
      render: (text, record) =>
        text > 0 ? (
          <div>
            <Tag>{text}</Tag>
          </div>
        ) : null
      //sorter: (a, b) => a.user_username.localeCompare(b.user_username),
    },
    {
      title: 'Grupp',
      dataIndex: 'user_group_name',
      key: 'user_group_name',
      sorter: (a, b) => a.user_group_name.localeCompare(b.user_group_name)
    },
    {
      title: 'Amet',
      dataIndex: 'user_positions_name',
      key: 'user_positions_name',
      sorter: (a, b) =>
        a.user_positions_name.localeCompare(b.user_positions_name)
    },
    {
      title: 'Üksus',
      dataIndex: 'division_name',
      key: 'division_name',
      sorter: (a, b) => a.division_name.localeCompare(b.division_name)
    },
    {
      title: 'Kütusekaardi nr',
      dataIndex: 'fuel_card',
      key: 'fuel_card',
      sorter: (a, b) => {
        if (a.fuel_card && b.fuel_card)
          return a.fuel_card.localeCompare(b.fuel_card)
        if (a.fuel_card) return -1
        if (b.fuel_card) return 1
        return 0
      }
    },
    {
      title: 'NAV id',
      dataIndex: 'user_nav_id',
      key: 'user_nav_id',
      sorter: (a, b) => a.user_nav_id - b.user_nav_id
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Viimati muudetud',
      dataIndex: 'user_lastchanged',
      key: 'user_lastchanged',
      render: (text) => (text ? moment(text).format('DD.MM.YYYY') : 'Ei aegu')
      //sorter: (a, b) => a.user_username.localeCompare(b.user_username),
    },
    {
      title: 'Tegevus',
      dataIndex: 'iduser',
      key: 'actions',
      render: (text, record, index) => (
        <span onClick={(event) => handleEdit(record)}>Muuda</span>
      )
    }
  ]

  const searchColumns = [
    'user_name',
    'user_surname',
    'user_username',
    'user_group_name',
    'user_positions_name',
    'division_name',
    'phone',
    'fuel_card',
    'user_nav_id'
  ]

  function handleEdit(record) {
    console.log('Users - edit: ', record)
    setModalData(record)
    setIsModalOpen(true)
  }

  useEffect(() => {
    API('/api/users').then((res) => setUsers(res.data))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    API('/api/users/groups').then((res) => setUsersGroups(res.data))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    API('/api/users/positions').then((res) => setUsersPositions(res.data))
    // eslint-disable-next-line
  }, [])

  return (
    <PageWithHeader>
      <div className="m-3 p-3">
        <div>
          <PaUsersModal
            isOpen={isModalOpen}
            modalData={modalData}
            groups={usersGroups}
            positions={usersPositions}
            okText="Muuda"
            cancelText="Tühista"
            onClose={() => {
              setModalData(emptyModalData)
              setIsModalOpen(false)
              API('/api/users').then((res) => setUsers(res.data))
            }}
          />
        </div>

        <Card
          title="Kasutajad"
          extra={
            <div className="d-flex">
              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="mr-2"
                placeholder="Otsi..."
              />
              <AntdButton
                type="primary"
                onClick={() => {
                  setIsModalOpen(true)
                }}>
                Lisa uus
              </AntdButton>
            </div>
          }>
          <div
            className="m-2"
            style={{
              overflowX: 'auto',
              background: '#fff'
            }}>
            <Table
              columns={columns}
              dataSource={smartSearch(
                users.filter((r) => r.user_status > 1),
                search,
                searchColumns
              )}
              className={'table-striped-rows'}
              size="middle"
              rowKey={'iduser'}
              pagination={{
                defaultPageSize: 100,
                showSizeChanger: true,
                onChange: (page, pageSize) =>
                  console.log('page: ', page, 'pagesize: ', pageSize),
                onShowSizeChange: (current, size) =>
                  console.log('page: ', current, 'pagesize: ', size)
              }}
            />
          </div>
        </Card>
      </div>
    </PageWithHeader>
  )
}

export default PaUsers
