import { Navbar, Nav, Form } from 'react-bootstrap'
import React, { useEffect, useState, createRef, useCallback, useRef } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import PageWithHeader from '../components/PageWithHeader'
import to from 'await-to-js'
import dateLocale from 'antd/es/date-picker/locale/et_EE'
import {
  DeleteOutlined,
  ScissorOutlined,
  FileExcelOutlined,
  LoadingOutlined,
  SnippetsOutlined,
  SettingOutlined,
  CopyOutlined,
  PieChartOutlined,
  ReloadOutlined
} from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'

import {
  Button,
  Modal,
  Tabs,
  DatePicker,
  Row,
  Col,
  PageHeader,
  Alert,
  Badge,
  Collapse,
  Affix,
  Tooltip,
  Tag,
  Table,
  message,
  Card,
  Menu,
  Dropdown,
  Radio
} from 'antd'
import '../App.css'

import PaObjectOffers from '../components/object/ObjectOffers'
import PaObjectOptions from '../components/object/ObjectOptions'
import moment from 'moment'
import 'react-pivottable/pivottable.css'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { PaObjectOverview } from '../components/object/overview/ObjectOverview'
import { PaObjectOverviewProduction } from '../components/object/overview/ObjectOverviewProduction'
import PaObjectsStatus from '../components/PaObjectsStatus'
import PaObjectsFavourite from '../components/PaObjectsFavourite'
import useWindowSize from '../services/useWindowSize'
import { FactoryLiveTable, FactoryStatistics } from './factoryLive'
import { PaProductionObjectPivotTable } from '../components/object/analysis/ObjectAnalysisProduction'
import PaObjectPivotTable from '../components/object/analysis/ObjectAnalysis'
import { PaProjection, PaProjectionV2 } from '../components/object/projections/Projection'
import PaObjectDayCostTable from '../components/object/day/costs/CostTable'
import PaCostsCopyDayModal from '../components/object/day/costs/CostCopyDay'
import PaCostsMoveDayModal from '../components/object/day/costs/CostMoveDay'

import PaObjectTabsDatepicker from '../components/object/ObjectTabsDatepicker'
import PaProductionImportDayModal from '../components/object/day/income/ProductionImportDay'
import PaObjectDayProductionIncomeTable from '../components/object/day/income/ProductionIncomeTable'
import PaObjectDayIncomeTable from '../components/object/day/income/IncomeTable'
import PaGroupBillDivisionV2 from '../components/object/day/bills/v2/GroupBillDivision'

import PaObjectDayWarehouseCostTable from '../components/object/day/costs/WarehouseCostTable'
import ActModal from '../components/object/acts/ActModal'
import ActTable from '../components/object/acts/ActTable'
import ConstructionDiary from '../components/object/diary/ConstructionDiary'

const { TabPane } = Tabs
const { Panel } = Collapse

const PaObject = () => {
  let { id, tab } = useParams()

  const user = useStoreState((state) => state.user.me)
  const meta = useStoreState((state) => state.object.meta)
  const objectActiveDate = useStoreState((state) => state.object.activeDate)
  const loadObjectCosts = useStoreActions((actions) => actions.object.apiLoadCosts)
  const loadBills = useStoreActions((actions) => actions.bills.apiFetchBills)
  const loadObjectMeta = useStoreActions((actions) => actions.object.apiLoadMeta)
  const loadObjectBudget = useStoreActions((actions) => actions.object.apiLoadBudget)
  const loadObjectIncome = useStoreActions((actions) => actions.object.apiLoadIncome)
  const loadObjectOffers = useStoreActions((actions) => actions.offers.apiLoadOffers)
  const loadObjectPrognosis = useStoreActions((actions) => actions.object.apiFetchPrognosis)
  const loadObjectIncomeDates = useStoreActions((actions) => actions.object.apiFetchIncomeDates)
  const fetchProductionIncome = useStoreActions((actions) => actions.production.apiFetchIncome)
  const fetchResultV2 = useStoreActions((actions) => actions.resultV2.apiFetchResult)
  const fetchObjectsTotals = useStoreActions((actions) => actions.resultV2.apiFetchObjectsTotals)

  // V2

  const fetchV2Budgets = useStoreActions((actions) => actions.budgets.apiFetchBudgets)
  const budgetsV2 = useStoreState((state) => state.budgets.budgets)
  const fetchV2budget = useStoreActions((actions) => actions.budget.apiFetchBudget)

  const [loading, setLoading] = useState(true)

  const pageContainerDiv = useRef()

  useEffect(() => {
    (async () => {
      await setLoading(true)
      await loadObjectMeta(id)
      await setLoading(false)
      loadBills()
      loadObjectCosts(id)
      loadObjectBudget(id)
      loadObjectIncome({ obj_id: id, date: objectActiveDate.format('YYYY-MM-DD 00:00:00') })
      loadObjectOffers({ obj_id: id })
      loadObjectPrognosis({ obj_id: id })
      loadObjectIncomeDates(id)
      fetchProductionIncome({ objid: id, date: objectActiveDate.format('YYYY-MM-DD 00:00:00') })

      await fetchV2Budgets()
      await fetchV2budget({ obj_id: id })
      await fetchResultV2({ obj_id: id })
      await fetchObjectsTotals({ objects: [id] })
    })()
    // eslint-disable-next-line
  }, [id])

  let navigate_to_tab = '2'

  if (tab) navigate_to_tab = tab
  else {
    const stored_tab = window.localStorage.getItem('objectTab')
    if (stored_tab) navigate_to_tab = stored_tab
  }

  // todo: implement URL argument pass for active tab
  return user.position === 7 ? (
    <Redirect to={'/livepaver/' + id} />
  ) : (
    <PageWithHeader>
      <div ref={pageContainerDiv}>
        <AdditionalHeaderBlock loading={loading} />

        <PaObjectTabs id={id} tab={navigate_to_tab} />
      </div>
    </PageWithHeader>
  )
}

const PaObjectTabs = ({ id, tab }) => {
  const type = useStoreState((state) => state.object.meta.type)
  const object = useStoreState((state) => state.object)
  const costingVersion = useStoreState((state) => state.object.meta.costing_version)
  const fetchObjectsTotals = useStoreActions((actions) => actions.resultV2.apiFetchObjectsTotals)

  const apiFetchOffers = useStoreActions((actions) => actions.offers.apiLoadOffers)
  const allOffers = useStoreState((state) => state.offers.allOffers)

  const [activeTab, setActiveTab] = useState(tab)

  useEffect(() => {
    apiFetchOffers()
  }, [])
  const offers = allOffers ? allOffers.filter((offer) => offer.obj_id === Number(id)) : []

  useEffect(() => {
    window.localStorage.setItem('objectTab', activeTab)
    if (object.meta && object.meta.idobject) fetchObjectsTotals({ objects: object.meta.idobject })
  }, [activeTab])

  return (
    <Tabs
      animated={false}
      activeKey={activeTab}
      onTabClick={(k, e) => setActiveTab(k)}
      tabBarExtraContent={{ left: <div style={{ width: 20 }} /> }}
      tabBarStyle={{ background: '#fff' }}>
      <TabPane tab="Ülevaade" key="1">
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          {type && type === 'Ehitus' ? <PaObjectOverview setActiveTab={setActiveTab} /> : <PaObjectOverviewProduction setActiveTab={setActiveTab} />}
        </div>
      </TabPane>
      <TabPane tab="Päev" key="2">
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <PaObjectDay />
        </div>
      </TabPane>
      <TabPane tab="Ehituspäevik" key="3">
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <ConstructionDiary />
        </div>
      </TabPane>
      {type && type === 'Ehitus' && (
        <TabPane tab="Eelarved" key="4">
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <PaObjectOffers />
          </div>
        </TabPane>
      )}
      {type && type === 'Ehitus' && (
        <TabPane tab="Prognoos" key="5">
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>{costingVersion === 2 ? <PaProjectionV2 /> : <PaProjection />}</div>
        </TabPane>
      )}
      <TabPane tab="Analüüs" key="6">
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>{type && type === 'Ehitus' ? <PaObjectPivotTable /> : <PaProductionObjectPivotTable />}</div>
      </TabPane>
      {object && object.meta.is_small_object === 1 && type && type === 'Ehitus' && (
        <TabPane tab="Aktid" key="7">
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <PaObjectActs object={object.meta} offers={offers.map(({ offer_nr }) => offer_nr)} />
          </div>
        </TabPane>
      )}
    </Tabs>
  )
}

const AdditionalHeaderBlock = ({ id, name, loading = true }) => {
  const editAllowed = useStoreState((state) => state.user.editAllowed)
  const objectMeta = useStoreState((state) => state.object.meta)
  const apiRollBack = useStoreActions((actions) => actions.object.apiRollBackCost)

  const undo = useStoreState((state) => state.object.undo)

  const winSize = useWindowSize()
  let history = useHistory()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const tags = []
  if (objectMeta) {
    tags.push(<PaObjectsFavourite idobject={objectMeta.idobject} />)
    tags.push(<PaObjectsStatus status={objectMeta.status} type="tag" />)
    tags.push(<Tag>V {objectMeta.costing_version}</Tag>)
  }

  const handleShowLoadsClick = () => {
    if (winSize.width < 600) {
      history.push('/livepaver/' + objectMeta.idobject)
    } else setIsModalOpen(true)
  }

  const [isAffixed, setIsAffixed] = useState(false)

  return (
    <Affix offsetTop={0} onChange={(affixed) => setIsAffixed(affixed)}>
      <div>
        <PaTrackLoadsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </div>

      {loading ? (
        <PageHeader
          style={{
            border: '1px solid rgb(235, 237, 240)'
          }}
          title={<LoadingOutlined />}
          subTitle=""
          ghost={true}>
          {' '}
        </PageHeader>
      ) : (
        <PageHeader
          style={{
            border: '1px solid rgb(235, 237, 240)',
            background: 'rgb(240, 242, 245)'
          }}
          title={objectMeta.shiffer}
          subTitle={objectMeta.name}
          tags={tags}
          ghost={true}
          extra={
            isAffixed ? (
              <div>
                {undo.length > 0 ? <Button icon={<LegacyIcon type={'undo'} />} className="mr-1" onClick={() => apiRollBack()}></Button> : null}
                <PaObjectTabsDatepicker />
              </div>
            ) : (
              [
                <Button onClick={handleShowLoadsClick}>
                  <LegacyIcon type={winSize.width < 600 ? 'mobile' : 'desktop'} /> Jälgi koormaid
                </Button>,
                <Button onClick={() => history.push('/objects/update/' + objectMeta.idobject)}>
                  <SettingOutlined />
                </Button>
              ]
            )
          }>
          {objectMeta.status !== 3 ? <Alert message='Begini ja Directo sünki ei toimu. Selleks peab objekt olema "Töös"' type="info" showIcon /> : null}
          {editAllowed ? null : (
            <Alert
              message="Sa ei tohi seda objekti muuta!"
              description="Sellel objektil on piiratud muutmisõigus! Kui Sa ikkagi oled kindel, siis pöördu oma projektijuhi poole!"
              type="error"
              showIcon
            />
          )}
        </PageHeader>
      )}
    </Affix>
  )
}

const PaTrackLoadsModal = ({ isOpen, onClose }) => {
  const factoryActiveDate = useStoreState((state) => state.factory.activeDate)
  const setDate = useStoreActions((actions) => actions.factory.setActiveDate)
  const meta = useStoreState((state) => state.object.meta)
  const fetchEvents = useStoreActions((actions) => actions.factory.apiFetchScaleEvents)

  useEffect(() => {
    fetchEvents({
      date: factoryActiveDate.format('YYYY-MM-DD')
    })
    // eslint-disable-next-line
  }, [factoryActiveDate])

  return (
    <Modal
      title="Koormate jälgimine"
      visible={isOpen}
      onOk={onClose}
      //confirmLoading={confirmLoading}
      style={{ width: '80%' }}
      width="80%"
      onCancel={onClose}>
      <div className="d-flex justify-content-between align-items-center">
        <FactoryStatistics obj={meta.idobject} appearance="thin" />
        <div className="m-2 d-flex justify-content-end">
          <DatePicker locale={dateLocale} allowClear={false} defaultValue={factoryActiveDate} onChange={setDate} format="DD.MM.YYYY" />
        </div>
      </div>
      <FactoryLiveTable obj={meta.idobject} type="product" />
    </Modal>
  )
}

const PaObjectDayTotals = ({ display = 'horizontal' }) => {
  const objectCostData = useStoreState((state) => state.object.costs)
  const income = useStoreState((state) => state.object.income)
  const productionIncome = useStoreState((state) => state.production.income)
  const objectActiveDate = useStoreState((state) => state.object.activeDate)

  let date = objectActiveDate.format('YY-MM-DD')

  let costTotal = 0
  let incomeTotal = 0
  let incomeTotalOffered = 0
  let productionIncomeTotal = 0

  if (objectCostData)
    costTotal = Object.values(objectCostData).reduce((sum, row) => (moment(row.cost_date).format('YY-MM-DD') === date ? sum + row.row_sum : sum), 0)
  if (income) incomeTotal = income.reduce((sum, row) => sum + row.fulfilled * row.unit_price, 0)
  if (income) incomeTotalOffered = income.reduce((sum, row) => sum + row.fulfilled * row.akteerimiseks_unit_price, 0)
  if (productionIncome)
    productionIncomeTotal = productionIncome
      .filter((r) => r.apply_general_cost === 1)
      .filter((r) => moment(r.production_date).format('YYYY-MM-DD') === objectActiveDate.format('YYYY-MM-DD'))
      .reduce((sum, row) => sum + row.row_sum, 0)

  incomeTotal = incomeTotal + productionIncomeTotal

  let result2 = incomeTotal - costTotal
  let result = incomeTotalOffered - costTotal

  return display === 'horizontal' ? (
    <div className="m-2" style={{ width: '500px', textAlign: 'right' }}>
      <div>
        <Row>
          <Col span={4}>Kulud:</Col>
          <Col span={4}>
            <Badge color="red" text={<span>€ {costTotal.toFixed(0)}</span>} />
          </Col>
          <Col span={4}>Lep. tulud:</Col>
          <Col span={4}>
            <Badge color="green" text={<span>€ {incomeTotalOffered.toFixed(0)}</span>} />
          </Col>
          <Col span={4}>Tulem:</Col>
          <Col span={4}>
            <Badge color={result > 0 ? 'green' : 'red'} text={<span>€ {result.toFixed(0)}</span>} />
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <div>&nbsp;</div>
          </Col>
          <Col span={4}>
            <div>&nbsp;</div>
          </Col>
          <Col span={4}>Eesm. tulud:</Col>
          <Col span={4}>
            <Badge color="green" text={<span>€ {incomeTotal.toFixed(0)}</span>} />
          </Col>
          <Col span={4}>Tulem:</Col>
          <Col span={4}>
            <Badge color={result > 0 ? 'green' : 'red'} text={<span>€ {result2.toFixed(0)}</span>} />
          </Col>
        </Row>
      </div>
      {/*<div>
                    <div className="d-flex mr-2">
                        <span className="mr-2 ml-2">Kulud:</span>
                        <Badge color="red" text={<span>€ {costTotal.toFixed(0)}</span>}/>
                        <span className="mr-2 ml-2">Teostatud eesmärkhinnas:</span>
                <Badge className="ml-1" color="green" text={<span>€ {incomeTotal.toFixed(0)}</span>}/>
                <span className="mr-2 ml-2">Teostatud akt hinnas:</span>
                <Badge className="ml-1" color="green" text={<span>€ {incomeTotalOffered.toFixed(0)}</span>}/>
                        <span className="mr-2 ml-2">Lep. tulud:</span>
                        <Badge className="ml-1" color="green" text={<span>€ {incomeTotalOffered.toFixed(0)}</span>}/>
                        <span className="mr-2 ml-2">Tulem:</span>
                        <Badge color={result > 0 ? "green" : "red"} text={<span>€ {result.toFixed(0)}</span>}/>
                    </div>
                    <div className="d-flex mr-2">
                        <span className="mr-2 ml-2">Kulud: </span>
                        <Badge color="red" text={<span>€ {costTotal.toFixed(0)}</span>}/>
                         <span className="mr-2 ml-2">Teostatud eesmärkhinnas:</span>
                <Badge className="ml-1" color="green" text={<span>€ {incomeTotal.toFixed(0)}</span>}/>
                <span className="mr-2 ml-2">Teostatud akt hinnas:</span>
                <Badge className="ml-1" color="green" text={<span>€ {incomeTotalOffered.toFixed(0)}</span>}/>
                        <span className="mr-2 ml-2">Eesm tulud:</span>
                        <Badge className="ml-1" color="green" text={<span>€ {incomeTotal.toFixed(0)}</span>}/>
                        <span className="mr-2 ml-2">Tulem:</span>
                        <Badge color={result > 0 ? "green" : "red"} text={<span>€ {result2.toFixed(0)}</span>}/>
                    </div>
                </div> */}
    </div>
  ) : (
    <Tooltip title="siia">
      <div className="m-2">
        <Row>
          <Col span={16}>Kulud:</Col>
          <Col span={8}>
            <Badge color="red" text={<span>€ {costTotal.toFixed(0)}</span>} />
          </Col>
        </Row>
        {/*<Row>
                    <Col span={16}>Teostatud eesmärkhinnas:</Col>
                    <Col span={8}><Badge color="green" text={<span>€ {incomeTotal.toFixed(0)}</span>}/></Col>
                </Row> */}
        <Row>
          {/*<Col span={16}>Teostatud akt hinnas:</Col>*/}
          <Col span={16}>Tulud:</Col>
          <Col span={8}>
            <Badge color="green" text={<span>€ {incomeTotalOffered.toFixed(0)}</span>} />
          </Col>
        </Row>
        <Row>
          <Col span={16}>Tulem:</Col>
          <Col span={8}>
            <Badge color={result > 0 ? 'green' : 'red'} text={<span>€ {result.toFixed(0)}</span>} />
          </Col>
        </Row>
      </div>
    </Tooltip>
  )
}

const PaObjectDay = () => {
  const objectActiveDate = useStoreState((state) => state.object.activeDate)
  const type = useStoreState((state) => state.object.meta.type)
  const setObjectActiveDate = useStoreActions((actions) => actions.object.setObjectActiveDate)
  const editAllowed = useStoreState((state) => state.user.editAllowed)
  const apiRollBack = useStoreActions((actions) => actions.object.apiRollBackCost) // undo
  const undo = useStoreState((state) => state.object.undo) // undo

  const cleanCosts = useStoreActions((actions) => actions.control.cleanCosts)
  const costData = useStoreState((state) => state.object.costs)
  const meta = useStoreState((state) => state.object.meta)
  const loadObjectCosts = useStoreActions((actions) => actions.object.apiLoadCosts)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModal1Open, setIsModal1Open] = useState(false)
  const [isModal2Open, setIsModal2Open] = useState(false)
  const [isModal3Open, setIsModal3Open] = useState(false)

  const [returnDate, setReturnDate] = useState(moment())

  const [isTemplateView, setIsTemplateView] = useState(false)
  const [activeTab, setActiveTab] = useState('2')

  const showTemplate = async () => {
    let myReturnDate = objectActiveDate
    await setReturnDate(myReturnDate)
    await setObjectActiveDate(moment('1900-01-01'))
    await setIsTemplateView(true)
  }

  const showCostView = async () => {
    await setObjectActiveDate(returnDate)
    await setIsTemplateView(false)
  }

  let filteredDataSource = Object.values(costData).filter((row) => {
    if (moment(row.cost_date).startOf('day').isSame(objectActiveDate.startOf('day'))) {
      return true
    }
    return false
  })

  const menu = (
    <Menu>
      <Menu.Item icon={<DeleteOutlined />}>
        <a
          onClick={async () => {
            const emptyIds =
              costData &&
              Object.values(costData)
                .filter(({ row_sum, cost_date }) => (!row_sum || row_sum === 0) && moment(cost_date).isSame(objectActiveDate, 'day'))
                .map((cost) => cost.idcosts)
            if (emptyIds && emptyIds.length > 0) {
              const res = await cleanCosts(emptyIds)
              if (res) {
                const { code } = res
                if (code === 200) {
                  loadObjectCosts(meta.idobject)
                  message.success(res.message)
                }
                if (code === 400) {
                  message.error(res.message)
                }
              }
            } else {
              message.warning('Tühje ridu ei leitud')
            }
          }}>
          {' '}
          Kustuta kõik tühjad read valitud päeval
        </a>
      </Menu.Item>
      <Menu.Item icon={<ScissorOutlined />}>
        <a onClick={() => setIsModal3Open(true)}> Tõsta valitud päeva kõik read teise objekti</a>
      </Menu.Item>
      <Menu.Item icon={<CopyOutlined />} disabled={!editAllowed}>
        {type === 'Tootmine' ? <a onClick={() => setIsModal2Open(true)}>Impordi</a> : <a onClick={() => setIsModalOpen(true)}>Kopeeri ridu </a>}
      </Menu.Item>
      <Menu.Item icon={<SnippetsOutlined />} disabled={!editAllowed}>
        <a onClick={showTemplate}>Vaata objekti andmete põhja</a>
      </Menu.Item>
      {meta.costing_version === 2 ? (
        <Menu.Item icon={<PieChartOutlined />} disabled={!editAllowed}>
          <PaGroupBillDivisionV2 records={filteredDataSource} costType={1} shape={false} placement="bottomRight" fullday={true} />
        </Menu.Item>
      ) : null}
      <Menu.Item icon={<SnippetsOutlined />} disabled={!editAllowed}>
        <a onClick={() => setIsModal1Open(true)}>Seadistused (fix väljad) </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <div>
      <div>
        <PaObjectOptions isOpen={isModal1Open} onClose={() => setIsModal1Open(false)} />
      </div>
      <div>
        <PaCostsCopyDayModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </div>
      <div>
        <PaProductionImportDayModal isOpen={isModal2Open} onClose={() => setIsModal2Open(false)} />
      </div>
      <div>
        <PaCostsMoveDayModal isOpen={isModal3Open} costs={filteredDataSource} onClose={() => setIsModal3Open(false)} />
      </div>
      <div className="fullscreenonly">
        <div className="mt-2 p-2" style={{ background: '#fff' }}>
          <div>
            {!isTemplateView ? (
              <div className="d-flex justify-content-lg-between mr-2">
                <div className="p-2">
                  <Radio.Group defaultValue={activeTab === '2' ? 'Kulud' : 'Tulud'}>
                    <Radio.Button value="Kulud" onClick={() => setActiveTab('2')}>
                      Kulud
                    </Radio.Button>
                    <Radio.Button value="Tulud" onClick={() => setActiveTab('1')}>
                      Tulud
                    </Radio.Button>
                  </Radio.Group>
                </div>
                <Form inline>
                  <PaObjectDayTotals />
                  <Tooltip title="Võta viimane muudatus tagasi">
                    <Button //icon={}
                      className="mr-1 ml-1"
                      disabled={undo.length === 0}
                      onClick={() => apiRollBack()}>
                      <i className="fas fa-reply"></i>
                    </Button>
                  </Tooltip>

                  <Tooltip title="Uuenda andmed">
                    <Button className="mr-1" onClick={() => loadObjectCosts(meta.idobject)}>
                      <ReloadOutlined />
                    </Button>
                  </Tooltip>
                  <PaObjectTabsDatepicker />
                  <Dropdown overlay={menu} placement="bottomRight">
                    <Button> ... </Button>
                  </Dropdown>
                </Form>
              </div>
            ) : (
              <Form inline>
                <Button className="mr-1" onClick={showCostView}>
                  Tänane aruanne
                </Button>
              </Form>
            )}
          </div>
        </div>
      </div>
      <div className="mobileonly">
        <div className="mt-2 p-2" style={{ background: '#fff' }}>
          <div className="p-2 d-flex justify-content-between" style={{ background: '#fafafa' }}>
            <Button.Group>
              <Button type={activeTab === '2' ? 'primary' : null}>Kulud</Button>
              <Button type={activeTab !== '2' ? 'primary' : null}>Tulud</Button>
            </Button.Group>
            <Button.Group />
            <Button.Group>{!isTemplateView ? <PaObjectTabsDatepicker /> : null}</Button.Group>
          </div>
          <Collapse bordered={false} defaultActiveKey={['1']}>
            <Panel header="Päeva tulem ja lisad" key="1">
              <PaObjectDayTotals display="vertical" />
              {!isTemplateView ? (
                <Button.Group>
                  <Button onClick={() => setIsModal1Open(true)}>Fix väljad</Button>

                  {type === 'Tootmine' ? (
                    <Button type="primary" onClick={() => setIsModal2Open(true)}>
                      Impordi
                    </Button>
                  ) : (
                    <Button onClick={() => setIsModalOpen(true)}>Kopeeri...</Button>
                  )}
                  <Button onClick={showTemplate}>Põhi</Button>
                </Button.Group>
              ) : (
                <Button className="mr-1" onClick={showCostView}>
                  Tänane aruanne
                </Button>
              )}
            </Panel>
          </Collapse>
        </div>
      </div>

      {objectActiveDate.format('YYYYMMDD') > moment().format('YYYYMMDD') ? (
        <div>
          <Alert
            message="Hoiatus"
            description={
              <p>
                See päev on {moment.duration(moment().diff(objectActiveDate)).locale('et').humanize()} tulevikus. Kas oled ikka kindel, et soovid seda täita?{' '}
              </p>
            }
            type="warning"
            closable
            showIcon
          />
        </div>
      ) : null}

      <Tabs animated={false} activeKey={activeTab} onTabClick={(k, e) => setActiveTab(k)} renderTabBar={(a, b) => <div />}>
        <TabPane tab="Tulud" key="1">
          <div className="p-2" style={{ background: '#fff' }}>
            <Row gutter={[0, 0]}>
              <Col span={24}>{type === 'Ehitus' ? <PaObjectDayIncomeTable /> : <PaObjectDayProductionIncomeTable />}</Col>
            </Row>
          </div>
        </TabPane>
        <TabPane tab="Kulud" key="2">
          {type === 'Ehitus' ? <PaCostsContentConstruction /> : <PaCostsContentProduction />}
        </TabPane>
      </Tabs>
    </div>
  )
}

const PaCostsContentConstruction = () => {
  const meta = useStoreState((state) => state.object.meta)

  return (
    <div className="p-2" style={{ background: '#fff' }}>
      <Row gutter={[0, 0]}>
        <Col md={12} sm={24}>
          <PaObjectDayCostTable index={0} addButton={false} />
          <PaObjectDayCostTable index={1} />
        </Col>

        <Col md={12} sm={24}>
          <PaObjectDayCostTable index={2} filterOutET="VE" />
        </Col>
      </Row>
      <Row>
        <div className={'col mt-1'}>
          <PaObjectDayCostTable index={3} />
        </div>
      </Row>
      <Row>
        <div className={'col mt-1'}>
          <PaObjectDayCostTable index={4} showfooter />
        </div>
      </Row>
      <Row>
        <div className={'col mt-1'}>
          {meta.temp_is_ET_object === 1 ? <PaObjectDayCostTable index={12} showfooter /> : <PaObjectDayCostTable index={5} showfooter />}
        </div>
      </Row>
      <Row>
        <div className={'col mt-1'}>
          <PaObjectDayCostTable index={9} />
        </div>
      </Row>
      <Row>
        <div className={'col mt-1'}>
          <PaObjectDayCostTable index={6} />
        </div>
      </Row>
      <Row>
        <div className={'col mt-1'}>
          <PaObjectDayCostTable index={7} />
        </div>
      </Row>
    </div>
  )
}

const PaCostsContentProduction = () => (
  <div className="p-2" style={{ background: '#fff' }}>
    <Row gutter={[0, 0]}>
      <Col md={12} sm={24}>
        <PaObjectDayCostTable index={0} addButton={false} billsButton={false} />
      </Col>

      <Col md={12} sm={24}>
        <PaObjectDayCostTable index={1} billsButton={false} />
      </Col>
    </Row>
    <Row>
      <div className={'col mt-1'}>
        <PaObjectDayWarehouseCostTable />
      </div>
    </Row>
    <Row>
      <Col span={24}>
        <PaObjectDayCostTable index={8}
                              //addButton={false}
                              billsButton={false} />
      </Col>
    </Row>

    <Row>
      <div className={'col mt-1'}>
        <PaObjectDayCostTable index={6} billsButton={false} />
      </div>
    </Row>
    <Row>
      <div className={'col mt-1'}>
        <PaObjectDayCostTable index={10} billsButton={false} />
      </div>
    </Row>
  </div>
)

const PaObjectActs = ({ object, offers }) => {
  const [selectedAct, setSelectedAct] = useState({})

  const objectId = object && object.idobject
  // State
  const user = useStoreState((state) => state.user.me)
  const acts = useStoreState((state) => state.acts.list)
  const actsLoading = useStoreState((state) => state.acts.loading)
  // Actions
  const fetchActs = useStoreActions((actions) => actions.acts.fetch)
  const saveAct = useStoreActions((actions) => actions.acts.save)
  const deleteAct = useStoreActions((actions) => actions.acts.delete)
  const exportData = useStoreActions((actions) => actions.acts.export)
  const fetchActOffers = useStoreActions((actions) => actions.actRows.fetchOffers)
  const fetchActRows = useStoreActions((actions) => actions.actRows.fetch)

  const addOffer = useStoreActions((actions) => actions.offers.apiAddOffer)

  const objectHasOffers = offers && offers.length > 0

  let history = useHistory()

  const [actModalVisible, setActModalVisible] = useState(false)
  moment.locale('et')

  useEffect(() => {
    fetchActs(objectId)
  }, [objectId])

  const formRef = createRef()

  const addAct = useCallback(() => {
    return formRef.current.form.validateFieldsAndScroll(async (err, values) => {
      console.log('posting: ', { values })
      if (!err) {
        console.log(values)
        const [error, response] = await to(
          saveAct({
            obj_id: objectId,
            date_document: moment(values.date_document).format('YY-MM-DD'),
            work_start: moment(values.work_start).format('YY-MM-DD'),
            work_end: moment(values.work_end).format('YY-MM-DD'),
            prepayment: false,
            prefill: values.prefill,
            ...values
          })
        )
        if (response) {
          setActModalVisible(false)
        }
      }
    })
  }, [formRef, saveAct])

  const actsColumns = [
    {
      title: 'Kuupäev',
      dataIndex: 'date_document',
      key: 'acts_date',
      render: (date) =>
        date ? (
          <a>
            <b>{moment(date).format('Do MMMM YYYY')}</b>
          </a>
        ) : (
          <a> - </a>
        )
    },
    {
      title: 'Nr',
      dataIndex: 'act_nr',
      key: 'acts_act_nr',
      render: (text) => (
        <a>
          <b>{text}</b>
        </a>
      )
    },
    {
      title: 'Ettemaks',
      dataIndex: 'prepayment',
      key: 'acts_prepayment',
      render: (bool) => (bool === 0 ? 'Ei' : 'Jah')
    },
    {
      title: 'Töö algus',
      dataIndex: 'work_start',
      key: 'acts_work_start',
      render: (date) => (date ? moment(date).format('Do MMMM') : '-')
    },
    {
      title: 'Töö lõpp',
      dataIndex: 'work_end',
      key: 'acts_work_end',
      render: (date) => (date ? moment(date).format('Do MMMM') : '-')
    },
    {
      title: 'Summa',
      dataIndex: 'total',
      key: 'acts_total',
      render: (text, record, index) => (text ? '€ ' + text.toFixed(2).toString() : '-')
    },
    {
      title: 'Arveks',
      dataIndex: 'date_confirmed',
      key: 'acts_date_confirmed',
      render: (date) => (date ? moment(date).format('Do MMMM') : '-')
    },
    {
      title: 'Märkused',
      dataIndex: 'description',
      key: 'acts_description'
    },
    {
      title: 'Kinnitus',
      key: 'acts_status',
      render: (text, record, index) => {
        if (record.status === 5) {
          return 'Kinnitatud'
        }
        return (
          <Button
            onClick={async (e) => {
              e.stopPropagation()
              if (record.id && record.status === 4) {
                const [error, response] = await to(
                  saveAct({
                    id: record.id,
                    status: 5,
                    object_name: `${object?.name} (${object?.shiffer})`,
                    confirmer_name: user ? `${user?.name} ${user?.surname}` : '',
                    send_mail: true
                  })
                )
                if (!error && response) {
                  message.success('Akt kinnitatud')
                } else {
                  console.log(error)
                }
              }
            }}>
            Kinnita
          </Button>
        )
      }
    },
    {
      title: 'Excel',
      key: 'acts_export',
      align: 'center',
      render: (text, record, index) => (
        <FileExcelOutlined
          style={{ color: 'green' }}
          onClick={(e) => {
            console.log(record)
            e.stopPropagation()
            if (record && record.total && record.total > 0) {
              exportData(record.id)
            } else {
              message.error('Aktil puuduvad read.')
            }
          }}
        />
      )
    },
    {
      title: 'Kustuta',
      key: 'acts_delete',
      align: 'center',
      render: (text, record, index) => (
        <DeleteOutlined
          style={{ color: 'red' }}
          onClick={async (e) => {
            console.log(record)
            e.stopPropagation()
            if (record.id && record.status === 4) {
              const [error, response] = await to(deleteAct({ id: record.id, obj_id: objectId }))
              if (!error && response) {
                message.success('Akt kustutatud')
              } else {
                console.log(error)
              }
            }
          }}
        />
      )
    }
  ]

  return (
    <div>
      <div className="m-2 p-2" style={{ background: 'white' }}>
        <Card
          title="Aktid"
          bordered={false}
          extra={
            <div className="d-flex">
              {objectHasOffers ? (
                <Button style={{ float: 'right' }} onClick={() => setActModalVisible(true)}>
                  Lisa uus akt
                </Button>
              ) : null}
            </div>
          }>
          {/* <Row type='flex' align='middle' style={{padding: 8, width: '100%'}}>
                <Col span={6}>
                    <Typography.Title style={{fontSize: 18, margin: 0, paddingLeft: 8}}>Aktid</Typography.Title>
                </Col>
                <Col span={6}>
                </Col>
                <Col span={6}>
                </Col>
                <Col span={6}>
                    {objectHasOffers && <Button style={{float: 'right'}} onClick={(e) => {
                        e.preventDefault()
                        setActModalVisible(true)
                    }}>Lisa uus akt</Button>}
                </Col>
            </Row> */}

          <Table
            dataSource={acts}
            columns={actsColumns}
            size="medium"
            //style={{padding: 8}}
            loading={actsLoading}
            locale={{
              emptyText: !objectHasOffers && (
                <>
                  <Row style={{ marginBottom: 15 }}>Sul ei ole objektil hinnapakkumist. Hinnapakkumine saab edaspidi akti põhjaks.</Row>
                  <Button
                    onClick={async () => {
                      const dateOffer = moment().format('YYYY-MM-DD')
                      const offer_nr = (object.shiffer + '-' + object.name + '-' + dateOffer).replaceAll(',', '_')
                      console.log(offer_nr)
                      await addOffer({
                        offer_nr: offer_nr,
                        shiffer: object.shiffer,
                        obj_id: objectId,
                        dateoffer: dateOffer
                      })
                      history.push('/offer/' + encodeURIComponent(offer_nr))
                    }}>
                    Lisa hinnapakkumine
                  </Button>
                </>
              )
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  e.preventDefault()
                  fetchActOffers({ act_id: record.id, offer_nrs: offers })
                  fetchActRows(record.id)
                  setSelectedAct(record)
                }
              }
            }}
          />
        </Card>
      </div>

      {selectedAct && Object.values(selectedAct).length > 0 ? (
        <div className="m-2 p-2" style={{ background: 'white' }}>
          <Card title={<div>Akt #: {selectedAct ? selectedAct.act_nr : null}</div>} bordered={false}>
            {/*  <Row type='flex' align='middle' style={{padding: 8, width: '100%'}}>
                        <Col span={6}>
                            <Typography.Title style={{fontSize: 18, margin: 0}}>Akt
                                #: {selectedAct && selectedAct.act_nr}</Typography.Title>
                        </Col>
                    </Row> */}

            {objectHasOffers && selectedAct && <ActTable act={selectedAct} objectId={objectId} exportAct={exportData} />}
          </Card>
        </div>
      ) : null}

      <ActModal wrappedComponentRef={formRef} visible={actModalVisible} loading={actsLoading} onCancel={() => setActModalVisible(false)} onSave={addAct} />
    </div>
  )
}

export default PaObject
